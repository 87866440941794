import { DayUnitsInMiliseconds } from '@heylog-app/shared/types';

export const getIsMessageSentToday = (
	messageDelta: number,
	messageDate: Date,
	now: Date,
): boolean => {
	return (
		messageDelta < DayUnitsInMiliseconds.OneDay && messageDate.getDay() === now.getDay()
	);
};
