import { mdiFeatureSearchOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../util';
import {
	StyledContent,
	StyledH3,
	StyledNoMatchLayout,
	StyledP,
} from './no-match-route.styles';

export const NoMatch = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleButtonClick = () => {
		navigate(ROUTES.LOGIN.USER);
	};

	return (
		<StyledNoMatchLayout>
			<StyledContent data-test="no-match-page">
				<Icon path={mdiFeatureSearchOutline} size={'3em'} />
				<StyledH3>{t('noMatchRoute.title')}</StyledH3>
				<StyledP>{t('noMatchRoute.body')}</StyledP>
				<Button
					data-test="no-match-home-button"
					variant="contained"
					onClick={handleButtonClick}
				>
					{t('noMatchRoute.ctaText')}
				</Button>
			</StyledContent>
		</StyledNoMatchLayout>
	);
};
