import { Button, Stack } from '@mui/material';
import Icon from '@mdi/react';
import { mdiExport, mdiTimerSandComplete } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { ModalClose, useFleetCheckDetails } from '@heylog-app/frontend-lib/app';

import {
	DraverLayout,
	DrawerContent,
	DrawerFooter,
	DrawerTitle,
	EmptyDrawer,
} from './send-check-dialog.style';

import type { DataTableProps } from './data-table';

export const SurveyDetailsDrawerContent = ({
	selectedRow,
	closeDrawer,
	handleExportData,
	handleOpenConversation,
	organizationId,
	workspaceId,
}: {
	selectedRow?: DataTableProps;
	closeDrawer: () => void;
	handleExportData: () => void;
	handleOpenConversation: () => void;
	organizationId: number;
	workspaceId: string;
}) => {
	const { t } = useTranslation();

	const { data: survey, isLoading } = useFleetCheckDetails(
		selectedRow?.id || '-1',
		organizationId,
		workspaceId,
	);

	return (
		<DraverLayout>
			<DrawerTitle>{selectedRow && `${selectedRow.form}`}</DrawerTitle>
			<ModalClose closeModalFn={closeDrawer} />
			<div style={{ height: '100%', overflow: 'scroll', paddingBottom: '20px' }}>
				<DrawerContent>
					{!survey && (
						<EmptyDrawer>
							<Icon path={mdiTimerSandComplete} size={2} />
							<h4>{t('vchk.drawerEmptyTitle')}</h4>
							<p>{t('vchk.drawerEmptySubtitle')}</p>
						</EmptyDrawer>
					)}
					{!isLoading && survey && (
						<Stack gap={2}>
							{survey.items.map((item, index) => {
								return (
									<div key={index}>
										<div>
											<div>
												<b>{t(`vchk.surveyQuestions.${item.slug}`)}</b>
											</div>
											{/* if it is an object we assume it is an image, get the image for the question, display a link or the image itself later */}
											<Stack
												key={index}
												direction="column"
												gap={2}
												sx={{ maxWidth: 600 }}
											>
												{item.answers.map((answer, index) => {
													if (item.type === 'URLS') {
														return <img key={index} src={answer} alt={answer} />;
													} else {
														return <div key={index}>{answer}</div>;
													}
												})}
											</Stack>
										</div>
									</div>
								);
							})}
						</Stack>
					)}
				</DrawerContent>
			</div>

			<DrawerFooter>
				<Stack direction="row" gap={2}>
					<Button
						disabled={!survey}
						onClick={handleExportData}
						variant="outlined"
						color="secondary"
					>
						{t('actionLabels.exportData')}
					</Button>
					<Button
						onClick={handleOpenConversation}
						variant="contained"
						startIcon={<Icon path={mdiExport} size={1} />}
					>
						{t('actionLabels.openConversation')}
					</Button>
				</Stack>
			</DrawerFooter>
		</DraverLayout>
	);
};
