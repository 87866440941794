import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiInformationOutline } from '@mdi/js';
import { Tooltip } from '@mui/material';

import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { StyledOrderStatusIndicator } from './order-status-indicator.styles';

import type { UserOrderStatus } from '@heylog-app/shared/types';
import type { FC } from 'react';

export type OrderStatusIndicatorProps = {
	status: UserOrderStatus;
	isActive: boolean;
};

export const OrderStatusIndicator: FC<OrderStatusIndicatorProps> = ({
	status,
	isActive,
}) => {
	const { t } = useTranslation();

	return (
		<StyledOrderStatusIndicator
			data-test={`order-status-indicator-${status}`}
			$isActive={isActive}
			$status={status}
		>
			<span>{t(`orders.details.status.${status.toLowerCase()}`)}</span>
			{status === UserOrderStatusEnum.DELIVERED && (
				<span style={{ marginLeft: 2 }}>
					<Tooltip title={t('orders.details.confirmComplete.info')} placement="top" arrow>
						<Icon path={mdiInformationOutline} size={'0.85em'} />
					</Tooltip>
				</span>
			)}
		</StyledOrderStatusIndicator>
	);
};
