import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { StyledToggleButton } from './toggle-link-tab.styles';

export type TabNavigationToggleButtonProps = {
	dataTest: string;
	label: string;
	url: string;
	onClick?: () => void;
};

export const TabNavigationToggleButton = ({
	dataTest,
	label,
	url,
	onClick,
}: TabNavigationToggleButtonProps) => {
	const { t } = useTranslation();
	const location = useLocation();
	const active = location.pathname === url;

	return (
		<StyledToggleButton
			data-test={dataTest}
			selected={active}
			onClick={onClick}
			component={Link}
			to={url}
			value={label}
			style={{ textTransform: 'none' }}
		>
			{t(label)}
		</StyledToggleButton>
	);
};
