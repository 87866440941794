import styled from 'styled-components';
import { Card } from '@mui/material';

import { T3, T4, colors } from '../../../styles';

export const StyledSubtitle = styled.div`
	${T4};
	text-align: left;
	color: ${colors.grey[600]};
`;

export const StyledFileCard = styled(Card)`
	box-shadow: unset;
	position: relative;

	:hover {
		cursor: pointer;
	}
`;

export const StyledFileName = styled.div`
	${T3};
	text-align: left;
	word-break: break-word;
	color: ${colors.grey[900]};
`;
