import { createContext, useState, useLayoutEffect, useEffect } from 'react';

import {
	getBrowserNotificationStatus,
	setNotificationOverrideStatus,
} from '../util/browser-notifications.helpers';
import { NotificationDisabledDialog } from '../components/browser-notifications/notification-disabled-dialog';
import { useDialog } from '../hooks';
import { NotificationPermissions, NotificationPolyfill } from '../util';

import type { FC, ReactNode } from 'react';

export const BrowserNotificationsContext = createContext<
	[
		browserNotificationsStatus: boolean,
		setBrowserNotificationsStatus: (status: boolean) => void,
	]
>([
	false,
	() => {
		return;
	},
]);

export const BrowserNotificationsProvider: FC<{
	children?: ReactNode;
}> = ({ children }) => {
	const notificationDisabledDialogControls = useDialog();

	const [browserNotificationsStatus, setBrowserNotificationsStatus] = useState(
		getBrowserNotificationStatus(),
	);

	useLayoutEffect(() => {
		const actBasedOnNotificationPermissionStatus = async () => {
			const notificationPermission: string = NotificationPolyfill.permission;

			switch (notificationPermission) {
				case NotificationPermissions.DENIED:
					notificationDisabledDialogControls.openDialog();
					break;
				case NotificationPermissions.DEFAULT:
					await NotificationPolyfill.requestNotification();
					break;
			}
		};

		(async () => {
			const notificationPermission: string = NotificationPolyfill.permission;

			if (browserNotificationsStatus) {
				// Reset the setting
				setNotificationOverrideStatus(true);
				await actBasedOnNotificationPermissionStatus();
			} else {
				// Reset the setting unless notifications are enabled in browser, but user want to disable them in UI
				setNotificationOverrideStatus(
					notificationPermission === NotificationPermissions.GRANTED ? false : true,
				);
			}

			setBrowserNotificationsStatus(getBrowserNotificationStatus());
		})();
	}, [browserNotificationsStatus, notificationDisabledDialogControls]);

	useEffect(() => {
		// When user change browser setting, automatically update the UI
		let lastNotificationPermission: string = NotificationPolyfill.permission;

		const timerId = setInterval(() => {
			if (lastNotificationPermission !== NotificationPolyfill.permission) {
				setBrowserNotificationsStatus(
					NotificationPolyfill.permission === NotificationPermissions.GRANTED,
				);
				lastNotificationPermission = NotificationPolyfill.permission;
			}
		}, 1000);

		return () => {
			clearInterval(timerId);
		};
	}, []);

	return (
		<>
			<BrowserNotificationsContext.Provider
				value={[browserNotificationsStatus, setBrowserNotificationsStatus]}
			>
				{children}
			</BrowserNotificationsContext.Provider>
			<NotificationDisabledDialog
				controls={notificationDisabledDialogControls}
			></NotificationDisabledDialog>
		</>
	);
};
