import Icon from '@mdi/react';
import { DialogActions } from '@mui/material';
import styled from 'styled-components';

export const StyledDialogActions = styled(DialogActions)`
	justify-content: space-between;
	padding: 0 3rem 2rem 3rem;
`;

export const StyledRoleIcon = styled(Icon)`
	margin-right: 4px;
	margin-bottom: -2px;
	opacity: 50%;
`;
