import { Button, Dialog, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { ModalClose } from '../ui';
import {
	StyledDialogContent,
	StyledDialogHeader,
	StyledDialogSubtitle,
	StyledDialogTitle,
} from './add-vchk-email-dialog.styles';
import { emailValidationRegEx } from '../../util/email-validation-regex';

import type { FC } from 'react';

type AddVchkEmailDialogProps = {
	onClose: () => void;
	handleAddEmail: (email: string) => void;
	isOpen: boolean;
};

type FormData = {
	email: string;
};

export const AddVchkEmailDialog: FC<AddVchkEmailDialogProps> = ({
	onClose,
	isOpen,
	handleAddEmail,
}) => {
	const { t } = useTranslation();

	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			email: '',
		},
	});

	const onSubmitVchkEmailForm = (data: FormData) => {
		handleAddEmail(data.email);
		reset({ email: '' });
	};

	const onFocusEmailField = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			if (event.target.value === '') {
				reset({ email: '' });
			}
		},
		[reset],
	);

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<StyledDialogContent>
				<StyledDialogHeader>
					<StyledDialogTitle>
						{t('navigation.settingsMenu.vchk.addEmailTitle')}
					</StyledDialogTitle>
					<ModalClose closeModalFn={onClose} />
				</StyledDialogHeader>
				<StyledDialogSubtitle>
					{t('navigation.settingsMenu.vchk.addEmailDescription')}
				</StyledDialogSubtitle>

				<form onSubmit={handleSubmit(onSubmitVchkEmailForm)} noValidate>
					<Grid container spacing={1}>
						<Grid item xs={9}>
							<Controller
								control={control}
								name="email"
								render={({ field }) => (
									<TextField
										data-test="invite-workspace-user-with-email-field"
										label={t('navigation.settingsMenu.members.addEmailAddress')}
										fullWidth
										type="email"
										error={!!errors.email}
										helperText={
											errors.email
												? t(`forms.errors.${errors.email?.type ?? 'invalid'}`)
												: null
										}
										placeholder={t('navigation.settingsMenu.members.addEmailAddress')}
										onFocus={onFocusEmailField}
										{...register('email', {
											required: true,
											pattern: {
												value: emailValidationRegEx,
												message: 'Email is invalid',
											},
										})}
										{...field}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={3}>
							<Button
								data-test="submit-workspace-user-invite-button"
								style={{ height: '100%' }}
								fullWidth
								variant="contained"
								type="submit"
							>
								{t('actionLabels.save')}
							</Button>
						</Grid>
					</Grid>
				</form>
			</StyledDialogContent>
		</Dialog>
	);
};
