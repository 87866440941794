import { mdiClose } from '@mdi/js';
import { IconButton } from '@mui/material';

import { colors } from '../../../../styles';
import { ModalCloseIcon } from './modal-close-icon';
import { ModalCloseWrapper } from './modal-close-icon-button';

import type { FC } from 'react';

interface ModalClosePropsInterface {
	closeModalFn: () => void;
	BeforeContent?: JSX.Element | false;
	color?: string;
}

export const ModalClose: FC<ModalClosePropsInterface> = ({
	closeModalFn,
	BeforeContent,
	color,
}) => {
	return (
		<ModalCloseWrapper>
			{BeforeContent}
			<IconButton aria-label="close" onClick={closeModalFn}>
				<ModalCloseIcon
					data-test="dialog-close-button"
					path={mdiClose}
					size={'1em'}
					color={color || colors.greyblue[112]}
				/>
			</IconButton>
		</ModalCloseWrapper>
	);
};
