import React from 'react';
import { Box, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { colors } from '../../styles';
import {
	StyledDoughnut,
	StyledLegendLabel,
	StyledLegendUrgent,
	StyledLegendValue,
	StyledTitle,
	StyledTotalTitle,
	StyledTotalValue,
	StyledUrgentValue,
} from './doughnut-chart.styles';
import { IconFire } from '../icons';

import type { FC } from 'react';
import type { ChartData, ChartOptions, TooltipItem, TooltipModel } from 'chart.js';

interface DoughnutChartLabel {
	text: string;
	hexColor: string;
}

interface DoughnutChartValue {
	total: number;
	urgent?: number;
}

interface DoughnutChartData {
	label: DoughnutChartLabel;
	value: DoughnutChartValue;
}

const getData = (
	chartData: DoughnutChartData[],
): ChartData<'doughnut', DoughnutChartValue[], string | undefined> => ({
	labels: chartData.map(({ label }) => label.text),
	datasets: [
		{
			data: chartData.map(({ value }) => value),
			backgroundColor: chartData.map(({ label }) => label.hexColor),
			borderWidth: 6,
			borderColor: 'transparent',
			hoverBorderColor: chartData.map(({ label }) => label.hexColor),
		},
	],
});

const options: ChartOptions<'doughnut'> = {
	responsive: true,
	parsing: {
		key: 'total',
	},
	cutout: '84%',
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			backgroundColor: colors.primary.darker,
			displayColors: false,
			position: 'nearest',
			callbacks: {
				title(this: TooltipModel<'doughnut'>, tooltipItem: TooltipItem<'doughnut'>[]) {
					return tooltipItem[0]?.label;
				},
				beforeLabel(
					this: TooltipModel<'doughnut'>,
					tooltipItem: TooltipItem<'doughnut'>,
				) {
					return tooltipItem.raw &&
						typeof tooltipItem.raw === 'object' &&
						'total' in tooltipItem.raw
						? `Total: ${tooltipItem.raw?.total}`
						: undefined;
				},
				label(this: TooltipModel<'doughnut'>, tooltipItem: TooltipItem<'doughnut'>) {
					return tooltipItem.raw &&
						typeof tooltipItem.raw === 'object' &&
						'urgent' in tooltipItem.raw
						? `Urgent: ${tooltipItem.raw?.urgent}`
						: undefined;
				},
			},
		},
	},
};

interface DoughnutChartPropsInterface {
	renderData: DoughnutChartData[];
	height?: number;
	title: string;
	total?: {
		value: number;
		urgent?: number;
	};
}

export const DoughnutChart: FC<DoughnutChartPropsInterface> = ({
	renderData,
	height,
	title,
	total,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				border: `1px solid ${colors.border.main}`,
				padding: '20px 16px',
				height: '100%',
				width: '100%',
				borderRadius: '4px',
				backgroundColor: colors.grey[200],
				overflow: 'auto',
			}}
		>
			<Stack
				direction="row"
				marginBottom={2}
				alignItems="center"
				justifyContent="space-between"
			>
				<StyledTitle>{title}</StyledTitle>
			</Stack>
			<Stack direction="row" width="100%" alignItems="center" gap={3}>
				<Box flex={1} minWidth={0} height="fit-content" position="relative">
					<StyledDoughnut
						height={height}
						width="100%"
						data={getData(renderData)}
						options={options}
					/>
					{total && (
						<Box
							textAlign="center"
							position="absolute"
							sx={{ transform: 'translate(-50%, -50%)', top: '50%', left: '50%' }}
						>
							<StyledTotalTitle>{t('dashboardMain.total')}</StyledTotalTitle>
							<Stack justifyContent="center" alignItems="center">
								<StyledTotalValue>{total.value}</StyledTotalValue>
								{total.urgent && (
									<StyledUrgentValue>
										<IconFire />
										{total.urgent}
									</StyledUrgentValue>
								)}
							</Stack>
						</Box>
					)}
				</Box>
				<Box flex={1} minWidth={0}>
					{renderData.map((item, idx) => (
						<Stack key={idx} direction="row" alignItems="center" paddingBottom={1}>
							<Box
								sx={{
									width: '10px',
									height: '10px',
									minWidth: '10px',
									minHeight: '10px',
									borderRadius: '50%',
									backgroundColor: item.label.hexColor,
									marginRight: 1,
								}}
							/>

							<StyledLegendLabel>{item.label.text}</StyledLegendLabel>

							<Stack marginLeft="auto" gap={0.5} direction="row" alignItems="center">
								<StyledLegendValue>{item.value.total}</StyledLegendValue>

								{item.value.urgent && (
									<StyledLegendUrgent>({item.value.urgent})</StyledLegendUrgent>
								)}
							</Stack>
						</Stack>
					))}
				</Box>
			</Stack>
		</Box>
	);
};
