import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Radio,
	RadioGroup,
	Stack,
	Step,
	StepLabel,
	Stepper,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
	FlashSnackbar,
	ModalClose,
	useApiClientContext,
	useContacts,
	useSnackbar,
	useOrganizationIdByWorkspaceId,
	createFleetCheckAPI,
	useRequestsWithFleetChecks,
	useDebounce,
	SidebarSearch,
} from '@heylog-app/frontend-lib/app';
import {
	MessagingTransportTypeEnum,
	type FleetCheckType,
} from '@heylog-app/shared/types';

import { StyledFormControlLabel } from './send-check-dialog.style';

import type { ChangeEvent } from 'react';

type SendCheckDialogProps = { showDialog: boolean; closeDialog: () => void };

export const SendCheckDialog = ({ showDialog, closeDialog }: SendCheckDialogProps) => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams();

	const { organizationId } = useOrganizationIdByWorkspaceId(workspaceId || '-1');
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const [, setIsSearching] = useState<boolean>(false);
	const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);
	const [isSending, setIsSending] = useState(false);

	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
			setIsSearching(true);
		},
		[setSearchTerm],
	);

	const steps = ['sendFormStep1', 'sendFormStep2'];
	const [selectedForm, setSelectedForm] = useState<FleetCheckType | null>(null);
	const [selectedContact, setSelectedContact] = useState<number | null>(null);
	const { mutateFleetCechkRequests } = useRequestsWithFleetChecks(
		organizationId || -1,
		workspaceId || '-1',
	);

	const { contacts } = useContacts({
		q: debouncedSearchTerm,
		status: 'ACTIVE',
	});

	const contactsToShow = contacts?.filter(
		(contact) =>
			contact.conversations?.[0]?.transportType ===
			MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,
	);

	const [activeStep, setActiveStep] = useState(0);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	useEffect(() => {
		if (showDialog) {
			setActiveStep(0);
		}
	}, [showDialog]);

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSubmit = () => {
		if (!selectedContact || !selectedForm || !organizationId) return;
		setIsSending(true);

		createFleetCheckAPI(
			apiClient,
			{
				type: selectedForm,
				contactId: selectedContact,
			},
			organizationId,
			workspaceId || '-1',
		)
			.then(() => {
				openSnackbar('success', t('vchk.sendFormSuccess'), true, 2000);
				mutateFleetCechkRequests();
				closeDialog();
				setIsSending(false);
			})
			.catch(() => {
				openSnackbar('error', t('vchk.sendFormError'), true, 2000);
				setIsSending(false);
			});
	};

	return (
		<>
			<Dialog open={showDialog} onClose={closeDialog} maxWidth="md" fullWidth>
				<DialogTitle>{t('vchk.sendNewFormTitle')}</DialogTitle>
				<ModalClose closeModalFn={closeDialog} />
				<DialogContent>
					<Box sx={{ width: '100%', minHeight: '300px' }}>
						<Stepper activeStep={activeStep}>
							{steps.map((label, index) => {
								const stepProps: { completed?: boolean } = {};
								const labelProps: {
									optional?: React.ReactNode;
								} = {};

								return (
									<Step key={index} {...stepProps}>
										<StepLabel {...labelProps}>{t(`vchk.${label}`)}</StepLabel>
									</Step>
								);
							})}
						</Stepper>

						<>
							{activeStep === 0 && (
								<RadioGroup
									value={selectedForm}
									name="radio-buttons-group"
									sx={{ marginTop: '16px' }}
								>
									<StyledFormControlLabel
										value={'VEHICLE'}
										control={<Radio />}
										data-test="vchk-option-vehicle"
										label={t('vchk.sendVehicleCheckLabel')}
										onChange={(e) =>
											setSelectedForm(
												(e.target as HTMLInputElement).value as FleetCheckType,
											)
										}
									/>
									<StyledFormControlLabel
										value={'FLAT_TIRE'}
										control={<Radio />}
										label={t('vchk.sendFlatTireWorkflowLabel')}
										onChange={(e) =>
											setSelectedForm(
												(e.target as HTMLInputElement).value as FleetCheckType,
											)
										}
									/>
								</RadioGroup>
							)}
							{activeStep === 1 && (
								<>
									<Stack sx={{ marginTop: 2 }}>
										<SidebarSearch
											onSearch={handleSearch}
											setIsSearching={setIsSearching}
										/>
									</Stack>

									<RadioGroup
										value={selectedContact}
										name="radio-buttons-group"
										sx={{ marginTop: '16px' }}
									>
										{contactsToShow?.map((contact) => (
											<StyledFormControlLabel
												data-test={`vchk-contact-${contact.id}`}
												key={contact.id}
												value={contact.id}
												control={<Radio />}
												label={`${contact.firstName} ${contact.lastName}`}
												onChange={() => setSelectedContact(contact.id)}
											/>
										))}
									</RadioGroup>
								</>
							)}
						</>
					</Box>
				</DialogContent>
				<DialogContent sx={{ paddingTop: 0, overflow: 'unset' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Button
							color="inherit"
							variant="contained"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							{t('actionLabels.back')}
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />

						{activeStep === steps.length - 1 ? (
							<Button
								data-test="vchk-dialog-finish"
								disabled={!selectedContact || isSending}
								onClick={handleSubmit}
								variant="contained"
							>
								{t('actionLabels.finish')}
							</Button>
						) : (
							<Button
								data-test="vchk-dialog-next"
								disabled={!selectedForm}
								onClick={handleNext}
								variant="contained"
							>
								{t('actionLabels.next')}
							</Button>
						)}
					</Box>
				</DialogContent>
			</Dialog>

			<FlashSnackbar
				controls={[stateSnackbar, openSnackbar, closeSnackbar]}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>
		</>
	);
};
