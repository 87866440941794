import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { ContactStatusEnum } from '@heylog-app/shared/types';

import { useContacts, useMenu, usePlugins } from '../../../hooks';
import { ButtonMenu, ContactName, ModalClose } from '../../ui';
import { swollowEvent } from '../../../util/swallow-event';
import { StyledDialogActions } from '../../dialog';

import type { FullContactResInterface } from '@heylog-app/shared/types';

export const ContactSelect = ({
	onSelect,
	onCreate,
	allowCreate,
	confirmationMessage,
	shouldConfirm,
	addUserInProgress = false,
}: {
	onSelect: (contact: FullContactResInterface) => void;
	onCreate?: () => void;
	allowCreate: boolean;
	confirmationMessage: string;
	shouldConfirm?: (contact: FullContactResInterface) => boolean;
	addUserInProgress: boolean;
}) => {
	const { t } = useTranslation();
	const { workspaceId = '' } = useParams();
	const menuControl = useMenu();
	const { closeMenu } = menuControl;

	const { TimeMattersOSUPlugin } = usePlugins(workspaceId);

	const [selectedContact, setSelectedContact] = useState<FullContactResInterface | null>(
		null,
	);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [contactFilter, setContactFilter] = useState<string>('');
	const { contacts, isLoading } = useContacts({
		q: contactFilter,
		status: ContactStatusEnum.ACTIVE,
	});

	const onCloseConfirmDialog = useCallback(() => {
		setShowConfirmDialog(false);
	}, [setShowConfirmDialog]);

	const onFilter = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			e.stopPropagation();
			e.preventDefault();
			const value = e.target.value;
			setContactFilter(value);
		},
		[setContactFilter],
	);

	const handleOnCreate = useCallback(() => {
		closeMenu();
		onCreate && onCreate();
	}, [closeMenu, onCreate]);

	const onConfirm = useCallback(() => {
		selectedContact && onSelect(selectedContact);
		onCloseConfirmDialog();
	}, [onCloseConfirmDialog, onSelect, selectedContact]);

	const handleOnSelect = useCallback(
		(contact: FullContactResInterface) => {
			closeMenu();
			if (shouldConfirm && shouldConfirm(contact)) {
				setSelectedContact(contact);
				setShowConfirmDialog(true);
			} else {
				onSelect(contact);
			}
		},
		[closeMenu, onSelect, shouldConfirm],
	);

	return (
		<>
			{!TimeMattersOSUPlugin && (
				<ButtonMenu
					id="create-conversation-menu"
					button={
						<Button data-test="order-contact-select-button" variant="contained">
							{t('actionLabels.new')}
						</Button>
					}
					control={menuControl}
					disabled={addUserInProgress}
				>
					<MenuItem onKeyDown={swollowEvent}>
						<TextField
							placeholder={t('actionLabels.search')}
							value={contactFilter}
							onChange={onFilter}
						/>
					</MenuItem>
					{contacts &&
						!isLoading &&
						contacts.map((contact) => (
							<MenuItem onClick={() => handleOnSelect(contact)} key={contact.id}>
								<ContactName contact={contact} />
							</MenuItem>
						))}
					{allowCreate && (
						<MenuItem>
							<Button variant="contained" onClick={handleOnCreate} fullWidth>
								{t('contacts.sidebar.createLabel')}
							</Button>
						</MenuItem>
					)}
				</ButtonMenu>
			)}
			<Dialog open={showConfirmDialog} onClose={onCloseConfirmDialog}>
				<ModalClose closeModalFn={onCloseConfirmDialog} />
				<DialogTitle id="alert-dialog-title">
					{t('contacts.toolSidebar.assignOrderConfirmationTitle')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{confirmationMessage}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={onCloseConfirmDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button variant="contained" onClick={onConfirm} autoFocus>
						{t('actionLabels.confirm')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</>
	);
};
