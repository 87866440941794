import { useEffect, useMemo, useState } from 'react';

import type { GridApiPro } from '@mui/x-data-grid-pro';
import type { MutableRefObject } from 'react';
import type {
	GridColDef,
	GridColumnResizeParams,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid';

interface UseTableColumnSettingsConfigInterface {
	columns: GridColDef[];
	apiRef: MutableRefObject<GridApiPro>;
	localStorageName: string;
}

const MAX_COLUMN_WIDTH = 1000;

const safelyInsertColumnWidth = (width: unknown, defaultWidth?: number) => {
	if (typeof width === 'number' && width <= MAX_COLUMN_WIDTH) return width;
	return defaultWidth;
};

const safelyParseColumnWidths = (storageKey: string) => {
	const savedColumnWidths = localStorage.getItem(storageKey);

	if (savedColumnWidths) {
		try {
			const widthsObject = JSON.parse(savedColumnWidths);
			if (widthsObject && typeof widthsObject === 'object')
				return widthsObject as Record<string, unknown>;
		} catch (error) {
			return localStorage.removeItem(storageKey);
		}
	}
};

const safelyParseColumnOrdering = (storageKey: string) => {
	const savedColumnOrdering = localStorage.getItem(storageKey);

	if (savedColumnOrdering) {
		try {
			const orderingArray = JSON.parse(savedColumnOrdering);
			if (orderingArray && Array.isArray(orderingArray))
				return orderingArray as unknown[];
		} catch (error) {
			return localStorage.removeItem(storageKey);
		}
	}
};

const safelyInsertColumnVisibilityBoolean = (
	visibilityModel: Record<string, unknown>,
) => {
	const parsedVisibilityModel: Record<string, boolean> = {};

	for (const field in visibilityModel) {
		const value = visibilityModel[field];
		if (typeof value === 'boolean') parsedVisibilityModel[field] = value;
		else parsedVisibilityModel[field] = true;
	}

	return parsedVisibilityModel;
};

const safelyParseColumnVisibilityModel = (storageKey: string) => {
	const savedColumnVisibilityModel = localStorage.getItem(storageKey);

	if (savedColumnVisibilityModel) {
		try {
			const visibilityModelObject = JSON.parse(savedColumnVisibilityModel);
			if (visibilityModelObject && typeof visibilityModelObject === 'object') {
				const parsedVisibilityModel = safelyInsertColumnVisibilityBoolean(
					visibilityModelObject as Record<string, unknown>,
				);
				return parsedVisibilityModel;
			}
		} catch (error) {
			return localStorage.removeItem(storageKey);
		}
	}
};

export const useTableColumnsSettings = ({
	columns,
	apiRef,
	localStorageName,
}: UseTableColumnSettingsConfigInterface) => {
	// store column size in local storage

	// i am using this state in order to prevent ui shifting and show only relevant columns
	const [isWaitingForLocalStorage, setIsWaitingForLocalStorage] = useState(true);
	// 1. Create a state variable to store the column widths.
	const [columnWidths, setColumnWidths] = useState<Record<string, unknown>>({});
	const [columnOrdering, setColumnOrdering] = useState<unknown[]>([]);

	const WIDTHS_STORAGE_KEY = `${localStorageName}-widths`;
	const ORDERINGS_STORAGE_KEY = `${localStorageName}-orderings`;
	const VISIBILITY_MODEL_STORAGE_KEY = `${localStorageName}-visibility-model`;

	// 2. Use the useEffect hook to load the column widths from local storage when the component mounts.
	useEffect(() => {
		const columnWidths = safelyParseColumnWidths(WIDTHS_STORAGE_KEY);
		const columnOrdering = safelyParseColumnOrdering(ORDERINGS_STORAGE_KEY);
		const columnVisibilityModel = safelyParseColumnVisibilityModel(
			VISIBILITY_MODEL_STORAGE_KEY,
		);

		if (columnWidths) setColumnWidths(columnWidths);
		if (columnOrdering) setColumnOrdering(columnOrdering);
		if (apiRef.current && columnVisibilityModel)
			apiRef.current.setState({
				...apiRef.current.state,
				columns: {
					...apiRef.current.state.columns,
					columnVisibilityModel: {
						...apiRef.current.state.columns.columnVisibilityModel,
						...columnVisibilityModel,
					},
				},
			});

		setIsWaitingForLocalStorage(false);
	}, [ORDERINGS_STORAGE_KEY, WIDTHS_STORAGE_KEY, VISIBILITY_MODEL_STORAGE_KEY, apiRef]);

	// 3. In the onColumnResize event handler, save the new column width to the local storage.
	const handleColumnResize = (params: GridColumnResizeParams) => {
		const state = apiRef.current.state.columns.lookup;
		const widthsObject: Record<string, unknown> = {};

		for (const key in state) {
			widthsObject[key] = state[key]?.width;
		}
		const newWidths = { ...widthsObject, [params.colDef.field]: params.width };

		// Update the widths of all columns in the local storage
		columns.forEach((column) => {
			if (!newWidths[column.field]) {
				newWidths[column.field] = column.width || 150;
			}
		});

		localStorage.setItem(WIDTHS_STORAGE_KEY, JSON.stringify(newWidths));
	};

	// 4. In the handleColumnOrderChange event handler, save the new column ordering to the local storage.
	const handleColumnOrderChange = () => {
		const { orderedFields } = apiRef.current.state.columns;
		localStorage.setItem(ORDERINGS_STORAGE_KEY, JSON.stringify(orderedFields));
	};

	const handleColumnVisibilityModelChange = (data: GridColumnVisibilityModel) => {
		localStorage.setItem(VISIBILITY_MODEL_STORAGE_KEY, JSON.stringify(data));
	};

	// 5. In the columns prop of the DataGridPro component, set the width property of each column to the corresponding value from the state.
	const adjustedColumns = useMemo(() => {
		return columns
			.map((column) => ({
				...column,
				width: safelyInsertColumnWidth(columnWidths[column.field], column.width),
				flex: undefined,
			}))
			.sort((a, b) => {
				const indexA = columnOrdering.indexOf(a.field);
				const indexB = columnOrdering.indexOf(b.field);

				// ~ operator conveniently transforms -1 to 0
				if (~indexA && ~indexB) return indexA - indexB;
				if (~indexA) return -1;
				if (~indexB) return 1;

				return 0;
			});
	}, [columns, columnWidths, columnOrdering]);

	return {
		adjustedColumns: isWaitingForLocalStorage ? [] : adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	};
};
