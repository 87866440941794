import { createContext, useMemo } from 'react';
import Axios from 'axios';

import { useAuthContext, useEnvContext } from '../hooks';
import { getLocalStorageLanguage } from '../util';

import type { Nullable } from '@heylog-app/shared/types';
import type { FC, PropsWithChildren } from 'react';
import type { ApiClientContextType } from '../types';

export const ApiClientContext = createContext<Nullable<ApiClientContextType>>(null);

export const ApiClientProvider: FC<PropsWithChildren> = ({ children }) => {
	const { API_URL } = useEnvContext();
	const { token } = useAuthContext();

	const value = useMemo(() => {
		const instance = Axios.create({
			baseURL: API_URL,
		});

		instance.interceptors.request.use(
			function (config) {
				if (config.headers) {
					config.headers['Authorization'] = `Bearer ${token}`;
					config.headers['Accept-language'] = getLocalStorageLanguage() ?? '';
				}

				return config;
			},
			function (error) {
				return Promise.reject(error);
			},
		);

		instance.interceptors.response.use(
			(res) => res,
			(error: unknown) => {
				if (Axios.isAxiosError(error) && error?.response?.status === 404) {
					console.log(error);
				}

				return Promise.reject(error);
			},
		);

		return { apiClient: instance };
	}, [API_URL, token]);

	return <ApiClientContext.Provider value={value}>{children}</ApiClientContext.Provider>;
};
