import { mdiAccount, mdiDelete } from '@mdi/js';

import {
	StyledDeleteIcon,
	StyledGuestUser,
	StyledIcon,
	StyledName,
} from './guest-user.styles';

import type { FC } from 'react';

type GuestUserProps = {
	firstName: string;
	lastName: string;
	email: string;
	invitationDate?: Date;
	guest?: boolean;
	onClick: () => void;
};

export const GuestUser: FC<GuestUserProps> = ({
	firstName,
	lastName,
	email,
	onClick,
}) => {
	return (
		<StyledGuestUser onClick={onClick}>
			<StyledName>
				<StyledIcon path={mdiAccount} size={'1rem'} />
				<div>
					<p>
						{firstName} {lastName}
					</p>
					<p>({email})</p>
				</div>
			</StyledName>
			<StyledDeleteIcon path={mdiDelete} />
		</StyledGuestUser>
	);
};
