import { useTranslation } from 'react-i18next';
import { Icon } from '@mdi/react';
import { mdiCardAccountDetailsOutline } from '@mdi/js';

import { useUser } from '../../../hooks';
import { StyledSignatureShield } from './message-input.styles';

export const SignatureShield = () => {
	const { t } = useTranslation();
	const { user } = useUser();

	return (
		<StyledSignatureShield data-test="active-signature-logo">
			<Icon path={mdiCardAccountDetailsOutline} size={'1.3em'} />{' '}
			{`${t('settings.labels.signature')}: ${user?.signature}`}
		</StyledSignatureShield>
	);
};
