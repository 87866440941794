import { mkenum } from './mkenum';

// keep in sync with schema.prisma
export const MessageStatusEnum = mkenum({
	PENDING: 'PENDING',
	OUT_OF_WHATSAPP_24H: 'OUT_OF_WHATSAPP_24H',
	SENT: 'SENT',
	DELIVERED: 'DELIVERED',
	READ: 'READ',
	FAILED: 'FAILED',
});

export type MessageStatus = typeof MessageStatusEnum[keyof typeof MessageStatusEnum];
