import { ContactStatusEnum } from '@heylog-app/shared/types';

import { useContacts, useUnread } from '.';

export const useActiveUnread = () => {
	const { unreadMap } = useUnread();
	const contactFilter = ContactStatusEnum.ACTIVE;
	const { contacts } = useContacts({ orderId: '', status: contactFilter });

	if (unreadMap) {
		const contactsIds = contacts?.map((contact) => contact.id);

		const activeUnreadMapEntries = Object.entries(unreadMap).filter(([contactId]) => {
			return contactsIds?.includes(Number(contactId));
		});

		const activeUnread = Object.fromEntries(activeUnreadMapEntries);
		return { activeUnread };
	}

	return { activeUnread: {} };
};
