import { StyledTabNavigation } from './tab-navigation.styles';

import type { FC } from 'react';

type TabNavigationProps = {
	children: React.ReactNode;
};

export const TabNavigation: FC<TabNavigationProps> = ({ children }) => {
	return (
		<StyledTabNavigation>
			<ul>{children}</ul>
		</StyledTabNavigation>
	);
};
