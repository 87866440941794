import axios from 'axios';

import type { I18nExceptionResponseInterface } from '@heylog-app/shared/types';
import type { AxiosError, AxiosResponse } from 'axios';

export const isAxiosResponse = (obj: unknown): obj is AxiosResponse => {
	return !!(
		obj &&
		(obj as AxiosResponse).status > 199 &&
		(obj as AxiosResponse).status < 300
	);
};

export const isTranslatedAxiosError = (
	obj: unknown,
): obj is AxiosError<I18nExceptionResponseInterface> => {
	return !!(
		obj &&
		axios.isAxiosError(obj) &&
		(obj as AxiosError<I18nExceptionResponseInterface>)?.response?.data?.i18nMessage
	);
};
