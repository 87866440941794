import { Trans, useTranslation } from 'react-i18next';

import { ExpiredSvg, HeylogLogoBlack, ROUTES } from '@heylog-app/frontend-lib/app';

import {
	StyledPageContainer,
	StyledPageContent,
	StyledText,
	StyledTextNavLink,
	StyledTitle,
} from './expired-reset-link.styles';

import type { FC } from 'react';

export const ExpiredResetLink: FC = () => {
	const { t } = useTranslation();

	return (
		<StyledPageContainer data-test="expired-reset-password-page">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<ExpiredSvg />
				</div>
				<StyledTitle>{t('expiredResetPassword.title')}</StyledTitle>
				<StyledText>
					<Trans
						i18nKey="expiredResetPassword.body"
						values={{
							linkText: 'forgot-password',
							email: 'support@heylog.com',
						}}
						components={[
							<StyledTextNavLink
								to={ROUTES.FORGOT_PASSWORD}
								data-test="forgot-password-link"
							/>,
							<a href="mailto:support@heylog.com">support@heylog.com</a>,
						]}
						shouldUnescape
					/>
				</StyledText>
			</StyledPageContent>
		</StyledPageContainer>
	);
};
