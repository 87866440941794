import { Button } from '@mui/material';
import styled from 'styled-components';

import { STYLE_1, STYLE_2 } from '../../../styles';

export const StyledButton = styled(Button)`
	${STYLE_1};

	svg {
		border-radius: 50%;
	}
`;

export const StyledLabel = styled.span`
	display: flex;
	gap: 0.5rem;
	align-items: center;
	color: ${({ theme }) => theme.palette.common.black};
	${STYLE_2};

	svg {
		border-radius: 50%;
	}
`;
