import type { EventContextType, EventPayloadType } from '@heylog-app/shared/types';

export type NarrowEntityEvent<
	Data,
	Context extends EventContextType,
	EventType extends EventPayloadType | undefined = EventPayloadType,
> = Data extends {
	contextType: Context;
	eventType: EventType;
}
	? Data
	: never;
