import type { AxiosInstance } from 'axios';
import type { ArchiveEntryParams } from '../types';

type DisconnectContactFromOrderAPIParams = ArchiveEntryParams & { orderId: number };

export const disconnectContactFromOrderAPI = async (
	apiClient: AxiosInstance,
	{ id: contactId, workspaceId, orderId }: DisconnectContactFromOrderAPIParams,
) => {
	const key = `/workspaces/${workspaceId}/orders/${orderId}/contacts/${contactId}`;
	return apiClient.delete(`${key}`);
};
