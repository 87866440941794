import { AccordionSummary, Box, Chip, FormLabel, Stack, TextField } from '@mui/material';
import styled from 'styled-components';

import { LabelsAutocomplete } from '../labels-autocomplete';
import { T2_medium, T3, T4, colors } from '../../styles';
import { ButtonMenu } from '../ui';

export const StyledFormlabel = styled(FormLabel)`
	color: ${({ theme }) => theme.palette.common.black};
	text-align: right;
	font-weight: 700;
	min-width: 100px;
`;

export const StyledTextField = styled(TextField)`
	width: 100%;

	fieldset {
		border: 0;
		border-bottom: 1px #9ba0b3 solid;
		border-radius: 0;
	}
`;

export const StyledLabelsAutocomplete = styled(LabelsAutocomplete)`
	width: 100%;

	fieldset {
		border: 0;
		border-bottom: 1px #9ba0b3 solid;
		border-radius: 0;
	}
`;

export const StyledAccordionSummary = styled(AccordionSummary)`
	${T2_medium};
	color: ${colors.grey[900]};
	padding: 16px;

	.MuiAccordionSummary-content {
		margin: 0;
	}
`;
export const StyledInfoStack = styled(Stack)`
	border-bottom: 1px solid ${colors.grey[300]};
	justify-content: space-between;
	padding-block: 16px;

	& > *:first-child {
		color: ${colors.grey[600]};
		${T3};
	}

	& > *:last-child {
		color: ${colors.grey[900]};
		${T3};
		text-align: right;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
`;

export const StyledLinkedOrderTitle = styled(Box)`
	${T2_medium};
	color: ${colors.grey[900]};
`;

export const StyledLabelChip = styled(Chip)`
	${T4};
	color: ${colors.grey[700]};
`;

export const StyledButtonMenu = styled(ButtonMenu)`
	.MuiMenu-paper {
		min-width: 240px;
	}
`;

export const StyledReplyTextField = styled(TextField)`
	width: 100%;
	.MuiInputBase-root {
		padding-left: 0;
	}

	input {
		padding: 8.5px 4px 8.5px 0px;
	}

	.Mui-error {
		margin-left: 0;
	}

	fieldset {
		border: 0;
		border-bottom: 1px ${colors.grey[300]} solid;
		border-radius: 0;
	}
`;
