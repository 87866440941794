// we don't want to expose local storage function directly to avoid different local
// storage keys.

import type { Nullable } from '@heylog-app/shared/types';

const setLocalStorage = <T>(name: string, value: T): void => {
	try {
		window.localStorage.setItem(name, JSON.stringify(value));
	} catch (error) {
		console.error("LocalStorage: couldn't set ", name, ' with value: ', value);
	}
};

const getLocalStorage = <T = never>(name?: string): Nullable<T> => {
	try {
		const rawItem = name && window.localStorage.getItem(name);
		return rawItem ? JSON.parse(rawItem) : null;
	} catch (error) {
		console.error("LocalStorage: couldn't get ", name);
		return null;
	}
};

// Auth token

export const LOCAL_STORAGE_TOKEN = 'token';

export const getLocalStorageToken = (): Nullable<string> =>
	getLocalStorage<string>(LOCAL_STORAGE_TOKEN);

export const setLocalStorageToken = (token: Nullable<string>): void =>
	setLocalStorage(LOCAL_STORAGE_TOKEN, token);

// Language

export const LOCAL_STORAGE_LANGUAGE = 'appLanguage';

export const getLocalStorageLanguage = (): Nullable<string> =>
	getLocalStorage<string>(LOCAL_STORAGE_LANGUAGE);

export const setLocalStorageLanguage = (lang: Nullable<string>): void =>
	setLocalStorage(LOCAL_STORAGE_LANGUAGE, lang);
