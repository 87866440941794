export const whatsappSupportedLanguages = [
	'de',
	'en',
	'pl',
	'cs',
	'ru',
	'ro',
	'hu',
	'sk',
	'sl',
	'lv',
	'lt',
	'tr',
	'bg',
	'es',
	'pt_PT',
	'fi',
	'it',
	'et',
	'fr',
	'uk',
	'bs',
	'sr',
	'hr',
	'mk',
];
