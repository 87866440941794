export const NoConversationSvg = () => (
	<svg
		width="300"
		height="300"
		viewBox="100 0 539 796"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M348.053 357.774C348.053 357.774 413.429 366.222 420.034 360.459C426.639 354.697 462.792 348.748 464.507 354.354C466.222 359.959 430.648 398.63 430.648 398.63C430.648 398.63 454.52 442.123 456.039 440.016C460.209 434.203 465 428.863 470.328 424.091C477.786 417.554 494.975 401.443 494.975 401.443C494.975 401.443 493.554 481.332 493.132 487.516C492.711 493.7 487.252 652.264 484.018 662.485C480.784 672.707 478.962 726.93 474.914 732.203C470.867 737.475 471.278 738.083 457.666 738.083C444.054 738.083 380.246 736.201 370.632 736.76C361.019 737.318 323.592 744.541 323.308 714.014C323.024 683.487 312.038 611.114 311.088 594.718C310.137 578.323 306.433 497.267 306.433 497.267C306.433 497.267 306.776 402.648 308.236 396.21C309.696 389.771 316.184 375.532 322.367 372.974C328.551 370.416 328.561 367.339 328.561 367.339C328.561 367.339 324.122 358.568 329.541 356.304"
			fill="#7C839E"
		/>
		<path
			d="M441.555 738.112C441.555 738.112 402.267 732.732 404.687 744.482C407.108 756.232 409.724 764.876 404.579 769.609C399.434 774.343 380.648 787.984 392.761 790.277C404.873 792.571 464.693 791.708 467.358 785.221C470.024 778.733 469.465 771.05 469.436 762.926C469.406 754.801 472.905 738.71 464.448 739.749"
			fill="#D1D4F2"
		/>
		<path
			d="M348.151 738.132C348.151 738.132 334.902 730.889 335.95 744.492C336.576 752.816 336.405 761.18 335.441 769.472C335.441 769.472 306.883 786.21 313.391 788.984C319.898 791.757 373.269 792.904 379.746 790.709C386.224 788.513 392.036 789.425 392.761 772.539C393.486 755.654 396.857 735.535 390.487 734.319C384.117 733.104 373.082 736.603 367.947 736.858C362.812 737.112 353.384 736.858 353.384 736.858"
			fill="#D1D4F2"
		/>
		<path
			d="M269.143 302.825C269.143 302.825 256.766 294.476 260.892 280.04C265.018 265.605 265.233 270.123 269.143 263.547C273.054 256.971 278.473 237.254 283.373 226.562C288.273 215.87 300.905 197.279 325.444 196.789C349.984 196.299 353.992 189.929 353.992 189.929C353.992 189.929 342.977 201.248 347.132 209.088C351.287 216.928 388.292 222.897 402.139 212.695C415.987 202.493 417.251 190.939 421.994 185.167C426.737 179.394 453.766 180.267 458.881 181.433C463.997 182.599 507.764 203.973 522.111 261.087C536.458 318.202 535.635 315.095 535.615 323.445C535.596 331.794 538.967 338.517 527.501 335.93C516.035 333.343 492.172 337.596 485.106 341.281C478.04 344.965 475.62 358.47 466.751 355.138C457.882 351.806 455.647 352.962 448.405 355.99C441.163 359.019 402.825 363.458 380.785 360.106C358.745 356.755 329.639 356.343 329.639 356.343C329.639 356.343 312.263 349.699 308.598 357.764C304.933 365.83 305.531 364.565 301.807 367.358C298.083 370.151 304.482 372.788 296.231 373.013C287.979 373.238 282.334 377.815 284.843 368.828C287.352 359.842 287.567 335.92 285.294 330.559"
			fill="#D1D4F2"
		/>
		<path
			d="M384.578 315.291C372.102 304.442 359.098 292.065 356.413 275.748C355.736 271.593 355.844 267.104 357.961 263.469C361.264 257.814 368.957 255.727 375.209 257.667C381.461 259.607 386.273 264.831 389.213 270.691C391.928 264.684 397.279 259.774 403.698 258.255C410.117 256.736 417.418 258.961 421.406 264.223C424.046 267.997 425.302 272.566 424.964 277.159C424.63 284.872 421.044 292.173 416.144 298.141C411.244 304.109 405.06 308.921 398.807 313.409C395.025 316.144 390.252 318.927 385.979 317.045"
			fill="white"
		/>
		<path
			d="M309.5 113.47C309.5 113.47 303.62 119.997 308.118 127.013C312.616 134.03 316.37 133.05 312.038 140.733C307.707 148.417 298.259 176.307 307.462 187.95C316.664 199.592 330.188 210.725 339.263 200.004C348.337 189.283 352.257 189.694 349.366 172.564C347.554 160.683 346.63 148.684 346.603 136.666L335.754 125.132C335.754 125.132 335.46 115.234 345.407 108.217C355.354 101.2 355.893 93.1936 356.04 87.833C356.187 82.4724 363.439 81.953 369.76 87.833C376.081 93.713 395.671 101.396 397.053 102.317C398.435 103.239 400.287 107.453 400.326 109.687C400.366 111.921 407.265 103.121 413.772 107.541C420.279 111.961 420.691 113.421 418.427 118.968C416.163 124.514 421.524 124.299 418.427 126.19C415.33 128.082 406.765 134.354 411.048 143.987C415.33 153.62 427.09 148.26 431.01 128.248C434.93 108.237 440.428 99.6322 428.707 85.6868C416.986 71.7414 401.757 77.3176 397.631 74.8382C393.506 72.3588 394.691 68.3016 386.783 63.2154C378.874 58.1292 346.603 54.3954 332.608 73.3878C318.614 92.3802 317.399 100.201 317.399 100.201"
			fill="#7C839E"
		/>
		<path
			d="M213.195 210.656L206.335 183.491L203.013 170.418C202.2 167.184 202.033 162.509 199.877 159.843C196.241 155.237 186.51 160.951 182.394 162.529C174.867 165.41 166.91 168.467 160.011 172.691C154.042 176.347 152.915 181.776 154.297 188.371C156.219 196.734 158.69 204.961 161.696 212.999C164.453 220.839 167.553 228.535 170.996 236.087C173.495 241.585 176.141 251.003 182.217 253.58C188.872 256.403 198.201 251.62 204.14 248.68C210.079 245.74 218.017 242.065 218.575 234.686C218.85 231.109 213.283 231.129 213.009 234.686C212.715 238.498 206.453 241.203 203.601 242.712C200.314 244.465 196.885 245.937 193.35 247.112C188.264 248.798 184.177 249.856 181.247 244.555C179.522 241.419 178.052 238.126 176.553 234.882C169.749 220.194 164.295 204.918 160.256 189.243C158.923 184.04 158.364 180.218 163.49 177.111C166.815 175.244 170.247 173.575 173.77 172.113C177.644 170.369 181.564 168.755 185.53 167.272C187.215 166.645 188.901 166.037 190.606 165.459C191.351 165.194 195.418 163.548 195.604 163.626C196.31 163.93 197.897 173.034 198.329 174.72L201.396 186.901L207.766 212.195C208.648 215.664 214.018 214.155 213.136 210.715L213.195 210.656Z"
			fill="#7C839E"
		/>
		<path
			d="M176.847 178.111L176.229 179.091C175.453 180.419 175.9 182.126 177.229 182.903C178.558 183.679 180.265 183.232 181.041 181.903L181.659 180.923C182.424 179.598 181.988 177.903 180.679 177.111C179.349 176.373 177.675 176.803 176.866 178.091L176.847 178.111Z"
			fill="#7C839E"
		/>
		<path
			d="M354.07 94.8498L342.046 107.776C338.518 111.569 334.118 115.302 331.207 119.536C325.778 127.533 339.664 134.236 345.113 137.098L343.731 134.697C344.591 155.32 347.547 175.803 352.551 195.829C353.424 199.308 358.794 197.789 357.922 194.349C353.023 174.81 350.131 154.823 349.288 134.697C349.28 133.707 348.756 132.793 347.906 132.286C344.721 130.63 340.752 128.768 338.224 126.161C334.147 121.966 339.204 118.977 342.144 115.753L357.971 98.7306C360.411 96.114 356.481 92.1646 354.051 94.8106L354.07 94.8498Z"
			fill="black"
		/>
		<path
			d="M358.5 86.1376C366.617 91.9395 375.361 96.8081 384.568 100.651C386.528 101.475 388.531 102.249 390.576 102.974C392.585 103.699 395.848 103.954 396.887 106.002C397.563 107.286 397.073 109.56 397.338 111.01C397.618 112.649 398.34 114.18 399.425 115.44C400.787 117.194 403.512 115.988 404.07 114.205C406.177 107.59 414.85 108.962 416.996 114.793C419.26 120.977 414.478 126.553 408.911 128.513C405.56 129.708 406.951 135.089 410.391 133.883C420.387 130.326 427.326 118.889 420.191 109.246C417.467 105.529 412.889 103.655 408.34 104.393C403.791 105.131 400.041 108.357 398.631 112.745L403.276 111.52C401.316 108.962 403.11 104.66 400.797 101.827C398.484 98.9952 393.672 98.27 390.35 97.0254C380.002 93.0867 370.211 87.8156 361.225 81.3454C358.285 79.2678 355.521 84.0894 358.422 86.1572L358.5 86.1376Z"
			fill="black"
		/>
		<path
			d="M358.559 115.312C358.863 115.391 358.853 115.371 358.559 115.234C358.824 115.4 358.824 115.381 358.559 115.234C358.775 115.459 358.765 115.449 358.559 115.234C358.353 115.018 358.432 114.91 358.491 115.106C358.549 115.302 358.491 115.253 358.491 114.93C358.491 114.607 358.549 114.548 358.491 114.871C358.522 114.697 358.621 114.542 358.765 114.44C358.54 114.656 358.549 114.656 358.765 114.44H361.568C362.142 114.742 362.467 115.371 362.382 116.014C362.296 116.657 361.819 117.179 361.186 117.321C362.669 116.913 363.539 115.379 363.131 113.896C362.722 112.413 361.189 111.542 359.706 111.951C358.212 112.437 357.129 113.736 356.92 115.293C356.711 116.85 357.413 118.389 358.726 119.252C359.588 119.771 360.667 119.771 361.529 119.252C363.357 118.114 364.289 115.964 363.871 113.852C363.447 111.933 361.948 110.434 360.029 110.01C359.07 109.746 358.042 110.014 357.334 110.712C356.625 111.411 356.343 112.434 356.594 113.397C356.845 114.36 357.59 115.116 358.549 115.381L358.559 115.312Z"
			fill="black"
		/>
		<path
			d="M378.433 114.959C378.737 115.038 378.737 115.018 378.433 114.881C378.698 115.048 378.698 115.028 378.433 114.881C378.649 115.106 378.639 115.097 378.433 114.881C378.227 114.665 378.306 114.558 378.365 114.754C378.423 114.95 378.365 114.901 378.365 114.577C378.365 114.254 378.423 114.195 378.365 114.518C378.396 114.344 378.495 114.19 378.639 114.087C378.414 114.303 378.423 114.303 378.639 114.087H381.452C382.026 114.391 382.35 115.021 382.262 115.665C382.174 116.309 381.694 116.829 381.06 116.968C382.543 116.56 383.414 115.026 383.005 113.543C382.596 112.06 381.063 111.189 379.58 111.598C378.086 112.084 377.003 113.383 376.794 114.94C376.585 116.497 377.287 118.036 378.6 118.899C379.465 119.418 380.547 119.418 381.412 118.899C383.234 117.756 384.165 115.61 383.755 113.499C383.325 111.58 381.824 110.082 379.903 109.658C378.944 109.393 377.916 109.661 377.208 110.359C376.499 111.058 376.217 112.082 376.468 113.045C376.719 114.008 377.464 114.764 378.423 115.028L378.433 114.959Z"
			fill="black"
		/>
		<path
			d="M349.131 183.354C347.779 199.288 328.395 206.521 316.703 196.094C303.14 183.951 306.354 164.342 311.803 149.112C314.332 142.086 317.125 137.352 311.735 131.208C308.344 127.337 307.893 124.25 309.775 119.36C311.45 114.959 314.038 110.794 316.331 106.698C324.249 92.5958 333.069 77.151 346.642 67.7332C359.274 58.9132 379.698 56.5514 389.997 70.3302C392.114 73.1722 396.955 70.3988 394.809 67.5274C385.009 54.4248 366.82 52.1022 352.316 58.2272C334.402 65.783 323.21 83.7072 313.842 99.8282C309.51 107.286 302.023 117.282 303.013 126.445C303.196 128.269 303.789 130.028 304.747 131.59C305.727 133.178 307.981 134.53 308.667 136.118C310.108 139.283 308.461 142.135 307.354 145.045C306.158 148.191 305.051 151.386 304.12 154.63C299.269 171.496 300.298 192.291 316.39 202.905C332.128 213.273 353.091 201.562 354.61 183.393C354.913 179.816 349.347 179.835 349.043 183.393L349.131 183.354Z"
			fill="black"
		/>
		<path
			d="M397.945 61.5592C399.989 65.2466 401.575 69.1694 402.669 73.2408C403.02 74.4371 404.108 75.2666 405.354 75.289C423.641 76.5434 434.754 95.1438 433.686 112.176C433.078 121.604 429.413 130.639 424.346 138.528C422.181 141.9 416.761 150.867 413.841 143.428C412.283 139.508 412.567 134.834 412.165 130.688C411.822 127.151 406.285 127.121 406.599 130.688C407.216 137.107 407.863 156.786 419.574 151.817C424.836 149.583 428.59 142.546 431.246 137.833C434.461 132.276 436.811 126.261 438.213 119.997C442.937 97.5938 430.266 71.4278 405.393 69.7226L408.078 71.761C406.843 67.2276 405.07 62.8584 402.796 58.7466C401.062 55.6106 396.25 58.4134 397.984 61.5592H397.945Z"
			fill="black"
		/>
		<path
			d="M367.82 137.098C372.26 135.845 376.986 136.058 381.295 137.705C384.666 138.94 386.116 133.56 382.775 132.335C377.497 130.407 371.746 130.195 366.34 131.727C362.89 132.707 364.38 138.058 367.82 137.098V137.098Z"
			fill="black"
		/>
		<path
			d="M350.993 214.665C362.875 220.829 376.514 222.721 389.625 220.025C396.029 218.815 402.116 216.304 407.51 212.646C412.792 208.853 416.536 203.483 420.172 198.201C422.21 195.261 417.388 192.448 415.37 195.388C411.998 200.288 408.51 205.345 403.492 208.667C398.796 211.607 393.59 213.641 388.145 214.665C376.498 216.994 364.405 215.301 353.845 209.863C350.67 208.226 347.848 213.028 351.042 214.665H350.993Z"
			fill="black"
		/>
		<path
			d="M445.073 184.343C465.653 180.316 483.587 194.457 494.583 210.451C506.029 227.111 514.32 246.436 520.661 265.566C527.07 284.94 532.048 305.628 532.734 326.1C533.077 336.41 532.078 346.916 528.167 356.559C524.179 366.418 516.995 374.424 509.861 382.098C493.237 399.996 475.37 416.697 456.392 432.078C453.609 434.332 457.578 438.252 460.312 435.998C478.817 421.011 496.266 404.767 512.536 387.38C520.22 379.148 528.001 370.641 532.607 360.214C536.635 351.081 538.212 341.094 538.35 331.157C538.653 309.176 533.303 286.538 526.59 265.742C519.926 245.162 511.086 224.014 498.483 206.295C485.88 188.577 466.241 174.524 443.603 178.953C440.085 179.639 441.575 185.01 445.083 184.324L445.073 184.343Z"
			fill="black"
		/>
		<path
			d="M435.195 413.761C442.958 427.463 452.114 440.326 462.517 452.148C464.889 454.843 468.809 450.894 466.437 448.228C456.358 436.759 447.491 424.279 439.977 410.988C438.213 407.872 433.401 410.674 435.175 413.801L435.195 413.761Z"
			fill="black"
		/>
		<path
			d="M315.478 354.922C338.9 361.704 363.312 362.605 387.537 363.027C410.773 363.438 434.499 364.007 456.765 356.324C460.126 355.167 458.725 349.787 455.285 350.953C433.47 358.47 410.293 357.813 387.537 357.46C363.792 357.049 339.919 356.206 316.977 349.552C313.528 348.572 312.077 353.932 315.498 354.922H315.478Z"
			fill="black"
		/>
		<path
			d="M174.044 253.071C179.924 258.765 186.402 262.871 189.136 271.034C191.518 278.139 192.821 285.626 195.016 292.8C199.149 306.504 204.823 319.694 211.931 332.118C223.485 351.992 247.838 384.665 274.916 373.709C278.199 372.386 276.768 366.996 273.436 368.338C259.559 373.954 245.555 364.84 236.059 355.128C226.37 344.988 218.295 333.419 212.117 320.828C205.981 308.406 201.16 295.376 197.731 281.951C196.065 275.65 195.085 268.829 191.733 263.126C188.382 257.422 182.698 253.688 178.013 249.141C175.436 246.652 171.487 250.582 174.093 253.061L174.044 253.071Z"
			fill="black"
		/>
		<path
			d="M223.378 226.542C225.25 213.459 210.638 203.894 199.946 212.607C195.644 216.105 192.714 222.612 195.271 227.983C197.721 233.128 204.513 233.863 207.688 229.12C209.648 226.18 204.866 223.338 202.886 226.317C201.426 228.502 199.946 223.328 200.024 222.397C200.186 221.413 200.539 220.471 201.063 219.623C202.541 217.101 205.047 215.351 207.923 214.831C213.705 213.783 218.87 219.3 218.047 225.004C217.547 228.512 222.947 230.031 223.417 226.493L223.378 226.542Z"
			fill="black"
		/>
		<path
			d="M275.739 257.696C284.138 237.293 287.117 205.119 312.901 198.896C316.39 198.054 314.861 192.683 311.421 193.526C283.128 200.386 279.62 233.775 270.369 256.246C269.007 259.558 274.397 260.999 275.739 257.726V257.696Z"
			fill="black"
		/>
		<path
			d="M323.985 256.658C320.123 274.337 316.991 292.15 314.586 310.097C313.41 318.937 312.414 327.799 311.597 336.684C311.225 340.791 307.971 352.09 314.234 353.531C317.722 354.334 319.212 348.964 315.713 348.16C316.429 348.327 316.899 339.683 316.997 338.595C317.33 334.786 317.7 330.981 318.105 327.178C318.954 319.182 319.957 311.208 321.113 303.257C323.322 288.125 326.069 273.099 329.355 258.177C330.119 254.678 324.749 253.188 323.985 256.697V256.658Z"
			fill="black"
		/>
		<path
			d="M260.931 297.484C277.965 315.551 285.934 340.34 282.619 364.948C282.05 368.868 279.992 374.258 284.383 376.806C287.685 378.717 293.869 378.521 297.299 377.051C299.009 376.367 300.469 375.177 301.484 373.64C302.464 372.102 303.052 370.22 304.541 369.005C305.257 368.417 306.443 368.191 307.227 367.633C311.147 364.761 311.019 358.94 314.753 356.137C317.595 354.011 314.822 349.179 311.95 351.335C308.373 354.011 307.795 357.607 305.502 361.184C304.1 363.38 302.219 363.527 300.337 364.967C299.105 366.026 298.152 367.372 297.564 368.887C295.887 372.066 292.103 373.496 288.744 372.219C286.284 371.171 287.813 367.672 288.136 365.281C288.869 360.019 289.099 354.7 288.822 349.395C287.692 328.526 279.198 308.733 264.851 293.535C262.382 290.928 258.452 294.868 260.931 297.455V297.484Z"
			fill="black"
		/>
		<path
			d="M459.558 328.266C458.735 333.715 457.911 339.164 457.088 344.613C456.598 347.896 455.824 351.414 457.48 354.491C460.685 360.469 468.623 358.509 472.68 354.648C476.247 351.257 478.158 347.366 483.176 345.779C488.193 344.191 493.956 343.427 499.179 342.417C510.567 340.212 522.023 338.595 533.656 338.625C537.233 338.625 537.242 333.068 533.656 333.058C516.604 333.058 498.964 335.998 482.48 340.212C479.477 340.88 476.694 342.303 474.395 344.348C471.974 346.582 470.357 349.856 467.535 351.649C461.067 355.804 462.263 347.19 462.841 343.368L464.899 329.746C465.428 326.238 460.067 324.728 459.528 328.266H459.558Z"
			fill="black"
		/>
		<path
			d="M324.582 357.039C322.957 360.997 322.894 365.424 324.406 369.426L326.366 366.006C303.61 373.846 304.071 403.246 303.826 423.022C303.503 446.415 303.708 469.827 304.198 493.22C305.325 547.316 307.824 601.49 313.077 655.361C315.517 680.292 318.457 705.272 323.426 729.841C324.132 733.349 329.502 731.86 328.796 728.361C319.555 682.693 316.321 635.663 313.567 589.201C310.627 539.221 309.069 489.163 309.255 439.114C309.255 427.913 309.069 416.574 309.912 405.392C310.892 392.652 314.185 376.041 327.885 371.337C329.362 370.922 330.234 369.401 329.845 367.917C328.651 364.875 328.715 361.484 330.021 358.489C331.344 355.157 325.964 353.717 324.651 357.01L324.582 357.039Z"
			fill="black"
		/>
		<path
			d="M369.113 479.578C372.729 519.876 373.651 560.418 374.993 600.843C375.709 622.345 376.414 643.846 377.394 665.337C378.247 683.791 378.658 702.577 381.099 720.923C382.363 730.448 386.528 737.74 396.779 739.043C409.901 740.709 423.513 740.68 436.723 740.856C443.479 740.941 450.231 740.925 456.98 740.807C462.037 740.719 469.122 741.679 473.64 738.7C477.217 736.309 477.893 732.301 478.54 728.332C479.334 723.138 480.069 717.934 480.784 712.72C486.89 668.346 491.113 623.697 493.995 578.999C497.229 528.882 498.944 478.53 497.189 428.314C497.062 424.737 491.496 424.728 491.623 428.314C494.612 513.574 488.948 599.677 478.883 684.369C477.674 694.541 476.368 704.7 474.963 714.847C474.336 719.316 473.748 723.794 473.003 728.243C472.425 731.752 471.553 734.506 467.299 734.956C463.938 735.309 460.439 735.172 457.088 735.241C443.904 735.476 430.726 735.312 417.555 734.751C411.773 734.506 405.971 734.28 400.218 733.771C396.759 733.467 393.172 733.202 390.565 730.635C388.39 728.479 387.557 725.088 387.018 722.187C385.45 713.798 385.352 704.91 384.803 696.403C384.195 687.25 383.705 678.087 383.274 668.963C382.294 648.863 381.628 628.783 380.961 608.644C379.521 565.632 378.678 522.492 374.836 479.608C374.513 476.07 368.956 476.031 369.26 479.608L369.113 479.578Z"
			fill="black"
		/>
		<path
			d="M323.887 728.038C321.25 739.19 339.518 738.043 345.937 738.318C359.393 738.825 372.864 737.879 386.117 735.495C389.645 734.878 388.145 729.508 384.637 730.125C375.284 731.791 365.814 732.712 356.315 732.879C353.816 732.879 328.434 732.977 329.257 729.517C330.08 726.058 324.71 724.549 323.887 728.038V728.038Z"
			fill="black"
		/>
		<path
			d="M401.483 737.553L402.463 753.096C402.718 757.271 404.923 766.013 401.992 769.394C400.748 770.824 398.072 771.902 396.495 773.02C394.917 774.137 393.466 775.254 391.996 776.43C389.448 778.468 386.45 780.605 384.666 783.388C374.601 799.068 413.723 795.021 419.054 794.825L447.925 793.747C454.079 793.521 464.066 794.962 468.436 789.346C469.985 787.386 470.318 784.956 470.592 782.486C471.151 777.498 471.504 772.471 471.778 767.463C472.327 757.418 472.366 747.363 471.994 737.318C471.857 733.741 466.29 733.731 466.418 737.318C466.731 745.482 466.77 753.645 466.418 761.818C466.28 765.594 466.075 769.358 465.8 773.108C465.536 776.646 465.8 780.948 464.693 784.29C463.419 788.21 458.911 787.739 455.579 787.867L443.064 788.337L419.005 789.229C411.636 789.503 404.021 790.356 396.661 789.846C394.074 789.66 390.918 789.983 390.262 787.19C389.723 784.887 391.046 784.446 392.722 782.976C395.647 780.43 398.738 778.079 401.973 775.94C404.011 774.588 406.775 773.441 408.029 771.187C409.411 768.708 408.735 765.444 408.568 762.779L407.03 737.524C406.814 733.967 401.248 733.937 401.463 737.524L401.483 737.553Z"
			fill="black"
		/>
		<path
			d="M333.059 735.388L333.304 756.85C333.304 761.044 334.627 766.591 331.285 769.864C329.433 771.687 326.385 772.971 324.18 774.352C320.084 776.93 304.031 786.299 312.9 792.433C316.82 795.109 323.239 795.119 327.747 795.197C334.48 795.324 341.232 795.109 347.975 794.981C354.913 794.844 361.855 794.661 368.8 794.433C374.621 794.237 380.226 794.099 385.528 791.424C388.723 789.817 385.91 785.015 382.716 786.622C377.375 789.307 370.848 788.807 365.017 788.984C359.333 789.16 353.652 789.304 347.975 789.415C342.095 789.523 336.136 789.65 330.217 789.64C327.599 789.681 324.982 789.57 322.377 789.307C320.856 789.263 319.352 788.982 317.918 788.474C316.272 787.651 316.242 788.317 317.056 786.514C318.751 782.751 325.944 779.821 329.247 777.743C332.549 775.666 336.548 773.823 338.135 770.207C340.095 765.758 338.919 759.623 338.861 754.89L338.635 735.378C338.635 731.791 333.03 731.781 333.069 735.378L333.059 735.388Z"
			fill="black"
		/>
		<path
			d="M391.33 736.858C391.82 750.636 389.155 764.298 390.35 778.096C390.654 781.634 396.23 781.673 395.917 778.096C394.741 764.376 397.416 750.656 396.897 736.858C396.769 733.29 391.203 733.271 391.33 736.858Z"
			fill="black"
		/>
		<path
			d="M445.093 757.129C440.007 758.961 436.587 764.057 438.115 769.535C439.623 774.821 445.031 777.977 450.375 776.689C455.55 775.383 459.162 770.708 459.117 765.37C459.221 762.662 458.36 760.005 456.686 757.873C454.726 755.678 451.963 755.316 449.189 755.335C445.612 755.335 445.603 760.931 449.189 760.902C450.601 760.902 451.904 760.706 452.825 762.038C453.447 763.192 453.697 764.51 453.541 765.811C453.449 768.182 451.941 770.265 449.719 771.094C443.897 773.161 440.683 764.626 446.573 762.499C449.924 761.294 448.474 755.913 445.093 757.129V757.129Z"
			fill="#7C839E"
		/>
		<path
			d="M346.456 754.051C348.106 754.195 349.769 754.025 351.356 753.552C352.315 753.287 353.061 752.531 353.312 751.568C353.562 750.605 353.28 749.582 352.572 748.883C351.863 748.184 350.836 747.917 349.876 748.181C349.593 748.271 349.305 748.346 349.014 748.407C348.818 748.407 348.622 748.485 348.426 748.514C348.622 748.514 348.622 748.514 348.426 748.514C347.774 748.575 347.118 748.575 346.466 748.514C344.928 748.525 343.684 749.769 343.673 751.307C343.719 752.829 344.944 754.05 346.466 754.091L346.456 754.051Z"
			fill="#7C839E"
		/>
		<path
			d="M347.23 763.969C348.475 763.563 349.809 763.512 351.081 763.822C352.564 764.231 354.098 763.36 354.507 761.877C354.915 760.394 354.044 758.86 352.561 758.452C350.304 757.878 347.932 757.929 345.701 758.599C342.281 759.637 343.741 765.018 347.181 763.969H347.23Z"
			fill="#7C839E"
		/>
		<path
			d="M346.868 773.044H348.926C350.456 773.028 351.693 771.791 351.709 770.261C351.683 768.733 350.453 767.499 348.926 767.468H346.868C345.832 767.406 344.848 767.924 344.311 768.812C343.775 769.7 343.775 770.812 344.311 771.7C344.848 772.588 345.832 773.105 346.868 773.044Z"
			fill="#7C839E"
		/>
		<path
			d="M416.575 751.993C417.977 752.017 419.349 751.595 420.495 750.788C421.103 750.405 421.555 749.817 421.769 749.132C421.965 748.408 421.867 747.636 421.495 746.986C420.75 745.829 418.937 745.026 417.683 746.006C417.552 746.106 417.414 746.198 417.271 746.28C417.173 746.339 416.722 746.535 417.153 746.358C417.585 746.182 417.085 746.358 417.016 746.358C416.858 746.404 416.698 746.44 416.536 746.466L417.271 746.368C417.052 746.383 416.833 746.383 416.614 746.368C415.139 746.456 413.987 747.678 413.987 749.156C413.987 750.635 415.139 751.857 416.614 751.944L416.575 751.993Z"
			fill="#7C839E"
		/>
		<path
			d="M416.575 760.245H419.045C420.582 760.245 421.828 758.999 421.828 757.462C421.828 755.925 420.582 754.679 419.045 754.679H416.575C415.038 754.679 413.792 755.925 413.792 757.462C413.792 758.999 415.038 760.245 416.575 760.245V760.245Z"
			fill="#7C839E"
		/>
		<path
			d="M414.409 770.447C417.445 772.005 421.143 771.392 423.513 768.938C425.973 766.331 422.043 762.381 419.593 765.018C419.275 765.429 418.806 765.697 418.29 765.762C417.934 765.799 417.574 765.766 417.232 765.664C415.906 764.922 414.231 765.354 413.429 766.644C412.659 767.966 413.096 769.661 414.409 770.447V770.447Z"
			fill="#7C839E"
		/>
		<path
			d="M383.157 310.317C372.113 300.684 359.97 289.816 356.325 275.037C354.267 266.678 356.325 257.005 366.625 256.77C374.465 256.584 380.051 262.738 383.402 269.108C383.908 269.955 384.822 270.473 385.808 270.473C386.794 270.473 387.708 269.955 388.214 269.108C391.526 262.366 397.945 256.839 405.913 257.652C415.948 258.632 419.927 268.03 418.447 277.076C417.046 285.592 411.812 292.756 405.707 298.636C400.925 303.173 390.566 313.718 383.334 311.376C379.913 310.278 378.434 315.658 381.854 316.736C387.116 318.412 391.84 316.266 396.181 313.277C401.171 309.895 405.875 306.11 410.244 301.958C418.28 294.118 424.944 283.475 424.366 271.882C423.876 261.925 417.506 253.654 407.353 252.282C397.024 250.851 387.861 257.309 383.432 266.335H388.253C384.265 258.769 377.552 252.066 368.653 251.302C360.176 250.576 352.13 255.643 350.347 264.159C346.103 284.396 365.821 302.565 379.276 314.306C381.962 316.658 385.921 312.728 383.196 310.386L383.157 310.317Z"
			fill="#7C839E"
		/>
		<path
			d="M410.126 569.003C416.263 567.804 422.494 567.148 428.746 567.043C432.323 566.975 432.333 561.408 428.746 561.477C421.985 561.619 415.25 562.354 408.617 563.672C405.109 564.368 406.598 569.738 410.097 569.042L410.126 569.003Z"
			fill="#D1D4F2"
		/>
		<path
			d="M409.794 585.879C417.634 585.016 425.474 584.539 433.314 584.448C436.9 584.399 436.91 578.833 433.314 578.872C425.474 578.97 417.634 579.45 409.794 580.312C406.275 580.704 406.236 586.271 409.794 585.879V585.879Z"
			fill="#D1D4F2"
		/>
		<path
			d="M411.038 602.49C412.697 602.638 414.357 602.224 415.752 601.314C417.081 600.54 417.53 598.835 416.756 597.506C415.982 596.178 414.278 595.728 412.949 596.502C412.841 596.571 411.969 597.1 412.645 596.727C412.448 596.818 412.241 596.883 412.028 596.923C411.42 597.119 412.537 596.923 411.9 596.923C411.613 596.943 411.325 596.943 411.038 596.923C409.503 596.929 408.26 598.172 408.255 599.707C408.296 601.228 409.517 602.453 411.038 602.5V602.49Z"
			fill="#D1D4F2"
		/>
		<path
			d="M424.728 602.852L430.491 603.715C431.972 604.072 433.476 603.213 433.921 601.755C434.283 600.442 433.47 598.55 431.961 598.325L426.198 597.462C424.716 597.1 423.209 597.961 422.768 599.422C422.406 600.736 423.219 602.617 424.728 602.843V602.852Z"
			fill="#D1D4F2"
		/>
		<path
			d="M327.199 564.515C333.361 565.468 339.584 565.978 345.819 566.044C349.396 566.044 349.406 560.507 345.819 560.477C340.065 560.44 334.321 559.995 328.63 559.144C325.131 558.615 323.612 563.976 327.15 564.515H327.199Z"
			fill="#D1D4F2"
		/>
		<path
			d="M327.307 582.694H335.313C338.9 582.694 338.91 577.127 335.313 577.127H327.277C323.69 577.127 323.681 582.694 327.277 582.694H327.307Z"
			fill="#D1D4F2"
		/>
		<path
			d="M348.337 584.556H350.817C352.293 584.468 353.444 583.246 353.444 581.768C353.444 580.289 352.293 579.067 350.817 578.98H348.337C346.807 578.995 345.57 580.232 345.554 581.763C345.58 583.291 346.81 584.524 348.337 584.556V584.556Z"
			fill="#D1D4F2"
		/>
		<path
			d="M330.413 598.099C336.673 598.509 342.959 598.18 349.141 597.119C352.669 596.522 351.17 591.161 347.661 591.749C341.963 592.689 336.174 592.955 330.413 592.543C326.836 592.298 326.846 597.864 330.413 598.109V598.099Z"
			fill="#D1D4F2"
		/>
		<path
			d="M356.383 367.104C356.079 371.592 356.236 376.58 359.98 379.726C363.723 382.872 369.878 381.931 374.317 381.853C377.394 381.794 380.481 381.647 383.559 381.412C385.715 381.245 389.174 381.598 391.075 380.334C394.29 378.178 392.918 370.318 393.035 367.074C393.143 363.487 387.577 363.497 387.469 367.074C387.371 370.151 387.929 374.258 387.008 377.188L388.968 375.228C384.068 376.786 377.581 376.257 372.504 376.296C370.338 376.296 367.114 376.825 365.066 376.1C361.283 374.777 361.773 370.269 361.989 367.094C362.224 363.517 356.658 363.527 356.422 367.094L356.383 367.104Z"
			fill="#D1D4F2"
		/>
		<path
			d="M335.529 370.426C343.466 373.505 351.808 375.42 360.293 376.11C363.861 376.423 363.841 370.847 360.293 370.543C352.308 369.844 344.466 367.996 337.009 365.055C333.677 363.762 332.226 369.142 335.529 370.426V370.426Z"
			fill="#D1D4F2"
		/>
		<path
			d="M394.897 377.982C399.557 377.755 404.202 377.287 408.813 376.58C413.987 375.786 420.377 373.121 425.532 374.179C429.03 374.895 430.53 369.524 427.012 368.809C422.004 367.78 417.466 369.328 412.557 370.298C406.735 371.428 400.84 372.136 394.917 372.415C391.349 372.601 391.33 378.168 394.917 377.982H394.897Z"
			fill="#D1D4F2"
		/>
		<path
			d="M424.689 365.016L425.199 371.2C425.235 372.722 426.46 373.947 427.982 373.983C429.515 373.972 430.755 372.733 430.765 371.2L430.266 365.016C430.225 363.496 429.002 362.274 427.482 362.233C425.948 362.244 424.705 363.482 424.689 365.016H424.689Z"
			fill="#D1D4F2"
		/>
		<path
			d="M440.007 363.36V369.554C440.007 373.131 445.583 373.14 445.583 369.554V363.36C445.583 359.783 440.007 359.773 440.007 363.36Z"
			fill="#D1D4F2"
		/>
		<path d="M390.507 122.888H396.642V129.022H390.507V122.888Z" fill="#D1D4F2" />
		<path
			d="M42.7774 4.86715C66.1934 2.34354 90.3264 1.27737 112.775 8.97534C135.223 16.6733 155.923 34.6418 162.304 59.3594C168.684 84.0771 156.596 114.566 133.888 121.286C139.213 133.07 146.321 143.791 154.911 152.997C145.17 153.193 137.884 143.93 129.685 138.139C107.093 122.146 76.8574 132.71 50.6365 126.039C27.4893 120.171 7.77437 98.9351 3.7328 73.3664C-0.308772 47.7978 12.6941 19.6664 34.6674 9.68939"
			fill="#D1D4F2"
		/>
		<path
			d="M42.7774 4.86715C66.1934 2.34354 90.3264 1.27737 112.775 8.97534C135.223 16.6733 155.923 34.6418 162.304 59.3594C168.684 84.0771 156.596 114.566 133.888 121.286C139.213 133.07 146.321 143.791 154.911 152.997C145.17 153.193 137.884 143.93 129.685 138.139C107.093 122.146 76.8574 132.71 50.6365 126.039C27.4893 120.171 7.77437 98.9351 3.7328 73.3664C-0.308772 47.7978 12.6941 19.6664 34.6674 9.68939"
			stroke="#7C839E"
			strokeWidth="5"
		/>
		<path
			d="M74.8443 79.2556C81.1553 68.6926 88.4705 58.855 96.565 50.0454C100.768 45.4394 96.218 39.8664 92.0091 44.5089C83.3509 53.9895 75.5276 64.5597 68.7767 75.8989C65.5076 81.3809 71.5624 84.7106 74.8231 79.2438L74.8443 79.2556Z"
			fill="#7C839E"
		/>
		<path
			d="M68.4392 58.3171C81.2286 59.4562 84.9703 80.3272 97.8558 79.0047C99.8962 78.5544 101.186 76.8738 101.025 74.8731C101.071 73.3383 100.237 71.7822 98.8199 70.7619C96.7426 69.0426 93.7864 68.8875 91.9871 70.4033L91.6594 70.6326C87.0639 73.8555 94.4359 80.4658 98.9943 77.2736L99.322 77.0444L92.5045 76.6943L93.0383 77.1094L91.3881 73.9553L91.3643 74.5067L94.5565 70.3706L93.5129 70.4776L97.1907 71.0923C91.1113 67.883 87.6367 60.4009 82.49 56.014C78.3313 52.2863 73.0017 49.9483 67.5528 49.4612C61.3291 48.9093 62.1929 57.7699 68.4093 58.3348L68.4392 58.3171Z"
			fill="#7C839E"
		/>
	</svg>
);
