export const formatDate = (
	date: Date | string,
	locale: Intl.LocalesArgument,
	options?: Intl.DateTimeFormatOptions,
) => {
	const dateObj = typeof date === 'string' ? new Date(date) : date;
	return dateObj.toLocaleDateString(
		locale,
		options || {
			month: '2-digit',
			day: '2-digit',
			year: 'numeric',
		},
	);
};

export const formatTime = (
	date: Date | string,
	locale: Intl.LocalesArgument,
	options?: Intl.DateTimeFormatOptions,
) => {
	const dateObj = typeof date === 'string' ? new Date(date) : date;
	return dateObj.toLocaleTimeString(
		locale,
		options || {
			hour: '2-digit',
			minute: '2-digit',
		},
	);
};
