import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import type { UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { FormState } from 'react-hook-form/dist/types/form';

/**
 * A wrapper around useForm() with new features
 * @param props
 */
export function useFormWrapper<
	TFieldValues extends FieldValues = FieldValues,
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	TContext = any,
>(props?: UseFormProps<TFieldValues, TContext>): UseFormReturn<TFieldValues, TContext> {
	const form = useForm(props);

	// Hooks
	useScrollToErrors(form.formState);

	return form;
}

/**
 * Scroll to error input whenever validation fails on form submission
 */
function useScrollToErrors<TFieldValues extends FieldValues = FieldValues>(
	formState: FormState<TFieldValues>,
) {
	const { errors, isSubmitting } = formState;

	useEffect(() => {
		const firstError = (Object.keys(errors) as Array<keyof typeof errors>).reduce<
			keyof typeof errors | null
		>((field, a) => {
			const fieldKey = field as keyof typeof errors;
			return errors[fieldKey] ? fieldKey : a;
		}, null);

		if (firstError) {
			const firstErrorStr = String(firstError);
			const el = document.querySelector(`[name=${firstErrorStr}]`);
			if (el) {
				el.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [errors, isSubmitting]);
}
