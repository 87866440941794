import { Button, Dialog, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

import { ModalClose } from '../ui';
import {
	StyledDialogContent,
	StyledDialogHeader,
	StyledDialogSubtitle,
	StyledDialogTitle,
} from './invite-user-dialog.styles';
import { FlashSnackbar } from '../snackbars';
import { useApiClientContext, useSnackbar } from '../../hooks';
import { inviteUserAPI } from '../../util';
import { emailValidationRegEx } from '../../util/email-validation-regex';

import type { WorkspaceInvitationUserRoleType } from '@heylog-app/shared/types';
import type { FC } from 'react';

type InviteUserDialogProps = {
	onClose: () => void;
	handleInviteSuccess: () => void;
	isOpen: boolean;
	role: WorkspaceInvitationUserRoleType;
};

type FormData = {
	email: string;
};

export const InviteUserDialog: FC<InviteUserDialogProps> = ({
	onClose,
	isOpen,
	handleInviteSuccess,
	role,
}) => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { workspaceId = '' } = useParams();
	const currentLanguage = i18next.language;

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			email: '',
		},
	});

	const onSubmitInviteUserForm = (data: FormData) => {
		inviteUserAPI(apiClient, {
			data: {
				email: data.email?.toLowerCase(),
				language: currentLanguage,
				role,
			},
			workspaceId,
		})
			.then(() => {
				handleInviteSuccess();
				openSnackbar(
					'success',
					t(`navigation.settingsMenu.${role}.inviteSuccessSnackbar`),
				);
				reset({ email: '' });
				onClose();
			})
			.catch((e) => {
				console.error(e);
				openSnackbar('error', t(`navigation.settingsMenu.${role}.inviteErrorSnackbar`));
			});
	};

	const onFocusEmailField = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			if (event.target.value === '') {
				reset({ email: '' });
			}
		},
		[reset],
	);

	return (
		<>
			<Dialog open={isOpen} onClose={onClose}>
				<StyledDialogContent>
					<StyledDialogHeader>
						<StyledDialogTitle>
							{t(`navigation.settingsMenu.${role}.inviteTeamMember`)}
						</StyledDialogTitle>
						<ModalClose closeModalFn={onClose} />
					</StyledDialogHeader>
					<StyledDialogSubtitle>
						{t(`navigation.settingsMenu.${role}.addEmailTitle`)}
					</StyledDialogSubtitle>

					<form onSubmit={handleSubmit(onSubmitInviteUserForm)} noValidate>
						<Grid container spacing={1}>
							<Grid item xs={9}>
								<Controller
									control={control}
									name="email"
									render={({ field }) => (
										<TextField
											data-test="invite-workspace-user-with-email-field"
											label={t(`navigation.settingsMenu.${role}.addEmailAddress`)}
											fullWidth
											type="email"
											error={!!errors.email}
											helperText={
												errors.email
													? t(`forms.errors.${errors.email?.type ?? 'invalid'}`)
													: null
											}
											placeholder={t(`navigation.settingsMenu.${role}.addEmailAddress`)}
											onFocus={onFocusEmailField}
											{...register('email', {
												required: true,
												pattern: {
													value: emailValidationRegEx,
													message: 'Email is invalid',
												},
											})}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<Button
									data-test="submit-workspace-user-invite-button"
									style={{ height: '100%' }}
									fullWidth
									variant="contained"
									type="submit"
								>
									{t('actionLabels.invite')}
								</Button>
							</Grid>
						</Grid>
					</form>
				</StyledDialogContent>
			</Dialog>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
