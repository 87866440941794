import {
	MessageDirectionEnum,
	MessageStatusEnum,
	MessageTypeEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import type { MessageResInterface } from '@heylog-app/shared/types';

export const staticMockedMessage: MessageResInterface = {
	id: 1,
	conversationId: 1,
	contactId: 1,
	direction: MessageDirectionEnum.TO_CONTACT,
	text: '',
	remoteId: '',
	reaction: null,
	translatedText: '',

	messageType: MessageTypeEnum.TEXT,

	transportType: MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,

	receivedAt: null,
	seenAt: null,
	createdAt: new Date(),
	updatedAt: new Date(),
	user: null,
	contact: null,

	attachments: null,
	conversationLanguage: 'de',
	status: MessageStatusEnum.DELIVERED,
};
