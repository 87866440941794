export const getFullDateTime = (createdAt: Date, conversationLanguage: string) => {
	return new Date(createdAt).toLocaleTimeString(conversationLanguage, {
		weekday: 'long',
		year: 'numeric',
		month: 'short',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit',
		formatMatcher: 'basic',
		hour12: false,
	});
};
