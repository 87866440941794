import { useParams } from 'react-router-dom';
import { Container, Stack, Tabs } from '@mui/material';
import { useState, type FC, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useElementHeight, useOrderActions } from '@heylog-app/frontend-lib/app';

import { ShipmentInfo } from './components/shipment-info';
import { TabPanel } from './tabpanel/tab-panel';
import { ShipmentMessages } from './components/shipment-messages';
import { StyledTab } from './shipment-details.styles';
import { ShipmentDocuments } from './components/shipment-documents';
import { ShipmentHeader } from './components/shipment-header';

import type { LiveLocationDataPointResInterface } from '@heylog-app/shared/types';

export const ShipmentDetails: FC = () => {
	const { shipmentId = '' } = useParams();
	const { order, getLiveLocationData } = useOrderActions(shipmentId);

	const [liveLocationData, setLiveLocationData] = useState<
		LiveLocationDataPointResInterface[]
	>([]);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getLiveLocationData();
			setLiveLocationData(data || []);
		};

		fetchData(); // Call the async function
	}, [getLiveLocationData]);

	const topSectionRef = useRef<HTMLDivElement>(null);
	const paddingBottomPx = 12;
	const { t } = useTranslation();

	const priority = order?.labels?.some(
		(label) => label.type === 'ORDER_PRIORITY' && label.value === 'Urgent',
	);

	const attention = order?.labels?.some(
		(label) => label.type === 'ORDER_ATTENTION' && label.value === 'Attention',
	);

	const internalReference = order?.labels?.find(
		(label) => label.type === 'ORDER_EXTERNAL_REFERENCE',
	)?.value;

	const [value, setValue] = useState(0);

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const topSectionHeight = useElementHeight(topSectionRef);

	return (
		<Container
			maxWidth={false}
			sx={{
				paddingBlock: '2rem',
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Stack direction="column" sx={{ height: '100%' }}>
				<div ref={topSectionRef}>
					<Stack gap={3} direction="column">
						<ShipmentHeader
							order={order}
							internalReference={internalReference}
							shipmentId={shipmentId}
							priority={priority}
							attention={attention}
						/>
						<Tabs
							value={value}
							onChange={handleChange}
							sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
						>
							<StyledTab label={t('shipment.tabs.orderDetails')} value={0} />
							<StyledTab label={t('shipment.tabs.messages')} value={1} />
							<StyledTab label={t('shipment.tabs.documents')} value={2} />
						</Tabs>
					</Stack>
				</div>
				<Stack
					marginTop={2}
					height={`calc(100% - ${topSectionHeight + paddingBottomPx}px)`}
				>
					<TabPanel value={value} index={0}>
						<ShipmentInfo order={order} liveLocationData={liveLocationData} />{' '}
					</TabPanel>

					<TabPanel value={value} index={1}>
						<ShipmentMessages />
					</TabPanel>

					<TabPanel value={value} index={2}>
						<ShipmentDocuments />
					</TabPanel>
				</Stack>
			</Stack>
		</Container>
	);
};
