import { mkenum } from './mkenum';
import { MessagingTransportTypeEnum } from './messaging-transport-type.type';

import type { TypeFromPEnum } from './type-from-p-enum';
import type { MessagingTransportType } from './messaging-transport-type.type';

export const ConversationTypeEnum = mkenum({
	HEYLOG_INTERNAL: 'HEYLOG_INTERNAL',
	HEYLOG_EXTERNAL: 'HEYLOG_EXTERNAL',
	EMAIL: 'EMAIL',
	WHATSAPP: 'WHATSAPP',
	VIBER: 'VIBER',
});

export type ConversationType = TypeFromPEnum<typeof ConversationTypeEnum>;
export function conversationTypeFromMessagingTransportType(
	messagingTransportType: MessagingTransportType,
): ConversationType {
	switch (messagingTransportType) {
		case MessagingTransportTypeEnum.FACEBOOK_WHATSAPP:
			return ConversationTypeEnum.WHATSAPP;
		case MessagingTransportTypeEnum.VIBER:
			return ConversationTypeEnum.VIBER;
		default:
			throw new Error(`Invalid messagingTransportType: ${messagingTransportType}`);
	}
}
