import { Chip, Stack } from '@mui/material';
import dayjs from 'dayjs';

import {
	ChooseOrder,
	DualText,
	colors,
	useAttachmentActions,
} from '@heylog-app/frontend-lib/app';
import { getMediaType, getMediaTypeString } from '@heylog-app/shared/util';

import { StyledTabTitle } from './file-info-tab.styles';

import type { AttachmentRow } from '@heylog-app/frontend-lib/app';
import type { FC } from 'react';

interface FileInfoTabProps {
	attachment: AttachmentRow;
}

export const FileInfoTab: FC<FileInfoTabProps> = ({ attachment }) => {
	const { linkAttachmentToOrder, unlinkAttachmentToOrder } = useAttachmentActions();

	const handleAssign = ({ orderId }: { orderId: string | null }) => {
		if (orderId)
			linkAttachmentToOrder({ attachmentId: attachment.id.toString(), orderId });
	};

	return (
		<Stack>
			<StyledTabTitle>File info</StyledTabTitle>

			<Stack gap={1} paddingBlock={2} borderBottom={`1px solid ${colors.border.main}`}>
				<DualText
					title="File type"
					text={getMediaTypeString(getMediaType(attachment.type))}
				/>
				<DualText
					title="Added"
					text={dayjs(attachment.createdAt).format('DD/MM/YYYY, HH:mm')}
				/>

				<DualText
					title="Status"
					text={attachment.orders.length ? 'Linked' : 'Unlinked'}
				/>
			</Stack>

			<Stack paddingBlock={2} gap={1}>
				<StyledTabTitle>Linked orders</StyledTabTitle>

				<Stack gap={2}>
					<Stack direction="row" flexWrap="wrap" gap={1}>
						{attachment.orders.map((order, index) => (
							<Chip
								key={index}
								label={
									order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
										?.value || order.refNumber
								}
								onDelete={() =>
									unlinkAttachmentToOrder({
										attachmentId: attachment.id.toString(),
										orderId: order.id.toString(),
									})
								}
							/>
						))}
					</Stack>
					<ChooseOrder width="100%" handleAssign={handleAssign} />
				</Stack>
			</Stack>
		</Stack>
	);
};
