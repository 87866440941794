import { Button, Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
	AlertMessage,
	FlashSnackbar,
	isWithinHours,
	parseAxiosErrorMessage,
	ROUTES,
	useApiClientContext,
	useAuthContext,
	useSnackbar,
} from '@heylog-app/frontend-lib/app';

import { FormWrapper } from '../../components/form-wrapper';
import { SignupInImage } from '../../components/ui/sign-in-image';
import {
	StyledButtonContainer,
	StyledFormWrapper,
	StyledMaintitle,
} from './reset-password.styles';
import { resetPasswordAPI } from '../../util';

import type { FC } from 'react';

type FormData = {
	password: string;
	repeatPassword: string;
};

export const ResetPasswordPage: FC = () => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { resetToken = '' } = useParams();
	const { decoded, setAppToken } = useAuthContext();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const createdAt = searchParams.get('createdAt');

	const isWithin1H = isWithinHours(new Date(parseInt(createdAt || '')), 1);

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [passwordPolicyHint, setPasswordPolicyHint] = useState<boolean>(false);

	useEffect(() => {
		if (!isWithin1H) navigate(ROUTES.EXPIRED_RESET_LINK);
	}, [isWithin1H, navigate]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<FormData>();

	useEffect(() => {
		if (decoded) {
			console.log(
				'[Reset-password]: successful password reset, navigating to last_visited_workspace',
			);
			navigate(ROUTES.DASHBOARD.REDIRECT);
		}
	}, [decoded, navigate]);

	const onSubmit = async (data: FormData) => {
		if (!resetToken) return;

		// TODO remove when transition to new password policy is complete
		const passwordPolicy = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,128}$/;
		if (!passwordPolicy.test(data.password)) {
			setPasswordPolicyHint(true);
			return;
		}

		await resetPasswordAPI(apiClient, {
			data: { password: data.password, confirmPassword: data.repeatPassword },
			resetToken,
		})
			.then((res) => {
				setAppToken(res.data);
			})
			.catch((e) => {
				openSnackbar('error', parseAxiosErrorMessage(e));
			});
	};

	return (
		<>
			<Grid
				data-test="reset-password-page"
				container
				sx={{ height: '100vh', overflow: 'hidden' }}
			>
				<Grid item xs={12} md={6}>
					<FormWrapper>
						<Grid alignItems="center" item>
							<StyledFormWrapper>
								<StyledMaintitle>{t('resetPassword.title')}</StyledMaintitle>

								<form onSubmit={handleSubmit(onSubmit)}>
									<TextField
										type="password"
										label={t('signup.personalData.form.labels.password')}
										{...register('password', { required: true })}
										data-test="reset-password-input"
										fullWidth
										margin="dense"
										error={!!errors.password}
										helperText={errors.password && t('forms.errors.required')}
									/>
									<TextField
										type="password"
										label={t('signup.personalData.form.labels.repeatPassword')}
										{...register('repeatPassword', {
											required: true,
											validate: (value) =>
												value !== watch('password')
													? 'signup.form.errors.passwordMatch'
													: true,
										})}
										data-test="reset-repeat-password-input"
										fullWidth
										margin="dense"
										error={!!errors.repeatPassword}
										helperText={
											errors.repeatPassword &&
											(errors.repeatPassword?.message
												? t(errors.repeatPassword.message)
												: t('forms.errors.required'))
										}
									/>

									{passwordPolicyHint && (
										<AlertMessage
											title={t('settings.form.errors.nonCompliantPasswordTitle')}
											message={t('settings.form.errors.nonCompliantPassword')}
										/>
									)}

									<StyledButtonContainer>
										<Button
											data-test="submit-password-button"
											variant="contained"
											type="submit"
											sx={{ mt: 2 }}
											fullWidth
										>
											{t('resetPassword.button')}
										</Button>
									</StyledButtonContainer>
								</form>
							</StyledFormWrapper>
						</Grid>
					</FormWrapper>
				</Grid>
				<Grid item display={{ xs: 'none', md: 'flex' }} md={6}>
					<SignupInImage />
				</Grid>
			</Grid>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
