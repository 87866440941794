import type { AxiosInstance, AxiosResponse } from 'axios';
import type { I18nExceptionResponseInterface } from '@heylog-app/shared/types';
import type { ToggleTodoConversationV2Params } from '../types';

export const toggleTodoConversationV2API = async (
	apiClient: AxiosInstance,
	{ id, workspaceId }: ToggleTodoConversationV2Params,
): Promise<AxiosResponse<I18nExceptionResponseInterface>> => {
	const url = `workspaces/${workspaceId}/conversations-v2/${id}/toggle-todo`;

	return apiClient.post(url);
};
