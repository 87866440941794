import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Icon } from '@mdi/react';
import { useState } from 'react';
import i18next from 'i18next';

import { phoneRegex } from '@heylog-app/shared/util';
import {
	ContactPageLayout,
	getContactLanguage,
	IconViber,
	joinWorkspaceAPI,
	useApiClientContext,
} from '@heylog-app/frontend-lib/app';
import {
	ContactLanguageEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import { ResultMessage } from '../../components';
import {
	StyledButton,
	StyledForm,
	StyledInputAdornment,
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../join.styles';

import type { Nullable } from '@heylog-app/shared/types';
import type { FC } from 'react';

type FormData = {
	phone: string;
};

export const ViberJoinPage: FC = () => {
	const { t } = useTranslation();
	const [result, setResult] = useState<Nullable<boolean>>(null);
	const { apiClient } = useApiClientContext();
	const params = useParams();
	const { joinKey } = params;
	const [searchParams] = useSearchParams();
	const companyName = searchParams.get('name');
	const contactLanguage = ContactLanguageEnum[getContactLanguage(i18next.language)];
	const navigate = useNavigate();
	const orderId = searchParams.get('orderId') || '';

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit = async (data: FormData) => {
		if (!joinKey) return;

		joinWorkspaceAPI(apiClient, {
			data: {
				orderId: parseInt(orderId),
				joinKey: joinKey,
				phoneNumber: data.phone,
				transportType: MessagingTransportTypeEnum.VIBER,
				language: contactLanguage === 'bs' ? 'hr' : contactLanguage,
			},
		})
			.then((res) => {
				setResult(true);

				const context = `ws${res.data.workspaceId}-c${res.data.conversationId}-k${res.data.contactId}`;

				window.location.href = `viber://pa?chatURI=${res.data.whatsappTransportNumber}&context=${context}&text=👍`;
			})
			.catch(() => {
				setResult(false);
			});
	};

	const onFocusPhoneField = (event: React.FocusEvent<HTMLInputElement>) => {
		if (event.target.value === '') {
			reset({ phone: '+' });
		}
	};

	const handleBackClick = () => {
		navigate(-1);
	};

	return (
		<ContactPageLayout>
			<div>
				<StyledTitleWrapper>{t('join.title', { companyName })}</StyledTitleWrapper>
				<StyledTextWrapper>
					<p
						dangerouslySetInnerHTML={{
							__html: t('join.messagingTransport.text', {
								companyName: companyName,
								transportType: 'Viber',
							}),
						}}
					></p>
				</StyledTextWrapper>
				<StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
					<TextField
						data-test="viber-join-input"
						label={t('contacts.form.labels.phone')}
						{...register('phone', {
							required: true,
							pattern: phoneRegex,
						})}
						type="tel"
						fullWidth
						error={!!errors.phone}
						helperText={
							errors.phone ? t(`forms.errors.${errors.phone?.type ?? 'invalid'}`) : null
						}
						placeholder={'+43660...'}
						onFocus={onFocusPhoneField}
						InputProps={{
							startAdornment: (
								<StyledInputAdornment position="start">
									<IconViber />
								</StyledInputAdornment>
							),
						}}
					/>
					<StyledButton
						variant="contained"
						color="primary"
						onClick={handleSubmit(onSubmit)}
						endIcon={<Icon path={mdiChevronRight} size={'2em'} />}
					/>
				</StyledForm>

				{result !== null && <ResultMessage result={result} transportType="Viber" />}
			</div>
			<div>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleBackClick}
					startIcon={<Icon path={mdiChevronLeft} size={'1.2em'} />}
				>
					{t('actionLabels.back')}
				</Button>
			</div>
		</ContactPageLayout>
	);
};
