import type { Maybe, OrderResInterface } from '@heylog-app/shared/types';

type MiscOrderDataType = {
	totalWeight?: string;
	volumeOfOrder?: string;
};

export const getMiscOrderData = (order?: OrderResInterface): MiscOrderDataType => {
	const { miscData } = order || {};
	const miscDataJson: Maybe<MiscOrderDataType> =
		typeof miscData === 'string' && JSON.parse(miscData);

	return { ...miscDataJson };
};
