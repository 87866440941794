import { useEffect, useRef } from 'react';

import type { Nullable } from '@heylog-app/shared/types';

type KeyValueObject = { [key: string]: unknown };

// given a name and the component props
// this method will show you what prop did update
export const useWhyDidYouUpdate = <Props extends KeyValueObject>(
	name: string,
	props: Props,
) => {
	const previousProps = useRef<Nullable<Props>>(null);

	useEffect(() => {
		if (previousProps.current) {
			const allKeys = Object.keys({ ...previousProps.current, ...props });
			const changesObj: KeyValueObject = {};
			allKeys.forEach((key) => {
				if (previousProps.current && previousProps.current[key] !== props[key]) {
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					};
				}
			});

			if (Object.keys(changesObj).length) {
				console.log(`[${name}]:`, changesObj);
			} else {
				console.log(`[${name}]: No changes.`, Date.now());
			}
		}

		previousProps.current = props;
	});
};

// given a name, this method will show you, if the given value has updated
export const useCompareValueToPrev = <T>(name: string, value: T) => {
	const prevVal = useRef<Nullable<T>>(null);
	const label = `[prev-value-checker][${name}]:`;

	useEffect(() => {
		if (!!prevVal.current && prevVal.current !== value)
			console.log(`%c${label} value changed`, 'color: #e94d46');
		else {
			console.log(`%c${label} value is the same`, 'color: green');
		}

		prevVal.current = value;
	});
};

type LogType = Parameters<Console['log']>;

// due to the fact, that react calls component functions more times than it renders them
// this hook exists to consistently render smth after the rendering cycle
export const useLog = (...parameters: LogType) => {
	useEffect(() => {
		console.log(`[Log]:`, ...parameters);
	});
};
