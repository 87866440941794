import { Trans, useTranslation } from 'react-i18next';
import {
	createSearchParams,
	generatePath,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import i18next from 'i18next';
import { useState } from 'react';
import { Button } from '@mui/material';

import {
	ContactPageLayout,
	FlashSnackbar,
	getContactLanguage,
	joinWorkspaceAPI,
	ROUTES,
	useApiClientContext,
	useSnackbar,
	useWorkspaceByJoinKey,
} from '@heylog-app/frontend-lib/app';
import {
	ContactLanguageEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import { MessengerButton } from '../../components';
import {
	StyledMessengerButtons,
	StyledPrivacyNoticeWrapper,
	StyledProceedButtonContainer,
	StyledTextWrapper,
	StyledTitleWrapper,
} from './join.styles';

import type { FC } from 'react';
import type { MessagingTransportType } from '@heylog-app/shared/types';

export const JoinPage: FC = () => {
	const { apiClient } = useApiClientContext();
	const [searchParams] = useSearchParams();
	const { t } = useTranslation();
	const { joinKey = '' } = useParams();
	const workspace = useWorkspaceByJoinKey(apiClient, joinKey).workspace;
	const workspaceName = workspace && (workspace.name || '');
	const phone = searchParams.get('phone') || '';
	const orderId = searchParams.get('orderId') || '';
	const orderReference = searchParams.get('orderReference') || undefined;
	const source = searchParams.get('source') || undefined;
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [deeplink, setDeeplink] = useState('');
	const contactLanguage = ContactLanguageEnum[getContactLanguage(i18next.language)];
	const navigate = useNavigate();
	const [transportTypeTitle, setTransportTypeTitle] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleMessagingTransportTypeClick = async (
		joinKey: string,
		transportType: MessagingTransportType,
		phone?: string,
	) => {
		if (isSubmitting) return;
		setIsSubmitting(true);
		if (transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP) {
			setTransportTypeTitle('WhatsApp');
		}
		if (transportType === MessagingTransportTypeEnum.VIBER) {
			setTransportTypeTitle('Viber');
		}
		if (phone?.length) {
			joinWorkspaceAPI(apiClient, {
				data: {
					joinKey,
					phoneNumber: phone,
					transportType,
					language: contactLanguage === 'bs' ? 'hr' : contactLanguage,
					orderId:
						transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP
							? parseInt(orderId)
							: undefined,
					orderReference:
						transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP
							? orderReference
							: undefined,
					source:
						transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP
							? source
							: undefined,
				},
			})
				.then((res) => {
					if (res.data.slug === 'duplicated_contact') {
						setDeeplink(`https://wa.me/${res.data.whatsappTransportNumber}`);
						openSnackbar('success', t('join.errorMessage.duplicatedNumber'));

						return;
					} else if (transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP) {
						openSnackbar(
							'success',
							t('join.messagingTransport.successDetails', {
								transportType: transportTypeTitle,
							}),
							true,
							2000,
						);

						setTimeout(() => {
							window.location.href = `https://wa.me/${res.data.whatsappTransportNumber}`;
						}, 2000);
					} else if (transportType === MessagingTransportTypeEnum.VIBER) {
						openSnackbar(
							'success',
							t('join.messagingTransport.successDetails', {
								transportType: transportTypeTitle,
							}),
							true,
							2000,
						);
						const context = `ws${res.data.workspaceId}-c${res.data.conversationId}-k${res.data.contactId}`;

						setTimeout(() => {
							window.location.href = `viber://pa?chatURI=${res.data.whatsappTransportNumber}&context=${context}&text=👍`;
						}, 2000);
					}
				})
				.catch(() => {
					openSnackbar(
						'error',
						t('join.messagingTransport.errorTitle', {
							transportType: transportTypeTitle,
						}),
					);
				})
				.finally(() => {
					setIsSubmitting(false);
				});
		} else {
			const path =
				transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP
					? ROUTES.JOIN.WHATSAPP
					: ROUTES.JOIN.VIBER;

			navigate({
				pathname: generatePath(path, { joinKey }),
				search: createSearchParams({
					name: workspaceName || '',
					orderId: orderId || '',
					orderReference: orderReference || '',
					source: source || '',
				}).toString(),
			});
		}
	};

	const handleDeeplinkClick = () => {
		window.location.href = deeplink;
	};

	return (
		<ContactPageLayout>
			<div>
				<StyledTitleWrapper>
					{t('join.title', { companyName: workspaceName })}
				</StyledTitleWrapper>
				<StyledTextWrapper>
					<p>
						<Trans
							i18nKey="join.text"
							values={{ companyName: workspaceName }}
							components={[<span data-test="workspace-name" />]}
						/>
					</p>
					<p>{t('join.selectTransportType')}</p>
				</StyledTextWrapper>
				<StyledMessengerButtons>
					<MessengerButton
						type="FACEBOOK_WHATSAPP"
						text={t(`join.transportTypes.FACEBOOK_WHATSAPP`)}
						onClick={() =>
							handleMessagingTransportTypeClick(
								joinKey,
								MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,
								phone,
							)
						}
						data-test="join-whatsapp-button"
						disabled={isSubmitting}
					/>
				</StyledMessengerButtons>

				{source !== 'timematters' && (
					<StyledMessengerButtons>
						<MessengerButton
							type="VIBER"
							text={t(`join.transportTypes.VIBER`)}
							onClick={() =>
								handleMessagingTransportTypeClick(
									joinKey,
									MessagingTransportTypeEnum.VIBER,
									phone,
								)
							}
							data-test="join-viber-button"
							disabled={isSubmitting}
						/>
					</StyledMessengerButtons>
				)}

				{deeplink && (
					<StyledProceedButtonContainer>
						<Button variant="contained" color="primary" onClick={handleDeeplinkClick}>
							{t('join.proceedAnyway')}
						</Button>
					</StyledProceedButtonContainer>
				)}

				{/* {result !== null && (
					<ResultMessage result={result} transportType={transportTypeTitle} />
				)} */}

				<FlashSnackbar
					controls={[stateSnackbar, openSnackbar, closeSnackbar]}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				/>

				<StyledPrivacyNoticeWrapper
					dangerouslySetInnerHTML={{
						__html: t('join.dataPrivacy', { companyName: workspaceName }),
					}}
				></StyledPrivacyNoticeWrapper>
			</div>
		</ContactPageLayout>
	);
};
