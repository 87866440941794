import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BarChart } from '../bar-chart/bar-chart';

const barChartResponseList = new Array(31).fill(1).map((_, idx) => ({
	value: Math.round(Math.random() * 50),
	date: new Date(new Date().setDate(idx + 1)).toISOString(),
}));

const barChartReponse = {
	list: barChartResponseList,
	total: 999,
	difference: -4,
};

export const DashboardOrdersChart = () => {
	// make some custom hook request...
	const parsedList = barChartReponse.list.map((item) => ({
		value: item.value,
		date: dayjs(item.date),
		label: dayjs(item.date).get('date'),
	}));
	const { t } = useTranslation();

	return (
		<BarChart
			total={barChartReponse.total}
			renderData={parsedList}
			title={t('dashboardMain.ordersImported')}
			difference={{
				isGrowing: barChartReponse.difference >= 0,
				value: barChartReponse.difference,
			}}
		/>
	);
};
