import styled from 'styled-components';

import {
	SIZE_LG,
	SIZE_MD,
	SIZE_SM,
	STYLE_1,
	STYLE_3,
} from '@heylog-app/frontend-lib/app';

export const StyledTitleWrapper = styled.h1`
	${STYLE_3};
	${SIZE_LG};
	line-height: 1.8rem;
	margin-bottom: 1rem;
`;

export const StyledTextWrapper = styled.div`
	${STYLE_1};
	${SIZE_MD};
	line-height: 1.4rem;
	margin-bottom: 2rem;
`;

export const StyledMessengerButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
	margin-bottom: 16px;
`;

export const StyledPrivacyNoticeWrapper = styled.div`
	margin-top: 2rem;
	${STYLE_1};
	${SIZE_SM};
	color: ${({ theme }) => theme.palette.common.blackTransparent};

	a {
		color: inherit;
	}
`;

export const StyledProceedButtonContainer = styled.div`
	margin-top: 4rem;
	display: flex;
	justify-content: center;
	align-items: center;
`;
