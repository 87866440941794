import type { FC } from 'react';

export const IconUk: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M38 9.31201C29.89 11.404 22.776 15.944 17.514 22.138L38 34.662V9.31201Z"
			fill="#3F51B5"
		/>
		<path
			d="M17.21 73.5279C22.498 79.8979 29.74 84.5579 38 86.6899V60.8199L17.21 73.5279Z"
			fill="#3F51B5"
		/>
		<path
			d="M9.31201 58C9.33801 58.104 9.36401 58.206 9.39201 58.31L9.89601 58H9.31201Z"
			fill="white"
		/>
		<path
			d="M79.152 22.938C73.832 16.336 66.456 11.492 58 9.31201V35.866L79.152 22.938Z"
			fill="#3F51B5"
		/>
		<path
			d="M58 86.688C66.606 84.468 74.082 79.482 79.422 72.7L58 59.608V86.688Z"
			fill="#3F51B5"
		/>
		<path
			d="M9.50601 37.2419C9.43601 37.4919 9.37601 37.7479 9.31201 37.9999H10.746L9.50601 37.2419Z"
			fill="white"
		/>
		<path
			d="M87.502 42.0001C87.312 40.7401 87.066 39.5021 86.762 38.2841L80.684 42.0001H87.502Z"
			fill="white"
		/>
		<path
			d="M10.746 38H9.31199C8.97399 39.31 8.70399 40.642 8.49799 42H17.29L10.746 38Z"
			fill="white"
		/>
		<path
			d="M86.88 57.258C87.134 56.188 87.336 55.1 87.502 54H81.548L86.88 57.258Z"
			fill="white"
		/>
		<path
			d="M16.44 54H8.49799C8.70199 55.358 8.97399 56.69 9.31199 58H9.89799L16.44 54Z"
			fill="white"
		/>
		<path
			d="M42 37.106V8.49805C40.642 8.70205 39.31 8.97405 38 9.31205V34.662L42 37.106Z"
			fill="white"
		/>
		<path
			d="M54 57.1641V87.5021C55.358 87.2981 56.69 87.0261 58 86.6881V59.6081L54 57.1641Z"
			fill="white"
		/>
		<path
			d="M38 60.82V86.688C39.31 87.026 40.642 87.296 42 87.502V58.376L38 60.82Z"
			fill="white"
		/>
		<path
			d="M54.37 37.388L54.938 37.736L58 35.864V9.31205C56.69 8.97405 55.358 8.70405 54 8.49805V37.994L54.37 37.388Z"
			fill="white"
		/>
		<path
			d="M17.29 41.9999H36.07L42 38.3759V37.1079L38 34.6619L17.514 22.1379C15.806 24.1499 14.288 26.3259 12.998 28.6519L28.1 37.9999H17.188L10.602 33.9059C10.19 34.9979 9.822 36.1079 9.504 37.2419L10.746 37.9999L17.29 41.9999Z"
			fill="white"
		/>
		<path
			d="M54.938 37.736L61.914 42H80.684L86.762 38.284C86.112 35.684 85.212 33.19 84.08 30.82L72.556 38H61.644L81.268 25.8C80.606 24.812 79.894 23.862 79.15 22.938L58 35.864L54.938 37.736Z"
			fill="white"
		/>
		<path
			d="M28.1 38L13 28.652C12.07 30.33 11.288 32.092 10.604 33.906L17.188 38H28.1Z"
			fill="#E53935"
		/>
		<path
			d="M72.556 38L84.082 30.82C83.252 29.08 82.334 27.392 81.27 25.802L61.644 38H72.556Z"
			fill="#E53935"
		/>
		<path
			d="M54 54V57.164L58 59.608L79.422 72.702C80.966 70.74 82.334 68.636 83.494 66.402L69.942 58H80.854L85.772 61.068C86.202 59.824 86.572 58.556 86.878 57.26L81.548 54H54Z"
			fill="white"
		/>
		<path
			d="M61.914 41.9999L54.938 37.7359L54.37 37.3879L54 37.9939V41.9999H61.914Z"
			fill="white"
		/>
		<path
			d="M42 58.376V54H16.44L9.89601 58L9.39001 58.31C10.094 60.952 11.054 63.484 12.256 65.882L24.658 58H35.57L15.206 70.866C15.842 71.778 16.502 72.674 17.21 73.528L38 60.82L42 58.376Z"
			fill="white"
		/>
		<path d="M36.07 42H42V38.376L36.07 42Z" fill="white" />
		<path
			d="M69.942 58L83.492 66.4C84.38 64.692 85.134 62.908 85.772 61.066L80.854 58H69.942Z"
			fill="#E53935"
		/>
		<path
			d="M24.658 58L12.256 65.882C13.126 67.618 14.102 69.286 15.206 70.866L35.57 58H24.658Z"
			fill="#E53935"
		/>
		<path
			d="M61.914 42H54V54H81.548H87.502C87.798 52.038 88 50.044 88 48C88 45.956 87.798 43.962 87.502 42H80.684H61.914Z"
			fill="#E53935"
		/>
		<path
			d="M42 54V42H36.07H17.29H8.498C8.202 43.962 8 45.956 8 48C8 50.044 8.202 52.038 8.498 54H16.44H42Z"
			fill="#E53935"
		/>
		<path
			d="M54 87.502V57.164V54V42V37.994V8.498C52.038 8.202 50.044 8 48 8C45.956 8 43.962 8.202 42 8.498V37.106V38.374V42V54V58.376V87.502C43.962 87.798 45.956 88 48 88C50.044 88 52.038 87.798 54 87.502Z"
			fill="#E53935"
		/>
	</svg>
);
