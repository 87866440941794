import { FormControlLabel, Switch } from '@mui/material';
import styled from 'styled-components';

import { T2, colors } from '../../../styles';

export const StyledSwitch = styled(Switch)(({ theme }) => ({
	width: 34,
	height: 20,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(11px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 3,
		'&.Mui-checked': {
			transform: 'translateX(14px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: colors.primary.main,
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 14,
		height: 14,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 24 / 2,
		opacity: 1,
		backgroundColor: colors.grey[400],
		boxSizing: 'border-box',
	},
}));

export const StyledFormControlLabel = styled(FormControlLabel)`
	justify-content: space-between;
	width: 100%;
	margin: 0;

	span {
		${T2}
	}
`;
