import * as PusherPushNotifications from '@pusher/push-notifications-web';

import type { Maybe } from '@heylog-app/shared/types';
import type { Environment, UserAuthContext } from '../types';

const pLog = (...args: unknown[]) => console.log('[PusherBeamsInit]:', ...args);
const pError = (...args: unknown[]) => console.error('[PusherBeamsInit]:', ...args);

const handleBeamsWithoutToken = ({
	beamsClient,
}: {
	beamsClient: PusherPushNotifications.Client;
}) => {
	beamsClient.stop().catch(() => pError("Can't build beams instance without token"));
	return beamsClient;
};

type BeamsBuildParams = {
	beamsClient: PusherPushNotifications.Client;
	token: string;
	API_URL: string;
	PUSHER_BEAMS_AUTH_ENDPOINT: string;
	currWs: Maybe<string>;
};

const handleBeamsWithToken = async ({
	beamsClient,
	API_URL,
	token,
	PUSHER_BEAMS_AUTH_ENDPOINT,
	currWs,
}: BeamsBuildParams) => {
	const beamsTokenProvider = new PusherPushNotifications.TokenProvider({
		url: `${API_URL}/${PUSHER_BEAMS_AUTH_ENDPOINT}/${currWs}`,
		headers: {
			...(token && { Authorization: `Bearer ${token}` }),
		},
	});

	try {
		await beamsClient.start();
		await beamsClient.setUserId(`${currWs}`, beamsTokenProvider);
		pLog('Successfully registered and subscribed!');
	} catch (e) {
		pError('Fail to register and subscribe to Beams', e);
	}
};

export const buildBeamsInstance = (
	{ PUSHER_BEAMS_AUTH_ENDPOINT, API_URL, PUSHER_BEAMS_ID }: Environment,
	{ token, decoded }: UserAuthContext,
	workspaceId: Maybe<string>,
) => {
	try {
		pLog('building beams instance');

		const beamsClient = new PusherPushNotifications.Client({
			instanceId: PUSHER_BEAMS_ID,
		});

		//if there is no token, stop beams connection as well
		token && decoded
			? handleBeamsWithToken({
					beamsClient,
					token,
					PUSHER_BEAMS_AUTH_ENDPOINT,
					API_URL,
					currWs: workspaceId,
			  })
			: handleBeamsWithoutToken({ beamsClient });

		return beamsClient;
	} catch {
		pLog('Pusher beams initialization failed');
		return;
	}
};
