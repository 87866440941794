import type { AxiosInstance } from 'axios';

export type OrganizationSetting = [
	{
		settingKey: string;
		settingValue: string;
		id: number;
		resourceId: number;
		resourceType: string;
		createdAt: string;
		updatedAt: string;
	},
];

export type OrganisationSettingsUpdateType = [
	{
		settingKey: string;
		settingValue: { emailAddresses: string[] };
	},
];

export const updateOrganizationSettingsEmailsAPI = async (
	apiClient: AxiosInstance,
	organizationId: number,
	data: OrganisationSettingsUpdateType,
) => {
	return apiClient.put(`/settings/organization/${organizationId}`, data);
};
