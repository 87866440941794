import styled from 'styled-components';

import { T3, colors } from '../../styles';

export const StyledEmptyText = styled.div`
	${T3};
	color: ${colors.grey[500]};
	text-align: center;
	margin-top: 8px;
`;
