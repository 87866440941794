import type { AcceptUserInviteEntryParams, CreateEntryParams } from '../types';
import type {
	ArchiveWorkspaceInvitationsReqInterface,
	ArchiveWorkspaceInvitationsResInterface,
	CreateWorkspaceInvitationReqInterface,
	WorkspaceInvitationResInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance, AxiosResponse } from 'axios';

export const inviteUserAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateWorkspaceInvitationReqInterface>,
): Promise<AxiosResponse<WorkspaceInvitationResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations`, data);
};

export const acceptUserInvitationAPI = async (
	apiClient: AxiosInstance,
	{ workspaceId, invitationId }: AcceptUserInviteEntryParams,
): Promise<AxiosResponse<WorkspaceInvitationResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations/${invitationId}/accept`);
};

export const archiveUserInvitationAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<ArchiveWorkspaceInvitationsReqInterface>,
): Promise<AxiosResponse<ArchiveWorkspaceInvitationsResInterface>> => {
	return apiClient.post(`workspaces/${workspaceId}/invitations/archive`, data);
};
