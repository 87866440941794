import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';

import { FileIcons } from '../file-icons';
import {
	StyledColumn,
	StyledRemoveButton,
	StyledUploadedFile,
} from './uploaded-files.styles';

import type { FC } from 'react';

type UploadedFileProps = {
	file: File;
	removeFile: (file: File) => void;
	fileError: boolean;
};

export const UploadedFile: FC<UploadedFileProps> = ({ fileError, file, removeFile }) => {
	const handleClick = useCallback(() => removeFile(file), [file, removeFile]);
	return (
		<StyledUploadedFile data-test="uploaded-file" $fileError={fileError}>
			<FileIcons type={file.type} />
			<StyledColumn>
				<span>{file.name}</span>
			</StyledColumn>
			<StyledRemoveButton data-test="file-remove-button">
				<CloseIcon onClick={handleClick} />
			</StyledRemoveButton>
		</StyledUploadedFile>
	);
};
