import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { DoughnutChart } from '../doughnut-chart/doughnut-chart';
import { colors } from '../../styles';

interface StatusLabel {
	status: string;
	text: string;
	hexColor: string;
}

interface StatusLabelLanguages {
	en: StatusLabel[];
	de: StatusLabel[];
}

const statusLabels: StatusLabelLanguages = {
	en: [
		{ status: UserOrderStatusEnum.CREATED, text: 'Created', hexColor: '#d0d5ed' },
		{
			status: UserOrderStatusEnum.ACCEPTED,
			text: 'Accepted by driver',
			hexColor: '#afb8e1',
		},
		{
			status: UserOrderStatusEnum.APPROACHING,
			text: 'Approaching loading location',
			hexColor: '#8c9ad5',
		},
		{
			status: UserOrderStatusEnum.ARRIVED_AT_LOADING,
			text: 'Arrived at loading location',
			hexColor: '#6f81cc',
		},
		{ status: UserOrderStatusEnum.LOADED, text: 'Loaded', hexColor: '#5068c2' },
		{
			status: UserOrderStatusEnum.DEPARTED_FROM_LOADING,
			text: 'Departed from loading location',
			hexColor: '#4960ba',
		},
		{ status: UserOrderStatusEnum.IN_TRANSIT, text: 'In transit', hexColor: '#122cc5' },
		{
			status: UserOrderStatusEnum.ARRIVED_AT_UNLOADING,
			text: 'Arrived at unloading location',
			hexColor: '#354aa4',
		},
		{ status: UserOrderStatusEnum.DELIVERED, text: 'Delivered', hexColor: '#233591' },
		{ status: UserOrderStatusEnum.PROBLEM, text: 'Problem', hexColor: '#df2626' },
		{
			status: UserOrderStatusEnum.ARCHIVED,
			text: 'Archived',
			hexColor: colors.grey[600],
		},
	],
	de: [
		{ status: UserOrderStatusEnum.CREATED, text: 'Erstellt', hexColor: '#d0d5ed' },
		{
			status: UserOrderStatusEnum.ACCEPTED,
			text: 'Vom Fahrer akzeptiert',
			hexColor: '#afb8e1',
		},
		{
			status: UserOrderStatusEnum.APPROACHING,
			text: 'Annäherung an den Ladeort',
			hexColor: '#8c9ad5',
		},
		{
			status: UserOrderStatusEnum.ARRIVED_AT_LOADING,
			text: 'Am Ladeort angekommen',
			hexColor: '#6f81cc',
		},
		{ status: UserOrderStatusEnum.LOADED, text: 'Geladen', hexColor: '#5068c2' },
		{
			status: UserOrderStatusEnum.DEPARTED_FROM_LOADING,
			text: 'Abfahrt vom Ladeort',
			hexColor: '#4960ba',
		},
		{ status: UserOrderStatusEnum.IN_TRANSIT, text: 'Unterwegs', hexColor: '#122cc5' },
		{
			status: UserOrderStatusEnum.ARRIVED_AT_UNLOADING,
			text: 'Am Entladeort angekommen',
			hexColor: '#354aa4',
		},
		{ status: UserOrderStatusEnum.DELIVERED, text: 'Geliefert', hexColor: '#233591' },
		{ status: UserOrderStatusEnum.PROBLEM, text: 'Problem', hexColor: '#df2626' },
		{
			status: UserOrderStatusEnum.ARCHIVED,
			text: 'Archiviert',
			hexColor: colors.grey[600],
		},
	],
};

const doughnutServerStatusesList = Object.values(UserOrderStatusEnum).map((value) => ({
	status: value,
	total: Math.round(Math.random() * 100),
	urgent: Math.round(Math.random() * 33),
}));

export const DashboardShipmentsChart = () => {
	// make some custom hook request...
	const currentLanguage: keyof StatusLabelLanguages =
		i18next.language as keyof StatusLabelLanguages;
	const doughnutServerResponse = {
		list: doughnutServerStatusesList,
		total: 555,
		totalUrgent: 45,
	};
	const { t } = useTranslation();

	const doughnutRenderData = doughnutServerResponse.list.map((item) => {
		const matchedStatusItem = statusLabels[currentLanguage].find(
			({ status }: StatusLabel) => item.status === status,
		);

		return {
			label: {
				text: matchedStatusItem?.text || '',
				hexColor: matchedStatusItem?.hexColor || '',
			},
			value: {
				total: item.total,
				urgent: item.urgent,
			},
		};
	});

	return (
		<DoughnutChart
			title={t('dashboardMain.shipments')}
			height={250}
			total={{
				value: doughnutServerResponse.total,
				urgent: doughnutServerResponse.totalUrgent,
			}}
			renderData={doughnutRenderData}
		/>
	);
};
