import { useContext } from 'react';

import { ApiClientContext } from '../providers';

import type { ApiClientContextType } from '../types';

export const useApiClientContext = (): ApiClientContextType => {
	const context = useContext(ApiClientContext);

	if (!context)
		throw new Error(
			'This hook must be used inside the api client provider context provider',
		);

	return context;
};
