import styled from 'styled-components';

import { SIZE_SM, STYLE_1 } from '../../../styles';

export const StyledConversationInactiveNotice = styled.div`
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	span {
		${STYLE_1};
		${SIZE_SM};
		margin-bottom: 0.75rem;
		text-align: center;
		display: block;
		color: ${({ theme }) => theme.palette.greyblue[112]};
	}
`;
