import { useCallback } from 'react';
import {
	Chip,
	Dialog,
	DialogTitle,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	MenuItem,
} from '@mui/material';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { mdiAccountRemove, mdiPencil, mdiTruck } from '@mdi/js';
import Icon from '@mdi/react';

import { ContactRoleEnum, MessagingTransportTypeEnum } from '@heylog-app/shared/types';
import { getRoleIcon } from '@heylog-app/shared/util';

import { colors } from '../../../styles/theme/colors';
import {
	StyledContactDetail,
	StyledContactLabel,
	StyledContactName,
	StyledContactDetailIcon,
} from './contact-list-item.styles';
import { SidebarListItem } from '../../sidebar';
import { ButtonMenu, ContactName, ConversationAvatar, ModalClose } from '../../ui';
import { useApiClientContext, useDialog, useMenu } from '../../../hooks';
import { ROUTES, disconnectContactFromOrderAPI } from '../../../util';
import { EditContactForm } from '../../forms/edit-contact-form/edit-contact-form';

import type { FullContactResInterface } from '@heylog-app/shared/types';
import type { ListItemProps } from '@mui/material';
import type { FC } from 'react';

type ContactListItemProps = Omit<ListItemProps, 'onClick'> & {
	contact: FullContactResInterface;
	unreadCount: number;
	onSelectContact: (contact: FullContactResInterface) => void;
};

const StyledChip = styled(Chip)`
	margin-left: 5px;
	border-radius: 4px;
	background-color: ${colors.error.main};
	color: ${colors.error.light};
`;

export const ContactListItem: FC<ContactListItemProps> = ({
	contact,
	unreadCount,
	onSelectContact,
	...props
}) => {
	const { apiClient } = useApiClientContext();
	const { contactId = '', orderId = '', workspaceId = '' } = useParams();
	const { t } = useTranslation();

	const messagingNetwork = contact.conversations?.[0]?.transportType;

	const { id, company } = contact;

	const isActive = contactId === id?.toString();
	const showDecoration: boolean =
		contact.role !== ContactRoleEnum.NONE && !!contact.company;
	const roleIcon = getRoleIcon(contact.role);

	const menuControl = useMenu();

	const handleItemClick = useCallback(() => {
		onSelectContact(contact);
	}, [contact, onSelectContact]);

	const navigate = useNavigate();

	const handleDisconnectContact = useCallback(() => {
		disconnectContactFromOrderAPI(apiClient, {
			orderId: parseInt(orderId),
			workspaceId,
			id: contact.id.toString(),
		}).then(() => {
			menuControl.closeMenu();

			navigate(
				generatePath(ROUTES.ORDERS.DETAIL, {
					workspaceId,
					orderId,
				}),
				{ replace: true },
			);
		});
	}, [apiClient, orderId, workspaceId, contact.id, menuControl, navigate]);

	const {
		showDialog: showEditDialog,
		openDialog: openEditDialog,
		closeDialog: closeEditDialog,
	} = useDialog();

	const onEditSuccess = useCallback(() => {
		closeEditDialog();
		menuControl.closeMenu();
	}, [closeEditDialog, menuControl]);

	return (
		<SidebarListItem
			{...props}
			onClick={handleItemClick}
			active={isActive}
			secondaryAction={
				orderId && (
					<>
						<ButtonMenu
							id={`sidebar-contact-menu-${id}`}
							dataTest="contact-order-menu-button"
							control={menuControl}
							active={isActive}
						>
							<MenuItem onClick={openEditDialog}>
								<ListItemIcon>
									<Icon path={mdiPencil} size={'1em'} />
								</ListItemIcon>
								{t('actionLabels.edit')}
							</MenuItem>
							<MenuItem onClick={handleDisconnectContact}>
								<ListItemIcon>
									<Icon
										data-test="remove-contact-from-order-button"
										path={mdiAccountRemove}
										size={'1em'}
									/>
								</ListItemIcon>
								<ListItemText>
									{t('orders.conversations.sidebar.disconnectContact')}
								</ListItemText>
							</MenuItem>
						</ButtonMenu>

						<Dialog open={showEditDialog} onClose={closeEditDialog}>
							<ModalClose closeModalFn={closeEditDialog} />
							<DialogTitle>{t('contacts.form.editContactTitle')}</DialogTitle>
							<EditContactForm
								onSuccess={onEditSuccess}
								onCancel={closeEditDialog}
								contact={contact}
							/>
						</Dialog>
					</>
				)
			}
		>
			<ListItemAvatar>
				<ConversationAvatar
					contact={contact}
					src=""
					transportType={messagingNetwork || MessagingTransportTypeEnum.FACEBOOK_WHATSAPP}
				/>
			</ListItemAvatar>
			<ListItemText>
				<StyledContactName>
					<ContactName contact={contact} />
				</StyledContactName>

				<StyledContactDetail>
					{roleIcon && <StyledContactDetailIcon path={roleIcon} size={'1.4em'} />}
					{contact.role !== ContactRoleEnum.NONE && (
						<StyledContactLabel>{t(`roles.${contact.role}`)} </StyledContactLabel>
					)}
					{showDecoration && '• '}
					{company}
				</StyledContactDetail>
				{contact.licensePlate && !orderId && (
					<StyledContactDetail>
						{<StyledContactDetailIcon path={mdiTruck} size={'1.4em'} />}
						<StyledContactLabel>{contact.licensePlate}</StyledContactLabel>
					</StyledContactDetail>
				)}
			</ListItemText>
			{!!unreadCount && <StyledChip label={unreadCount} size={'small'} />}
		</SidebarListItem>
	);
};
