import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	MenuItem,
	TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrders, useMenu } from '../../../hooks';
import { ButtonMenu, ModalClose } from '../../ui';
import { swollowEvent } from '../../../util/swallow-event';
import { StyledDialogActions } from '../../dialog';

import type { OrderResInterface } from '@heylog-app/shared/types';

export const OrderSelect = ({
	onSelect,
	shouldConfirm,
	confirmationMessage,
}: {
	onSelect: (order: OrderResInterface) => void;
	shouldConfirm?: boolean;
	confirmationMessage: string;
}) => {
	const { t } = useTranslation();
	const menuControl = useMenu();
	const { closeMenu } = menuControl;

	const [selectedOrder, setSelectedOrder] = useState<OrderResInterface | null>(null);
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);
	const [orderFilter, setOrderFilter] = useState<string>('');
	const { orders, isLoading } = useOrders({ q: orderFilter });

	const onCloseConfirmDialog = useCallback(() => {
		setShowConfirmDialog(false);
	}, [setShowConfirmDialog]);

	const onFilter = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			e.stopPropagation();
			e.preventDefault();
			const value = e.target.value;
			setOrderFilter(value);
		},
		[setOrderFilter],
	);

	const onConfirm = useCallback(() => {
		if (selectedOrder) {
			onSelect(selectedOrder);
		}
		onCloseConfirmDialog();
	}, [onCloseConfirmDialog, onSelect, selectedOrder]);

	const handleOnSelect = useCallback(
		(order: OrderResInterface) => {
			closeMenu();
			if (shouldConfirm) {
				setSelectedOrder(order);
				setShowConfirmDialog(true);
			} else {
				onSelect(order);
			}
		},
		[closeMenu, onSelect, shouldConfirm],
	);

	return (
		<>
			<ButtonMenu
				id="create-conversation-menu"
				button={<Button variant="contained">{t('actionLabels.select')}</Button>}
				control={menuControl}
				disabled={orders?.length === 0}
			>
				<MenuItem onKeyDown={swollowEvent}>
					<TextField
						placeholder={t('actionLabels.search')}
						value={orderFilter}
						onChange={onFilter}
					/>
				</MenuItem>
				{orders &&
					!isLoading &&
					orders.map((order) => (
						<MenuItem onClick={() => handleOnSelect(order)} key={order.id}>
							{
								order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
									?.value
							}{' '}
							- {order.customer}
						</MenuItem>
					))}
			</ButtonMenu>

			<Dialog open={showConfirmDialog} onClose={onCloseConfirmDialog}>
				<ModalClose closeModalFn={onCloseConfirmDialog} />
				<DialogTitle id="alert-dialog-title">
					{t('contacts.toolSidebar.assignOrderConfirmationTitle')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{confirmationMessage}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={onCloseConfirmDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button variant="contained" onClick={onConfirm} autoFocus>
						{t('actionLabels.confirm')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</>
	);
};
