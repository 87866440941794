import styled, { css } from 'styled-components';
import Icon from '@mdi/react';

import { colors } from '../../styles';
import { T2, T4 } from '../../styles/theme/typography-v2';

export const StyledNavList = styled.ul`
	list-style-type: none;
	display: flex;
	flex-direction: column;
	margin: 0;
	padding: 16px 8px;
	align-items: center;
	justify-content: center;
	gap: 8px;
	${T2};
`;

export const StyledNavItem = styled.li<{ $active?: boolean }>`
	border-radius: 4px;
	width: 100%;
	height: 48px;

	&:hover {
		background-color: ${({ theme }) => theme.palette.primary.darkV2};
		a {
			color: ${colors.common.white};
			& svg path {
				fill: ${colors.common.white};
				color: ${colors.common.white};
			}
		}
	}

	a {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		border-radius: 4px;
		padding-inline: 12px;
		gap: 12px;
		position: relative;

		color: ${colors.grey[900]};
		text-decoration: none;

		&:hover {
			opacity: 1;
			box-shadow: none;
		}

		& svg {
			min-width: 24px;
			path {
				fill: ${colors.grey[600]};
				color: ${colors.grey[600]};
			}
		}

		${({ $active }) =>
			$active &&
			css`
				opacity: 1;
				background-color: ${({ theme }) => theme.palette.primary.darkV2};
				color: ${colors.common.white};

				& svg path {
					fill: ${colors.common.white};
					color: ${colors.common.white};
				}
			`}
	}
`;

export const StyledDotIcon = styled(Icon)`
	position: absolute;
	left: 50%;
	top: 5%;
	transform: scale(2);

	path {
		color: ${colors.success['main']} !important;
	}
`;

export const StyledUnreadCount = styled.div`
	${T4};
	color: ${colors.common.white};
	border-radius: 50%;
	background-color: ${colors.success['main']};
	margin-left: auto;
	width: 24px;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StyledMenuText = styled.div`
	display: inline-block;
	text-overflow: ellipsis;
	overflow: hidden;
`;
