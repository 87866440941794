import styled from 'styled-components';

export const StyledDragOverlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({ theme }) => theme.palette.common.white};
	background: rgba(0, 0, 0, 0.5);
`;
