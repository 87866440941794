import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import { DashboardDataTable } from './components';
import { StyledBackground } from './dashboard.styles';

import type { FC } from 'react';

export const DashboardPage: FC = () => {
	return (
		<SidebarLayout>
			<StyledBackground>
				<DashboardDataTable />
			</StyledBackground>
		</SidebarLayout>
	);
};
