import { mdiAvTimer, mdiCheck, mdiCheckAll, mdiCircle, mdiClose } from '@mdi/js';

import {
	MessageDirectionEnum,
	MessageStatusEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

/* TODO: DEJAN: Check why the below import gives us 
"Cannot find module '@emotion/styled' from '../../../node_modules/@mui/styled-engine/node/index.js'"
in Jest test runtime

import { colors } from '../../styles';
*/

import { colors } from '../../styles/theme/colors';

import type { MessageIconData } from './message-icon-data.type';
import type { MessageResInterface } from '@heylog-app/shared/types';

export const getMessageIconData = (message: MessageResInterface): MessageIconData => {
	const messageIconData: MessageIconData = {
		path: '',
		color: '',
	};

	if (message?.direction === MessageDirectionEnum.TO_CONTACT) {
		switch (message.status) {
			case MessageStatusEnum.PENDING:
				messageIconData.path = mdiAvTimer;
				messageIconData.color = colors.greyblue[205];
				break;
			case MessageStatusEnum.OUT_OF_WHATSAPP_24H:
				messageIconData.path = mdiCircle;
				messageIconData.color = colors.red.main;
				break;
			case MessageStatusEnum.SENT:
				messageIconData.path = mdiCheck;
				messageIconData.color = colors.greyblue[205];
				break;
			case MessageStatusEnum.DELIVERED:
				messageIconData.path = mdiCheckAll;
				messageIconData.color = colors.greyblue[205];
				break;
			case MessageStatusEnum.READ:
				messageIconData.path = mdiCheckAll;
				if (message.transportType === MessagingTransportTypeEnum.VIBER) {
					messageIconData.color = colors.purple.main50;
				}
				if (message.transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP) {
					messageIconData.color = colors.green.main50;
				}

				break;
			case MessageStatusEnum.FAILED:
				messageIconData.path = mdiClose;
				messageIconData.color = colors.greyblue[205];
				break;

			default:
				break;
		}
	}

	return messageIconData;
};
