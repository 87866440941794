import { StyledDialogContent } from './dialog-footer.styles';

import type { DialogContentProps } from '@mui/material';
import type { FC } from 'react';

type DialogFooterProps = DialogContentProps;

export const DialogFooter: FC<DialogFooterProps> = ({ children, ...props }) => (
	<StyledDialogContent {...props}>{children}</StyledDialogContent>
);
