// matches a single email address, e.g. "user@domain.com"
export const emailValidationRegEx = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,})+$/;

// matches multiple email addresses with optional display names
// e.g. "user@domain.com, <user@domain.com>, User Name <user@domain.com>"
export const multipleEmailValidationRegEx =
	/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b|<\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b>/g;

export const validateEmailAddresses = (text: string | undefined): boolean => {
	if (!text || !text.length) return false;
	return text
		.split(',')
		.map((segment) => segment.trim())
		.every((segment) => {
			if (!segment.length) return true;
			const address = extractEmailAddresses(segment)[0];
			return address ? emailValidationRegEx.test(address) : false;
		});
};

export const extractEmailAddresses = (text: string | undefined): string[] => {
	const addresses = text?.match(multipleEmailValidationRegEx) || [];
	const emails = addresses.map((address) => address.replace(/[<>]/g, ''));
	return emails;
};
