import styled from 'styled-components';

import { STYLE_1, STYLE_3 } from '@heylog-app/frontend-lib/app';

export const StyledTitle = styled.h2`
	${STYLE_3}
`;

export const SignupFormWrapper = styled.div`
	max-width: 444px;
`;

export const StyledSubTitle = styled.p`
	${STYLE_1}

	margin-bottom: 32px;
`;
