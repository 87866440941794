import { useCallback, useEffect, useState } from 'react';

import { useActiveUnread } from './use-active-unread.hook';
import { getUnreadSum } from '../util/get-unread-sum';

import type { UseTitleType } from '../types';

export const useTitle = (): UseTitleType => {
	const { activeUnread } = useActiveUnread();
	const [activeUnreadMessagesSum, setActiveUnreadMessagesSum] = useState(0);

	const mainTitle = 'Heylog';

	useEffect(() => {
		setActiveUnreadMessagesSum(getUnreadSum(activeUnread));
	}, [setActiveUnreadMessagesSum, activeUnread]);

	const setTitle = useCallback(() => {
		document.title =
			activeUnreadMessagesSum > 0
				? `(${activeUnreadMessagesSum}) ${mainTitle}`
				: mainTitle;
	}, [activeUnreadMessagesSum]);

	const cleanTitle = () => (document.title = mainTitle);

	return { activeUnreadMessagesSum, setTitle, cleanTitle };
};
