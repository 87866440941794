import styled from 'styled-components';

import { SIZE_XS, STYLE_1 } from '../../../styles';

export const StyledOrderEventWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin: 1rem 0 2rem 0;

	&::after {
		content: '';
		position: absolute;
		display: block;
		top: 50%;
		transform: translateY(-51%);
		width: 100%;
		height: 0.5px;
		background-color: ${({ theme }) => theme.palette.greyblue[205]};
		z-index: -1;
	}
`;

export const StyledOrderEvent = styled.div`
	${STYLE_1};
	${SIZE_XS};
	color: ${({ theme }) => theme.palette.greyblue[112]};
	text-align: center;
	background: white;
	padding: 0.5rem;

	> span {
		display: block;
	}
`;
