import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import { Dialog, DialogTitle, LinearProgress } from '@mui/material';
import { Icon } from '@mdi/react';
import { mdiPlusCircle } from '@mdi/js';

import {
	CreateOrderFormV1,
	ModalClose,
	Sidebar,
	SidebarHeader,
	SidebarLayout,
	SidebarLayoutContent,
	SidebarList,
	SidebarSearch,
	useDebounce,
	useDialog,
	useOrders,
	usePlugins,
	useUser,
} from '@heylog-app/frontend-lib/app';

import { StyledCreateButton } from './orders.styles';
import { OrderListItem } from '../../components/ui/order-list-item/order-list-item';
import { CreateOrderWelcome } from './welcome/create-order-welcome';
import { SelectOrderWelcome } from './welcome/select-order-welcome';

import type { FC, ChangeEvent } from 'react';

export const OrdersPage: FC = () => {
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const [_, setSearchParams] = useSearchParams();
	const [isSearching, setIsSearching] = useState(false);
	const params = useParams();
	const { orderId, workspaceId = '' } = params;
	const { TimeMattersOSUPlugin, FrontendV2Plugin } = usePlugins(workspaceId);

	const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);

	const {
		orders,
		updateOrders,
		isLoading: ordersLoading,
	} = useOrders({ q: debouncedSearchTerm });
	const { user } = useUser();

	const {
		showDialog: showCreateDialog,
		openDialog: openCreateDialog,
		closeDialog: closeCreateDialog,
	} = useDialog();
	const { t } = useTranslation();

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchParams({ q: debouncedSearchTerm }, { replace: true });
		}
	}, [debouncedSearchTerm, setSearchParams]);

	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
		},
		[setSearchTerm],
	);

	const handleSuccess = useCallback(() => {
		closeCreateDialog();
		updateOrders();
	}, [closeCreateDialog, updateOrders]);

	const hasOrders = orders?.length && orders?.length > 0;

	return (
		<>
			<SidebarLayout>
				{!FrontendV2Plugin && (
					<Sidebar variant="primary">
						<SidebarHeader title={'orders.sidebar.title'}>
							{!TimeMattersOSUPlugin && (
								<StyledCreateButton
									data-test="create-order-button"
									variant="contained"
									onClick={openCreateDialog}
									size="small"
									startIcon={<Icon path={mdiPlusCircle} size={'1.5em'} />}
								>
									{t('orders.sidebar.createLabel')}
								</StyledCreateButton>
							)}
						</SidebarHeader>
						<SidebarSearch onSearch={handleSearch} setIsSearching={setIsSearching} />
						<SidebarList>
							{orders?.map((order, index) => (
								<OrderListItem order={order} key={index + '-' + order.id} />
							))}
						</SidebarList>
					</Sidebar>
				)}
				<SidebarLayoutContent>
					{ordersLoading ? (
						<LinearProgress></LinearProgress>
					) : !hasOrders || isSearching ? (
						<CreateOrderWelcome onClick={openCreateDialog} user={user} />
					) : !orderId ? (
						<SelectOrderWelcome user={user} />
					) : (
						<Outlet />
					)}
				</SidebarLayoutContent>
			</SidebarLayout>

			<Dialog
				data-test="create-order-dialog"
				open={showCreateDialog}
				onClose={closeCreateDialog}
			>
				<ModalClose closeModalFn={closeCreateDialog} />
				<DialogTitle>{t('orders.form.createOrderTitle')}</DialogTitle>
				<CreateOrderFormV1 onSuccess={handleSuccess} onCancel={closeCreateDialog} />
			</Dialog>
		</>
	);
};
