import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';

import { useTemplates } from '../../../hooks';
import { ControlledSelect } from '../controlled-select';
import { getAvailableTemplates } from '../../../util/get-available-templates';
import { getLanguageCode } from '../../../util/get-language-code';
import { TemplatePreview } from '../template-preview';
import { ModalClose } from '../modals';

import type { FC, Dispatch, SetStateAction } from 'react';
import type { Control, FieldValues } from 'react-hook-form';
import type {
	ConversationResInterface,
	WhatsAppMessageTemplateResInterface,
} from '@heylog-app/shared/types';
import type { UseDialogReturnType } from '../../../types';

type WhatsappTemplatesDialogType = {
	control: UseDialogReturnType;
	setSelectedTemplate: Dispatch<
		SetStateAction<WhatsAppMessageTemplateResInterface | undefined>
	>;
	conversationLanguage: string;
	conversation?: ConversationResInterface;
};

type FormData = {
	templateName: string;
	templateLanguage: string;
};

export const WhatsAppTemplatesDialog: FC<WhatsappTemplatesDialogType> = ({
	control: dialogControl,
	setSelectedTemplate,
	conversationLanguage,
	conversation,
}) => {
	const { showDialog, closeDialog } = dialogControl;
	const { templates } = useTemplates();
	const { t } = useTranslation();
	const [previewTemplate, setPreviewTemplate] =
		useState<WhatsAppMessageTemplateResInterface>();

	const {
		handleSubmit,
		formState: { errors },
		control,
		reset,
		watch,
	} = useForm<FormData>();

	const selectedTemplateName = watch('templateName');

	const availableTemplates = getAvailableTemplates({
		language: conversationLanguage,
		templates,
	});
	const areTemplatesAvailable =
		availableTemplates && Object.keys(availableTemplates).length > 0;

	const findTemplate = useCallback(
		(templateName: string) =>
			templates?.find(
				(template) =>
					template.name === templateName &&
					getLanguageCode(template.language) === getLanguageCode(conversationLanguage),
			),
		[templates, conversationLanguage],
	);

	useEffect(() => {
		const template = findTemplate(selectedTemplateName);
		setPreviewTemplate(template);
	}, [selectedTemplateName, findTemplate]);

	const onSubmit = async (data: FormData) => {
		const template = findTemplate(data.templateName);
		setSelectedTemplate(template);
		closeDialog();
	};

	const handleCloseDialog = () => {
		closeDialog();

		//reset values when closing dialog without submitting
		reset();
	};

	return (
		<Dialog
			data-test="template-dialog"
			open={showDialog}
			onClose={handleCloseDialog}
			fullWidth
			maxWidth="md"
		>
			<ModalClose closeModalFn={handleCloseDialog} />
			<DialogTitle>{t('waTemplates.selectTemplateLabel')}</DialogTitle>
			<form onSubmit={handleSubmit(onSubmit)} noValidate>
				<DialogContent>
					{availableTemplates && (
						<ControlledSelect
							data-test="template-select"
							id="templateName"
							label={
								areTemplatesAvailable
									? 'waTemplates.selectTemplateLabel'
									: 'waTemplates.noTemplatesAvailable'
							}
							control={control as unknown as Control<FieldValues>}
							selectOptions={{
								error: errors.templateName ? true : false,
								disabled: Object.keys(availableTemplates).length === 0,
							}}
							rules={{ required: true }}
							errorMessage={errors.templateName && t('forms.errors.required')}
						>
							{Object.keys(availableTemplates)
								.reverse()
								.map((template) => (
									<MenuItem data-test={template} key={template} value={template}>
										{t(`waTemplates.templates.${template}`)}
									</MenuItem>
								))}
						</ControlledSelect>
					)}
					{previewTemplate && conversation && (
						<TemplatePreview conversation={conversation} template={previewTemplate} />
					)}
				</DialogContent>
				<DialogActions>
					<Button data-test="insert-template-button" type="submit" variant="contained">
						{t('actionLabels.insert')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
};
