import styled from 'styled-components';
import { Avatar, Button, Chip, MenuItem } from '@mui/material';
import Icon from '@mdi/react';

import { PusherConnectionStatesEnum } from '@heylog-app/shared/types';

import { STYLE_3, SIZE_MD, STYLE_2, SIZE_XS, colors } from '../../../styles';
import { T3, T3_medium, T4 } from '../../../styles/theme/typography-v2';
import Image from './images/avatar-bg@2x.jpg';

import type { PusherConnectionStatesType } from '@heylog-app/shared/types';

interface BaseProps {
	isV2: boolean | undefined;
}

export const StyledUserMenuWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 1rem;
`;

export const StyledConnectionDot = styled.span<{ state: PusherConnectionStatesType }>`
	height: 15px;
	width: 15px;
	background-color: ${(props) => {
		switch (props.state) {
			case PusherConnectionStatesEnum.CONNECTING:
				return '#ffc107';
			case PusherConnectionStatesEnum.CONNECTED:
				return '#28a745';
			case PusherConnectionStatesEnum.FAILED:
			case PusherConnectionStatesEnum.DISCONNECTED:
				return '#dc3545';
			case PusherConnectionStatesEnum.UNAVAILABLE:
				return '#343a40';
			default:
				return '#343a40';
		}
	}};
	border-radius: 50%;
	display: inline-block;
	margin-left: 1px;
`;

export const StyledMenuButton = styled.button<BaseProps>`
	background: none;
	border: 0;
	padding: 0.25rem;
	margin: 0;
	${SIZE_MD};
	${({ isV2 }) => (isV2 ? T3 : STYLE_3)};
	color: ${({ theme }) => theme.palette.common.white};

	display: flex;
	align-items: center;
	gap: 0.4rem;
	white-space: nowrap;

	&:after {
		content: '';
		display: block;
		border: 0.3em solid transparent;
		border-top: 0.3em solid ${({ theme }) => theme.palette.common.white};
		transform: translateY(35%);
	}
`;

export const StyledButtonTitle = styled.div`
	max-width: 180px;
	text-align: right;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const StyledWorkspaceLabel = styled.div<{ size?: string } & BaseProps>`
	max-width: 180px;
	overflow: hidden;
	margin-top: 0;
	font-weight: 300;
	text-overflow: ellipsis;
	${(props) => {
		const xsFont = props.isV2 ? T4 : SIZE_XS;
		const lgFont = props.isV2 ? T3_medium : undefined;

		return props.size === 'XS' ? xsFont : lgFont;
	}}
	${(props) => props.size === 'XS' && props.isV2 && `color: ${colors.primary.light}`}
`;

export const StyledMenuItem = styled(MenuItem)<BaseProps>`
	${SIZE_MD};
	${({ isV2 }) => (isV2 ? T3 : STYLE_2)};
	margin: 0;
	color: black;
	border-bottom: 1px solid ${colors.greyblue[225]};

	&:last-of-type {
		border-bottom: none;
	}
`;

export const StyledSwitchButton = styled(Button)`
	${SIZE_MD};
	padding: 6px 16px;
	margin: 0;
	color: ${colors.common.black};
	border-bottom: 1px solid ${colors.greyblue[225]};
`;

export const StyledMenuIcon = styled(Icon)`
	margin-right: 10px;
`;

export const StyledCurrentWorkspaceChip = styled(Chip)`
	margin-left: 10px;
`;

export const StyledGuestChip = styled(Chip)`
	color: ${colors.common.white};
	${SIZE_XS};
	height: 24px;

	.MuiChip-label {
		padding: 8px;
	}
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;
