import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Dialog,
	DialogTitle,
	MenuItem,
	DialogContent,
	DialogContentText,
	Button,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import ArchiveIcon from '@mui/icons-material/Archive';

import {
	ButtonMenu,
	ROUTES,
	DetailItem,
	useOrderActions,
	useMenu,
	useDialog,
	ModalClose,
	StyledDialogActions,
	CreateOrderFormV1,
} from '@heylog-app/frontend-lib/app';

import {
	StyledButtonContainer,
	StyledOrderDetailSidebar,
	StyledSidebarSection,
} from './order-detail-sidebar.styles';

import type { FC } from 'react';

export const OrderDetailSidebar: FC = () => {
	const { t } = useTranslation();
	const { orderId, workspaceId = '' } = useParams();
	const { order, archiveOrder } = useOrderActions(orderId);
	const navigate = useNavigate();
	const menuControl = useMenu();

	const {
		showDialog: showEditDialog,
		openDialog: openEditDialog,
		closeDialog: closeEditDialog,
	} = useDialog();

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();

	const handleConfirmArchive = useCallback(async () => {
		if (!orderId) return;
		await archiveOrder({ id: orderId, workspaceId });
		closeConfirmArchiveDialog();
		navigate(generatePath(ROUTES.ORDERS.OVERVIEW, { workspaceId }));
	}, [orderId, archiveOrder, navigate, workspaceId, closeConfirmArchiveDialog]);

	const handleSuccess = () => {
		closeEditDialog();
		menuControl.closeMenu();
	};

	const parsedMiscData = order?.miscData ? JSON.parse(order.miscData as string) : null;

	return (
		<>
			<StyledSidebarSection>
				<StyledOrderDetailSidebar>
					<DetailItem label={'orders.details.orderRef'} value={order?.refNumber || '-'} />
					<DetailItem label={'orders.details.customer'} value={order?.customer || '-'} />
					<DetailItem
						label={'orders.details.licensePlateTruck'}
						value={order?.licensePlateTruck || '-'}
					/>
					<DetailItem
						label={'orders.details.licensePlateTrailer'}
						value={order?.licensePlateTrailer || '-'}
					/>
					<DetailItem
						label={'orders.details.weight'}
						value={parsedMiscData?.totalWeight || '-'}
					/>
					<DetailItem
						label={'orders.details.volume'}
						value={parsedMiscData?.volumeOfOrder || '-'}
					/>
					<DetailItem label={'orders.details.notes'} value={order?.notes} />
				</StyledOrderDetailSidebar>
				<StyledButtonContainer>
					<Button
						data-test="edit-order-button"
						onClick={openEditDialog}
						variant="contained"
						fullWidth
					>
						{t('actionLabels.edit')}
					</Button>
					<ButtonMenu
						dataTest="edit-order-menu"
						id="edit-order-menu"
						control={menuControl}
					>
						<MenuItem onClick={openConfirmArchiveDialog}>
							<ListItemIcon>
								<ArchiveIcon />
							</ListItemIcon>
							<ListItemText>{t('actionLabels.archive')}</ListItemText>
						</MenuItem>
					</ButtonMenu>
				</StyledButtonContainer>
			</StyledSidebarSection>

			<Dialog open={showEditDialog} onClose={closeEditDialog}>
				<ModalClose closeModalFn={closeEditDialog} />
				<DialogTitle>{t('orders.form.editOrderTitle')}</DialogTitle>
				<CreateOrderFormV1
					onSuccess={handleSuccess}
					onCancel={closeEditDialog}
					order={order}
				/>
			</Dialog>

			<Dialog open={showConfirmArchiveDialog} onClose={closeConfirmArchiveDialog}>
				<ModalClose closeModalFn={closeConfirmArchiveDialog} />
				<DialogTitle id="alert-dialog-title">
					{t('orders.form.confirmArchiveText')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('orders.form.confirmArchive')}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={closeConfirmArchiveDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={handleConfirmArchive}
						autoFocus
					>
						{t('actionLabels.archive')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</>
	);
};
