import { useContext } from 'react';

import { AuthContext } from '../providers';

import type { UserAuthContext } from '../types';

export const useAuthContext = (): UserAuthContext => {
	const context = useContext(AuthContext);

	if (!context)
		throw new Error('auth context must be used inside an auth context provider.');

	return context;
};
