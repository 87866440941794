export const IconUntrack = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 64 54"
		width="24"
		height="24"
		fill="#091C8F"
		stroke="#091C8F"
	>
		<rect
			x="4"
			y="5"
			width="35"
			height="28"
			fill="none"
			stroke="#122CC5"
			strokeWidth="4"
		/>

		<rect
			x="40"
			y="18"
			width="14"
			height="14"
			fill="none"
			stroke="#122CC5"
			strokeWidth="4"
		/>

		<circle cx="16" cy="38" r="6" fill="none" stroke="#122CC5" strokeWidth="4" />
		<circle cx="44" cy="38" r="6" fill="none" stroke="#122CC5" strokeWidth="4" />

		<line x1="24" y1="20" x2="8" y2="20" stroke="#122CC5" strokeWidth="4" />
		<line x1="14" y1="15" x2="8" y2="20" stroke="#122CC5" strokeWidth="4" />
		<line x1="14" y1="25" x2="8" y2="20" stroke="#122CC5" strokeWidth="4" />
	</svg>
);
