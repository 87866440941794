import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const ContactRoleEnum = mkenum({
	DRIVER: 'DRIVER',
	DISPATCHER: 'DISPATCHER',
	CLIENT: 'CLIENT',
	RECIPIENT: 'RECIPIENT',
	OTHER: 'OTHER',
	NONE: 'NONE',
});

export type ContactRoleType = TypeFromPEnum<typeof ContactRoleEnum>;
