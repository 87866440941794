export const IconWhatsApp = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.44386 29.2497L4.3529 22.2794C3.17548 20.2394 2.55612 17.9252 2.55677 15.5542C2.55999 8.13742 8.59548 2.10323 16.0129 2.10323C19.6122 2.10452 22.9903 3.50581 25.531 6.04839C28.071 8.59097 29.4697 11.971 29.4684 15.5652C29.4652 22.9813 23.4284 29.0168 16.0129 29.0168H16.0071C13.7555 29.0161 11.5426 28.451 9.57741 27.3794L2.44451 29.2497H2.44386ZM9.90773 24.9439L10.3161 25.1858C12.0329 26.2045 14.0013 26.7432 16.0084 26.7445H16.0129C22.1768 26.7445 27.1942 21.729 27.1968 15.5639C27.1974 12.5761 26.0355 9.7671 23.9239 7.65419C21.8123 5.54129 19.0045 4.37677 16.0168 4.37548C9.84773 4.37548 4.83096 9.39032 4.82838 15.5548C4.82773 17.6671 5.4187 19.7245 6.53806 21.5045L6.80451 21.9277L5.67419 26.0535L9.90709 24.9439H9.90773Z"
			fill="white"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.3161 25.1858L9.90774 24.9439H9.90709L5.67419 26.0535L6.80451 21.9277L6.53806 21.5045C5.41871 19.7245 4.82774 17.6671 4.82838 15.5548C4.83097 9.39032 9.84774 4.37548 16.0168 4.37548C19.0045 4.37677 21.8123 5.54129 23.9239 7.65419C26.0355 9.76709 27.1974 12.5761 27.1968 15.5639C27.1942 21.729 22.1768 26.7445 16.0129 26.7445H16.0084C14.0013 26.7432 12.0329 26.2045 10.3161 25.1858Z"
			fill="#25D366"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12.6497 9.93161C12.3981 9.37161 12.1329 9.36065 11.8929 9.35097C11.6968 9.34258 11.4729 9.34323 11.2484 9.34323C11.0239 9.34323 10.66 9.42774 10.3516 9.76387C10.0432 10.1 9.17484 10.9136 9.17484 12.5677C9.17484 14.2219 10.38 15.8206 10.5477 16.0452C10.7161 16.269 12.8735 19.7723 16.291 21.12C19.131 22.24 19.709 22.0174 20.3258 21.9613C20.9426 21.9052 22.3148 21.1484 22.5955 20.3632C22.8755 19.5781 22.8755 18.9052 22.7916 18.7645C22.7077 18.6245 22.4832 18.5406 22.1471 18.3723C21.811 18.2039 20.1581 17.3903 19.8497 17.2787C19.5413 17.1665 19.3174 17.1103 19.0929 17.4471C18.869 17.7832 18.2245 18.5406 18.0284 18.7645C17.8323 18.989 17.6361 19.0174 17.3 18.849C16.9639 18.6806 15.8806 18.3258 14.5961 17.1806C13.5968 16.2897 12.9219 15.189 12.7258 14.8523C12.5297 14.5161 12.7045 14.3335 12.8735 14.1665C13.0245 14.0161 13.2097 13.7742 13.3781 13.5774C13.5458 13.3813 13.6019 13.2413 13.7142 13.0168C13.8264 12.7923 13.7703 12.5961 13.6864 12.4277C13.6026 12.2594 12.949 10.5968 12.6503 9.93226L12.6497 9.93161Z"
			fill="white"
		/>
	</svg>
);
