import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { getEventsByContactIdKey, getFetcher } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers';

import type { Maybe, EntityEventResType } from '@heylog-app/shared/types';

/**
 * used in the free flow chat view, loads all events relevant to a contact id and all orders the contact is assigned to
 * */
export const useRelevantEvents = (
	workspaceId: string | number,
	contactId: Maybe<string>,
) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const entityEventsKey = getEventsByContactIdKey({ workspaceId, contactId });

	const { data, mutate, isLoading, isValidating } = useSWR<EntityEventResType[]>(
		contactId && ability.can(Actions.MANAGE, 'Workspace') ? entityEventsKey : null,
		getFetcher(apiClient),
	);

	return {
		entityEvents: data,
		mutateEvents: mutate,
		isLoadingRelevantEvents: isLoading,
		isValidatingRelevantEvents: isValidating,
	};
};
