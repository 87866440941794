import { Button, Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ReactRouterPrompt from 'react-router-prompt';

import { StyledDialogActions, StyledDialogContent } from '../../settings.styles';

export const Prompt = ({ isDirty }: { isDirty: boolean }) => {
	const { t } = useTranslation();

	return (
		<ReactRouterPrompt when={isDirty}>
			{({ isActive, onConfirm, onCancel }) => (
				<Dialog open={isActive}>
					<DialogTitle>{t('navigation.settingsMenu.dialogTitle')}</DialogTitle>
					<StyledDialogContent>
						{t('navigation.settingsMenu.dialogText')}
					</StyledDialogContent>
					<StyledDialogActions>
						<Button size="large" variant="outlined" onClick={onCancel}>
							{t('actionLabels.cancel')}
						</Button>
						<Button size="large" variant="contained" color="error" onClick={onConfirm}>
							{t('actionLabels.continue')}
						</Button>
					</StyledDialogActions>
				</Dialog>
			)}
		</ReactRouterPrompt>
	);
};
