import type { FC } from 'react';

export const IconLv: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M86.1641 60H9.83606C14.9341 76.228 30.0901 88 48.0001 88C65.9101 88 81.0661 76.228 86.1641 60Z"
			fill="#B71C1C"
		/>
		<path
			d="M48.0001 8C30.0901 8 14.9341 19.772 9.83606 36H86.1621C81.0661 19.772 65.9101 8 48.0001 8Z"
			fill="#B71C1C"
		/>
		<path
			d="M88 48C88 43.818 87.354 39.788 86.164 36H9.836C8.646 39.788 8 43.818 8 48C8 52.182 8.646 56.212 9.836 60H86.162C87.354 56.212 88 52.182 88 48Z"
			fill="#ECEFF1"
		/>
	</svg>
);
