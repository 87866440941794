import { useTranslation } from 'react-i18next';
import { Avatar, Checkbox, Chip, Stack, Typography } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

import { getFormattedEta, getNameInitials } from '../util';
import { useConversationsV2 } from './use-conversationv2.hook';
import { colors } from '../styles';
import { useOrders } from './use-orders.hook';
import { convertOrderToDashboardRow } from './use-dashboard.hook';

import type { ConversationType } from '@heylog-app/shared/types';
import type { GridColDef } from '@mui/x-data-grid-pro';

export interface ConversationDetailsRow {
	contactId?: number;
	conversationV1Id?: string;
	dateAndTime: string;
	from: {
		senderEmail?: string;
		senderName?: string;
	};
	id: number;
	message?: string;
	priority?: string;
	internalReference?: string;
	orderDateFromTo?: string;
	subject?: string;
	todo: boolean;
	type: ConversationType;
}

export const useMessagesData = (types?: ConversationType[]) => {
	const { t } = useTranslation();

	const handleTodoChange = () => {
		console.log('handleTodoChange');
		// make api call to BE don't just change on FE
	};

	const columns: GridColDef[] = [
		{
			field: 'priority',
			headerName: t('messages.priority'),
			renderCell: (params) => {
				const value = params.value;
				if (!value) return null;
				return (
					<Chip
						sx={{
							bgcolor: colors.error.light,
							fontSize: '10px',
						}}
						label={value}
						size="small"
					/>
				);
			},
		},
		{
			field: 'todo',
			type: 'boolean',
			headerName: t(`messages.todo`),
			width: 55,
			// changing value of checkbox
			// https://stackoverflow.com/questions/71445011/how-do-i-change-state-of-mui-data-grid-when-using-a-checkbox-in-cellrender
			renderCell: (params) => {
				if (params.value === undefined) return null;
				return (
					<Checkbox
						icon={<StarOutlineIcon htmlColor={colors.primary.light} />}
						checkedIcon={<StarIcon />}
						checked={params.value}
						onChange={handleTodoChange}
						sx={{
							'&.Mui-checked': {
								color: colors.primary.main,
							},
						}}
					/>
				);
			},
		},
		{
			field: 'type',
			headerName: t(`messages.type`),
			flex: 1,
		},
		{
			field: 'dateAndTime',
			headerName: t(`messages.dateAndTime`),
			flex: 1,
		},
		{
			field: 'from',
			headerName: t(`messages.from`),
			flex: 2,
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" alignItems="center">
						<Avatar
							sizes="small"
							src=""
							sx={{
								height: '22px',
								width: '22px',
								fontSize: '12px',
								bgcolor: `${colors.primary.light}`,
							}}
						>
							{getNameInitials(params.value.senderName) || (
								<Icon path={mdiAccount} size={'12px'} />
							)}
						</Avatar>
						<Stack sx={{ marginLeft: '8px' }}>
							<Typography sx={{ fontWeight: '700', fontSize: '12px' }}>
								{params.value.senderName}
							</Typography>
							<Typography sx={{ fontSize: '10px' }}>
								{params.value.senderEmail}
							</Typography>
						</Stack>
					</Stack>
				);
			},
		},
		{
			field: 'subject',
			headerName: t(`messages.subject`),
			flex: 2,
			renderCell: (params) => {
				if (!params.row.subject) return null;
				const subject = params.row.subject;
				return <span>{subject}</span>;
			},
		},
		{
			field: 'internalReference',
			headerName: t('messages.internalReference'),
			flex: 1,
		},
		{
			field: 'orderDateFromTo',
			headerName: t('messages.bookingDate'),
			flex: 1,
		},
		{
			field: 'labels',
			headerName: t('messages.labels'),
			flex: 2,
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" gap={1}>
						{params.value.map((team: string, index: number) => {
							return (
								<Chip
									sx={{
										bgcolor: '#198A1F',
										fontSize: '10px',
										padding: '2px',
										color: 'white',
									}}
									key={index}
									size="small"
									label={team}
								/>
							);
						})}
					</Stack>
				);
			},
		},
	];

	const { conversations } = useConversationsV2(types);

	// SWR cache should help keeping from overfetching
	const { orders } = useOrders();

	const rows: ConversationDetailsRow[] =
		conversations?.map((conversation) => {
			// maybe i don't have details
			const details = conversation.details
				? (conversation.details as { conversationV1Id: string; contactId: number })
				: null;

			const order =
				conversation.orderId &&
				orders?.find((order) => order.id === conversation.orderId);

			const internalReference = order
				? order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')?.value
				: undefined;

			// this will break when we have multiple orders
			const priority = order
				? order.labels.find((label) => label.type === 'ORDER_PRIORITY')?.value
				: undefined;

			const todo = conversation.labels.find(
				(label) => label.type === 'CONVERSATION_TODO' && label.value === 'Todo',
			)
				? true
				: false;

			const orderForDashboard = order ? convertOrderToDashboardRow(order, t) : undefined;
			const orderDateFrom = orderForDashboard?.collectionDate
				? getFormattedEta(orderForDashboard?.collectionDate)
				: '';
			const orderDateTo = orderForDashboard?.deliveryDate
				? getFormattedEta(orderForDashboard?.deliveryDate)
				: '';
			const orderDateFromTo = orderDateFrom + ' - ' + orderDateTo;

			const orderAttention =
				order && order?.labels?.find((label) => label.type === 'ORDER_ATTENTION')?.value;

			const attention = orderAttention ? [orderAttention] : [];

			const labels = conversation.labels
				.filter((label) => label.type !== 'CONVERSATION_TODO')
				.map((label) => label.value);

			return {
				contactId: details?.contactId,
				conversationV1Id: details?.conversationV1Id,
				dateAndTime: conversation.updatedAt
					? new Date(conversation.updatedAt).toLocaleString('eu')
					: '',
				from: {
					senderEmail: conversation.lastMessage?.from,
					senderName: conversation.lastMessage?.from,
				},
				id: conversation.id,
				labels: [...attention, ...labels],
				message: conversation.lastMessage?.text,
				orderId: conversation.orderId,
				priority,
				internalReference,
				orderDateFromTo,
				subject: conversation.lastMessage?.subject,
				todo,
				type: conversation.type,
			};
		}) || [];

	return { columns, rows };
};
