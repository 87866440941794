import { StyledMessageAudio } from './message-audio.styles';

import type { AttachmentResInterface } from '@heylog-app/shared/types';

export const MessageAudio = ({ attachment }: { attachment?: AttachmentResInterface }) => {
	if (!attachment) return null;
	return (
		<>
			<StyledMessageAudio controls src={attachment.publicUrl} />
			{attachment?.caption && <p>{attachment?.caption}</p>}
		</>
	);
};
