import { getIsMessageSentToday } from './get-is-message-sent-today';
import { getIsMessageSentYesterday } from './get-is-message-sent-yesterday';
import { getIsMessageSentRecently } from './get-is-message-sent-recently';
import { getIsMessageSentAWhileAgo } from './get-is-message-sent-a-while-ago';
import { getMessageSentString } from './get-message-sent-string';

export const getMessageTime = (createdAt: Date, conversationLanguage: string): string => {
	const now = new Date();
	const messageDate = new Date(createdAt);
	const messageDelta = now.getTime() - messageDate.getTime();

	const conditionCheckFuncs = [
		{
			func: getIsMessageSentToday,
			execute: () => getMessageSentString(createdAt, conversationLanguage, true),
		},
		{
			func: getIsMessageSentYesterday,
			execute: () => getMessageSentString(createdAt, conversationLanguage),
		},
		{
			func: getIsMessageSentRecently,
			execute: () =>
				new Date(createdAt).toLocaleTimeString(conversationLanguage, {
					weekday: 'long',
					hour: '2-digit',
					minute: '2-digit',
					hour12: false,
				}),
		},
		{
			func: getIsMessageSentAWhileAgo,
			execute: () =>
				new Date(createdAt).toLocaleTimeString(conversationLanguage, {
					day: '2-digit',
					month: 'short',
					hour: '2-digit',
					minute: '2-digit',
					hour12: false,
				}),
		},
	];

	for (const condition of conditionCheckFuncs) {
		if (condition.func(messageDelta, messageDate, now)) {
			return condition.execute();
		}
	}

	return '';
};
