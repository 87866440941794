import { OutlinedInput } from '@mui/material';
import styled from 'styled-components';

import { SIZE_SM_PLUS, STYLE_1 } from '../../../styles';

export const StyledTemplateInputWrapper = styled.div`
	${STYLE_1};
	${SIZE_SM_PLUS};
	margin: 0.25rem;
`;

export const StyledParameterInput = styled(OutlinedInput)`
	${STYLE_1};
	${SIZE_SM_PLUS};
	margin: 0.25rem;

	input {
		padding: 0.25rem;
	}
`;
