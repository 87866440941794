import AttachFileIcon from '@mui/icons-material/AttachFile';
import TranslateIcon from '@mui/icons-material/Translate';
import MessageIcon from '@mui/icons-material/Message';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import {
	StyledFileInput,
	StyledMessageInputToolbar,
	StyledToolbarButton,
} from '../message-input/message-input.styles';
import { useConversation } from '../../../hooks';

import type { WhatsAppMessageTemplateResInterface } from '@heylog-app/shared/types';
import type { DropzoneInputProps } from 'react-dropzone';
import type { FC, Dispatch, SetStateAction } from 'react';

type MessageInputToolbarProps = {
	isTranslationActive: boolean;
	setIsTranslationActive: Dispatch<SetStateAction<boolean>>;
	openTranslationDialog: () => void;
	openWhatsAppTemplatesDialog: () => void;
	conversationId: string;
	selectedTemplate: WhatsAppMessageTemplateResInterface | undefined;
	setSelectedTemplate: Dispatch<
		SetStateAction<WhatsAppMessageTemplateResInterface | undefined>
	>;
	openFileDialog: () => void;
	getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
	toggleEmojiPicker: () => void;
};

export const MessageInputToolbar: FC<MessageInputToolbarProps> = ({
	isTranslationActive,
	setIsTranslationActive,
	openTranslationDialog,
	conversationId,
	openWhatsAppTemplatesDialog,
	selectedTemplate,
	setSelectedTemplate,
	openFileDialog,
	getInputProps,
	toggleEmojiPicker,
}) => {
	const { t } = useTranslation();
	const { workspaceId = '' } = useParams();
	const { updateConversation } = useConversation(workspaceId, conversationId);

	const toggleIsTranslationActive = useCallback(() => {
		if (isTranslationActive) {
			setIsTranslationActive(false);
			updateConversation({ isTranslationActive: false });
		} else {
			openTranslationDialog();
		}
	}, [
		isTranslationActive,
		updateConversation,
		setIsTranslationActive,
		openTranslationDialog,
	]);

	const toggleIsTemplateSelected = useCallback(() => {
		if (selectedTemplate) {
			setSelectedTemplate(undefined);
		} else {
			openWhatsAppTemplatesDialog();
		}
	}, [selectedTemplate, setSelectedTemplate, openWhatsAppTemplatesDialog]);

	const toggleTranslationButtonLabel = useMemo(
		() =>
			isTranslationActive
				? t('chats.deactivateTranslation')
				: t('chats.activateTranslation'),
		[t, isTranslationActive],
	);

	const toggleTemplateButtonLabel = useMemo(
		() => (selectedTemplate ? t('chats.removeTemplate') : t('chats.template')),
		[t, selectedTemplate],
	);

	return (
		<StyledMessageInputToolbar>
			<StyledToolbarButton onClick={openFileDialog} data-test="attach-file-button">
				<AttachFileIcon />
				{t('chats.fileUpload')}
			</StyledToolbarButton>
			<StyledFileInput data-test="attach-file-input" {...getInputProps()} />
			<StyledToolbarButton
				onClick={toggleIsTranslationActive}
				data-test="toggle-translation-button"
			>
				<TranslateIcon />
				{toggleTranslationButtonLabel}
			</StyledToolbarButton>
			<StyledToolbarButton
				onClick={toggleIsTemplateSelected}
				data-test="message-template-button"
			>
				<MessageIcon />
				{toggleTemplateButtonLabel}
			</StyledToolbarButton>
			<StyledToolbarButton onClick={toggleEmojiPicker} data-test="emoji-picker-button">
				<SentimentSatisfiedAltIcon />
				{t('chats.emoji')}
			</StyledToolbarButton>
		</StyledMessageInputToolbar>
	);
};
