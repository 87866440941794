import { useTranslation } from 'react-i18next';

import {
	StyledSidebarContent,
	StyledSidebarContentTitle,
} from './sidebar-content.styles';

import type { FC, ReactNode } from 'react';

type SidebarContentProps = { title?: string; children: ReactNode };

export const SidebarContent: FC<SidebarContentProps> = ({ title, children }) => {
	const { t } = useTranslation();
	return (
		<StyledSidebarContent>
			{title && <StyledSidebarContentTitle>{t(title)}</StyledSidebarContentTitle>}
			{children}
		</StyledSidebarContent>
	);
};
