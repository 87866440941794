import { Box, Button, Stack } from '@mui/material';
import { deDE, enUS } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import i18next from 'i18next';

import { FileCard, TableV2, useDialog } from '@heylog-app/frontend-lib/app';
import { getMediaType } from '@heylog-app/shared/util';

import { DocumentModal } from './document-modal';
import { StyledTitle } from './documents-data-table.styles';

import type { GridApiPro } from '@mui/x-data-grid-pro';
import type { MessageType } from '@heylog-app/shared/types';
import type { AttachmentRow } from '@heylog-app/frontend-lib/app';
import type { FC, MutableRefObject } from 'react';
import type {
	GridRowParams,
	GridColDef,
	GridColumnResizeParams,
	GridColumnVisibilityModel,
} from '@mui/x-data-grid';

export enum DocumentsDataTableViewTypes {
	GRID,
	TABLE,
}

interface DocumentsDataTableProps {
	columns: GridColDef[];
	rows: AttachmentRow[];
	apiRef: MutableRefObject<GridApiPro>;
	TableToolbar?: JSX.Element;
	title?: string;
	viewType: DocumentsDataTableViewTypes;
	handleColumnResize?: (params: GridColumnResizeParams) => void;
	handleColumnOrderChange?: () => void;
	handleColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
}

export const DocumentsDataTable: FC<DocumentsDataTableProps> = ({
	columns,
	rows,
	apiRef,
	TableToolbar,
	title,
	viewType,
	handleColumnOrderChange,
	handleColumnResize,
	handleColumnVisibilityModelChange,
}) => {
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const [displayedDocumentId, setDisplayedDocumentId] = useState(0);

	const { showDialog, openDialog, closeDialog } = useDialog();

	const files = rows.map((file) => {
		const mediaType = getMediaType(file.type);

		return {
			...file,
			mediaType,
		};
	});

	const handleRowClick = (params: GridRowParams) => {
		openDocumentModal(params.row.id);
	};

	const handleDocumentCardClick = (documentId: number) => {
		openDocumentModal(documentId);
	};

	const openDocumentModal = (documentId: number) => {
		setDisplayedDocumentId(documentId);
		openDialog();
	};

	const handlePrevClick = () => {
		handleNavigationClick('prev');
	};

	const handleNextClick = () => {
		handleNavigationClick('next');
	};

	const handleNavigationClick = (direction: 'prev' | 'next') => {
		const sortedRowsIds = rows.map((row) => row.id);
		const selectedRowIndex = sortedRowsIds.indexOf(displayedDocumentId);
		const previousRowIndex =
			(selectedRowIndex - 1 + sortedRowsIds.length) % sortedRowsIds.length;
		const nextRowIndex = (selectedRowIndex + 1) % sortedRowsIds.length;
		const nextRowId =
			sortedRowsIds[direction === 'prev' ? previousRowIndex : nextRowIndex];
		setDisplayedDocumentId(nextRowId as number);
	};

	return (
		<>
			{viewType === DocumentsDataTableViewTypes.GRID && (
				<Box>
					{(TableToolbar || title) && (
						<Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
							<StyledTitle>{title}</StyledTitle>
							<Box marginLeft="auto">{TableToolbar}</Box>
						</Box>
					)}
					<Stack direction="row" alignItems="flex-start" flexWrap="wrap" gap={2}>
						{files.map((file, index) => (
							<Button
								key={index}
								onClick={() => handleDocumentCardClick(file.id)}
								sx={{ padding: 0 }}
							>
								<FileCard
									fileType={file.type}
									mediaType={file.mediaType as MessageType}
									fileName={file.fileName}
									src={file.src}
									createdAt={file.createdAt}
									hasOrder={!!file.orders.length}
								/>
							</Button>
						))}
					</Stack>
				</Box>
			)}

			{viewType === DocumentsDataTableViewTypes.TABLE && (
				<TableV2
					ToolbarContent={TableToolbar}
					title={title}
					disableColumnFilter
					onColumnResize={handleColumnResize}
					onColumnOrderChange={handleColumnOrderChange}
					onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
					data-test="fleet-checks-table"
					localeText={
						tableLanguage === 'deDE'
							? deDE.components.MuiDataGrid.defaultProps.localeText
							: enUS.components.MuiDataGrid.defaultProps.localeText
					}
					density="compact"
					apiRef={apiRef}
					rows={rows}
					columns={columns}
					withColumnSettings
					disableDensitySelector
					initialState={{
						filter: {
							filterModel: {
								items: [],
								quickFilterValues: [''],
							},
						},
						pagination: { paginationModel: { pageSize: 15 } },
					}}
					slotProps={{}}
					sx={{
						backgroundColor: 'white',
						borderRadius: '5px',
						fontSize: '10px',
					}}
					onRowClick={handleRowClick}
				/>
			)}

			<DocumentModal
				showDialog={showDialog}
				closeDialog={closeDialog}
				attachment={rows.find((row) => row.id === displayedDocumentId)}
				handlePrevClick={handlePrevClick}
				handleNextClick={handleNextClick}
			/>
		</>
	);
};
