import Icon from '@mdi/react';
import styled, { css } from 'styled-components';
import { Button, Grid } from '@mui/material';

import { STYLE_2, colors } from '../../styles';

export const StyledNavigation = styled(Grid)`
	min-height: 69px;
	height: 69px;
	padding-right: 24px;
	padding-left: 24px;
	flex-wrap: nowrap;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.palette.primary.dark};
`;

export const StyledLogoWrapper = styled.div`
	display: flex;
	height: 100%;
	line-height: 0;
	padding-right: 24px;
	justify-content: center;
	align-items: center;
`;

export const StyledNavContainer = styled.div`
	display: flex;
`;

export const StyledNavList = styled.ul`
	list-style-type: none;
	display: flex;
	margin: 0;
	padding: 0;
	align-items: center;
	justify-content: center;
`;

export const StyledNavItem = styled(Button)<{ $active?: boolean; $disabled?: boolean }>`
	width: 120px;
	height: 100%;
	position: relative;
	padding: 0;
	border-radius: 0;
	background-color: ${({ theme }) => theme.palette.primary.dark};
	border: none;
	box-shadow: none;

	&:hover {
		background-color: ${({ theme }) => theme.palette.primary.darker};
		border: none;
		box-shadow: none !important;
	}

	a {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		color: ${({ theme }) => theme.palette.common.white};
		${STYLE_2};
		text-decoration: none;

		&:hover {
			opacity: 1;
			box-shadow: none;
		}

		${({ $active }) =>
			$active &&
			css`
				opacity: 1;
				background-color: ${({ theme }) => theme.palette.primary.darker};
			`}

		${({ $disabled }) =>
			$disabled &&
			css`
				pointer-events: none;
				cursor: pointer;
			`}
	}
`;

export const StyledToolbar = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
`;

export const StyledDotIcon = styled(Icon)`
	position: absolute;
	left: 80%;
	top: 8%;
	color: ${colors.red['main']};
	transform: scale(2);
`;

export const StyledOrderDotIcon = styled(StyledDotIcon)`
	right: 22%;
`;
