import { Fragment, useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import {
	StyledParameterInput,
	StyledTemplateInputWrapper,
} from './template-input.styles';
import { useDebounce, useTemplateMessage } from '../../../hooks';
import { templateRegEx } from '../../../util';

import type {
	WATplParamTextInterface,
	WhatsAppMessageTemplateResInterface,
} from '@heylog-app/shared/types';
import type { FC, KeyboardEventHandler } from 'react';

type TemplateInputProps = {
	template: WhatsAppMessageTemplateResInterface;
	readonly?: boolean;
	dataTest?: string;
	translatedTemplateParameters?: WATplParamTextInterface[];
	// translatedTemplateParameters?: WATplParamTextInterface[];
	onPressEnter: KeyboardEventHandler<Element>;
	setEmojiPickerField: (
		target: EventTarget & (HTMLTextAreaElement | HTMLInputElement),
	) => void;
	focusedFieldIndex: number | null;
	setFocusedFieldIndex: (num: number) => void;
	templateParameters?: WATplParamTextInterface[];
	setTemplateParameters?: React.Dispatch<
		React.SetStateAction<WATplParamTextInterface[] | undefined>
	>;
	templateCursorPosition: number | undefined;
};

export const TemplateInput: FC<TemplateInputProps> = ({
	template,
	setTemplateParameters,
	readonly = false,
	templateParameters,
	translatedTemplateParameters,
	dataTest,
	onPressEnter,
	setEmojiPickerField,
	focusedFieldIndex,
	setFocusedFieldIndex,
	templateCursorPosition,
}) => {
	const { contactId = '' } = useParams();
	const { setParameterValues, parameterValues, splitText, parameters } =
		useTemplateMessage({
			template,
			contactId,
		});

	const templateParamsRefs = useRef<HTMLInputElement[]>([]);

	const debouncedParameterValues = useDebounce<string[]>(parameterValues, 500);

	const onTemplateInputChange = useCallback(
		(value: string | undefined, parameterIndex: number) => {
			if (value !== undefined) {
				setParameterValues((oldValues: string[]) => {
					const newParameterValues = [...oldValues];
					newParameterValues[parameterIndex] = value;

					if (JSON.stringify(newParameterValues) !== JSON.stringify(oldValues)) {
						return [...newParameterValues];
					} else {
						return oldValues;
					}
				});
			}
		},
		[setParameterValues],
	);

	const setNewParameters = useCallback(
		(parameters: WATplParamTextInterface[]) => {
			const newParameterValues = parameters.reduce((acc, curr) => {
				if (curr.type === 'text') {
					return acc.concat(!curr.text.match(templateRegEx) ? curr.text : '');
				}
				return acc;
			}, [] as string[]);

			if (focusedFieldIndex !== null && newParameterValues[focusedFieldIndex]) {
				onTemplateInputChange(newParameterValues[focusedFieldIndex], focusedFieldIndex);
			}
		},
		[onTemplateInputChange, focusedFieldIndex],
	);

	useEffect(() => {
		if (templateParameters) {
			setNewParameters(
				translatedTemplateParameters ? translatedTemplateParameters : templateParameters,
			);
		}
	}, [templateParameters, translatedTemplateParameters, setNewParameters]);

	useEffect(() => {
		if (setTemplateParameters) {
			const newParameters = debouncedParameterValues.reduce((acc, curr) => {
				return acc.concat({ type: 'text', text: curr });
			}, [] as WATplParamTextInterface[]);

			setTemplateParameters((oldParams: WATplParamTextInterface[] | undefined) => {
				if (JSON.stringify(newParameters) !== JSON.stringify(oldParams)) {
					return [...newParameters];
				} else {
					return oldParams;
				}
			});
		}
	}, [debouncedParameterValues, setTemplateParameters, focusedFieldIndex]);

	useEffect(() => {
		const target =
			templateParamsRefs.current[focusedFieldIndex ? focusedFieldIndex + 2 : 1];

		if (target && templateCursorPosition !== undefined) {
			target.focus();
			target.setSelectionRange(templateCursorPosition, templateCursorPosition);
		}
	}, [
		debouncedParameterValues,
		templateParamsRefs,
		templateCursorPosition,
		focusedFieldIndex,
	]);

	return (
		<StyledTemplateInputWrapper data-test={dataTest}>
			{splitText?.map((item, index) => {
				if (item.match(templateRegEx)) {
					const parameterIndex = parameters?.indexOf(item) ?? -1;

					if (parameterIndex < 0) return null;

					return (
						<StyledParameterInput
							data-test="template-parameter-input"
							key={index}
							type="text"
							value={parameterValues[parameterIndex]}
							onChange={(e) => onTemplateInputChange(e.target.value, parameterIndex)}
							onKeyPress={onPressEnter}
							disabled={readonly}
							onFocus={(e) => {
								setEmojiPickerField(e.target);
								setFocusedFieldIndex(parameterIndex);
							}}
							inputRef={(input) => {
								templateParamsRefs.current[index] = input;
							}}
						/>
					);
				}
				return <Fragment key={index}>{item}</Fragment>;
			})}
		</StyledTemplateInputWrapper>
	);
};
