import { Button } from '@mui/material';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { STYLE_1, STYLE_2, colors } from '../../styles';

export const StyledLogo = styled.img`
	height: 85px;
	width: 85px;
`;

export const StyledMobileInfoWrapper = styled.div`
	min-height: 100vh;
	margin: 0 24px;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: auto;
`;

export const StyledMobileMainInfoWrapper = styled.div`
	height: 100%;
	margin-top: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const StyledText = styled.p`
	${STYLE_1}
	font-weight: 400;
	margin-top: 15px;
	margin-bottom: 22px;
	text-align: center;
`;

export const StyledButton = styled(Button)`
	${STYLE_2};
	min-width: 210px;
`;

export const StyledMobileAppIconWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	margin-top: 20px;
`;

export const StyledMobileAppIcon = styled(Button)`
	padding: 0;
`;

export const StyledHomeLink = styled(NavLink)`
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	text-decoration: underline;
	color: ${colors.primary.main};
	margin-top: auto;
	margin-bottom: 30px;

	:hover {
		color: ${colors.primary.lighter};
	}
`;
