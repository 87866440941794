import type { FC } from 'react';

export const SvgSr: FC = () => (
	<svg
		width="20"
		height="20"
		xmlns="http://www.w3.org/2000/svg"
		id="flag-icons-sr"
		viewBox="0 0 512 512"
	>
		<mask id="a">
			<circle cx="256" cy="256" r="256" fill="#fff" />
		</mask>
		<g mask="url(#a)">
			<path fill="#0052b4" d="m0 167 253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
			<path fill="#d80027" d="M0 0h512v167H0z" />
			<path fill="#eee" d="M0 345h512v167H0z" />
			<path
				fill="#d80027"
				d="M66.2 144.7v127.7c0 72.6 94.9 95 94.9 95s94.9-22.4 94.9-95V144.7z"
			/>
			<path
				fill="#ffda44"
				d="M105.4 167h111.4v-44.6l-22.3 11.2-33.4-33.4-33.4 33.4-22.3-11.2zm128.3 123.2-72.3-72.4L89 290.2l23.7 23.6 48.7-48.7 48.7 48.7z"
			/>
			<path
				fill="#eee"
				d="M233.7 222.6H200a22.1 22.1 0 0 0 3-11.1 22.3 22.3 0 0 0-42-10.5 22.3 22.3 0 0 0-41.9 10.5 22.1 22.1 0 0 0 3 11.1H89a23 23 0 0 0 23 22.3h-.7c0 12.3 10 22.2 22.3 22.2 0 11 7.8 20 18.1 21.9l-17.5 39.6a72.1 72.1 0 0 0 27.2 5.3 72.1 72.1 0 0 0 27.2-5.3L171.1 289c10.3-2 18.1-11 18.1-21.9 12.3 0 22.3-10 22.3-22.2h-.8a23 23 0 0 0 23-22.3z"
			/>
		</g>
	</svg>
);
