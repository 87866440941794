export const tractorSurveyJson = {
	checkErrorsMode: 'onValueChanged',
	showQuestionNumbers: 'off',
	showNavigationButtons: 'none',
	pages: [
		//page1 - photo-driver-license
		{
			name: 'page1',
			elements: [
				{
					type: 'file',
					name: 'photo-driver-license',
					title: {
						default: 'Please upload a pictures (front and back) of your driving license',
						en: 'Please upload a pictures (front and back) of your driving license',
						de: 'Bitte um ein Foto der Vorder- und Rückseite deines Führerscheins',
						et: 'Palun laadige üles pilte (esi- ja tagakülg) oma juhiloast',
						es: 'Por favor, sube una foto (anverso y reverso) de tu permiso de conducir',
						fr: 'Télécharge une photo (recto et verso) de ton permis de conduire',
						hr: 'Molimo prenesite slike (prednju i stražnju) svoje vozačke dozvole',
						it: 'Carica una foto (fronte e retro) della tua patente di guida',
						lv: 'Lūdzu, augšupielādējiet savas vadītāja apliecības fotoattēlus (priekšpusē un aizmugurē)',
						lt: 'Įkelkite vairuotojo pažymėjimo nuotraukas (priekyje ir gale)',
						hu: 'Kérjük, tölts fel egy képet (elõ és hát oldal) a jogosítványodról',
						pl: 'Prześlij zdjęcia (przód i tył) swojego prawa jazdy.',
						pt: 'Carrega uma fotografia (frente e verso) da tua carta de condução',
						ro: 'Vă rugăm să încărcați o fotografie (față și verso) a permisului de conducere',
						sk: 'Nahrajte fotografie (prednej a zadnej strany) svojho vodičského preukazu',
						rs: 'Отпремите слике (предње и задње) возачке дозволе',
						fi: 'Lataa ajokorttisi kuvat (etu- ja takaosa)',
						tr: 'Lütfen ehliyetinizin bir fotoğrafını (ön ve arka) yükleyin',
						cs: 'Nahrajte prosím fotografie (přední a zadní) svého řidičského průkazu',
						bg: 'Моля, качете снимки (отпред и отзад) на шофьорската си книжка',
						mk: 'Поставете слики (напред и назад) од вашата возачка дозвола',
						ru: 'Пожалуйста, загрузите фотографии (спереди и сзади) своего водительского удостоверения',
						ua: 'Будь ласка, завантажте фотографію вашого водійського посвідчення (спереду і ззаду)',
						sl: 'Prenesite slike (spredaj in zadaj) svojega vozniškega dovoljenja.',
						bs: 'Postavite slike (prednje i zadnje strane) vaše vozačke dozvole',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					isRequired: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page2 - plate-number-tractor
		{
			name: 'page2',
			elements: [
				{
					type: 'text',
					name: 'plate-number-tractor',
					title: {
						default: 'Plate number tractor',
						en: 'Plate number tractor',
						de: 'Zugmaschinen Kennzeichen',
						et: 'Traktori numbrimärk',
						es: 'Número de matrícula del tractor',
						fr: "Numéro d'immatriculation du tracteur",
						hr: 'Registarski broj traktora',
						it: 'Numero di targa del trattore',
						lv: 'Traktora numura zīme',
						lt: 'Traktoriaus valstybinis numeris',
						hu: 'Traktor rendszám',
						pl: 'Numer rejestracyjny ciągnika',
						pt: 'Matrícula do trator',
						ro: 'Număr de înmatriculare tractor',
						sk: 'Číslo evidenčného čísla traktora',
						rs: 'Трактор са таблицама',
						fi: 'Traktorin rekisterinumero',
						tr: 'Plaka numarası traktör',
						cs: 'Číslo SPZ traktoru',
						bg: 'Номер на табелата на трактора',
						mk: 'Трактор со број на таблички',
						ru: 'Трактор с номерным знаком',
						ua: 'Номерний знак трактора',
						sl: 'Številka registrske tablice traktorja',
						bs: 'Traktor sa tablicama',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page3 - fill-level-fuel
		{
			name: 'page3',
			elements: [
				{
					type: 'dropdown',
					name: 'fill-level-fuel',
					isRequired: true,
					choices: [
						{
							value: '100%',
						},
						{
							value: '75%',
						},
						{
							value: '50%',
						},
						{
							value: '25%',
						},
						{
							value: 'less',
							text: {
								default: 'less than 25%',
								en: 'less than 25%',
								de: 'Darunter 25%',
								et: 'Vähem kui 25%',
								es: 'Menos del 25%',
								fr: 'Moins de 25%',
								hr: 'Manje od 25%',
								it: 'Meno del 25%',
								lv: 'Mazāk nekā 25%',
								lt: 'Mažiau nei 25%',
								hu: 'Kevesebb mint 25%',
								pl: 'Mniej niż 25%',
								pt: 'Menos de 25%',
								ro: 'Mai puțin de 25%',
								sk: 'Menej ako 25 %',
								rs: 'Мање од 25%',
								fi: 'Alle 25%',
								tr: "25'ten az",
								cs: 'Méně než 25%',
								bg: 'По-малко от 25%',
								mk: 'Помалку од 25%',
								ru: 'Менее 25%',
								ua: 'Менше ніж 25%',
								sl: 'Manj kot 25%',
								bs: 'Manje od 25%',
							},
						},
					],
					title: {
						default: 'Fill level fuel',
						en: 'Fill level fuel',
						de: 'Füllstand Tank',
						et: 'Kütuse täitetase',
						es: 'Nivel de llenado de combustible',
						fr: 'Remplir le niveau de carburant',
						hr: 'Napunite razinu goriva',
						it: 'Livello di riempimento del carburante',
						lv: 'Degvielas uzpildes līmenis',
						lt: 'Degalų užpildymo lygis',
						hu: 'Üzemanyag töltöttségi szint',
						pl: 'Poziom paliwa',
						pt: 'Nível de enchimento do combustível',
						ro: 'Nivel de umplere combustibil',
						sk: 'Naplňte hladinu paliva',
						rs: 'Напуните ниво горива',
						fi: 'Polttoaineen täyttötaso',
						tr: 'Yakıt dolum seviyesi',
						cs: 'Hladina náplně paliva',
						bg: 'Ниво на зареждане с гориво',
						mk: 'Наполнете го нивото на гориво',
						ru: 'Уровень наполнения топливом',
						ua: 'Рівень пального',
						sl: 'Napolnite nivo goriva',
						bs: 'Napunite nivo goriva',
					},
					maxLength: 30,
				},
			],
		},
		//page4 - fill-level-adblue
		{
			name: 'page4',
			elements: [
				{
					type: 'dropdown',
					name: 'fill-level-adblue',
					isRequired: true,
					choices: [
						{
							value: '100%',
						},
						{
							value: '75%',
						},
						{
							value: '50%',
						},
						{
							value: '25%',
						},
						{
							value: 'less',
							text: {
								default: 'less than 25%',
								en: 'less than 25%',
								de: 'Darunter 25%',
								et: 'Vähem kui 25%',
								es: 'Menos del 25%',
								fr: 'Moins de 25%',
								hr: 'Manje od 25%',
								it: 'Meno del 25%',
								lv: 'Mazāk nekā 25%',
								lt: 'Mažiau nei 25%',
								hu: 'Kevesebb mint 25%',
								pl: 'Mniej niż 25%',
								pt: 'Menos de 25%',
								ro: 'Mai puțin de 25%',
								sk: 'Menej ako 25 %',
								rs: 'Мање од 25%',
								fi: 'Alle 25%',
								tr: "25'ten az",
								cs: 'Méně než 25%',
								bg: 'По-малко от 25%',
								mk: 'Помалку од 25%',
								ru: 'Менее 25%',
								ua: 'Менше ніж 25%',
								sl: 'Manj kot 25%',
								bs: 'Manje od 25%',
							},
						},
					],
					title: {
						default: 'Fill level adblue',
						en: 'Fill level adblue',
						de: 'Füllstand AdBlue',
						et: 'Täitmise tase adblue',
						es: 'Nivel de llenado adblue',
						fr: 'Niveau de remplissage adblue',
						hr: 'Täitmise tase adblue',
						it: 'Livello di riempimento adblue',
						lv: 'Adblue uzpildes līmenis',
						lt: 'Užpildymo lygis adblue',
						hu: 'Adblue töltöttségi szint',
						pl: 'Poziom napełnienia adblue',
						pt: 'Nível de enchimento de adblue',
						ro: 'Nivelul de umplere adblue',
						sk: 'Úroveň naplnenia adblue',
						rs: 'Таитмисе тасе адблуе',
						fi: 'Täyttötaso adblue',
						tr: 'Adblue dolum seviyesi',
						cs: 'Hladina náplně adblue',
						bg: 'Ниво на запълване с adblue',
						mk: 'Ниво на пополнување adblue',
						ru: 'Уровень заполнения adblue',
						ua: 'Рівень заповнення adblue',
						sl: 'Nivo polnjenja adblue',
						bs: 'Napunite nivo adblue',
					},
					maxLength: 30,
				},
			],
		},
		//page5 - driver-cabin-cleaned
		{
			name: 'page5',
			elements: [
				{
					type: 'boolean',
					name: 'driver-cabin-cleaned',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Driver cabin cleaned?',
						en: 'Driver cabin cleaned?',
						de: 'Fahrerkabine gereinigt?',
						et: 'Juhikabiin puhastatud?',
						es: '¿Limpiaste la cabina del conductor?',
						fr: 'La cabine du conducteur a-t-elle été nettoyée ?',
						hr: 'Vozačka kabina očišćena?',
						it: 'La cabina del conducente è stata pulita?',
						lv: 'Autovadītāja kabīnes tīrīšana?',
						lt: 'Išvalyta vairuotojo kabina?',
						hu: 'A vezetőfülke kitakarítva?',
						pl: 'Czyszczenie kabiny kierowcy?',
						pt: 'Limpaste o habitáculo do condutor?',
						ro: 'Cabina șoferului a fost curățată?',
						sk: 'Vyčistená kabína vodiča?',
						rs: 'Возачка кабина очишћена?',
						fi: 'Kuljettajan ohjaamo puhdistettu?',
						tr: 'Sürücü kabini temizlendi mi?',
						cs: 'Vyčištěná kabina řidiče?',
						bg: 'Почистена е кабината на водача?',
						mk: 'Исчистена е кабината на возачот?',
						ru: 'Кабина водителя убрана?',
						ua: 'Кабіна водія прибрана?',
						sl: 'Voznikova kabina očiščena?',
						bs: 'Vozačka kabina očišćena?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page6 - photo-cabin-driver-side
		{
			name: 'page6',
			elements: [
				{
					type: 'file',
					name: 'photo-cabin-driver-side',
					isRequired: true,
					title: {
						default: "Photo cabin driver's side",
						en: "Photo cabin driver's side",
						de: 'Foto Fahrerseite',
						et: 'Foto juhikabiini juhipoolne külg',
						es: 'Foto cabina lado conductor',
						fr: 'Photo cabine côté conducteur',
						hr: 'Foto kabina s vozačeve strane',
						it: 'Foto cabina lato guida',
						lv: 'Foto kabīne vadītāja pusē',
						lt: 'Nuotraukų kabina vairuotojo pusėje',
						hu: 'Fotó vezetőoldali fülke',
						pl: 'Zdjęcie kabiny po stronie kierowcy',
						pt: 'Cabina fotográfica do lado do condutor',
						ro: 'Cabină foto partea șoferului',
						sk: 'Fotografia kabíny na strane vodiča',
						rs: 'Слика возачеве стране кабине', // serbian
						fi: 'Kuva ohjaamo kuljettajan puolella',
						tr: 'Fotoğraf kabini sürücü tarafı',
						cs: 'Foto kabiny řidiče',
						bg: 'Снимка на кабината от страната на водача',
						mk: 'Фото кабина возачка страна',
						ru: 'Фото кабины со стороны водителя',
						ua: 'Фото кабіни з боку водія',
						sl: 'Foto kabina na voznikovi strani',
						bs: 'Slika vozačeve strane kabine',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page7 - photo-cabin-passenger-side
		{
			name: 'page7',
			elements: [
				{
					type: 'file',
					name: 'photo-cabin-passenger-side',
					isRequired: true,
					title: {
						default: 'Photo cabin passenger side',
						en: 'Photo cabin passenger side',
						de: 'Foto Beifahrerseite',
						et: 'Foto salongi sõitjapoolne külg',
						es: 'Foto cabina lado pasajero',
						fr: 'Photo cabine côté passager',
						hr: 'Foto kabina sa suvozačeve strane',
						it: 'Foto cabina lato passeggero',
						lv: 'Foto salona pasažiera pusēV',
						lt: 'Nuotraukų kabina keleivio pusėje',
						hu: 'Fénykép kabin utas oldala',
						pl: 'Zdjęcie kabiny po stronie pasażera',
						pt: 'Foto cabina lado do passageiro',
						ro: 'Foto cabină partea pasagerului',
						sk: 'Fotografia kabíny na strane spolujazdca',
						rs: 'Фотографија кабине на страни сувозача', // serbian
						fi: 'Valokuva matkustamon matkustajan puolella',
						tr: 'Fotoğraf kabin yolcu tarafı',
						cs: 'Fotografie kabiny spolujezdce',
						bg: 'Снимка на кабината от страната на пътника',
						mk: 'Фото кабина совозачка страна',
						ru: 'Фото кабины с пассажирской стороны',
						ua: 'Фото кабіни з пасажирського боку',
						sl: 'Foto kabina potniška stran',
						bs: 'Fotografija kabine sa suvozačke strane',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page8 - photo-refrigerator
		{
			name: 'page8',
			elements: [
				{
					type: 'file',
					name: 'photo-refrigerator',
					isRequired: true,
					title: {
						default: 'Photo fridge incl. drawer',
						en: 'Photo fridge incl. drawer',
						de: 'Bild Kühlschrank inkl. Schublade',
						et: 'Fotokülmik koos sahtliga',
						es: 'Foto nevera incl. cajón',
						fr: 'Réfrigérateur photo avec tiroir',
						hr: 'Foto hladnjak uklj. ladica',
						it: 'Frigo fotografico con cassetto',
						lv: 'Foto ledusskapis ar atvilktni',
						lt: 'Foto šaldytuvas su stalčiumi',
						hu: 'Fotó hűtőszekrény fiókkal együtt',
						pl: 'Lodówka fotograficzna z szufladą',
						pt: 'Frigorífico fotográfico com gaveta',
						ro: 'Frigider foto incl. sertar',
						sk: 'Foto chladnička vrátane zásuvky',
						rs: 'Фрижидер за фотографије укљ. фиока', // serbian
						fi: 'Valokuvajääkaappi sis. laatikon',
						tr: 'Çekmece dahil fotoğraf dolabı',
						cs: 'Foto lednice včetně zásuvky',
						bg: 'Фотохладилник с чекмедже',
						mk: 'Фото фрижидер вкл. фиока',
						ru: 'Фото холодильника в комплекте с выдвижным ящиком',
						ua: 'Холодильник з шухлядою для фотографій',
						sl: 'Foto hladilnik, vključno s predalom',
						bs: 'Foto frižider uklj. crtač',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page9 - cleaned-outside
		{
			name: 'page9',
			elements: [
				{
					type: 'boolean',
					name: 'cleaned-outside',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Cleaned outside?',
						en: 'Cleaned outside?',
						de: 'Außen gereinigt?',
						et: 'Väljas puhastatud?',
						es: '¿Limpiaste el exterior?',
						fr: 'Vani očišćeno?',
						hr: 'Vani očišćeno?',
						it: "Pulito all'esterno?",
						lv: 'Tīrīts ārpusē?',
						lt: 'Išvalyta lauke?',
						hu: 'Kívülről megtisztítva?',
						pl: 'Sprzątałeś na zewnątrz?',
						pt: 'Limpaste o exterior?',
						ro: 'Ați curățat afară?',
						sk: 'Vyčistené vonku?',
						rs: 'Очишћени напољу?',
						fi: 'Puhdistettu ulkona?',
						tr: 'Dışarısı temizlendi mi?',
						cs: 'Vyčištěno venku?',
						bg: 'Почистено отвън?',
						mk: 'Исчистена надвор?',
						ru: 'Почистил снаружи?',
						ua: 'Зовні прибрали?',
						sl: 'Očiščeno zunaj?',
						bs: 'Očišćeni napolju?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page10 - photo-tractor-front
		{
			name: 'page10',
			elements: [
				{
					type: 'file',
					name: 'photo-tractor-front',
					isRequired: true,
					title: {
						default: 'Photo from the front',
						en: 'Photo from the front',
						de: 'Foto von vorne',
						et: 'Foto esiplaanilt',
						es: 'Foto del frente',
						fr: "Photo de l'avant",
						hr: 'Fotografija s prednje strane',
						it: 'Foto dal fronte',
						lv: 'Foto no priekšpuses',
						lt: 'Nuotrauka iš priekio',
						hu: 'Fotó elölről',
						pl: 'Zdjęcie od przodu',
						pt: 'Foto da frente',
						ro: 'Fotografie din față',
						sk: 'Fotografia spredu',
						rs: 'Фотографија са предње стране', // serbian
						fi: 'Kuva edestä',
						tr: 'Ön taraftan fotoğraf',
						cs: 'Fotografie zepředu',
						bg: 'Снимка отпред',
						mk: 'Фотографија од напред',
						ru: 'Фотография спереди',
						ua: 'Фото спереду',
						sl: 'Fotografija s sprednje strani',
						bs: 'Fotografija sa prednje strane',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page11 - photo-tractor-drivers-side
		{
			name: 'page11',
			elements: [
				{
					type: 'file',
					name: 'photo-tractor-drivers-side',
					isRequired: true,
					title: {
						default: "Photo from the driver's side",
						en: "Photo from the driver's side",
						de: 'Foto von der Fahrerseite',
						et: 'Foto juhi poolelt',
						es: 'Foto desde el lado del conductor',
						fr: 'Photo du côté conducteur',
						hr: 'Fotografija s vozačeve strane',
						it: 'Foto dal lato del conducente',
						lv: 'Foto no vadītāja puses',
						lt: 'Vairuotojo pusės nuotrauka',
						hu: 'Fénykép a vezető oldaláról',
						pl: 'Zdjęcie od strony kierowcy',
						pt: 'Foto do lado do condutor',
						ro: 'Fotografie din partea șoferului',
						sk: 'Fotografia zo strany vodiča',
						rs: 'Фотографија са возачеве стране', // serbian
						fi: 'Kuva kuljettajan puolelta',
						tr: 'Sürücü tarafından fotoğraf',
						cs: 'Fotografie ze strany řidiče',
						bg: 'Снимка от страната на водача',
						mk: 'Фотографија од возачката страна',
						ru: 'Фотография со стороны водителя',
						ua: 'Фото з боку водія',
						sl: 'Fotografija z voznikove strani',
						bs: 'Fotografija sa vozačeve strane',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page12 - photo-tractor-passenger-side
		{
			name: 'page12',
			elements: [
				{
					type: 'file',
					name: 'photo-tractor-passenger-side',
					isRequired: true,
					title: {
						default: 'Photo from the passenger side',
						en: 'Photo from the passenger side',
						de: 'Foto von der Beifahrerseite',
						et: 'Foto kaassõitja poolelt',
						es: 'Foto desde el lado del pasajero',
						fr: 'Photo du côté passager',
						hr: 'Fotografija sa suvozačeve strane',
						it: 'Foto dal lato passeggero',
						lv: 'Foto no pasažiera puses',
						lt: 'Nuotrauka iš keleivio pusės',
						hu: 'Fotó az utasoldalról',
						pl: 'Zdjęcie od strony pasażera',
						pt: 'Foto do lado do passageiro',
						ro: 'Fotografie din partea pasagerului',
						sk: 'Fotografia zo strany spolujazdca',
						rs: 'Фотографија са сувозачеве стране', // serbian
						fi: 'Kuva matkustajan puolelta',
						tr: 'Yolcu tarafından fotoğraf',
						cs: 'Fotografie ze strany spolujezdce',
						bg: 'Снимка от страната на пътника',
						mk: 'Фотографија од совозачката страна',
						ru: 'Фотография со стороны пассажира',
						ua: 'Фото з боку пасажира',
						sl: 'Fotografija s sovoznikove strani',
						bs: 'Fotografija sa suvozačeve strane',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page13 - tires-steering-axle
		{
			name: 'page13',
			elements: [
				{
					type: 'boolean',
					name: 'tires-steering-axle',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Tires steering axle ok?',
						en: 'Tires steering axle ok?',
						de: 'Reifen Lenkachse ok?',
						et: 'Rehvid roolitakseel ok?',
						es: '¿Los neumáticos dirigen bien el eje?',
						fr: "Les pneus et l'essieu directeur sont en bon état?",
						hr: 'Gume upravljačka osovina u redu?',
						it: "I pneumatici dell'asse sterzante sono a posto?",
						lv: 'Riepas stūres ass ir kārtībā?',
						lt: 'Padangos vairuojamoji ašis tvarkinga?',
						hu: 'Gumiabroncsok a kormányzott tengelyen rendben?',
						pl: 'Opony, oś skrętna w porządku?',
						pt: 'Os pneus e o eixo direcional estão bem?',
						ro: 'Anvelopele puntea de direcție sunt în regulă?',
						sk: 'Pneumatiky riadiaca náprava v poriadku?',
						rs: 'Гуме управљачка осовина у реду?',
						fi: 'Renkaat ohjausakseli ok?',
						tr: 'Lastikler direksiyon aksı tamam mı?',
						cs: 'Pneumatiky řídicí náprava v pořádku?',
						bg: 'Гуми кормилен мост ок?',
						mk: 'Гуми, управувачка оска во ред?',
						ru: 'Шины рулевой оси в порядке?',
						ua: 'Шини рульової осі в порядку?',
						sl: 'Pnevmatike krmilna os v redu?',
						bs: 'Gume upravljačka osovina ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{tires-steering-axle} = "No"',
					type: 'file',
					name: 'photo-tires-steering-axle',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page14 - tires-drive-axle
		{
			name: 'page14',
			elements: [
				{
					type: 'boolean',
					name: 'tires-drive-axle',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Tires drive axle ok?',
						en: 'Tires drive axle ok?',
						de: 'Reifen Antriebachse ok?',
						et: 'Rehvid sõita telgiga ok?',
						es: '¿Los neumáticos conducen bien el eje?',
						fr: "Les pneus et l'essieu moteur sont en bon état ?",
						hr: 'Gume pogonska osovina u redu?',
						it: "I pneumatici dell'asse motore sono a posto?",
						lv: 'Riepas piedziņas ass ok?',
						lt: 'Padangos varomoji ašis gerai?',
						hu: 'Gumiabroncsok meghajtó tengelyen rendben?',
						pl: 'Opony na osi napędowej w porządku?',
						pt: 'Os pneus do eixo motor estão bem?',
						ro: 'Anvelopele conduc axa ok?',
						sk: 'Pneumatiky poháňajú nápravu v poriadku?',
						rs: 'Гуме погонска осовина у реду?',
						fi: 'Renkaiden vetoakseli ok?',
						tr: 'Lastikler tahrik aksı tamam mı?',
						cs: 'Pneumatiky pohánějí nápravu v pořádku?',
						bg: 'Гумите задвижват оста добре?',
						mk: 'Гумите погонска оска во ред?',
						ru: 'Шины ведущей оси в порядке?',
						ua: 'Шини ведучої осі в порядку?',
						sl: 'Pnevmatike pogonska os v redu?',
						bs: 'Gume pogonska osovina ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{tires-drive-axle} = "No"',
					type: 'file',
					name: 'photo-tires-drive-axle',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page15 - lighting
		{
			name: 'page15',
			elements: [
				{
					type: 'boolean',
					name: 'lighting',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Lighting ok?',
						en: 'Lighting ok?',
						de: 'Beleuchtung ok?',
						et: 'Valgustus ok?',
						es: '¿Está bien la iluminación?',
						fr: 'Eclairage ok?',
						hr: 'Rasvjeta u redu?',
						it: "L'illuminazione va bene?",
						lv: 'Apgaismojums ir kārtībā?',
						lt: 'Apšvietimas tvarkingas?',
						hu: 'Világítás rendben?',
						pl: 'Oświetlenie w porządku?',
						pt: 'A iluminação está boa?',
						ro: 'Iluminarea este în regulă?',
						sk: 'Osvetlenie je v poriadku?',
						rs: 'Осветљење ОК?',
						fi: 'Onko valaistus kunnossa?',
						tr: 'Işıklandırma iyi mi?',
						cs: 'Osvětlení je v pořádku?',
						bg: 'Осветлението е наред?',
						mk: 'Осветлувањето е во ред?',
						ru: 'Освещение в порядке?',
						ua: 'Освітлення в порядку?',
						sl: 'Razsvetljava je v redu?',
						bs: 'Osvetljenje OK?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{lighting} = "No"',
					type: 'file',
					name: 'photo-lighting',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page16 - damage
		{
			name: 'page16',
			elements: [
				{
					type: 'boolean',
					name: 'damage',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Damage?',
						en: 'Damage?',
						de: 'Schäden?',
						et: 'Kahju?',
						es: '¿Daños?',
						fr: 'Les dégâts?',
						hr: 'Šteta?',
						it: 'Danni?',
						lv: 'Bojājumi?',
						lt: 'Žala?',
						hu: 'Sérülés?',
						pl: 'Uszkodzenie?',
						pt: 'Danos?',
						ro: 'Daune?',
						sk: 'Poškodenie?',
						rs: 'Штета?',
						fi: 'Vahinkoja?',
						tr: 'Hasar mı?',
						cs: 'Poškození?',
						bg: 'Щети?',
						mk: 'Штета?',
						ru: 'Повреждения?',
						ua: 'Пошкодження?',
						sl: 'Škoda?',
						bs: 'Šteta?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{damage} = "Yes"',
					type: 'file',
					name: 'photo-damage',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page17 - board-folder
		{
			name: 'page17',
			elements: [
				{
					type: 'boolean',
					name: 'board-folder',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Board folder ok?',
						en: 'Board folder ok?',
						de: 'Bordmappe ok?',
						et: 'Juhatuse kausta ok?',
						es: '¿Está bien la carpeta del tablón?',
						fr: "Le dossier du conseil d'administration est ok ?",
						hr: 'Mapa ploče u redu?',
						it: 'La cartella del consiglio va bene?',
						lv: 'Board mape ok?',
						lt: 'Valdybos aplankas gerai?',
						hu: 'Fedélzeti mappa rendben?',
						pl: 'Folder zarządu w porządku?',
						pt: 'Podes colocar a pasta do quadro?',
						ro: 'Dosarul de bord ok?',
						sk: 'Priečinok s doskami je v poriadku?',
						rs: 'Фасцикла плоче ок?',
						fi: 'Hallituksen kansio ok?',
						tr: 'Pano dosyası tamam mı?',
						cs: 'Složka desek v pořádku?',
						bg: 'Папката на борда е добре?',
						mk: 'Папка на табла во ред?',
						ru: 'Папка Board в порядке?',
						ua: 'Папка з дошкою в порядку?',
						sl: 'Mapa odbora v redu?',
						bs: 'Folder ploče ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page18 - board-toolkit
		{
			name: 'page18',
			elements: [
				{
					type: 'boolean',
					name: 'board-toolkit',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'On-board tool kit ok?',
						en: 'On-board tool kit ok?',
						de: 'Bordwerkzeug ok?',
						et: 'Pardal olev tööriistakomplekt ok?',
						es: '¿Está bien el kit de herramientas de a bordo?',
						fr: "Le kit d'outils de bord est bon ?",
						hr: 'Ugrađeni alat je u redu?',
						it: 'Il kit di strumenti di bordo va bene?',
						lv: 'Borta instrumentu komplekts ir kārtībā?',
						lt: 'Borto įrankių rinkinys tinkamas?',
						hu: 'Fedélzeti szerszámkészlet rendben?',
						pl: 'Zestaw narzędzi pokładowych w porządku?',
						pt: 'Tens um kit de ferramentas a bordo?',
						ro: 'Trusa de scule de la bord este în regulă?',
						sk: 'Palubná súprava náradia v poriadku?',
						rs: 'Комплет алата на плочи у реду?',
						fi: 'Sisäinen työkalupakki ok?',
						tr: 'Araç üstü alet kiti tamam mı?',
						cs: 'Sada palubního nářadí v pořádku?',
						bg: 'Бордовият комплект инструменти е добре?',
						mk: 'Вграден комплет со алатки во ред?',
						ru: 'Бортовой набор инструментов в порядке?',
						ua: 'Бортовий набір інструментів в порядку?',
						sl: 'Vgrajen komplet orodij v redu?',
						bs: 'Ugrađeni komplet alata je u redu?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page19 - camera-cable
		{
			name: 'page19',
			elements: [
				{
					type: 'boolean',
					name: 'camera-cable',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Camera cable for trailer ok?',
						en: 'Camera cable for trailer ok?',
						de: 'Kamerakabel für Auflieger ok?',
						et: 'Kaamera kaabel haagise jaoks ok?',
						es: '¿Está bien el cable de la cámara para el remolque?',
						fr: 'Le câble de la caméra pour la remorque est ok ?',
						hr: 'Kabel kamere za prikolicu u redu?',
						it: 'Il cavo della telecamera per il rimorchio va bene?',
						lv: 'Kameras kabelis piekabei ok?',
						lt: 'Kameros kabelis priekabai tinka?',
						hu: 'Kamerakábel az utánfutóhoz rendben?',
						pl: 'Kabel do kamery w przyczepie ok?',
						pt: 'Podes usar o cabo da câmara para o atrelado?',
						ro: 'Cablul camerei pentru remorcă este în regulă?',
						sk: 'Kábel kamery pre príves v poriadku?',
						rs: 'Кабл камере за приколицу у реду?',
						fi: 'Kamerakaapeli perävaunua varten ok?',
						tr: 'Römork için kamera kablosu tamam mı?',
						cs: 'Kabel kamery pro přívěs v pořádku?',
						bg: 'Кабелът на камерата за ремаркето е добре?',
						mk: 'Кабел за камера за приколка во ред?',
						ru: 'Кабель камеры для прицепа подойдет?',
						ua: 'Кабель камери для причепа в порядку?',
						sl: 'Kabel kamere za prikolico v redu?',
						bs: 'Kabl kamere za prikolicu ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page20 - display-error-messages
		{
			name: 'page20',
			elements: [
				{
					type: 'boolean',
					name: 'display-error-messages',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Error message on display?',
						en: 'Error message on display?',
						de: 'Fehlermeldungen am Display?',
						et: 'Veateade ekraanil?',
						es: '¿Mensaje de error en pantalla?',
						fr: "Message d'erreur sur l'écran ?",
						hr: 'Poruka o pogrešci na zaslonu?',
						it: 'Messaggio di errore sul display?',
						lv: 'Kļūdas ziņojums displejā?',
						lt: 'Klaidos pranešimas ekrane?',
						hu: 'Hibaüzenet a kijelzőn?',
						pl: 'Komunikat o błędzie na wyświetlaczu?',
						pt: 'Mensagem de erro no ecrã?',
						ro: 'Mesaj de eroare pe ecran?',
						sk: 'Chybová správa na displeji?',
						rs: 'Порука о грешци на екрану?',
						fi: 'Virheilmoitus näytössä?',
						tr: 'Ekranda hata mesajı var mı?',
						cs: 'Chybová zpráva na displeji?',
						bg: 'Съобщение за грешка на дисплея?',
						mk: 'Порака за грешка на екранот?',
						ru: 'Сообщение об ошибке на дисплее?',
						ua: "На екрані з'явилося повідомлення про помилку?",
						sl: 'Sporočilo o napaki na zaslonu?',
						bs: 'Poruka o grešci na ekranu?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{display-error-messages} = "Yes"',
					type: 'file',
					name: 'photo-display-error',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page21 - notes
		{
			name: 'page21',
			elements: [
				{
					type: 'text',
					name: 'notes-tractor',
					title: {
						default: 'Any other comments',
						en: 'Any other comments',
						de: 'Andere Anmerkungen',
						et: 'Muud kommentaarid',
						es: 'Otros comentarios',
						fr: 'Autres commentaires',
						hr: 'Bilo koji drugi komentari',
						it: 'Altri commenti',
						lv: 'Citi komentāri',
						lt: 'Kitos pastabos',
						hu: 'Egyéb észrevételek',
						pl: 'Inne uwagi',
						pt: 'Outras observaçõesse',
						ro: 'Orice alte observațiise',
						sk: 'Akékoľvek ďalšie pripomienky',
						rs: 'Било који други коментар',
						fi: 'Mahdolliset muut huomautuksetse',
						tr: 'Başka yorumunuz varsa',
						cs: 'Jakékoli další připomínky',
						bg: 'Други коментари',
						mk: 'Сите други коментари',
						ru: 'Любые другие комментарииse',
						ua: 'Будь-які інші коментарі',
						sl: 'Morebitne druge pripombe',
						bs: 'Bilo koji drugi komentar',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
	],
};
