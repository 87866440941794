import type { FC } from 'react';

export const SvgCs: FC = () => (
	<svg
		width="20"
		height="20"
		xmlns="http://www.w3.org/2000/svg"
		id="flag-icons-cs"
		viewBox="0 0 512 512"
	>
		<path fill="#fff" d="M0 0h512v256H0z" />
		<path fill="#d7141a" d="M0 256h512v256H0z" />
		<path fill="#11457e" d="M300 256 0 56v400z" />
	</svg>
);
