export const FormSvg = () => (
	<svg
		width="132"
		height="106"
		viewBox="0 0 132 106"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<ellipse cx="66" cy="47.5026" rx="44.9032" ry="46.5541" fill="#D1D4F2" />
		<ellipse cx="66" cy="47.5026" rx="35.0968" ry="36.3871" fill="#A3AAE6" />
		<path
			d="M64.6851 93.398C60.5624 97.7666 54.8124 100.478 48.4516 100.478C40.8593 100.478 34.1372 96.6151 30.0138 90.6818C27.9784 96.4003 22.6743 100.478 16.4516 100.478C8.47021 100.478 2 93.7698 2 85.4949C2 77.2201 8.47021 70.512 16.4516 70.512C20.063 70.512 23.3651 71.8854 25.8983 74.1559C27.2238 62.4615 36.8157 53.3887 48.4516 53.3887C56.2914 53.3887 63.2034 57.5074 67.2843 63.7715C70.6822 60.9573 74.9903 59.2748 79.6774 59.2748C88.3259 59.2748 95.6839 65.003 98.4144 72.9995C100.496 71.7575 102.91 71.0471 105.484 71.0471C113.04 71.0471 119.218 77.1693 119.653 84.8905C120.467 84.5889 121.344 84.4247 122.258 84.4247C126.534 84.4247 130 88.0183 130 92.4513C130 96.8842 126.534 100.478 122.258 100.478C119.309 100.478 116.745 98.7682 115.438 96.2527C112.876 98.8657 109.362 100.478 105.484 100.478C100.7 100.478 96.4697 98.0246 93.8983 94.2654C90.2897 98.0983 85.2516 100.478 79.6774 100.478C73.6939 100.478 68.3281 97.736 64.6851 93.398Z"
			fill="white"
		/>
		<path
			d="M2 85.4949C2 77.2201 8.47021 70.512 16.4516 70.512C20.063 70.512 23.3651 71.8854 25.8983 74.1559C27.2238 62.4615 36.8157 53.3887 48.4516 53.3887C56.2914 53.3887 63.2034 57.5074 67.2843 63.7715C70.6822 60.9573 74.9903 59.2748 79.6774 59.2748C88.3259 59.2748 95.6839 65.003 98.4144 72.9995C100.496 71.7575 102.91 71.0471 105.484 71.0471C113.04 71.0471 119.218 77.1693 119.653 84.8905C120.467 84.5889 121.344 84.4247 122.258 84.4247C126.534 84.4247 130 88.0183 130 92.4513"
			stroke="#343B64"
			strokeWidth="2.06618"
			strokeLinecap="round"
		/>
		<path
			d="M88.7929 92.0974C88.9945 92.3064 89.3213 92.3064 89.5228 92.0974L92.1715 89.3514C92.1805 89.3421 92.1943 89.3176 92.21 89.2862C92.2886 89.1294 92.3226 88.9514 92.3226 88.776L92.3226 24.493L40.1936 24.493L40.1936 88.5084L40.1936 88.776C40.1936 89.0435 40.313 89.3175 40.3456 89.3514L42.9943 92.0974C43.1958 92.3064 43.5226 92.3064 43.7242 92.0974L46.2216 89.5081L48.7191 92.0974C48.9207 92.3064 49.2475 92.3064 49.449 92.0974L51.9465 89.5081L54.4439 92.0974C54.6455 92.3064 54.9723 92.3064 55.1738 92.0974L57.6713 89.5081L60.1688 92.0974C60.3703 92.3064 60.6971 92.3064 60.8987 92.0974L63.3961 89.5081L65.8936 92.0974C66.0952 92.3064 66.422 92.3064 66.6235 92.0974L69.121 89.5081L71.6184 92.0974C71.82 92.3064 72.1468 92.3064 72.3483 92.0974L74.8458 89.5081L77.3433 92.0974C77.5448 92.3064 77.8716 92.3064 78.0732 92.0974L80.5706 89.5081L83.0681 92.0974C83.2696 92.3064 83.5964 92.3064 83.798 92.0974L86.2955 89.5081L88.7929 92.0974Z"
			fill="#F5F8FE"
			stroke="#343B64"
			strokeWidth="2"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M59.2903 55.5291C60.4305 55.5291 61.3549 54.5708 61.3549 53.3887C61.3549 52.2066 60.4305 51.2483 59.2903 51.2483C58.1501 51.2483 57.2258 52.2066 57.2258 53.3887C57.2258 54.5708 58.1501 55.5291 59.2903 55.5291ZM73.2258 55.5291C74.366 55.5291 75.2904 54.5708 75.2904 53.3887C75.2904 52.2066 74.366 51.2483 73.2258 51.2483C72.0856 51.2483 71.1613 52.2066 71.1613 53.3887C71.1613 54.5708 72.0856 55.5291 73.2258 55.5291Z"
			fill="#343B64"
		/>
		<path
			d="M57.7419 62.4857C59.6023 65.2953 62.7226 67.1379 66.258 67.1379C69.7935 67.1379 72.9138 65.2953 74.7742 62.4857"
			stroke="#343B64"
			strokeWidth="2.58272"
			strokeLinecap="round"
		/>
		<ellipse
			cx="49.4839"
			cy="16.4664"
			rx="1.54839"
			ry="1.60531"
			stroke="#343B64"
			strokeWidth="1.54963"
		/>
		<ellipse
			cx="107.29"
			cy="66.7663"
			rx="1.03226"
			ry="1.07021"
			stroke="#343B64"
			strokeWidth="1.54963"
		/>
		<path
			d="M49.4839 34.125H72.7097M49.4839 39.7436H63.4194"
			stroke="#ABC0DC"
			strokeWidth="1.54963"
			strokeLinecap="round"
		/>
		<path
			d="M45.3549 82.8193H87.1613M45.3549 77.4683H70.4387"
			stroke="#ABC0DC"
			strokeWidth="1.54963"
			strokeLinecap="round"
		/>
		<ellipse
			cx="26.5162"
			cy="58.4722"
			rx="2.32258"
			ry="2.40797"
			stroke="#343B64"
			strokeWidth="1.54963"
		/>
		<path
			d="M96.4694 25.0282H101.613M92.8387 20.4117V15.9314"
			stroke="#6E7191"
			strokeWidth="1.54963"
			strokeLinecap="round"
		/>
		<path
			d="M96.1669 21.3605L99.5881 17.8135"
			stroke="#6E7191"
			strokeWidth="1.54963"
			strokeLinecap="round"
		/>
		<ellipse cx="66.2581" cy="102.886" rx="26.0645" ry="2.40797" fill="#ABC0DC" />
	</svg>
);
