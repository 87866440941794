import type { Maybe } from '@heylog-app/shared/types';

// this is used in free flow where we only have a contact id
export const getEventsByContactIdKey = ({
	workspaceId,
	contactId = '-1',
}: {
	workspaceId: string | number;
	contactId: Maybe<string | number>;
}) => `/workspaces/${workspaceId}/entity-events/contact/${contactId}`;
