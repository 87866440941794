// helper function for better browser support (string.replaceAll())
export const replaceAll = (
	string: string,
	match: RegExp,
	replacement: string,
): string => {
	return string.replace(match, replacement);
};

export const getRawPhoneNumber = (string: string): string => {
	return replaceAll(string, / |-|\(|\)/g, '');
};

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
export const fixedEncodeURIComponent = (string: string) => {
	return encodeURIComponent(string).replace(/[!'()*]/g, (c) => {
		return '%' + c.charCodeAt(0).toString(16).toUpperCase();
	});
};

export function removeSpecialCharacters(input: string) {
	return input.replace(/[^-a-zA-Z0-9 ]/g, '');
}
