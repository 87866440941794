import { Chip, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAttachments } from './use-attachments.hooks';

import type { OrderResInterface } from '@heylog-app/shared/types';
import type { GridColDef } from '@mui/x-data-grid-pro';

export type AttachmentRow = {
	createdAt: Date;
	fileName: string;
	id: number;
	orders: OrderResInterface[];
	src: string;
	type: string;
};

export const useAttachmentsData = () => {
	const { t } = useTranslation();
	// example of custom subcomponent rendering
	// https://codesandbox.io/p/sandbox/datagrid-text-avatar-example-v6b9l?file=%2Findex.js

	const columns: GridColDef[] = [
		{
			field: 'type',
			headerName: t('attachments.fileType'),
			flex: 1,
		},
		{
			field: 'fileName',
			headerName: t('attachments.fileName'),
			flex: 1,
		},
		{
			field: 'createdAt',
			headerName: t('attachments.date'),
			flex: 1,
			type: 'date-time',
			valueFormatter: (params) =>
				`${params.value.toLocaleDateString()} ${params.value.toLocaleTimeString()}`,
		},
		// {
		// 	field: 'linked',
		// 	headerName: t(`messages.linked`),
		// 	flex: 1,
		// 	renderCell: (params) => {
		// 		if (!params.value) return null;
		// 		return <Icon path={mdiCheck} size={1} color="blue" />;
		// 	},
		// },
		{
			field: 'labels',
			headerName: t('attachments.labels'),
			flex: 2,
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" gap={1}>
						{params.value.map((team: string, index: number) => {
							return (
								<Chip
									sx={{ bgcolor: '#198A1F', fontSize: '10px', color: 'white' }}
									key={index}
									size="small"
									label={team}
								/>
							);
						})}
					</Stack>
				);
			},
		},
		{
			field: 'orders',
			headerName: 'Internal Reference',
			flex: 1,
			renderCell: ({ value }) => {
				if (!value.length) return null;

				return (
					<Stack direction="row" gap={1}>
						{value
							.map((order: OrderResInterface) => {
								return order.refNumber;
							})
							.join(', ')}
					</Stack>
				);
			},
		},
	];

	const { attachments } = useAttachments();

	const rows: AttachmentRow[] =
		attachments?.map((attachment) => {
			const createdAt = new Date(attachment.createdAt);
			return {
				createdAt,
				fileName: attachment.name || '',
				id: attachment.id,
				orders: attachment.orders,
				src: attachment.url,
				type: attachment.type,
			};
		}) || [];

	return { columns, rows };
};
