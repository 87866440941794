export const HeylogIconBlue = () => (
	<svg
		width="145"
		height="40"
		viewBox="0 0 145 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M42.0556 40V4.84203C42.0556 2.16978 39.8858 0 37.2059 0H4.84964C2.16978 0 0 2.16978 0 4.84203V27.5828C0 30.255 2.16978 32.4248 4.84964 32.4248H30.7194L42.048 39.9848L42.0556 40Z"
			fill="#122CC5"
		/>
		<path
			d="M30.5596 26.1134L25.4206 6.67682H21.9871L22.2992 14.5641H19.665L20.0609 6.67682H16.635L11.4884 26.1134L19.0864 26.182L19.5356 18.7514H22.3982L22.756 26.182L30.5596 26.1134Z"
			fill="white"
		/>
		<path
			d="M36.6807 14.5641V18.7514H32.8207L31.0545 20.9669L29.2501 14.5641H36.6807Z"
			fill="white"
		/>
		<path
			d="M5.36731 14.5641V18.7514H9.22723L10.8869 20.9669L12.7978 14.5641H5.36731Z"
			fill="white"
		/>
	</svg>
);
