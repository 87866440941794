import { matchPath } from 'react-router-dom';

export const matchNavigationPath = (pathname: string, routes: Record<string, string>) => {
	let hasMatch = false;

	Object.values(routes).forEach((route) => {
		if (matchPath(route, pathname)) {
			hasMatch = true;
		}
	});

	return hasMatch;
};
