import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const HeylogPluginEnum = mkenum({
	DemoPlugin: 'DemoPlugin',
	FleetCheckPlugin: 'FleetCheckPlugin',
	FleetCheckReportPlugin: 'FleetCheckReportPlugin',
	FrontendV2Plugin: 'FrontendV2Plugin',
	KuehneNagelOnboardingPlugin: 'KuehneNagelOnboardingPlugin',
	LiveLocationPlugin: 'LiveLocationPlugin',
	OSUPlugin: 'OSUPlugin',
	OSUReminderPlugin: 'OSUReminderPlugin',
	ProofOfDeliveryPlugin: 'ProofOfDeliveryPlugin',
	TSUPlugin: 'TSUPlugin',
	TaskReminderPlugin: 'TaskReminderPlugin',
	TimeMattersOSUPlugin: 'TimeMattersOSUPlugin',
});

export type HeylogPlugin = TypeFromPEnum<typeof HeylogPluginEnum>;
