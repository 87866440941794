import { useState } from 'react';
import { Button } from '@mui/material';

import { EmailFile } from './email-file';
import { StyledUploadedFilesList } from './uploaded-files.styles';

type EmailFilesProps = {
	files: File[];
	showMargin: boolean;
};

export const EmailFiles = ({ files, showMargin }: EmailFilesProps) => {
	const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

	const handleSelectFile = (file: File) => {
		const selected = selectedFiles.includes(file);

		if (selected) {
			setSelectedFiles((prev) => prev.filter((f) => f !== file));
		} else {
			setSelectedFiles((prev) => [...prev, file]);
		}
	};

	const handleAttachToOrder = () => {
		console.log('attach to order', selectedFiles);
	};

	return (
		<>
			<StyledUploadedFilesList $showMargin={showMargin} data-test="uploaded-files-list">
				{files.map((file, index) => {
					const selected = selectedFiles.includes(file);
					return (
						<EmailFile
							selected={selected}
							selectFile={handleSelectFile}
							key={file.size + index}
							file={file}
						/>
					);
				})}
			</StyledUploadedFilesList>

			<Button
				onClick={handleAttachToOrder}
				variant="outlined"
				sx={{ marginTop: 2 }}
				disabled={selectedFiles.length === 0}
			>
				Attach to order
			</Button>
		</>
	);
};
