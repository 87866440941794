import styled from 'styled-components';
import { Avatar } from '@mui/material';

import Image from './images/avatar-bg@2x.jpg';
import { STYLE_2, SIZE_XS, SIZE_SM, colors } from '../../../styles';

type StyledMessageAuthorProps = {
	fromInactiveUser: boolean;
};

export const StyledMessageAuthor = styled.div`
	display: flex;
	align-items: center;
	gap: 0.5rem;
	margin-bottom: 0.25rem;
`;

export const StyledMessageAuthorText = styled.div`
	${STYLE_2};
	${SIZE_SM};

	display: flex;
	align-items: baseline;
	gap: 0.5rem;
`;

export const StyledMessageTime = styled.span`
	${SIZE_XS};
	color: ${({ theme }) => theme.palette.greyblue[112]};
`;

export const StyledMessageAuthorName = styled.span<StyledMessageAuthorProps>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	color: ${(props) => (props.fromInactiveUser ? colors.greyblue[112] : '')};
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;
