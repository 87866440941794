import { ChevronRight } from '@mui/icons-material';

import { getCommunicationChannelIcon } from '@heylog-app/frontend-lib/app';

import { StyledButton } from './messenger-button.styles';

import type { FC } from 'react';
import type { MessagingTransportType } from '@heylog-app/shared/types';

type MessengerButtonProps = {
	type: MessagingTransportType;
	text: string;
	disabled?: boolean;
	onClick: () => void;
};

export const MessengerButton: FC<MessengerButtonProps> = ({
	onClick,
	text,
	type,
	...rest
}) => {
	const Icon = getCommunicationChannelIcon(type);

	return (
		<StyledButton
			variant="outlined"
			startIcon={<Icon />}
			endIcon={<ChevronRight />}
			sx={{ '& .MuiButton-endIcon': { marginLeft: 'auto' } }}
			onClick={onClick}
			{...rest}
		>
			{text}
		</StyledButton>
	);
};
