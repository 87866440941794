import { mkenum } from './mkenum';

export const ContactLanguageEnum = mkenum({
	DE: 'de',
	EN: 'en',
	PL: 'pl',
	CS: 'cs',
	RU: 'ru',
	RO: 'ro',
	HU: 'hu',
	SK: 'sk',
	SL: 'sl',
	LV: 'lv',
	LT: 'lt',
	TR: 'tr',
	BG: 'bg',
	ES: 'es',
	PT: 'pt',
	PTPT: 'pt-pt',
	PT_PT: 'pt_pt',
	FI: 'fi',
	IT: 'it',
	ET: 'et',
	FR: 'fr',
	UK: 'uk',
	BS: 'bs',
	SR: 'sr',
	HR: 'hr',
	MK: 'mk',
});

export type ContactLanguageType =
	typeof ContactLanguageEnum[keyof typeof ContactLanguageEnum];
