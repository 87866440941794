import { Box } from '@mui/material';
import { type FC, type ReactNode, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Navigation } from '../navigation/navigation';
import { StyledLayout } from './layout.styles';
import { NotificationFlashSnackbar } from '../browser-notifications/notification-flash-snackbar';
import { SidebarV2 } from '../sidebar-v2/sidebar-v2';
import { NavigationV2 } from '../navigation-v2/navigation-v2';
import { usePlugins } from '../../hooks';
import { NAVIGATION_V2_HEIGHT } from '../navigation-v2/navigation-v2.styles';
import { UiContext } from '../../providers';

export const SIDEBAR_V2_WIDTH_CLOSE = '64px';
export const SIDEBAR_V2_WIDTH_OPEN = '210px';

type LayoutProps = {
	children?: ReactNode;
};

export const Layout: FC<LayoutProps> = ({ children }) => {
	const { workspaceId = '' } = useParams();
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const uiContextData = useContext(UiContext);
	const { menu } = uiContextData || {};
	const sidebarWidth = menu?.isOpen ? SIDEBAR_V2_WIDTH_OPEN : SIDEBAR_V2_WIDTH_CLOSE;

	const onBurgerClick = () => menu?.setIsOpen(!menu.isOpen);

	return (
		<StyledLayout>
			{FrontendV2Plugin ? (
				<NavigationV2 onBurgerClick={onBurgerClick} isOpen={!!menu?.isOpen} />
			) : (
				<Navigation />
			)}
			<NotificationFlashSnackbar />
			{FrontendV2Plugin ? (
				<Box
					display="flex"
					height={`calc(100% - ${NAVIGATION_V2_HEIGHT})`}
					sx={{ transition: '.4s all ease' }}
				>
					<SidebarV2 isOpened={!!menu?.isOpen} width={sidebarWidth} />
					{children}
					{/* Important! - Used by TableFilterSidebar with Portal */}
					<div id="filter-container" />
				</Box>
			) : (
				children
			)}
		</StyledLayout>
	);
};
