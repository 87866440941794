import { Alert, Snackbar } from '@mui/material';
import styled from 'styled-components';

export const StyledSnackbar = styled(Snackbar)`
	top: 64px;
`;

export const StyledAlert = styled(Alert)`
	border: 2px solid rgb(76, 175, 80);

	&.MuiAlert-standardSuccess {
		border-color: ${({ theme }) => theme.palette.success.light};
	}

	&.MuiAlert-standardInfo {
		border-color: ${({ theme }) => theme.palette.info.light};
	}

	&.MuiAlert-standardWarning {
		border-color: ${({ theme }) => theme.palette.warning.light};
	}

	&.MuiAlert-standardError {
		border-color: ${({ theme }) => theme.palette.error.light};
	}
`;
