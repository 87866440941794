import styled from 'styled-components';

import { colors } from '../../styles';

import type { Theme } from '@mui/material/styles';

export const StyledContainer = styled.div`
	flex-grow: 1;
	max-width: 400px;
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
	overflow-y: auto;
	border: 1px solid ${colors.grey[300]};
`;

export const StyledStepWrapper = styled.div`
	border-radius: 4px;
	background-color: ${colors.grey[200]};
	padding: 8px 16px;
	width: 316px;
`;

export const StyledTypeLabel = styled.span`
	margin-left: 5px;
	margin-top: 2px;
`;

export const StyledHeader = styled.div`
	display: flex;
	flex-direction: row;
	color: ${({ theme }) => theme.palette.primary.darkV2};
`;

export const StyledLabel = styled.div`
	color: ${colors.grey[600]};
`;

export const StyledWithoutTasks = styled.div`
	width: 300px;
	height: 100%;
	color: ${colors.grey[600]};
	font-size: 18px;
	text-align: center;
	padding-top: 50%;
`;

export const CustomBlock = styled.div``;

export const CustomLabelsBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const CustomCompanyInfoBlock = styled.div`
	display: flex;
	flex-direction: column;
`;

export const CustomInfoBlock = styled.div`
	display: flex;
`;

export const CustomReferenceBlock = styled.div`
	display: flex;
	flex-direction: row;
`;

export const CustomReferenceText = styled.span`
	margin-left: 5px;
`;

export const StyledCompanyName = styled.div`
	margin-left: 5px;
	font-weight: bold;
`;

export const CustomStepCircle = styled('div')<{
	theme: Theme;
	completed: boolean;
	active: boolean;
	warning: boolean;
}>(({ theme, completed, active, warning }) => ({
	width: 24,
	height: 24,
	borderRadius: '50%',
	backgroundColor: completed
		? theme.palette.success.main
		: active
		? theme.palette.primary.main
		: warning
		? theme.palette.warning.light
		: theme.palette.grey[400],
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
}));

export const StatusLabel = styled('div')<{
	active: boolean;
	theme: Theme;
	completed: boolean;
	cancelled?: boolean;
}>(({ theme, completed, active, cancelled }) => ({
	borderRadius: '4px',
	backgroundColor: cancelled
		? theme.palette.warning.light
		: completed
		? theme.palette.success.lighter
		: active
		? theme.palette.primary.lighter
		: theme.palette.grey[300],
	color: cancelled
		? theme.palette.warning.main
		: completed
		? theme.palette.success.main
		: active
		? theme.palette.primary.main
		: theme.palette.grey[700],
	fontWeight: 500,
	padding: completed ? '2px 22px' : active ? '2px 4px' : '2px 16px',
}));
