import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const MessageDirectionEnum = mkenum({
	TO_CONTACT: 'TO_CONTACT',
	FROM_CONTACT: 'FROM_CONTACT',
});

export type MessageDirection = TypeFromPEnum<typeof MessageDirectionEnum>;
