import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { InviteNewContactsSvg, OnboardingNotice } from '@heylog-app/frontend-lib/app';

import { StyledWelcomeContactsBlock } from '../../contacts.styles';

import type { FC } from 'react';
import type { UserResInterface } from '@heylog-app/shared/types';

type CreateContactWelcomeProps = {
	user: UserResInterface | undefined;
	onClick: () => void;
};

export const CreateContactWelcome: FC<CreateContactWelcomeProps> = ({
	user,
	onClick,
}) => {
	const { t } = useTranslation();

	return (
		<StyledWelcomeContactsBlock>
			<InviteNewContactsSvg />
			<OnboardingNotice
				title={t('onboarding.title', {
					name: `${user?.firstName} ${user?.lastName}`,
				})}
				text={t('onboarding.contacts.pleaseCreate')}
			>
				<Button variant="outlined" color="primary" onClick={onClick}>
					{t('onboarding.contacts.ctaLabel')}
				</Button>
			</OnboardingNotice>
		</StyledWelcomeContactsBlock>
	);
};
