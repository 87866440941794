import {
	generatePath,
	matchPath,
	Outlet,
	useLocation,
	useNavigate,
	useParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
	ROUTES,
	Sidebar,
	SidebarContent,
	SidebarLayoutContent,
	TabNavigation,
	TabNavigationItem,
	usePlugins,
} from '@heylog-app/frontend-lib/app';

import { OrderStatusMenu } from '../../components/ui/order-status-menu';
import { OrderDetailSidebar } from '../../components/order-detail/order-detail-sidebar';
import { OrderDetailsTabpanel } from './orders.styles';

import type { FC } from 'react';

export const OrderDetailPage: FC = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const params = useParams();
	const { orderId = '', workspaceId = '' } = useParams();
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const navigate = useNavigate();

	const [value, setValue] = useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	useEffect(() => {
		// prevent sub-route endless routing
		if (matchPath(ROUTES.ORDERS.DETAIL, pathname)) {
			navigate(generatePath(ROUTES.ORDERS.CHATS, params));
		}
	}, [navigate, params, pathname]);

	return (
		<Grid
			container
			sx={{
				height: '100%',
				overflow: 'hidden',
			}}
		>
			<SidebarLayoutContent>
				<TabNavigation>
					<TabNavigationItem
						label="orders.tabs.chats"
						active={!!matchPath(ROUTES.ORDERS.CHAT, pathname)}
						url={generatePath(ROUTES.ORDERS.CHATS, params)}
					/>
				</TabNavigation>
				<Outlet />
			</SidebarLayoutContent>
			{!FrontendV2Plugin && (
				<Sidebar variant="secondary">
					<SidebarContent>
						<OrderStatusMenu orderId={orderId} />
					</SidebarContent>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
							<Tab label={t('orders.sidebar.details')} />
							<Tab
								label={t('orders.sidebar.location')}
								// disabled={contactId ? false : true}
								disabled
							/>
						</Tabs>
					</Box>
					<OrderDetailsTabpanel role="tabpanel" hidden={value !== 0} id={`tabpanel-${0}`}>
						<OrderDetailSidebar />
					</OrderDetailsTabpanel>
				</Sidebar>
			)}
		</Grid>
	);
};
