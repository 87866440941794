import { useContext, useEffect, useState } from 'react';

import { PusherConnectionStatesEnum } from '@heylog-app/shared/types';

import { PusherChannelsContext, PusherBeamsContext } from '../providers';
import { bindToPusherStateChange, unbindPusherStateChange } from '../util';

import type {
	PusherStateChangePayload,
	PusherChannelsContextType,
	PusherBeamsContextType,
} from '../types';
import type { Channel } from 'pusher-js';
import type { Maybe } from '@heylog-app/shared/types';

export const usePusherBeamsContext = (): PusherBeamsContextType => {
	const pusherContext = useContext(PusherBeamsContext);

	if (!pusherContext)
		throw new Error('Cannot use pusher context outside of the pusher context provider');

	return pusherContext;
};

export const usePusherChannelsContext = (): PusherChannelsContextType => {
	const context = useContext(PusherChannelsContext);

	if (!context)
		throw new Error(
			'Cannot use pusher channels context outside of the pusher channels context provider',
		);

	return context;
};

export const useChannelConnectionStatus = () => {
	const { channelsClient } = usePusherChannelsContext();

	const [states, setStates] = useState<PusherStateChangePayload>({
		current: PusherConnectionStatesEnum.INITIALIZED,
		previous: PusherConnectionStatesEnum.INITIALIZED,
	});

	useEffect(() => {
		const cb = (payload: PusherStateChangePayload) => setStates(payload);

		bindToPusherStateChange(channelsClient, cb);

		return () => {
			unbindPusherStateChange(channelsClient, cb);
		};
	}, [channelsClient]);

	return states;
};

export const useEvent = <T>(
	channel: Maybe<Channel>,
	eventName: string,
	callback: (payload: T) => void,
) => {
	useEffect(() => {
		!!channel && channel.bind(eventName, callback);
		return () => {
			channel?.unbind(eventName, callback);
		};
	}, [callback, channel, eventName]);
};
