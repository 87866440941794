import Icon from '@mdi/react';
import { mdiCheck } from '@mdi/js';

import { FileIcons } from '../file-icons';
import {
	StyledColumn,
	StyledRemoveButton,
	StyledUploadedFile,
} from './uploaded-files.styles';

import type { FC } from 'react';

type EmailFileProps = {
	file: File;
	selectFile: (file: File) => void;
	selected?: boolean;
};

export const EmailFile: FC<EmailFileProps> = ({ file, selectFile, selected }) => {
	return (
		<StyledUploadedFile onClick={() => selectFile(file)} data-test="uploaded-file">
			<FileIcons type={file.type} />
			<StyledColumn>
				<span>{file.name}</span>
			</StyledColumn>
			{selected && (
				<StyledRemoveButton data-test="file-remove-button">
					<Icon path={mdiCheck} />
				</StyledRemoveButton>
			)}
		</StyledUploadedFile>
	);
};
