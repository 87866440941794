export const parseMarkdown = (text: string) => {
	let result = text;

	// Bold
	result = result.replace(/\*(.*?)\*/g, '**$1**');

	// Italic
	result = result.replace(/_(.*?)_/g, '_$1_');

	return result;
};
