import type { WhatsAppMessageTemplateResInterface } from '@heylog-app/shared/types';

export const getAvailableTemplates = ({
	language,
	templates,
}: {
	language: string;
	templates?: WhatsAppMessageTemplateResInterface[];
}) => {
	const filteredTemplates = templates?.filter((template) => {
		const lang = language.split('-')[0] || language; // standardize language code (en-GB -> en)
		return template.language.includes(lang);
	});

	const groupedTemplates = filteredTemplates?.reduce((acc, curr) => {
		const { name } = curr;
		if (!acc[name]) {
			acc[name] = [];
		}
		acc[name]?.push(curr);
		return acc;
	}, {} as Record<string, WhatsAppMessageTemplateResInterface[]>);

	return groupedTemplates;
};
