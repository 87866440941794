import {
	mdiChevronLeft,
	mdiChevronRight,
	mdiFileDocumentOutline,
	mdiTrayArrowDown,
} from '@mdi/js';
import { Stack, Box, Tabs } from '@mui/material';
import { Icon } from '@mdi/react';
import { useState, type FC } from 'react';

import {
	ModalClose,
	ButtonV2,
	TabPanel,
	colors,
	Comments,
} from '@heylog-app/frontend-lib/app';
import { getMediaType } from '@heylog-app/shared/util';
import { MessageTypeEnum } from '@heylog-app/shared/types';

import pdfIcon from '../../../../../../app/src/assets/pdfIcon.png';
import {
	StyledArrowButton,
	StyledDialog,
	StyledDialogTitle,
	StyledDocumentLabel,
	StyledImage,
	StyledImageContainer,
	StyledTab,
} from './document-modal.styles';
import { FileInfoTab } from './file-info-tab/file-info-tab';

import type { AttachmentRow } from '@heylog-app/frontend-lib/app';

interface DocumentModalProps {
	handlePrevClick: () => void;
	handleNextClick: () => void;
	closeDialog: () => void;
	showDialog: boolean;
	attachment: AttachmentRow | undefined; // remove undefined from here, handle this properly in the parent component
}
export const DocumentModal: FC<DocumentModalProps> = ({
	attachment,
	showDialog,
	handleNextClick,
	handlePrevClick,
	closeDialog,
}) => {
	const [tab, setTab] = useState(0);

	const handleChange = (_: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	if (!attachment) return null;

	const image = attachment?.type === 'application/pdf' ? pdfIcon : attachment?.src;

	return (
		<StyledDialog open={showDialog} onClose={closeDialog} maxWidth="lg">
			<StyledArrowButton $direction="left" onClick={handlePrevClick}>
				<Icon size={'36px'} path={mdiChevronLeft} color={colors.grey[300]} />
			</StyledArrowButton>
			<StyledArrowButton $direction="right" onClick={handleNextClick}>
				<Icon size={'36px'} path={mdiChevronRight} color={colors.grey[300]} />
			</StyledArrowButton>

			<Stack direction="row" height="100%">
				<Stack flex={1} justifyContent="space-between" overflow="auto" minWidth={0}>
					<Stack bgcolor={colors.common.white} position="sticky" zIndex="999" top="0px">
						<Stack
							padding="24px 16px 8px 16px"
							direction="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<StyledDialogTitle>{attachment?.fileName}</StyledDialogTitle>
							<a href={attachment?.src} download>
								<ButtonV2 $variant="light" $padding="12px">
									<Icon path={mdiTrayArrowDown} size={1} />
								</ButtonV2>
							</a>
						</Stack>
						<Tabs
							value={tab}
							onChange={handleChange}
							sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}
						>
							<StyledTab label="Details" value={0} />
							<StyledTab label="Comments" value={1} />
						</Tabs>
					</Stack>
					<Stack height="100%" overflow="hidden">
						<TabPanel value={tab} index={0}>
							<Box padding={2}>
								<FileInfoTab attachment={attachment} />
							</Box>
						</TabPanel>

						<TabPanel value={tab} index={1}>
							<Comments type="internal" />
						</TabPanel>
					</Stack>
				</Stack>
				<StyledImageContainer flex={2}>
					<ModalClose closeModalFn={closeDialog} />
					{getMediaType(attachment.type) === MessageTypeEnum.IMAGE ? (
						<StyledImage src={image} alt="" />
					) : (
						<Stack width="100%" height="100%" alignItems="center" justifyContent="center">
							<Icon path={mdiFileDocumentOutline} size={4} color={colors.grey[700]} />
							<StyledDocumentLabel>
								Preview is not available. Please download the file to check the details.
							</StyledDocumentLabel>
						</Stack>
					)}
				</StyledImageContainer>
			</Stack>
		</StyledDialog>
	);
};
