import { Stack } from '@mui/material';
import { type FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AttachmentButton } from '../attachment-button';
import { colors } from '../../styles';
import { ButtonV2 } from '../ui';
import { useEmailForm } from '../../hooks';

interface NewEmailPropsInterface {
	orderId?: number;
	setSubject?: (subject: string) => void;
	handleCloseDialog?: () => void;
}

export const NewEmail: FC<NewEmailPropsInterface> = ({
	orderId,
	setSubject,
	handleCloseDialog,
}) => {
	const { workspaceId = '' } = useParams();
	const { t } = useTranslation();
	const {
		setAttachments,
		setAttachmentsError,
		onSubmitEmailForm,
		EmailFormComponent,
		isLoadingSend,
	} = useEmailForm({
		fieldsToRender: ['bcc', 'cc', 'orderId'],
		handleCloseDialog,
		orderId,
		setSubject,
		workspaceId,
	});

	return (
		<Stack
			component="form"
			noValidate
			onSubmit={onSubmitEmailForm}
			alignItems="center"
			justifyContent="space-between"
			height="100%"
		>
			{EmailFormComponent}
			<Stack
				width="100%"
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				zIndex={10}
				paddingBlock={2}
				bgcolor={colors.common.white}
				position="sticky"
				bottom="0px"
				borderTop={`1px solid ${colors.border.main}`}
			>
				<AttachmentButton
					onFileSelect={(files) => setAttachments((prev) => [...prev, ...files])}
					getOnValidationErrorText={setAttachmentsError}
					maxFileSizeMb={9}
				/>
				<ButtonV2 isLoading={isLoadingSend} $variant="dark" type="submit">
					{t('actionLabels.send')}
				</ButtonV2>
			</Stack>
		</Stack>
	);
};
