import { StyledMessageVideo } from './message-video.styles';

import type { AttachmentResInterface } from '@heylog-app/shared/types';

export const MessageVideo = ({ attachment }: { attachment?: AttachmentResInterface }) => {
	if (!attachment) return null;

	return (
		<>
			{/* We should agree on some height of the videos, loading them causes layout shift, and we can't scroll to bottom */}
			<StyledMessageVideo controls src={attachment?.publicUrl} />
			{attachment?.caption && <div>{attachment?.caption}</div>}
		</>
	);
};
