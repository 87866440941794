import { Button } from '@mui/material';
import styled from 'styled-components';

export const StyledGuestList = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledSidebarDescription = styled.div``;

export const StyledSidebarButton = styled(Button)`
	width: 100%;
`;
