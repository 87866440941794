import { Box, Button, Dialog, Tab } from '@mui/material';
import styled from 'styled-components';

import { H4, T3, T3_medium, colors } from '@heylog-app/frontend-lib/app';

export const StyledDialog = styled(Dialog)`
	.MuiDialog-paper {
		width: 1080px;
		height: 740px;
		position: static;
		margin: 120px;
	}
`;

export const StyledImageContainer = styled(Box)`
	background-color: ${colors.grey[900]};
	position: relative;
`;

export const StyledImage = styled.img`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
`;

export const StyledDialogTitle = styled.div`
	${H4};
	color: ${colors.grey[900]};
`;

export const StyledTab = styled(Tab)`
	text-transform: unset !important;
	${T3_medium};
`;

export const StyledDocumentLabel = styled.div`
	text-transform: unset !important;
	${T3};
	color: ${colors.grey[600]};
	text-align: center;
	margin-top: 16px;
	max-width: 320px;
`;

export const StyledArrowButton = styled(Button)<{ $direction: 'left' | 'right' }>(
	({ $direction }) => `
    position: absolute;
    ${$direction}: 24px;
    top: calc(50% - 48px);
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
`,
);
