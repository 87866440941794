import i18next from 'i18next';

export const getMessageSentString = (
	createdAt: Date,
	conversationLanguage: string,
	isToday = false,
): string => {
	const translatableString = isToday
		? i18next.t('chats.today')
		: i18next.t('chats.yesterday');
	const timeFromCreatedAt = new Date(createdAt).toLocaleTimeString(conversationLanguage, {
		hour: '2-digit',
		minute: '2-digit',
		hour12: false,
	});

	return `${translatableString}, ${timeFromCreatedAt}`;
};
