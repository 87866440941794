import type { OrderResInterface } from '@heylog-app/shared/types';

export enum OrderDialogActionEnum {
	NEW,
	OPEN,
}

export interface OrderDialogPayloadInterface {
	orderId?: number;
	order?: OrderResInterface;
}

export type OrderDialogInterface = {
	// type: MessageDialogTypesEnum.EMAIL;
	action: OrderDialogActionEnum;
	id?: string;
	payload: OrderDialogPayloadInterface;
};
