import styled from 'styled-components';
import { Button, MenuItem } from '@mui/material';

export const StyledButton = styled(Button)`
	background-color: ${({ theme }) => theme.palette.common.white};
	border: solid 1px ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.primary.main};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const StyledMenuItem = styled(MenuItem)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	& .MuiListItemIcon-root {
		width: 16px;
		min-width: 24px;
	}
`;
