import AudioFileIcon from '@mui/icons-material/Mic';
import VideoFileIcon from '@mui/icons-material/Videocam';
import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import { Icon } from '@mdi/react';
import { mdiFileImageOutline } from '@mdi/js';
import { PictureAsPdf } from '@mui/icons-material';

import { MessageTypeEnum } from '@heylog-app/shared/types';

import type { MessageType } from '@heylog-app/shared/types';

export const FileIcons = ({
	type,
	mediaType,
}: {
	type: string;
	mediaType?: MessageType;
}) => {
	if (mediaType === MessageTypeEnum.IMAGE) {
		return <Icon path={mdiFileImageOutline} size={1} />;
	} else if (mediaType === MessageTypeEnum.VIDEO) {
		return <VideoFileIcon />;
	} else if (mediaType === MessageTypeEnum.AUDIO) {
		return <AudioFileIcon />;
	} else if (mediaType === MessageTypeEnum.DOCUMENT && type === 'application/pdf') {
		return <PictureAsPdf />;
	}
	return <DescriptionIcon />;
};
