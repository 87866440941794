import { ContactLanguageEnum } from '@heylog-app/shared/types';

type ContactLanguageType = keyof typeof ContactLanguageEnum;

export type ContactLanguageEnumType = {
	[key: string]: string;
};

const ContactLanguageEnumTyped: ContactLanguageEnumType = ContactLanguageEnum;

export const getContactLanguage = (currentLanguage: string): ContactLanguageType => {
	const language = currentLanguage.toLowerCase();

	for (const key in ContactLanguageEnumTyped) {
		if (ContactLanguageEnumTyped[key] === language) {
			return key as ContactLanguageType;
		}
	}

	return 'DE';
};
