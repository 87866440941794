import { ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

import { SidebarListItem } from '../../sidebar';

import type { FC } from 'react';

type ContactSkeletonProps = {
	animated?: boolean;
};

export const ContactSkeleton: FC<ContactSkeletonProps> = ({ animated = true }) => {
	const animation = animated ? 'wave' : false;
	return (
		<SidebarListItem>
			<ListItemAvatar>
				<Skeleton variant="circular" height={32} width={32} animation={animation} />
			</ListItemAvatar>
			<ListItemText>
				<Skeleton variant="text" width="100%" height={24} animation={animation} />
				<Skeleton variant="text" width="70%" height={16} animation={animation} />
			</ListItemText>
		</SidebarListItem>
	);
};
