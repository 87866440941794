import {
	mdiAccount,
	mdiAccountArrowRight,
	mdiAccountCheck,
	mdiFaceAgent,
	mdiTruckFast,
} from '@mdi/js';

import type { ContactRoleType } from '@heylog-app/shared/types';

export const getRoleIcon = (role: ContactRoleType) => {
	switch (role) {
		case 'DRIVER':
			return mdiTruckFast;
		case 'DISPATCHER':
			return mdiFaceAgent;
		case 'CLIENT':
			return mdiAccountArrowRight;
		case 'RECIPIENT':
			return mdiAccountCheck;
		case 'OTHER':
			return mdiAccount;
		case 'NONE':
			return null;
		default:
			return mdiTruckFast;
	}
};
