import { useTranslation } from 'react-i18next';

import { StyledSidebarHeader, StyledSidebarTitle } from './sidebar-header.styles';

import type { FC, ReactNode } from 'react';

type SidebarHeaderProps = { children?: ReactNode; title: string };

export const SidebarHeader: FC<SidebarHeaderProps> = ({ title, children }) => {
	const { t } = useTranslation();

	return (
		<StyledSidebarHeader>
			<StyledSidebarTitle>{t(title)}</StyledSidebarTitle>
			{children}
		</StyledSidebarHeader>
	);
};
