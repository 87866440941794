import qs from 'qs';

type QueryType = Record<string, string | number | string[] | number[] | undefined>;

/**
 * function to build query string ?queryParam=X
 * why you no used package for this? 🥲
 * */
export const getQueryString = (query: QueryType) => {
	return `?${qs.stringify(query)}`;
};
