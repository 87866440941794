import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
import { Icon } from '@mdi/react';
import i18next from 'i18next';
import { useState } from 'react';

import { phoneRegex } from '@heylog-app/shared/util';
import {
	ContactPageLayout,
	FlashSnackbar,
	getContactLanguage,
	IconWhatsApp,
	joinWorkspaceAPI,
	useApiClientContext,
	useSnackbar,
} from '@heylog-app/frontend-lib/app';
import {
	ContactLanguageEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import {
	StyledButton,
	StyledForm,
	StyledInputAdornment,
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../join.styles';
import { StyledProceedButtonContainer } from '../join/join.styles';

import type { FC } from 'react';

type FormData = {
	phone: string;
};

export const WhatsappJoinPage: FC = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	// const [result, setResult] = useState<Nullable<boolean>>(null);
	const { apiClient } = useApiClientContext();
	const params = useParams();
	const { joinKey } = params;
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [deeplink, setDeeplink] = useState('');
	const orderId = searchParams.get('orderId') || '';
	const orderReference = searchParams.get('orderReference') || undefined;
	const source = searchParams.get('source') || undefined;
	const companyName = searchParams.get('name');
	const contactLanguage = ContactLanguageEnum[getContactLanguage(i18next.language)];
	const navigate = useNavigate();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<FormData>();

	const onSubmit = async (data: FormData) => {
		if (!joinKey || isSubmitting) return;

		setIsSubmitting(true);

		joinWorkspaceAPI(apiClient, {
			data: {
				joinKey: joinKey,
				phoneNumber: data.phone,
				transportType: MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,
				language: contactLanguage === 'bs' ? 'hr' : contactLanguage,
				orderId: parseInt(orderId),
				orderReference,
				source,
			},
		})
			.then((res) => {
				if (res.data.slug === 'duplicated_contact') {
					openSnackbar('success', t('join.errorMessage.duplicatedNumber'));
					setDeeplink(`https://wa.me/${res.data.whatsappTransportNumber}`);

					setTimeout(() => {
						closeSnackbar();
					}, 2000);

					return;
				}

				openSnackbar(
					'success',
					t('join.messagingTransport.successDetails', {
						transportType: 'WhatsApp',
					}),
					true,
					2000,
				);

				setTimeout(() => {
					window.location.href = `https://wa.me/${res.data.whatsappTransportNumber}`;
				}, 2000);
			})
			.catch(() => {
				openSnackbar(
					'error',
					t('join.messagingTransport.errorTitle', {
						transportType: 'WhatsApp',
					}),
				);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const onFocusPhoneField = (event: React.FocusEvent<HTMLInputElement>) => {
		if (event.target.value === '') {
			reset({ phone: '+' });
		}
	};

	const handleBackClick = () => {
		navigate(-1);
	};

	const handleDeeplinkClick = () => {
		window.location.href = deeplink;
	};

	return (
		<ContactPageLayout>
			<div>
				<StyledTitleWrapper>{t('join.title', { companyName })}</StyledTitleWrapper>
				<StyledTextWrapper>
					<p
						dangerouslySetInnerHTML={{
							__html: t('join.messagingTransport.text', {
								companyName: companyName,
								transportType: 'WhatsApp',
							}),
						}}
					></p>
				</StyledTextWrapper>
				<StyledForm onSubmit={handleSubmit(onSubmit)} noValidate>
					<TextField
						data-test="whatsapp-join-input"
						label={t('contacts.form.labels.phone')}
						{...register('phone', {
							required: true,
							pattern: phoneRegex,
						})}
						type="tel"
						fullWidth
						error={!!errors.phone}
						helperText={
							errors.phone ? t(`forms.errors.${errors.phone?.type ?? 'invalid'}`) : null
						}
						placeholder={'+43660...'}
						onFocus={onFocusPhoneField}
						InputProps={{
							startAdornment: (
								<StyledInputAdornment position="start">
									<IconWhatsApp />
								</StyledInputAdornment>
							),
						}}
					/>
					<StyledButton
						variant="contained"
						color="primary"
						onClick={handleSubmit(onSubmit)}
						endIcon={<Icon path={mdiChevronRight} size={'2em'} />}
						loading={isSubmitting}
					/>
				</StyledForm>

				{deeplink && (
					<StyledProceedButtonContainer>
						<Button variant="contained" color="primary" onClick={handleDeeplinkClick}>
							{t('join.proceedAnyway')}
						</Button>
					</StyledProceedButtonContainer>
				)}

				{/* {result !== null && <ResultMessage result={result} transportType="WhatsApp" />} */}
			</div>
			<div>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleBackClick}
					startIcon={<Icon path={mdiChevronLeft} size={'1.2em'} />}
				>
					{t('actionLabels.back')}
				</Button>
			</div>

			<FlashSnackbar
				controls={[stateSnackbar, openSnackbar, closeSnackbar]}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>
		</ContactPageLayout>
	);
};
