import { Box, Portal } from '@mui/material';
import { useEffect, useState } from 'react';
import { mdiWindowClose } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	StyledCloseIcon,
	StyledFilterHeader,
	StyledFilterSidebar,
} from './table-filter-sidebar.styles';
import { colors } from '../../styles';

import type { FC, PropsWithChildren } from 'react';

interface TableFilterSidebarPropsInterface {
	onClose: () => void;
	isOpen: boolean;
	title?: string;
}

export const TableFilterSidebar: FC<
	PropsWithChildren<TableFilterSidebarPropsInterface>
> = ({ onClose, isOpen, children, title = 'filters' }) => {
	const [hasPortalTarget, setHasPortalTarget] = useState(true);
	const [opacity, setOpacity] = useState(0);
	const { t } = useTranslation();

	useEffect(() => {
		if (isOpen) setTimeout(() => setOpacity(1), 300);
		else setOpacity(0);
	}, [isOpen]);

	if (!hasPortalTarget) return null;

	return (
		<Portal
			container={() => {
				const elem = document.getElementById('filter-container');
				if (!elem) setHasPortalTarget(false);
				return elem;
			}}
		>
			<StyledFilterSidebar isOpen={isOpen}>
				<StyledFilterHeader>
					{t(title)}
					<div onClick={onClose}>
						<StyledCloseIcon path={mdiWindowClose} color={colors.grey[600]} size={1} />
					</div>
				</StyledFilterHeader>
				{isOpen && <Box sx={{ opacity, transition: '1s all ease' }}>{children}</Box>}
			</StyledFilterSidebar>
		</Portal>
	);
};
