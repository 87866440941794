import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const EventContextEnum = mkenum({
	ORDER_EVENT: 'ORDER_EVENT',
	CONTACT_EVENT: 'CONTACT_EVENT',
	CONVERSATION_EVENT: 'CONVERSATION_EVENT',
});

export type EventContextType = TypeFromPEnum<typeof EventContextEnum>;
