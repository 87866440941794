import { mdiMapMarker } from '@mdi/js';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';

import {
	StyledLocationTitle,
	StyledLocationNameAddress,
	StyledLocationMapWrapper,
	StyledMapLink,
} from './message-location.styles';
import { useEnvContext } from '../../../hooks';

import type { LocationMessageResInterface, Nullable } from '@heylog-app/shared/types';

type MessageLocationProps = {
	location?: Nullable<LocationMessageResInterface>;
};

export const MessageLocation = ({ location }: MessageLocationProps) => {
	const { t } = useTranslation();
	const env = useEnvContext();
	let addressWithLineBreaks: string[] = [];

	if (!location) return null;

	const { latitude, longitude, name, address } = location;

	if (address) {
		addressWithLineBreaks = address.split(', ');
	}

	return (
		<>
			<StyledMapLink
				target="_blank"
				rel="noreferrer"
				href={`https://maps.google.com/?q=${latitude},${longitude}`}
			>
				<StyledLocationMapWrapper>
					<img
						alt="Location message map"
						src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=340x170&key=${env.GOOGLE_MAPS_STATIC_API_KEY}&markers=color:red%7C${latitude},${longitude}`}
					/>
				</StyledLocationMapWrapper>
				<StyledLocationTitle>
					<Icon path={mdiMapMarker} size={'1.5em'} />
					<span>{t('chats.location')}</span>
				</StyledLocationTitle>
			</StyledMapLink>
			<StyledLocationNameAddress>{name && <span>{name}</span>}</StyledLocationNameAddress>
			{address &&
				addressWithLineBreaks.map((addressLine) => (
					<StyledLocationNameAddress>{addressLine}</StyledLocationNameAddress>
				))}
		</>
	);
};
