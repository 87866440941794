import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@mui/material';

import { StyledTextContainer, StyledTextWrapper } from './result-message.styles';

import type { FC } from 'react';

type ResultMessageProps = {
	result: boolean;
	transportType: string;
};

export const ResultMessage: FC<ResultMessageProps> = ({ result, transportType }) => {
	const { t } = useTranslation();

	return (
		<StyledTextContainer>
			{result ? (
				<StyledTextWrapper>
					<Alert severity="success">
						<AlertTitle>{t('join.messagingTransport.successTitle')}</AlertTitle>
						{t('join.messagingTransport.successDetails', { transportType })}
					</Alert>
				</StyledTextWrapper>
			) : (
				<StyledTextWrapper>
					<Alert severity="error">
						<AlertTitle>
							{' '}
							{t('join.messagingTransport.errorTitle', { transportType })}
						</AlertTitle>
						<p
							dangerouslySetInnerHTML={{
								__html: t('join.messagingTransport.errorDetails', { transportType }),
							}}
						></p>
					</Alert>
				</StyledTextWrapper>
			)}
		</StyledTextContainer>
	);
};
