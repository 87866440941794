import { DayUnitsInMiliseconds } from '@heylog-app/shared/types';

export const getIsMessageSentYesterday = (
	messageDelta: number,
	messageDate: Date,
	now: Date,
): boolean => {
	return (
		(DayUnitsInMiliseconds.OneDay < messageDelta &&
			messageDelta < DayUnitsInMiliseconds.TwoDays &&
			messageDate.getDay() === now.getDay() - DayUnitsInMiliseconds.OneDay) ||
		(messageDelta < DayUnitsInMiliseconds.OneDay && messageDate.getDay() !== now.getDay())
	);
};
