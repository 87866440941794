import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ModalClose } from '../ui';
import { StyledDialogActions } from '../dialog';

import type { FC } from 'react';

type ConfirmArchiveDialogProps = {
	dataTest?: string;
	dialogConfirmButtonText?: string;
	dialogContent?: string;
	dialogTitle: string;
	isOpen: boolean;
	onArchive: () => void;
	onClose: () => void;
};

export const ConfirmArchiveDialog: FC<ConfirmArchiveDialogProps> = ({
	dataTest,
	dialogContent,
	dialogConfirmButtonText,
	dialogTitle,
	isOpen,
	onArchive,
	onClose,
}) => {
	const { t } = useTranslation();

	return (
		<Dialog data-test={dataTest} open={isOpen} onClose={onClose}>
			<ModalClose closeModalFn={onClose} />
			<DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
			{dialogContent && <DialogContent>{dialogContent}</DialogContent>}

			<StyledDialogActions>
				<Button data-test="cancel-button" variant="outlined" onClick={onClose}>
					{t('actionLabels.cancel')}
				</Button>
				<Button
					data-test="archive-button"
					variant="contained"
					color="error"
					onClick={onArchive}
				>
					{dialogConfirmButtonText ? dialogConfirmButtonText : t('actionLabels.remove')}
				</Button>
			</StyledDialogActions>
		</Dialog>
	);
};
