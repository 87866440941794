import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import i18next from 'i18next';

import { StyledMobileAppIcon } from '@heylog-app/frontend-lib/app';

import AndroidDeChatScreenshot from '../../../../../app/src/assets/android-de-chat.png';
import AndroidEnChatScreenshot from '../../../../../app/src/assets/android-en-chat.png';
import IOSEnChatScreenshot from '../../../../../app/src/assets/ios-de-chat.png';
import IOSDeChatScreenshot from '../../../../../app/src/assets/ios-en-chat.png';
import IOSEnContactsScreenshot from '../../../../../app/src/assets/ios-en-contacts.png';
import IOSDeContactsScreenshot from '../../../../../app/src/assets/ios-de-contacts.png';
import AndroidContactsScreenshot from '../../../../../app/src/assets/android-contacts.png';
import {
	StyledSettingsContentWrapper,
	StyledSettingsSection,
	StyledSettingsPageLayout,
	StyledParagraph,
	StyledScreenshot,
	StyledScreenshotsWrapper,
} from './settings.styles';
import { SettingsFooter } from './components';

import type { FC } from 'react';
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable';

type StoreIcons = {
	[key: string]: JSX.Element;
};

type AppScreenshots = {
	[key: string]: string;
};

export const AppsSettingsPage: FC = () => {
	const { t } = useTranslation();
	const [storeIcons, setStoreIcons] = useState<Nullable<StoreIcons>>(null);
	const [appScreenshots, setAppScreenshots] = useState<Nullable<AppScreenshots>>(null);

	useEffect(() => {
		const fetchIcons = async () => {
			const googleIcon = await import(
				`libs/frontend-lib/app/src/components/icons/icon-google-play-${i18next.language}`
			);
			const appleIcon = await import(
				`libs/frontend-lib/app/src/components/icons/icon-appstore-${i18next.language}`
			);

			setStoreIcons({
				google: googleIcon.IconGooglePlay(),
				apple: appleIcon.IconAppStore(),
			});
		};

		const fetchScreenshots = async () => {
			const googleChatScreenshotLink =
				i18next.language === 'en' ? AndroidEnChatScreenshot : AndroidDeChatScreenshot;
			const appleChatScreenshotLink =
				i18next.language === 'en' ? IOSEnChatScreenshot : IOSDeChatScreenshot;
			const appleContactsScreenshotLink =
				i18next.language === 'en' ? IOSEnContactsScreenshot : IOSDeContactsScreenshot;

			setAppScreenshots({
				googleChat: googleChatScreenshotLink,
				appleChat: appleChatScreenshotLink,
				googleContacts: AndroidContactsScreenshot,
				appleContacts: appleContactsScreenshotLink,
			});
		};

		fetchIcons();
		fetchScreenshots();
	}, []);

	return (
		<StyledSettingsPageLayout>
			<StyledSettingsContentWrapper>
				<StyledSettingsSection width={'48%'}>
					<Typography variant="h6">{t('navigation.settingsMenu.apps.ios')}</Typography>

					<StyledParagraph
						dangerouslySetInnerHTML={{
							__html: t('navigation.settingsMenu.apps.iosParagraph'),
						}}
					/>
					<StyledScreenshotsWrapper>
						<StyledScreenshot
							src={appScreenshots ? appScreenshots['appleChat'] : ''}
							alt="Heylog App screenshot"
						/>
						<StyledScreenshot
							src={appScreenshots ? appScreenshots['appleContacts'] : ''}
							alt="Heylog App screenshot"
						/>
					</StyledScreenshotsWrapper>

					<Link
						target="_blank"
						to={`https://apps.apple.com/at/app/heylog/id6448185455?l=${i18next.language}`}
					>
						<StyledMobileAppIcon>
							{storeIcons ? storeIcons['apple'] : ''}
						</StyledMobileAppIcon>
					</Link>
				</StyledSettingsSection>

				<StyledSettingsSection width={'48%'}>
					<Typography variant="h6">
						{t('navigation.settingsMenu.apps.android')}
					</Typography>

					<StyledParagraph
						dangerouslySetInnerHTML={{
							__html: t('navigation.settingsMenu.apps.androidParagraph'),
						}}
					/>
					<StyledScreenshotsWrapper>
						<StyledScreenshot
							src={appScreenshots ? appScreenshots['googleChat'] : ''}
							alt="Heylog App screenshot"
						/>
						<StyledScreenshot
							src={appScreenshots ? appScreenshots['googleContacts'] : ''}
							alt="Heylog App screenshot"
						/>
					</StyledScreenshotsWrapper>

					<Link
						target="_blank"
						to={`https://play.google.com/store/apps/details?id=com.heylog`}
					>
						<StyledMobileAppIcon>
							{storeIcons ? storeIcons['google'] : ''}
						</StyledMobileAppIcon>
					</Link>
				</StyledSettingsSection>
			</StyledSettingsContentWrapper>

			<SettingsFooter />
		</StyledSettingsPageLayout>
	);
};
