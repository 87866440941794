export const IconSelectAContact = () => (
	<svg
		width="298"
		height="241"
		viewBox="0 0 298 241"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		data-test="select-conversation-icon"
	>
		<path
			d="M168.205 5.93663C155.74 4.7058 142.892 4.1858 130.942 7.9403C118.991 11.6948 107.971 20.4585 104.575 32.5139C101.178 44.5693 107.613 59.4394 119.702 62.7169C116.867 68.4645 113.083 73.6932 108.51 78.1833C113.696 78.2787 117.575 73.7609 121.94 70.9367C133.966 63.1367 150.063 68.289 164.022 65.0354C176.344 62.173 186.84 51.816 188.991 39.3455C191.143 26.875 184.221 13.1546 172.523 8.28856"
			fill="#D1D4F2"
		/>
		<path
			d="M90.5107 80.8549C90.3771 80.6068 98.3155 74.5529 97.6953 71.1943C97.0751 67.8358 86.408 53.9771 75.9841 55.8615C65.5602 57.7459 44.0208 55.2031 32.8861 77.3866C21.7514 99.5702 18.8174 108.611 21.0501 112.251C23.2827 115.891 50.2989 135.15 51.8112 123.776C53.3235 112.403 52.2549 103.907 52.2549 103.907C52.2549 103.907 53.8388 99.1122 48.6817 98.907C43.5246 98.7019 37.4277 90.9591 41.6211 86.699C45.8145 82.4388 46.6112 81.0648 48.6817 82.4054C50.7521 83.7459 54.7213 80.9265 54.7213 80.9265C54.7213 80.9265 69.1716 76.5852 73.9041 73.6417C78.6366 70.6982 82.2193 63.6233 84.6142 69.1621C87.0091 74.7008 90.5107 80.8549 90.5107 80.8549V80.8549Z"
			fill="#7C839E"
		/>
		<path
			d="M193.819 59.3679C193.819 59.3679 198.146 54.2824 199.172 55.4798C200.197 56.6773 203.942 60.842 205.321 59.3679C206.7 57.8938 203.775 53.0086 204.892 52.1356C206.008 51.2626 218.727 49.2732 224.132 53.271C229.647 57.3642 238.148 61.5195 240.247 66.8864C242.346 72.2534 245.018 79.8435 242.857 82.954C240.695 86.0644 242.58 88.0395 240.061 84.8622C237.542 81.685 234.36 79.0134 232.609 82.4769C230.858 85.9404 230.653 90.5012 229.384 87.6388C228.115 84.7764 223.76 71.118 223.76 71.118C223.76 71.118 205.927 73.0978 200.503 68.289C195.079 63.4802 195.255 62.6024 195.255 62.6024"
			fill="#7C839E"
		/>
		<path
			d="M195.093 65.4457C192.521 73.0373 188.333 79.9808 182.818 85.7973C180.213 88.5404 177.155 90.5918 174.359 93.0869C172.279 94.9426 172.833 97.6237 174.97 99.1647C176.461 100.17 178.106 100.925 179.841 101.397C180.542 101.607 181.301 101.707 181.978 101.97C183.82 102.671 184.149 103.625 184.516 105.371L187.379 119.092C189.366 128.541 191.354 137.991 193.342 147.444C193.676 149.027 196.099 148.36 195.77 146.776C193.843 137.629 191.919 128.482 189.998 119.335L187.307 106.531C186.94 104.799 186.892 102.27 185.652 100.877C184.731 99.8469 183.171 99.6894 181.921 99.365C180.161 98.888 178.343 98.4109 176.797 97.3995C174.087 95.663 178.319 93.2968 179.66 92.1757C182.342 89.9548 184.811 87.4889 187.035 84.8098C191.651 79.2701 195.205 72.9252 197.516 66.0945C198.036 64.5584 195.608 63.9 195.093 65.4266V65.4457Z"
			fill="black"
		/>
		<path
			d="M197.158 86.2553C196.858 86.6942 196.519 86.8039 196.152 86.594V86.7514C196.052 86.9184 196.152 87.5052 196.152 87.7056V88.569C196.152 88.6835 196.152 88.798 196.152 88.9125C196.047 89.2035 196.214 89.0795 196.662 88.5452L197.297 88.7169C197.368 88.7551 197.402 89.132 197.297 89.194C198.728 88.3925 197.435 86.2171 196.028 87.0234C195.417 87.365 195 87.9713 194.899 88.6639C194.798 89.3565 195.025 90.0566 195.513 90.5584C196.872 91.8656 198.518 90.5823 198.647 89.027C198.747 87.739 198.876 85.7353 197.879 84.7334C196.882 83.7316 194.935 84.1276 194.673 85.5731C194.391 87.1569 196.81 87.8344 197.101 86.241L197.158 86.2553Z"
			fill="black"
		/>
		<path
			d="M211.308 86.2553C211.008 86.6942 210.674 86.8039 210.306 86.594V86.7514C210.201 86.9184 210.306 87.5052 210.306 87.7056V88.569C210.314 88.6834 210.314 88.7981 210.306 88.9125C210.201 89.2035 210.373 89.0795 210.822 88.5452L211.456 88.7169C211.528 88.7551 211.561 89.132 211.456 89.194C212.859 88.3925 211.594 86.2171 210.187 87.0234C209.576 87.365 209.159 87.9713 209.058 88.6639C208.957 89.3565 209.184 90.0566 209.672 90.5584C211.027 91.8656 212.677 90.5823 212.801 89.027C212.906 87.739 213.03 85.7353 212.038 84.7334C211.046 83.7316 209.09 84.1276 208.832 85.5731C208.546 87.1569 210.97 87.8344 211.256 86.241L211.308 86.2553Z"
			fill="black"
		/>
		<path
			d="M198.623 108.491C205.946 110.204 215.12 109.331 218.579 101.579C219.237 100.114 217.066 98.8355 216.408 100.31C213.412 107.027 205.603 107.542 199.291 106.068C197.717 105.696 197.044 108.124 198.623 108.491V108.491Z"
			fill="black"
		/>
		<path
			d="M191.911 55.4798C192.23 61.9488 196.281 67.5543 201.877 70.6409C208.661 74.3811 216.79 74.0806 224.103 72.3297L222.553 71.4519C224.032 77.9474 226.274 84.2454 229.232 90.2149C229.809 91.3885 231.536 90.7492 231.574 89.5804C231.655 87.052 232.428 83.4597 235.543 83.2879C238.134 83.1448 239.66 86.1503 240.008 88.34C240.613 92.354 238.558 96.2993 234.923 98.1056C233.492 98.8212 234.746 100.968 236.192 100.276C241.812 97.514 244.474 89.9382 241.254 84.4043C239.698 81.7279 236.717 79.9008 233.621 81.1173C230.195 82.4674 229.155 86.2314 229.05 89.5804L231.393 88.9459C228.537 83.1658 226.377 77.0678 224.957 70.7793C224.763 70.1178 224.079 69.7295 223.411 69.9015C216.961 71.4472 209.825 71.8097 203.728 68.7899C198.637 66.2472 194.687 61.2809 194.401 55.4798C194.325 53.8721 191.811 53.8626 191.887 55.4798H191.911Z"
			fill="black"
		/>
		<path
			d="M196.867 48.9202C196.095 52.0432 196.783 55.3478 198.738 57.9033C199.692 59.1675 201.901 57.9033 200.908 56.6343C199.31 54.6611 198.714 52.0605 199.291 49.5881C199.658 48.0137 197.235 47.3411 196.867 48.9202V48.9202Z"
			fill="black"
		/>
		<path
			d="M203.585 46.1293C203.026 49.5728 203.515 53.1046 204.987 56.267C205.66 57.7363 207.85 56.4578 207.158 54.998C205.979 52.4362 205.579 49.5844 206.008 46.7972C206.251 45.2182 203.828 44.5312 203.585 46.1293Z"
			fill="black"
		/>
		<path
			d="M213.24 53.4857C221.433 52.8987 229.462 55.9923 235.142 61.925C238.225 65.131 240.37 69.1207 241.344 73.4604C242.494 78.8656 241.578 84.4329 240.318 89.7331C239.942 91.3026 242.365 91.9753 242.742 90.401C244.04 84.9338 244.932 79.2663 243.939 73.6751C243.011 68.8135 240.746 64.3068 237.399 60.6608C231.239 53.9247 222.348 50.3588 213.24 50.9716C211.637 51.0908 211.623 53.605 213.24 53.4857V53.4857Z"
			fill="black"
		/>
		<path
			d="M234.25 88.9125C233.855 90.0381 233.855 91.2647 234.25 92.3903C234.449 93.0488 235.128 93.4367 235.796 93.2729C236.463 93.0829 236.854 92.3923 236.674 91.7224C236.418 91.0294 236.428 90.2663 236.702 89.5804C236.878 88.9061 236.474 88.2169 235.801 88.0395C235.132 87.8698 234.449 88.2568 234.25 88.9173V88.9125Z"
			fill="black"
		/>
		<path
			d="M236.177 105.5H236.316C236.766 105.5 237.182 105.26 237.406 104.87C237.631 104.481 237.631 104.001 237.406 103.611C237.182 103.221 236.766 102.981 236.316 102.981H236.177C235.482 102.981 234.918 103.545 234.918 104.241C234.918 104.936 235.482 105.5 236.177 105.5V105.5Z"
			fill="black"
		/>
		<path
			d="M81.7087 68.7565C85.4038 74.2333 88.733 79.9483 91.6745 85.8641C93.1057 88.7503 94.6753 91.7368 95.701 94.7948C96.4738 97.099 96.4166 99.0883 93.578 99.5177C92.6907 99.656 91.956 99.6417 91.25 100.281C89.5802 101.793 89.8808 105.586 89.6136 107.637L88.2254 118.324C87.2999 125.445 86.3728 132.569 85.4441 139.696C85.2342 141.294 87.7531 141.28 87.9582 139.696C88.9123 132.263 89.8792 124.829 90.8588 117.393L92.3138 106.244C92.4188 105.433 92.4092 104.226 92.6764 103.458C93.3156 101.593 93.5971 102.17 94.8852 101.965C95.8384 101.823 96.714 101.358 97.3659 100.648C99.0261 98.8403 98.8544 96.288 98.1245 94.1269C97.1131 91.1214 95.6151 88.2113 94.2268 85.3679C91.1943 79.1762 87.7369 73.2018 83.8793 67.4876C82.9824 66.1518 80.8022 67.4112 81.7087 68.7565V68.7565Z"
			fill="black"
		/>
		<path
			d="M83.1781 64.1434C79.1135 69.8681 73.279 72.95 66.8053 75.2494C63.7139 76.3514 60.6034 77.3342 57.5168 78.4887C54.6926 79.5478 51.3961 80.4924 49.6691 83.1401L51.6441 82.8825C46.9069 77.8924 40.4426 83.3595 39.8368 88.7981C39.1212 95.1955 44.3212 100.448 50.6423 100.248C52.2548 100.2 52.2643 97.681 50.6423 97.7335C46.7017 97.8575 43.076 95.372 42.3891 91.3551C41.9896 89.1775 42.5554 86.9337 43.9395 85.2058C45.5043 83.3738 48.0852 82.7823 49.8647 84.6571C50.1421 84.9286 50.5284 85.0582 50.9134 85.0089C51.2984 84.9597 51.6397 84.7369 51.8397 84.4043C53.624 81.6707 58.9623 80.5878 61.9201 79.5764C65.0258 78.5078 68.1649 77.5107 71.2038 76.2369C76.9286 73.8516 81.7326 70.4835 85.3488 65.3933C86.3029 64.0718 84.1084 62.8171 83.1781 64.1243V64.1434Z"
			fill="black"
		/>
		<path
			d="M70.9605 86.5797C70.4834 85.3536 68.9902 85.1485 67.9645 85.8212C67.3741 86.2208 67.0329 86.8978 67.0629 87.6102C67.0651 88.1976 67.1404 88.7826 67.2871 89.3514C67.4922 90.0042 68.0241 90.5018 68.6891 90.6629C69.354 90.824 70.0548 90.6251 70.5359 90.1386C71.6284 89.1129 70.8794 87.3907 69.5007 87.1665C68.8344 87.0048 68.1574 87.3893 67.955 88.0443C67.7718 88.7145 68.1638 89.407 68.8328 89.5947C68.9918 89.6329 69.0156 89.6329 68.9043 89.5947L68.5895 89.1463C68.4573 88.9119 68.497 88.6181 68.6868 88.4273C68.8765 88.2364 69.17 88.1949 69.4052 88.3258C69.3575 88.3019 69.7726 88.5929 69.701 88.7122C69.6987 88.5324 69.6779 88.3534 69.639 88.1779C69.6348 88.0171 69.614 87.8573 69.577 87.7008V87.5624C69.5595 87.7281 69.4637 87.8753 69.3194 87.9584L69.2192 87.9966C69.348 87.968 69.3671 87.9584 69.2764 87.9727C69.1858 87.987 69.2096 87.9727 69.3385 87.9727C69.1582 87.9595 68.9887 87.8816 68.8614 87.7533L68.537 87.1999V87.2619C68.7374 87.9185 69.4161 88.3039 70.0827 88.1397C70.7492 87.9519 71.1406 87.2627 70.9605 86.594V86.5797Z"
			fill="black"
		/>
		<path
			d="M84.1752 85.1771C83.722 83.9511 82.2001 83.7459 81.1792 84.4186C80.5898 84.8196 80.2476 85.4951 80.2728 86.2076C80.2793 86.7966 80.3562 87.3828 80.5018 87.9536C80.708 88.6056 81.2405 89.102 81.9053 89.2621C82.5701 89.4222 83.2702 89.2226 83.7506 88.736C84.8431 87.7103 84.0941 85.9881 82.7106 85.7639C82.0433 85.6052 81.3674 85.9912 81.1649 86.6465C80.9875 87.3148 81.3778 88.0021 82.0427 88.1922C82.2049 88.2351 82.2288 88.2256 82.119 88.1922L81.7994 87.7437C81.6672 87.5093 81.707 87.2155 81.8967 87.0247C82.0865 86.8338 82.38 86.7923 82.6152 86.9232C82.5675 86.8993 82.9825 87.1903 82.9157 87.3096C82.8719 86.9696 82.8306 86.6293 82.7917 86.2887V86.1551C82.7706 86.3194 82.6758 86.4652 82.5341 86.5511L82.4339 86.5892C82.5627 86.5606 82.5818 86.5511 82.4911 86.5892C82.4005 86.6274 82.4196 86.5892 82.5484 86.5892C82.3679 86.5769 82.1981 86.4988 82.0713 86.3698L81.7087 85.7973V85.8593C81.9092 86.5159 82.5879 86.9013 83.2544 86.7371C83.921 86.5493 84.3124 85.8601 84.1322 85.1914L84.1752 85.1771Z"
			fill="black"
		/>
		<path
			d="M67.8213 103.539C70.4117 106.879 75.1824 107.547 79.1086 107.475C80.7259 107.442 80.7306 104.928 79.1086 104.961C75.7692 105.023 71.8047 104.603 69.5959 101.765C68.6084 100.486 66.8385 102.28 67.8213 103.539V103.539Z"
			fill="black"
		/>
		<path
			d="M47.0977 91.0402C47.2456 91.1356 47.0977 91.0402 47.0547 90.9925C47.0547 91.0307 47.1788 91.1023 47.2122 91.1404C47.2456 91.1786 47.3887 91.2835 47.2599 91.1738C47.1311 91.0641 47.2599 91.2072 47.3124 91.2597L47.3982 91.4123C47.4936 91.5889 47.3982 91.3551 47.3744 91.3503C47.3505 91.3456 47.465 91.7129 47.4746 91.7701C47.6602 92.4384 48.3512 92.8309 49.0202 92.6479C49.6768 92.4475 50.0622 91.7688 49.898 91.1023C49.7257 90.1752 49.1694 89.3642 48.3667 88.8696C47.7668 88.5213 46.9983 88.7214 46.6445 89.318C46.3105 89.9209 46.5102 90.6799 47.0977 91.0402V91.0402Z"
			fill="black"
		/>
		<path
			d="M85.4966 56.8681C69.3575 51.1433 50.48 54.8072 38.7824 67.6068C32.447 74.5338 27.8624 82.8204 24.3416 91.4601C22.4965 96.0487 20.8914 100.73 19.5328 105.486C18.9699 107.423 18.1016 109.603 18.4165 111.649C18.7123 113.51 19.9431 114.784 21.3457 115.943C30.0593 122.953 40.3072 127.803 51.2529 130.098C51.6307 130.194 52.0317 130.112 52.342 129.876C52.6523 129.64 52.8379 129.276 52.8463 128.886C53.0657 122.057 53.255 115.231 53.414 108.405C53.4712 105.953 55.1839 96.8175 50.3083 97.7716C48.7197 98.0865 49.3923 100.51 50.9762 100.2C50.7376 100.243 50.7901 112.914 50.7615 114.121C50.6343 119.044 50.4896 123.966 50.3274 128.886L51.9208 127.669C43.1922 125.824 34.8977 122.325 27.4855 117.36C25.6631 116.129 23.7024 114.865 22.1328 113.314C20.0624 111.268 21.2503 108.587 21.9563 106.158C23.1871 101.927 24.5659 97.7287 26.1688 93.6212C32.1703 78.2692 41.9692 62.8075 58.7571 58.2325C67.3526 55.9267 76.4464 56.2928 84.8287 59.282C86.3601 59.8211 87.0137 57.3738 85.4966 56.8538V56.8681Z"
			fill="black"
		/>
		<path
			d="M83.9843 58.9385C87.4659 60.5903 90.5731 62.9362 93.1153 65.8321C94.2984 67.1918 96.4071 69.5389 96.4309 71.4901C96.4643 73.7323 93.7928 75.7837 92.4236 77.296C91.3406 78.4982 93.1153 80.2824 94.203 79.0754C95.7487 77.358 97.9623 75.5165 98.6922 73.2409C99.4603 70.8556 98.2581 68.5609 96.9175 66.6384C93.9392 62.4052 89.9248 59.0067 85.2581 56.7679C83.8269 56.0618 82.5293 58.2325 83.9843 58.9385V58.9385Z"
			fill="black"
		/>
		<path
			d="M43.1428 149.805C51.2529 168.802 81.6229 175.28 93.7737 156.827C94.6658 155.473 92.4857 154.213 91.5983 155.558C80.3921 172.575 52.6317 165.639 45.323 148.536C44.6885 147.052 42.5226 148.336 43.1524 149.805H43.1428Z"
			fill="black"
		/>
		<path
			d="M92.3519 157.882C107.356 162.447 118.667 174.789 122.378 189.95C125.169 201.352 125.994 213.422 127.45 225.052C128.079 230.062 128.676 235.071 129.391 240.08C129.615 241.678 132.039 240.996 131.815 239.412C130.302 228.831 129.134 218.202 127.774 207.601C126.968 201.328 126.243 194.993 124.697 188.853C120.768 172.913 108.73 160.222 93.0198 155.458C91.4693 154.981 90.8062 157.414 92.3519 157.887V157.882Z"
			fill="black"
		/>
		<path
			d="M51.8157 135.579H51.5342C51.0666 135.55 50.6217 135.783 50.3791 136.184C50.1366 136.585 50.1366 137.087 50.3791 137.488C50.6217 137.889 51.0666 138.122 51.5342 138.093H51.8157C52.5064 138.088 53.0651 137.529 53.0703 136.838C53.0602 136.149 52.5051 135.592 51.8157 135.579V135.579Z"
			fill="black"
		/>
		<path
			d="M37.9953 150.874C26.7461 154.29 18.0349 162.939 12.5343 173.091C5.89837 185.294 3.94717 199.329 2.578 212.969C1.67157 221.89 0.860564 230.82 0.0066176 239.746C-0.146043 241.354 2.39194 241.344 2.52075 239.746C3.40332 230.534 4.22864 221.312 5.178 212.105C6.08442 203.322 7.27232 194.511 9.87709 186.048C14.2804 171.736 23.7501 157.834 38.687 153.297C40.2327 152.82 39.5744 150.401 38.0191 150.874H37.9953Z"
			fill="black"
		/>
		<path
			d="M68.036 143.436C73.7204 144.664 79.6449 144.113 85.0052 141.857C85.9879 141.437 87.5718 140.121 88.5593 140.049C90.6155 139.911 90.3531 143.112 90.5487 144.433C91.0925 148.092 92.2757 152.152 90.9447 155.74C90.3817 157.262 92.8147 157.915 93.3729 156.408C94.7659 152.648 93.6782 148.522 93.1105 144.691C92.7718 142.425 92.9483 138.294 90.1766 137.416C88.9124 137.015 87.7913 137.654 86.6892 138.217C84.95 139.171 83.1222 139.954 81.2316 140.555C77.1585 141.742 72.8555 141.906 68.7039 141.032C67.1248 140.703 66.4521 143.126 68.036 143.455V143.436Z"
			fill="black"
		/>
		<path
			d="M24.752 159.647C23.1175 155.419 21.7723 151.086 20.7256 146.676C20.4632 145.554 19.9337 144.142 19.9862 142.988C20.0482 141.452 21.4174 140.603 22.4669 139.768C24.0334 138.539 25.685 137.423 27.4093 136.428C34.5349 132.327 42.6755 130.326 50.8904 130.656C52.5077 130.718 52.5077 128.204 50.8904 128.142C41.4268 127.779 32.0818 130.346 24.1319 135.493C21.0596 137.502 16.9377 139.815 17.5913 144.009C18.4548 149.562 20.3201 155.086 22.3238 160.315C22.901 161.808 25.3293 161.159 24.752 159.647V159.647Z"
			fill="black"
		/>
		<path
			d="M10.6929 149.39C11.7539 149.123 12.7283 148.588 13.5219 147.835C13.9775 147.338 13.9609 146.57 13.4841 146.093C13.0073 145.616 12.2395 145.6 11.7425 146.055C11.2636 146.509 10.6696 146.822 10.025 146.962C9.36707 147.156 8.98599 147.842 9.16795 148.504C9.34992 149.165 10.028 149.559 10.6929 149.39V149.39Z"
			fill="black"
		/>
		<path
			d="M11.4848 154.867C12.6511 154.984 13.8101 154.584 14.6573 153.774C15.1487 153.284 15.1497 152.489 14.6597 151.997C14.1696 151.506 13.374 151.505 12.8826 151.995C12.7177 152.142 12.5222 152.252 12.3101 152.314C12.2446 152.336 12.1776 152.354 12.1097 152.367C12.2147 152.367 12.1908 152.367 12.0334 152.367C11.8501 152.374 11.6664 152.365 11.4848 152.338C10.8074 152.252 10.2253 152.982 10.2253 153.598C10.2453 154.285 10.7976 154.837 11.4848 154.857V154.867Z"
			fill="black"
		/>
		<path
			d="M12.5343 160.343C13.4072 160.715 14.3853 160.758 15.287 160.463C16.1848 160.112 16.791 159.265 16.8327 158.302C16.8301 157.609 16.2706 157.047 15.578 157.042C14.8867 157.052 14.3288 157.61 14.3186 158.302C14.3186 158.23 14.4283 158.034 14.4378 158.082C14.4474 158.13 14.2565 158.158 14.2279 158.163C13.8732 158.151 13.5246 158.068 13.2022 157.92C12.7693 157.801 12.3056 157.921 11.9858 158.237C11.6661 158.552 11.5388 159.014 11.6519 159.448C11.765 159.883 12.1014 160.224 12.5343 160.343V160.343Z"
			fill="black"
		/>
		<path
			d="M14.0704 166.164C16.0862 166.743 18.2584 166.284 19.8667 164.938C20.3522 164.444 20.3522 163.652 19.8667 163.158C19.3689 162.683 18.5851 162.683 18.0873 163.158C17.1342 163.882 15.8895 164.099 14.7478 163.74C13.1926 163.287 12.5294 165.71 14.0799 166.164H14.0704Z"
			fill="black"
		/>
		<path
			d="M235.242 113.367V144.81C235.242 146.427 237.757 146.432 237.757 144.81V113.367C237.757 111.75 235.242 111.745 235.242 113.367V113.367Z"
			fill="black"
		/>
		<path
			d="M182.951 154.552C188.533 160.124 194.597 164.842 202.373 166.87C213.741 169.828 227.18 167.824 237.437 162.261C242.36 159.599 246.549 155.916 250.318 151.828C251.415 150.64 249.64 148.856 248.538 150.048C244.517 154.404 240.061 158.216 234.703 160.844C227.547 164.36 219.118 165.882 211.16 165.615C205.264 165.471 199.502 163.829 194.415 160.844C190.775 158.655 187.708 155.778 184.717 152.792C183.572 151.647 181.792 153.426 182.942 154.571L182.951 154.552Z"
			fill="black"
		/>
		<path
			d="M183.09 153.531C170.958 156.999 162.404 167.232 158.092 178.744C153.478 191.076 154.07 205.097 155.167 217.997C155.783 225.41 156.813 232.782 158.254 240.08C158.573 241.664 160.997 240.996 160.682 239.412C158.15 226.994 156.927 214.345 157.032 201.672C157.185 189.554 159.389 177.537 166.97 167.724C171.263 162.166 176.988 157.896 183.762 155.954C185.313 155.511 184.655 153.092 183.09 153.531V153.531Z"
			fill="black"
		/>
		<path
			d="M249.44 152.195C269.354 153.024 285.309 168.977 286.141 188.891C286.207 190.504 288.722 190.513 288.655 188.891C287.795 167.601 270.731 150.539 249.44 149.681C247.818 149.619 247.823 152.133 249.44 152.195V152.195Z"
			fill="black"
		/>
		<path
			d="M160.553 240.381C164.656 233.325 172.07 228.211 178.954 224.16C186.878 219.49 196.128 215.134 205.526 215.297C207.143 215.325 207.143 212.811 205.526 212.782C195.708 212.611 185.967 217.076 177.685 221.99C170.433 226.283 162.7 231.693 158.383 239.121C157.567 240.524 159.738 241.793 160.553 240.39V240.381Z"
			fill="black"
		/>
		<path
			d="M203.837 215.311L210.516 232.485C210.629 232.855 210.903 233.154 211.261 233.299C211.619 233.445 212.024 233.421 212.362 233.234C215.735 231.619 218.978 229.744 222.061 227.629C223.387 226.722 222.133 224.542 220.792 225.458C217.705 227.566 214.461 229.433 211.089 231.045L212.935 231.794L206.256 214.619C205.674 213.126 203.241 213.775 203.832 215.287L203.837 215.311Z"
			fill="black"
		/>
		<path
			d="M225.601 225.687C227.218 225.687 227.223 223.173 225.601 223.173C223.979 223.173 223.984 225.687 225.601 225.687Z"
			fill="black"
		/>
		<path
			d="M231.03 222.82C237.149 218.291 243.391 213.932 249.755 209.744C255.69 205.851 261.682 201.829 268.136 198.828C272.845 196.629 278.026 194.768 283.264 195.727C288.321 196.653 292.996 199.21 295.591 203.775C296.388 205.183 298.563 203.919 297.762 202.506C295.109 197.855 290.52 194.902 285.416 193.628C280.402 192.369 275.874 193.123 271.042 194.907C263.79 197.578 257.24 201.905 250.785 206.08C243.613 210.714 236.601 215.575 229.747 220.664C228.463 221.618 229.713 223.803 231.016 222.834L231.03 222.82Z"
			fill="black"
		/>
		<path
			d="M290.363 208.484C291.342 209.289 292.175 210.257 292.824 211.346C293.645 212.744 295.82 211.48 294.995 210.077C294.243 208.797 293.275 207.658 292.132 206.709C291.635 206.234 290.851 206.234 290.353 206.709C289.868 207.202 289.868 207.992 290.353 208.484H290.363Z"
			fill="black"
		/>
		<path
			d="M285.597 212.162C285.954 213.139 286.551 214.01 287.333 214.696C287.83 215.151 288.598 215.134 289.075 214.658C289.552 214.181 289.568 213.413 289.113 212.916C288.979 212.808 288.851 212.691 288.731 212.568L288.559 212.377L288.473 212.277C288.373 212.134 288.283 211.991 288.197 211.838C288.154 211.762 288.116 211.685 288.077 211.609C288.039 211.533 287.958 211.332 288.02 211.494C287.824 210.835 287.142 210.447 286.474 210.617C285.883 210.807 285.339 211.509 285.597 212.162V212.162Z"
			fill="black"
		/>
		<path
			d="M279.757 215.64C280.404 216.94 281.331 218.081 282.472 218.98C282.971 219.453 283.753 219.453 284.251 218.98C284.736 218.487 284.736 217.697 284.251 217.205C283.27 216.44 282.477 215.461 281.933 214.342C281.577 213.748 280.812 213.547 280.211 213.889C279.614 214.242 279.404 215.002 279.734 215.611L279.757 215.64Z"
			fill="black"
		/>
		<path
			d="M270.693 220.062C274.82 221.255 279.886 220.821 281.923 216.465C282.601 215.006 280.435 213.727 279.753 215.196C278.193 218.536 274.462 218.536 271.361 217.639C269.806 217.191 269.138 219.614 270.693 220.062V220.062Z"
			fill="black"
		/>
		<path
			d="M38.649 171.574C38.0908 170.848 37.8284 169.317 37.3227 168.797C37.2655 167.843 37.046 168.053 36.6691 169.36C36.8904 170.003 37.0158 170.675 37.0412 171.354C37.1462 172.68 39.2024 173.172 39.5124 171.693C39.8534 169.888 40.7529 168.236 42.0838 166.97L39.98 166.416C40.8688 168.712 39.8063 171.303 37.5612 172.313L38.5154 174.608C40.0452 174.115 40.8784 174.407 41.0152 175.485C41.1869 176.373 41.2824 176.165 41.3014 174.861C41.2251 174.054 40.6002 173.043 40.3139 172.299L38.0192 173.253C38.2196 173.534 39.5029 175.204 39.2882 175.495L38.3341 175.581C38.5293 175.017 38.7886 174.478 39.1069 173.973H36.9363C36.3225 173.935 35.7763 173.571 35.5051 173.019C35.348 172.758 35.2046 172.489 35.0757 172.213L34.6273 173.935L36.3495 174.274C37.4897 175.423 39.2691 173.644 38.1242 172.499C36.9031 171.166 34.9193 170.86 33.3535 171.764C32.7624 172.12 32.5615 172.881 32.9003 173.482C33.7543 175.166 35.2141 177.179 37.3943 176.559C38.1925 176.371 38.8569 175.821 39.1895 175.071C39.5221 174.322 39.4845 173.46 39.0879 172.742C38.8579 172.363 38.4463 172.131 38.0025 172.131C37.5587 172.131 37.1472 172.363 36.9172 172.742C36.1062 174.174 35.3477 175.777 35.9631 177.422C36.388 178.683 37.6022 179.505 38.9304 179.431C40.1817 179.235 41.2144 178.348 41.5972 177.141C42.2603 175.28 41.2156 173.501 40.166 172.022C39.4027 170.963 37.3036 171.545 37.8666 172.976C38.4915 174.603 39.0068 177.661 40.8959 178.372C42.1935 178.849 43.2335 178.004 43.5484 176.75C44.5025 172.933 40.209 170.891 37.2225 172.456C35.9869 173.105 36.8647 175.319 38.1767 174.756C41.7069 173.272 43.6629 169.474 42.3558 165.768C42.2276 165.343 41.8884 165.015 41.4596 164.901C41.0308 164.788 40.5737 164.904 40.2519 165.21C38.6089 166.778 37.4866 168.813 37.0365 171.039L39.5029 171.373C39.3646 169.665 38.5488 165.171 35.7961 166.307C33.239 167.347 35.3811 171.497 36.4258 172.862C37.38 174.131 39.584 172.862 38.5965 171.593L38.649 171.574Z"
			fill="#7C839E"
		/>
		<path
			d="M65.3169 189.039C65.0354 188.056 62.0251 184.345 63.4706 183.705L61.7484 183.257C62.4492 184.28 62.8295 185.489 62.8409 186.73C62.8743 188.118 64.9495 188.471 65.3121 187.064C65.8452 184.972 66.7997 183.011 68.1173 181.301L65.8178 181C66.7055 183.145 66.1081 185.618 64.3389 187.121C63.1367 188.214 64.9114 189.984 66.1184 188.896C66.834 187.805 67.5718 187.904 68.3319 189.192C68.165 189.54 67.9789 189.879 67.7785 190.208C67.2284 191.103 66.5769 191.933 65.8369 192.679L67.0629 192.355C66.0332 192.192 65.2642 191.321 65.231 190.279C65.1213 189.196 63.4516 188.371 62.8886 189.645C62.3639 190.842 60.5033 191.162 62.5213 190.661C63.6209 190.472 64.7465 190.497 65.8369 190.733C67.4207 190.976 68.1029 188.552 66.5048 188.304C64.0622 187.932 58.3327 187.756 58.6332 191.687C58.9194 195.422 64.129 193.032 65.064 190.914L62.7169 190.279C62.9506 192.536 64.1767 194.144 66.3951 194.778C66.8309 194.899 67.2979 194.775 67.6163 194.454C69.224 192.789 72.4395 189.12 70.8652 186.582C69.2241 183.939 66.042 185.628 64.3389 187.121L66.1184 188.896C68.5279 186.755 69.3732 183.352 68.2461 180.332C68.0967 179.858 67.6856 179.513 67.1922 179.449C66.6989 179.384 66.213 179.612 65.9466 180.032C64.5083 181.926 63.4666 184.09 62.8839 186.396L65.3551 186.73C65.3412 185.045 64.8445 183.399 63.9239 181.988C63.5607 181.407 62.8088 181.209 62.2064 181.535C60.9136 182.107 60.3983 183.381 60.4938 184.745C60.6369 186.744 62.3686 187.87 62.8791 189.707C63.3275 191.257 65.7415 190.599 65.3073 189.039H65.3169Z"
			fill="#7C839E"
		/>
		<path
			d="M95.4864 183.93C94.5942 183.791 93.7987 183.29 93.2872 182.546C92.8435 181.893 93.0916 181.196 93.0152 182.665H95.5437C95.5165 181.432 95.6988 180.203 96.0828 179.03L96.5884 177.842C97.2516 177.146 97.1132 176.888 96.1734 177.103C95.9778 177.523 96.0541 179.965 95.973 180.361C95.7375 181.491 95.3841 182.592 94.9187 183.648L97.3851 183.982C99.2552 184.936 99.3125 185.095 97.5569 184.459L96.9987 186.558C95.8728 187.002 94.1888 186.444 93.3826 185.604C92.2758 184.416 90.5202 186.196 91.6031 187.384C93.3396 189.244 97.5187 190.461 99.2075 187.894C100.51 185.909 98.5921 183.276 96.3451 184.159C95.5198 184.483 95.0332 185.59 95.7917 186.262C98.4585 188.71 103.181 186.51 101.827 182.713C100.395 178.72 95.2765 180.266 94.8853 183.996C94.7279 185.542 96.8604 185.504 97.3565 184.33C98.158 182.422 100.491 175.61 97.056 174.789C93.8024 173.997 93.0438 180.748 93.0438 182.694C93.0438 184.321 95.4721 184.302 95.558 182.694C95.6439 181.086 95.4053 179.278 93.6879 178.606C92.5412 178.108 91.2071 178.614 90.6776 179.746C89.3084 182.646 92.1088 185.852 94.8472 186.382C96.431 186.687 97.1084 184.264 95.515 183.958L95.4864 183.93Z"
			fill="#7C839E"
		/>
		<path
			d="M33.2581 204.181C33.0228 203.632 32.8778 203.049 32.8288 202.454L32.9147 201.376C33.5062 200.756 33.5253 200.355 32.9624 200.178C32.6809 199.401 32.9194 201.132 32.9624 201.285C33.1532 202.177 33.2963 203.065 33.4108 203.966C33.5825 205.307 35.4383 205.755 35.882 204.305C36.5071 202.152 37.8942 200.301 39.7844 199.095L38.067 198.642C39.2024 200.994 37.5613 203.265 35.0281 203.232L35.9106 205.397C37.0556 204.806 37.7235 205.124 37.9143 206.352C37.0492 207.684 36.5229 207.684 36.3352 206.352L35.0758 207.606C34.7227 208.494 34.1789 208.813 33.449 208.56C32.8002 208.56 32.7191 208.498 33.2057 208.403L31.9844 206.299L31.1018 207.64C32.6952 207.349 32.0178 204.925 30.4339 205.211C28.9455 205.483 28.4589 207.296 29.2937 208.489C30.4816 210.192 32.5664 209.104 33.7638 208.079C34.718 207.263 33.7018 205.593 32.5378 205.975C31.1066 206.452 30.0093 207.821 30.4339 209.419C30.911 211.184 33.3774 211.327 34.8802 211.07C38.4438 210.45 39.2453 204.911 35.0758 205.078C34.3915 205.103 33.8432 205.653 33.8211 206.337C33.3869 210.116 38.94 211.275 40.2137 207.702C41.5352 204.004 36.9792 200.927 34.1359 203.647C33.7922 204.011 33.6943 204.544 33.8857 205.007C34.0771 205.47 34.5224 205.778 35.0233 205.793C39.6031 205.851 42.1793 201.457 40.2328 197.416C39.879 196.819 39.1105 196.619 38.5106 196.968C36.0673 198.516 34.2702 200.9 33.4537 203.675L35.9202 204.009C35.7007 202.297 35.7055 197.073 32.7047 197.769C29.9091 198.423 30.1095 202.936 30.8394 204.892C31.4024 206.395 33.8354 205.746 33.2677 204.224L33.2581 204.181Z"
			fill="#7C839E"
		/>
		<path
			d="M14.7479 190.184C15.2426 189.696 15.6695 189.144 16.0169 188.543C16.3652 187.975 16.6943 187.026 17.3527 186.744C18.4165 186.267 17.5721 187.264 17.2573 187.67C16.6814 188.386 16.0269 189.036 15.3061 189.607L17.4052 190.165C17.4576 190.451 17.4958 190.074 17.7105 190.165C18.0759 190.278 18.3549 190.575 18.4452 190.947C18.6074 191.348 18.9461 192.56 18.7409 192.96C18.0683 194.244 16.1362 190.642 15.9692 190.098L13.8701 191.319C14.3758 191.963 13.8319 192.717 13.2404 192.984H14.5094C13.9989 192.746 13.7938 192.25 14.247 191.806C15.4158 190.68 13.6363 188.901 12.4675 190.026C10.96 191.486 11.3083 195.546 14.0419 195.308C16.7754 195.069 16.9472 191.2 15.6448 189.54C14.8433 188.519 13.2213 189.635 13.5457 190.766C14.1659 192.917 15.5971 195.231 17.9681 195.665C20.3391 196.099 21.3934 194.234 21.2074 192.13C21.0404 190.265 20.2532 187.837 18.0492 187.689C16.1696 187.546 14.6286 188.972 14.9817 190.861C15.1725 191.882 16.4129 191.954 17.0808 191.415C18.7075 190.093 22.4764 186.239 19.2657 184.512C18.135 183.984 16.8109 184.085 15.7736 184.779C14.4378 185.652 14.0848 187.369 12.9685 188.438C11.8521 189.506 13.5743 191.334 14.7479 190.212V190.184Z"
			fill="#7C839E"
		/>
		<path
			d="M68.9139 219.924C67.3546 218.647 66.7583 216.532 67.4207 214.629L65.5697 215.382C66.502 215.736 67.1905 216.539 67.3969 217.515C67.7642 218.946 69.7822 218.55 69.8681 217.181C69.9587 215.51 70.8459 213.984 72.2534 213.078L70.3451 211.995C70.5771 214.48 70.1886 216.983 69.2145 219.28H71.638C71.5281 220.282 70.9198 221.161 70.0207 221.618L71.7429 222.066L71.366 220.931C71.366 219.313 68.8519 219.309 68.8471 220.931C68.8162 221.784 69.0696 222.623 69.5675 223.316C69.9214 223.912 70.6882 224.114 71.2897 223.769C73.136 222.457 75.6453 219.428 72.9738 217.453C71.0655 216.065 68.8853 217.839 69.2145 219.929C69.4292 221.312 71.2277 220.912 71.638 219.929C72.6921 217.419 73.1116 214.687 72.8593 211.976C72.7829 211.098 71.824 210.316 70.951 210.888C68.7952 212.266 67.4445 214.606 67.3301 217.162L69.7965 216.828C69.4262 215.214 68.3208 213.866 66.8101 213.188C66.4693 213.021 66.072 213.013 65.7249 213.167C65.3778 213.32 65.1166 213.62 65.0115 213.985C64.1065 216.762 64.9521 219.812 67.1583 221.727C68.3653 222.791 70.1495 221.021 68.933 219.948L68.9139 219.924Z"
			fill="#7C839E"
		/>
		<path
			d="M100.849 213.15C100.849 212.348 99.8946 211.9 99.6799 211.184C99.4153 210.852 99.4153 210.381 99.6799 210.049C99.6799 209.095 100.125 208.996 101.016 209.753L103.358 210.388C103.31 209.352 103.582 206.433 104.904 206.07C106.03 205.755 105.5 207.754 105.381 208.184C105.092 209.066 104.622 209.878 104.002 210.569C103.546 211.066 103.563 211.834 104.04 212.31C104.517 212.787 105.284 212.804 105.782 212.348C106.344 211.909 106.354 213.512 105.615 213.913C105.309 214.08 104.183 213.846 104.031 214.142C104.727 212.711 102.561 211.413 101.86 212.873C100.023 216.718 106.392 217.482 108.343 214.996C110.6 212.134 106.86 208.317 104.002 210.569L105.782 212.348C107.384 210.469 110.156 204.51 106.521 203.098C102.886 201.686 100.744 207.869 100.853 210.368C100.901 211.499 102.662 212.234 103.196 211.003C105.071 206.671 98.3823 204.73 97.1085 209.29C96.5312 211.361 97.8193 213.861 99.7228 214.805C101.245 215.559 103.387 215.101 103.382 213.131C103.382 211.513 100.863 211.509 100.868 213.131L100.849 213.15Z"
			fill="#7C839E"
		/>
		<path
			d="M17.0284 229.618C16.3788 228.849 15.9574 227.914 15.8119 226.918L14.2662 228.464C14.5645 228.506 14.8333 228.666 15.0126 228.908C15.1919 229.15 15.2667 229.454 15.2203 229.752C15.1631 231.183 17.329 231.45 17.6915 230.086C17.8726 229.354 18.1317 228.644 18.4644 227.967C18.5693 227.762 19.4185 226.15 19.5521 226.164L18.66 225.797C19.3708 226.417 17.8013 230.047 17.5007 230.73H19.6714C19.4996 230.067 19.4567 230.019 19.533 230.591C19.591 231.07 19.4456 231.551 19.1323 231.918C18.9022 232.243 18.4977 232.396 18.1098 232.305C17.7218 232.214 17.428 231.896 17.3671 231.503C17.0189 230.005 15.0581 230.548 14.8959 231.836L14.3425 232.042L15.5543 233.635L15.8644 233.215C17.1381 232.223 15.3491 230.453 14.0849 231.436C13.59 231.812 13.2505 232.357 13.1308 232.967C13.0364 233.344 13.1187 233.744 13.3545 234.053C13.5903 234.362 13.9539 234.547 14.3425 234.556C16.0981 234.713 17.2049 233.473 17.4148 231.836L14.9436 232.17C15.9741 236.602 22.1712 234.875 22.071 230.711C22.0281 228.993 20.4013 227.009 18.5359 227.719C16.6706 228.43 16.7994 230.515 17.5007 232.013C17.8776 232.824 19.2992 232.853 19.6714 232.013C20.4585 230.234 22.6244 225.425 20.3106 223.931C17.7297 222.267 15.7117 227.748 15.2633 229.432L17.7345 229.766C17.8337 228.019 16.6623 226.453 14.958 226.054C14.5231 225.942 14.0613 226.068 13.7437 226.386C13.4262 226.703 13.3001 227.165 13.4123 227.6C13.6669 229.02 14.311 230.341 15.2728 231.417C16.3271 232.647 18.1018 230.858 17.0523 229.637L17.0284 229.618Z"
			fill="#7C839E"
		/>
		<path
			d="M49.6645 235.801C49.1616 235.672 48.7451 235.321 48.5338 234.847C48.4049 234.487 48.345 234.107 48.3573 233.726L47.4032 233.85C48.1188 233.959 49.0777 235.391 49.5738 235.901C50.6234 236.998 52.107 235.61 51.5489 234.379C51.2929 233.745 51.3813 233.024 51.7826 232.471C51.9687 232.199 52.2358 232.075 52.441 231.846C52.8465 231.813 52.918 231.708 52.6032 231.541C52.4092 231.254 52.3042 231.321 52.2883 231.741C51.7826 232.371 51.5441 233.625 51.2435 234.384L49.9984 237.556C49.4641 238.906 51.5918 239.717 52.2931 238.511C52.7527 237.684 53.5212 237.073 54.4303 236.812L52.8369 235.6C52.6843 235.557 52.6843 235.562 52.8369 235.6C51.587 234.599 49.798 236.364 51.0623 237.38C51.8059 238.032 52.856 238.204 53.7686 237.822C54.6812 237.441 55.297 236.573 55.3558 235.586C55.3527 235.195 55.1691 234.828 54.8584 234.591C54.5477 234.354 54.1449 234.274 53.7672 234.374C52.2282 234.82 50.9228 235.846 50.1272 237.237L52.4267 238.191C53.2186 236.178 54.3349 234.098 54.8406 231.989C55.227 230.458 54.3254 229.041 52.6557 229.16C49.5404 229.384 48.2333 233.086 49.3782 235.615L51.3533 234.088C50.4707 233.172 49.445 231.55 48.0758 231.393C46.9166 231.254 46.2296 232.161 45.9815 233.206C45.5373 235.403 46.8589 237.573 49.0157 238.186C50.5757 238.635 51.2388 236.211 49.6835 235.763L49.6645 235.801Z"
			fill="#7C839E"
		/>
		<path
			d="M92.0898 234.451C91.9312 233.607 91.3699 232.894 90.587 232.542C90.1791 232.381 89.7809 232.197 89.3944 231.989C88.8505 231.598 89.1368 231.622 88.9841 231.989C88.4355 233.339 90.5727 234.15 91.2835 232.943L92.7624 230.453C92.9628 230.119 93.5019 229.604 93.2061 229.298C92.9103 228.993 92.9246 229.752 92.8435 230.024C92.3166 231.706 91.552 233.304 90.5727 234.77L92.8722 235.725C92.9628 235.119 93.3969 233.287 94.3034 233.392L93.416 233.024L93.2157 232.547H92.8102C92.3601 232.495 91.9054 232.495 91.4553 232.547H89.0127C87.3716 232.547 87.4193 234.933 89.0127 235.061C89.8799 235.14 90.749 234.934 91.4887 234.475C92.8817 233.654 91.6175 231.479 90.2149 232.299C89.8764 232.568 89.4301 232.66 89.0127 232.547V235.061C90.8112 235.061 97.2803 235.705 95.7489 231.951C95.2193 230.653 93.7357 230.591 92.6813 231.283C91.4515 232.168 90.6418 233.521 90.4439 235.023C90.234 236.388 91.9228 237.261 92.7386 235.977C93.6797 234.533 94.4452 232.981 95.019 231.355C95.4435 230.162 95.9302 228.631 95.1669 227.452C92.8865 223.965 89.9621 230.171 89.1034 231.612L91.4028 232.566C92.4571 229.966 88.9555 227.6 87.1092 229.88C86.3124 230.824 86.2787 232.195 87.0281 233.177C87.5052 233.807 89.5804 234.389 89.6663 234.999C89.9001 236.598 92.3235 235.915 92.0946 234.331L92.0898 234.451Z"
			fill="#7C839E"
		/>
		<path
			d="M115.452 232.218C111.73 231.264 109.898 236.722 113.725 237.814C115.184 238.252 116.726 237.448 117.202 236.001C117.889 233.854 115.952 232.867 115.566 230.982L114.02 232.533L116.973 233.082C117.64 233.246 118.319 232.86 118.519 232.204C118.783 231.511 118.839 230.757 118.681 230.033C118.681 229.771 118.681 229.756 118.648 229.99C118.627 230.314 118.467 230.613 118.209 230.811C117.822 231.173 117.387 231.479 116.916 231.722C115.533 232.557 116.792 234.732 118.185 233.893C119.402 233.153 120.652 232.275 121.047 230.825C121.429 229.597 120.959 228.265 119.893 227.548C118.977 226.971 117.794 227.05 116.964 227.743C115.728 228.855 116.563 230.214 116.091 231.536L117.636 230.658L114.683 230.105C114.249 229.996 113.789 230.123 113.472 230.439C113.156 230.756 113.029 231.216 113.138 231.65C113.362 232.664 113.81 233.614 114.45 234.432C114.583 234.618 115.137 234.999 114.755 235.338C114.178 235.858 113.643 234.351 114.779 234.642C116.348 235.052 117.016 232.624 115.447 232.218H115.452Z"
			fill="#7C839E"
		/>
		<path
			d="M83.722 204.41H84.013C84.6773 204.369 85.195 203.818 85.195 203.153C85.195 202.487 84.6773 201.937 84.013 201.896H83.722C83.0578 201.937 82.54 202.487 82.54 203.153C82.54 203.818 83.0578 204.369 83.722 204.41Z"
			fill="#7C839E"
		/>
		<path
			d="M32.8288 188.748V188.457C32.7879 187.793 32.2373 187.275 31.5718 187.275C30.9063 187.275 30.3556 187.793 30.3147 188.457V188.748C30.3556 189.412 30.9063 189.93 31.5718 189.93C32.2373 189.93 32.7879 189.412 32.8288 188.748Z"
			fill="#7C839E"
		/>
		<path
			d="M14.5717 213.341C16.1937 213.341 16.1937 210.826 14.5717 210.826C12.9496 210.826 12.9544 213.341 14.5717 213.341Z"
			fill="#7C839E"
		/>
		<path
			d="M35.7102 225.911V225.62C35.6692 224.956 35.1186 224.438 34.4531 224.438C33.7876 224.438 33.237 224.956 33.196 225.62V225.911C33.237 226.576 33.7876 227.093 34.4531 227.093C35.1186 227.093 35.6692 226.576 35.7102 225.911V225.911Z"
			fill="#7C839E"
		/>
		<path
			d="M53.1803 212.763H53.4713C54.1355 212.722 54.6532 212.172 54.6532 211.506C54.6532 210.841 54.1355 210.29 53.4713 210.249H53.1803C52.516 210.29 51.9983 210.841 51.9983 211.506C51.9983 212.172 52.516 212.722 53.1803 212.763V212.763Z"
			fill="#7C839E"
		/>
		<path
			d="M68.6371 235.782L68.6085 235.5L68.7802 234.866C69.4163 234.112 70.0524 234.112 70.6885 234.866L70.8602 235.5V235.476L70.6885 236.111V236.078C70.8459 235.786 70.8917 235.447 70.8173 235.123C70.7277 234.809 70.5211 234.541 70.2401 234.374C69.6914 234.083 68.8089 234.184 68.5179 234.823C68.1649 235.434 68.3337 236.212 68.9077 236.622C69.4817 237.032 70.2732 236.939 70.7362 236.407C71.0871 236.07 71.2165 235.563 71.0701 235.1C70.9712 234.789 70.7671 234.522 70.4929 234.346C70.2033 234.181 69.8608 234.137 69.5388 234.222C69.2218 234.308 68.9513 234.516 68.785 234.799C68.6127 235.086 68.5661 235.431 68.6562 235.753L68.6371 235.782Z"
			fill="#7C839E"
		/>
		<path
			d="M81.809 183.562V182.975C81.8378 182.508 81.6047 182.063 81.2039 181.82C80.8031 181.578 80.3008 181.578 79.9 181.82C79.4991 182.063 79.2661 182.508 79.2949 182.975V183.553C79.3358 184.217 79.8865 184.735 80.5519 184.735C81.2174 184.735 81.7681 184.217 81.809 183.553V183.562Z"
			fill="#7C839E"
		/>
		<path
			d="M52.0546 178.925L51.7684 178.639C51.2783 178.148 50.4827 178.146 49.9913 178.637C49.4999 179.127 49.4989 179.922 49.9889 180.414L50.2752 180.705C50.588 181.046 51.063 181.188 51.5119 181.075C51.9608 180.961 52.3114 180.611 52.4247 180.162C52.538 179.713 52.3959 179.238 52.0546 178.925V178.925Z"
			fill="#7C839E"
		/>
		<path
			d="M25.8112 171.278C27.4285 171.278 27.4333 168.759 25.8112 168.759C24.1892 168.759 24.1892 171.278 25.8112 171.278Z"
			fill="#7C839E"
		/>
		<path
			d="M111.072 183.586L111.358 183.872C111.853 184.355 112.643 184.355 113.138 183.872C113.616 183.375 113.616 182.59 113.138 182.093L112.847 181.807C112.529 181.49 112.066 181.366 111.633 181.483C111.199 181.6 110.861 181.939 110.745 182.373C110.63 182.807 110.754 183.269 111.072 183.586V183.586Z"
			fill="#7C839E"
		/>
		<path
			d="M107.06 195.479H107.351C108.015 195.438 108.533 194.888 108.533 194.222C108.533 193.557 108.015 193.006 107.351 192.965H107.06C106.396 193.006 105.878 193.557 105.878 194.222C105.878 194.888 106.396 195.438 107.06 195.479V195.479Z"
			fill="#7C839E"
		/>
		<path
			d="M114.736 208.913V209.777C114.707 210.245 114.94 210.689 115.341 210.932C115.742 211.175 116.244 211.175 116.645 210.932C117.046 210.689 117.279 210.245 117.25 209.777V208.913C117.209 208.249 116.659 207.732 115.993 207.732C115.328 207.732 114.777 208.249 114.736 208.913V208.913Z"
			fill="#7C839E"
		/>
		<path
			d="M106.54 232.872C106.659 232.434 106.602 231.966 106.383 231.569C106.155 231.187 105.795 230.902 105.371 230.768C104.703 230.588 104.013 230.979 103.826 231.646C103.661 232.313 104.046 232.993 104.703 233.196C104.272 233.077 104.013 232.639 104.117 232.204C103.932 232.873 104.325 233.565 104.994 233.749C105.664 233.934 106.356 233.541 106.54 232.872V232.872Z"
			fill="#7C839E"
		/>
		<path
			d="M83.4358 220.258C85.053 220.258 85.0578 217.744 83.4358 217.744C81.8137 217.744 81.8137 220.258 83.4358 220.258Z"
			fill="#7C839E"
		/>
		<path
			d="M222.286 213.603C219.188 205.202 222.523 195.787 230.219 191.21C238.449 186.363 249.225 190.632 250.48 200.508C250.685 202.091 253.199 202.115 252.999 200.508C251.663 190.046 240.982 183.653 231.164 187.923C221.079 192.307 216.17 203.976 219.857 214.271C220.401 215.783 222.834 215.13 222.286 213.603Z"
			fill="#7C839E"
		/>
		<path d="M61.3 91.5078H65.9848V96.1925H61.3V91.5078Z" fill="#D1D4F2" />
		<path d="M216.398 91.0545H221.083V95.7393H216.398V91.0545Z" fill="#D1D4F2" />
		<path
			d="M170.128 0.870206C152.825 -0.818604 133.842 -1.03805 119.106 9.59095C107.575 17.911 100.791 32.1752 106.416 46.0244C109.111 52.6699 114.302 58.0941 121.291 60.1169L120.537 58.2707C117.723 63.9058 114.009 69.0444 109.541 73.4843C109.196 73.8496 109.099 74.383 109.291 74.8466C109.484 75.3101 109.931 75.6173 110.433 75.6311C115.132 75.5261 118.586 72.5874 122.159 69.8776C126.113 66.8637 130.784 64.9333 135.712 64.2769C145.602 62.8457 155.711 64.6919 165.539 62.5976C182.393 59.0101 195.412 42.3415 191.949 24.9524C190.093 15.6306 183.877 7.20086 175.08 3.39388C173.61 2.75461 172.327 4.92049 173.811 5.56452C181.444 8.86581 186.921 15.6926 189.077 23.693C191.281 31.8603 189.516 40.3569 184.635 47.2171C179.646 54.1411 172.163 58.8569 163.764 60.3697C154.7 62.0156 145.402 60.5415 136.28 61.6626C131.681 62.2255 127.154 63.4897 123.232 66.0182C119.192 68.6277 115.599 72.9833 110.438 73.0978L111.325 75.2446C115.95 70.6496 119.796 65.3323 122.712 59.5015C122.903 59.1628 122.928 58.756 122.783 58.396C122.637 58.0359 122.336 57.7616 121.963 57.6505C109.674 54.1011 104.279 39.1499 108.129 27.6717C112.842 13.5888 127.397 5.70287 141.246 3.40342C150.788 1.81479 160.563 2.41113 170.137 3.34617C171.745 3.5036 171.736 0.960848 170.137 0.83204L170.128 0.870206Z"
			fill="#7C839E"
		/>
		<path
			d="M139.333 34.2504C141.814 35.7914 142.616 38.7873 145.588 39.7128C146.14 39.8625 146.725 39.6267 147.019 39.1356C150.333 33.5539 153.766 28.0502 157.319 22.6244C158.206 21.2647 156.031 20.0005 155.148 21.3506C151.592 26.786 148.159 32.2913 144.848 37.8666L146.28 37.2846C143.856 36.5308 142.773 33.4156 140.626 32.0798C139.252 31.2211 137.988 33.3965 139.357 34.2504H139.333Z"
			fill="white"
		/>
	</svg>
);
