import { Box, CircularProgress, Portal } from '@mui/material';
import Draggable from 'react-draggable';
import React, { useRef, type FC, memo, useState, useEffect } from 'react';
import { Resizable } from 'react-resizable';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';

import { usePlugins, useSnackbar } from '../../hooks';
import { FlashSnackbar } from '../snackbars';
import {
	StyledDialogContent,
	StyledWrapperDiv,
} from '../conversation-dialog/conversation-dialog.styles';
import { SidebarLayoutContent } from '../sidebar-layout';
import { ErrorBoundary } from '../error-boundary';
import { ROUTES } from '../../util';
import {
	DIALOG_HEIGHT,
	DIALOG_WIDTH,
	ORDER_DIALOG_HEIGHT_v2,
} from './order-dialog.styles';
import { CreateOrderFormV2 } from './create-order-form-v2';

import type { OrderDialogActionEnum, OrderDialogPayloadInterface } from '../../providers';
import type { ResizeCallbackData } from 'react-resizable';
import type { PropsWithChildren } from 'react';

const LoaderWrapper: React.FC<PropsWithChildren<{ isLoading: boolean }>> = ({
	children,
	isLoading,
}) => {
	if (isLoading)
		return (
			<Box display="flex" justifyContent="center" alignItems="center" height="300px">
				<CircularProgress variant="indeterminate" />
			</Box>
		);

	return (
		<Box height="100%" overflow="hidden">
			{children}
		</Box>
	);
};

interface OrderDialogPropsInterface {
	action: OrderDialogActionEnum;
	id: string;
	idx: number;
	isFocused: boolean;
	onClick: (id: string) => void;
	onClose: (id: string) => void;
	payload: OrderDialogPayloadInterface;
	zIndex: number;
}

export const OrderDialog: FC<OrderDialogPropsInterface> = memo(
	({ id, idx, onClick, payload, zIndex, onClose }) => {
		const nodeRef = useRef<HTMLDivElement>(null);

		const { workspaceId = '' } = useParams();
		const { FrontendV2Plugin } = usePlugins(workspaceId);
		const [height, setHeight] = useState(
			FrontendV2Plugin ? ORDER_DIALOG_HEIGHT_v2 : DIALOG_HEIGHT,
		);
		const [width, setWidth] = useState(DIALOG_WIDTH);

		const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

		const pageWidth = window.innerWidth;
		const elementId = `popup-heading-${id}`;

		const xyAxisIndentPx = (idx + 1) * 16;

		const initialX = pageWidth - DIALOG_WIDTH;
		const preparedX = initialX - xyAxisIndentPx;

		const initialY = FrontendV2Plugin ? -ORDER_DIALOG_HEIGHT_v2 : -DIALOG_HEIGHT;

		const preparedY = initialY - xyAxisIndentPx;

		const onMouseDown = () => onClick(id);

		const onResize = (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
			if (nodeRef.current) {
				const windowRef = nodeRef.current.getBoundingClientRect();
				const maxWidth = window.innerWidth - windowRef.left;
				const maxHeight = window.innerHeight - windowRef.top;

				size.height <= maxHeight && setHeight(size.height);
				size.width <= maxWidth && setWidth(size.width);
			}
		};

		const navigate = useNavigate();

		const onSuccess = (id: string) => {
			onClose(id);
			navigate(generatePath(ROUTES.DASHBOARD.OVERVIEW, { workspaceId }));
		};

		// close dialog when navigating away
		const location = useLocation();
		const { pathname } = location;
		const prevPathnameRef = useRef(pathname);

		useEffect(() => {
			if (prevPathnameRef.current !== pathname) {
				onClose(id);
			}
			prevPathnameRef.current = pathname;
		}, [id, pathname, onClose]);

		return (
			<Portal>
				<Draggable
					nodeRef={nodeRef}
					bounds="parent"
					defaultPosition={{ x: preparedX, y: preparedY }}
					onMouseDown={onMouseDown}
					handle={`#${elementId}`}
				>
					<Resizable
						height={height}
						width={width}
						onResize={onResize}
						minConstraints={[400, 500]}
						maxConstraints={[1500, 1500]}
					>
						<StyledWrapperDiv ref={nodeRef} style={{ zIndex, height, width }}>
							<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
							<LoaderWrapper isLoading={false}>
								<StyledDialogContent>
									<SidebarLayoutContent>
										<ErrorBoundary>
											<Box sx={{ height: '100%' }}>
												<CreateOrderFormV2
													onSuccess={() => onSuccess(id)}
													onCancel={() => onClose(id)}
													order={payload?.order}
												/>
											</Box>
										</ErrorBoundary>
									</SidebarLayoutContent>
								</StyledDialogContent>
							</LoaderWrapper>
						</StyledWrapperDiv>
					</Resizable>
				</Draggable>
			</Portal>
		);
	},
);
