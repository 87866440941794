export type Environment = {
	production: boolean;

	API_URL: string;
	GPLACES_KEY: string;
	GOOGLE_MAPS_STATIC_API_KEY: string;

	PUSHER_KEY: string;
	PUSHER_CLUSTER: string;
	PUSHER_AUTH_ENDPOINT: string;

	PUSHER_BEAMS_AUTH_ENDPOINT: string;
	PUSHER_BEAMS_ID: string;

	MUI_PRO: string;
	APP_URL: string;
};
