import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const EventPayloadEnum = mkenum({
	ORDER_STATUS_CHANGED: 'ORDER_STATUS_CHANGED',
	POD_PENDING: 'POD_PENDING',
	POD_COMPLETED: 'POD_COMPLETED',
	POD_MESSAGE: 'POD_MESSAGE',
	CONTACT_ORDER_ASSIGNMENT_CHANGED: 'CONTACT_ORDER_ASSIGNMENT_CHANGED',
	CONTACT_UNSUBSCRIBES_FROM_VIBER: 'CONTACT_UNSUBSCRIBES_FROM_VIBER',
	TIMEMATTERS_ORDER_STATUS_CHANGED: 'TIMEMATTERS_ORDER_STATUS_CHANGED',
});

export type EventPayloadType = TypeFromPEnum<typeof EventPayloadEnum>;
