// A string array of ISO 639-1 codes that represent the supported languages of DEEPL
export const deeplSupportedLanguages = [
	'en',
	'de',
	'pl',
	'cs',
	'ru',
	'ro',
	'hu',
	'sl',
	'sk',
	'lv',
	'lt',
	'tr',
	'bg',
	'es',
	'pt-pt',
	'fi',
	'it',
	'et',
	'fr',
	'uk',
	// 'bs',
	// 'sr',
	// 'hr',
	// 'mk',
] as const;

export type DeeplSupportedLanguagesType = typeof deeplSupportedLanguages[number];
