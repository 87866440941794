import styled, { css } from 'styled-components';

import { STYLE_4, SIZE_SM, colors } from '../../../../styles';

import type { PickStyleProps } from '../../../../types';
import type { TabNavigationItemProps } from './tab-navigation-item';

type StyledTabNavigationItemProps = PickStyleProps<TabNavigationItemProps, 'active'>;

export const StyledTabNavigationItem = styled.li<StyledTabNavigationItemProps>`
	a,
	div {
		padding: 1rem 1.12rem;
		display: flex;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		cursor: pointer;

		${STYLE_4};
		${SIZE_SM};
		color: ${({ theme }) => theme.palette.grey[500]};

		&:hover {
			color: ${colors.primary.light};
		}

		${({ $active }) =>
			$active &&
			css`
				color: ${({ theme }) => theme.palette.primary.main};
				border-bottom: 3px solid ${({ theme }) => theme.palette.primary.main};
			`}
	}
`;
