import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { languages } from '@heylog-app/shared/util';
import {
	de,
	en,
	ru,
	pl,
	cs,
	ro,
	hu,
	sk,
	sl,
	lv,
	lt,
	tr,
	bg,
	es,
	pt,
	fi,
	it,
	hr,
	uk,
	et,
	fr,
	mk,
	sr,
	bs,
} from '@heylog-app/shared/translations';

type I18nRetVal = ReturnType<typeof i18n.init>;

export const initI18n = (): I18nRetVal => {
	return i18n.use(initReactI18next).init({
		resources: {
			de,
			en,
			ru,
			pl,
			cs,
			ro,
			hu,
			sk,
			sl,
			lv,
			lt,
			tr,
			bg,
			es,
			'pt-pt': pt,
			fi,
			it,
			hr,
			uk,
			et,
			fr,
			mk,
			sr,
			bs,
		},
		fallbackLng: 'de',
		lowerCaseLng: true,
		supportedLngs: languages.map((l) => l.value),
	});
};
