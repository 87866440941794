import styled from 'styled-components';

import { SIZE_MD, STYLE_1 } from '@heylog-app/frontend-lib/app';

export const StyledTextWrapper = styled.div`
	${STYLE_1};
	${SIZE_MD};
	line-height: 1.4rem;
	margin-bottom: 2rem;
`;

export const StyledTextContainer = styled.div`
	margin-top: 2rem;
`;
