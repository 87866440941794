import { StyledDualTextWrapper } from './dual-text.styles';

import type { FC } from 'react';

interface DualTextProps {
	title: string;
	text?: string | null;
}

export const DualText: FC<DualTextProps> = ({ title, text }) => (
	<StyledDualTextWrapper>
		<div>{title}</div>
		<div>{text || 'N/A'}</div>
	</StyledDualTextWrapper>
);
