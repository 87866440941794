import styled from 'styled-components';

import { SIZE_MD, SIZE_SM, STYLE_1 } from '../../styles';

export const StyledDetailItem = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};

	margin-bottom: 2rem;
	width: 30%;
`;

export const StyledDetailItemLabel = styled.div`
	color: ${({ theme }) => theme.palette.greyblue[112]};

	${STYLE_1};
	${SIZE_SM};

	margin-bottom: 0.5rem;
`;

export const StyledDetailItemValue = styled.div`
	${STYLE_1};
	${SIZE_MD};

	min-height: 1.5em;
	margin-bottom: 0.5rem;
`;
