import { Button, Dialog, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import i18next from 'i18next';
import { useParams } from 'react-router-dom';

import { ModalClose } from '../ui';
import {
	StyledDialogContent,
	StyledDialogHeader,
	StyledDialogSubtitle,
	StyledDialogTitle,
} from './invite-guest-user-dialog.styles';
import { FlashSnackbar } from '../snackbars';
import { useApiClientContext, useSnackbar } from '../../hooks';
import { inviteGuestAPI } from '../../util';
import { emailValidationRegEx } from '../../util/email-validation-regex';

import type { FC } from 'react';

type InviteGuestDialogProps = {
	onClose: () => void;
	handleInviteSuccess: () => void;
	isOpen: boolean;
	workspaceId?: string;
	conversationId?: string;
};

type FormData = {
	email: string;
};

export const InviteGuestUserDialog: FC<InviteGuestDialogProps> = ({
	onClose,
	isOpen,
	handleInviteSuccess,
	workspaceId,
	conversationId,
}) => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const params = useParams();
	const _workspaceId = workspaceId || params['workspaceId'] || '';
	const _conversationId = conversationId || params['conversationId'] || '';

	const currentLanguage = i18next.language;

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		register,
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			email: '',
		},
	});

	const onSubmitInviteUserForm = (data: FormData) => {
		inviteGuestAPI(apiClient, {
			data: {
				email: data.email?.toLowerCase(),
				language: currentLanguage,
			},
			workspaceId: _workspaceId,
			conversationId: _conversationId,
		})
			.then(() => {
				handleInviteSuccess();
				openSnackbar('success', t('contacts.toolSidebar.inviteGuestSuccessSnackbar'));
				reset({ email: '' });
				onClose();
			})
			.catch((e) => {
				console.error(e);
				openSnackbar('error', t('contacts.toolSidebar.inviteGuestErrorSnackbar'));
			});
	};

	const onFocusEmailField = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			if (event.target.value === '') {
				reset({ email: '' });
			}
		},
		[reset],
	);

	return (
		<>
			<Dialog data-test="invite-guest-dialog" open={isOpen} onClose={onClose}>
				<StyledDialogContent>
					<StyledDialogHeader>
						<StyledDialogTitle>
							{t('contacts.toolSidebar.inviteGuestUserDialog.inviteGuestUser')}
						</StyledDialogTitle>
						<ModalClose closeModalFn={onClose} />
					</StyledDialogHeader>
					<StyledDialogSubtitle>
						{t('contacts.toolSidebar.inviteGuestUserDialog.dialogText')}
					</StyledDialogSubtitle>

					<form onSubmit={handleSubmit(onSubmitInviteUserForm)} noValidate>
						<Grid container spacing={1}>
							<Grid item xs={9}>
								<Controller
									control={control}
									name="email"
									render={({ field }) => (
										<TextField
											data-test="invite-guest-with-email-field"
											label={t('contacts.toolSidebar.inviteGuestUserForm.email')}
											fullWidth
											type="email"
											error={!!errors.email}
											helperText={
												errors.email
													? t(`forms.errors.${errors.email?.type ?? 'invalid'}`)
													: null
											}
											placeholder={t('contacts.toolSidebar.inviteGuestUserForm.email')}
											onFocus={onFocusEmailField}
											{...register('email', {
												required: true,
												pattern: {
													value: emailValidationRegEx,
													message: 'Email is invalid',
												},
											})}
											{...field}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<Button
									data-test="submit-guest-invite-button"
									style={{ height: '100%' }}
									fullWidth
									variant="contained"
									type="submit"
								>
									{t('contacts.toolSidebar.inviteGuestUserForm.submit')}
								</Button>
							</Grid>
						</Grid>
					</form>
				</StyledDialogContent>
			</Dialog>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
