import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Stack } from '@mui/material';
import Icon from '@mdi/react';
import { mdiArrowBottomLeft, mdiArrowTopRight } from '@mdi/js';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { colors, typographyV2 } from '../../styles';
import {
	StyledDifferenceValue,
	StyledTitle,
	StyledTotalLabel,
	StyledTotalValue,
} from './line-chart.styles';

import type { Dayjs } from 'dayjs';
import type {
	ChartData,
	ChartOptions,
	ScriptableContext,
	TooltipItem,
	TooltipModel,
} from 'chart.js';
import type { FC } from 'react';

export const getData = (chartData: LineChartDataInterface[]): ChartData<'line'> => ({
	labels: chartData.map(({ label }) => label),
	datasets: [
		{
			fill: 'start',
			label: 'Dataset 1',
			data: chartData.map(({ value }) => value),
			backgroundColor: (context: ScriptableContext<'line'>) => {
				const ctx = context.chart.ctx;
				const gradient = ctx.createLinearGradient(0, 0, 0, ctx.canvas.offsetHeight);
				gradient.addColorStop(0, 'rgba(18,44,197,0.25');
				gradient.addColorStop(0.2, 'rgba(18,44,197,0.1');
				gradient.addColorStop(1, 'rgba(255,255,255,0)');
				return gradient;
			},
			borderColor: colors.primary.main,
			borderWidth: 3,
			pointRadius: 0,
			pointBorderColor: colors.primary.main,
			pointBackgroundColor: colors.primary.main,
			pointHoverRadius: 4,
			pointBorderWidth: 2,
			pointHoverBorderWidth: 2,
			showLine: true,
		},
	],
});

const getOptions = (chartData: LineChartDataInterface[]): ChartOptions<'line'> => ({
	responsive: true,
	maintainAspectRatio: false,
	elements: {
		line: {
			tension: 0.35,
		},
	},
	hover: {
		mode: 'index',
		intersect: false,
	},
	plugins: {
		tooltip: {
			mode: 'index',
			intersect: false,
			backgroundColor: colors.primary.darker,
			displayColors: false,
			callbacks: {
				title(this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>[]) {
					const [item] = tooltipItem;
					if (!item) return '';

					const currentData = chartData[item.dataIndex];

					return dayjs(currentData?.date).format('ddd, DD.MM.YYYY');
				},
				label(this: TooltipModel<'line'>, tooltipItem: TooltipItem<'line'>) {
					return tooltipItem.formattedValue;
				},
			},
		},
		annotation: {
			annotations: {
				line1: {
					type: 'line',
					yMin: chartData[0]?.value,
					yMax: [...chartData].pop()?.value,
					borderColor: colors.primary.light,
					borderWidth: 2,
				},
			},
		},
	},
	scales: {
		x: {
			grid: { display: false },
			ticks: {
				color: colors.grey[600],
				font: {
					family: typographyV2.fontFamily,
				},
			},
		},
		y: {
			ticks: {
				color: colors.grey[600],
				font: {
					family: typographyV2.fontFamily,
				},
				precision: 10,
			},
			border: { color: 'rgba(0, 0, 0, 0)' }, // Set the color of the grid lines to transparent},
		},
	},
});

interface LineChartDataInterface {
	label: number;
	value: number;
	date: Dayjs;
}

interface LineChartInterface {
	title: string;
	total: number;
	renderData: LineChartDataInterface[];
	difference?: {
		isGrowing: boolean;
		value: number | string;
	};
}

export const LineChart: FC<LineChartInterface> = ({
	title,
	total,
	difference,
	renderData,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				border: `1px solid ${colors.border.main}`,
				padding: '20px 16px',
				height: '100%',
				width: '100%',
				borderRadius: '4px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Stack
				direction="row"
				marginBottom={4}
				alignItems="center"
				justifyContent="space-between"
			>
				<StyledTitle>{title}</StyledTitle>

				<Stack direction="row" alignItems="center" gap={2}>
					<StyledTotalLabel>{t('dashboardMain.totalForThePeriod')}:</StyledTotalLabel>
					<StyledTotalValue>{total}</StyledTotalValue>
					{difference && (
						<StyledDifferenceValue isGrowing={difference.isGrowing}>
							{difference.isGrowing ? (
								<Icon path={mdiArrowTopRight} size={1} />
							) : (
								<Icon path={mdiArrowBottomLeft} size={1} />
							)}
							{difference.value}
						</StyledDifferenceValue>
					)}
				</Stack>
			</Stack>
			<Box height="100%">
				<Line options={getOptions(renderData)} data={getData(renderData)} />
			</Box>
		</Box>
	);
};
