import styled from 'styled-components';

import { HeylogLogoBlack } from '@heylog-app/frontend-lib/app';

export const StyledFormWrapper = styled.div`
	padding: 2rem;
	height: 100%;
	position: relative;
`;

export const StyledHeylogLogoBlack = styled(HeylogLogoBlack)`
	position: absolute;
`;

export const StyledFormContainer = styled.div`
	padding: 4rem 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
