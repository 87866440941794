import { createContext, useState } from 'react';

import type { FC, ReactNode } from 'react';
import type { Environment } from '../types';

export const EnvironmentContext = createContext<Environment | null>(null);

export const EnvironmentProvider: FC<{
	children?: ReactNode;
	environment: Environment;
}> = ({ children, environment }) => {
	const [env] = useState(() => environment);

	return (
		<EnvironmentContext.Provider value={env}>{children}</EnvironmentContext.Provider>
	);
};
