import { createContext, useMemo, useState } from 'react';

import { FlashSnackbar } from '../components';
import { useSnackbar } from '../hooks';

import type { UiContextType } from '../types/ui-context.type';
import type { FC, ReactElement } from 'react';

export const UiContext = createContext<UiContextType | Partial<UiContextType>>({});

export const UiContextProvider: FC<{ children?: ReactElement }> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(true);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const value: UiContextType = useMemo<UiContextType>(
		() => ({
			menu: {
				isOpen,
				setIsOpen,
			},
			snackbar: {
				openSnackbar,
			},
		}),
		[isOpen, openSnackbar],
	);

	return (
		<UiContext.Provider value={value}>
			{children}
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</UiContext.Provider>
	);
};
