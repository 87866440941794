import { TextField } from '@mui/material';
import styled from 'styled-components';

import type { InputV2Props } from './inputV2';

export const StyledTextField = styled(TextField)<InputV2Props>`
	input {
		padding: ${({ $padding }) => ($padding ? $padding : '13.5px 14px')};
	}
`;
