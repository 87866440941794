import { StyledTabPanelContent, StyledTabPanelWrapper } from './tab-panel.styles';

import type { FC } from 'react';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

export const TabPanel: FC<TabPanelProps> = ({ children, index, value }) => {
	return (
		<StyledTabPanelWrapper
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
		>
			{value === index && <StyledTabPanelContent>{children}</StyledTabPanelContent>}
		</StyledTabPanelWrapper>
	);
};
