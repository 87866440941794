import { Button, Container, Grid } from '@mui/material';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
	ButtonV2,
	SidebarLayout,
	StyledGridSidebar,
	useDialog,
	usePlugins,
} from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './fleet-page.styles';
import { SendCheckDialog } from './components/send-check-dialog';
import { DataTable } from './components/data-table';

export const FleetPage: FC = () => {
	const { workspaceId = '' } = useParams();
	const { t } = useTranslation();
	const { FrontendV2Plugin } = usePlugins(workspaceId);

	const { showDialog, openDialog, closeDialog } = useDialog();

	const onSendNewCheck = () => {
		openDialog();
	};

	return (
		<>
			<SidebarLayout>
				<StyledBackground>
					<Container
						maxWidth={false}
						sx={{
							paddingTop: '2rem',
							paddingBottom: '4rem',
							display: 'flex',
							flexDirection: 'column',
							overflow: 'scroll',
							height: '100%',
						}}
					>
						<Grid container gap={2} wrap="nowrap" columns={{ xs: 12 }}>
							{!FrontendV2Plugin && (
								<StyledGridSidebar>
									<Button
										onClick={onSendNewCheck}
										sx={{ marginBottom: 2, alignSelf: 'flex-end' }}
										variant="contained"
										fullWidth
										data-test="send-fleet-check-button"
									>
										{t('vchk.sendNewCheck')}
									</Button>
								</StyledGridSidebar>
							)}

							<Grid item xs={FrontendV2Plugin ? 12 : 11}>
								<DataTable
									tableV2Title="Fleet"
									isV2={!!FrontendV2Plugin}
									TableV2Toolbar={
										<ButtonV2
											onClick={onSendNewCheck}
											$variant="light"
											data-test="send-fleet-check-button"
										>
											{t('vchk.sendNewCheck')}
										</ButtonV2>
									}
								/>
							</Grid>
						</Grid>
					</Container>
				</StyledBackground>
			</SidebarLayout>

			<SendCheckDialog showDialog={showDialog} closeDialog={closeDialog} />
		</>
	);
};
