import { MessageTypeEnum } from '@heylog-app/shared/types';

import type { MessageType } from '@heylog-app/shared/types';

// according to:
// https://developers.facebook.com/docs/whatsapp/cloud-api/reference/media#supported-media-types
export const getMediaType = (mimeType: string): MessageType => {
	if (mimeType.includes('image')) {
		return MessageTypeEnum.IMAGE;
	} else if (mimeType.includes('video')) {
		return MessageTypeEnum.VIDEO;
	} else if (mimeType.includes('audio')) {
		return MessageTypeEnum.AUDIO;
	} else if (mimeType.includes('application')) {
		return MessageTypeEnum.DOCUMENT;
	} else return MessageTypeEnum.TEXT;
};

export const getMediaTypeString = (mimeType: MessageType): string => {
	switch (mimeType) {
		case MessageTypeEnum.DOCUMENT:
			return 'Document';
		case MessageTypeEnum.AUDIO:
			return 'Audio';
		case MessageTypeEnum.IMAGE:
			return 'Image';
		case MessageTypeEnum.TEXT:
			return 'Text';
		case MessageTypeEnum.VIDEO:
			return 'Video';

		default:
			return 'Unknown Type';
	}
};
