import { default as Pusher } from 'pusher-js';

import type { Environment, PusherStateChangePayload, UserAuthContext } from '../types';

export const buildPusherInstance = (
	{ PUSHER_KEY, PUSHER_CLUSTER, API_URL, PUSHER_AUTH_ENDPOINT }: Environment,
	{ token }: UserAuthContext,
) =>
	new Pusher(PUSHER_KEY, {
		cluster: PUSHER_CLUSTER,
		forceTLS: false,
		channelAuthorization: {
			endpoint: `${API_URL}/${PUSHER_AUTH_ENDPOINT}`,
			transport: 'ajax',
			headers: {
				...(token && { Authorization: `Bearer ${token}` }),
			},
		},
	});

export const bindToPusherStateChange = (
	client: Pusher,
	cb: (payload: PusherStateChangePayload) => void,
) => {
	client.connection.bind('state_change', cb);
};

export const unbindPusherStateChange = (
	client: Pusher,
	cb: (payload: PusherStateChangePayload) => void,
) => {
	client.connection.unbind('state_change', cb);
};
