import { t } from 'i18next';

import type { UserResInterface, WorkspaceResInterface } from '@heylog-app/shared/types';

const INVITE_MESSAGE_DUMMY_SUFFIX = ' app.heylog.com/...';

export const buildInviteMessage = (
	user: UserResInterface | undefined,
	workspace: WorkspaceResInterface | undefined,
	selectedLanguage: string,
) => {
	return `${t('contacts.inviteContact.message', {
		name: `${user?.firstName} ${user?.lastName}`,
		workspace: workspace?.name,
		lng: selectedLanguage,
	})}${INVITE_MESSAGE_DUMMY_SUFFIX}`;
};

export const cleanInviteMessage = (inviteMessage: string) => {
	return inviteMessage.slice(0, -INVITE_MESSAGE_DUMMY_SUFFIX.length);
};
