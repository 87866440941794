import { StyledFormControlLabel, StyledSwitch } from './switch.styles';

import type { ChangeEvent, FC } from 'react';

interface SwitchPropsInterface {
	label?: string;
	value: boolean;
	onChange: (value: boolean) => void;
}

export const Switch: FC<SwitchPropsInterface> = ({ label, value, onChange }) => {
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.checked);

	if (!label) return <StyledSwitch checked={value} onChange={handleChange} />;

	return (
		<StyledFormControlLabel
			checked={value}
			labelPlacement="start"
			label={label}
			control={<StyledSwitch onChange={handleChange} />}
		/>
	);
};
