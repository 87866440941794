import Icon from '@mdi/react';
import { Button } from '@mui/material';
import styled from 'styled-components';

import { colors, SIZE_XS } from '../../styles';

export const StyledConversation = styled.div`
	height: 100%;

	display: flex;
	justify-content: space-between;
	flex-direction: column;
`;

export const StyledConversationMessages = styled.div`
	display: inline-flex;
	flex-direction: column;
	overflow-y: auto;
	max-height: 100%;
	padding: 1rem;

	.bottom-ref {
		padding-top: 0.5rem;
	}
`;

export const StyledIcon = styled(Icon)`
	margin: 0.1rem 0 0.5rem 0;
`;

export const StyledIconButton = styled.button`
	background-color: transparent;
	border: none;
	cursor: pointer;
`;

export const StyledIconWrapper = styled.div`
	display: flex;
	margin-top: 3px;
`;

export const StyledOarHeader = styled.div`
	display: flex;
	z-index: 10;
	padding: 0.8rem 1rem;
	align-items: center;
	background-color: ${colors.common.white};
	border-bottom: 1px solid ${colors.greyblue[205]};
`;

export const StyledOarButton = styled(Button)`
	svg {
		margin-right: 4px;
	}
`;

export const StyledOarText = styled.div`
	margin: 0 1rem 0 0.5rem;
`;

export const StyledTranslationButton = styled.span`
	display: flex;
	align-items: center;
	height: 1rem;
	${SIZE_XS};
	cursor: pointer;
	color: ${colors.primary.main};
	margin-right: 5px;
`;
