export const ROUTES = {
	HOME_V1: '/workspaces/:workspaceId/contacts',
	HOME: '/workspaces/:workspaceId/dashboard',
	// Naming will be fixed in scope of https://www.notion.so/heylog/rename-dashboard-to-orders-76efd6e8708c429d8d8701e1e9bfa9e0?pvs=4
	DASHBOARD: {
		OVERVIEW: '/workspaces/:workspaceId/dashboard',
		REDIRECT: '/dashboard-redirect',
	},
	DASHBOARD_MAIN: {
		OVERVIEW: '/workspaces/:workspaceId/dashboard-main',
	},
	SHIPMENTS: {
		DETAIL: '/workspaces/:workspaceId/shipments/:shipmentId',
	},
	MESSAGES: {
		OVERVIEW: '/workspaces/:workspaceId/messages',
		NEW_MESSAGE: '/workspaces/:workspaceId/messages/new-message/:orderId?',
		// TODO add ID later to open a specific message
		EMAIL: '/workspaces/:workspaceId/conversations/:conversationId/emails',
	},
	WORKSPACES: {
		WORKSPACE: '/workspaces/:workspaceId',
		NO_WORKSPACE: '/no-workspace',
		DISABLED_WORKSPACE: '/workspaces/:workspaceId/disabled-workspace',
		INVITE: '/workspaces/:workspaceId/invitations/:invitationId',
		EXPIRED_INVITATION: '/expired-invitation',
		NO_CONVERSATION: '/no-conversation',
	},
	CONVERSATIONS: {
		INVITE:
			'/workspaces/:workspaceId/conversations/:conversationId/invitations/:invitationId',
	},
	DOCUMENTS: {
		OVERVIEW: '/workspaces/:workspaceId/documents',
	},
	SUCCESSFUL_REGISTRATION: '/successful-registration',
	LOGIN: {
		USER: '/login',
	},
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/auth/password-reset/:resetToken',
	EXPIRED_RESET_LINK: '/expired-reset-link',
	LOGOUT: '/logout',
	ORDERS: {
		OVERVIEW: '/workspaces/:workspaceId/orders',
		DETAIL: '/workspaces/:workspaceId/orders/:orderId',
		CHATS: '/workspaces/:workspaceId/orders/:orderId/contacts',
		CHAT: '/workspaces/:workspaceId/orders/:orderId/contacts/:contactId/chats/:conversationId',
		NOTES: '/workspaces/:workspaceId/orders/:orderId/notes',
		DOCUMENTS: '/workspaces/:workspaceId/orders/:orderId/documents',
		TIMELINE: '/workspaces/:workspaceId/orders/:orderId/timeline',
	},
	TASK_DELAYED:
		'/task-delayed/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/orders/:orderId/tasks/:taskId',
	TASK_STATUS_UPDATE:
		'/task-status-update/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/orders/:orderId/tasks/:taskId',
	ORDER_STATUS_UPDATE:
		'/order-status-update/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/orders/:orderId',
	ORDER_STATUS_UPDATE_BY_REFERENCE:
		'/order-status-update/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/orders/:orderId/orderReference/:orderReference',
	CONTACTS: {
		OVERVIEW: '/workspaces/:workspaceId/contacts',
		DETAIL: '/workspaces/:workspaceId/contacts/:contactId',
		INFORMATION: '/workspaces/:workspaceId/contacts/:contactId/information',
		CHAT: '/workspaces/:workspaceId/contacts/:contactId/chat/:conversationId',
		MANAGEMENT: '/workspaces/:workspaceId/contacts/:contactId/management/:conversationId',
		ORDERS: '/workspaces/:workspaceId/contacts/:contactId/orders',
	},
	FLEET: {
		OVERVIEW: '/workspaces/:workspaceId/fleet',
		VCHKSTART:
			'/organizations/:organizationId/workspaces/:workspaceId/fleet/vehicle-check-start',
		VCHK: '/organizations/:organizationId/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/fleet/vehicle-check',
		FTRSTART:
			'/organizations/:organizationId/workspaces/:workspaceId/fleet/flat-tire-check-start',
		FTR: '/organizations/:organizationId/workspaces/:workspaceId/transportNumber/:whatsappTransportNumber/fleet/flat-tire-check',
	},
	SIGNUP: {
		PERSONAL_DATA: '/signup',
	},
	JOIN: {
		WORKSPACE: '/workspaces/join/:joinKey',
		ORDER: '/workspaces/join/:joinKey',
		WHATSAPP: '/workspaces/join/whatsapp/:joinKey',
		VIBER: '/workspaces/join/viber/:joinKey',
	},
	SETTINGS: {
		OVERVIEW: '/workspaces/:workspaceId/settings',
		PROFILE: '/workspaces/:workspaceId/settings/profile',
		WORKSPACE: '/workspaces/:workspaceId/settings/workspaces',
		ORDERSTATUS: '/workspaces/:workspaceId/settings/order-status',
		VCHKEMAILLIST: '/workspaces/:workspaceId/settings/vehicle-check-email-list',
		MEMBERS: '/workspaces/:workspaceId/settings/members',
		CUSTOMERS: '/workspaces/:workspaceId/settings/customers',
		APPS: '/workspaces/:workspaceId/settings/apps',
	},
	NOT_FOUND: '/404',
	DEMO: '/workspaces/:workspaceId/demo-demo-demon',
};
