import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { ModalClose } from '../../index';
import {
	StyledDecoration,
	StyledInputDescription,
} from '../forms/add-contact-form/add-contact-form.styles';
import { NotificationPolyfill } from '../../util';

import type { UseDialogReturnType } from '../../types';
import type { FC } from 'react';

export const NotificationDisabledDialog: FC<{
	controls: UseDialogReturnType;
}> = ({ controls }) => {
	const { t } = useTranslation();
	const { showDialog, closeDialog } = controls;

	return (
		<Dialog
			open={showDialog}
			onClose={closeDialog}
			data-test="notification-blocked-dialog"
		>
			<ModalClose closeModalFn={closeDialog} />
			<DialogTitle>{t('browserNotifications.blockedDialog.title')}</DialogTitle>
			<StyledDecoration />
			<DialogContent>
				<StyledInputDescription
					dangerouslySetInnerHTML={{
						__html: NotificationPolyfill.notificationApiExists()
							? t('browserNotifications.blockedDialog.description')
							: t('browserNotifications.blockedDialog.descriptionBrowserIssue'),
					}}
				/>
			</DialogContent>
		</Dialog>
	);
};
