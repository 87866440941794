import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const MessagingTransportTypeEnum = mkenum({
	FACEBOOK_WHATSAPP: 'FACEBOOK_WHATSAPP',
	// FACEBOOK_MESSENGER: 'FACEBOOK_MESSENGER',
	VIBER: 'VIBER',
	// TELEGRAM: 'TELEGRAM',
	// TWILIO_SMS: 'TWILIO_SMS',
});

export type MessagingTransportType = TypeFromPEnum<typeof MessagingTransportTypeEnum>;
