import { Button, DialogContent, TextField } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath, useParams } from 'react-router-dom';

import { ROUTES } from '../../util/routes';
import { useOrderActions } from '../../hooks';
import { preventSubmitOnEnter } from '../../util';
import { StyledDialogActions } from '../dialog';
import { getMiscOrderData } from './get-misc-order-data';

import type { FC } from 'react';
import type {
	CreateOrderReqInterface as CreateOrderV1ReqInterface,
	OrderResInterface,
} from '@heylog-app/shared/types';

type FormData = CreateOrderV1ReqInterface & {
	etaFrom: string;
	etaTo: string;
	totalWeight: string;
	volumeOfOrder: string;
};

type CreateOrderFormV1Props = {
	order?: OrderResInterface; // provide ID if editing an order: ;
	onSuccess: () => void;
	onCancel: () => void;
};

export const CreateOrderFormV1: FC<CreateOrderFormV1Props> = ({
	order,
	onSuccess,
	onCancel,
}) => {
	const { createOrder, updateOrder, isLoading } = useOrderActions(order?.id.toString());
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { workspaceId = '' } = useParams();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm<FormData>({
		defaultValues: {
			refNumber: '',
			customer: '',
			licensePlateTrailer: '',
			licensePlateTruck: '',
			notes: undefined,
			totalWeight: undefined,
			volumeOfOrder: undefined,
		},
	});

	const { totalWeight, volumeOfOrder } = useMemo(() => getMiscOrderData(order), [order]);

	useEffect(() => {
		if (order && !isLoading) {
			reset({
				refNumber: order.refNumber ?? '',
				customer: order.customer ?? '',
				licensePlateTrailer: order.licensePlateTrailer ?? '',
				licensePlateTruck: order.licensePlateTruck ?? '',
				totalWeight: totalWeight || undefined,
				volumeOfOrder: volumeOfOrder || undefined,
				notes: order.notes ?? undefined,
			});
		}
	}, [order, reset, totalWeight, volumeOfOrder, isLoading]);

	const onSubmit = async (formData: FormData) => {
		const { totalWeight, volumeOfOrder, ...data } = formData;
		const miscdata = JSON.stringify({
			totalWeight,
			volumeOfOrder,
		});

		if (order) {
			const res = await updateOrder({
				id: order?.id.toString(),
				data: {
					...data,
					...(miscdata && { miscData: miscdata }),
				},
				workspaceId,
			});

			if (res) {
				onSuccess();
			}
		} else {
			const res = await createOrder({
				data: {
					...data,
					...(miscdata && { miscData: miscdata }),
				},
				workspaceId,
			});

			if (res) {
				onSuccess();
				navigate(generatePath(ROUTES.ORDERS.DETAIL, { orderId: res.id, workspaceId }));
			}
		}
	};

	return (
		<form
			data-test="edit-order-dialog"
			onSubmit={handleSubmit(onSubmit)}
			onKeyDown={preventSubmitOnEnter}
			noValidate
		>
			<DialogContent>
				<Controller
					control={control}
					name="refNumber"
					render={({ field }) => (
						<TextField
							data-test="order-refnumber-input"
							label={t('orders.details.orderRef')}
							fullWidth
							margin="dense"
							error={errors.refNumber ? true : false}
							helperText={errors.refNumber && t('forms.errors.required')}
							{...register('refNumber', { required: true })}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="customer"
					render={({ field }) => (
						<TextField
							data-test="order-customer-input"
							label={t('orders.details.customer')}
							fullWidth
							margin="dense"
							{...register('customer')}
							{...field}
						/>
					)}
				/>
				<Controller
					control={control}
					name="licensePlateTruck"
					render={({ field }) => (
						<TextField
							data-test="order-licensePlateTruck-input"
							label={t('orders.details.licensePlateTruck')}
							fullWidth
							margin="dense"
							{...register('licensePlateTruck')}
							{...field}
						/>
					)}
				/>

				<Controller
					control={control}
					name="licensePlateTrailer"
					render={({ field }) => (
						<TextField
							data-test="order-licensePlateTrailer-input"
							label={t('orders.details.licensePlateTrailer')}
							fullWidth
							margin="dense"
							{...register('licensePlateTrailer')}
							{...field}
						/>
					)}
				/>
				<>
					<Controller
						control={control}
						name="totalWeight"
						render={({ field }) => (
							<TextField
								data-test="order-weight-input"
								label={t('orders.details.totalWeight')}
								fullWidth
								margin="dense"
								{...register('totalWeight')}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						name="volumeOfOrder"
						render={({ field }) => (
							<TextField
								data-test="order-volume-input"
								label={t('orders.details.volumeOfOrder')}
								fullWidth
								margin="dense"
								{...register('volumeOfOrder')}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						name="notes"
						render={({ field }) => (
							<TextField
								data-test="order-notes-input"
								label={t('orders.details.labels')}
								fullWidth
								margin="dense"
								multiline
								{...register('notes')}
								{...field}
							/>
						)}
					/>
				</>
			</DialogContent>

			<StyledDialogActions>
				<Button variant="outlined" type="reset" onClick={onCancel}>
					{t('actionLabels.cancel')}
				</Button>
				<Button data-test="submit-order-button" variant="contained" type="submit">
					{order ? t('actionLabels.save') : t('orders.form.labels.submit')}
				</Button>
			</StyledDialogActions>
		</form>
	);
};
