import { Stack } from '@mui/material';
import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import React, { useContext } from 'react';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';

import {
	ButtonV2,
	colors,
	IconFire,
	OrderStatusMenu,
	ROUTES,
	useOrderActions,
	IconAttention,
	OrderContext,
	OrderDialogActionEnum,
} from '@heylog-app/frontend-lib/app';

import {
	StyledDetailsTitle,
	StyledGoBackLink,
	StyledHeaderCustomerLabelTitle,
	StyledHeaderLabelTitle,
	StyledParagraph,
	StyledSpan,
} from '../shipment-details.styles';

import type { OrderResInterface } from '@heylog-app/shared/types';
import type { FC } from 'react';

interface ShipmentHeaderPropsInterface {
	internalReference: string | undefined;
	priority: boolean | undefined;
	attention: boolean | undefined;
	shipmentId: string;
	order?: OrderResInterface;
}

export const ShipmentHeader: FC<ShipmentHeaderPropsInterface> = ({
	internalReference,
	priority,
	attention,
	shipmentId,
}) => {
	const { order, toggleUrgentOrder, toggleAttentionOrder } = useOrderActions(shipmentId);
	const { t } = useTranslation();
	const params = useParams();

	const handleMarkAsPriority = () => {
		toggleUrgentOrder();
	};

	const handleMarkerAsAttentioned = () => {
		toggleAttentionOrder();
	};

	const { onOpenOrderDialog } = useContext(OrderContext);

	const handleUpdateOrderClick = () => {
		if (onOpenOrderDialog)
			onOpenOrderDialog({
				action: OrderDialogActionEnum.OPEN,
				payload: { order },
			});
	};

	return (
		<Stack gap={1}>
			<StyledGoBackLink to={generatePath(ROUTES.DASHBOARD.OVERVIEW, params)}>
				<Icon path={mdiArrowLeft} size={1} />
				{t('shipment.back')}
			</StyledGoBackLink>
			<Stack gap={2} direction="row" flexWrap="wrap" justifyContent="space-between">
				<Stack gap={2} direction="row" flexWrap="wrap" alignItems="center">
					{internalReference && (
						<StyledDetailsTitle>
							{t('shipment.order', { internalReference })}
						</StyledDetailsTitle>
					)}
					<Stack
						gap={1}
						sx={{
							borderRadius: '4px',
							backgroundColor: colors.grey[200],
							padding: '8px 16px',
						}}
					>
						{`${t('shipment.truck')}: ${order?.licensePlateTruck}`}
					</Stack>
					<Stack
						gap={1}
						sx={{
							borderRadius: '4px',
							backgroundColor: colors.grey[200],
							padding: '8px 16px',
						}}
					>
						{`${t('shipment.trailer')}: ${order?.licensePlateTrailer}`}
					</Stack>
				</Stack>

				<Stack gap={2} direction="row" alignItems="center" justifyContent="flex-end">
					<ButtonV2
						onClick={handleUpdateOrderClick}
						$variant="light"
						startIcon={<Icon path={mdiPencil} size={1} />}
					>
						{t('orders.form.editOrderTitle')}
					</ButtonV2>
					<ButtonV2
						$variant={priority ? 'warning' : 'light'}
						onClick={handleMarkAsPriority}
						startIcon={priority ? <IconFire /> : undefined}
						$typography="T3"
						$padding="8px 18px"
					>
						{t('shipment.urgent')}
					</ButtonV2>
					<ButtonV2
						onClick={handleMarkerAsAttentioned}
						$variant={attention ? 'error' : 'light'}
						startIcon={attention ? <IconAttention /> : undefined}
						$typography="T3"
						$padding="8px 18px"
					>
						{t('shipment.attention')}
					</ButtonV2>
					<OrderStatusMenu fullWidth={false} isV2 orderId={shipmentId} />
				</Stack>
			</Stack>

			<StyledParagraph>
				<Stack
					direction="row"
					alignItems="center"
					spacing={2} // Adjust the spacing between the text and the labels
				>
					{order?.customer && (
						<>
							<StyledHeaderCustomerLabelTitle>
								{t('shipment.customer') + ': '}
							</StyledHeaderCustomerLabelTitle>
							<StyledSpan>{order?.customer}</StyledSpan>
						</>
					)}{' '}
					{order?.refNumber && (
						<>
							<StyledHeaderLabelTitle>
								{t('shipment.orderReference') + ': '}
							</StyledHeaderLabelTitle>
							<StyledSpan>{order?.refNumber}</StyledSpan>
						</>
					)}
					{order?.labels && (
						<>
							<StyledHeaderLabelTitle>
								{t('shipment.labels') + ': '}
							</StyledHeaderLabelTitle>
							<StyledSpan>
								<Stack
									gap={1}
									direction="row"
									flexWrap="wrap"
									alignItems="center"
									justifyContent="space-between"
									sx={{
										maxWidth: '100%',
									}}
								>
									{order?.labels.map((label) => (
										<Stack
											key={label.id}
											sx={{
												borderRadius: '16px',
												backgroundColor: colors.grey[200],
												padding: '6px 10px',
												flex: '0 1 auto',
											}}
										>
											{label.value}
										</Stack>
									))}
								</Stack>
							</StyledSpan>
						</>
					)}
				</Stack>
			</StyledParagraph>
		</Stack>
	);
};
