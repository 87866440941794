import styled, { css } from 'styled-components';

import {
	RESET_LIST,
	SidebarListItem,
	SIZE_MD,
	SIZE_XS,
	STYLE_1,
	STYLE_2,
	STYLE_4,
} from '@heylog-app/frontend-lib/app';

export const StyledOrderNumber = styled.div`
	${STYLE_4};
	${SIZE_MD};
`;

export const StyledCompanyName = styled.div`
	${STYLE_2};
	${SIZE_XS};
	margin-top: 0.25rem;
	color: ${({ theme }) => theme.palette.grey[600]};
`;

export const StyledRoute = styled.ul`
	${RESET_LIST};
	${SIZE_XS};
	${STYLE_1};
	margin-top: 0.15rem;

	display: flex;
	align-items: center;
	gap: 0.25rem;

	color: ${({ theme }) => theme.palette.grey[600]};

	li {
		span {
			margin-right: 0.25rem;

			svg {
				path {
					fill: ${({ theme }) => theme.palette.grey[600]};
				}
			}
		}
	}
`;

export const StyledListItem = styled(SidebarListItem)<{ $borderColor: string }>`
	border-left: 4px solid ${({ $borderColor }) => $borderColor || 'transparent'};
	color: ${({ theme }) => theme.palette.grey[600]};

	${({ active: $active, theme }) =>
		$active &&
		css`
			${StyledRoute} {
				color: ${theme.palette.grey[700]};
			}
		`}
`;
