import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import {
	isWithinHours,
	removeSpecialCharacters,
	ROUTES,
	useAuthContext,
	useLocalStorage,
	useUser,
} from '@heylog-app/frontend-lib/app';
import { timestampIsExpired, userIsInWsOrConv } from '@heylog-app/shared/util';
import { UserRoleEnum } from '@heylog-app/shared/types';

import { SignupInImage } from '../../components/ui/sign-in-image';
import { FormWrapper } from '../../components/form-wrapper';
import {
	StyledButtonContainer,
	StyledGrid,
	StyledLoginButton,
	StyledSignupButton,
	StyledSubtitle,
	StyledMaintitle,
} from './join-workspace.styles';

import type { SignupUserRoleType } from '@heylog-app/shared/types';
import type { FC } from 'react';

interface LocalStorageWorkspaceInvite {
	workspaceId: string;
	invitationId: string;
	role: SignupUserRoleType | null;
}

export const JoinWorkspacePage: FC = () => {
	const { decoded } = useAuthContext();
	const { user } = useUser();
	const { t } = useTranslation();
	const { workspaceId, invitationId } = useParams();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const createdAt = searchParams.get('createdAt');

	const isWithin72H = isWithinHours(new Date(parseInt(createdAt || '')), 72);

	useEffect(() => {
		if (!isWithin72H) navigate(ROUTES.WORKSPACES.EXPIRED_INVITATION);
	}, [isWithin72H, navigate]);

	const workspaceName = removeSpecialCharacters(searchParams.get('workspaceName') || '');
	const role = searchParams.get('role') as SignupUserRoleType;

	const [workspaceUserInvite, setWorkspaceUserInvite] =
		useLocalStorage<LocalStorageWorkspaceInvite>('workspaceUserInvite', {
			workspaceId: '',
			invitationId: '',
			role: null,
		});

	if (
		workspaceId &&
		invitationId &&
		role &&
		(workspaceUserInvite?.invitationId !== invitationId ||
			workspaceUserInvite?.workspaceId !== workspaceId)
	) {
		setWorkspaceUserInvite({ workspaceId, invitationId, role });
	}

	const userWorkspaceIds = user?.workspaces?.map((workspace) => workspace.id);

	// If a logged-in user visits the invitation link
	if (
		decoded &&
		workspaceId !== undefined &&
		invitationId !== undefined &&
		!timestampIsExpired(decoded.exp) &&
		userWorkspaceIds
	) {
		if (userIsInWsOrConv(userWorkspaceIds, workspaceId)) {
			navigate(generatePath(ROUTES.HOME, { workspaceId }));
		} else {
			navigate(ROUTES.DASHBOARD.REDIRECT);
		}
	}

	if (decoded && decoded.role === UserRoleEnum.CONVERSATION_GUEST) {
		navigate(ROUTES.DASHBOARD.REDIRECT);
	}

	const handleSignupRedirect = () => {
		navigate(generatePath(ROUTES.SIGNUP.PERSONAL_DATA));
	};

	const handleLoginRedirect = () => {
		navigate(generatePath(ROUTES.LOGIN.USER));
	};

	return (
		<Grid
			data-test="workspace-user-join-page"
			container
			sx={{ height: '100vh', overflow: 'hidden' }}
		>
			<Grid item xs={6}>
				<FormWrapper>
					<StyledGrid alignItems="center" item>
						<StyledMaintitle>
							{t('signup.user-invitation.title', {
								workspaceName,
							})}
						</StyledMaintitle>

						<StyledSubtitle>
							{t('signup.user-invitation.prompt', {
								workspaceName,
							})}
						</StyledSubtitle>

						<StyledButtonContainer>
							<StyledLoginButton
								data-test="login-button"
								variant="outlined"
								sx={{ mx: 2, px: 5 }}
								onClick={handleLoginRedirect}
							>
								{t('signup.user-invitation.login')}
							</StyledLoginButton>
							<StyledSignupButton
								data-test="signup-button"
								variant="contained"
								sx={{ mx: 2, px: 5 }}
								onClick={handleSignupRedirect}
							>
								{t('signup.user-invitation.signup')}
							</StyledSignupButton>
						</StyledButtonContainer>
					</StyledGrid>
				</FormWrapper>
			</Grid>
			<Grid item xs={6}>
				<SignupInImage />
			</Grid>
		</Grid>
	);
};
