export const enum NotificationPermissions {
	GRANTED = 'granted',
	DENIED = 'denied',
	DEFAULT = 'default',
}

/**
 * A wrapper around global Notification object to avoid issues with unsupported browsers
 *
 * Safari mobile is one of them. This wrapper will fake the result as denied permission in such browsers
 */
export const NotificationPolyfill = (() => {
	const notificationApiExists = () => {
		return typeof Notification !== 'undefined';
	};

	return {
		notificationApiExists,

		requestNotification: () => {
			if (notificationApiExists()) {
				return Notification.requestPermission();
			} else {
				return new Promise((resolve) => {
					resolve(NotificationPermissions.DENIED);
				});
			}
		},
		get permission() {
			if (notificationApiExists()) {
				return Notification.permission;
			} else {
				return NotificationPermissions.DENIED;
			}
		},
	};
})();
