import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Paper,
	Stack,
	Step,
	StepContent,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { mdiTruckDeliveryOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { OrderTaskStatusEnum, OrderTaskTypeEnum } from '@heylog-app/shared/types';

import { getFormattedEta } from '../../util';
import {
	CustomBlock,
	CustomCompanyInfoBlock,
	CustomInfoBlock,
	CustomLabelsBlock,
	CustomReferenceBlock,
	CustomReferenceText,
	CustomStepCircle,
	StatusLabel,
	StyledCompanyName,
	StyledContainer,
	StyledHeader,
	StyledLabel,
	StyledStepWrapper,
	StyledTypeLabel,
	StyledWithoutTasks,
} from './vertical-stepper.styles';
import { IconUntrack } from '../icons/icon-untrack';
import { TaskDetailsDialog } from '../task-details-dialog/task-details-dialog';
import { useDialog } from '../../hooks';

import type {
	OrderTaskResInterface,
	ContactResInterface,
} from '@heylog-app/shared/types';
import type { FC, Dispatch, SetStateAction } from 'react';

export interface VerticalStepperProps {
	completedSteps?: number[];
	contacts?: ContactResInterface[];
	currentStep?: number;
	currentSteps?: number[];
	customer?: string;
	deleteTask: (id?: number) => void;
	haveSwitcher: boolean;
	isLinear?: boolean;
	setStatusChanged?: Dispatch<SetStateAction<{ status: string; taskId: string }>>;
	steps: OrderTaskResInterface[];
	warningSteps?: number[];
}

export const VerticalStepper: FC<VerticalStepperProps> = ({
	completedSteps = [],
	contacts,
	currentStep,
	currentSteps = [],
	customer,
	deleteTask,
	haveSwitcher = true,
	isLinear = false,
	setStatusChanged,
	steps,
	warningSteps = [],
}) => {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(currentStep);
	const [activeSteps, setActiveSteps] = useState(currentSteps);
	const [doneSteps, setDoneSteps] = useState(completedSteps);
	const [warnSteps, setWarnSteps] = useState(warningSteps);
	const [number, setNumber] = useState(0);
	const [dialogData, setDialogData] = useState<OrderTaskResInterface | null>(null);

	const handleNext = () => {
		activeStep && setActiveStep((prevActiveStep) => prevActiveStep && prevActiveStep + 1);
	};

	const handleBack = () => {
		activeStep && setActiveStep((prevActiveStep) => prevActiveStep && prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
		setActiveSteps([]);
		setDoneSteps([]);
		setWarnSteps([]);
	};

	const openDialog = (data: OrderTaskResInterface, number: number) => {
		setDialogData(data);
		setNumber(number);
		openTaskDetailsDialog();
	};

	useEffect(() => {
		setActiveSteps(currentSteps);
		setDoneSteps(completedSteps);
		setWarnSteps(warningSteps);
	}, [currentSteps, completedSteps, warningSteps]);

	const {
		showDialog: showTaskDetailsDialog,
		openDialog: openTaskDetailsDialog,
		closeDialog: closeTaskDetailsDialog,
	} = useDialog();

	return (
		<StyledContainer>
			{steps[0] ? (
				<Stepper
					{...(!isLinear ? { activeStep } : { nonLinear: true })}
					orientation="vertical"
				>
					{steps.map((step, index) => (
						<Step key={index}>
							<StepLabel
								sx={{
									'& .MuiStepLabel-label': {
										color: 'text.primary',
										fontWeight: '500',
									},
								}}
								StepIconComponent={({ active, completed, warning }) => (
									<CustomStepCircle
										completed={isLinear ? doneSteps.includes(index) : completed}
										active={isLinear ? activeSteps.includes(index) : active}
										warning={isLinear ? warnSteps.includes(index) : warning}
									>
										{completed ? (
											<Check style={{ color: '#fff', fontSize: '14px' }} />
										) : (
											<Stack sx={{ color: '#fff', fontSize: '14px' }}>{index + 1}</Stack>
										)}
									</CustomStepCircle>
								)}
							>
								<StyledStepWrapper onClick={() => openDialog(step, index + 1)}>
									<CustomLabelsBlock>
										<StyledHeader>
											{step.type === OrderTaskTypeEnum.LOADING ? (
												<Icon path={mdiTruckDeliveryOutline} size={1} />
											) : (
												<IconUntrack />
											)}
											<StyledTypeLabel>
												{step.type === OrderTaskTypeEnum.LOADING
													? t('orders.tasks.type.loading')
													: t('orders.tasks.type.unloading')}
											</StyledTypeLabel>
										</StyledHeader>
										{step.status === OrderTaskStatusEnum.COMPLETED ? (
											<StatusLabel completed={true} active={false}>
												{t('orders.tasks.status.done')}
											</StatusLabel>
										) : step.status === OrderTaskStatusEnum.IN_PROGRESS ? (
											<StatusLabel completed={false} active={true}>
												{t('orders.tasks.status.in_progress')}
											</StatusLabel>
										) : step.status === OrderTaskStatusEnum.CANCELLED ? (
											<StatusLabel completed={true} active={false} cancelled={true}>
												{t('orders.tasks.status.cancelled')}
											</StatusLabel>
										) : (
											<StatusLabel completed={false} active={false}>
												{t('orders.tasks.status.pending')}
											</StatusLabel>
										)}
									</CustomLabelsBlock>
									<CustomBlock>
										<CustomCompanyInfoBlock>
											<CustomInfoBlock>
												<StyledLabel>{t('orders.tasks.company')}:</StyledLabel>
												<StyledCompanyName>{step.company}</StyledCompanyName>
											</CustomInfoBlock>
											<CustomInfoBlock>
												<StyledLabel>{t('orders.tasks.address')}:</StyledLabel>
												<CustomReferenceText>{step.location}</CustomReferenceText>
											</CustomInfoBlock>
										</CustomCompanyInfoBlock>
									</CustomBlock>
									<CustomBlock>
										<StyledLabel>
											{step.etaFrom
												? getFormattedEta(new Date(step.etaFrom as Date))
												: ''}
											{step.etaTo
												? ' - ' + getFormattedEta(new Date(step.etaTo as Date))
												: ''}
										</StyledLabel>
									</CustomBlock>

									<CustomBlock>
										<CustomReferenceBlock>
											<StyledLabel>{t('orders.tasks.reference')}:</StyledLabel>
											<CustomReferenceText>{step.customerReference}</CustomReferenceText>
										</CustomReferenceBlock>
									</CustomBlock>
								</StyledStepWrapper>
							</StepLabel>
							<StepContent>
								{/*<Typography>{step.street}</Typography>*/}
								{haveSwitcher && (
									<Box sx={{ mb: 2 }}>
										<div>
											<Button
												variant="contained"
												onClick={handleNext}
												sx={{ mt: 1, mr: 1 }}
											>
												{index === steps.length - 1 ? 'Finish' : 'Continue'}
											</Button>
											<Button
												disabled={index === 0}
												onClick={handleBack}
												sx={{ mt: 1, mr: 1 }}
											>
												{t('orders.tasks.back')}
											</Button>
										</div>
									</Box>
								)}
							</StepContent>
						</Step>
					))}
				</Stepper>
			) : (
				<StyledWithoutTasks>{t('exceptions.order.noTasks')}</StyledWithoutTasks>
			)}
			{haveSwitcher && activeStep === steps.length && (
				<Paper square elevation={0} sx={{ p: 3 }}>
					<Typography>All steps completed - you&apos;re finished</Typography>
					<Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
						Reset
					</Button>
				</Paper>
			)}
			{dialogData && (
				<TaskDetailsDialog
					contacts={contacts}
					customer={customer ? customer : ''}
					dataTest="task-details-dialog"
					deleteTask={deleteTask}
					dialogTitle={t('orders.taskDetailsDialog.title', { number })}
					isOpen={showTaskDetailsDialog}
					onClose={closeTaskDetailsDialog}
					orderTask={dialogData}
					setStatusChanged={setStatusChanged}
				/>
			)}{' '}
		</StyledContainer>
	);
};
