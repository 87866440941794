import { useTranslation } from 'react-i18next';

import { IconSelectAContact, OnboardingNotice } from '@heylog-app/frontend-lib/app';

import { StyledWelcomeContactsBlock } from '../../contacts.styles';

import type { FC } from 'react';
import type { UserResInterface } from '@heylog-app/shared/types';

type SelectContactWelcomeProps = {
	user: UserResInterface | undefined;
};

export const SelectContactWelcome: FC<SelectContactWelcomeProps> = ({ user }) => {
	const { t } = useTranslation();

	return (
		<StyledWelcomeContactsBlock>
			<IconSelectAContact />
			<OnboardingNotice
				title={t('onboarding.title', {
					name: `${user?.firstName} ${user?.lastName}`,
				})}
				text={t('onboarding.contacts.pleaseSelect')}
			/>
		</StyledWelcomeContactsBlock>
	);
};
