import styled from 'styled-components';

import { SIZE_LG, SIZE_MD, STYLE_1, STYLE_3 } from '@heylog-app/frontend-lib/app';

export const StyledTitleWrapper = styled.h1`
	${STYLE_3};
	${SIZE_LG};
	line-height: 1.8rem;
	margin-bottom: 1rem;
`;

export const StyledTextWrapper = styled.div`
	${STYLE_1};
	${SIZE_MD};
	line-height: 1.4rem;
	margin-bottom: 2rem;
`;
