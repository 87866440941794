// allowed whatsapp templates
export enum WhatsAppTemplateNames {
	AVAILABILITY_STATUS = 'availability_status_v3',
	FLOW_FINISHED_MESSAGE = 'flow_finished_message',
	I_HAVE_A_QUESTION = 'i_have_a_question',
	LIVE_LOCATION_REQUEST = 'live_location_request',
	NEW_CONVERSATION = 'new_conversation_v3',
	NEW_MESSAGE = 'new_message',
	ORDER_ACCEPTANCE_REQUEST = 'order_acceptance_request_v2',
	ORDER_DETAILS = 'order_details',
	ORDER_STATUS_UPDATE_REQUEST = 'order_status_update_request',
	STATUS_UPDATE = 'status_update',
	UNIVERSAL_CONFIRMATION = 'universal_confirmation',
	UNIVERSAL_NOTIFICATION = 'universal_notification',
	UPDATE_CONFIRMATION_MESSAGE = 'update_confirmation_message',
}
