import { useTranslation } from 'react-i18next';

import { StyledBoxTab, StyledBoxWrapper } from './tabs-group-v2.styles';

interface TabsGroupV2PropsInterface<T> {
	tabs: Array<{
		title: string;
		value: T;
	}>;
	value: T;
	onChange: (value: T) => void;
	$tabPadding?: string;
}

export const TabsGroupV2 = <T,>({
	tabs,
	value,
	$tabPadding,
	onChange,
}: TabsGroupV2PropsInterface<T>) => {
	const { t } = useTranslation();

	const handleChange = (newValue: T) => () => {
		onChange(newValue);
	};

	return (
		<StyledBoxWrapper>
			{tabs.map((tab) => (
				<StyledBoxTab
					key={String(tab.value)}
					$padding={$tabPadding}
					$isActive={value === tab.value}
					onClick={handleChange(tab.value)}
				>
					{t(tab.title)}
				</StyledBoxTab>
			))}
		</StyledBoxWrapper>
	);
};
