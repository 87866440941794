import { useTranslation } from 'react-i18next';

import { NoConversationSvg, OnboardingNotice } from '@heylog-app/frontend-lib/app';

import { StyledWelcomeContactsBlock } from '../../contacts.styles';

import type { FC } from 'react';
import type { UserResInterface } from '@heylog-app/shared/types';

type CreateContactWelcomeProps = {
	user: UserResInterface | undefined;
};

export const NoContactsGuest: FC<CreateContactWelcomeProps> = () => {
	const { t } = useTranslation();

	return (
		<StyledWelcomeContactsBlock data-test="no-conversation-guest-welcome">
			<NoConversationSvg />
			<OnboardingNotice
				title={t('onboarding.contacts.guestWithoutContactsTitle')}
				text={t('onboarding.contacts.guestWithoutContactsDescription')}
			/>
		</StyledWelcomeContactsBlock>
	);
};
