import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	SignupReqInterface,
	I18nExceptionResponseInterface,
} from '@heylog-app/shared/types';

type SignupUserParams = {
	data: SignupReqInterface;
};

export const signupUserAPI = async (
	apiClient: AxiosInstance,
	{ data }: SignupUserParams,
): Promise<AxiosResponse<string, I18nExceptionResponseInterface>> => {
	return apiClient.post('/auth/signup', data);
};
