import styled from 'styled-components';

export const StyledPageContainer = styled.div`
	max-width: 35rem;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	padding: 2rem 1rem;
`;
