import type { FC } from 'react';

export const SvgBg: FC = () => (
	<svg
		width="20"
		height="20"
		xmlns="http://www.w3.org/2000/svg"
		id="flag-icons-bg"
		viewBox="0 0 512 512"
	>
		<g fillRule="evenodd" strokeWidth="1pt">
			<path fill="#d62612" d="M0 341.3h512V512H0z" />
			<path fill="#fff" d="M0 0h512v170.7H0z" />
			<path fill="#00966e" d="M0 170.7h512v170.6H0z" />
		</g>
	</svg>
);
