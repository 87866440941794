import styled from 'styled-components';
import ToggleButton from '@mui/material/ToggleButton';

import type { ToggleButtonProps } from '@mui/material/ToggleButton';
import type { LinkProps } from '@mui/material';

type CustomToggleButtonProps = ToggleButtonProps &
	LinkProps & {
		'data-test'?: string;
		to?: string;
		component?: React.ElementType;
	};

export const StyledToggleButton = styled(ToggleButton)<CustomToggleButtonProps>`
	background-color: none;
	z-index: 1;
	width: 100px;
	height: 40px;

	&.Mui-selected {
		background-color: ${({ theme }) => theme.palette.primary.lighter};
	}
	&.MuiToggleButtonGroup-lastButton {
		border-left: 1px solid silver;
		border-radius: 4px;
	}
`;
