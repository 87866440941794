import { PageFooter, PageHeader } from '../ui';
import { StyledPageContainer } from './contact-page-layout.styles';

import type { FC, ReactNode } from 'react';

type LayoutProps = {
	children?: ReactNode;
};

export const ContactPageLayout: FC<LayoutProps> = ({ children }) => {
	return (
		<StyledPageContainer>
			<PageHeader />
			{children}
			<PageFooter />
		</StyledPageContainer>
	);
};
