import { isAfter, isBefore } from 'date-fns';

export const isWithinHours = (date: string | Date, hours: number) => {
	const then = typeof date === 'string' ? new Date(date) : date;
	const now = new Date();

	const msBetweenDates = Math.abs(then.getTime() - now.getTime());

	const hoursBetweenDates = msBetweenDates / (60 * 60 * 1000);

	return hoursBetweenDates < hours;
};

export const isBetweenDates = (
	date: string | Date,
	start: string | Date,
	end: string | Date,
) => {
	date = new Date(date);
	start = new Date(start);
	end = new Date(end);

	return !isBefore(date, start) && !isAfter(date, end);
};
