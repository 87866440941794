import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { getOrderStatusColor } from '@heylog-app/shared/util';

import {
	StyledCompanyName,
	StyledListItem,
	StyledOrderNumber,
} from './order-list-item.styles';

import type { OrderResInterface } from '@heylog-app/shared/types';
import type { FC } from 'react';
import type { ListItemProps } from '@mui/material';

type OrderListItemProps = Omit<ListItemProps, 'onClick'> & { order: OrderResInterface };

export const OrderListItem: FC<OrderListItemProps> = ({ order, ...props }) => {
	const { id, refNumber, customer, currentStatus } = order;
	const params = useParams();
	const navigate = useNavigate();

	const isActive = params['orderId'] === id?.toString();

	const handleItemClick = useCallback(() => {
		navigate(`${id}`);
	}, [navigate, id]);

	return (
		<StyledListItem
			{...props}
			onClick={handleItemClick}
			active={isActive}
			$borderColor={getOrderStatusColor(currentStatus)}
		>
			<div>
				<StyledOrderNumber>{refNumber}</StyledOrderNumber>
				<StyledCompanyName>{customer}</StyledCompanyName>
			</div>
		</StyledListItem>
	);
};
