import { useAbility } from '@casl/react';
import useSWR from 'swr';

import { Actions } from '@heylog-app/shared/types';

import { getFetcher, getMessagesV2Key } from '../util';
import { AbilityContext } from '../providers';
import { useApiClientContext } from './use-api-client-context.hook';

import type { FullMessageResInterface } from '@heylog-app/shared/types';

export const useMessagesV2 = ({
	conversationV2Id,
	messagesDateFrom,
	messagesToFetch,
	workspaceId,
}: {
	conversationV2Id: string;
	messagesDateFrom?: Date;
	messagesToFetch?: number;
	workspaceId: string;
}) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const messagesV2Key = getMessagesV2Key(
		workspaceId,
		conversationV2Id,
		messagesToFetch,
		messagesDateFrom,
	);

	const { data, error, mutate, isLoading, isValidating } = useSWR<
		FullMessageResInterface[]
	>(
		ability.can(Actions.READ, 'Conversation') && conversationV2Id ? messagesV2Key : null,
		getFetcher(apiClient),
	);

	return {
		messages: data,
		updateMessages: mutate,
		messagesError: error,
		isLoadingMessages: isLoading,
		isValidatingMessages: isValidating,
	};
};
