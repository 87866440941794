import { ConversationTypeEnum } from '@heylog-app/shared/types';

import type {
	ConversationType,
	CreateMessageUserToUserReqInterface,
	FullMessageResInterface,
	I18nExceptionResponseInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance, AxiosResponse } from 'axios';
import type { CreateEntryParams } from '../types';

export const createUserToUserMessageAPI = async (
	apiClient: AxiosInstance,
	type: ConversationType,
	{ data, workspaceId }: CreateEntryParams<CreateMessageUserToUserReqInterface>,
): Promise<AxiosResponse<FullMessageResInterface, I18nExceptionResponseInterface>> => {
	const urlForType =
		type === ConversationTypeEnum.HEYLOG_EXTERNAL ? 'external' : 'internal';
	return apiClient.post(
		'workspaces/' + workspaceId + '/conversations-v2/user-to-user/' + urlForType,
		data,
	);
};
