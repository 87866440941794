import { MenuItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
	FlashSnackbar,
	useSnackbar,
	useUser,
	useWorkspace,
	useFormWrapper,
	ControlledSelect,
	MessageBubble,
	buildOSUMessage,
	buildOSUConfirmationMessage,
	buildOSUFinalMessage,
} from '@heylog-app/frontend-lib/app';
import {
	deeplSupportedLanguages,
	googleSupportedLanguages,
	MessageDirectionEnum,
	MessageStatusEnum,
	MessageTypeEnum,
	MessagingTransportTypeEnum,
	UserOrderStatusEnum,
	TranslationServiceEnum,
} from '@heylog-app/shared/types';
import { OrderStatusIndicator } from '@heylog-app/frontend-lib/modules/orders';

import {
	StyledSettingsSection,
	StyledSettingsPageLayout,
	StyledSettingsContentWrapper,
	StyledParagraph,
	StyledDescription,
	StyledList,
	StyledMessageGroup,
} from './settings.styles';
import { SettingsFooter } from './components';

import type { SelectChangeEvent } from '@mui/material';
import type { MessageResInterface } from '@heylog-app/shared/types';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';

type FormData = {
	chatLanguage: string;
};

export const OrderStatusSettingsPage: FC = () => {
	const { t } = useTranslation();
	const { workspaceId } = useParams();
	const { user } = useUser();

	const { workspace } = useWorkspace(workspaceId ? workspaceId : '-1', user);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const defaultLanguageSelected = 'de';
	const [chatLanguage, setChatLanguage] = useState(defaultLanguageSelected);
	const [mockedMessageText, setMockedMessageText] = useState('');
	const [mockedConfirmationMessageText, setMockedConfirmationMessageText] = useState('');
	const [mockedFinishMessageText, setMockedFinishMessageText] = useState('');

	useEffect(() => {
		setMockedMessageText(buildOSUMessage(chatLanguage, 'order123'));
		setMockedConfirmationMessageText(
			buildOSUConfirmationMessage(
				chatLanguage,
				t(`orders.details.status.${UserOrderStatusEnum.ACCEPTED.toLowerCase()}`, {
					lng: chatLanguage,
				}),
				'order123',
			),
		);
		setMockedFinishMessageText(buildOSUFinalMessage(chatLanguage, 'order123'));
	}, [chatLanguage, t]);

	const supportedLanguages =
		workspace?.translationService === TranslationServiceEnum.DEEPL
			? deeplSupportedLanguages
			: googleSupportedLanguages;

	const sortedLanguages = useMemo(() => {
		const supportedLanguagesMap = supportedLanguages.map((languageCode) => {
			return { code: languageCode, name: t(`languages.${languageCode}`) };
		});
		supportedLanguagesMap.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		return supportedLanguagesMap;
	}, [supportedLanguages, t]);

	const {
		control,
		formState: { errors },
	} = useFormWrapper<FormData>({});

	const staticMockedMessage: MessageResInterface = {
		id: 1,
		conversationId: 1,
		contactId: 1,
		direction: MessageDirectionEnum.TO_CONTACT,
		text: 'OSU message preview text',
		remoteId: '',
		reaction: null,
		translatedText: '',

		messageType: MessageTypeEnum.TEXT,

		transportType: MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,

		receivedAt: null,
		seenAt: null,
		createdAt: new Date(),
		updatedAt: new Date(),
		user: null,
		contact: null,

		attachments: null,
		conversationLanguage: 'de',
		status: MessageStatusEnum.DELIVERED,
	};

	const handleLanguageChange = useCallback(
		(event: SelectChangeEvent<unknown>) => setChatLanguage(event.target.value as string),
		[setChatLanguage],
	);

	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<StyledSettingsPageLayout data-test="settings-page-layout">
				<form noValidate>
					<StyledSettingsContentWrapper>
						<StyledSettingsSection width={'50%'}>
							<Typography variant="h6">
								{t('navigation.settingsMenu.orderStatus.title')}
							</Typography>

							<StyledParagraph>
								{t('orderStatusUpdate.availableStatuses')}
							</StyledParagraph>

							<StyledList>
								{Object.values(UserOrderStatusEnum).map((status) => (
									<OrderStatusIndicator status={status} isActive={true} key={status} />
								))}
							</StyledList>

							<StyledDescription>
								{t('orderStatusUpdate.availableMessagePreviews')}
							</StyledDescription>

							<StyledDescription>
								<ControlledSelect
									margin="none"
									data-test="message-language-select"
									control={control as unknown as Control<FieldValues>}
									label={t('contacts.form.labels.chatLanguage')}
									id="chatLanguage"
									rules={{ required: true }}
									selectOptions={{
										error: !!errors.chatLanguage,
										onChange: handleLanguageChange,
										value: chatLanguage,
									}}
									errorMessage={errors.chatLanguage && t('forms.errors.required')}
									defaultValue={defaultLanguageSelected}
								>
									{sortedLanguages?.map((lang) => (
										<MenuItem key={lang.code} value={lang.code}>
											{lang.name}
										</MenuItem>
									))}
								</ControlledSelect>
							</StyledDescription>

							<StyledDescription data-test="message-preview-container">
								<StyledMessageGroup $direction={staticMockedMessage.direction}>
									<MessageBubble
										message={{
											...staticMockedMessage,
											text: mockedMessageText,
										}}
									/>
								</StyledMessageGroup>

								<StyledMessageGroup $direction={staticMockedMessage.direction}>
									<MessageBubble
										message={{
											...staticMockedMessage,
											text: mockedConfirmationMessageText,
										}}
									/>
								</StyledMessageGroup>

								<StyledMessageGroup $direction={staticMockedMessage.direction}>
									<MessageBubble
										message={{
											...staticMockedMessage,
											text: mockedFinishMessageText,
										}}
									/>
								</StyledMessageGroup>
							</StyledDescription>
						</StyledSettingsSection>
					</StyledSettingsContentWrapper>
				</form>

				<SettingsFooter />
			</StyledSettingsPageLayout>
		</>
	);
};
