export const IconChevron = () => (
	<svg
		width="5"
		height="7"
		viewBox="0 0 5 7"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		aria-hidden="true"
	>
		<path
			d="M0.0108643 6.17167L2.68253 3.5L0.0108643 0.8225L0.833364 0L4.33336 3.5L0.833364 7L0.0108643 6.17167Z"
			fill="white"
		/>
	</svg>
);
