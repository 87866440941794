import type { ContactRoleType } from '@heylog-app/shared/types';

type Role = {
	value: ContactRoleType;
};

export const roles: Role[] = [
	{ value: 'NONE' },
	{
		value: 'DRIVER',
	},
	{
		value: 'DISPATCHER',
	},
	{
		value: 'CLIENT',
	},
	{
		value: 'RECIPIENT',
	},
	{
		value: 'OTHER',
	},
];
