import { Box, CardMedia, Stack, Tooltip } from '@mui/material';
import { mdiPaperclipOff, mdiWindowClose } from '@mdi/js';
import Icon from '@mdi/react';

import { MessageTypeEnum } from '@heylog-app/shared/types';
import { getSizeString } from '@heylog-app/shared/util';

import { colors } from '../../../styles/theme/colors';
import { StyledSubtitle, StyledFileCard, StyledFileName } from './file-card.styles';
import { FileIcons } from '../file-icons';

import type { MessageType, Nullable } from '@heylog-app/shared/types';
import type { FC } from 'react';

type FileCardProps = {
	fileName: string;
	fileType: string;
	mediaType: MessageType;
	src: string;
	createdAt?: Date;
	hasOrder?: boolean;
	height?: number;
	width?: number;
	size?: Nullable<number>;
	onDelete?: () => void;
};

export const FileCard: FC<FileCardProps> = ({
	fileType,
	src,
	fileName,
	mediaType,
	createdAt,
	hasOrder,
	width = 160,
	height = 140,
	size,
	onDelete,
}) => {
	return (
		<Box maxWidth={width} position="relative">
			{onDelete && (
				<Box
					onClick={onDelete}
					sx={{
						backgroundColor: colors.grey[250],
						minWidth: '24px',
						width: '24px',
						height: '24px',
						minHeight: '24px',
						borderRadius: '50%',
						alignItems: 'center',
						justifyContent: 'center',
						display: 'flex',
						position: 'absolute',
						top: '-10px',
						right: '-12px',
						zIndex: 1,
						cursor: 'pointer',
					}}
				>
					<Icon path={mdiWindowClose} color={colors.grey[600]} size={0.7} />
				</Box>
			)}
			<StyledFileCard>
				{mediaType === MessageTypeEnum.IMAGE ? (
					<CardMedia sx={{ height, width }} image={src} />
				) : (
					<Stack
						sx={{
							backgroundColor: colors.grey[200],
							border: `1px solid ${colors.grey[300]}`,
							height,
							width,
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<Box sx={{ transform: 'scale(2.3)', color: colors.primary.light }}>
							<FileIcons type={fileType} mediaType={mediaType} />
						</Box>
					</Stack>
				)}
				{hasOrder === false && (
					<Stack alignItems="center" position="absolute" bottom={6} right={4}>
						<Tooltip placement="bottom" title="Document is not attached to any order">
							<Icon path={mdiPaperclipOff} size={1} color={colors.grey[600]} />
						</Tooltip>
					</Stack>
				)}
			</StyledFileCard>

			<Box marginTop="12px">
				<Stack direction="column">
					<StyledFileName>{fileName}</StyledFileName>
					{createdAt && (
						<StyledSubtitle> {new Date(createdAt).toLocaleString()} </StyledSubtitle>
					)}
					{size && <StyledSubtitle> {getSizeString(size)} </StyledSubtitle>}
				</Stack>
			</Box>
		</Box>
	);
};
