import styled from 'styled-components';

import { T3, T4, colors } from '../../../styles';

export const StyledDualTextWrapper = styled.div`
	div:first-of-type {
		${T4};
		color: ${colors.grey[600]};
	}

	div:last-of-type {
		${T3};
		color: ${colors.grey[900]};
	}
`;
