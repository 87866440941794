import { NotificationPermissions, NotificationPolyfill } from './notification-polyfill';

const notificationOverrideVarKey = 'browserNotificationsEnabled';

export function getNotificationOverrideStatus(): boolean {
	return (
		localStorage.getItem(notificationOverrideVarKey) === 'true' ||
		localStorage.getItem(notificationOverrideVarKey) === null
	);
}

export function setNotificationOverrideStatus(status: boolean): void {
	localStorage.setItem(notificationOverrideVarKey, status ? 'true' : 'false');
}

export function getBrowserNotificationStatus(): boolean {
	return (
		NotificationPolyfill.permission === NotificationPermissions.GRANTED &&
		getNotificationOverrideStatus()
	);
}
