export const IconFacebookMessenger = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M16 2.66667C8.48894 2.66667 2.66667 8.16853 2.66667 15.6C2.66667 19.4871 4.25974 22.8461 6.85408 25.1661C7.07187 25.3611 7.20334 25.6341 7.21227 25.9264L7.28488 28.2981C7.30808 29.0547 8.08953 29.5469 8.78179 29.2413L11.4283 28.0731C11.6526 27.9741 11.904 27.9557 12.1404 28.0208C13.3565 28.3552 14.6509 28.5333 16 28.5333C23.5111 28.5333 29.3333 23.0315 29.3333 15.6C29.3333 8.16853 23.5111 2.66667 16 2.66667Z"
			fill="url(#paint0_radial_1974_4543)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.99322 19.3821L11.9099 13.1682C12.5329 12.1797 13.867 11.9335 14.8018 12.6346L17.917 14.9709C18.2028 15.1853 18.596 15.1842 18.8806 14.9682L23.0878 11.7753C23.6493 11.3491 24.3823 12.0211 24.0065 12.6175L20.0898 18.8314C19.4668 19.8199 18.1326 20.0661 17.1979 19.365L14.0827 17.0286C13.7969 16.8142 13.4036 16.8154 13.119 17.0314L8.91189 20.2243C8.35037 20.6504 7.61736 19.9784 7.99322 19.3821Z"
			fill="white"
		/>
		<defs>
			<radialGradient
				id="paint0_radial_1974_4543"
				cx="0"
				cy="0"
				r="1"
				gradientUnits="userSpaceOnUse"
				gradientTransform="translate(7.79932 29.1907) scale(29.0559 29.0558)"
			>
				<stop stopColor="#0099FF" />
				<stop offset="0.609754" stopColor="#A033FF" />
				<stop offset="0.934823" stopColor="#FF5280" />
				<stop offset="1" stopColor="#FF7061" />
			</radialGradient>
		</defs>
	</svg>
);
