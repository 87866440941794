import { Grid } from '@mui/material';
import styled from 'styled-components';

export const NAVIGATION_V2_HEIGHT = '69px';

export const StyledNavigation = styled(Grid)`
	min-height: ${NAVIGATION_V2_HEIGHT};
	height: ${NAVIGATION_V2_HEIGHT};
	padding-right: 24px;
	padding-left: 16px;
	flex-wrap: nowrap;
	justify-content: space-between;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.primary.darkV2};
`;

export const StyledLogoWrapper = styled.div`
	display: flex;
	height: 100%;
	line-height: 0;
	padding-right: 24px;
	justify-content: center;
	align-items: center;
`;

export const StyledToolbar = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
`;

export const StyledMenuButton = styled.button`
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 0;
	background-color: rgba(255, 255, 255, 0.1);
	margin-right: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
