import {
	StyledOnboardingNotice,
	StyledText,
	StyledTitle,
} from './onboarding-notice.styles';

import type { FC, ReactNode } from 'react';

type OnboardingNoticeProps = {
	children?: ReactNode;
	title?: string;
	text: string;
};

export const OnboardingNotice: FC<OnboardingNoticeProps> = ({
	title,
	text,
	children,
}) => {
	return (
		<StyledOnboardingNotice>
			{title && <StyledTitle dangerouslySetInnerHTML={{ __html: title }} />}
			<StyledText>{text}</StyledText>
			<div>{children}</div>
		</StyledOnboardingNotice>
	);
};
