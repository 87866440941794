import type { FC } from 'react';

export const IconRo: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M64 11.35C59.098 9.208 53.692 8 48 8C42.308 8 36.902 9.208 32 11.35V84.648C36.902 86.792 42.308 88 48 88C53.692 88 59.098 86.792 64 84.65V11.35Z"
			fill="#FFCA28"
		/>
		<path
			d="M88 48C88 31.602 78.122 17.526 64 11.35V84.648C78.122 78.474 88 64.398 88 48Z"
			fill="#D50000"
		/>
		<path
			d="M8 48C8 64.398 17.878 78.474 32 84.65V11.35C17.878 17.526 8 31.602 8 48Z"
			fill="#1A237E"
		/>
	</svg>
);
