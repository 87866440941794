import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { NotificationBadge } from '../../notification-badge';
import { StyledTabNavigationItem } from './tab-navigation-item.styles';

export type TabNavigationItemProps<T = undefined> =
	| {
			label: string;
			notifications?: number;
			active: boolean;
			url: string;
			dataTest?: string;
			value?: undefined;
			onClick?: undefined;
	  }
	| {
			label: string;
			notifications?: number;
			active: boolean;
			url?: undefined;
			value: T;
			onClick: (value: T) => void;
			dataTest?: string;
	  };

export const TabNavigationItem = <T,>({
	label,
	notifications,
	active,
	url,
	dataTest,
	value,
	onClick,
}: TabNavigationItemProps<T>) => {
	const { t } = useTranslation();

	const handleClick = (value: T) => () => onClick && onClick(value);

	return (
		<StyledTabNavigationItem data-test={dataTest} $active={active}>
			{url && (
				<Link to={url}>
					{t(label)}
					{notifications && <NotificationBadge count={notifications} />}
				</Link>
			)}
			{value && (
				<div onClick={handleClick(value)}>
					{t(label)}
					{notifications && <NotificationBadge count={notifications} />}
				</div>
			)}
		</StyledTabNavigationItem>
	);
};
