import { useGridApiRef } from '@mui/x-data-grid-pro';

import {
	DocumentsDataTable,
	DocumentsDataTableViewTypes,
} from '@heylog-app/frontend-lib/modules/documents';
import {
	useAttachmentsData,
	useTableColumnsSettings,
} from '@heylog-app/frontend-lib/app';

import type { FC } from 'react';

export const ShipmentDocuments: FC = () => {
	const apiRef = useGridApiRef();
	const { columns, rows } = useAttachmentsData();
	const { adjustedColumns, handleColumnResize, handleColumnOrderChange } =
		useTableColumnsSettings({
			columns,
			apiRef,
			localStorageName: 'shipment-documents-data-table',
		});

	return (
		<DocumentsDataTable
			viewType={DocumentsDataTableViewTypes.GRID}
			columns={adjustedColumns}
			rows={rows}
			handleColumnResize={handleColumnResize}
			handleColumnOrderChange={handleColumnOrderChange}
			apiRef={apiRef}
		/>
	);
};
