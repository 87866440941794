import { useGridApiRef } from '@mui/x-data-grid-pro';

import { MessagesDataGrid } from '@heylog-app/frontend-lib/modules/messages';
import { useMessagesData, useTableColumnsSettings } from '@heylog-app/frontend-lib/app';
import { ConversationTypeEnum } from '@heylog-app/shared/types';

import type { FC } from 'react';

export const ShipmentMessages: FC = () => {
	const apiRef = useGridApiRef();

	const { columns, rows } = useMessagesData([
		ConversationTypeEnum.EMAIL,
		ConversationTypeEnum.WHATSAPP,
		ConversationTypeEnum.VIBER,
	]);

	const { adjustedColumns, handleColumnResize, handleColumnOrderChange } =
		useTableColumnsSettings({
			columns,
			apiRef,
			localStorageName: 'shipment-messages-data-table',
		});

	return (
		<MessagesDataGrid
			apiRef={apiRef}
			handleColumnOrderChange={handleColumnOrderChange}
			handleColumnResize={handleColumnResize}
			rows={rows}
			columns={adjustedColumns}
		/>
	);
};
