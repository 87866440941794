export const IconDots = () => (
	<svg
		width="4"
		height="14"
		viewBox="0 0 4 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M1.99992 10.3333C2.44195 10.3333 2.86587 10.5089 3.17843 10.8215C3.49099 11.1341 3.66659 11.558 3.66659 12C3.66659 12.442 3.49099 12.866 3.17843 13.1785C2.86587 13.4911 2.44195 13.6667 1.99992 13.6667C1.55789 13.6667 1.13397 13.4911 0.821408 13.1785C0.508847 12.866 0.333252 12.442 0.333252 12C0.333252 11.558 0.508847 11.1341 0.821408 10.8215C1.13397 10.5089 1.55789 10.3333 1.99992 10.3333ZM1.99992 5.33334C2.44195 5.33334 2.86587 5.50894 3.17843 5.8215C3.49099 6.13406 3.66659 6.55798 3.66659 7.00001C3.66659 7.44204 3.49099 7.86596 3.17843 8.17852C2.86587 8.49108 2.44195 8.66668 1.99992 8.66668C1.55789 8.66668 1.13397 8.49108 0.821408 8.17852C0.508847 7.86596 0.333252 7.44204 0.333252 7.00001C0.333252 6.55798 0.508847 6.13406 0.821408 5.8215C1.13397 5.50894 1.55789 5.33334 1.99992 5.33334ZM1.99992 0.333344C2.44195 0.333344 2.86587 0.508938 3.17843 0.821499C3.49099 1.13406 3.66659 1.55798 3.66659 2.00001C3.66659 2.44204 3.49099 2.86596 3.17843 3.17852C2.86587 3.49108 2.44195 3.66668 1.99992 3.66668C1.55789 3.66668 1.13397 3.49108 0.821408 3.17852C0.508847 2.86596 0.333252 2.44204 0.333252 2.00001C0.333252 1.55798 0.508847 1.13406 0.821408 0.821499C1.13397 0.508938 1.55789 0.333344 1.99992 0.333344Z"
			fill={'black'}
		/>
	</svg>
);
