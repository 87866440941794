export const IconFolders = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<path
				d="M3.308 20.885c-.499 0-.925-.177-1.278-.53a1.741 1.741 0 0 1-.53-1.278V7.788c0-.212.072-.39.215-.534a.726.726 0 0 1 .535-.216c.213 0 .391.072.535.216A.726.726 0 0 1 3 7.788v11.289a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087H18.75c.213 0 .391.071.535.215a.726.726 0 0 1 .215.535.726.726 0 0 1-.215.534.726.726 0 0 1-.535.216H3.308zm3.5-3.5c-.499 0-.925-.177-1.278-.53A1.741 1.741 0 0 1 5 15.577V4.807c0-.498.177-.924.53-1.277.353-.353.779-.53 1.278-.53h4.232c.241 0 .473.047.696.14.222.094.415.223.58.387L13.787 5h6.904c.499 0 .925.177 1.278.53.353.353.53.779.53 1.278v8.769c0 .499-.177.925-.53 1.278-.353.353-.779.53-1.278.53H6.808zm0-1.5h13.884a.3.3 0 0 0 .221-.087.3.3 0 0 0 .087-.221v-8.77a.3.3 0 0 0-.087-.22.3.3 0 0 0-.22-.087h-7.518l-2-2H6.808a.3.3 0 0 0-.221.087.3.3 0 0 0-.087.22v10.77a.3.3 0 0 0 .087.221.3.3 0 0 0 .22.087zm-.27 0V4.5v11.385zm6.558-3.423-1.208-1.5a.442.442 0 0 0-.356-.172.433.433 0 0 0-.357.181l-1.377 1.806c-.12.154-.14.313-.058.477a.424.424 0 0 0 .41.246h7.508a.424.424 0 0 0 .41-.246.427.427 0 0 0-.049-.477L15.835 9.89a.438.438 0 0 0-.362-.18.438.438 0 0 0-.361.18l-2.016 2.572z"
				fill="#7C839E"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);
