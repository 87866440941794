import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';

import { Conversation } from '@heylog-app/frontend-lib/app';
import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { useContactContext } from './contacts';

import type { FC } from 'react';

export const ContactConversationPage: FC = () => {
	const { conversationId = '' } = useParams();

	const { order, contact, contactFilter } = useContactContext();

	const contactOrderContext = useMemo(() => {
		if (
			!!contact &&
			!!order &&
			order.currentStatus !== UserOrderStatusEnum.DELIVERED &&
			order.currentStatus !== UserOrderStatusEnum.ARCHIVED
		) {
			return { order, contact };
		}
		return undefined;
	}, [contact, order]);

	if (!conversationId) return null;

	return (
		<Box sx={{ height: '100%', overflow: 'hidden' }}>
			<Conversation
				contactOrderContext={contactOrderContext}
				contactFilter={contactFilter}
			/>
		</Box>
	);
};
