import { getOrderConnectionsKey, getOrderDisconnectionsKey } from './get-order-key';

import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	CreateOrderConnectionReqInterface,
	I18nExceptionResponseInterface,
	OrderConnectionResInterface,
} from '@heylog-app/shared/types';
import type { CreateEntryParams } from '../types';

export const createOrderConnectionApi = (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateOrderConnectionReqInterface>,
): Promise<
	AxiosResponse<OrderConnectionResInterface, I18nExceptionResponseInterface>
> => {
	const key = getOrderConnectionsKey(workspaceId, data.orderId.toString());
	return apiClient.post(key, data);
};

export const createOrderDisconnectionApi = (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<CreateOrderConnectionReqInterface>,
): Promise<
	AxiosResponse<OrderConnectionResInterface, I18nExceptionResponseInterface>
> => {
	const key = getOrderDisconnectionsKey(
		workspaceId,
		data.orderId.toString(),
		data.contactId.toString(),
	);
	return apiClient.delete(key);
};
