import { MenuItem, Switch, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import {
	parseAxiosErrorMessage,
	FlashSnackbar,
	useSnackbar,
	useUser,
	useWorkspace,
	useFormWrapper,
	ControlledSelect,
	ButtonV2,
} from '@heylog-app/frontend-lib/app';
import {
	deeplSupportedLanguages,
	googleSupportedLanguages,
	TranslationServiceEnum,
	WorkspaceinvitationUserRoleEnum,
} from '@heylog-app/shared/types';

import {
	StyledSettingsSection,
	StyledSettingsPageLayout,
	StyledSettingsContentWrapper,
	StyledSwitchWrapper,
	StyledTextField,
	StyledParagraph,
	StyledDescription,
	StyledTyphographyHeader,
	StyledMembersSection,
	StyledWorkspaceSettingsContentWrapper,
	StyledButtonBlock,
} from './settings.styles';
import { StyledMessengerButtons } from './components/messenger-button/messenger-button.styles';
import { MessengerButton, Prompt, SettingsFooter } from './components';
import { MembersSettingsPage } from './members-settings';

import type { TranslationServiceType } from '@heylog-app/shared/types';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';

type FormData = {
	name: string;
	showUserSignature: boolean;
	translationService: TranslationServiceType;
};

export const WorkspaceSettingsPage: FC = () => {
	const { t } = useTranslation();
	const { workspaceId } = useParams();
	const { user } = useUser();

	const { workspace, updateWorkspace } = useWorkspace(
		workspaceId ? workspaceId : '-1',
		user,
	);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const [translationService, setTranslationService] = useState<TranslationServiceType>(
		workspace?.translationService || TranslationServiceEnum.DEEPL,
	);

	const [saveActive, setSaveActive] = useState(false);

	const {
		setValue,
		control,
		handleSubmit,
		register,
		reset,
		watch,
		formState: { errors, isDirty },
	} = useFormWrapper<FormData>({
		defaultValues: {
			name: workspace?.name || '',
			showUserSignature: workspace?.showUserSignature || false,
			translationService: translationService,
		},
	});

	useEffect(() => {
		if (workspace?.translationService) {
			setTranslationService(workspace.translationService as TranslationServiceType);
			setValue('translationService', workspace.translationService);
		}
	}, [workspace, setValue]);

	useEffect(() => {
		if (workspace) {
			setValue('showUserSignature', workspace.showUserSignature);
			setValue('name', workspace.name);
		}
	}, [workspaceId, setValue, workspace]);

	const onSubmit = async (data: FormData) => {
		try {
			await updateWorkspace(data).finally(() => reset(data));
			openSnackbar('success', t('settings.settingsSavedSuccessfully'));
		} catch (error) {
			openSnackbar(
				'error',
				parseAxiosErrorMessage(error, t('settings.form.errors.unknown')),
			);
		}
	};

	useEffect(() => {
		setSaveActive(isDirty);
	}, [isDirty]);

	return (
		<>
			<Prompt isDirty={isDirty} />

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<StyledSettingsPageLayout>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<StyledSettingsContentWrapper>
						<StyledWorkspaceSettingsContentWrapper>
							<StyledSettingsSection width={'48.5%'}>
								<StyledTyphographyHeader>
									<Typography variant="h6">
										{t('navigation.settingsMenu.profile.details')}
									</Typography>
								</StyledTyphographyHeader>

								<Controller
									control={control}
									name="name"
									render={({ field }) => (
										<StyledTextField
											{...register('name', {
												validate: (value) =>
													(value || '').length === 0
														? t('settings.form.errors.workspaceNameEmpty')
														: (value || '').length > 40
														? t('settings.form.errors.workspaceNameTooBig', { limit: 40 })
														: true,
											})}
											error={!!errors.name}
											helperText={errors.name && errors.name.message}
											label={t('settings.labels.teamName')}
											data-test="settings-admin-workspace-name"
											data-test-error={!!errors.name}
											{...field}
										/>
									)}
								/>

								<StyledButtonBlock>
									{/*<ButtonV2*/}
									{/*		$variant="light"*/}
									{/*		size="medium"*/}
									{/*		onClick={() => {}}*/}
									{/*		type="button"*/}
									{/*		data-test="settings-user-cancel-button"*/}
									{/*		disabled={!saveActive}*/}
									{/*>*/}
									{/*		{t('settings.labels.cancel')}*/}
									{/*</ButtonV2>*/}
									<ButtonV2
										$variant="dark"
										size="medium"
										type="submit"
										data-test="settings-user-save-button"
										disabled={!saveActive}
									>
										{t('settings.labels.saveChanges')}
									</ButtonV2>
								</StyledButtonBlock>
							</StyledSettingsSection>

							<StyledSettingsSection width={'48.5%'}>
								<StyledTyphographyHeader>
									<Typography variant="h6">
										{t('navigation.settingsMenu.workspace.communicationChannels')}
									</Typography>
								</StyledTyphographyHeader>

								<StyledMessengerButtons>
									<MessengerButton type="FACEBOOK_WHATSAPP" active />
									{/* <MessengerButton type="FACEBOOK_MESSENGER" disabled /> */}
									<MessengerButton type="VIBER" active />
									{/* <MessengerButton type="TELEGRAM" disabled /> */}
								</StyledMessengerButtons>
							</StyledSettingsSection>

							<StyledSettingsSection width={'48.5%'}>
								<Typography variant="h6">
									{t('navigation.settingsMenu.profile.signature')}
								</Typography>

								<StyledSwitchWrapper>
									<label>{t('settings.labels.signatureSwitchLabel')}</label>
									<Controller
										name="showUserSignature"
										control={control}
										render={({ field }) => (
											<Switch
												onChange={(e) => field.onChange(e.target.checked)}
												checked={field.value}
												data-test="settings-admin-show-signature"
											/>
										)}
									/>
								</StyledSwitchWrapper>
							</StyledSettingsSection>

							<StyledSettingsSection width={'48.5%'}>
								<StyledTyphographyHeader>
									<Typography variant="h6">
										{t('navigation.settingsMenu.workspace.translationEngine')}
									</Typography>
								</StyledTyphographyHeader>

								<StyledParagraph>
									{t('settings.labels.translationEngineLabel')}
								</StyledParagraph>

								<ControlledSelect
									control={control as unknown as Control<FieldValues>}
									margin="normal"
									label={t('navigation.settingsMenu.workspace.translationEngine')}
									id="translationService"
									defaultValue={translationService}
									data-test="settings-workspace-translation-service"
								>
									{Object.keys(TranslationServiceEnum).map((service, index) => {
										const supportedLanguages =
											service === 'DEEPL'
												? deeplSupportedLanguages
												: googleSupportedLanguages;

										const availableLanguages = Object.keys(supportedLanguages).length;

										return (
											<MenuItem key={index} value={service}>
												{t(`settings.labels.${service}`, {
													availableLanguages: availableLanguages,
												})}
											</MenuItem>
										);
									})}
								</ControlledSelect>

								<StyledDescription>
									{watch('translationService') === TranslationServiceEnum.DEEPL && (
										<p>{t('settings.labels.deeplTranslationDescription')}</p>
									)}
									{watch('translationService') === TranslationServiceEnum.GOOGLE && (
										<p>{t('settings.labels.googleTranslationDescription')}</p>
									)}
								</StyledDescription>
							</StyledSettingsSection>
						</StyledWorkspaceSettingsContentWrapper>
						<StyledWorkspaceSettingsContentWrapper width={'33%'}>
							<StyledMembersSection>
								<MembersSettingsPage
									role={WorkspaceinvitationUserRoleEnum.WORKSPACE_ADMIN}
								/>
							</StyledMembersSection>
						</StyledWorkspaceSettingsContentWrapper>
					</StyledSettingsContentWrapper>
				</form>

				<SettingsFooter />
			</StyledSettingsPageLayout>
		</>
	);
};
