import styled from 'styled-components';

import { SIZE_SM_PLUS, STYLE_1, STYLE_3 } from '../../../styles';

type StyledValueProps = {
	$value?: string | null;
};

export const StyledDetailItem = styled.div`
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
`;

export const StyledLabel = styled.span`
	display: block;
	${STYLE_1};
	${SIZE_SM_PLUS};
	color: ${({ theme }) => theme.palette.greyblue[112]};
	margin-bottom: 4px;
`;

export const StyledValue = styled.span<StyledValueProps>`
	display: block;
	${STYLE_3};
	${SIZE_SM_PLUS};
	color: ${({ theme, $value }) =>
		$value === '-' ? theme.palette.grey[400] : theme.palette.grey[900]};
`;
