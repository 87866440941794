import styled from 'styled-components';

import { SIZE_LG, STYLE_3, colors } from '../../../styles';

export const StyledSidebarHeader = styled.div`
	padding: 1.33rem 1rem;
	display: flex;
	justify-content: space-between;
`;

export const StyledSidebarTitle = styled.h2`
	${STYLE_3};
	${SIZE_LG};
	color: ${colors.grey[900]};
	margin: 0;
`;
