import type { AxiosInstance } from 'axios';
import type { CreateLabelReqInterface } from '@heylog-app/shared/types';

export const createLabelAPI = async (
	apiClient: AxiosInstance,
	data: CreateLabelReqInterface,
	workspaceId: number,
) => {
	return apiClient.post(`/workspaces/${workspaceId}/labels`, data);
};
