import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colors } from '@heylog-app/frontend-lib/app';

export const StyledPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	margin: 32px auto 0 auto;
	max-width: 720px;
`;

export const StyledPageContent = styled.div`
	margin: 72px auto 24px auto;
	padding: 56px;
	background-color: ${colors.greyblue[245]};
	border-radius: 2px;
	text-align: center;
`;

export const StyledIconWrapper = styled.div`
	svg {
		width: 100%;
		height: auto;
	}
`;

export const StyledTitle = styled.h1`
	margin: 16px auto 0 auto;
	max-width: 320px;
	font-size: 32px;
	line-height: 40px;
	font-weight: 800;
	color: ${colors.common.black};
`;

export const StyledText = styled.p`
	margin: 24px auto 0 auto;
	max-width: 505px;
	line-height: 24px;
	font-size: 15px;
`;

export const StyledTextNavLink = styled(NavLink)`
	padding: 0;
	font-size: 14px;
	text-decoration: none;
	color: ${colors.primary.main};
	cursor: pointer;

	&:hover {
		color: ${colors.primary.lighter};
	}
`;
