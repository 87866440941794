import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const TranslationServiceEnum = mkenum({
	DEEPL: 'DEEPL',
	GOOGLE: 'GOOGLE',
});

export type TranslationServiceType = TypeFromPEnum<typeof TranslationServiceEnum>;
