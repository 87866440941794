import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mdiLogout } from '@mdi/js';

import {
	HeylogLogoBlack,
	ROUTES,
	useLocalStorage,
	RegistrationComplete,
	useAuthContext,
} from '@heylog-app/frontend-lib/app';

import {
	StyledButton,
	StyledButtonIcon,
	StyledPageContainer,
	StyledPageContent,
	StyledText,
	StyledTitle,
} from './no-workspace.styles';

import type { FC } from 'react';

export const SuccessfulRegistration: FC = () => {
	const { t } = useTranslation();

	const { decoded } = useAuthContext();

	const navigate = useNavigate();

	const [workspaceUserInvite] = useLocalStorage('workspaceUserInvite', {
		workspaceId: '',
		invitationId: '',
	});

	const [conversationGuestInvite] = useLocalStorage('conversationGuestInvite', {
		workspaceId: '',
		conversationId: '',
		invitationId: '',
	});

	const handleLogout = useCallback(() => {
		navigate(ROUTES.LOGOUT);
	}, [navigate]);

	// If the decoded user has an invitation navigate INVITATION_DASHBOARD to handle it.
	useEffect(() => {
		if (
			(decoded && workspaceUserInvite.workspaceId && workspaceUserInvite.invitationId) ||
			(conversationGuestInvite.conversationId && conversationGuestInvite.invitationId)
		) {
			navigate(ROUTES.DASHBOARD.REDIRECT);
		}
	}, [
		conversationGuestInvite.conversationId,
		conversationGuestInvite.invitationId,
		decoded,
		navigate,
		workspaceUserInvite.invitationId,
		workspaceUserInvite.workspaceId,
	]);

	return (
		<StyledPageContainer data-test="successful-registration-component">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<RegistrationComplete />
				</div>
				<StyledTitle>{t('workspaces.successfulRegistration.title')}</StyledTitle>
				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('workspaces.successfulRegistration.description'),
					}}
				/>
			</StyledPageContent>

			<StyledButton
				variant="outlined"
				color="secondary"
				onClick={handleLogout}
				data-test="logout-button"
			>
				<StyledButtonIcon path={mdiLogout} size={'1rem'} />
				{t('navigation.menu.logout')}
			</StyledButton>
		</StyledPageContainer>
	);
};
