import { mkenum } from './mkenum';

export const MessageTypeEnum = mkenum({
	TEXT: 'TEXT',
	WHATSAPP_TEMPLATE: 'WHATSAPP_TEMPLATE',
	IMAGE: 'IMAGE',
	VIDEO: 'VIDEO',
	AUDIO: 'AUDIO',
	DOCUMENT: 'DOCUMENT',
	BUTTON: 'BUTTON',
	REACTION: 'REACTION',
	LOCATION: 'LOCATION',
});

export type MessageType = typeof MessageTypeEnum[keyof typeof MessageTypeEnum];
