import { Avatar, Box, Card, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useRef, type FC, useEffect, useMemo } from 'react';
import Icon from '@mdi/react';
import { mdiMessageOutline } from '@mdi/js';

import { getNameInitials } from '../../util';
import { ButtonV2, DualText, InputV2 } from '../ui';
import { colors } from '../../styles';
import { StyledEmptyText } from './comments.styles';

type FormData = {
	comment: string;
};

type CommentsProps = {
	type: string;
};

export const Comments: FC<CommentsProps> = ({ type }) => {
	const { handleSubmit, control, register } = useForm<FormData>({
		defaultValues: {
			comment: '',
		},
	});

	const onSubmitCommentForm = (data: FormData) => {
		console.log(data);
	};

	const comments = useMemo(
		() =>
			type === 'internal'
				? [
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{ user: 'user two', comment: 'No it is already completed' },
						{
							user: 'user one',
							comment:
								'How does this line look like when it is toooooooooooo loooooooooooooooooonnnnnnnnnnnnnnggggggggggggggggggg',
						},
				  ]
				: [
						{ user: 'user one', comment: 'Hey this order should be delivered tomorrow' },
						{
							user: 'customer one',
							comment:
								'This is a customer comment! How does this line look like when it is toooooooooooo loooooooooooooooooonnnnnnnnnnnnnnggggggggggggggggggg',
						},
				  ],
		[type],
	);

	// scroll to bottom of comments if its overflowing
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 200);
	}, [comments]);
	// scroll to bottom of comments if its overflowing

	return (
		<Card
			sx={{
				height: '100%',
				boxShadow: 'none',
			}}
		>
			<Stack gap={1} justifyContent="space-between" sx={{ height: '100%' }}>
				{!comments.length ? (
					<Stack height="100%" alignItems="center" justifyContent="center">
						<Icon path={mdiMessageOutline} color={colors.grey[500]} size={2} />
						<StyledEmptyText>No comments yet</StyledEmptyText>
					</Stack>
				) : (
					<Stack overflow="auto" marginTop="auto" padding={2} gap={2}>
						{comments.map((comment, index) => {
							return (
								<Stack
									key={index}
									direction="row"
									alignItems="center"
									gap={1.5}
									sx={{ wordBreak: 'break-all', fontSize: '14px' }}
								>
									<Avatar sx={{ fontSize: '10px', height: '32px', width: '32px' }}>
										{getNameInitials(comment.user)}
									</Avatar>
									<DualText title={comment.user || ''} text={comment.comment} />
								</Stack>
							);
						})}
						<div ref={messagesEndRef} />
					</Stack>
				)}

				<Box position="relative" borderTop={`1px solid ${colors.grey[300]}`}>
					<form onSubmit={handleSubmit(onSubmitCommentForm)} noValidate>
						<Controller
							control={control}
							name="comment"
							render={({ field }) => (
								<InputV2
									id="standard-basic"
									variant="standard"
									placeholder="Your comment here"
									fullWidth
									$padding="14px 100px 14px 14px"
									{...register('comment')}
									{...field}
								/>
							)}
						/>

						<ButtonV2
							type="submit"
							$variant="dark"
							$padding="6px 16px"
							sx={{
								position: 'absolute',
								right: '16px',
								top: '50%',
								transform: 'translateY(-50%)',
							}}
						>
							Send
						</ButtonV2>
					</form>
				</Box>
			</Stack>
		</Card>
	);
};
