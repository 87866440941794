import { Avatar } from '@mui/material';
import styled from 'styled-components';

import Image from './images/avatar-bg@2x.jpg';

export const StyledTransportTypeIcon = styled.div`
	svg {
		width: 1rem;
		height: 1rem;
	}
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
	font-size: 0.8rem;
`;
