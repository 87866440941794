import type { FC } from 'react';

export const HeylogLogoBlack: FC<{ className?: string }> = ({ className }) => (
	<svg
		width="145"
		height="40"
		viewBox="0 0 145 40"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		className={className}
	>
		<path
			d="M42.0556 40V4.84203C42.0556 2.16978 39.8858 0 37.2059 0H4.84964C2.16978 0 0 2.16978 0 4.84203V27.5828C0 30.255 2.16978 32.4248 4.84964 32.4248H30.7194L42.048 39.9848L42.0556 40Z"
			fill="#122CC5"
		/>
		<path
			d="M30.5596 26.1134L25.4206 6.67682H21.9871L22.2992 14.5641H19.665L20.0609 6.67682H16.635L11.4884 26.1134L19.0864 26.182L19.5356 18.7514H22.3982L22.756 26.182L30.5596 26.1134Z"
			fill="white"
		/>
		<path
			d="M36.6807 14.5641V18.7514H32.8207L31.0545 20.9669L29.2501 14.5641H36.6807Z"
			fill="white"
		/>
		<path
			d="M5.36731 14.5641V18.7514H9.22723L10.8869 20.9669L12.7978 14.5641H5.36731Z"
			fill="white"
		/>
		<path
			d="M136.231 22.4743C134.115 22.4743 132.623 21.0735 132.623 19.0179C132.623 16.9623 134.115 15.5615 136.231 15.5615C138.348 15.5615 139.84 16.9623 139.84 19.0179C139.84 21.0735 138.348 22.4743 136.231 22.4743ZM139.84 11.8614V13.3841C138.858 12.1584 137.427 11.4427 135.485 11.4427C131.252 11.4427 128.146 14.8991 128.146 19.0179C128.146 23.1367 131.245 26.5931 135.485 26.5931C137.427 26.5931 138.858 25.8774 139.84 24.6517V26.0221C139.84 28.1386 138.553 29.212 136.467 29.212C134.496 29.212 133.635 28.3746 133.064 27.3316L129.25 29.5394C130.62 31.9833 133.217 33.1481 136.315 33.1481C140.282 33.1481 144.187 31.0316 144.187 26.0221V11.8538H139.832L139.84 11.8614ZM118.348 22.8321C116.407 22.8321 114.914 21.4313 114.914 19.3148C114.914 17.1983 116.407 15.7975 118.348 15.7975C120.289 15.7975 121.774 17.1983 121.774 19.3148C121.774 21.4313 120.282 22.8321 118.348 22.8321ZM118.348 27.1869C122.733 27.1869 126.25 23.7609 126.25 19.3148C126.25 14.8687 122.733 11.4427 118.348 11.4427C113.963 11.4427 110.445 14.8687 110.445 19.3148C110.445 23.7609 113.963 27.1869 118.348 27.1869ZM103.738 26.7682H108.215V4.99429H103.738V26.7606V26.7682ZM97.0308 11.8614L94.2291 20.9897L90.9174 11.8614H85.9992L91.9375 26.083C91.2828 27.9026 90.2931 28.5573 88.2071 28.5573V32.7294C92.3791 32.9349 95.0361 31.0621 96.7034 26.319L101.804 11.8538H97.0308V11.8614ZM74.7392 17.7693C75.1579 16.2467 76.2923 15.4168 77.9901 15.4168C79.33 15.4168 80.6775 16.0411 81.1496 17.7693H74.7392ZM74.8306 21.1115H85.413C85.5348 20.5482 85.5957 19.9467 85.5957 19.3224C85.5957 14.823 82.3753 11.4503 78.0205 11.4503C73.3079 11.4503 70.0875 14.8763 70.0875 19.3224C70.0875 23.7686 73.247 27.1945 78.3479 27.1945C81.18 27.1945 83.3879 26.1515 84.8496 24.1264L81.2714 22.0708C80.6776 22.7255 79.6345 23.2052 78.4088 23.2052C76.772 23.2052 75.394 22.6723 74.8306 21.1192V21.1115ZM63.1214 5.90788V13.9018H56.5588V5.89266H51.7853V26.7682H56.5588V18.4774H63.1214V26.7682H67.8949V5.89266H63.1214V5.90788Z"
			fill="#141b47"
		/>
	</svg>
);
