import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	AuthReqInterface,
	I18nExceptionResponseInterface,
} from '@heylog-app/shared/types';

type LoginUserParams = {
	data: AuthReqInterface;
};

export const loginUserAPI = (
	apiClient: AxiosInstance,
	{ data }: LoginUserParams,
): Promise<AxiosResponse<string, I18nExceptionResponseInterface>> => {
	return apiClient.post('/auth/login', data);
};

export const sendPasswordResetEmailAPI = (
	apiClient: AxiosInstance,
	{
		data,
	}: {
		data: {
			email: string;
			language: string;
		};
	},
): Promise<AxiosResponse<string, I18nExceptionResponseInterface>> => {
	return apiClient.post('/auth/send-password-reset-link', data);
};

export const resetPasswordAPI = (
	apiClient: AxiosInstance,
	{
		data,
		resetToken,
	}: {
		data: {
			password: string;
			confirmPassword: string;
		};
		resetToken: string;
	},
): Promise<AxiosResponse<string, I18nExceptionResponseInterface>> => {
	return apiClient.post(`/auth/password-reset/${resetToken}`, data);
};
