import { FileTypeEnum } from '@heylog-app/shared/types';

import type { FileType } from '@heylog-app/shared/types';

export const getFileTypeString = (fileType: FileType): string => {
	switch (fileType) {
		case FileTypeEnum.DOCX:
			return 'MS Word document';
		case FileTypeEnum.XLSX:
			return 'MS Excel spreadsheet';
		case FileTypeEnum.PPTX:
			return 'MS PowerPoint presentation';
		case FileTypeEnum.PDF:
			return 'PDF';
		case FileTypeEnum.TXT:
			return 'Plain Text';
		case FileTypeEnum.RTF:
			return 'RTF';
		case FileTypeEnum.CSV:
			return 'CSV';
		case FileTypeEnum.XML:
			return 'XML';
		case FileTypeEnum.HTML:
			return 'HTML';
		case FileTypeEnum.JPEG:
		case FileTypeEnum.JPG: // Both JPEG and JPG have the same MIME type
			return 'JPEG Image';
		case FileTypeEnum.PNG:
			return 'PNG Image';
		case FileTypeEnum.GIF:
			return 'GIF Image';
		case FileTypeEnum.BMP:
			return 'BMP Image';
		case FileTypeEnum.TIFF:
			return 'TIFF';
		case FileTypeEnum.SVG:
			return 'SVG';
		case FileTypeEnum.WEBP:
			return 'WEBP';
		default:
			return 'Unknown';
	}
};
