import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const OrderConnectionStatusEnum = mkenum({
	CREATED: 'CREATED',
	REQUESTED: 'REQUESTED',
	ACCEPTED: 'ACCEPTED',
	DECLINED: 'DECLINED',
	ARCHIVED: 'ARCHIVED',
});

export type OrderConnectionStatus = TypeFromPEnum<typeof OrderConnectionStatusEnum>;
