import { mdiAccount, mdiDelete } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	StyledContentWrapper,
	StyledDeleteIcon,
	StyledEmail,
	StyledGuestUser,
	StyledIcon,
} from './guest-invitation.styles';
import {
	StyledExpiredPill,
	StyledInactiveGuestPill,
} from '../messenger-button/messenger-button.styles';
import { isWithinHours } from '../../util';

import type { FC } from 'react';

type GuestInvitationProps = {
	email: string;
	onClick: () => void; // Never passed at the moment because "remove" feature is not implemented
	invitationDate: Date;
};

export const GuestInvitation: FC<GuestInvitationProps> = ({
	email,
	invitationDate,
	onClick,
}) => {
	const { t } = useTranslation();

	const isWithin72H = isWithinHours(invitationDate, 72);

	return (
		<StyledGuestUser onClick={onClick}>
			<StyledContentWrapper>
				<StyledIcon path={mdiAccount} size={'1rem'} />
				<StyledEmail $inactive>{email}</StyledEmail>
				{isWithin72H ? (
					<StyledInactiveGuestPill data-test="pending-guest-icon">
						{t('settings.pending')}
					</StyledInactiveGuestPill>
				) : (
					<StyledExpiredPill data-test="expired-guest-icon">
						{t('settings.expired')}
					</StyledExpiredPill>
				)}
				<StyledDeleteIcon path={mdiDelete} />
			</StyledContentWrapper>
		</StyledGuestUser>
	);
};
