import { mdiLock } from '@mdi/js';
import { InputAdornment, MenuItem, Switch, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Icon from '@mdi/react';

import {
	useSnackbar,
	ControlledSelect,
	MessageAuthor,
	MessageBubble,
	MessageGroup,
	useCurrentLanguage,
	useUser,
	BrowserNotificationsContext,
	parseAxiosErrorMessage,
	FlashSnackbar,
	useFormWrapper,
	ROUTES,
	useWorkspace,
	AlertMessage,
	ButtonV2,
} from '@heylog-app/frontend-lib/app';
import { getFullDateTime, languages, uiLanguages } from '@heylog-app/shared/util';
import {
	MessageDirectionEnum,
	MessageTypeEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import {
	PreviewContainer,
	StyledSwitchWrapper,
	StyledSettingsContentWrapper,
	StyledSettingsSection,
	StyledTextField,
	StyledSettingsPageLayout,
	StyledParagraph,
	StyledParagraphSmall,
	StyledTextNavLink,
	StyledButtonBlock,
	StyledTyphographyHeader,
	StyledMessagesList,
} from './settings.styles';
import { Prompt, SettingsFooter } from './components';

import type { MessageResInterface } from '@heylog-app/shared/types';
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable';
import type { Control, FieldValues } from 'react-hook-form';
import type { FC } from 'react';

//TODO change back to mandatory when user setting change is implemented
type FormData = {
	firstName: string;
	lastName: string;
	email?: string;
	role?: string;
	password?: string;
	newPassword?: string;
	confirmNewPassword?: string;
	uiLanguage: string;
	signature?: Nullable<string>;
};

export const ProfileSettingsPage: FC = () => {
	const { t, i18n } = useTranslation();
	const { workspaceId } = useParams();
	const { user, updateUser } = useUser();
	const { workspace } = useWorkspace(workspaceId ? workspaceId : '-1', user);

	const { currentLanguage, setCurrentLanguage } = useCurrentLanguage();

	const [saveActive, setSaveActive] = useState(false);

	const [passwordPolicyHint, setPasswordPolicyHint] = useState<boolean>(false);

	const {
		control,
		setValue,
		getValues,
		register,
		handleSubmit,
		watch,
		formState: { errors, isDirty },
		reset,
	} = useFormWrapper<FormData>({
		defaultValues: {
			firstName: user?.firstName || '',
			lastName: user?.lastName || '',
			email: user?.email || '',
			signature: user?.signature || '',
		},
	});

	const message: MessageResInterface = {
		id: 1,
		conversationId: 1,
		contactId: 1,
		direction: MessageDirectionEnum.TO_CONTACT,
		remoteId: '',
		reaction: null,
		translatedText: '',

		messageType: MessageTypeEnum.TEXT,

		transportType: MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,

		receivedAt: null,
		seenAt: null,
		createdAt: new Date('22.11.2022'),
		updatedAt: new Date('22.11.2022'),
		user: user || null,
		contact: null,

		attachments: null,
		text: `Example message (${user?.signature || ''})`,
		conversationLanguage: '',
		status: 'PENDING',
	};

	const createdAtDate = getFullDateTime(message.createdAt, currentLanguage);

	useEffect(() => {
		if (user) {
			setValue('firstName', user?.firstName);
			setValue('lastName', user?.lastName);
			setValue('email', user?.email);
			setValue('role', user?.role);
			setValue('signature', user.signature);
			setValue('password', '');
			setValue('newPassword', '');
			setValue('confirmNewPassword', '');
		}
	}, [workspaceId, user, setValue, reset]);

	useEffect(() => {
		i18n.changeLanguage(currentLanguage);
	}, [i18n, currentLanguage]);

	const [browserNotificationsStatus, setBrowserNotificationsStatus] = useContext(
		BrowserNotificationsContext,
	);

	const handleBrowserNotificationSwitch = (
		e: React.ChangeEvent<HTMLInputElement>,
		value: boolean,
	) => {
		setBrowserNotificationsStatus(value);
	};

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const onSubmit = async (data: FormData) => {
		setCurrentLanguage(data.uiLanguage);

		if (data.password?.length === 0 && data.newPassword?.length === 0) {
			delete data.password;
			delete data.newPassword;
		}

		try {
			await updateUser({ ...data, email: data.email?.toLowerCase() }).then(() =>
				reset({ ...data, password: '', newPassword: '', confirmNewPassword: '' }),
			);
			openSnackbar('success', t('settings.settingsSavedSuccessfully'));
		} catch (error) {
			const newPassword = getValues('newPassword');
			if (newPassword) {
				const passwordPolicy =
					/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,128}$/;
				setPasswordPolicyHint(!passwordPolicy.test(newPassword));
			}

			openSnackbar(
				'error',
				parseAxiosErrorMessage(error, t('settings.form.errors.unknown')),
			);
		}
	};

	useEffect(() => {
		setSaveActive(isDirty);
	}, [isDirty]);

	return (
		<>
			<Prompt isDirty={isDirty} />

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<StyledSettingsPageLayout>
				<form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
					<StyledSettingsContentWrapper>
						<StyledSettingsSection>
							<StyledTyphographyHeader>
								<Typography variant="h6">
									{t('navigation.settingsMenu.profile.details')}
								</Typography>
							</StyledTyphographyHeader>

							<Controller
								control={control}
								name="firstName"
								render={({ field }) => (
									<StyledTextField
										label={t('settings.labels.firstName')}
										data-test="settings-user-first-name"
										data-test-error={!!errors.firstName}
										error={!!errors.firstName}
										helperText={errors.firstName && t('forms.errors.required')}
										{...register('firstName', { required: true })}
										{...field}
									/>
								)}
							/>

							<Controller
								control={control}
								name="lastName"
								render={({ field }) => (
									<StyledTextField
										label={t('settings.labels.lastName')}
										data-test="settings-user-last-name"
										data-test-error={!!errors.lastName}
										error={!!errors.lastName}
										helperText={errors.lastName && t('forms.errors.required')}
										{...register('lastName', { required: true })}
										{...field}
									/>
								)}
							/>

							<Controller
								control={control}
								name="email"
								render={({ field }) => (
									<StyledTextField
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<Icon path={mdiLock} size={0.73} />
												</InputAdornment>
											),
										}}
										disabled
										label={t('settings.labels.email')}
										{...field}
									/>
								)}
							/>

							<StyledButtonBlock>
								{/*<ButtonV2*/}
								{/*		$variant="light"*/}
								{/*		size="medium"*/}
								{/*		onClick={() => {}}*/}
								{/*		type="button"*/}
								{/*		data-test="settings-user-cancel-button"*/}
								{/*		disabled={!saveActive}*/}
								{/*>*/}
								{/*		{t('settings.labels.cancel')}*/}
								{/*</ButtonV2>*/}
								<ButtonV2
									$variant="dark"
									size="medium"
									type="submit"
									data-test="settings-user-save-button"
									disabled={!saveActive}
								>
									{t('settings.labels.saveChanges')}
								</ButtonV2>
							</StyledButtonBlock>
						</StyledSettingsSection>

						<StyledSettingsSection>
							<StyledTyphographyHeader>
								<Typography variant="h6">
									{t('navigation.settingsMenu.profile.password')}
								</Typography>
							</StyledTyphographyHeader>

							<StyledTextField
								label={t('settings.labels.currentPassword')}
								type="password"
								autoComplete="new-password"
								data-test="settings-user-password"
								data-test-error={!!errors.password}
								{...register('password', {
									validate: (value) =>
										value?.length === 0 && (watch('newPassword') || '').length > 0
											? 'forms.errors.required'
											: true,
								})}
								error={!!errors.password}
								helperText={
									errors.password && errors.password.message && t(errors.password.message)
								}
							/>

							<StyledTextField
								label={t('settings.labels.newPassword')}
								type="password"
								data-test="settings-user-new-password"
								data-test-error={!!errors.newPassword}
								{...register('newPassword', {
									validate: (value) =>
										(value || '').length > 0 && value === watch('password')
											? 'settings.form.errors.sameNewPassword'
											: (value || '').length === 0 && (watch('password') || '').length > 0
											? 'forms.errors.required'
											: true,
								})}
								error={!!errors.newPassword}
								helperText={
									errors.newPassword &&
									errors.newPassword.message &&
									t(errors.newPassword.message)
								}
							/>

							<StyledTextField
								label={t('settings.labels.confirmNewPassword')}
								type="password"
								data-test="settings-user-confirm-new-password"
								data-test-error={!!errors.confirmNewPassword}
								{...register('confirmNewPassword', {
									validate: (value) =>
										value !== watch('newPassword')
											? 'settings.form.errors.passwordMatch'
											: true,
								})}
								error={!!errors.confirmNewPassword}
								helperText={
									errors.confirmNewPassword &&
									errors.confirmNewPassword.message &&
									t(errors.confirmNewPassword.message)
								}
							/>

							{passwordPolicyHint && (
								<AlertMessage
									title={t('settings.form.errors.nonCompliantPasswordTitle')}
									message={t('settings.form.errors.nonCompliantPassword')}
								/>
							)}
						</StyledSettingsSection>

						<StyledSettingsSection>
							<StyledTyphographyHeader>
								<Typography variant="h6">
									{t('navigation.settingsMenu.profile.signature')}
								</Typography>
							</StyledTyphographyHeader>

							<StyledParagraphSmall data-test="settings-user-signature-hint">
								<Switch
									checked={workspace?.showUserSignature || false}
									disabled
									data-test="settings-user-signature-disabled-toggle"
								/>
								<Trans
									i18nKey="settings.labels.signatureRedirection"
									values={{
										linkText: `${t('navigation.settingsMenu.workspace.team')} > ${t(
											'navigation.settingsMenu.profile.signature',
										)}`,
									}}
									components={[
										<StyledTextNavLink
											to={`${generatePath(ROUTES.SETTINGS.WORKSPACE, { workspaceId })}`}
											data-test="settings-user-signature-link"
										/>,
									]}
									shouldUnescape
								/>
							</StyledParagraphSmall>
							<StyledParagraph
								dangerouslySetInnerHTML={{
									__html: t('settings.labels.signatureDescription'),
								}}
							/>

							<Controller
								control={control}
								name="signature"
								render={({ field }) => (
									<StyledTextField
										disabled
										label={t('navigation.settingsMenu.profile.signature')}
										{...field}
									/>
								)}
							/>

							<PreviewContainer>
								<MessageGroup message={message}>
									<StyledMessagesList>
										<MessageAuthor message={message} createdAtDate={createdAtDate} />
										<MessageBubble message={message} reaction={undefined} />
									</StyledMessagesList>
								</MessageGroup>
							</PreviewContainer>
						</StyledSettingsSection>

						<StyledSettingsSection>
							<StyledTyphographyHeader>
								<Typography variant="h6">
									{t('navigation.settingsMenu.profile.appSettings')}
								</Typography>
							</StyledTyphographyHeader>

							<ControlledSelect
								control={control as unknown as Control<FieldValues>}
								margin="none"
								label={t('settings.labels.language')}
								id="uiLanguage"
								defaultValue={currentLanguage}
							>
								{uiLanguages.map((uiLanguage) => {
									const nativeLanguage = languages.find(
										(item) => item.value === uiLanguage,
									);
									return (
										<MenuItem key={uiLanguage} value={uiLanguage}>
											{nativeLanguage?.label}
										</MenuItem>
									);
								})}
							</ControlledSelect>
							<StyledSwitchWrapper>
								<label>{t('settings.labels.browserNotifications')}</label>
								<Switch
									data-test="browser-notifications-toggle-slider"
									checked={browserNotificationsStatus}
									onChange={handleBrowserNotificationSwitch}
								/>
							</StyledSwitchWrapper>
						</StyledSettingsSection>
					</StyledSettingsContentWrapper>
					<SettingsFooter />
				</form>
			</StyledSettingsPageLayout>
		</>
	);
};
