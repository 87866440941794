import { cloneElement, useMemo } from 'react';
import { IconButton, Menu } from '@mui/material';

import { IconDots } from '../../icons';

import type { MenuProps } from '@mui/material';
import type { UseMenuReturnType } from '../../../types';
import type { FC, ReactNode, ReactElement } from 'react';

type ButtonMenuProps = {
	id: string;
	children?: ReactNode; // menu items
	button?: ReactElement; // button to open menu
	control: UseMenuReturnType;
	disabled?: boolean;
	active?: boolean;
	className?: string;
	dataTest?: string;
	slotProps?: MenuProps['slotProps'];
	renderChildren?: (onClose: () => void) => ReactNode;
	menuOrientation?: 'left' | 'right';
};

export const ButtonMenu: FC<ButtonMenuProps> = ({
	id,
	button,
	children,
	control,
	disabled,
	dataTest,
	className,
	slotProps,
	renderChildren,
	menuOrientation = 'left',
}) => {
	const { toggleButtonRef, isMenuOpen, closeMenu, toggleMenu } = control;

	const buttonProps = useMemo(
		() => ({
			ref: toggleButtonRef,
			id: `control-${id}`,
			'aria-controls': id,
			'aria-haspopup': true,
			'aria-expanded': isMenuOpen,
			onClick: toggleMenu,
			disabled,
		}),
		[toggleMenu, toggleButtonRef, isMenuOpen, id, disabled],
	);

	return (
		<>
			{button ? (
				cloneElement(button, buttonProps)
			) : (
				<IconButton data-test={dataTest} {...buttonProps}>
					<IconDots />
				</IconButton>
			)}

			<Menu
				slotProps={slotProps}
				anchorEl={toggleButtonRef?.current}
				id={id}
				open={isMenuOpen}
				onClose={closeMenu}
				className={className}
				MenuListProps={{
					'aria-labelledby': `control-${id}`,
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: menuOrientation,
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: menuOrientation,
				}}
			>
				{renderChildren ? renderChildren(closeMenu) : children}
			</Menu>
		</>
	);
};
