import styled from 'styled-components';

import { SIZE_LG, STYLE_1, STYLE_3 } from '../../../styles';

export const StyledOnboardingNotice = styled.div`
	max-width: 22rem;
	text-align: center;
`;

export const StyledTitle = styled.h2`
	${STYLE_3};
	${SIZE_LG};
`;

export const StyledText = styled.p`
	${STYLE_1};
	margin-bottom: 1.77rem;
	padding-left: 20px;
`;
