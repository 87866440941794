import styled from 'styled-components';

import { colors, STYLE_1, STYLE_3 } from '../../styles';
import { StyledLayout } from '../layout/layout.styles';
import Image from './images/404.jpg';

export const StyledNoMatchLayout = styled(StyledLayout)`
	display: flex;
	align-items: center;
	justify-content: center;
	background: url(${Image});
	background-size: cover;
`;

export const StyledContent = styled.div`
	max-width: 350px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: ${colors.common.white};
`;

export const StyledH3 = styled.h3`
	${STYLE_3}
`;

export const StyledP = styled.p`
	${STYLE_1}
	margin-bottom: 32px;
`;
