import { mdiEmail, mdiEmailOutline, mdiPlus } from '@mdi/js';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Icon from '@mdi/react';

import {
	FlashSnackbar,
	useSnackbar,
	useUser,
	useWorkspace,
	useApiClientContext,
	useDialog,
	ConfirmArchiveDialog,
	AddVchkEmailDialog,
	useOrganizationIdByWorkspaceId,
	useSettingsByOrganizationId,
	updateOrganizationSettingsEmailsAPI,
	parseAxiosErrorMessage,
} from '@heylog-app/frontend-lib/app';

import {
	StyledSettingsSection,
	StyledHeaderIcon,
	StyledHeaderTitle,
	StyledSettingsPageLayout,
	StyledSettingsContentWrapper,
	StyledHeaderWrapper,
	StyledSettingsHeader,
	StyledSettingsTitle,
	StyledSaveButton,
	StyledMember,
	StyledMemberEmail,
	StyledEmailIcon,
	StyledRemoveButton,
} from './settings.styles';
import { SettingsFooter } from './components';

import type { OrganisationSettingsUpdateType } from '@heylog-app/frontend-lib/app';
import type { FC } from 'react';

export const VehicleCheckEmailListPage: FC = () => {
	const { t } = useTranslation();
	const { workspaceId } = useParams();
	const { user } = useUser();
	const [emailList, setEmailList] = useState<string[]>();
	const { organizationId = -1 } = useOrganizationIdByWorkspaceId(workspaceId || '-1');

	const { apiClient } = useApiClientContext();

	const { data: vchkEmails, mutate } = useSettingsByOrganizationId(organizationId);

	const settings = vchkEmails?.find(
		(setting) => setting.settingKey === 'fleetCheckExport',
	);

	useEffect(() => {
		if (settings) {
			setEmailList(JSON.parse(JSON.parse(settings?.settingValue)).emailAddresses);
		}
	}, [settings]);

	const [emailToRemove, setEmailToRemove] = useState<string | undefined>(undefined);
	const { workspace } = useWorkspace(workspaceId ? workspaceId : '-1', user);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();

	const {
		showDialog: showAddVchkEmailDialog,
		openDialog: openAddVchkEmailDialog,
		closeDialog: closeAddVchkEmailDialog,
	} = useDialog();

	const handleCloseConfirmArchiveDialog = () => {
		closeConfirmArchiveDialog();
		setEmailToRemove(undefined);
	};

	const onEmailRemoveClick = (email: string) => {
		openConfirmArchiveDialog();
		setEmailToRemove(email);
	};

	const handleEmailRemove = async () => {
		if (!emailToRemove) return;

		const filteredEmailList = emailList?.filter((email) => email !== emailToRemove);

		const data: OrganisationSettingsUpdateType = [
			{
				settingKey: 'fleetCheckExport',
				settingValue: {
					emailAddresses: filteredEmailList || [],
				},
			},
		];

		await updateOrganizationSettingsEmailsAPI(apiClient, organizationId, data)
			.then(() => {
				openSnackbar('success', t('settings.settingsSavedSuccessfully'));
				mutate();
			})
			.catch((err) => {
				openSnackbar('error', parseAxiosErrorMessage(err));

				throw new Error(err);
			});

		closeConfirmArchiveDialog();
	};

	const handleAddEmailClick = () => {
		openAddVchkEmailDialog();
	};

	const handleAddEmail = async (email: string) => {
		const data: OrganisationSettingsUpdateType = [
			{
				settingKey: 'fleetCheckExport',
				settingValue: {
					emailAddresses: [...(emailList || []), email],
				},
			},
		];
		await updateOrganizationSettingsEmailsAPI(apiClient, organizationId, data)
			.then(() => {
				openSnackbar('success', t('settings.settingsSavedSuccessfully'));
				mutate();
			})
			.catch((err) => {
				openSnackbar('error', parseAxiosErrorMessage(err));
				throw new Error(err);
			});
		closeAddVchkEmailDialog();
	};

	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<StyledSettingsPageLayout>
				<StyledHeaderWrapper>
					<StyledHeaderTitle>
						<StyledSettingsHeader>
							<StyledSettingsTitle>
								<StyledHeaderIcon path={mdiEmail} size={'2.2em'} />
								<Typography variant="h5">
									{t('navigation.settingsMenu.vchk.title')}
								</Typography>
							</StyledSettingsTitle>

							<StyledSaveButton
								onClick={handleAddEmailClick}
								size="medium"
								variant="contained"
								startIcon={<Icon path={mdiPlus} size={1} />}
							>
								{t('navigation.settingsMenu.vchk.addEmail')}
							</StyledSaveButton>
						</StyledSettingsHeader>
					</StyledHeaderTitle>
				</StyledHeaderWrapper>

				<StyledSettingsContentWrapper>
					<StyledSettingsSection>
						{emailList?.map((vchkEmail: string, index: number) => (
							<StyledMember key={index}>
								<StyledMemberEmail>
									<StyledEmailIcon path={mdiEmailOutline} size={'1em'} />
									{vchkEmail}
								</StyledMemberEmail>

								<StyledRemoveButton
									variant="outlined"
									onClick={() => onEmailRemoveClick(vchkEmail)}
								>
									{t('actionLabels.remove')}
								</StyledRemoveButton>
							</StyledMember>
						))}
						{(!emailList?.length || emailList.length === 0) && (
							<Typography>{t('navigation.settingsMenu.vchk.emptyEmailList')}</Typography>
						)}
					</StyledSettingsSection>
				</StyledSettingsContentWrapper>

				<SettingsFooter />
			</StyledSettingsPageLayout>

			<ConfirmArchiveDialog
				isOpen={showConfirmArchiveDialog}
				onClose={handleCloseConfirmArchiveDialog}
				dialogTitle={t('navigation.settingsMenu.vchk.removeEmailDialog.title')}
				dialogContent={t('navigation.settingsMenu.vchk.removeEmailDialog.body', {
					email: emailToRemove,
					workspaceName: workspace?.name,
				})}
				onArchive={handleEmailRemove}
			/>

			<AddVchkEmailDialog
				isOpen={showAddVchkEmailDialog}
				onClose={closeAddVchkEmailDialog}
				handleAddEmail={handleAddEmail}
			/>
		</>
	);
};
