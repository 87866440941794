import styled from 'styled-components';

import { SIZE_SM, SIZE_XS, STYLE_2 } from '../../../styles';

export const StyledMessageFile = styled.a`
	display: flex;
	align-items: center;
	background-color: ${({ theme }) => theme.palette.greyblue['245']};
	text-decoration: none;
	padding: 0.5rem;
	${STYLE_2};
	margin: 0.5rem 0;
	border-radius: 0.25rem;
	width: 100%;

	svg {
		color: ${({ theme }) => theme.palette.common.white};
		background: ${({ theme }) => theme.palette.primary.main};
		border-radius: inherit;
		padding: 0.25rem;
		height: 1.75rem;
		width: 1.75rem;
	}
`;

export const StyledFileInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5rem;
`;

export const StyledFileName = styled.span`
	color: ${({ theme }) => theme.palette.common.black};
	${SIZE_SM};
`;

export const StyledFileType = styled.span`
	color: ${({ theme }) => theme.palette.common.blackTransparent};
	text-transform: uppercase;
	${SIZE_XS};
`;
