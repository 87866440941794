import { useTranslation } from 'react-i18next';

import {
	StyledFooterContent,
	StyledFooterWrapper,
	StyledLink,
} from '../../settings.styles';

export const SettingsFooter = () => {
	const { t } = useTranslation();

	return (
		<StyledFooterWrapper>
			<StyledFooterContent>
				<StyledLink
					href="https://www.heylog.com/agb"
					rel="noreferrer noopener"
					target="_blank"
				>
					{t('navigation.settingsMenu.legal.termsOfService')}
				</StyledLink>
				<StyledLink
					href="https://www.heylog.com/datenschutzerklaerung"
					rel="noreferrer noopener"
					target="_blank"
				>
					{t('navigation.settingsMenu.legal.privacyPolicy')}
				</StyledLink>
				<StyledLink
					href="https://www.heylog.com/impressum"
					rel="noreferrer noopener"
					target="_blank"
				>
					{t('navigation.settingsMenu.legal.imprint')}
				</StyledLink>
			</StyledFooterContent>
		</StyledFooterWrapper>
	);
};
