import type { UserOrderStatus } from '@heylog-app/shared/types';

const colors = {
	orderStatus: {
		created: '#ffe0b2',
		accepted: '#dcedc8',
		in_delivery: '#dcedc8',
		unloaded: '#006968',
		completed: '#122CC5',
		archived: '#e3f2fd',
		problem: '#ffcdd2',
		approaching: '#dcedc8',
		arrived_at_loading: '#dcedc8',
		loaded: '#dcedc8',
		departed_from_loading: '#dcedc8',
		in_transit: '#dcedc8',
		arrived_at_unloading: '#dcedc8',
		delivered: '#dcedc8',
	},
};

export const getOrderStatusColor = (status: UserOrderStatus) => {
	const statusColors = colors.orderStatus;
	const statusKey = status.toLowerCase() as keyof typeof statusColors;

	return statusColors[statusKey];
};
