import styled from 'styled-components';

import { STYLE_1, STYLE_2 } from '../../../styles';

export const StyledLocationTitle = styled.div`
	display: inline-flex;
	align-items: center;
	text-decoration: none;
	padding-right: 100px;
	${STYLE_2};

	svg {
		color: ${({ theme }) => theme.palette.common.white};
		background: ${({ theme }) => theme.palette.primary.main};
		border-radius: 0.25rem;
		padding: 0.25rem;
		height: 1.75rem;
		width: 1.75rem;
		margin-right: 0.3rem;
	}
`;

export const StyledLocationNameAddress = styled.div`
	text-decoration: none;
	${STYLE_1};
`;

export const StyledLocationMapWrapper = styled.div`
	background-color: ${({ theme }) => theme.palette.greyblue['245']};
	text-decoration: none;
	padding: 0.5rem;
	${STYLE_2};
	margin: 0.5rem 0;
	width: 100%;
`;

export const StyledMapLink = styled.a`
	text-decoration: none;
`;
