import { Button, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

import { H1, colors } from '@heylog-app/frontend-lib/app';

export const StyledFormControlLabel = styled(FormControlLabel)`
	margin-right: 0;
	padding-left: 3px;

	span {
		padding: 5px;
	}

	.MuiTypography-root {
		font-size: 14px;
	}
`;

export const StyledTitle = styled.div`
	${H1};
	color: ${colors.grey[900]};
`;

export const StyledButton = styled(Button)`
	height: 30px;
	background-color: ${({ theme }) => theme.palette.primary.light};
`;
