import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { AdapterDayjs as AdapterDayjsPro } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';

import { InputV2 } from '../inputV2/inputV2';

import type { Dayjs } from 'dayjs';
import type { FC } from 'react';
import type { MuiTextFieldProps } from '@mui/x-date-pickers/internals';

interface BaseProps {
	$inputPadding?: string;
}

interface RangeProps extends BaseProps {
	type: 'range';
	value: [Dayjs | null, Dayjs | null];
	onChange: (values: [Dayjs | null, Dayjs | null]) => void;
}

interface SingleDateProps extends BaseProps {
	type: 'month';
	value: Dayjs | null;
	onChange: (value: Dayjs | null) => void;
}

type DatepickerProps = RangeProps | SingleDateProps;

export const Datepicker: FC<DatepickerProps> = ({
	$inputPadding,
	type,
	value,
	onChange,
}) => {
	const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);

	const handleInputClick = () => {
		setOpenDatePicker(true);
	};

	const handleCloseDatePicker = () => {
		setOpenDatePicker(false);
	};

	const renderRangeInput = (
		startProps: MuiTextFieldProps,
		endProps: MuiTextFieldProps,
	) => {
		const start = startProps.inputProps?.['value'];
		const end = endProps.inputProps?.['value'];

		return (
			<InputV2
				$padding={$inputPadding}
				fullWidth
				{...startProps}
				label={undefined}
				inputProps={{ ...startProps.inputProps, value: `${start} - ${end}` }}
			/>
		);
	};

	const renderInput = (props: MuiTextFieldProps) => {
		return (
			<InputV2 $padding={$inputPadding} fullWidth {...props} onClick={handleInputClick} />
		);
	};

	if (type === 'month')
		return (
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DatePicker
					onChange={onChange}
					value={value}
					open={openDatePicker}
					onClose={handleCloseDatePicker}
					openTo="month"
					views={['year', 'month']}
					renderInput={renderInput}
				/>
			</LocalizationProvider>
		);

	return (
		<LocalizationProviderPro dateAdapter={AdapterDayjsPro}>
			<DateRangePicker
				renderInput={renderRangeInput}
				onChange={onChange}
				value={value}
				inputFormat="DD.MM.YYYY"
			/>
		</LocalizationProviderPro>
	);
};
