import styled from 'styled-components';
import { Button } from '@mui/material';

import { colors } from '@heylog-app/frontend-lib/app';

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
	justify-content: start;
	padding: 1.11rem 1rem;

	background-color: ${colors.common.white};

	&:hover {
		background-color: ${colors.greyblue[245]};
	}
`;

export const StyledMessengerButtons = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.25rem;
`;

const StyledPill = styled.p`
	width: 70px;
	font-size: 10px !important;
	line-height: 10px;
	font-weight: bold;
	padding: 4px 12px;
	display: inline-block;
	margin: 0 16px 0 0;
	border-radius: 13px;
`;

export const StyledActivePill = styled(StyledPill)`
	background-color: ${colors.green.main100};
	color: white;
`;

export const StyledInactivePill = styled(StyledPill)`
	width: auto;
	background-color: transparent;
	color: ${colors.greyblue[175]};
	border: 1px solid ${colors.greyblue[175]};
`;

export const StyledInactiveGuestPill = styled(StyledInactivePill)`
	margin-left: 4px;
	margin-right: 4px;
	text-align: center;
`;

export const StyledExpiredPill = styled(StyledInactiveGuestPill)`
	border-color: ${colors.red.main};
	color: ${colors.red.main};
`;
