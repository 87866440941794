export const IconClipboard = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
		<g fill="none" fillRule="evenodd">
			<path
				d="m15 17.654 5.142-5.143a.725.725 0 0 1 .522-.212.707.707 0 0 1 .532.213c.145.144.217.32.217.526a.718.718 0 0 1-.217.527l-5.563 5.564a.867.867 0 0 1-.633.27.867.867 0 0 1-.633-.27l-2.744-2.744a.725.725 0 0 1-.212-.523.707.707 0 0 1 .212-.531.718.718 0 0 1 .527-.218c.206 0 .382.073.527.218L15 17.654zM4.808 20.692a1.74 1.74 0 0 1-1.277-.53A1.74 1.74 0 0 1 3 18.884V5.5c0-.497.177-.923.531-1.277a1.74 1.74 0 0 1 1.277-.53h4.405c.139-.488.422-.892.85-1.212C10.49 2.16 10.969 2 11.5 2c.551 0 1.038.16 1.46.48.423.321.703.725.842 1.212h4.39c.497 0 .923.177 1.277.531.354.354.53.78.53 1.277v3.942c0 .213-.071.39-.215.535a.726.726 0 0 1-.534.215.725.725 0 0 1-.535-.215.726.726 0 0 1-.215-.535V5.5a.294.294 0 0 0-.096-.212.294.294 0 0 0-.212-.096H16v1.712c0 .256-.086.47-.259.644a.869.869 0 0 1-.641.26H7.9a.869.869 0 0 1-.641-.26A.877.877 0 0 1 7 6.904V5.192H4.808a.294.294 0 0 0-.212.096.294.294 0 0 0-.096.212v13.385c0 .076.032.147.096.211a.294.294 0 0 0 .212.096H9.75c.212 0 .39.072.534.216a.726.726 0 0 1 .216.535c0 .212-.072.39-.216.534a.726.726 0 0 1-.534.215H4.808zm6.694-15.384a.87.87 0 0 0 .643-.26.88.88 0 0 0 .259-.646.87.87 0 0 0-.26-.643.88.88 0 0 0-.646-.259.87.87 0 0 0-.643.26.88.88 0 0 0-.259.646.87.87 0 0 0 .26.643.88.88 0 0 0 .646.259z"
				fill="#FFF"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);
