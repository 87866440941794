import { Button, Dialog, DialogContent, DialogTitle, InputLabel } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { MessageLanguageSelect } from '../message-language-select/message-language-select';
import { useContact, useConversation, useMessageTranslation } from '../../../hooks';
import { ModalClose } from '../modals/modal-close';
import { StyledDialogActions } from '../../../components';

import type { TranslationServiceType } from '@heylog-app/shared/types';
import type { UseDialogReturnType } from '../../../types';
import type { SelectChangeEvent } from '@mui/material';
import type { FC } from 'react';

type MessageTranslationDialogProps = {
	control: UseDialogReturnType;
	conversationLanguage: string;
	handleConversationLanguage: (e: SelectChangeEvent<string>) => void;
	contactLanguage: string;
	handleContactLanguage: (e: SelectChangeEvent<string>) => void;
	setIsTranslationActive: (isTranslationActive: boolean) => void;
	conversationId: string;
	translationService: TranslationServiceType;
};

export const MessageTranslationDialog: FC<MessageTranslationDialogProps> = ({
	control,
	setIsTranslationActive,
	conversationLanguage,
	contactLanguage,
	handleConversationLanguage,
	handleContactLanguage,
	conversationId,
	translationService,
}) => {
	const { workspaceId = '', contactId = '' } = useParams();
	const { updateConversation } = useConversation(workspaceId, conversationId);
	const { supportedLang } = useMessageTranslation({
		translationService: translationService,
	});

	const { contact } = useContact(contactId);

	const { showDialog, closeDialog } = control;

	const activateTranslation = useCallback(() => {
		closeDialog();
		setIsTranslationActive(true);
		updateConversation({
			conversationLanguage,
			contactLanguage,
			isTranslationActive: true,
		});
	}, [
		setIsTranslationActive,
		updateConversation,
		conversationLanguage,
		contactLanguage,
		closeDialog,
	]);

	const { t } = useTranslation();

	return (
		<Dialog open={showDialog} onClose={closeDialog} fullWidth maxWidth="md">
			<ModalClose closeModalFn={closeDialog} />
			<DialogTitle>{t('chats.activateTranslationTitle')}</DialogTitle>
			<DialogContent>
				<InputLabel>{t('chats.conversationLanguageSelectLabel')}</InputLabel>
				<MessageLanguageSelect
					dataTest="dialog-user-language-select"
					value={conversationLanguage}
					onChange={handleConversationLanguage}
					supportedLang={supportedLang?.sourceLanguages}
				/>
				<InputLabel>{`${t('chats.contactLanguageSelectLabel')} ${
					contact?.firstName ?? t('chats.contactNameFallback')
				}`}</InputLabel>

				<MessageLanguageSelect
					dataTest="dialog-contact-language-select"
					value={contactLanguage}
					onChange={handleContactLanguage}
					supportedLang={supportedLang?.targetLanguages}
				/>
			</DialogContent>
			<StyledDialogActions>
				<Button variant="outlined" onClick={closeDialog}>
					{t('actionLabels.cancel')}
				</Button>
				<Button
					data-test="activate-translation-button"
					variant="contained"
					onClick={activateTranslation}
					autoFocus
				>
					{t('actionLabels.activate')}
				</Button>
			</StyledDialogActions>
		</Dialog>
	);
};
