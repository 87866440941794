import type { FC } from 'react';

export const IconPl: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M88 48H8C8 70.092 25.908 88 48 88C70.092 88 88 70.092 88 48Z"
			fill="#F44336"
		/>
		<path d="M48 8C25.908 8 8 25.908 8 48H88C88 25.908 70.092 8 48 8Z" fill="#ECEFF1" />
	</svg>
);
