export const IconViber = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M26.3235 5.40928C25.6247 4.76454 22.801 2.7147 16.5114 2.68685C16.5114 2.68685 9.09432 2.23958 5.47861 5.55612C3.4659 7.56926 2.75786 10.5149 2.68318 14.1669C2.60849 17.8189 2.51187 24.663 9.10909 26.5187H9.11542L9.1112 29.3504C9.1112 29.3504 9.06901 30.4969 9.82388 30.7306C10.737 31.0142 11.2729 30.1429 12.1446 29.2036C12.6231 28.688 13.2839 27.9306 13.7818 27.3516C18.2937 27.7314 21.7639 26.8634 22.158 26.7352C23.069 26.4398 28.224 25.7795 29.0624 18.9358C29.9278 11.882 28.6438 7.42031 26.3235 5.40928Z"
			fill="white"
		/>
		<path
			d="M24.8556 6.83334C24.2649 6.29662 21.715 4.69447 16.3946 4.67126C16.3946 4.67126 10.0944 4.25732 7.03613 7.02744C5.33398 8.70808 4.76054 11.2073 4.69767 14.2572C4.6348 17.307 4.30905 23.1334 9.88768 24.6832C9.88768 24.6832 9.86574 29.0086 9.86321 29.3888C9.86321 29.6546 9.90541 29.8365 10.0573 29.8741C10.1666 29.9006 10.3303 29.8441 10.4696 29.7053C11.3616 28.8095 14.2173 25.3642 14.2173 25.3642C18.0491 25.6144 21.0977 24.8579 21.4256 24.7507C22.1986 24.5022 26.3797 24.1431 27.0873 18.4273C27.8186 12.5356 26.8185 8.51398 24.8556 6.83334Z"
			fill="#7360F2"
		/>
		<path
			d="M19.1 14.0067C19.0308 12.6117 18.3222 11.879 16.9742 11.8087"
			stroke="white"
			strokeWidth="0.266779"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.9254 14.619C20.9535 13.3194 20.5686 12.2336 19.7705 11.3615C18.9688 10.4864 17.8591 10.0045 16.435 9.90031"
			stroke="white"
			strokeWidth="0.266779"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M22.7943 15.3496C22.7771 13.0944 22.1032 11.3183 20.7727 10.0212C19.4421 8.72413 17.7874 8.0687 15.8084 8.05492"
			stroke="white"
			strokeWidth="0.266779"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M17.0443 18.7712C17.0443 18.7712 17.5443 18.8134 17.8135 18.4818L18.3384 17.8214C18.5916 17.494 19.2026 17.2851 19.8009 17.6185C20.2493 17.8742 20.6846 18.1522 21.1052 18.4514C21.5022 18.7434 22.3153 19.4219 22.3179 19.4219C22.7052 19.7489 22.7947 20.2291 22.531 20.7354C22.531 20.7384 22.5288 20.7434 22.5288 20.746C22.2384 21.2495 21.8696 21.7036 21.4364 22.0912C21.4314 22.0937 21.4314 22.0962 21.4267 22.0988C21.0503 22.4132 20.6806 22.592 20.3174 22.6351C20.2639 22.6444 20.2096 22.6478 20.1554 22.6452C19.9952 22.6467 19.8359 22.6222 19.6836 22.5726L19.6718 22.5553C19.1123 22.3975 18.1781 22.0025 16.6224 21.1443C15.7221 20.6535 14.865 20.0873 14.0603 19.4518C13.6569 19.1335 13.2722 18.7923 12.9079 18.4299L12.8691 18.3911L12.8303 18.3522L12.7915 18.3134C12.7784 18.3008 12.7657 18.2877 12.7526 18.2746C12.3902 17.9103 12.049 17.5256 11.7307 17.1222C11.0953 16.3176 10.5291 15.4607 10.0382 14.5606C9.17996 13.0044 8.78502 12.0711 8.62721 11.5107L8.60991 11.4989C8.56044 11.3466 8.53607 11.1873 8.53775 11.0271C8.5348 10.9729 8.53805 10.9186 8.54746 10.8651C8.59275 10.5025 8.77179 10.1325 9.0846 9.75496C9.08713 9.75032 9.08967 9.75032 9.0922 9.74525C9.47968 9.31204 9.93378 8.94341 10.4374 8.65324C10.4399 8.65324 10.445 8.65071 10.4479 8.65071C10.9543 8.38699 11.4345 8.47644 11.761 8.86169C11.7636 8.86422 12.4408 9.67732 12.7315 10.0744C13.0308 10.4954 13.3088 10.9311 13.5645 11.3799C13.8978 11.9778 13.6889 12.5901 13.3615 12.8424L12.7012 13.3673C12.3678 13.6365 12.4117 14.1365 12.4117 14.1365C12.4117 14.1365 13.3898 17.8383 17.0443 18.7712Z"
			fill="white"
		/>
	</svg>
);
