import type {
	AcceptGuestInviteEntryParams,
	CreateEntryParams,
	DeleteGuestParams,
} from '../types';
import type {
	CreateConversationInvitationReqInterface,
	ConversationInvitationResInterface,
	ArchiveConversationInvitationsReqInterface,
	ArchiveConversationInvitationsResInterface,
	UserResInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance, AxiosResponse } from 'axios';

export const inviteGuestAPI = async (
	apiClient: AxiosInstance,
	{
		data,
		workspaceId,
		conversationId,
	}: CreateEntryParams<CreateConversationInvitationReqInterface>,
): Promise<AxiosResponse<ConversationInvitationResInterface>> => {
	return apiClient.post(
		`workspaces/${workspaceId}/conversations/${conversationId}/invitations`,
		data,
	);
};

export const archiveGuestAPI = async (
	apiClient: AxiosInstance,
	{ workspaceId, conversationId, guestToRemoveId }: DeleteGuestParams,
): Promise<AxiosResponse<UserResInterface>> => {
	return apiClient.patch(
		`workspaces/${workspaceId}/conversations/${conversationId}/guests/${guestToRemoveId}/remove`,
	);
};

export const acceptGuestInvitationAPI = async (
	apiClient: AxiosInstance,
	{ workspaceId, conversationId, invitationId }: AcceptGuestInviteEntryParams,
): Promise<AxiosResponse<ConversationInvitationResInterface>> => {
	return apiClient.post(
		`workspaces/${workspaceId}/conversations/${conversationId}/invitations/${invitationId}/accept`,
	);
};

export const archiveGuestInvitationAPI = async (
	apiClient: AxiosInstance,
	{
		data,
		workspaceId,
		conversationId,
	}: CreateEntryParams<ArchiveConversationInvitationsReqInterface>,
): Promise<AxiosResponse<ArchiveConversationInvitationsResInterface>> => {
	return apiClient.post(
		`workspaces/${workspaceId}/conversations/${conversationId}/invitations/archive`,
		data,
	);
};
