export const HeylogLogoWhite = () => (
	<svg
		width="100"
		height="35"
		viewBox="0 0 101 28"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_567_1181)">
			<path
				d="M28.9389 3.38942V27.0581L21.7812 22.2815L21.6551 22.1974H21.5036H3.39475C1.79447 22.1974 0.5 20.9019 0.5 19.308V3.38942C0.5 1.7955 1.79447 0.5 3.39475 0.5H26.0442C27.6444 0.5 28.9389 1.7955 28.9389 3.38942Z"
				stroke="white"
			/>
			<path
				d="M21.3917 18.2794L17.7944 4.67377H15.3909L15.6094 10.1949H13.7655L14.0426 4.67377H11.6445L8.04187 18.2794L13.3605 18.3274L13.6749 13.126H15.6787L15.9292 18.3274L21.3917 18.2794Z"
				fill="white"
			/>
			<path
				d="M25.6765 10.1949V13.126H22.9745L21.7381 14.6768L20.4751 10.1949H25.6765Z"
				fill="white"
			/>
			<path
				d="M3.75708 10.1949V13.126H6.45902L7.6208 14.6768L8.95845 10.1949H3.75708Z"
				fill="white"
			/>
			<path
				d="M95.3621 15.732C93.8805 15.732 92.836 14.7514 92.836 13.3125C92.836 11.8736 93.8805 10.893 95.3621 10.893C96.8436 10.893 97.8881 11.8736 97.8881 13.3125C97.8881 14.7514 96.8436 15.732 95.3621 15.732ZM97.8881 8.30301V9.36886C97.2006 8.51085 96.1987 8.0099 94.8398 8.0099C91.8767 8.0099 89.7024 10.4294 89.7024 13.3125C89.7024 16.1957 91.8714 18.6151 94.8398 18.6151C96.1987 18.6151 97.2006 18.1142 97.8881 17.2562V18.2155C97.8881 19.697 96.9875 20.4484 95.5273 20.4484C94.147 20.4484 93.5448 19.8622 93.1451 19.1321L90.4751 20.6776C91.4344 22.3883 93.2517 23.2037 95.4207 23.2037C98.1972 23.2037 100.931 21.7221 100.931 18.2155V8.29768H97.8828L97.8881 8.30301ZM82.8436 15.9825C81.4846 15.9825 80.4401 15.0019 80.4401 13.5204C80.4401 12.0388 81.4846 11.0582 82.8436 11.0582C84.2026 11.0582 85.2418 12.0388 85.2418 13.5204C85.2418 15.0019 84.1972 15.9825 82.8436 15.9825ZM82.8436 19.0308C85.9132 19.0308 88.3754 16.6327 88.3754 13.5204C88.3754 10.4081 85.9132 8.0099 82.8436 8.0099C79.7739 8.0099 77.3118 10.4081 77.3118 13.5204C77.3118 16.6327 79.7739 19.0308 82.8436 19.0308ZM72.6167 18.7377H75.7503V3.496H72.6167V18.7324V18.7377ZM67.9216 8.30301L65.9605 14.6928L63.6422 8.30301H60.1995L64.3563 18.2581C63.898 19.5318 63.2052 19.9901 61.745 19.9901V22.9105C64.6654 23.0544 66.5254 21.7434 67.6925 18.4233L71.2631 8.29768H67.9216V8.30301ZM52.3175 12.4385C52.6106 11.3727 53.4047 10.7918 54.5931 10.7918C55.5311 10.7918 56.4743 11.2288 56.8048 12.4385H52.3175ZM52.3815 14.7781H59.7892C59.8744 14.3837 59.9171 13.9627 59.9171 13.5257C59.9171 10.3761 57.6628 8.01523 54.6144 8.01523C51.3156 8.01523 49.0613 10.4134 49.0613 13.5257C49.0613 16.638 51.273 19.0362 54.8436 19.0362C56.8261 19.0362 58.3716 18.3061 59.3948 16.8885L56.89 15.4496C56.4743 15.9079 55.7442 16.2436 54.8862 16.2436C53.7404 16.2436 52.7758 15.8706 52.3815 14.7834V14.7781ZM44.185 4.13552V9.73125H39.5912V4.12486H36.2498V18.7377H39.5912V12.9341H44.185V18.7377H47.5265V4.12486H44.185V4.13552Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_567_1181">
				<rect width="263" height="55" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
