import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	ListItemIcon,
	MenuItem,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Icon from '@mdi/react';
import { mdiAccount, mdiArchive, mdiPencil } from '@mdi/js';

import {
	Actions,
	ContactStatusEnum,
	MessagingTransportTypeEnum,
} from '@heylog-app/shared/types';

import {
	StyledAvatar,
	StyledContactTitleWrapper,
	StyledTransportTypeInformation,
	StyledTransportTypeWrapper,
} from './contact-information-header.styles';
import { IconViber, IconWhatsApp } from '../icons';
import { ROUTES, formatPhoneNumberForDisplay, getNameInitials } from '../../util';
import { Can } from '../can';
import { ButtonMenu, ModalClose } from '../ui';
import { EditContactForm } from '../forms';
import { StyledDialogActions } from '../dialog';
import { useContactActions, useDialog, useMenu, usePlugins } from '../../hooks';

import type { FC } from 'react';

type ContactInformationHeaderProps = {
	contactId?: string;
	onCloseDialog?: () => void;
};

export const ContactInformationHeader: FC<ContactInformationHeaderProps> = ({
	contactId,
	onCloseDialog,
}) => {
	const { workspaceId = '' } = useParams();
	const navigate = useNavigate();
	const { FrontendV2Plugin } = usePlugins(workspaceId);

	const menuControl = useMenu();
	const { t } = useTranslation();

	const { contact, deleteContact } = useContactActions(contactId);
	const transportType = contact?.conversations?.[0]?.transportType;

	const {
		showDialog: showEditDialog,
		openDialog: openEditDialog,
		closeDialog: closeEditDialog,
	} = useDialog();

	const {
		showDialog: showConfirmDeleteDialog,
		openDialog: openConfirmDeleteDialog,
		closeDialog: closeConfirmDeleteDialog,
	} = useDialog();

	const handleSubmitDelete = useCallback(async () => {
		const link = FrontendV2Plugin ? ROUTES.DASHBOARD.OVERVIEW : ROUTES.CONTACTS.OVERVIEW;
		contactId && (await deleteContact({ id: contactId, workspaceId }));
		onCloseDialog && onCloseDialog();
		closeConfirmDeleteDialog();
		navigate(generatePath(link, { workspaceId }));
	}, [
		contactId,
		deleteContact,
		navigate,
		workspaceId,
		FrontendV2Plugin,
		onCloseDialog,
		closeConfirmDeleteDialog,
	]);

	const onEditSuccess = useCallback(() => {
		closeEditDialog();
		menuControl.closeMenu();
	}, [closeEditDialog, menuControl]);

	return (
		<>
			<Grid container marginBottom={7} justifyContent="space-between" alignItems="center">
				<StyledContactTitleWrapper item>
					<StyledAvatar sx={{ width: 96, height: 96, fontSize: '2rem' }} src="">
						{getNameInitials(contact?.firstName, contact?.lastName) || (
							<Icon path={mdiAccount} size={'1rem'} />
						)}
					</StyledAvatar>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'space-between',
							height: '70px',
						}}
					>
						<Box sx={{ fontSize: '28px' }}>
							{contact && contact.firstName + ' ' + contact.lastName}
						</Box>
						<StyledTransportTypeWrapper>
							{transportType === MessagingTransportTypeEnum.FACEBOOK_WHATSAPP && (
								<>
									<IconWhatsApp />
									WhatsApp
								</>
							)}
							{transportType === MessagingTransportTypeEnum.VIBER && (
								<>
									<IconViber />
									Viber
								</>
							)}
							<StyledTransportTypeInformation>
								{formatPhoneNumberForDisplay(contact?.phone)}
							</StyledTransportTypeInformation>
						</StyledTransportTypeWrapper>
					</Box>
				</StyledContactTitleWrapper>
				<Can I={Actions.MANAGE} a="Workspace">
					{() => (
						<Grid item>
							<ButtonMenu
								id="edit-contact"
								control={menuControl}
								disabled={contact?.status === ContactStatusEnum.ARCHIVED ? true : false}
								menuOrientation="right"
								button={
									<Button data-test="contact-menu-button" variant="outlined" color="info">
										<MoreHorizIcon />
									</Button>
								}
							>
								<MenuItem onClick={openEditDialog} data-test="edit-contact-button">
									<ListItemIcon>
										<Icon path={mdiPencil} size={'1em'} />
									</ListItemIcon>
									{t('actionLabels.edit')}
								</MenuItem>
								<MenuItem
									data-test="archive-contact-button"
									onClick={openConfirmDeleteDialog}
								>
									<ListItemIcon>
										<Icon path={mdiArchive} size={'1em'} />
									</ListItemIcon>
									{t('actionLabels.archive')}
								</MenuItem>
							</ButtonMenu>
						</Grid>
					)}
				</Can>
			</Grid>

			<Dialog open={showEditDialog} onClose={closeEditDialog}>
				<ModalClose closeModalFn={closeEditDialog} />
				<DialogTitle>{t('contacts.form.editContactTitle')}</DialogTitle>
				<EditContactForm
					onSuccess={onEditSuccess}
					onCancel={closeEditDialog}
					contact={contact}
				/>
			</Dialog>

			<Dialog open={showConfirmDeleteDialog} onClose={closeConfirmDeleteDialog}>
				<ModalClose closeModalFn={closeConfirmDeleteDialog} />
				<DialogTitle id="alert-dialog-title">
					{t('contacts.form.confirmArchive')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('contacts.form.confirmArchiveText')}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={closeConfirmDeleteDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button
						data-test="confirm-archive-contact-button"
						variant="contained"
						color="error"
						onClick={handleSubmitDelete}
						autoFocus
					>
						{t('actionLabels.archive')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</>
	);
};
