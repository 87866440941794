import styled from 'styled-components';

import { H2, T2_medium, T3, T4, T4_medium, colors } from '../../styles';

export const StyledTooltipDate = styled.div`
	${T4};
	color: ${colors.common.white};
`;

export const StyledTooltipValue = styled.div`
	${T4_medium};
	color: ${colors.common.white};
`;

export const StyledTitle = styled.div`
	${T2_medium};
	color: ${colors.grey[900]};
`;

export const StyledTotalLabel = styled.div`
	${T3};
	color: ${colors.grey[600]};
`;

export const StyledTotalValue = styled.div`
	${H2};
	color: ${colors.grey[900]};
`;

export const StyledDifferenceValue = styled.div<{ isGrowing: boolean }>`
	display: flex;
	margin-top: auto;
	align-items: center;
	${T2_medium};
	color: ${({ isGrowing }) => (isGrowing ? colors.success.dark : colors.error.dark)};
`;
