import SendIcon from '@mui/icons-material/Send';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import TranslateIcon from '@mui/icons-material/Translate';
import MessageIcon from '@mui/icons-material/Message';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { useUploadFiles } from '../../../hooks';
import {
	StyledFileInput,
	StyledFileWrapper,
	StyledMessageFlexWrapper,
	StyledMessageInput,
	StyledMessageInputToolbar,
	StyledMessageInputWrapper,
	StyledSubmitButton,
	StyledTextareas,
	StyledToolbarButton,
} from './message-input.styles';
import { StyledConversationInactiveNotice } from '../conversation-inactive-notice/conversation-inactive-notice.styles';

import type { FC } from 'react';

type MessageInputBlockedProps = {
	title: string;
};

export const MessageInputBlocked: FC<MessageInputBlockedProps> = ({ title }) => {
	const { getInputProps, getRootProps } = useUploadFiles();
	const { t } = useTranslation();

	const toggleTranslationButtonLabel = useMemo(() => t('chats.activateTranslation'), [t]);

	const toggleTemplateButtonLabel = useMemo(() => t('chats.template'), [t]);

	const emptyFn = () => {
		console.log();
	};

	return (
		<StyledMessageInputWrapper data-test="blocked-message-input">
			<StyledMessageInput {...getRootProps()}>
				<StyledMessageInputToolbar>
					<StyledToolbarButton
						onClick={emptyFn}
						disabled={true}
						data-test="attach-file-button"
					>
						<AttachFileIcon />
						{t('chats.fileUpload')}
					</StyledToolbarButton>
					<StyledFileInput {...getInputProps()} />
					<StyledToolbarButton
						onClick={emptyFn}
						disabled={true}
						data-test="toggle-translation-button"
					>
						<TranslateIcon />
						{toggleTranslationButtonLabel}
					</StyledToolbarButton>
					<StyledToolbarButton
						onClick={emptyFn}
						disabled={true}
						data-test="message-template-button"
					>
						<MessageIcon />
						{toggleTemplateButtonLabel}
					</StyledToolbarButton>
				</StyledMessageInputToolbar>
				<StyledMessageFlexWrapper>
					<StyledFileWrapper>
						<StyledTextareas>
							<StyledConversationInactiveNotice data-test="inactive-notice">
								<span>{title}</span>
							</StyledConversationInactiveNotice>
						</StyledTextareas>
					</StyledFileWrapper>
					<StyledSubmitButton disabled={true} $active={false}>
						<SendIcon />
					</StyledSubmitButton>
				</StyledMessageFlexWrapper>
			</StyledMessageInput>
		</StyledMessageInputWrapper>
	);
};
