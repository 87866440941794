import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { useApiClientContext } from './use-api-client-context.hook';
import { getFetcher, getGuestInvitationsKey } from '../util';
import { AbilityContext } from '../providers';

import type { ConversationInvitationResInterface } from '@heylog-app/shared/types';

export const useGuestInvitations = (workspaceId: string, conversationId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, mutate, isLoading } = useSWR<
		Array<ConversationInvitationResInterface>
	>(
		ability.can(Actions.MANAGE, 'Workspace')
			? getGuestInvitationsKey(workspaceId, conversationId)
			: null,
		getFetcher(apiClient),
	);

	return {
		guestInvitations: data,
		fetchGuestInvitations: mutate,
		isLoading,
		isError: error,
	};
};
