import { createContext, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { useEnvContext, useAuthContext } from '../hooks';
import { buildBeamsInstance, NotificationPolyfill } from '../util';
import { BrowserNotificationsContext } from './browser-notifications-provider';
import { AbilityContext } from './ability';

import type { Nullable } from '@heylog-app/shared/types';
import type { FC, PropsWithChildren } from 'react';
import type { PusherBeamsContextType } from '../types';

export const PusherBeamsContext = createContext<Nullable<PusherBeamsContextType>>(null);

export const PusherBeamsProvider: FC<PropsWithChildren> = ({ children }) => {
	const ability = useAbility(AbilityContext);
	const [browserNotificationsStatus] = useContext(BrowserNotificationsContext);

	// we can use params here, as the pusher provider is only loaded on authenticated routes
	const { workspaceId } = useParams();
	const envContext = useEnvContext();
	const authContext = useAuthContext();

	// Ask for notification permission on first component render
	const firstRender = useRef(true);
	useEffect(() => {
		if (firstRender.current) {
			NotificationPolyfill.requestNotification();
			firstRender.current = false;
		}
	});

	const value = useRef<Nullable<PusherBeamsContextType>>({
		beamsClient: undefined,
	});

	useEffect(() => {
		(async () => {
			await value.current?.beamsClient?.stop();

			// Check if the user can manage the workspace before calling buildBeamsInstance
			if (browserNotificationsStatus && ability.can(Actions.MANAGE, 'Workspace')) {
				value.current = {
					beamsClient: buildBeamsInstance(envContext, authContext, workspaceId),
				};
			} else {
				value.current = {
					beamsClient: undefined,
				};
			}
		})();
	}, [ability, authContext, browserNotificationsStatus, envContext, workspaceId]);

	useEffect(() => {
		return () => {
			value.current?.beamsClient?.stop();
		};
	}, []);

	return (
		<PusherBeamsContext.Provider value={value.current}>
			{children}
		</PusherBeamsContext.Provider>
	);
};
