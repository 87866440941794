import { UploadedFile } from './uploaded-file';
import { StyledUploadedFilesList } from './uploaded-files.styles';

type UploadedFilesProps = {
	files: File[];
	removeFile: (file: File) => void;
	fileError: boolean;
	showMargin: boolean;
};

export const UploadedFiles = ({
	fileError,
	files,
	removeFile,
	showMargin,
}: UploadedFilesProps) => {
	return (
		<StyledUploadedFilesList $showMargin={showMargin} data-test="uploaded-files-list">
			{files.map((file, index) => (
				<UploadedFile
					fileError={fileError}
					removeFile={removeFile}
					key={file.size + index}
					file={file}
				/>
			))}
		</StyledUploadedFilesList>
	);
};
