import {
	Badge,
	Box,
	Container,
	Grid,
	Stack,
	ThemeProvider,
	Tooltip,
	createTheme,
	// Dialog,
	// DialogTitle,
} from '@mui/material';
import { useGridApiRef, deDE, enUS } from '@mui/x-data-grid-pro';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import * as locales from '@mui/material/locale';
import i18next from 'i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import Icon from '@mdi/react';
import { mdiFilterOutline, mdiPencil, mdiTrayArrowDown } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	ButtonV2,
	OrderContext,
	// ModalClose,
	OrderDialogActionEnum,
	ROUTES,
	TableFilterSidebar,
	TableV2,
	theme,
	useDashboardData,
	useDashboardDataTableFilters,
	// useDialog,
	useTableColumnsSettings,
} from '@heylog-app/frontend-lib/app';

import type { GridRowParams } from '@mui/x-data-grid-pro';

export const DashboardDataTable = () => {
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const apiRef = useGridApiRef();
	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const { workspaceId = '' } = useParams();
	const navigate = useNavigate();

	const { columns, rows } = useDashboardData();
	const { FiltersComponent, filteredRows, activeFiltersCount } =
		useDashboardDataTableFilters(rows, workspaceId);
	const {
		adjustedColumns,
		handleColumnResize,
		handleColumnOrderChange,
		handleColumnVisibilityModelChange,
	} = useTableColumnsSettings({
		columns,
		apiRef,
		localStorageName: 'dashboard-data-table',
	});
	const { t } = useTranslation();

	// https://github.com/mui/mui-x/issues/2138
	const openNewTabOnMiddleClick = useCallback(
		(e: MouseEvent) => {
			const target = e.target as Element | null;
			const rowElement = target?.closest<HTMLDivElement>('.MuiDataGrid-row');
			const rowId = rowElement?.dataset['id'];
			if (!rowId) {
				return;
			}
			const path = generatePath(ROUTES.SHIPMENTS.DETAIL, {
				shipmentId: rowId,
				workspaceId,
			});

			// middle button
			if (e.button === 1) {
				const win = window.open(path, '_blank');
				win?.focus();
			}
		},
		[workspaceId],
	);

	useEffect(() => {
		window.addEventListener('auxclick', openNewTabOnMiddleClick);

		return () => {
			window.removeEventListener('auxclick', openNewTabOnMiddleClick);
		};
	}, [openNewTabOnMiddleClick]);

	const handleRowClick = (params: GridRowParams) => {
		const order = params.row;
		navigate(
			generatePath(ROUTES.SHIPMENTS.DETAIL, { shipmentId: order.id, workspaceId }),
		);
	};

	const handleCloseFilters = () => setIsOpenFilters(false);
	const handleOpenFilters = () => setIsOpenFilters(true);

	const { onOpenOrderDialog } = useContext(OrderContext);

	const handleCreateOrderClick = () => {
		if (onOpenOrderDialog)
			onOpenOrderDialog({
				action: OrderDialogActionEnum.NEW,
				payload: {},
			});
	};

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales[tableLanguage]),
		[tableLanguage],
	);

	return (
		<ThemeProvider theme={themeWithLocale}>
			<TableFilterSidebar isOpen={isOpenFilters} onClose={handleCloseFilters}>
				{FiltersComponent}
			</TableFilterSidebar>
			<Grid container wrap="nowrap" columns={{ xs: 12 }} sx={{ height: '100%' }}>
				<Container
					maxWidth={false}
					sx={{
						paddingTop: '2rem',
						paddingBottom: '2rem',
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
					}}
				>
					<TableV2
						ToolbarContent={
							<Stack direction="row" gap={2}>
								<ButtonV2
									onClick={handleCreateOrderClick}
									$variant="light"
									startIcon={<Icon path={mdiPencil} size={1} />}
								>
									{t('orders.form.labels.submit')}
								</ButtonV2>
								{!isOpenFilters && (
									<Badge
										color="info"
										invisible={!activeFiltersCount}
										badgeContent={activeFiltersCount}
									>
										<ButtonV2
											onClick={handleOpenFilters}
											$variant="light"
											startIcon={<Icon path={mdiFilterOutline} size={1} />}
										>
											{t('dashboard.filters.title')}
										</ButtonV2>
									</Badge>
								)}
								<Tooltip placement="bottom" title={t('orders.csvTooltip')}>
									<Box>
										<ButtonV2
											sx={{ height: '100%' }}
											$padding="12px 16px"
											$variant="light"
											onClick={() => apiRef.current.exportDataAsCsv()}
										>
											<Icon path={mdiTrayArrowDown} size={1} />
										</ButtonV2>
									</Box>
								</Tooltip>
							</Stack>
						}
						title="navigation.orders"
						disableColumnFilter
						data-test="fleet-checks-table"
						localeText={
							tableLanguage === 'deDE'
								? deDE.components.MuiDataGrid.defaultProps.localeText
								: enUS.components.MuiDataGrid.defaultProps.localeText
						}
						withColumnSettings
						apiRef={apiRef}
						rows={filteredRows}
						onColumnResize={handleColumnResize}
						onColumnOrderChange={handleColumnOrderChange}
						onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
						density="compact"
						disableDensitySelector
						initialState={{
							filter: {
								filterModel: {
									items: [],
									quickFilterValues: [''],
								},
							},
							pagination: { paginationModel: { pageSize: 15 } },
						}}
						slotProps={{
							toolbar: {
								printOptions: { disableToolbarButton: true },
							},
						}}
						pageSizeOptions={[5, 10, 15]}
						sx={{
							backgroundColor: 'white',
							borderRadius: '5px',
							fontSize: '10px',
						}}
						onRowClick={handleRowClick}
						columns={adjustedColumns}
					/>
				</Container>
			</Grid>
		</ThemeProvider>
	);
};
