import { useTranslation } from 'react-i18next';

import { StyledDetailItem, StyledLabel, StyledValue } from './detail-item.styles';

import type { FC } from 'react';
type DetailItemProps = { label: string; value?: string | null };

export const DetailItem: FC<DetailItemProps> = ({ label, value }) => {
	const { t } = useTranslation();

	return (
		<StyledDetailItem>
			<StyledLabel>{t(label)}</StyledLabel>
			<StyledValue $value={value}>{value}</StyledValue>
		</StyledDetailItem>
	);
};
