import { useContext, useRef } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { BrowserNotificationsContext } from '../../providers';
import { FlashSnackbar } from '../snackbars';
import { useSnackbar } from '../../hooks';
import { useUpdateEffectHook } from '../../hooks/use-update-effect.hook';

import type { FC } from 'react';

export const NotificationFlashSnackbar: FC = () => {
	const { t } = useTranslation();

	const [browserNotificationsStatus] = useContext(BrowserNotificationsContext);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	// Sometime status changes twice in a moment (For example, user tries to enable browser notification by clicking
	// the setting but browser doesn't allow it so the setting is reverted to false again.
	// We use eventsCount and setTimeout to ensure first change is not triggered in that case. If there are more than one events,
	// we ignore the previous events and only process the latest event.
	const eventsCount = useRef(0);

	// Used to track last notification status. Suppose notification status is changed but it's the same as old one,
	// it means that change failed and we display the snackbar as an error instead of success
	const lastStatus = useRef(browserNotificationsStatus);

	useUpdateEffectHook(() => {
		eventsCount.current++;
		setTimeout(() => {
			if (eventsCount.current === 1) {
				openSnackbar(
					lastStatus.current !== browserNotificationsStatus ? 'success' : 'error',
					t('browserNotifications.status.' + (browserNotificationsStatus ? 'on' : 'off')),
				);
				lastStatus.current = browserNotificationsStatus;
			}
			eventsCount.current--;
		}, 1);
	}, [browserNotificationsStatus]);

	return <FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />;
};
