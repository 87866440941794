import { EventContextEnum, EventPayloadEnum } from '@heylog-app/shared/types';

import type { EntityEventResType } from '@heylog-app/shared/types';

export const extractOrderIdFromEntityEvent = (event: EntityEventResType) =>
	event.contextType === EventContextEnum.ORDER_EVENT
		? event.externalID.toString()
		: event.eventType === EventPayloadEnum.CONTACT_ORDER_ASSIGNMENT_CHANGED
		? event.eventPayload?.orderId?.toString()
		: null;
