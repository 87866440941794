import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	ListItemIcon,
	Menu,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ContactOrderStatusEnum, UserOrderStatusEnum } from '@heylog-app/shared/types';

import { StyledButton, StyledMenuItem } from './order-status-menu.styles';
import { useMenu, useDialog, useOrderActions } from '../../hooks';
import { ROUTES } from '../../util';
import { StyledDialogActions } from '../dialog';
import { ModalClose } from '../ui';
import { OrderStatusIcon } from '../order-status-icon';

import type { FC } from 'react';
import type { UserOrderStatus } from '@heylog-app/shared/types';

type OrderStatusMenuType = {
	orderId: string;
	isV2?: boolean;
	fullWidth?: boolean;
};

export const OrderStatusMenu: FC<OrderStatusMenuType> = ({
	orderId,
	isV2,
	fullWidth = true,
}) => {
	const { isMenuOpen, toggleButtonRef, toggleMenu, closeMenu } = useMenu();
	const { t } = useTranslation();
	const { workspaceId = '' } = useParams();
	const navigate = useNavigate();

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();

	const {
		showDialog: showConfirmCompleteDialog,
		openDialog: openConfirmCompleteDialog,
		closeDialog: closeConfirmCompleteDialog,
	} = useDialog();

	const { order, updateOrder } = useOrderActions(orderId);

	const setOrderStatus = useCallback(
		(status: UserOrderStatus) =>
			updateOrder({
				id: order?.id.toString(),
				data: { currentStatus: status },
				workspaceId: workspaceId || '',
			}),
		[order?.id, updateOrder, workspaceId],
	);

	const handleStatusChange = useCallback(
		(status: UserOrderStatus) => {
			if (status === UserOrderStatusEnum.ARCHIVED) {
				openConfirmArchiveDialog();
			} else if (status === UserOrderStatusEnum.DELIVERED) {
				openConfirmCompleteDialog();
			} else {
				setOrderStatus(status);
			}

			closeMenu();
		},
		[closeMenu, openConfirmArchiveDialog, openConfirmCompleteDialog, setOrderStatus],
	);

	const handleSubmitArchive = useCallback(async () => {
		await setOrderStatus(UserOrderStatusEnum.ARCHIVED);
		closeConfirmArchiveDialog();
		navigate(generatePath(ROUTES.DASHBOARD.OVERVIEW, { workspaceId }));
	}, [closeConfirmArchiveDialog, navigate, setOrderStatus, workspaceId]);

	const handleSubmitComplete = useCallback(async () => {
		await setOrderStatus(UserOrderStatusEnum.DELIVERED);
		closeConfirmCompleteDialog();
		navigate(generatePath(ROUTES.DASHBOARD.OVERVIEW, { workspaceId }));
	}, [closeConfirmCompleteDialog, navigate, setOrderStatus, workspaceId]);

	const statusLabel = useMemo(() => {
		return t(`orders.details.status.${order?.currentStatus?.toLowerCase()}`);
	}, [order?.currentStatus, t]);

	return (
		<>
			<StyledButton
				$isV2={isV2}
				data-test="order-status-menu-button"
				aria-controls={'order-status-menu'}
				aria-haspopup="true"
				aria-expanded={isMenuOpen ? 'true' : undefined}
				ref={toggleButtonRef}
				onClick={toggleMenu}
				variant="contained"
				fullWidth={fullWidth}
				size="small"
				startIcon={
					order?.currentStatus && <OrderStatusIcon status={order?.currentStatus} />
				}
				endIcon={<KeyboardArrowDownIcon />}
			>
				{statusLabel || t('orders.details.status.none')}
			</StyledButton>

			<Menu
				id="order-status-menu"
				open={isMenuOpen}
				anchorEl={toggleButtonRef?.current}
				onClose={closeMenu}
			>
				{!workspaceId &&
					Object.values(ContactOrderStatusEnum).map((status) => {
						return (
							<StyledMenuItem
								onClick={() => handleStatusChange(status)}
								key={status}
								data-test={`order-status-${status}`}
								sx={{ fontSize: '0.875rem' }}
							>
								<ListItemIcon>
									<OrderStatusIcon status={status} />
								</ListItemIcon>
								{t(`orders.details.status.${status.toLowerCase()}`)}
							</StyledMenuItem>
						);
					})}

				{workspaceId &&
					Object.values(UserOrderStatusEnum).map((status) => {
						return (
							<StyledMenuItem
								onClick={() => handleStatusChange(status)}
								key={status}
								data-test={`order-status-${status}`}
								sx={{ fontSize: '0.875rem' }}
							>
								<ListItemIcon>
									<OrderStatusIcon status={status} />
								</ListItemIcon>
								{t(`orders.details.status.${status.toLowerCase()}`)}
							</StyledMenuItem>
						);
					})}
			</Menu>

			<Dialog open={showConfirmArchiveDialog} onClose={closeConfirmArchiveDialog}>
				<ModalClose closeModalFn={closeConfirmArchiveDialog} />
				<DialogTitle id="archive-dialog-title">
					{t('orders.details.confirmArchive.title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="achive-dialog-description">
						{t('orders.details.confirmArchive.text')}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={closeConfirmArchiveDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button
						variant="contained"
						color="error"
						onClick={handleSubmitArchive}
						autoFocus
					>
						{t('actionLabels.archive')}
					</Button>
				</StyledDialogActions>
			</Dialog>

			<Dialog open={showConfirmCompleteDialog} onClose={closeConfirmCompleteDialog}>
				<ModalClose closeModalFn={closeConfirmCompleteDialog} />
				<DialogTitle id="complete-dialog-title">
					{t('orders.details.confirmComplete.title')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="complete-dialog-description">
						{t('orders.details.confirmComplete.text')}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={closeConfirmCompleteDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button
						variant="contained"
						onClick={handleSubmitComplete}
						autoFocus
						data-test="confirm-complete-order-button"
					>
						{t('actionLabels.complete')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</>
	);
};
