import styled from 'styled-components';
import Button from '@mui/material/Button';
import Icon from '@mdi/react';

import { colors } from '../../../styles';

export const StyledButton = styled(Button)<{ status: string }>`
	color: ${({ status }) => (status === 'true' ? '#198A1F' : colors.red['main'])};
`;

export const StyledButtonV2 = styled.div`
	width: 40px;
	height: 40px;
	border-radius: 4px;
	border: 0;
	background-color: rgba(255, 255, 255, 0.1);
	margin-right: 24px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
`;

export const StyledDotIcon = styled(Icon)`
	position: absolute;
	top: -12px;
	right: -12px;
`;
