import { MenuItem, Select } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import {
	convertToISO639LikeCountryCodes,
	whatsappSupportedLanguages,
} from '@heylog-app/shared/util';

import { useTemplates } from '../../../hooks';

import type { FC } from 'react';
import type { WhatsAppTemplateMessageResInterface } from '@heylog-app/shared/types';
import type { SelectChangeEvent } from '@mui/material';

type MessageLanguageSelectProps = {
	dataTest: string;
	value: string;
	onChange: (e: SelectChangeEvent<string>) => void;
	supportedLang?: readonly string[];
	variant?: 'standard' | 'outlined' | 'filled';
	selectedTemplate?: WhatsAppTemplateMessageResInterface;
	isMessageTextarea?: boolean;
};

export const MessageLanguageSelect: FC<MessageLanguageSelectProps> = ({
	dataTest,
	supportedLang,
	value,
	onChange,
	variant = 'outlined',
	selectedTemplate,
	isMessageTextarea,
}) => {
	const { t } = useTranslation();
	const { templates } = useTemplates();

	const isTemplateLanguageAvailable = (language: string) => {
		return templates?.find((template) => template.language === language);
	};

	// const sortedLanguages = useMemo(() => immutableSort(supportedLang), [supportedLang]);

	const sortedLanguages = useMemo(() => {
		const supportedLanguagesMap = supportedLang?.map((languageCode) => {
			return { code: languageCode, name: t(`languages.${languageCode}`) };
		});
		supportedLanguagesMap?.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		return supportedLanguagesMap;
	}, [supportedLang, t]);

	const whatsappSortedLanguages = useMemo(() => {
		const supportedLanguagesMap = whatsappSupportedLanguages?.map((languageCode) => {
			return { code: languageCode, name: t(`languages.${languageCode}`) };
		});
		supportedLanguagesMap?.sort((a, b) => {
			return a.name.localeCompare(b.name);
		});
		return supportedLanguagesMap;
	}, [t]);

	if (!supportedLang) return null;

	return (
		<Select
			data-test={dataTest}
			onChange={onChange}
			value={value}
			variant={variant}
			sx={
				isMessageTextarea
					? [
							{
								'.MuiInputBase-input': {
									paddingTop: '5px',
									paddingBottom: '5px',
									fontSize: '0.85rem',
								},
							},
					  ]
					: { margin: '5px 0' }
			}
			fullWidth
		>
			{selectedTemplate
				? whatsappSortedLanguages?.map((lang) => (
						<MenuItem
							data-test={'language-list-item'}
							data-value={convertToISO639LikeCountryCodes(lang.code)}
							key={lang.name}
							value={convertToISO639LikeCountryCodes(lang.code)}
							disabled={!isTemplateLanguageAvailable(lang.code)}
						>
							{t(`languages.${convertToISO639LikeCountryCodes(lang.code)}`)}
						</MenuItem>
				  ))
				: sortedLanguages?.map((lang) => (
						<MenuItem
							data-test={'language-list-item'}
							data-value={convertToISO639LikeCountryCodes(lang.code)}
							key={lang.name}
							value={lang.code}
						>
							{t(`languages.${lang.code}`)}
						</MenuItem>
				  ))}
		</Select>
	);
};
