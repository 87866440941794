import { StyledNotificationBadge } from './notification-badge.styles';

import type { FC } from 'react';

type NotificationBadgeProps = { count: number };
export const NotificationBadge: FC<NotificationBadgeProps> = ({ count }) => {
	return (
		<StyledNotificationBadge>{count > 99 ? `${99}+` : count}</StyledNotificationBadge>
	);
};
