import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSWRConfig } from 'swr';

import { ROUTES, useAuthContext, useLocalStorage } from '@heylog-app/frontend-lib/app';

import type { FC } from 'react';

export const LogoutPage: FC = () => {
	const { token, setAppToken } = useAuthContext();
	const { cache } = useSWRConfig();
	const [, , removeWorkspaceUserInvite] = useLocalStorage('workspaceUserInvite');
	const [, , removeConversationGuestInvite] = useLocalStorage('conversationGuestInvite');

	removeWorkspaceUserInvite();
	removeConversationGuestInvite();

	useEffect(() => {
		if (token) setAppToken(null);
		// as long as we use the default cache it's a JS Map, so we can "clear()" it
		// @ts-expect-error The types are wrong, as the function exists at runtime
		cache?.clear?.();
	}, [cache, token, setAppToken]);

	// render empty div until logging out is done - could be replaced with loading but will
	// only flash very shortly
	return token ? <div /> : <Navigate to={ROUTES.LOGIN.USER} />;
};
