import { useEffect, useState } from 'react';

import { isTranslatedAxiosError } from '../types';

import type { AxiosError } from 'axios';

// NOTE: errors from try/catch blocks are 'unknown'
type ErrorType = Error | AxiosError | unknown;

export const parseAxiosErrorMessage = (error: ErrorType, defaultMessage = ''): string => {
	if (isTranslatedAxiosError(error) && error.response?.data?.i18nMessage) {
		return error.response?.data?.i18nMessage;
	} else if (error instanceof Error) {
		return error?.message;
	} else {
		return defaultMessage;
	}
};

/** hook for handling api errors */
export const useErrorMessage = () => {
	const [error, setError] = useState<ErrorType>();
	const [errorMessage, setErrorMessage] = useState<string>();

	useEffect(() => {
		setErrorMessage(parseAxiosErrorMessage(error));
	}, [error]);
	return { error, setError, errorMessage };
};
