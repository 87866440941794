import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

type ErrorMessageProps = {
	errorMessage: string;
};

export const ErrorMessage: FC<ErrorMessageProps> = ({ errorMessage }) => {
	const { t } = useTranslation();

	return (
		<Box mt={1} mb={1}>
			<Alert severity="error">{t(errorMessage)}</Alert>
		</Box>
	);
};
