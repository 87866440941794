import type { FC } from 'react';

export const SvgFi: FC = () => (
	<svg
		width="20"
		height="20"
		xmlns="http://www.w3.org/2000/svg"
		id="flag-icons-fi"
		viewBox="0 0 512 512"
	>
		<path fill="#fff" d="M0 0h512v512H0z" />
		<path fill="#002f6c" d="M0 186.2h512v139.6H0z" />
		<path fill="#002f6c" d="M123.2 0h139.6v512H123.1z" />
	</svg>
);
