import type { PusherConnectionStatesType, Maybe } from '@heylog-app/shared/types';
import type { Channel, default as Pusher } from 'pusher-js';

export type PusherStateChangePayload = {
	current: PusherConnectionStatesType;
	previous: PusherConnectionStatesType;
};

export type PusherChannelsContextType = {
	channelsClient: Pusher;
	wsChannel: Maybe<Channel>;
};
