import styled, { css } from 'styled-components';

import { SIZE_SM, STYLE_1, STYLE_2 } from '@heylog-app/frontend-lib/app';

import type { OrderStatusIndicatorProps } from './order-status-indicator';
import type { PickStyleProps } from '@heylog-app/frontend-lib/app';

export const StyledOrderStatusIndicator = styled.li<
	PickStyleProps<OrderStatusIndicatorProps, 'isActive' | 'status'>
>`
	${STYLE_1};
	${SIZE_SM};
	color: ${({ theme }) => theme.palette.grey[600]};

	margin: 0.75rem 0;
	position: relative;

	&::marker {
		font-size: 1.5em;
	}

	${({ $isActive, $status }) =>
		$isActive &&
		css`
			color: ${({ theme }) => theme.palette.grey[900]};
			${STYLE_2};

			&::marker {
				color: ${({ theme }) => theme.palette.status[$status.toLowerCase()]};
			}
		`}

	&:not(:last-child)::after {
		content: '';
		position: absolute;
		top: 1.9em;
		left: -18px;
		width: 1px;
		height: 1em;
		background-color: ${({ theme }) => theme.palette.greyblue[205]};
	}
`;
