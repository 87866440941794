import { createContext, useMemo, useCallback } from 'react';
import jwtDecode from 'jwt-decode';

import { LOCAL_STORAGE_TOKEN } from '../util';
import { useLocalStorage } from '../hooks';

import type { FC, ReactElement } from 'react';
import type { UserAuthContext } from '../types';
import type { Nullable, TypeDecodedToken } from '@heylog-app/shared/types';

export const AuthContext = createContext<UserAuthContext | null>(null);

export const AuthContextProvider: FC<{ children?: ReactElement }> = ({ children }) => {
	const [token, setToken] = useLocalStorage<Nullable<string>>(LOCAL_STORAGE_TOKEN, null);

	const setAppToken = useCallback(
		(token: Nullable<string>) => setToken(token),
		[setToken],
	);

	const value = useMemo(
		() => ({
			token,
			setAppToken,
			decoded: token ? jwtDecode<TypeDecodedToken>(token) : null,
		}),
		[token, setAppToken],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
