import Icon from '@mdi/react';
import { mdiAccount } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { getNameInitials, useUser } from '@heylog-app/frontend-lib/app';

import {
	StyledAvatar,
	StyledProfileInfo,
	StyledProfileWrapper,
	StyledTeamInfo,
} from './profile-info.styles';

import type { FC } from 'react';

type ProfileInfoProps = {
	workspaceName: string | undefined;
	height: number;
	width: number;
};

export const ProfileInfo: FC<ProfileInfoProps> = ({
	workspaceName = '',
	height,
	width,
}) => {
	const { t } = useTranslation();
	const { user } = useUser();

	return (
		<>
			<StyledAvatar sx={{ width, height, fontSize: '2rem' }} src="">
				{getNameInitials(user?.firstName, user?.lastName) || (
					<Icon path={mdiAccount} size={'0.5rem'} />
				)}
			</StyledAvatar>
			<StyledProfileWrapper>
				<StyledProfileInfo>{user?.firstName + ' ' + user?.lastName}</StyledProfileInfo>
				<StyledTeamInfo>
					<span style={{ color: 'gray' }}>{t('settings.labels.team')}</span>:{' '}
					{workspaceName}
				</StyledTeamInfo>
			</StyledProfileWrapper>
		</>
	);
};
