import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { colors, STYLE_3 } from '@heylog-app/frontend-lib/app';

export const LoginFormWrapper = styled.form`
	max-width: 100%;
`;

export const StyledTitle = styled.h2`
	${STYLE_3}
`;

export const StyledNavLink = styled(NavLink)`
	display: block;
	margin-top: 24px;
	text-align: center;
	text-decoration: none;
	color: ${colors.primary.main};

	:hover {
		color: ${colors.primary.lighter};
	}
`;
