import { Menu } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { mdiAccount, mdiCog, mdiLogout, mdiSwapHorizontal } from '@mdi/js';
import Icon from '@mdi/react';

import { Actions } from '@heylog-app/shared/types';

import {
	StyledMenuButton,
	StyledUserMenuWrapper,
	StyledMenuItem,
	StyledWorkspaceLabel,
	StyledButtonTitle,
	StyledMenuIcon,
	StyledSwitchButton,
	StyledCurrentWorkspaceChip,
	StyledGuestChip,
	StyledAvatar,
} from './user-menu.styles';
import { NotificationBellButton } from './notification-bell-button';
import { usePlugins, usePusherBeamsContext, useUser } from '../../../hooks';
import { getNameInitials, ROUTES } from '../../../util';
import { Can } from '../../can';

import type { FC } from 'react';

export const UserMenu: FC = () => {
	const { beamsClient } = usePusherBeamsContext();
	const { user } = useUser();
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '' } = params;
	const { FrontendV2Plugin } = usePlugins(workspaceId);

	const guestWorkspaceIds = [
		...new Set(user?.guestConversations?.map((conv) => conv.workspaceId)),
	];

	const [workspaceName, setWorkspaceName] = useState<string | undefined>(undefined);

	const [isOpen, setIsOpen] = useState(false);
	const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = useState(false);

	const buttonRef = useRef<HTMLButtonElement>(null);
	const switchWsButtonRef = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();

	const handleLogout = useCallback(() => {
		beamsClient?.stop().catch(console.error);
		localStorage.removeItem('pusherTransportTLS');
		localStorage.removeItem('pusherTransportNonTLS');
		navigate(ROUTES.LOGOUT);
	}, [navigate, beamsClient]);

	const closeMenu = useCallback(() => {
		setIsOpen(false);
	}, []);

	const toggleMenu = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const closeSwitchMenu = useCallback(() => {
		setSwitchWorkspaceOpen(false);
		setIsOpen(false);
	}, []);

	const handleSwitchWorkspaceClick = () => {
		setSwitchWorkspaceOpen(!switchWorkspaceOpen);
	};

	const handleSettingsClick = useCallback(() => {
		setIsOpen(false);
		navigate(
			generatePath(ROUTES.SETTINGS.PROFILE, {
				...params,
			}),
		);
	}, [navigate, params]);

	const handleWorkspaceClick = (id: number) => {
		navigate(
			generatePath(ROUTES.HOME, {
				workspaceId: id.toString(),
			}),
		);
	};

	useEffect(() => {
		closeSwitchMenu();
	}, [closeSwitchMenu, workspaceId]);

	useEffect(() => {
		if (user) {
			setWorkspaceName(
				user?.workspaces?.find((w) => w.id === Number(workspaceId))?.name as string,
			);
		}
	}, [workspaceId, user, setWorkspaceName]);

	return (
		<StyledUserMenuWrapper>
			<NotificationBellButton />
			<StyledMenuButton
				isV2={FrontendV2Plugin}
				aria-controls="user-menu"
				data-test="user-menu"
				aria-haspopup="true"
				aria-expanded={isOpen ? 'true' : undefined}
				ref={buttonRef}
				onClick={toggleMenu}
			>
				<StyledButtonTitle>
					<StyledWorkspaceLabel isV2={FrontendV2Plugin}>
						{user?.firstName} {user?.lastName}
					</StyledWorkspaceLabel>
					<Can not I={Actions.MANAGE} a="Workspace">
						{() => (
							<StyledGuestChip
								label={`${t(`navigation.chip.${user?.role}`)}`}
								variant="outlined"
								data-test="guest-chip"
							/>
						)}
					</Can>
					{workspaceName && (
						<StyledWorkspaceLabel isV2={FrontendV2Plugin} size="XS">
							{workspaceName}
						</StyledWorkspaceLabel>
					)}
				</StyledButtonTitle>
			</StyledMenuButton>
			<Menu
				id="user-menu"
				open={isOpen}
				anchorEl={buttonRef?.current}
				onClose={closeMenu}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
			>
				<StyledSwitchButton
					aria-controls="switch-workspace-menu"
					data-test="switch-workspace-menu"
					aria-haspopup="true"
					aria-expanded={switchWorkspaceOpen ? 'true' : undefined}
					ref={switchWsButtonRef}
					onClick={handleSwitchWorkspaceClick}
				>
					<StyledMenuIcon path={mdiSwapHorizontal} size={'1rem'} />
					{t('navigation.menu.changeTeam')}
				</StyledSwitchButton>
				<Menu
					id="switch-workspace-menu"
					open={switchWorkspaceOpen}
					anchorEl={switchWsButtonRef?.current}
					onClose={closeSwitchMenu}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
				>
					<div>
						{user?.workspaces?.map((workspace, index) => {
							return (
								<StyledMenuItem
									isV2={FrontendV2Plugin}
									key={index}
									onClick={() => handleWorkspaceClick(workspace.id)}
								>
									{workspace.name}
									{workspace.id === parseInt(workspaceId || '') && (
										<StyledCurrentWorkspaceChip
											label={`${t('navigation.current')}`}
											variant="outlined"
										/>
									)}
								</StyledMenuItem>
							);
						})}
						{guestWorkspaceIds.map((guestWorkspaceId, index) => {
							return (
								<StyledMenuItem
									isV2={FrontendV2Plugin}
									key={index}
									onClick={() => handleWorkspaceClick(guestWorkspaceId)}
								>
									{`Team ${guestWorkspaceId}`}
									{guestWorkspaceId === parseInt(workspaceId || '') && (
										<StyledCurrentWorkspaceChip
											label={`${t('navigation.current')}`}
											variant="outlined"
										/>
									)}
								</StyledMenuItem>
							);
						})}
					</div>
				</Menu>

				<StyledMenuItem
					isV2={FrontendV2Plugin}
					onClick={handleSettingsClick}
					data-test="user-menu-settings-button"
				>
					<StyledMenuIcon path={mdiCog} size={'1rem'} />
					{t('navigation.menu.settings')}
				</StyledMenuItem>
				<StyledMenuItem
					isV2={FrontendV2Plugin}
					onClick={handleLogout}
					data-test="logout-button"
				>
					<StyledMenuIcon path={mdiLogout} size={'1rem'} />
					{t('navigation.menu.logout')}
				</StyledMenuItem>
			</Menu>

			<StyledAvatar src="" sx={{ fontSize: '1rem' }}>
				{getNameInitials(user?.firstName, user?.lastName) || (
					<Icon path={mdiAccount} size={'1rem'} />
				)}
			</StyledAvatar>
		</StyledUserMenuWrapper>
	);
};
