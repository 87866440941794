import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const WorkspaceEventsEnum = mkenum({
	CONTACT_UPDATED: 'contact:updated',
	MESSAGE_SENT: 'message:sent',
	MESSAGE_RECEIVED: 'message:received',
	MESSAGE_STATUS_UPDATED: 'message:status:updated',
	MESSAGE_UPDATED: 'message:updated',
	ENTITY_EVENT_ADDED: 'entity-event:added',
});

export type WorkspaceEventsType = TypeFromPEnum<typeof WorkspaceEventsEnum>;
