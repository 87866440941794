// array to map language names on contact join page for values for both deepl and google, only display purposes
export const languages = [
	{ label: 'Deutsch', value: 'de' },
	{ label: 'English', value: 'en' },
	{ label: 'Polski', value: 'pl' },
	{ label: 'Čeština', value: 'cs' },
	{ label: 'Русский', value: 'ru' },
	{ label: 'Română', value: 'ro' },
	{ label: 'Magyar', value: 'hu' },
	{ label: `Slovensky`, value: 'sk' }, // should be "Slovenský" but Styled Components is not handling it correctly
	{ label: 'Slovenščina', value: 'sl' },
	{ label: 'Latviski', value: 'lv' },
	{ label: 'Lietuvių', value: 'lt' },
	{ label: 'Türk', value: 'tr' },
	{ label: 'Български', value: 'bg' },
	{ label: 'Español', value: 'es' },
	{ label: 'Português', value: 'pt-pt' },
	{ label: 'Suomalainen', value: 'fi' },
	{ label: 'Italiano', value: 'it' },
	{ label: 'Eesti', value: 'et' },
	{ label: 'France', value: 'fr' },
	{ label: 'Україна', value: 'uk' },
	{ label: 'Босна и Херцеговина', value: 'bs' },
	{ label: 'Србија', value: 'sr' },
	{ label: 'Hrvatska', value: 'hr' },
	{ label: 'Северна Македонија', value: 'mk' },
];

export const uiLanguages = ['en', 'de'];
