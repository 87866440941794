import { Layout } from '../layout';
import { StyledSidebarLayout } from './sidebar-layout.styles';

import type { FC, ReactNode } from 'react';

type SidebarLayoutProps = {
	children: ReactNode;
	overflowAuto?: boolean;
};

/** layout component for pages with sidebar */
export const SidebarLayout: FC<SidebarLayoutProps> = ({
	children,
	overflowAuto = false,
}) => {
	return (
		<Layout>
			<StyledSidebarLayout container $overflowAuto={overflowAuto}>
				{children}
			</StyledSidebarLayout>
		</Layout>
	);
};
