import styled from 'styled-components';

import { STYLE_H2 } from '../../styles';

export const StyledDialogContent = styled.div`
	padding: 32px;
	min-width: 600px;
`;

export const StyledDialogHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		position: static;
		padding: 0;
	}
`;
export const StyledDialogTitle = styled.h2`
	margin: 0;
	${STYLE_H2}
`;

export const StyledDialogSubtitle = styled.div`
	margin-top: 4px;
	margin-bottom: 16px;
	line-height: 28px;
	opacity: 0.6;
`;
