import React, { useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { mdiTriangleDown } from '@mdi/js';
import Icon from '@mdi/react';
import CheckIcon from '@mui/icons-material/Check';

import { colors } from '../../../styles/theme/colors';
import { StyledButtonsScope, StyledButton } from './buttonV2-dropdown.styles';

import type { ButtonProps } from '@mui/material';
import type { FC, PropsWithChildren } from 'react';

type ButtonV2VariantType = 'dark' | 'light' | 'warning';
export type ButtonV2DropdownProps = ButtonProps & {
	$variant: ButtonV2VariantType;
	$padding?: string;
	$typography?: 'T2' | 'T3';

	handleFuncClick: (id: number) => void;
	options: { label: string; value: number }[];
	isLoading?: boolean;
};

export const ButtonV2Dropdown: FC<PropsWithChildren<ButtonV2DropdownProps>> = ({
	children,
	options = [],
	isLoading,
	handleFuncClick,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [selectedOption, setSelectedOption] = useState<string | number>(0);

	const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget as HTMLElement);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleButtonClick = () => {
		handleFuncClick(selectedOption as number);
	};

	const handleOptionSelect = (option: string | number) => {
		setSelectedOption(option);
		setAnchorEl(null);
	};

	return (
		<div>
			<StyledButtonsScope>
				<StyledButton
					variant="contained"
					onClick={handleButtonClick}
					aria-controls="select-menu"
					aria-haspopup="true"
					disabled={!selectedOption}
					{...props}
					style={{ borderRadius: '4px 0 0 4px' }}
				>
					{children}
				</StyledButton>
				<StyledButton
					variant="contained"
					onClick={handleClick}
					{...{ $variant: props.$variant }}
					style={{
						borderLeft: '1px gray solid',
						height: '45px',
						borderRadius: '0 4px 4px 0',
					}}
				>
					<Icon style={{ color: 'grey' }} path={mdiTriangleDown} size={0.4} />
				</StyledButton>
			</StyledButtonsScope>
			<Menu
				id="select-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				PaperProps={{
					style: {
						maxHeight: '300px',
						width: '250px',
					},
				}}
			>
				{options.map((option, index) => (
					<MenuItem key={index} onClick={() => handleOptionSelect(option.value)}>
						{option.label}
						{option.value === selectedOption && (
							<CheckIcon style={{ marginLeft: 'auto', color: colors.primary.main }} />
						)}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
