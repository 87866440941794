import { Tooltip } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiBellOutline, mdiCircleSmall } from '@mdi/js';
import Icon from '@mdi/react';
import { useParams } from 'react-router-dom';

import {
	StyledButton,
	StyledButtonV2,
	StyledDotIcon,
} from './notification-bell-button.styles';
import { BrowserNotificationsContext } from '../../../providers/browser-notifications-provider';
import { IconBell } from '../../icons/icon-bell';
import { IconBellCancel } from '../../icons/icon-bell-cancel';
import { colors } from '../../../styles';
import { usePlugins } from '../../../hooks';

export const NotificationBellButton = () => {
	const { workspaceId = '' } = useParams();
	const { t } = useTranslation();
	const { FrontendV2Plugin } = usePlugins(workspaceId);

	const [browserNotificationsStatus, setBrowserNotificationsStatus] = useContext(
		BrowserNotificationsContext,
	);

	function toggleStatus() {
		setBrowserNotificationsStatus(!browserNotificationsStatus);
	}

	return (
		<Tooltip
			title={
				browserNotificationsStatus
					? t('browserNotifications.status.on')
					: t('browserNotifications.status.off')
			}
		>
			{FrontendV2Plugin ? (
				<StyledButtonV2 onClick={toggleStatus}>
					<Icon color="#fff" path={mdiBellOutline} size={1} />
					<StyledDotIcon
						data-test="unread-contacts-dot"
						path={mdiCircleSmall}
						size={2}
						color={browserNotificationsStatus ? colors.success.main : colors.error.main}
					/>
				</StyledButtonV2>
			) : (
				<StyledButton
					status={browserNotificationsStatus ? 'true' : 'false'}
					onClick={toggleStatus}
					data-test="browser-notifications-toggle-button"
				>
					{browserNotificationsStatus ? <IconBell /> : <IconBellCancel />}
				</StyledButton>
			)}
		</Tooltip>
	);
};
