import { Alert, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { SnackbarProps } from '@mui/material';
import type { FC } from 'react';

type ErrorSnackbarProps = SnackbarProps & {
	message?: string;
};

export const ErrorSnackbar: FC<ErrorSnackbarProps> = ({ message, ...props }) => {
	const { t } = useTranslation();
	return (
		<Snackbar
			autoHideDuration={3000}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			{...props}
		>
			<Alert severity="error" variant="filled" elevation={6}>
				{message ? t(message) : t('errorBoundary.generic')}
				<br />
				{t('errorBoundary.reload')}
			</Alert>
		</Snackbar>
	);
};
