import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { generatePath, useNavigate } from 'react-router-dom';

import { StyledMessageInputWrapper } from './message-input.styles';
import { ROUTES } from '../../../util';
import { StyledOrderNotice } from './order-notice.styles';

import type {
	FullContactResInterface,
	OrderResInterface,
} from '@heylog-app/shared/types';

export const OrderNotice = ({
	order,
	contact,
}: {
	contact: FullContactResInterface;
	order: OrderResInterface;
}) => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const onNavigateToOrder = () => {
		navigate(
			generatePath(ROUTES.ORDERS.CHAT, {
				conversationId: contact?.conversations[0]?.id.toString(),
				workspaceId: order?.workspaceId?.toString(),
				orderId: order.id.toString(),
				contactId: contact.id.toString(),
			}),
		);
	};

	return (
		<StyledMessageInputWrapper data-test="order-notice">
			<StyledOrderNotice>
				<span>
					{t('chats.orderReadOnlyMode', {
						name: contact.firstName,
						refNumber: order.refNumber,
					})}
				</span>
				<Button
					variant="contained"
					onClick={onNavigateToOrder}
					data-test="open-order-button"
				>
					{t('chats.orderReadOnlyModeCta', { refNumber: order.refNumber })}
				</Button>
			</StyledOrderNotice>
		</StyledMessageInputWrapper>
	);
};
