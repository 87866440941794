import { useMemo } from 'react';

import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { findLast } from '../util/find-last';

import type {
	UserOrderStatus,
	EntityEventResType,
	OrderResInterface,
} from '@heylog-app/shared/types';

const byOrderStatusChange = (status: UserOrderStatus) => (event: EntityEventResType) =>
	event.eventType === 'ORDER_STATUS_CHANGED' && event.eventPayload.newStatus === status;

const byContactOrderAssignmentChange =
	(orderId?: number) => (event: EntityEventResType) =>
		event.eventType === 'CONTACT_ORDER_ASSIGNMENT_CHANGED' &&
		event.eventPayload.newStatus === 'CREATED' &&
		orderId &&
		event.eventPayload.orderId === orderId;

/** get start end end of order */
export const useOrderActiveInterval = (
	events?: EntityEventResType[],
	order?: OrderResInterface,
) => {
	const isOrderClosed =
		order?.currentStatus === UserOrderStatusEnum.DELIVERED ||
		order?.currentStatus === UserOrderStatusEnum.ARCHIVED;
	const created = useMemo(() => {
		return events?.find(byContactOrderAssignmentChange(order?.id))?.createdAt || null;
	}, [events, order]);

	const archived = useMemo(() => {
		return (
			findLast(events, byOrderStatusChange(UserOrderStatusEnum.ARCHIVED))?.createdAt ||
			null
		);
	}, [events]);

	const completed = useMemo(() => {
		return (
			findLast(events, byOrderStatusChange(UserOrderStatusEnum.DELIVERED))?.createdAt ||
			null
		);
	}, [events]);

	return events ? [created, isOrderClosed ? archived ?? completed : null] : undefined;
};
