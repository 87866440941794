import { Avatar, Box, Card, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useRef, type FC, useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	InputV2,
	TabsGroupV2,
	colors,
	getNameInitials,
	useConversationsV2,
	useUserToUserMessageActions,
	useUserToUserMessages,
} from '@heylog-app/frontend-lib/app';
import { ConversationTypeEnum } from '@heylog-app/shared/types';

import { StyledTitle } from './comments.styles';

import type {
	FullMessageResInterface,
	MessageUserToUserPartialResInterface,
	ConversationType,
} from '@heylog-app/shared/types';

type FormData = {
	comment: string;
};

const internalTab = {
	title: 'shipment.internal',
	value: ConversationTypeEnum.HEYLOG_INTERNAL as ConversationType,
};
const externalTab = {
	title: 'shipment.customer',
	value: ConversationTypeEnum.HEYLOG_EXTERNAL as ConversationType,
};

const tabs = [internalTab, externalTab];

export const Comments: FC = () => {
	const { t } = useTranslation();
	const [type, setType] = useState<ConversationType>(internalTab.value);

	const { shipmentId } = useParams();

	const { handleSubmit, control, register, reset } = useForm<FormData>({
		defaultValues: {
			comment: '',
		},
	});

	const { conversations } = useConversationsV2([
		ConversationTypeEnum.HEYLOG_INTERNAL,
		ConversationTypeEnum.HEYLOG_EXTERNAL,
	]);
	const internalConversation = conversations?.find(
		(conversation) => conversation.type === ConversationTypeEnum.HEYLOG_INTERNAL,
	);
	const externalConversation = conversations?.find(
		(conversation) => conversation.type === ConversationTypeEnum.HEYLOG_EXTERNAL,
	);

	const { createUserToUserMessage: createUserToUserMessageInternal } =
		useUserToUserMessageActions(internalConversation?.id.toString() || '');
	const { createUserToUserMessage: createUserToUserMessageExternal } =
		useUserToUserMessageActions(externalConversation?.id.toString() || '');

	const onSubmitCommentForm = useCallback(
		async (data: FormData) => {
			if (data.comment.trim() === '') {
				return;
			}
			const createUserToUserMessage =
				type === ConversationTypeEnum.HEYLOG_INTERNAL
					? createUserToUserMessageInternal
					: createUserToUserMessageExternal;

			await createUserToUserMessage(
				{
					orderId: shipmentId ? parseInt(shipmentId) : 0,
					text: data.comment,
				},
				type,
			);

			reset({ comment: '' });
		},
		[
			createUserToUserMessageInternal,
			createUserToUserMessageExternal,
			reset,
			shipmentId,
			type,
		],
	);

	const internalMessages = useUserToUserMessages(
		internalConversation?.id.toString(),
	).messages;

	const externalMessages = useUserToUserMessages(
		externalConversation?.id.toString(),
	).messages;

	const messageToComment = (message: FullMessageResInterface) => {
		const transportMessage =
			message.transportMessage as MessageUserToUserPartialResInterface;
		return {
			user: transportMessage.sender,
			comment: transportMessage.text,
		};
	};

	const comments = useMemo(() => {
		if (type === ConversationTypeEnum.HEYLOG_INTERNAL) {
			return internalMessages ? internalMessages.map(messageToComment) : [];
		} else {
			return externalMessages ? externalMessages.map(messageToComment) : [];
		}
	}, [externalMessages, internalMessages, type]);

	// scroll to bottom of comments if its overflowing
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		setTimeout(() => {
			scrollToBottom();
		}, 200);
	}, [comments]);
	// scroll to bottom of comments if its overflowing

	return (
		<Card
			sx={{
				height: '100%',
				boxShadow: 'none',
				border: '1px solid #e1e4ea',
			}}
		>
			<Stack gap={1} justifyContent="space-between" sx={{ height: '100%' }}>
				<Stack
					gap={2}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					padding={1}
					sx={{ borderBottom: `1px solid ${colors.grey[300]}` }}
				>
					<StyledTitle>{t('shipment.comments')}</StyledTitle>
					<Box>
						<TabsGroupV2 tabs={tabs} onChange={setType} value={type} />
					</Box>
				</Stack>
				<Stack marginTop="auto" padding={1} gap={1} sx={{ overflowY: 'scroll' }}>
					{comments.map((comment, index) => {
						return (
							<Stack
								key={index}
								direction="row"
								alignItems="center"
								gap={1}
								sx={{ wordBreak: 'break-all', fontSize: '14px' }}
							>
								<Avatar sx={{ fontSize: '10px', height: '32px', width: '32px' }}>
									{getNameInitials(comment.user.firstName, comment.user.lastName)}
								</Avatar>
								<span>{comment.comment}</span>
							</Stack>
						);
					})}
					<div ref={messagesEndRef} />
				</Stack>
				<Box borderTop={`1px solid ${colors.grey[300]}`}>
					<form onSubmit={handleSubmit(onSubmitCommentForm)} noValidate>
						<Controller
							control={control}
							name="comment"
							render={({ field }) => (
								<InputV2
									id="standard-basic"
									variant="standard"
									placeholder={t('shipment.yourCommentHere')}
									fullWidth
									{...register('comment')}
									{...field}
								/>
							)}
						/>
					</form>
				</Box>
			</Stack>
		</Card>
	);
};
