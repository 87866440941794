type HasCreatedAt = { createdAt: Date };

export const sortByCreatedAtAsc = (
	{ createdAt: A }: HasCreatedAt,
	{ createdAt: B }: HasCreatedAt,
) => new Date(A).getTime() - new Date(B).getTime();

export const sortByCreatedAtDesc = (
	{ createdAt: A }: HasCreatedAt,
	{ createdAt: B }: HasCreatedAt,
) => new Date(B).getTime() - new Date(A).getTime();
