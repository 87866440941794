import { useTranslation } from 'react-i18next';

import {
	StyledCopyright,
	StyledFooterLinks,
	StyledPageFooter,
} from './page-footer.styles';

import type { FC } from 'react';

export const PageFooter: FC = () => {
	const { t } = useTranslation();
	return (
		<StyledPageFooter>
			<StyledFooterLinks>
				<li>
					<a href="https://www.heylog.com/agb" target="_blank" rel="noreferrer">
						{t('join.termsOfService')}
					</a>
				</li>

				<li>
					<a href="mailto:support@heylog.com">{t('join.reportProblem')}</a>
				</li>

				<li>
					<a href="https://www.heylog.com/impressum" target="_blank" rel="noreferrer">
						{t('join.imprint')}
					</a>
				</li>
			</StyledFooterLinks>
			<StyledCopyright>&copy; Heylog GmbH {new Date().getFullYear()}</StyledCopyright>
		</StyledPageFooter>
	);
};
