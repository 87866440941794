import { useContacts, useUnread } from '.';

import type { FullContactResInterface } from '@heylog-app/shared/types';

export const useOrdersUnread = () => {
	const { unreadMap } = useUnread();
	const { contacts } = useContacts({ orderId: '' });

	if (unreadMap) {
		const contactsWithOrders = contacts
			?.filter((contact: FullContactResInterface) => contact.orderConnections?.length)
			.map((contact) => contact.id);

		const ordersUnreadMapEntries = Object.entries(unreadMap).filter(([contactId]) => {
			return contactsWithOrders?.includes(Number(contactId));
		});

		const ordersUnread = Object.fromEntries(ordersUnreadMapEntries);
		return { ordersUnread };
	}

	return { ordersUnread: {} };
};
