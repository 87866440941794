import { Container, FormControlLabel } from '@mui/material';
import styled from 'styled-components';

export const StyledFormControlLabel = styled(FormControlLabel)`
	background-color: ${({ theme }) => theme.palette.grey[200]};
	margin-bottom: 8px;
	margin-right: 0;
	margin-left: 0;
	padding: 8px;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.palette.grey[300]};

	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const DrawerTitle = styled.div`
	padding: 16px;
	font-weight: 700;
	border-bottom: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const DrawerContent = styled(Container)`
	height: 100%;
	padding-top: 20px;
	padding-bottom: 20px;
	display: flex;
	flex-direction: column;
	overflow: 'scroll';
`;

export const DrawerFooter = styled(Container)`
	padding-top: 16px;
	overflow: 'unset';
	padding-bottom: 16px;
	border-top: 1px solid ${({ theme }) => theme.palette.grey[300]};
`;

export const DraverLayout = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const EmptyDrawer = styled.div`
	margin-top: 40%;
	text-align: center;

	& > h4 {
		margin-bottom: 8px;
	}
	& > p {
		margin-top: 0;
	}
`;
