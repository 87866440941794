/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { Model } from 'survey-core';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
	submitFleetCheckAPI,
	uploadContactFile,
	useApiClientContext,
} from '@heylog-app/frontend-lib/app';
import { getSurveyLanguageCode } from '@heylog-app/shared/util';

import type { SelectChangeEvent } from '@mui/material';
import type { SetStateAction } from 'react';
import type {
	CreateFleetCheckItemReqInterface,
	CreateFleetCheckReqInterface,
	FleetCheckItemType,
	SupportedLanguagesType,
} from '@heylog-app/shared/types';

export const useVchk = ({
	tractorSurveyJson,
	themeJson,
	trailerSurveyJson,
	type,
}: {
	tractorSurveyJson: any;
	themeJson: any;
	trailerSurveyJson: any;
	type: 'FLAT_TIRE' | 'VEHICLE';
}) => {
	const { apiClient } = useApiClientContext();
	const { t } = useTranslation();
	const currentLanguage = i18next.language;

	const {
		organizationId = '',
		whatsappTransportNumber = '',
		workspaceId = '',
	} = useParams();

	const [isRunning, setIsRunning] = useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isError, setIsError] = useState(false);
	const [showIntro, setShowIntro] = useState(true);
	const [surveyType, setSurveyType] = useState('Tractor');

	const [tractorSurvey, setTractorSurvey] = useState(() => {
		// conditionally change survey JSON based on user selection
		const s = new Model(tractorSurveyJson);
		s.applyTheme(themeJson);
		return s;
	});

	const [trailerSurvey, setTrailerSurvey] = useState(() => {
		// conditionally change survey JSON based on user selection
		const s = new Model(trailerSurveyJson);
		s.applyTheme(themeJson);
		return s;
	});

	const choosenSurvey = surveyType === 'Tractor' ? tractorSurvey : trailerSurvey;

	const [activeStep, setActiveStep] = useState(choosenSurvey.currentPageNo);

	useEffect(() => {
		const handleCurrentPageChanged = (
			_: unknown,
			options: { newCurrentPage: { visibleIndex: SetStateAction<number> } },
		) => {
			setActiveStep(options.newCurrentPage.visibleIndex);
		};

		const tempFileStorage: {
			[questionName: string]: File[];
		} = {};

		const handleComplete = async (sender: { data: unknown }) => {
			setIsSubmitting(true);

			const dataToSubmit: CreateFleetCheckReqInterface = {
				type: type,
				items: [], // Ensure the correct type
			};

			const questionKeys = Object.keys(sender.data as object) as string[];

			for (const key of questionKeys) {
				const surveyItemType = key.includes('photo') ? 'URLS' : 'STRING';
				let answerString: string[] = [];

				if (surveyItemType === 'STRING') {
					answerString = [(sender.data as Record<string, string | undefined>)[key] ?? ''];
				}

				if (surveyItemType === 'URLS') {
					const files = tempFileStorage[key];

					if (files && files.length > 0) {
						const uploadPromises: Promise<void>[] = [];

						for (const file of files) {
							const formData = new FormData();
							formData.append('file', file);

							try {
								const response = await uploadContactFile(apiClient, {
									body: formData,
									organizationId,
								});
								answerString.push(response.data);
							} catch (error) {
								setIsError(true);
								setIsSubmitting(false);
								console.error('error uploading file', error);
							}
						}

						await Promise.all(uploadPromises);
					}
				}

				const surveyItem: CreateFleetCheckItemReqInterface = {
					type: surveyItemType as FleetCheckItemType,
					slug: key,
					question: t(`vchk.surveyQuestions.${key}`),
					answers: answerString,
				};

				dataToSubmit.items.push(surveyItem);
			}

			try {
				await submitFleetCheckAPI(apiClient, dataToSubmit, organizationId, workspaceId);
				setIsCompleted(true);
				setIsSubmitting(false);
			} catch (error) {
				setIsError(true);
				setIsSubmitting(false);
				console.error(error);
			}
		};

		choosenSurvey.onUploadFiles.add((_, options) => {
			const renamedFiles: File[] = [];

			options.files.forEach((file) => {
				const fileExtension = file.name.split('.').pop();
				const filenameWithoutExtension = file.name.replace(/\.[^/.]+$/, '');
				const uuid = uuidv4();
				const modifiedFileName = `${filenameWithoutExtension}-${uuid}.${fileExtension}`;
				// Create a new File object with the modified filename
				const modifiedFile = new File([file], modifiedFileName, {
					type: file.type,
				});
				renamedFiles.push(modifiedFile);
			});

			// Add files to the temporary storage
			if (tempFileStorage[options.name] !== undefined) {
				tempFileStorage[options.name]?.push(...renamedFiles);
			} else {
				tempFileStorage[options.name] = renamedFiles;
			}

			// Load file previews
			const content: {
				name: string;
				type: string;
				content: string | ArrayBuffer | null;
				file: File;
			}[] = [];
			// add to files preview
			renamedFiles.forEach((file) => {
				const fileReader = new FileReader();
				fileReader.onload = () => {
					content.push({
						name: file.name,
						type: file.type,
						content: fileReader.result,
						file: file,
					});
					if (content.length === renamedFiles.length) {
						// Return a file for preview as a { file, content } object
						options.callback(
							'success',
							content.map((fileContent) => {
								return {
									file: fileContent.file,
									content: fileContent.content,
								};
							}),
						);
					}
				};
				fileReader.readAsDataURL(file);
			});
		});

		choosenSurvey.onClearFiles.add((_, options) => {
			// Empty the temporary file storage if "Clear All" is clicked
			if (options.fileName === null) {
				tempFileStorage[options.name] = [];
				options.callback('success');
				return;
			}

			// Remove an individual file
			const tempFiles = tempFileStorage[options.name];
			if (tempFiles) {
				const fileInfoToRemove = tempFiles.filter(
					(file) => file.name === options.fileName,
				)[0];
				if (fileInfoToRemove) {
					const index = tempFiles.indexOf(fileInfoToRemove);
					tempFiles.splice(index, 1);
				}
			}
			options.callback('success');
		});

		choosenSurvey.onStarted.add(() => {
			setIsRunning(true);
		});
		choosenSurvey.onComplete.add(() => {
			setIsRunning(false);
		});

		choosenSurvey.onCurrentPageChanged.add(handleCurrentPageChanged);
		choosenSurvey.onComplete.add(handleComplete);

		return () => {
			choosenSurvey.onCurrentPageChanged.remove(handleCurrentPageChanged);
			choosenSurvey.onComplete.remove(handleComplete);
			choosenSurvey.onUploadFiles.clear();
			choosenSurvey.onClearFiles.clear();
		};
	}, [apiClient, choosenSurvey, organizationId, t, type, workspaceId]);

	useEffect(() => {
		choosenSurvey.locale = getSurveyLanguageCode(
			currentLanguage as SupportedLanguagesType,
		);
	}, [currentLanguage, choosenSurvey]);

	const handleBack = () => {
		choosenSurvey.prevPage();
	};

	const handleNext = () => {
		choosenSurvey.nextPage();
	};

	const handleComplete = () => {
		choosenSurvey.completeLastPage();
	};

	const handleStart = (e: SelectChangeEvent<unknown>) => {
		setSurveyType(e.target.value as string);
		setIsRunning(true);

		setShowIntro(false);
	};

	const handleClose = () => {
		window.location.href = `https://wa.me/${whatsappTransportNumber}`;
	};

	const handleRestart = () => {
		// Reset the state to their initial states
		choosenSurvey.clear();

		// Reset survey states in order to avoid mistakes with unselectable radio buttons //
		setTractorSurvey(() => {
			const s = new Model(tractorSurveyJson);
			s.applyTheme(themeJson);
			return s;
		});
		setTrailerSurvey(() => {
			const s = new Model(trailerSurveyJson);
			s.applyTheme(themeJson);
			return s;
		});
		// Reset survey states in order to avoid mistakes with unselectable radio buttons //

		setIsRunning(true);
		setIsError(false);
		setIsCompleted(false);
		setIsSubmitting(false);
		setShowIntro(true);
		setActiveStep(0);
	};

	return {
		isRunning,
		isCompleted,
		isError,
		showIntro,
		activeStep,
		choosenSurvey,
		isSubmitting,
		handleBack,
		handleNext,
		handleComplete,
		handleStart,
		handleClose,
		handleRestart,
	};
};
