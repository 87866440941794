import type {
	CreateFleetCheckReqInterface,
	CreateFleetCheckRequestReqInterface,
} from '@heylog-app/shared/types';
import type { AxiosInstance } from 'axios';

export const createFleetCheckAPI = async (
	apiClient: AxiosInstance,
	data: CreateFleetCheckRequestReqInterface,
	organizationId: number,
	workspaceId: string,
) => {
	const fleetCheckRequestsKey = `organizations/${organizationId}/workspaces/${workspaceId}/fleet-check-requests`;
	return apiClient.post(fleetCheckRequestsKey, data);
};

export const submitFleetCheckAPI = async (
	apiClient: AxiosInstance,
	data: CreateFleetCheckReqInterface,
	organizationId: string,
	workspaceId: string,
) => {
	const url = `organizations/${organizationId}/workspaces/${workspaceId}/fleet-checks`;

	return apiClient.post(url, data);
};
