import type { AxiosInstance } from 'axios';
import type { CreateMessageOutgoingEmailReqInterface } from '@heylog-app/shared/types';

export const sendOutgoingEmailAPI = async (
	apiClient: AxiosInstance,
	data: CreateMessageOutgoingEmailReqInterface,
	workspaceId: number,
) => {
	return apiClient.post(
		`/workspaces/${workspaceId}/conversations-v2/outgoing-emails`,
		data,
	);
};
