import type { Nullable } from '@heylog-app/shared/types';

export const getNameInitials = (
	firstName?: Nullable<string>,
	lastName?: Nullable<string>,
) => {
	if (!firstName && !lastName) return undefined;

	let firstNameInitials = firstName
		?.split(' ')
		.filter((name) => name !== '')
		.map((name) => name.charAt(0).toUpperCase())
		.join('');

	if (firstNameInitials && firstNameInitials.length > 2) {
		firstNameInitials = firstNameInitials.slice(0, 2);
	}

	return `${firstNameInitials || ''}${lastName?.charAt(0).toUpperCase() || ''}`.slice(
		0,
		2,
	);
};
