import { createTheme, Popover, ThemeProvider, Typography } from '@mui/material';
import { deDE, enUS } from '@mui/x-data-grid-pro';
import { useContext, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import * as locales from '@mui/x-data-grid/locales';

import {
	EmailDialogActionEnum,
	MessageContext,
	MessageDialogTypesEnum,
	ROUTES,
	TableV2,
	theme,
} from '@heylog-app/frontend-lib/app';
import { ConversationTypeEnum } from '@heylog-app/shared/types';

import type { ConversationDetailsRow } from '@heylog-app/frontend-lib/app';
import type { MutableRefObject } from 'react';
import type {
	GridApiPro,
	GridColDef,
	GridColumnResizeParams,
	GridColumnVisibilityModel,
	GridRowParams,
	MuiEvent,
} from '@mui/x-data-grid-pro';

interface MessagesDataGridProps {
	rows: ConversationDetailsRow[]; // Define the type of rows and columns as per your requirement
	columns: GridColDef[];
	apiRef: MutableRefObject<GridApiPro>;
	title?: string;
	ToolbarComponent?: JSX.Element;
	hideFooter?: boolean;
	disableColumnMenu?: boolean;
	columnVisibilityModel?: GridColumnVisibilityModel;
	handleColumnResize?: (params: GridColumnResizeParams) => void;
	handleColumnOrderChange?: () => void;
	handleColumnVisibilityModelChange?: (model: GridColumnVisibilityModel) => void;
}

export const MessagesDataGrid: React.FC<MessagesDataGridProps> = ({
	rows,
	columns,
	apiRef,
	ToolbarComponent,
	title,
	hideFooter,
	disableColumnMenu,
	columnVisibilityModel,
	handleColumnOrderChange,
	handleColumnResize,
	handleColumnVisibilityModelChange,
}) => {
	const { onOpenMessageDialog } = useContext(MessageContext);
	const navParams = useParams();
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [messagePreview, setMessagePreview] = useState<string | undefined>('');
	const { workspaceId } = useParams();

	const navigate = useNavigate();

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales[tableLanguage]),
		[tableLanguage],
	);

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const handleRowOver = (event: MuiEvent<React.MouseEvent<HTMLElement>>) => {
		const row = Number(event.currentTarget.getAttribute('data-id'));

		// check if this is buggy with pagination
		const messageText = rows.find((r) => r.id === row)?.message;

		setMessagePreview(messageText);

		setAnchorEl(event.currentTarget);
	};

	const handleRowLeave = () => {
		setAnchorEl(null);
	};

	const handleRowClick = (params: GridRowParams) => {
		const data = params.row;
		if (data.type === ConversationTypeEnum.EMAIL && onOpenMessageDialog) {
			onOpenMessageDialog({
				type: MessageDialogTypesEnum.EMAIL,
				action: EmailDialogActionEnum.OPEN,
				payload: {
					...navParams,
					conversationId: data.id,
					orderId: data.orderId || undefined,
				},
			});
		}

		if (
			data.type === ConversationTypeEnum.WHATSAPP ||
			data.type === ConversationTypeEnum.VIBER
		) {
			onOpenMessageDialog &&
				onOpenMessageDialog({
					type: MessageDialogTypesEnum.CHAT_APP,
					payload: {
						...navParams,
						conversationV2Id: data.id,
						conversationId: data.conversationV1Id,
						contactId: data.contactId,
						orderId: data.orderId,
					},
				});
		}
		if (
			data.type === ConversationTypeEnum.HEYLOG_INTERNAL ||
			data.type === ConversationTypeEnum.HEYLOG_EXTERNAL
		) {
			navigate(
				generatePath(ROUTES.SHIPMENTS.DETAIL, {
					workspaceId,
					shipmentId: data.orderId,
				}),
				{ replace: true },
			);
		}
	};

	const open = Boolean(anchorEl);

	return (
		<ThemeProvider theme={themeWithLocale}>
			<TableV2
				ToolbarContent={ToolbarComponent}
				title={title}
				withColumnSettings
				hideFooter={hideFooter}
				disableColumnFilter
				disableColumnMenu={disableColumnMenu}
				columnVisibilityModel={columnVisibilityModel}
				data-test="fleet-checks-table"
				onColumnResize={handleColumnResize}
				onColumnOrderChange={handleColumnOrderChange}
				onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
				localeText={
					tableLanguage === 'deDE'
						? deDE.components.MuiDataGrid.defaultProps.localeText
						: enUS.components.MuiDataGrid.defaultProps.localeText
				}
				density="compact"
				apiRef={apiRef}
				rows={rows || []}
				columns={columns}
				disableDensitySelector
				initialState={{
					filter: {
						filterModel: {
							items: [],
							quickFilterValues: [''],
						},
					},
					pagination: { paginationModel: { pageSize: 5 } },
				}}
				slotProps={{
					row: {
						onMouseEnter: handleRowOver,
						onMouseLeave: handleRowLeave,
					},
				}}
				pageSizeOptions={[5, 10, 15]}
				sx={{
					backgroundColor: 'white',
					borderRadius: '5px',
					fontSize: '10px',
				}}
				onRowClick={handleRowClick}
			/>
			<Popover
				sx={{
					pointerEvents: 'none',
					maxWidth: '800px',
				}}
				open={open}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={handlePopoverClose}
				disableRestoreFocus
			>
				<Typography sx={{ p: 1 }}>{messagePreview}</Typography>
			</Popover>
		</ThemeProvider>
	);
};
