import styled from 'styled-components';

import { RESET_LIST } from '../../../styles';

export const StyledTabNavigation = styled.nav`
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyblue[225]};

	ul {
		${RESET_LIST};
		display: flex;
		align-items: baseline;
	}
`;
