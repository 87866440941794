export const getMessagesV2Key = (
	workspaceId: string,
	conversationV2Id: string,
	messagesToFetch?: number,
	messagesDateFrom?: Date,
) => {
	const params = new URLSearchParams();
	if (messagesToFetch) {
		params.append('count', messagesToFetch.toString());
	}
	if (messagesDateFrom) {
		params.append('beforeDate', messagesDateFrom.toISOString());
	}
	return `/workspaces/${workspaceId}/conversations-v2/${conversationV2Id}/messages?${params.toString()}`;
};
