import DescriptionIcon from '@mui/icons-material/DescriptionOutlined';
import mimelite from 'mime/lite';

import {
	StyledFileInfoWrapper,
	StyledFileName,
	StyledFileType,
	StyledMessageFile,
} from './message-file.styles';

import type { AttachmentResInterface } from '@heylog-app/shared/types';
export const MessageFile = ({ attachment }: { attachment?: AttachmentResInterface }) => {
	if (!attachment) return null;
	return (
		<>
			<StyledMessageFile href={`${attachment.publicUrl}`}>
				<DescriptionIcon />
				<StyledFileInfoWrapper>
					<StyledFileName>{attachment.name}</StyledFileName>
					<StyledFileType>{mimelite.getExtension(attachment.type)}</StyledFileType>
				</StyledFileInfoWrapper>
			</StyledMessageFile>
			{attachment?.caption && <p>{attachment?.caption}</p>}
		</>
	);
};
