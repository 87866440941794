import styled from 'styled-components';
import { Button } from '@mui/material';

import { colors, SIZE_SM, STYLE_2 } from '../../../styles';

export const StyledUploadedFile = styled(Button)`
	display: flex;
	border: 1px solid ${colors.greyblue[175]};
	align-items: center;
	border-radius: 0.25rem;
	padding: 0.5rem;
	position: relative;
	${STYLE_2};
	${SIZE_SM};

	&:hover button {
		display: flex;
	}
`;

export const StyledColumn = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 0.5rem;
`;

export const StyledRemoveButton = styled.div`
	background: ${({ theme }) => theme.palette.primary.main};
	border-radius: 100%;
	display: flex;
	border: none;
	padding: 0.25rem;
	margin: 0;
	cursor: pointer;
	position: absolute;
	top: -0.75rem;
	right: -0.75rem;

	svg {
		width: 1rem;
		height: 1rem;
		color: ${({ theme }) => theme.palette.common.white};
	}

	&:hover {
		background: ${({ theme }) => theme.palette.primary.dark};
	}
`;

export const StyledUploadedFilesList = styled.div<{ $showMargin?: boolean }>`
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	margin-top: ${(props) => (props.$showMargin ? '0.5rem' : '0')};
`;
