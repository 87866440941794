import type { FC } from 'react';

export const IconCs: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M56.134 48.008L17.13 73.438C24.466 82.332 35.572 88 48 88C70.092 88 88 70.092 88 48H56.12L56.134 48.008Z"
			fill="#FF3D00"
		/>
		<path
			d="M48 8C35.57 8 24.464 13.67 17.128 22.564L56.12 48H88C88 25.908 70.092 8 48 8Z"
			fill="#D7DFE2"
		/>
		<path
			d="M56.12 48L17.128 22.564C11.426 29.478 8 38.338 8 48C8 57.662 11.426 66.524 17.13 73.438L56.134 48.008L56.12 48Z"
			fill="#3F51B5"
		/>
	</svg>
);
