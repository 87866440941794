import useSWR, { mutate } from 'swr';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { ConversationTypeEnum } from '@heylog-app/shared/types';

import {
	createUserToUserMessageAPI,
	getFetcher,
	getUserToUserMessagesKey,
} from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { getConversationsV2Key } from '../util/get-conversationv2-key';

import type {
	CreateMessageUserToUserReqInterface,
	FullMessageResInterface,
	ConversationType,
} from '@heylog-app/shared/types';

export const useUserToUserMessages = (conversationId: string | undefined) => {
	// const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams();

	const { data, error, isValidating, isLoading } = useSWR<FullMessageResInterface[]>(
		// ability.can(Actions.READ, 'Conversation') ? contactsKey : null,
		workspaceId && conversationId
			? getUserToUserMessagesKey(workspaceId, conversationId)
			: null,
		getFetcher(apiClient),
	);

	return {
		messages: data,
		isLoading,
		isError: error,
		isValidating,
	};
};

export const useUserToUserMessageActions = (conversationId: string) => {
	const { apiClient } = useApiClientContext();
	const { workspaceId = '' } = useParams();

	const createUserToUserMessage = useCallback(
		async (params: CreateMessageUserToUserReqInterface, type: ConversationType) => {
			const response = await createUserToUserMessageAPI(apiClient, type, {
				data: params,
				workspaceId,
			});

			// u2u conversations are created on the fly, so i need to refresh them if it's the first message
			if (!conversationId && params?.orderId) {
				const conversationsV2 = getConversationsV2Key(
					workspaceId,
					params?.orderId?.toString(),
					[ConversationTypeEnum.HEYLOG_INTERNAL, ConversationTypeEnum.HEYLOG_EXTERNAL],
				);
				mutate(conversationsV2);
			}
			mutate(
				getUserToUserMessagesKey(workspaceId, response.data.conversationId.toString()),
			);

			return response;
		},
		[apiClient, conversationId, workspaceId],
	);

	return {
		createUserToUserMessage,
	};
};
