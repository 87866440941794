import Icon from '@mdi/react';
import styled from 'styled-components';

import { STYLE_3, SIZE_MD, STYLE_2, SIZE_XS } from '../../../styles';

export const StyledContactName = styled.div`
	${STYLE_3};
	${SIZE_MD};
`;

export const StyledContactDetail = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${STYLE_2};
	${SIZE_XS};
	margin-top: 0.25rem;
`;

export const StyledContactLabel = styled.span`
	${STYLE_2};
	${SIZE_XS};
	margin-top: 0.25rem;
`;

export const StyledContactDetailIcon = styled(Icon)`
	margin-right: 6px;
	margin-bottom: -2px;
`;
