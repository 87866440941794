import styled from 'styled-components';
import { TextField } from '@mui/material';

export const StyledSidebarSearchWrapper = styled.div`
	padding: 0 1rem 1rem 1rem;

	input {
		&::placeholder {
			color: #9ba0b3;
		}
	}
`;

export const StyledTextField = styled(TextField)({
	width: '200px',
	'& .MuiInputBase-root': {
		height: '40px',
	},
	'& .MuiInputLabel-root': {
		position: 'absolute',
		left: '5px',
		top: '-5px',
	},
});
