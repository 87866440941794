import { useSWRConfig } from 'swr';

import type { MutatorOptions } from 'swr';

export function useMatchMutate() {
	const { cache, mutate } = useSWRConfig();
	//data can be any type
	//eslint-disable-next-line @typescript-eslint/no-explicit-any
	return (matcher: RegExp, data?: any, options?: boolean | MutatorOptions) => {
		if (!(cache instanceof Map)) {
			throw new Error('matchMutate requires the cache provider to be a Map instance');
		}

		const keys = [];

		for (const key of cache.keys()) {
			if (matcher.test(key)) {
				keys.push(key);
			}
		}

		const mutations = keys.map((key) => mutate(key, data, options));
		return Promise.all(mutations);
	};
}
