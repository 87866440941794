import { useCallback, useState } from 'react';

import type { UseSnackbarStatusType, UseSnackbarReturnType } from '../types';
import type { AlertColor } from '@mui/material/Alert/Alert';

export const useSnackbar = (): UseSnackbarReturnType => {
	const [state, setState] = useState<UseSnackbarStatusType>({
		open: false,
		severity: 'success',
		message: '',
		autoClose: true,
		autoCloseDuration: 0,
	});

	const close = useCallback(() => {
		setState({ ...state, open: false });
	}, [state]);

	const open = useCallback(
		(
			severity: AlertColor,
			message: string,
			autoClose = true,
			autoCloseDuration = 3000,
		) => {
			// Close the opened snackbar (if any) and reopen it on next event-loop cycle
			close();
			setTimeout(() => {
				setState({
					...state,
					open: true,
					severity,
					message,
					autoClose,
					autoCloseDuration,
				});
			}, 1);
		},
		[close, state],
	);

	return [state, open, close];
};
