import { useContext } from 'react';

import { EnvironmentContext } from '../providers';

import type { Environment } from '../types';

export const useEnvContext = (): Environment => {
	const context = useContext(EnvironmentContext);

	if (!context)
		throw new Error('Env context must be used inside an env context provider');

	return context;
};
