import type { Environment } from '@heylog-app/frontend-lib/app';

export const environment: Environment = {
	production: true,

	API_URL: '/api/v1',
	GPLACES_KEY: 'AIzaSyAr-G4w6sGBvbeErxytRhkTvaWAqz2UdVc',
	GOOGLE_MAPS_STATIC_API_KEY: 'AIzaSyAz7Gc86-Dzuw7b_uLANt409Ad-pLDNhAA',

	PUSHER_KEY: '8a174a03069ee98de1c8',
	PUSHER_CLUSTER: 'eu',
	PUSHER_AUTH_ENDPOINT: 'auth/pusher',

	PUSHER_BEAMS_AUTH_ENDPOINT: 'auth/pusher/beams',
	PUSHER_BEAMS_ID: 'ffa10390-d2ef-491f-9b32-17787aada9fd',
	MUI_PRO:
		'b6995d4f77ba4b9a21966a313821652cTz04MDk1NixFPTE3MzQ3OTUyMjIwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',

	APP_URL: 'https://app-dev.heylog.com',
};
