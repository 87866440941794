import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './messages-page.styles';
import { MessagesDataTable } from './components/messages-data-table';

import type { FC } from 'react';

export const MessagesPage: FC = () => {
	return (
		<SidebarLayout>
			<StyledBackground>
				<MessagesDataTable />
			</StyledBackground>
		</SidebarLayout>
	);
};
