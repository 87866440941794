import { StyledMessageImage } from './message-image.styles';

import type { AttachmentResInterface } from '@heylog-app/shared/types';

export const MessageImage = ({ attachment }: { attachment?: AttachmentResInterface }) => {
	if (!attachment) return null;
	return (
		<>
			<a href={attachment?.publicUrl} target="_blank" download={false} rel="noreferrer">
				<StyledMessageImage
					src={attachment?.publicUrl}
					alt=""
					width={400}
					height={400}
					loading="lazy"
				/>
			</a>
			{attachment?.caption && <div>{attachment?.caption}</div>}
		</>
	);
};
