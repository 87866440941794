import { useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions, WorkspaceEventsEnum } from '@heylog-app/shared/types';

import {
	getContactsKey,
	getQueryString,
	deleteContactAPI,
	updateContactAPI,
	getFetcher,
	ROUTES,
} from '../util';
import { useWsChannelEvent } from './use-ws-channel-event';
import { useMatchMutate } from './use-match-mutate';
import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers/ability';

import type { ArchiveEntryParams, UpdateEntryParams } from '../types';
import type { Key } from 'swr';
import type {
	FullContactResInterface,
	UpdateContactReqInterface,
	UnreadMessagesResInterface,
	Nullable,
	ContactStatusType,
} from '@heylog-app/shared/types';

const useContactKey = (params?: {
	orderId?: string;
	q?: string;
	id?: Nullable<string>;
	status?: ContactStatusType;
}): Key => {
	const { workspaceId } = useParams();

	const baseContactsKey = workspaceId ? getContactsKey(workspaceId) : undefined;

	if (!params) return baseContactsKey;

	const { orderId, q = undefined, id = undefined, status = undefined } = params;

	if (id) return `${baseContactsKey}/${id}`;

	const queryParams = {
		status,
		...(q && { q }),
		...(orderId && { orderId }),
	};

	return `${baseContactsKey}${getQueryString(queryParams)}`;
};

export const useContacts = ({
	orderId,
	q,
	status,
}: {
	orderId?: string;
	q?: string;
	status?: ContactStatusType;
}) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const contactsKey = useContactKey({ orderId, q, status });

	const { data, error, mutate, isValidating, isLoading } = useSWR<
		FullContactResInterface[]
	>(
		ability.can(Actions.READ, 'Conversation') ? contactsKey : null,
		getFetcher(apiClient),
	);

	const updateContacts = useCallback(() => mutate(), [mutate]);

	useWsChannelEvent(WorkspaceEventsEnum.CONTACT_UPDATED, () => {
		console.log('[Realtime]: handling "contact:updated" event');
		mutate().catch((e) => console.error('error fetching contacts', e));
	});

	useEffect(() => {
		mutate();
	}, [q, orderId, mutate, contactsKey]);

	const filteredContacts = data?.filter((contact) => contact.conversations.length > 0);

	return {
		contacts: filteredContacts,
		isLoading,
		isError: error,
		updateContacts,
		isValidating,
	};
};

const useUnreadKey = (): Key => {
	const { workspaceId } = useParams();
	return workspaceId ? getContactsKey(workspaceId) + '/unread' : undefined;
};

export const useUnread = () => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const contactsKey = useUnreadKey();

	const { data, error, mutate, isLoading } = useSWR<UnreadMessagesResInterface>(
		ability.can(Actions.MANAGE, 'Conversation') ? contactsKey : null,
		getFetcher(apiClient),
	);

	useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_STATUS_UPDATED, () => {
		console.log('[Realtime]: handling "message:status:updated" event');
		mutate().catch((e) => console.error('error updating unread', e));
	});

	useWsChannelEvent(WorkspaceEventsEnum.MESSAGE_RECEIVED, () => {
		console.log('[Realtime]: handling "message:status:updated" event');
		mutate().catch((e) => console.error('error updating unread', e));
	});

	return {
		unreadMap: data,
		isLoading,
		isError: error,
		updateUnread: mutate,
	};
};

export const useContact = (id?: Nullable<string>) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const navigate = useNavigate();

	const contactsKey = useContactKey({ id });

	const { data, error, mutate, isLoading } = useSWR<FullContactResInterface>(
		id && ability.can(Actions.READ, 'Conversation') ? () => contactsKey : null,
		getFetcher(apiClient),
	);

	if (error && error.response.status === 500) {
		navigate(ROUTES.NOT_FOUND);
	}

	return {
		contact: data,
		contactError: error,
		updateContact: mutate,
		revalidateContact: mutate,
		isLoading,
	};
};

export const useContactActions = (id?: Nullable<string>) => {
	const { apiClient } = useApiClientContext();
	const { workspaceId = '' } = useParams();
	const baseContactsKey = getContactsKey(workspaceId);

	const matchMutate = useMatchMutate();

	const { contact, revalidateContact, contactError, isLoading } = useContact(id);

	const updateContact = useCallback(
		async (params: UpdateEntryParams<UpdateContactReqInterface>) => {
			const response = await updateContactAPI(apiClient, params);

			//wildcard mutate for everything that matches starting baseContactsKey
			await matchMutate(new RegExp(`^${baseContactsKey}.*$`));

			return response;
		},
		[apiClient, matchMutate, baseContactsKey],
	);

	useWsChannelEvent(WorkspaceEventsEnum.CONTACT_UPDATED, () => {
		console.log('[Realtime]: handling "contact:updated" event');
		revalidateContact().catch((e) => console.error('error fetching contacts', e));
	});

	const deleteContact = useCallback(
		(params: ArchiveEntryParams) => {
			return deleteContactAPI(apiClient, params).then(
				async () => await matchMutate(new RegExp(`^${baseContactsKey}.*$`)),
			);
		},
		[apiClient, baseContactsKey, matchMutate],
	);

	return {
		contact,
		isLoading,
		isError: contactError,
		updateContact,
		deleteContact,
	};
};
