import React, { useCallback, useState } from 'react';
import {
	Select,
	MenuItem,
	Box,
	Chip,
	DialogTitle,
	DialogContent,
	DialogContentText,
	Button,
	Dialog,
	TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ButtonV2, ModalClose } from '../../ui';
import { StyledDialogActions } from '../../dialog';
import { useOrders } from '../../../hooks';
import { swollowEvent } from '../../../util/swallow-event';

import type { SelectChangeEvent } from '@mui/material';
import type { OrderResInterface } from '@heylog-app/shared/types';

type ItemInputProps = {
	selectedProp: OrderResInterface | undefined;
	confirmationMessage: string;
	onSelect: (order: OrderResInterface) => void;
	shouldConfirm?: boolean;
	handleNavigation?: () => void;
};

export const OrderSelectField = ({
	selectedProp,
	confirmationMessage,
	onSelect,
	shouldConfirm,
	handleNavigation,
}: ItemInputProps) => {
	const { t } = useTranslation();
	const [selectedItem, setSelectedItem] = useState<string | number | undefined>(
		selectedProp?.id,
	);
	const [selectedOrder, setSelectedOrder] = useState<OrderResInterface | undefined>(
		selectedProp,
	);
	const [orderFilter, setOrderFilter] = useState<string>('');
	const { orders, isLoading } = useOrders({ q: orderFilter });
	const [showConfirmDialog, setShowConfirmDialog] = useState(false);

	const onCloseConfirmDialog = useCallback(() => {
		setShowConfirmDialog(false);
	}, [setShowConfirmDialog]);

	const onConfirm = useCallback(() => {
		if (selectedProp) {
			onSelect(selectedProp);
		}
		onCloseConfirmDialog();
	}, [onCloseConfirmDialog, onSelect, selectedProp]);

	const handleSelectChange = (event: SelectChangeEvent<string | number>) => {
		setSelectedItem(event.target.value);
	};

	const onFilter = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			e.stopPropagation();
			e.preventDefault();
			const value = e.target.value;
			setOrderFilter(value);
			setSelectedItem('');
		},
		[setOrderFilter],
	);

	const handleOnSelect = (order: OrderResInterface) => setSelectedOrder(order);

	const handleAddItem = useCallback(() => {
		if (shouldConfirm) {
			setShowConfirmDialog(true);
		} else {
			if (selectedOrder) {
				onSelect(selectedOrder);
			}
		}
	}, [onSelect, shouldConfirm, selectedOrder]);

	return (
		<Box
			sx={{
				width: '80%',
				height: '48px',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
			}}
		>
			<Select
				sx={{ width: '70%' }}
				value={selectedItem}
				onChange={handleSelectChange}
				renderValue={(selected) =>
					selectedOrder && (
						<Box
							sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
							onMouseDown={(event) => event.stopPropagation()}
						>
							<Chip
								sx={{ padding: '4px', cursor: 'pointer' }}
								key={selected}
								label={
									selectedOrder.labels.find(
										(label) => label.type === 'ORDER_EXTERNAL_REFERENCE',
									)?.value +
									' ' +
									selectedOrder.customer
								}
								onClick={handleNavigation}
							/>
						</Box>
					)
				}
			>
				<MenuItem disableGutters>
					<Box sx={{ width: '100%' }} onClick={swollowEvent}>
						<TextField
							fullWidth
							placeholder={t('actionLabels.search')}
							value={orderFilter}
							onChange={onFilter}
							onKeyDown={swollowEvent}
						/>
					</Box>
				</MenuItem>
				{orders &&
					!isLoading &&
					orders.map((order, index: number) => (
						<MenuItem onClick={() => handleOnSelect(order)} key={index} value={order.id}>
							{
								order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
									?.value
							}{' '}
							- {order.customer}
						</MenuItem>
					))}
			</Select>
			<ButtonV2 $variant="dark" onClick={handleAddItem}>
				{t('contacts.changeBtn')}
			</ButtonV2>

			<Dialog open={showConfirmDialog} onClose={onCloseConfirmDialog}>
				<ModalClose closeModalFn={onCloseConfirmDialog} />
				<DialogTitle id="alert-dialog-title">
					{t('contacts.toolSidebar.assignOrderConfirmationTitle')}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{confirmationMessage}
					</DialogContentText>
				</DialogContent>
				<StyledDialogActions>
					<Button variant="outlined" onClick={onCloseConfirmDialog}>
						{t('actionLabels.cancel')}
					</Button>
					<Button variant="contained" onClick={onConfirm} autoFocus>
						{t('actionLabels.confirm')}
					</Button>
				</StyledDialogActions>
			</Dialog>
		</Box>
	);
};
