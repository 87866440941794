import { useCallback, useRef, useState } from 'react';

import type { UseMenuReturnType } from '../types';

export const useMenu = (): UseMenuReturnType => {
	const toggleButtonRef = useRef<HTMLButtonElement>(null);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const openMenu = useCallback(() => setIsMenuOpen(true), []);
	const closeMenu = useCallback(() => setIsMenuOpen(false), []);
	const toggleMenu = useCallback(() => setIsMenuOpen(!isMenuOpen), [isMenuOpen]);

	return { toggleButtonRef, isMenuOpen, openMenu, closeMenu, toggleMenu };
};
