import { useCallback } from 'react';
import { faker } from '@faker-js/faker';
import { useParams } from 'react-router-dom';
import { formatISO } from 'date-fns';
import { Button } from '@mui/base';

import {
	isAxiosResponse,
	toggleUrgentOrderAPI,
	useApiClientContext,
	useOrderActions,
	useOrders,
	usePlugins,
} from '@heylog-app/frontend-lib/app';

import type { FC } from 'react';

const locations = [
	{
		name: 'Central Warehouse',
		street: 'Carrer de Sants 1',
		zipCode: '08014',
		city: 'Barcelona',
		latitude: 41.3789473,
		longitude: 2.1408826,
	},
	{
		name: 'North Storage',
		street: 'Marktstraße 2',
		zipCode: '20357',
		city: 'Hamburg',
		latitude: 53.5649325,
		longitude: 9.9631996,
	},
	{
		name: 'West Depot',
		street: 'Rue du Faubourg Saint-Antoine 3',
		zipCode: '75011',
		city: 'Paris',
		latitude: 48.8539804,
		longitude: 2.3741968,
	},
	{
		name: 'East Storage',
		street: 'Jagiellońska 4',
		zipCode: '03-721',
		city: 'Warsaw',
		latitude: 52.2539464,
		longitude: 21.0349145,
	},
	{
		name: 'South Warehouse',
		street: 'Via Roma 5',
		zipCode: '00184',
		city: 'Rome',
		latitude: 41.8964973,
		longitude: 12.4854272,
	},
	{
		name: 'Central Depot',
		street: 'Freyung 6',
		zipCode: '1010',
		city: 'Vienna',
		latitude: 48.2116202,
		longitude: 16.3656063,
	},
	{
		name: 'Main Warehouse',
		street: 'King Street 7',
		zipCode: 'M2 4LQ',
		city: 'Manchester',
		latitude: 53.4791302,
		longitude: -2.2420273,
	},
	{
		name: 'West Storage',
		street: 'Weteringschans 8',
		zipCode: '1017',
		city: 'Amsterdam',
		latitude: 52.3647583,
		longitude: 4.8838655,
	},
	{
		name: 'East Depot',
		street: 'Landsberger Allee 9',
		zipCode: '10407',
		city: 'Berlin',
		latitude: 52.5314973,
		longitude: 13.4393012,
	},
	{
		name: 'North Warehouse',
		street: 'Vesterbrogade 10',
		zipCode: '1620',
		city: 'Copenhagen',
		latitude: 55.6713912,
		longitude: 12.5575185,
	},
	{
		name: 'South Depot',
		street: 'Largo da Academia Nacional de Belas Artes 11',
		zipCode: '1200-005',
		city: 'Lisbon',
		latitude: 38.7110463,
		longitude: -9.1426853,
	},
];
export const DemoPage: FC = () => {
	console.log('Hello from demo page');

	const { apiClient } = useApiClientContext();
	const { workspaceId = '' } = useParams();
	const { orders } = useOrders();
	const { archiveOrder } = useOrderActions();

	//
	// onDeleteALLOrders
	//

	const onDeleteALLOrders = useCallback(async () => {
		console.log('Deleting ALL orders...');
		console.log('orders', orders);

		if (!orders?.length) {
			console.log('No orders to delete.');
			return;
		}

		for (const order of orders) {
			try {
				const data = {
					id: order.id.toString(),
					workspaceId,
				};

				await archiveOrder(data);
				console.log(`Order ${order.id} archived successfully.`);
			} catch (error) {
				console.error(`Failed to archive order ${order.id}:`, error);
			}
		}
	}, [orders, archiveOrder, workspaceId]);

	//
	// onCreateOrder
	//
	const onCreateOrder = useCallback(
		async (type: string) => {
			console.log('Creating orders...');

			const etaFromto = () => {
				const now = new Date();
				const threeHoursFromNow = new Date(now.getTime() + 3 * 60 * 60 * 1000);
				const twoDaysFromNow = new Date(now.getTime() + 2 * 24 * 60 * 60 * 1000);
				return {
					// etaFrom is in a range from now to 3 hours from now
					etaFrom: formatISO(threeHoursFromNow),
					// etaTo is in a range from 10 hours from now, to 2 days from now
					etaTo: formatISO(twoDaysFromNow),

					start: faker.helpers.arrayElement(locations),
					destination: faker.helpers.arrayElement(locations),
				};
			};

			const dataPlus = type === 'etas' ? etaFromto() : undefined;

			// tasks!!!

			const data = {
				refNumber: `${faker.word.adjective()}-${faker.word.noun()}`,
				customer: faker.company.companyName(),
				licensePlateTrailer: faker.vehicle.vrm(),
				licensePlateTruck: faker.vehicle.vrm(),
				notes: faker.lorem.paragraph(),
				...dataPlus,
			};

			const totalWeight = `${faker.random.numeric()} kg`;
			const volumeOfOrder = `${faker.random.numeric()} m3`;

			const internalReference = {
				value: faker.random.alphaNumeric(10),
				type: 'ORDER_EXTERNAL_REFERENCE',
			};

			const key = `workspaces/${workspaceId}/orders/create-with-labels`;
			const res = await apiClient.post(key, {
				...data,
				labels: [internalReference],
				miscData: JSON.stringify({ totalWeight, volumeOfOrder }),
			});

			if (res && isAxiosResponse(res)) {
				const order = res.data;
				console.log('Order created successfully:', res.data);

				// randomly, 1 out of 3 is urgent
				if (Math.random() < 0.33) {
					await toggleUrgentOrderAPI(apiClient, {
						id: order.id.toString(),
						workspaceId,
					});
				}

				if (type === 'tasks') {
					for (let i = 0; i < 4; i++) {
						const data = {
							location: `${faker.address.city()} ${faker.address.streetAddress()} ${faker.address.zipCode()}`,
							type: Math.random() < 0.5 ? 'LOADING' : 'UNLOADING',
							customerReference: `${faker.company.companyName()} ${faker.random.alphaNumeric(
								5,
							)}`,
							etaFrom: formatISO(
								new Date().getTime() + //now
									45 * 60 * 1000 + // add 45 minutes
									i * 15 * 60 * 1000, // stagger 15 minutes between each step
							),
							etaTo: formatISO(
								new Date().getTime() + //now
									45 * 60 * 1000 + // add 45 minutes
									i * 15 * 60 * 1000 + // stagger 15 minutes between each step
									3 * 15 * 60 * 1000, // add 3 minutes betwween from and to
							),
						};

						const key = `workspaces/${workspaceId}/orders/${order.id}/tasks`;
						await apiClient.post(key, data);
					}
				}
			}
		},
		[apiClient, workspaceId],
	);

	const onCreateOrderWithTasks = () => onCreateOrder('tasks');
	const onCreateOrderWithEtas = () => onCreateOrder('etas');

	const { DemoPlugin } = usePlugins(workspaceId);
	console.log('DemoPlugin', DemoPlugin);

	if (!DemoPlugin) {
		return <div>Plugin not enabled</div>;
	}
	return (
		<div>
			<h1>Welcome to demo!</h1>
			<Button
				data-test="submit-order-button"
				type="submit"
				onClick={onCreateOrderWithEtas}
			>
				Create Order etaFrom, etaTo
			</Button>
			<br />
			<br />
			<br />
			<Button
				data-test="submit-order-button"
				type="submit"
				onClick={onCreateOrderWithTasks}
			>
				Create Order with tasks
			</Button>
			<br />
			<br />
			<br />
			<Button data-test="submit-order-button" type="submit" onClick={onDeleteALLOrders}>
				Delete ALL Orders
			</Button>
		</div>
	);
};
