import { Box, InputAdornment, Stack } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSWRConfig } from 'swr';

import { getMediaType } from '@heylog-app/shared/util';
import { ConversationTypeEnum } from '@heylog-app/shared/types';

import { FileCard } from '../../components';
import {
	StyledChooseOrder,
	StyledErrorItem,
	StyledTextField,
} from './use-email-form.styles';
import EmailEditor from '../../components/email-editor/email-editor';
import { useApiClientContext } from '../use-api-client-context.hook';
import { UiContext } from '../../providers';
import {
	extractEmailAddresses,
	sendOutgoingEmailAPI,
	validateEmailAddresses,
} from '../../util';
import { getConversationsV2Key } from '../../util/get-conversationv2-key';
import { colors } from '../../styles';

import type { FileWithPreview } from '../../components/attachment-button';
import type {
	CreateAttachmentReqInterface,
	EmailHeaderInterface,
	Nullable,
} from '@heylog-app/shared/types';

interface EmailBodyRef {
	getHtml: () => string;
	getText: () => string;
}

type FormData = {
	orderId?: string;
	cc?: string;
	bcc?: string;
	subject: string;
	to: string;
	labels?: string;
	headers?: EmailHeaderInterface[];
};

interface UseEmailFormArgs {
	orderId?: number;
	workspaceId: string;
	fieldsToRender: Array<keyof Omit<FormData, 'to' | 'subject'>>;
	editorInitialData?: string;
	handleCloseDialog?: () => void;
	setSubject?: (subject: string) => void;
}

export interface EmailFormForwardRefPayload {
	onSubmitEmailForm: () => Promise<void>;
	isLoadingSend: boolean;
}

export const useEmailForm = ({
	orderId,
	workspaceId,
	fieldsToRender,
	editorInitialData,
	handleCloseDialog,
	setSubject,
}: UseEmailFormArgs) => {
	const emailBodyRef = useRef<EmailBodyRef>(null);
	const { t } = useTranslation();
	const [isLoadingSend, setIsLoadingSend] = useState(false);
	const [attachments, setAttachments] = useState<FileWithPreview[]>([]);
	const [attachmentsError, setAttachmentsError] = useState<string[]>([]);
	const [selectedOrderId, setSelectedOrderId] = useState<Nullable<string>>(
		orderId?.toString() || null,
	);
	const { apiClient } = useApiClientContext();
	const { mutate: globalMutate } = useSWRConfig();
	const { snackbar } = useContext(UiContext);
	const { openSnackbar } = snackbar || {};

	const {
		register,
		control,
		reset,
		setValue,
		handleSubmit,
		watch,
		getValues,
		formState: { errors },
	} = useForm<FormData>({
		defaultValues: {
			subject: '',
			to: '',
		},
	});

	const onFocusEmailField = useCallback(
		(event: React.FocusEvent<HTMLInputElement>) => {
			if (event.target.value === '') {
				const headers = getValues('headers');
				reset({ to: '', headers });
			}
		},
		[reset, getValues],
	);

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result as string);
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	};

	const onSubmitEmailForm = useCallback(
		async (data: FormData) => {
			setIsLoadingSend(true);
			const plainText = emailBodyRef.current?.getText() ?? ''; // Fallback to an empty string if null
			const htmlBody = emailBodyRef.current?.getHtml();

			// Convert attachments to base64
			const attachmentsBase64 = await Promise.all(
				attachments.map(async (file): Promise<CreateAttachmentReqInterface> => {
					const base64Content = await fileToBase64(file);
					return {
						name: file.name,
						type: file.type,
						base64Content: base64Content.split(',')[1] || '', // Remove the data URL prefix
						contentId: '', // You'll need to provide a content ID
					};
				}),
			);
			const fullData = {
				...data,
				to: extractEmailAddresses(data.to),
				// TODO get plain text from editor
				cc: extractEmailAddresses(data.cc),
				bcc: extractEmailAddresses(data.bcc),
				text: plainText, // Include rich text content in form data
				html: htmlBody,
				orderId: selectedOrderId ? parseInt(selectedOrderId) : undefined,
				attachments: attachmentsBase64,
			};
			await sendOutgoingEmailAPI(apiClient, fullData, parseInt(workspaceId))
				.then(() => {
					globalMutate(
						getConversationsV2Key(
							workspaceId,
							orderId?.toString(),
							orderId
								? [
										ConversationTypeEnum.EMAIL,
										ConversationTypeEnum.VIBER,
										ConversationTypeEnum.WHATSAPP,
								  ]
								: undefined,
						),
					);

					if (openSnackbar) openSnackbar('success', t('outgoingEmails.sendEmailSuccess'));
					if (handleCloseDialog) handleCloseDialog();
				})
				.catch(() => {
					if (openSnackbar) openSnackbar('error', t('outgoingEmails.sendEmailError'));
				});

			setIsLoadingSend(false);
		},
		[
			selectedOrderId,
			attachments,
			apiClient,
			emailBodyRef,
			handleCloseDialog,
			openSnackbar,
			orderId,
			t,
			globalMutate,
			workspaceId,
		],
	);

	const subjectValue = watch('subject');

	useEffect(() => {
		if (setSubject) setSubject(subjectValue || '');
	}, [subjectValue, setSubject]);

	useEffect(() => {
		setSelectedOrderId(orderId ? orderId.toString() : null);
	}, [orderId]);

	const EmailFormComponent = (
		<div style={{ width: '100%', marginBottom: '16px' }}>
			<div>
				<Stack gap={1} direction="column">
					<Stack gap={2} direction="row" alignItems="center" position="relative">
						<Stack direction="row" width="100%" gap={4}>
							<Controller
								control={control}
								name="to"
								render={({ field }) => (
									<StyledTextField
										type="email"
										size="small"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{t('email.form.to')}:
												</InputAdornment>
											),
										}}
										id="to"
										variant="outlined"
										error={!!errors.to}
										helperText={
											errors.to
												? t(
														`forms.errors.${
															errors.to?.type === 'validate' ? 'pattern' : 'required'
														}`,
												  )
												: null
										}
										onFocus={onFocusEmailField}
										{...register('to', {
											required: true,
											validate: (value) => validateEmailAddresses(value),
										})}
										{...field}
									/>
								)}
							/>

							<Controller
								control={control}
								name="subject"
								render={({ field }) => (
									<StyledTextField
										type="text"
										size="small"
										id="to"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{t('email.form.subject')}:
												</InputAdornment>
											),
										}}
										error={!!errors.subject}
										{...register('subject', {
											required: true,
										})}
										helperText={errors.subject ? t('forms.errors.required') : null}
										{...field}
									/>
								)}
							/>
						</Stack>
					</Stack>
					{fieldsToRender.includes('cc') && (
						<Stack gap={2} direction="row" alignItems="center">
							<Controller
								control={control}
								name="cc"
								render={({ field }) => (
									<StyledTextField
										type="email"
										size="small"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{t('email.form.cc')}:
												</InputAdornment>
											),
										}}
										id="CC"
										variant="outlined"
										error={!!errors.cc}
										helperText={errors.cc ? t('forms.errors.pattern') : null}
										{...register('cc', {
											required: false,
											validate: (value) =>
												value?.length ? validateEmailAddresses(value) : true,
										})}
										{...field}
									/>
								)}
							/>
						</Stack>
					)}
					{fieldsToRender.includes('bcc') && (
						<Stack gap={2} direction="row" alignItems="center">
							<Controller
								control={control}
								name="bcc"
								render={({ field }) => (
									<StyledTextField
										type="email"
										size="small"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{t('email.form.bcc')}:
												</InputAdornment>
											),
										}}
										id="BCC"
										variant="outlined"
										error={!!errors.bcc}
										helperText={errors.bcc ? t('forms.errors.pattern') : null}
										{...register('bcc', {
											required: false,
											validate: (value) =>
												value?.length ? validateEmailAddresses(value) : true,
										})}
										{...field}
									/>
								)}
							/>
						</Stack>
					)}
					{fieldsToRender.includes('labels') && (
						<Stack gap={2} direction="row" alignItems="center">
							<Controller
								control={control}
								name="labels"
								render={({ field }) => (
									<StyledTextField
										type="text"
										size="small"
										id="to"
										variant="outlined"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													{t('email.form.labels')}:
												</InputAdornment>
											),
										}}
										{...register('labels')}
										{...field}
									/>
								)}
							/>
						</Stack>
					)}
					{fieldsToRender.includes('orderId') && (
						<StyledChooseOrder
							initialOrderId={orderId}
							hasSubmit={false}
							inputProps={{
								variant: 'outlined',
								hasLabel: false,
								startAdornment: (
									<InputAdornment position="start">
										{t('email.form.linkToOrder')}:
									</InputAdornment>
								),
							}}
							width="100%"
							handleAssign={({ orderId }) => setSelectedOrderId(orderId)}
						/>
					)}
				</Stack>
			</div>
			<Box sx={{ marginTop: 2 }}>
				<EmailEditor
					initialData={editorInitialData}
					editorMinHeight={220}
					ref={emailBodyRef}
				/>
			</Box>
			<Stack gap={1} marginBlock={1}>
				{attachmentsError.map((err) => (
					<StyledErrorItem color={colors.error.main}>{err}</StyledErrorItem>
				))}
			</Stack>
			<Stack sx={{ marginBlock: 2 }} gap={2} direction="row" flexWrap="wrap">
				{attachments.map((file, index) => (
					<FileCard
						key={crypto.randomUUID()}
						width={128}
						height={88}
						fileType={file.type}
						mediaType={getMediaType(file.type)}
						fileName={file.name || ''}
						src={file.src}
						size={file.size}
						onDelete={() => {
							setAttachments((prev) => prev.filter((_, i) => i !== index));
						}}
					/>
				))}
			</Stack>
		</div>
	);

	return {
		EmailFormComponent,
		isLoadingSend,
		onSubmitEmailForm: handleSubmit(onSubmitEmailForm),
		setAttachmentsError,
		setAttachments,
		setValue,
	};
};
