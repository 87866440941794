import { Tab } from '@mui/material';
import styled from 'styled-components';

import { colors, SIZE_MD, STYLE_1, STYLE_2 } from '../../../styles';
import { DialogFooter } from '../../ui';

export const StyledInputDescription = styled.span`
	${STYLE_2};
	margin-bottom: 1rem;
	display: block;
`;

export const StyledDecoration = styled.div`
	height: 1px;
	background-color: ${colors.greyblue[205]};
`;

export const StyledDialogFooter = styled(DialogFooter)`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	min-height: 264px;
`;

export const StyledTab = styled(Tab)`
	text-transform: none;
`;

export const StyledMessageGroupWrapper = styled.div`
	max-width: 20rem;
`;

export const StyledTabsContainer = styled.div`
	margin-top: 1rem;
	padding: 0 56px;
`;

export const StyledDialogContainer = styled.div`
	min-height: 480px;
`;

export const StyledQRCodePlaceholder = styled.div`
	height: 150px;
	width: 150px;
	background-color: ${colors.greyblue[225]};
`;

export const StyledDownloadButton = styled.button`
	${STYLE_1}
	${SIZE_MD}

	border: none;
	margin: 1rem 0 0 0;
	padding: 0;
	width: auto;
	overflow: visible;
	cursor: pointer;
	transition: all linear 300ms;

	background: transparent;

	&:hover {
		color: ${colors.primary.main};
	}

	svg {
		margin-bottom: -2px;
	}
`;
