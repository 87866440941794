import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const useUploadFiles = () => {
	const [files, setFiles] = useState<File[]>([]);
	const [fileError, setFileError] = useState(false);

	const removeFile = useCallback(
		(file: File) => {
			setFiles(files.filter((existingFile) => existingFile !== file));
			setFileError(false);
		},
		[files],
	);

	const removeAllFiles = useCallback(() => {
		setFiles([]);
		setFileError(false);
	}, []);

	const onDrop = useCallback(
		(acceptedFiles: File[]) => {
			setFiles([...files, ...acceptedFiles]);
		},
		[setFiles, files],
	);

	const {
		getInputProps,
		getRootProps,
		open: openFileDialog,
		isFocused,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		noClick: true,
		noKeyboard: true,
		maxFiles: 5,
		// TODO: talk with Bernhard about accepted files types
		// https://app.asana.com/0/1202161641843055/1202768427999717/f
		// for now this is the list of types that whatsapp supports
		accept: {
			'image/jpeg': [],
			'image/png': [],
			'video/mp4': [],
			'video/3gp': [],
			'text/plain': [],
			'application/pdf': [],
			'application/msword': [],
			'audio/aac': [],
			'audio/mpeg': [],
			'audio/mp4': [],
			'audio/amr': [],
		},
	});

	return {
		removeFile,
		files,
		getInputProps,
		getRootProps,
		openFileDialog,
		removeAllFiles,
		isFocused,
		isDragAccept,
		isDragReject,
		fileError,
		setFileError,
		onDrop,
	};
};
