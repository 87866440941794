import { Box, Stack } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid-pro';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
	StyledDataGridPro,
	StyledGridToolbarColumnsButton,
	StyledTitle,
} from './table-v2.styles';

import type { FC } from 'react';
import type {
	DataGridProProps,
	GridColDef,
	GridColumnResizeParams,
	GridRowParams,
	GridRowsProp,
} from '@mui/x-data-grid-pro';

export interface TableV2PropsInterface extends DataGridProProps {
	rows: GridRowsProp;
	columns: GridColDef[];
	withColumnSettings?: boolean;
	ToolbarContent?: JSX.Element;
	title?: string;
	handleRowClick?: (params: GridRowParams) => void;
	handleColumnResize?: (params: GridColumnResizeParams) => void;
	handleColumnOrderChange?: () => void;
}

export const TableV2: FC<TableV2PropsInterface> = ({
	rows,
	columns,
	withColumnSettings,
	ToolbarContent,
	title = '',
	slots,
	handleRowClick,
	handleColumnOrderChange,
	handleColumnResize,
	...props
}) => {
	// const [page, setPage] = useState(0);
	const hasToolbar = !!title || !!ToolbarContent;
	const { t } = useTranslation();

	// const handlePageChange = (
	// 	_: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
	// 	page: number,
	// ) => {
	// 	console.log(page);
	// 	setPage(page);
	// };

	const CustomFooter = useCallback(() => {
		return (
			<GridToolbarContainer>
				<Stack
					direction="row"
					justifyContent="space-between"
					sx={{ width: '100%', paddingBlock: '12px' }}
				>
					{/* we dont need the filter button since we introduce a custom filter? */}
					{/* <GridToolbarFilterButton /> */}
					<Stack direction="row">
						{withColumnSettings && <StyledGridToolbarColumnsButton />}
					</Stack>

					{/* TODO: Server pagination */}
					{/* <StyledGridPagination
						onPageChange={handlePageChange}
						page={page}
						labelRowsPerPage="Per page:"
					/> */}
				</Stack>
			</GridToolbarContainer>
		);
	}, [withColumnSettings]);

	const Toolbar = () => (
		<Box sx={{ paddingBlock: '20px', display: 'flex', alignItems: 'center' }}>
			<StyledTitle>{t(title)}</StyledTitle>
			<Box marginLeft="auto">{ToolbarContent}</Box>
		</Box>
	);

	return (
		<Box sx={{ height: '100%' }}>
			<StyledDataGridPro
				sx={{ height: '100%' }}
				rowCount={10}
				paginationMode="server"
				rows={rows || []}
				onColumnResize={handleColumnResize}
				onColumnOrderChange={handleColumnOrderChange}
				slots={{
					footer: CustomFooter,
					toolbar: hasToolbar ? Toolbar : undefined,
					...slots,
				}}
				onRowClick={handleRowClick}
				columns={columns}
				columnHeaderHeight={82}
				rowHeight={70}
				{...props}
			/>
		</Box>
	);
};
