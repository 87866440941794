import { useTranslation } from 'react-i18next';
import { mdiLogout, mdiSwapHorizontal } from '@mdi/js';
import { useCallback, useRef, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import {
	ExpiredSvg,
	HeylogLogoBlack,
	ROUTES,
	useAuthContext,
	useUser,
} from '@heylog-app/frontend-lib/app';
import { UserRoleEnum } from '@heylog-app/shared/types';

import {
	StyledButton,
	StyledButtonIcon,
	StyledButtonsContainer,
	StyledChip,
	StyledMenu,
	StyledMenuIcon,
	StyledMenuItem,
	StyledPageContainer,
	StyledPageContent,
	StyledText,
	StyledTitle,
} from './no-workspace.styles';

import type { FC } from 'react';

export const ExpiredInvitation: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { decoded } = useAuthContext();
	const { user } = useUser();
	const { workspaceId } = useParams();

	const workspaces =
		user?.role === UserRoleEnum.CONVERSATION_GUEST
			? [
					...new Set(
						user.guestConversations?.map((conversation) => conversation.workspaceId),
					),
			  ]
			: user?.workspaces?.map((workspace) => workspace.id);

	const workspaceUserWorkspaces = user?.workspaces || [];

	const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = useState(false);
	const switchWsButtonRef = useRef<HTMLButtonElement>(null);

	const handleSwitchWorkspaceClick = () => {
		setSwitchWorkspaceOpen(!switchWorkspaceOpen);
	};

	const handleLogout = useCallback(() => {
		navigate(ROUTES.LOGOUT);
	}, [navigate]);

	const closeSwitchMenu = useCallback(() => {
		setSwitchWorkspaceOpen(false);
	}, []);

	const handleWorkspaceClick = (id: number) => {
		navigate(
			generatePath(ROUTES.CONTACTS.OVERVIEW, {
				workspaceId: id.toString(),
			}),
		);
	};

	return (
		<StyledPageContainer data-test="expired-invitation-page">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<ExpiredSvg />
				</div>
				<StyledTitle>{t('workspaces.expired-invitation.title')}</StyledTitle>
				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('workspaces.expired-invitation.description'),
					}}
				/>
			</StyledPageContent>

			<StyledButtonsContainer>
				{decoded && (
					<StyledButton
						variant="outlined"
						color="secondary"
						onClick={handleLogout}
						data-test="logout-button"
					>
						<StyledButtonIcon path={mdiLogout} size={'1rem'} />
						{t('navigation.menu.logout')}
					</StyledButton>
				)}

				{!!workspaces?.length && workspaces.length > 1 && (
					<div>
						<StyledButton
							aria-controls="switch-workspace-page-disabled"
							data-test="switch-workspace-page-disabled"
							aria-haspopup="true"
							aria-expanded={switchWorkspaceOpen ? 'true' : undefined}
							ref={switchWsButtonRef}
							onClick={handleSwitchWorkspaceClick}
						>
							<StyledMenuIcon path={mdiSwapHorizontal} size={'1rem'} />
							{t('navigation.menu.changeTeam')}
						</StyledButton>

						<StyledMenu
							id="switch-workspace-page-disabled"
							open={switchWorkspaceOpen}
							onClose={closeSwitchMenu}
							anchorEl={switchWsButtonRef?.current}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{workspaceUserWorkspaces.length > 0
								? workspaceUserWorkspaces.map((workspace) => {
										return (
											<StyledMenuItem
												key={workspaceId}
												onClick={() => handleWorkspaceClick(workspace.id)}
												disabled={workspace.id === parseInt(workspaceId || '')}
											>
												{workspace.name}
												{workspace.id === parseInt(workspaceId || '') && (
													<StyledChip
														label={`${t('navigation.current')}`}
														variant="outlined"
													/>
												)}
											</StyledMenuItem>
										);
								  })
								: workspaces.map((guestWorkspaceId) => {
										return (
											<StyledMenuItem
												key={guestWorkspaceId}
												onClick={() => handleWorkspaceClick(guestWorkspaceId)}
												disabled={guestWorkspaceId === parseInt(workspaceId || '')}
											>
												{`Team ${guestWorkspaceId}`}
												{guestWorkspaceId === parseInt(workspaceId || '') && (
													<StyledChip
														label={`${t('navigation.current')}`}
														variant="outlined"
													/>
												)}
											</StyledMenuItem>
										);
								  })}
						</StyledMenu>
					</div>
				)}
			</StyledButtonsContainer>
		</StyledPageContainer>
	);
};
