import Icon from '@mdi/react';
import { Avatar, Button, DialogActions, Grid, Tab, TextField } from '@mui/material';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import { OverflowMenu, colors } from '@heylog-app/frontend-lib/app';
import { MessageDirectionEnum } from '@heylog-app/shared/types';

import Image from '../../images/avatar-bg@2x.jpg';

import type { MessageResInterface } from '@heylog-app/shared/types';

type StyledMessageGroupProps = {
	$direction: MessageResInterface['direction'];
	$inactive?: boolean;
};

export const StyledSettingsPageLayout = styled.div`
	position: relative;
	overflow: auto;
	height: 100%;
`;

export const StyledHeaderWrapper = styled.div`
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background-color: ${colors.common.white};
	z-index: 10;
	border-bottom: 1px solid ${colors.greyblue[225]};
`;

export const StyledHeaderTitle = styled.div`
	max-width: 1500px;
	margin: 0 auto;

	h5 {
		font-size: 1.2em;
		font-weight: 600;
	}
`;

export const StyledSettingsHeader = styled.div<{ paddingTop?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: ${(props) => props.paddingTop || '26px'};
	padding-bottom: 16px;
`;

export const StyledSettingsTitle = styled.div`
	margin-left: 20px;
	display: flex;
	align-items: center;
	font-weight: 700;
`;

export const StyledButtonsBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 400px;
	margin-right: 32px;
`;

export const StyledButtonBlock = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	padding-top: 24px;

	border-top: 1px solid ${colors.greyblue[225]};
`;

export const StyledSaveButton = styled(Button)<{ disabled?: boolean }>`
	${({ disabled }) => (disabled ? `color: ${colors.common.white} !important` : '')}
`;

export const StyledHeaderIcon = styled(Icon)`
	margin-right: 16px;
`;

export const StyledTab = styled(Tab)`
	text-transform: capitalize !important;
	padding: 12px;

	&:hover {
		color: ${colors.common.black};
	}
`;

export const StyledSettingsContentWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	max-width: 1500px;
	margin: 24px auto 40px auto;
`;

export const StyledWorkspaceSettingsContentWrapper = styled.div<{ width?: string }>`
	display: flex;
	flex-wrap: wrap;
	gap: 24px;
	width: ${(props) => props.width || '63%'};
	margin: 0 0 40px 0;
`;

export const StyledSettingsSection = styled.div<{ width?: string }>`
	display: flex;
	flex-direction: column;
	width: ${(props) => props.width || '32%'};
	padding: 24px 24px 15px;
	border: 1px solid ${colors.greyblue[225]};
	border-radius: 4px;
	background-color: #ffffff;
	scroll-margin-top: 180px;

	h6 {
		margin-bottom: 18px;
		line-height: 30px;
		font-size: 1.15em;
		font-weight: 600;
	}
`;

export const StyledMembersSection = styled.div`
	width: 100%;
	padding: 14px 10px 10px;
	border: 1px solid ${colors.greyblue[225]};
	border-radius: 4px;
	background-color: #ffffff;
	scroll-margin-top: 180px;
`;

export const StyledMessagesList = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const StyledParagraph = styled.p`
	margin-top: 0;
	margin-bottom: 18px;
	color: #171717;
	line-height: 1.35;
`;

export const StyledParagraphSmall = styled(StyledParagraph)`
	font-size: 14px;
`;

export const StyledTextField = styled(TextField)`
	margin-bottom: 24px;

	input {
		padding: 16px;
		font-size: 16px;
	}

	&:last-of-type {
		margin-bottom: 0;
	}
`;

export const StyledMember = styled(Grid)`
	padding: 16px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
	margin: 0 0 10px 0;
`;

export const StyledMemberName = styled.div`
	margin-left: 12px;
	font-size: 16px;
	line-height: 20px;
`;

export const StyledEmailIcon = styled(Icon)``;

export const StyledMemberEmail = styled.div`
	margin-left: 12px;
	font-size: 12px;
	display: flex;
	align-items: center;

	${StyledEmailIcon} {
		margin-right: 4px;
	}
`;

export const StyledUserInfoBlock = styled.div`
	flex: 1;
`;

export const StyledRemoveButton = styled(Button)`
	justify-self: flex-end;
	margin: 0 0 0 auto;
`;

export const PreviewContainer = styled.div`
	padding: 20px 10px;
	border: 1px solid ${colors.greyblue[225]};
	background-color: ${colors.common.white};
	border-radius: 4px;
`;

export const StyledSwitchWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 12px;

	border-top: 1px solid ${colors.greyblue[225]};
`;

export const StyledFooterWrapper = styled.div`
	max-width: 1500px;
	margin: 0 auto 56px auto;
`;

export const StyledFooterContent = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 40px;
	padding-top: 8px;
	border-top: 1px solid ${colors.greyblue[225]};
`;

export const StyledLink = styled.a`
	font-size: 14px;
	line-height: 17.64px;
	color: ${colors.greyblue[125]};
`;

export const StyledTextNavLink = styled(NavLink)`
	padding: 0;
	font-size: 14px;
	text-decoration: none;
	color: ${colors.primary.main};
	cursor: pointer;

	&:hover {
		color: ${colors.primary.lighter};
	}
`;

export const StyledDialogContent = styled(DialogActions)`
	line-height: 1.4em;
	padding: 20px 54px;
`;

export const StyledDialogActions = styled(DialogActions)`
	justify-content: space-between;
	padding: 0 3rem 2rem 3rem;
`;

export const MembersStyledButton = styled(StyledRemoveButton)`
	margin: 0;
`;

export const PendingUserInvitations = styled.div`
	justify-self: flex-end;
	margin: 0 0 0 auto;
`;

export const StyledDescription = styled.div`
	margin-top: 12px;
	p {
		margin: 0;
		color: #171717;
		line-height: 1.35;
	}
`;

export const StyledScreenshotsWrapper = styled.div`
	display: flex;
	gap: 16px;
`;

export const StyledScreenshot = styled.img`
	height: 400px;
	object-fit: contain;
	object-position: left;
	margin-bottom: 30px;
`;

export const StyledList = styled.ul`
	margin: -20px 0 0 0;
	padding: 0 0 0 1.5em;
`;

export const StyledMessageGroup = styled.div<StyledMessageGroupProps>`
	display: flex;
	width: 20rem;
	flex-direction: column;
	align-items: ${({ $direction }) =>
		$direction === MessageDirectionEnum.TO_CONTACT ? 'flex-end' : 'flex-start'};
`;

export const StyledTyphographyHeader = styled.div`
	margin-bottom: 25px;
	border-bottom: 1px solid ${colors.greyblue[225]};
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;

export const StyledOverflowMenu = styled(OverflowMenu)`
	margin-left: auto;
`;
