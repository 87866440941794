import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { type FC } from 'react';

import { useApiClientContext, useLabels } from '../../hooks';
import { createLabelAPI } from '../../util';

interface LabelsAutocompleteProps {
	className?: string;
}

export const LabelsAutocomplete: FC<LabelsAutocompleteProps> = ({ className }) => {
	const { workspaceId = '' } = useParams();
	const { labels, mutateLabels } = useLabels(workspaceId);
	const { apiClient } = useApiClientContext();

	const onCreateLabel = async (label: string) => {
		createLabelAPI(
			apiClient,
			{ value: label, type: 'label' },
			parseInt(workspaceId),
		).then(() => mutateLabels());
	};

	const onInputChange = (value: string[], reason: string) => {
		console.log(value, reason);

		if (reason === 'createOption') {
			const labelToCreate = value[value.length - 1];
			if (labelToCreate) {
				onCreateLabel(labelToCreate);
			}
		}
	};

	return (
		<Stack sx={{ width: '100%' }}>
			<Autocomplete
				className={className}
				multiple
				id="tags-filled"
				options={labels?.map((option) => option.value) || []}
				freeSolo
				onChange={(event, value, reason) => onInputChange(value, reason)}
				renderTags={(value: readonly string[], getTagProps) =>
					value.map((option: string, index: number) => (
						<Chip variant="outlined" label={option} {...getTagProps({ index })} />
					))
				}
				renderInput={(params) => (
					<TextField {...params} variant="outlined" size="small" />
				)}
			/>
		</Stack>
	);
};
