import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18next from 'i18next';
import { useEffect, useState } from 'react';

import {
	StyledLogo,
	StyledMobileInfoWrapper,
	StyledMobileMainInfoWrapper,
	StyledText,
	StyledMobileAppIcon,
	StyledHomeLink,
	StyledMobileAppIconWrapper,
} from './mobile-info.styles';
import logo from '../../assets/Heylog-Icon-Transparent-M.png';

import type { Nullable } from 'ts-toolbelt/out/Union/Nullable';

type StoreIcons = {
	[key: string]: JSX.Element;
};

export const MobileInfo = () => {
	const { t } = useTranslation();
	const [storeIcons, setStoreIcons] = useState<Nullable<StoreIcons>>(null);

	useEffect(() => {
		const fetchIcons = async () => {
			const newGoogleIcon = await import(`../icons/icon-google-play-${i18next.language}`);
			const newAppleIcon = await import(`../icons/icon-appstore-${i18next.language}`);

			setStoreIcons({
				google: newGoogleIcon.IconGooglePlay(),
				apple: newAppleIcon.IconAppStore(),
			});
		};
		fetchIcons();
	}, []);

	return (
		<StyledMobileInfoWrapper>
			<StyledMobileMainInfoWrapper>
				<Link to="https://www.heylog.com/">
					<StyledLogo src={logo} alt="" />
				</Link>
				<StyledText>
					{t('mobileScreen.infoTextFirstPart')}
					<br />
					<br />
					{t('mobileScreen.infoTextSecondPart')}
				</StyledText>

				<StyledMobileAppIconWrapper>
					<Link
						to={`https://apps.apple.com/at/app/heylog/id6448185455?l=${i18next.language}`}
					>
						<StyledMobileAppIcon>
							{storeIcons ? storeIcons['apple'] : ''}
						</StyledMobileAppIcon>
					</Link>
					<Link to="https://play.google.com/store/apps/details?id=com.heylog">
						<StyledMobileAppIcon>
							{storeIcons ? storeIcons['google'] : ''}
						</StyledMobileAppIcon>
					</Link>
				</StyledMobileAppIconWrapper>
			</StyledMobileMainInfoWrapper>
			<StyledHomeLink data-test="home-link" to="https://www.heylog.com">
				{t('mobileScreen.homeLink')}
			</StyledHomeLink>
		</StyledMobileInfoWrapper>
	);
};
