import {
	mdiTruckFast,
	mdiArchiveCheck,
	mdiArchiveOutline,
	mdiFlagCheckered,
	mdiTrainCarCenterbeamFull,
	mdiCheckCircle,
	mdiFile,
	mdiAlertCircle,
	mdiArrowBottomRight,
} from '@mdi/js';
import Icon from '@mdi/react';

import { OrderTaskStatusEnum, UserOrderStatusEnum } from '@heylog-app/shared/types';

import type { OrderTaskStatus, UserOrderStatus } from '@heylog-app/shared/types';
import type { FC } from 'react';

type OrderStatusIconProps = {
	status: UserOrderStatus | OrderTaskStatus;
};

export const OrderStatusIcon: FC<OrderStatusIconProps> = ({ status }) => {
	switch (status) {
		case UserOrderStatusEnum.CREATED:
			return <Icon path={mdiFile} size={0.6} />;
		case UserOrderStatusEnum.ACCEPTED:
			return <Icon path={mdiCheckCircle} size={0.6} />;
		case UserOrderStatusEnum.IN_TRANSIT:
			return <Icon path={mdiTruckFast} size={0.6} />;
		case UserOrderStatusEnum.DELIVERED:
			return <Icon path={mdiArchiveCheck} size={0.6} />;
		case UserOrderStatusEnum.ARCHIVED:
			return <Icon path={mdiArchiveOutline} size={0.6} />;
		case UserOrderStatusEnum.PROBLEM:
			return <Icon path={mdiAlertCircle} size={0.6} />;
		case UserOrderStatusEnum.ARRIVED_AT_LOADING:
			return <Icon path={mdiFlagCheckered} size={0.6} />;
		case UserOrderStatusEnum.LOADED:
			return <Icon path={mdiTrainCarCenterbeamFull} size={0.6} />;
		case UserOrderStatusEnum.DEPARTED_FROM_LOADING:
			return <Icon path={mdiTruckFast} size={0.6} />;
		case UserOrderStatusEnum.ARRIVED_AT_UNLOADING:
			return <Icon path={mdiFlagCheckered} size={0.6} />;
		case UserOrderStatusEnum.APPROACHING:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;
		// @TODO: choose the right icons for the following statuses
		case OrderTaskStatusEnum.ARCHIVED:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;
		case OrderTaskStatusEnum.CANCELLED:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;
		case OrderTaskStatusEnum.COMPLETED:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;
		case OrderTaskStatusEnum.IN_PROGRESS:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;
		case OrderTaskStatusEnum.PENDING:
			return <Icon path={mdiArrowBottomRight} size={0.6} />;

		default:
			return null;
	}
};
