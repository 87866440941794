import styled, { css } from 'styled-components';
import { Button, MenuItem } from '@mui/material';

import { T3_medium, colors } from '../../styles';

export const StyledButton = styled(Button)<{ $isV2?: boolean }>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${({ $isV2 }) =>
		$isV2 &&
		css`
			padding: 8px 18px;
			border-radius: 4px;
			box-shadow: unset;
			${T3_medium};
			min-width: fit-content;
			height: fit-content;
			background-color: ${colors.grey[200]};
			color: ${colors.primary.main};

			&:hover {
				background-color: ${colors.primary.lighter};
				box-shadow: unset;
			}
		`}
`;

export const StyledMenuItem = styled(MenuItem)`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	& .MuiListItemIcon-root {
		width: 16px;
		min-width: 24px;
	}
`;
