import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';
import { useCallback } from 'react';

import {
	HeylogPluginEnum,
	UserRoleEnum,
	WorkspaceStatusEnum,
} from '@heylog-app/shared/types';
import {
	timestampIsExpired,
	uiLanguages,
	userIsInWsOrConv,
} from '@heylog-app/shared/util';

import {
	useAuthContext,
	useCurrentLanguage,
	useEnvContext,
	useLocalStorage,
	useRelevantWorkspace,
	useUser,
	useWorkspace,
} from '../../hooks';
import { defineAbilitiesFor, LOCAL_STORAGE_LANGUAGE, ROUTES } from '../../util';
import {
	AbilityContext,
	MessagesProvider,
	OrderProvider,
	PusherBeamsProvider,
	PusherChannelsProvider,
} from '../../providers';

import type { FC } from 'react';

export const ProtectedRoute: FC = () => {
	const [lastVisitedWsId, setLastVisitedWsId] = useLocalStorage('lastVisitedWsId', '');
	const { workspaceId = '', contactId, conversationId = '' } = useParams();
	const { user, isLoading } = useUser();
	const { token, decoded } = useAuthContext();
	const { production } = useEnvContext();
	const { workspace } = useWorkspace(workspaceId ? workspaceId : '', user);
	const { currentLanguage, setCurrentLanguage, detectedLanguage } = useCurrentLanguage();
	const { relevantWorkspace } = useRelevantWorkspace(user);

	const userWorkspaceIds = user?.workspaces?.map((workspace) => workspace.id);
	const guestUserContactIds = user?.guestConversations?.map(
		(conversation) => conversation.contactId,
	);
	const guestUserConversationIds = user?.guestConversations?.map(
		(conversation) => conversation.id,
	);
	const guestUserWorkspaceIds = user?.guestConversations?.map(
		(conversation) => conversation.workspaceId,
	);

	let ability = defineAbilitiesFor(undefined, undefined);

	if (workspaceId && guestUserWorkspaceIds && user) {
		ability = defineAbilitiesFor(parseInt(workspaceId), user);
	}

	const mergedWorkspaceIds =
		userWorkspaceIds && guestUserWorkspaceIds
			? [...userWorkspaceIds, ...guestUserWorkspaceIds]
			: [];

	const isInWsOrConv = userIsInWsOrConv(
		mergedWorkspaceIds ? mergedWorkspaceIds : userWorkspaceIds,
		workspaceId,
	);

	const prLogger = useCallback(
		(...args: unknown[]) => !production && console.log('[ProtectedRoute]:', ...args),
		[production],
	);

	if (!localStorage.getItem(LOCAL_STORAGE_LANGUAGE) && detectedLanguage) {
		setCurrentLanguage(detectedLanguage);
	}

	if (!uiLanguages.includes(currentLanguage) && detectedLanguage) {
		setCurrentLanguage(detectedLanguage);
	}

	if (decoded && timestampIsExpired(decoded.exp)) {
		prLogger('Expired token', { workspaceId, user, isLoading, decoded });
		return <Navigate to={ROUTES.LOGOUT} />;
	} else if (!token || (!isLoading && !user)) {
		prLogger('route to logout', { workspaceId, user, isLoading, decoded });
		return <Navigate to={ROUTES.LOGOUT} />;
	} else if (
		workspace &&
		workspaceId &&
		workspace.status !== WorkspaceStatusEnum.ACTIVE
	) {
		const path = generatePath(ROUTES.WORKSPACES.DISABLED_WORKSPACE, { workspaceId });
		prLogger('Workspace is disabled', { workspaceId, user, isLoading, decoded });
		return <Navigate to={path} />;
	} else if (
		decoded &&
		relevantWorkspace &&
		!workspaceId &&
		!timestampIsExpired(decoded.exp)
	) {
		let path;

		// cannot use usePlugins, because i get the workspace data inside the page
		// TODO: refactor usePlugin to be bases on user, not on workspace
		const isV2 = user?.workspaces?.find(
			(w) =>
				w.id === relevantWorkspace &&
				w.pluginsEnabled &&
				w.pluginsEnabled?.find((p) => p.plugin === HeylogPluginEnum.FrontendV2Plugin),
		);
		if (isV2) {
			path = generatePath(ROUTES.HOME, {
				workspaceId: relevantWorkspace,
			});
		} else {
			path = generatePath(ROUTES.HOME_V1, {
				workspaceId: relevantWorkspace,
			});
		}

		prLogger(`[Routing]: rerouting user to default ${path}`);
		return <Navigate to={path} />;
	}

	if (user && user.role !== UserRoleEnum.CONVERSATION_GUEST) {
		if (!workspaceId || mergedWorkspaceIds.length === 0) {
			prLogger('No workspace found', {
				userWorkspaceIds,
				user,
				workspaceId,
				workspace,
				mergedWorkspaceIds,
			});
			return <Navigate to={ROUTES.WORKSPACES.NO_WORKSPACE} />;
		} else if (!isInWsOrConv) {
			prLogger(`No access for the user to workspace, navigating home`, { user });

			const path = generatePath(ROUTES.HOME, {
				workspaceId: relevantWorkspace,
			});

			return <Navigate to={path} />;
		}
	}

	if (user && user.role === UserRoleEnum.CONVERSATION_GUEST) {
		if (!guestUserWorkspaceIds || !guestUserWorkspaceIds.length) {
			prLogger(`Guest user has no workspaces`, { user });
			return <Navigate to={ROUTES.WORKSPACES.NO_CONVERSATION} />;
		}

		if (
			(workspaceId && !guestUserWorkspaceIds?.includes(parseInt(workspaceId))) ||
			(contactId && !guestUserContactIds?.includes(parseInt(contactId))) ||
			(conversationId && !guestUserConversationIds?.includes(parseInt(conversationId)))
		) {
			prLogger(`No access to workspaceId, contactId, or conversationId`, { user });
			return <Navigate to={ROUTES.NOT_FOUND} />;
		}
		if (!workspaceId && !contactId && !conversationId && relevantWorkspace) {
			prLogger(`No access to workspace for the guest, navigating home`, { user });

			const path = generatePath(ROUTES.HOME, {
				workspaceId: relevantWorkspace,
			});

			return <Navigate to={path} />;
		}
	}

	// Save workspaceId to localstorage, only if the workspace ID is active
	if (workspaceId && lastVisitedWsId !== workspaceId && workspace?.status === 'ACTIVE') {
		setLastVisitedWsId(workspaceId);
	}

	// everything fine -> render content
	prLogger('route to outlet', { workspaceId, user, isLoading, decoded, workspace });

	return (
		<AbilityContext.Provider value={ability}>
			<PusherBeamsProvider>
				<PusherChannelsProvider>
					<MessagesProvider>
						<OrderProvider>
							<Outlet />
						</OrderProvider>
					</MessagesProvider>
				</PusherChannelsProvider>
			</PusherBeamsProvider>
		</AbilityContext.Provider>
	);
};
