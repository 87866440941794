import useSWR from 'swr';
import { useEffect, useMemo, useState } from 'react';
import { useAbility } from '@casl/react';

import { Actions, WhatsAppTemplateNames } from '@heylog-app/shared/types';

import { getFetcher, templateRegEx } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { useContact } from './use-contacts.hooks';
import { AbilityContext } from '../providers';
import { useUser } from './use-user.hook';

import type { WhatsAppMessageTemplateResInterface } from '@heylog-app/shared/types';
import type { Key } from 'swr';

const TEMPLATES_KEY: Key = '/conversations/whatsapp/templates';

export const useTemplates = () => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, isLoading } = useSWR<WhatsAppMessageTemplateResInterface[]>(
		ability.can(Actions.MANAGE, 'Workspace') ? TEMPLATES_KEY : null,
		getFetcher(apiClient),
		{
			revalidateOnFocus: false,
		},
	);

	return {
		templates: data,
		isLoading,
		isError: error,
	};
};

export const useTemplateMessage = ({
	template,
	contactId,
	parametersFromInput,
}: {
	template?: WhatsAppMessageTemplateResInterface;
	contactId: string;
	// This is helping for viber template handling
	parametersFromInput?: string[];
}) => {
	const { contact } = useContact(contactId);
	const templateBodyText =
		template?.components.find((component) => component.type === 'BODY')?.text || '';

	const splitText = templateBodyText?.split(templateRegEx);
	const parameters = splitText?.filter((item) => item.match(templateRegEx));

	const { user } = useUser();

	const contactName = `${contact?.firstName} ${contact?.lastName}`;
	const userName = `${user?.firstName} ${user?.lastName}`;
	const questionText =
		parametersFromInput?.length && parametersFromInput[0] ? parametersFromInput[0] : '';

	const getInitialParameterValues = useMemo((): string[] => {
		switch (template?.name) {
			case WhatsAppTemplateNames.NEW_MESSAGE: {
				return [contactName, userName];
			}

			case WhatsAppTemplateNames.I_HAVE_A_QUESTION: {
				return [questionText ?? ''];
			}
			default:
				return [];
		}
	}, [template, questionText, contactName, userName]);

	const [parameterValues, setParameterValues] = useState<string[]>(
		getInitialParameterValues,
	);

	useEffect(() => {
		switch (template?.name) {
			case WhatsAppTemplateNames.NEW_MESSAGE: {
				setParameterValues([contactName, userName]);
				break;
			}

			case WhatsAppTemplateNames.I_HAVE_A_QUESTION: {
				setParameterValues([questionText ?? '']);
				break;
			}

			default:
				break;
		}
	}, [template, contact, user, questionText, contactName, userName]);

	useEffect(() => {
		if (
			parametersFromInput?.length &&
			template?.name === WhatsAppTemplateNames.I_HAVE_A_QUESTION &&
			parameterValues[0] !== questionText
		) {
			setParameterValues((prevValues) =>
				prevValues.map((value, index) => (index === 0 ? questionText ?? '' : value)),
			);
		} else if (
			parametersFromInput?.length &&
			JSON.stringify(parametersFromInput) !== JSON.stringify(parameterValues)
		) {
			setParameterValues(parametersFromInput);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parametersFromInput, questionText, template?.name]);

	const finalTemplateText = useMemo(() => {
		return splitText?.map((item) => {
			if (item.match(templateRegEx)) {
				const parameterIndex = parameters?.indexOf(item) ?? -1;

				if (parameterIndex < 0) return null;

				const parameterValue = parameterValues[parameterIndex];
				return parameterValue !== null && parameterValue !== undefined
					? parameterValue
					: '';
			}
			return item;
		});
	}, [splitText, parameters, parameterValues]);

	return {
		finalTemplateText,
		setParameterValues,
		parameterValues,
		splitText,
		parameters,
	};
};
