import { t } from 'i18next';

export const buildOSUMessage = (selectedLanguage: string, orderId: string) => {
	return `${t('orderStatusUpdate.orderStatusUpdateMessage', {
		orderId,
		lng: selectedLanguage,
		statusUpdateURL: 'https://app.heylog.com/...',
	})}`;
};

export const buildOSUConfirmationMessage = (
	selectedLanguage: string,
	currentStatus: string,
	orderId: string,
) => {
	return `${t('orderStatusUpdate.orderStatusUpdateConfirmationMessage', {
		orderId,
		currentStatus,
		lng: selectedLanguage,
	})}`;
};

export const buildOSUFinalMessage = (selectedLanguage: string, orderId: string) => {
	return `${t('orderStatusUpdate.orderStatusUpdateFinishMessage', {
		orderId,
		lng: selectedLanguage,
	})}`;
};
