import { createContext, useCallback, useState } from 'react';

import { FlashSnackbar } from '../components';
import { useSnackbar } from '../hooks';
import { OrderDialog } from '../components/order-dialog/order-dialog';

import type { OrderDialogInterface } from './order-provider.types';
import type { FC, PropsWithChildren } from 'react';

export const OrderContext = createContext<{
	onOpenOrderDialog?: (data: OrderDialogInterface) => void;
}>({});

export const OrderProvider: FC<PropsWithChildren> = ({ children }) => {
	const [orderDialogs, setOrderDialogs] = useState<OrderDialogInterface[]>([]);
	const [clickedDialog, setClickedDialog] = useState('');
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const onOpenOrderDialog = useCallback(
		(data: OrderDialogInterface) => {
			if (orderDialogs.length >= 8)
				return openSnackbar('error', 'Maximum 8 dialogs at one time allowed');

			const id = crypto.randomUUID();
			const newDialog = {
				...data,
				id,
			};

			setOrderDialogs((prev) => [...prev, newDialog]);
			setClickedDialog(id);
		},
		[orderDialogs, openSnackbar],
	);

	const onClose = useCallback((id: string) => {
		setOrderDialogs((prev) => prev.filter(({ id: dialogId }) => dialogId !== id));
	}, []);

	return (
		<OrderContext.Provider value={{ onOpenOrderDialog: onOpenOrderDialog }}>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			{orderDialogs.map((dialog, idx) => {
				const isFocusedDialog = clickedDialog === dialog.id;
				const focusedDialogZIndex = 99;
				const notFocusedDialogZIndex = 98;
				const zIndex = isFocusedDialog ? focusedDialogZIndex : notFocusedDialogZIndex;

				return (
					<OrderDialog
						action={dialog.action}
						id={dialog.id || ''}
						idx={idx}
						isFocused={isFocusedDialog}
						key={dialog.id}
						onClick={setClickedDialog}
						onClose={onClose}
						payload={dialog.payload}
						zIndex={zIndex}
					/>
				);
			})}

			{children}
		</OrderContext.Provider>
	);
};
