import {
	FormControl,
	FormHelperText,
	InputLabel,
	Select as MUISelect,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import type { SelectProps as MuiSelectProps, FormControlProps } from '@mui/material';
import type { Control, FieldValues, ControllerProps } from 'react-hook-form';
import type { FC, ReactNode } from 'react';

type ControlledSelectProps = {
	children?: ReactNode;
	control: Control<FieldValues>;
	label: string;
	id: string;
	selectOptions?: MuiSelectProps;
	formControlOptions?: FormControlProps;
	rules?: ControllerProps['rules'];
	errorMessage?: string;
	margin?: 'none' | 'dense' | 'normal';
	defaultValue?: string;
	disabled?: boolean;
};

/** MUI select for usage with react-hook-form */
export const ControlledSelect: FC<ControlledSelectProps> = ({
	control,
	label,
	id,
	children,
	selectOptions,
	formControlOptions,
	rules,
	margin = 'dense',
	errorMessage,
	defaultValue,
	...rest
}) => {
	const { t } = useTranslation();

	return (
		<FormControl fullWidth {...formControlOptions} margin={margin}>
			<InputLabel id={`${id}-label`}>{t(label)}</InputLabel>

			<Controller
				render={({ field: { ...props } }) => (
					<>
						<MUISelect
							labelId={`${id}-label`}
							label={t(label)}
							id={id}
							{...selectOptions}
							{...props}
							value={selectOptions?.value || props.value || ''}
							onChange={selectOptions?.onChange || props.onChange}
							{...rest}
						>
							{children}
						</MUISelect>
						{selectOptions?.error && (
							<FormHelperText error>{errorMessage}</FormHelperText>
						)}
					</>
				)}
				name={id}
				control={control}
				rules={rules}
				defaultValue={defaultValue}
			/>
		</FormControl>
	);
};
