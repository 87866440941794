import { useEffect } from 'react';
import { Link, useParams, generatePath } from 'react-router-dom';
import { mdiMenu, mdiMenuOpen } from '@mdi/js';
import { Grid, Stack } from '@mui/material';
import Icon from '@mdi/react';

import { ROUTES } from '../../util';
import { useTitle } from '../../hooks';
import {
	StyledLogoWrapper,
	StyledMenuButton,
	StyledNavigation,
	StyledToolbar,
} from './navigation-v2.styles';
import { StyledGridSidebar } from '../sidebar';
import { HeylogLogoBlue } from '../icons';
import { UserMenu } from '../navigation/user-menu/user-menu';

import type { FC } from 'react';

interface NavigationV2PropsInterface {
	isOpen: boolean;
	onBurgerClick: () => void;
}

export const NavigationV2: FC<NavigationV2PropsInterface> = ({
	isOpen,
	onBurgerClick,
}) => {
	const params = useParams();

	const { activeUnreadMessagesSum, setTitle, cleanTitle } = useTitle();

	useEffect(() => {
		setTitle();
		return () => cleanTitle();
	}, [setTitle, activeUnreadMessagesSum, cleanTitle]);

	return (
		<StyledNavigation container gap={2} columns={{ xs: 12 }}>
			<StyledMenuButton onClick={onBurgerClick}>
				{isOpen ? (
					<Icon color="#fff" path={mdiMenuOpen} size={1} />
				) : (
					<Icon color="#fff" path={mdiMenu} size={1} />
				)}
			</StyledMenuButton>
			<StyledGridSidebar>
				<StyledLogoWrapper>
					<Link to={generatePath(ROUTES.HOME, params)}>
						<HeylogLogoBlue color="#fff" />
					</Link>
				</StyledLogoWrapper>
			</StyledGridSidebar>

			<Grid item xs={11}>
				<Stack direction="row" sx={{ height: '100%' }}>
					<StyledToolbar>
						<UserMenu />
					</StyledToolbar>
				</Stack>
			</Grid>
		</StyledNavigation>
	);
};
