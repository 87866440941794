import {
	StyledFormContainer,
	StyledFormWrapper,
	StyledHeylogLogoBlack,
} from './form-wrapper.styles';

import type { FC, ReactNode } from 'react';

type FormWrapperProps = {
	children: ReactNode;
};

export const FormWrapper: FC<FormWrapperProps> = ({ children }) => {
	return (
		<StyledFormWrapper>
			<StyledHeylogLogoBlack />

			<StyledFormContainer>{children}</StyledFormContainer>
		</StyledFormWrapper>
	);
};
