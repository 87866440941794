import { useTranslation } from 'react-i18next';
import { ChevronRight } from '@mui/icons-material';

import { getCommunicationChannelIcon } from '@heylog-app/frontend-lib/app';

import {
	StyledActivePill,
	StyledButton,
	StyledInactivePill,
} from './messenger-button.styles';

import type { FC } from 'react';
import type { MessagingTransportType } from '@heylog-app/shared/types';

type MessengerButtonProps = {
	type: MessagingTransportType;
	disabled?: boolean;
	active?: boolean;
};

export const MessengerButton: FC<MessengerButtonProps> = ({
	type,
	active = false,
	disabled,
	...rest
}) => {
	const { t } = useTranslation();

	const Icon = getCommunicationChannelIcon(type);

	return (
		<StyledButton
			variant="outlined"
			color="secondary"
			startIcon={<Icon />}
			disabled={disabled}
			endIcon={
				<>
					{active ? (
						<StyledActivePill>{t('settings.active')}</StyledActivePill>
					) : (
						<StyledInactivePill>{t('settings.inactive')}</StyledInactivePill>
					)}
					<ChevronRight />
				</>
			}
			sx={{ '& .MuiButton-endIcon': { marginLeft: 'auto' } }}
			{...rest}
		>
			{t(`join.transportTypes.${type}`)}
		</StyledButton>
	);
};
