import type { PureAbility } from '@casl/ability';

export enum Actions {
	//wildcard for all CRUD actions
	MANAGE = 'manage',
	READ = 'read',
	CREATE = 'create',
	UPDATE = 'update',
	ARCHIVE = 'archive',
	REMOVE = 'remove',
	SEND_MESSAGE = 'sendMessage',
	BECOME_GUEST = 'becomeGuest',
	ACCEPT_INVITATION = 'acceptInvitation',
}

export type Subjects = 'Workspace' | 'Conversation' | 'all';

export type AppAbility = PureAbility<[Actions, Subjects]>;
