import { StyledSidebar } from './sidebar.styles';

import type { FC, ReactNode } from 'react';

export type SidebarProps = {
	children: ReactNode;
	variant: 'primary' | 'secondary' | 'conversations';
	border?: string;
	marginLeft?: number | string;
	size?: number;
	width?: string;
	dataTest?: string;
};

export const Sidebar: FC<SidebarProps> = ({
	border = '',
	marginLeft = 0,
	variant,
	children,
	size,
	dataTest,
	width,
}) => {
	return (
		<StyledSidebar
			data-test={dataTest}
			item
			$variant={variant}
			$width={width}
			sx={{ maxWidth: size, marginLeft, border }}
		>
			{children}
		</StyledSidebar>
	);
};
