import styled from 'styled-components';

import { colors } from '../../styles';

export const StyledDivWrapper = styled.div<{
	$editorMinHeight?: number;
	$withBorders?: boolean;
}>`
	.ck {
		&-toolbar {
			background-color: ${colors.grey[200]};
			border-radius: 4px 4px 0 0 !important;
			border-color: ${colors.grey[300]};
			border-width: ${({ $withBorders }) => ($withBorders ? '1' : '0')};
		}
		&-editor {
			display: flex;
			flex-direction: column;

			&__main {
				.ck-content {
					border-radius: 0 0 4px 4px !important;
					border-color: ${colors.grey[300]} !important;
					border-width: ${({ $withBorders }) => ($withBorders ? '1' : '0')};
					border-top: 0;
					min-height: ${({ $editorMinHeight }) =>
						$editorMinHeight ? $editorMinHeight : 0}px;
				}
			}
		}
	}
`;
