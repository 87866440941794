export const getOrderKey = (workspaceId: string) => {
	return `/workspaces/${workspaceId}/orders`;
};

export const getOrderConnectionsKey = (workspaceId: string, orderId: string) => {
	return `/workspaces/${workspaceId}/orders/${orderId}/contacts`;
};

export const getOrderDisconnectionsKey = (
	workspaceId: string,
	orderId: string,
	contactId: string,
) => {
	return `/workspaces/${workspaceId}/orders/${orderId}/contacts/${contactId}`;
};

export const getLiveLocationDataKey = (workspaceId: string, orderId: string) => {
	return `/workspaces/${workspaceId}/orders/${orderId}/live-location-data`;
};
