import Icon from '@mdi/react';
import styled from 'styled-components';

import { colors, SIZE_SM } from '@heylog-app/frontend-lib/app';

export const StyledDeleteIcon = styled(Icon)`
	padding-right: 2px;
	opacity: 0;
	transition: all;
	transition-duration: 0.3s;
	color: ${colors.primary.main};
	width: 16px;
	margin-left: auto;
`;

export const StyledGuestUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	transition: all;
	transition-duration: 0.3s;
	background-color: ${colors.grey[250]};

	${SIZE_SM}

	:hover {
		background-color: ${colors.common.white};
		cursor: pointer;

		${StyledDeleteIcon} {
			opacity: 1;
		}
	}
`;

export const StyledName = styled.div<{ $inactive?: boolean }>`
	display: flex;
	gap: 2px;
	margin-bottom: 2px;
	color: ${({ $inactive }) => ($inactive ? `${colors.common.black}` : '')};
	opacity: ${({ $inactive }) => ($inactive ? '0.5' : '1')};

	p {
		margin: 1px 0;
	}
`;

export const StyledIcon = styled(Icon)`
	color: ${colors.greyblue[125]};
`;
