import { StrictMode } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	ArcElement,
	BarElement,
	Filler,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
	AuthContextProvider,
	EnvironmentProvider,
	ApiClientProvider,
	theme,
	initI18n,
	LanguageProvider,
	BrowserNotificationsProvider,
	UiContextProvider,
} from '@heylog-app/frontend-lib/app';

import { environment } from '../environments/environment';
import { router } from './app.routes';

initI18n();

ChartJS.register(
	ArcElement,
	CategoryScale,
	BarElement,
	LinearScale,
	PointElement,
	LineElement,
	Filler,
	Title,
	Tooltip,
	annotationPlugin,
);

LicenseInfo.setLicenseKey(environment.MUI_PRO);
export const App = () => {
	return (
		<StrictMode>
			<EnvironmentProvider environment={environment}>
				<LanguageProvider>
					<AuthContextProvider>
						<MUIThemeProvider theme={theme}>
							<BrowserNotificationsProvider>
								<ApiClientProvider>
									<UiContextProvider>
										<RouterProvider router={router} />
									</UiContextProvider>
								</ApiClientProvider>
							</BrowserNotificationsProvider>
						</MUIThemeProvider>
					</AuthContextProvider>
				</LanguageProvider>
			</EnvironmentProvider>
		</StrictMode>
	);
};
