import React from 'react';
import { Bar } from 'react-chartjs-2';
import dayjs from 'dayjs';
import { Box, Stack } from '@mui/material';
import Icon from '@mdi/react';
import { mdiArrowBottomLeft, mdiArrowTopRight } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import { colors, typographyV2 } from '../../styles';
import {
	StyledDifferenceValue,
	StyledTitle,
	StyledTotalLabel,
	StyledTotalValue,
} from './bar-chart.styles';

import type { Dayjs } from 'dayjs';
import type { FC } from 'react';
import type { ChartData, ChartOptions, TooltipItem, TooltipModel } from 'chart.js';

const getOptions = (chartData: BarChartDataInterface[]): ChartOptions<'bar'> => ({
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		tooltip: {
			backgroundColor: colors.primary.darker,
			displayColors: false,
			callbacks: {
				title(this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>[]) {
					const [item] = tooltipItem;
					if (!item) return '';

					const currentData = chartData[item.dataIndex];

					return dayjs(currentData?.date).format('ddd, DD.MM.YYYY');
				},
				label(this: TooltipModel<'bar'>, tooltipItem: TooltipItem<'bar'>) {
					return tooltipItem.formattedValue;
				},
			},
		},
		legend: {
			display: false,
		},
	},
	scales: {
		x: {
			grid: { display: false },
			offset: true,
			ticks: {
				color: colors.grey[600],
				font: {
					family: typographyV2.fontFamily,
				},
			},
		},
		y: {
			ticks: {
				color: colors.grey[600],
				font: {
					family: typographyV2.fontFamily,
				},
				precision: 10,
			},
			border: { color: 'rgba(0, 0, 0, 0)' }, // Set the color of the grid lines to transparent},
		},
	},
});

const getData = (
	chartData: BarChartDataInterface[],
): ChartData<'bar', number[], number> => ({
	labels: chartData.map(({ label }) => label),
	datasets: [
		{
			data: chartData.map(({ value }) => value),
			backgroundColor: colors.primary.light,
			hoverBackgroundColor: colors.primary.main,
			borderRadius: 12,
			borderSkipped: false,
			barPercentage: 0.6,
		},
	],
});

interface BarChartDataInterface {
	label: number;
	value: number;
	date: Dayjs;
}

interface BarChartInterface {
	title: string;
	total: number;
	renderData: BarChartDataInterface[];
	difference?: {
		isGrowing: boolean;
		value: number | string;
	};
}

export const BarChart: FC<BarChartInterface> = ({
	difference,
	title,
	total,
	renderData,
}) => {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				border: `1px solid ${colors.border.main}`,
				padding: '20px 16px',
				height: '100%',
				width: '100%',
				borderRadius: '4px',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<Stack
				direction="row"
				marginBottom={4}
				alignItems="center"
				justifyContent="space-between"
			>
				<StyledTitle>{title}</StyledTitle>

				<Stack direction="row" alignItems="center" gap={2}>
					<StyledTotalLabel>{t('dashboardMain.totalForThePeriod')}:</StyledTotalLabel>
					<StyledTotalValue>{total}</StyledTotalValue>
					{difference && (
						<StyledDifferenceValue isGrowing={difference.isGrowing}>
							{difference.isGrowing ? (
								<Icon path={mdiArrowTopRight} size={1} />
							) : (
								<Icon path={mdiArrowBottomLeft} size={1} />
							)}
							{difference.value}
						</StyledDifferenceValue>
					)}
				</Stack>
			</Stack>
			<Box height="100%">
				<Bar options={getOptions(renderData)} data={getData(renderData)} />
			</Box>
		</Box>
	);
};
