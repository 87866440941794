import { Button, Grid, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import {
	FlashSnackbar,
	parseAxiosErrorMessage,
	useApiClientContext,
	useSnackbar,
	emailValidationRegEx,
} from '@heylog-app/frontend-lib/app';

import { FormWrapper } from '../../components/form-wrapper';
import { SignupInImage } from '../../components/ui/sign-in-image';
import {
	StyledButtonContainer,
	StyledFormWrapper,
	StyledMaintitle,
	StyledSubtitle,
} from './forgot-password.styles';
import { sendPasswordResetEmailAPI } from '../../util';

import type { FC } from 'react';

type FormData = {
	email: string;
};

export const ForgotPasswordPage: FC = () => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const currentLanguage = i18next.language;

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm<FormData>();

	const onSubmit = async (data: FormData) => {
		await sendPasswordResetEmailAPI(apiClient, {
			data: { email: data.email, language: currentLanguage },
		})
			.then(() => {
				reset({ email: '' });
				openSnackbar('success', t('forgotPassword.success'));
			})
			.catch((e) => {
				openSnackbar('error', parseAxiosErrorMessage(e));
			});
	};

	return (
		<>
			<Grid
				data-test="forgotten-password-page"
				container
				sx={{ height: '100vh', overflow: 'hidden' }}
			>
				<Grid item xs={12} md={6}>
					<FormWrapper>
						<Grid alignItems="center" item>
							<StyledFormWrapper>
								<StyledMaintitle>{t('forgotPassword.title')}</StyledMaintitle>

								<StyledSubtitle>{t('forgotPassword.description')}</StyledSubtitle>

								<form onSubmit={handleSubmit(onSubmit)}>
									<TextField
										type="email"
										label={t('login.labels.email')}
										{...register('email', {
											required: true,
											pattern: {
												value: emailValidationRegEx,
												message: 'Email is invalid',
											},
										})}
										data-test="forgot-password-email-input"
										fullWidth
										margin="dense"
										error={!!errors.email}
										helperText={errors.email && t('forms.errors.required')}
									/>

									<StyledButtonContainer>
										<Button
											data-test="send-email-button"
											variant="contained"
											type="submit"
											sx={{ mt: 2 }}
											fullWidth
										>
											{t('forgotPassword.button')}
										</Button>
									</StyledButtonContainer>
								</form>
							</StyledFormWrapper>
						</Grid>
					</FormWrapper>
				</Grid>
				<Grid item display={{ xs: 'none', md: 'flex' }} md={6} data-test="signup-image">
					<SignupInImage />
				</Grid>
			</Grid>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
