import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import {
	StyledPreviewBox,
	StyledPreviewTitle,
	StyledPreviewWrapper,
} from './template-preview.styles';
import { Message } from '../../conversation/message';
import { useContact, useTemplateMessage, useUser } from '../../../hooks';

import type {
	ConversationResInterface,
	MessageResInterface,
	WhatsAppMessageTemplateResInterface,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

type TemplatePreviewProps = {
	template: WhatsAppMessageTemplateResInterface;
	conversation: ConversationResInterface;
};

export const TemplatePreview: FC<TemplatePreviewProps> = ({ conversation, template }) => {
	const { t } = useTranslation();
	const { contactId = '' } = useParams();

	const { finalTemplateText } = useTemplateMessage({ template, contactId });
	const { contact } = useContact(contactId);

	const { user } = useUser();

	const previewMessage: MessageResInterface = {
		id: 0,
		conversationId: conversation?.id || 0,
		contactId: conversation?.contactId || 0,
		direction: 'TO_CONTACT',
		text: finalTemplateText.join(''),
		remoteId: 'WABA.000',
		reaction: undefined,
		translatedText: null,
		messageType: 'WHATSAPP_TEMPLATE',
		transportType: conversation.transportType || 'FACEBOOK_WHATSAPP',
		receivedAt: null,
		seenAt: null,
		createdAt: new Date(Date.now() - 60 * 1000 * 15),
		updatedAt: new Date(Date.now() - 60 * 1000 * 15),
		user: {
			firstName: user?.firstName || 'Heylog',
			lastName: user?.lastName || 'User',
			id: user?.id || null,
		},
		contact: {
			firstName: contact?.firstName || 'Driver',
			lastName: contact?.lastName || 'Contact',
			id: user?.id || null,
		},
		attachments: null,
		conversationLanguage: conversation?.conversationLanguage || 'en',
		template: template,
		status: 'SENT',
	};

	return (
		<StyledPreviewWrapper>
			<StyledPreviewTitle>{t('waTemplates.preview')}</StyledPreviewTitle>
			<StyledPreviewBox>
				<Message message={previewMessage} />
			</StyledPreviewBox>
		</StyledPreviewWrapper>
	);
};
