export const IconFire = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
		<g fill="none" fillRule="evenodd">
			<path
				d="M12 19.836c-2.092 0-3.865-.727-5.32-2.18-1.453-1.455-2.18-3.228-2.18-5.32 0-1.691.488-3.334 1.463-4.93.976-1.595 2.314-2.998 4.014-4.208.322-.238.66-.261 1.012-.071.354.19.53.48.53.873v.848c0 .611.249 1.145.746 1.603a2.515 2.515 0 0 0 1.76.685c.296 0 .583-.058.86-.173.278-.115.533-.28.765-.494a.786.786 0 0 1 .474-.236.679.679 0 0 1 .51.147 7.397 7.397 0 0 1 2.105 2.63 7.54 7.54 0 0 1 .76 3.326c0 2.092-.726 3.865-2.18 5.32-1.454 1.453-3.227 2.18-5.319 2.18zm-6-7.5c0 1.008.24 1.946.722 2.814a5.74 5.74 0 0 0 1.986 2.1 1.731 1.731 0 0 1-.16-.423 1.977 1.977 0 0 1-.048-.44c0-.464.089-.9.266-1.309.178-.409.433-.78.765-1.111L12 11.538l2.479 2.429c.332.332.585.702.76 1.111a3.3 3.3 0 0 1 .26 1.308 1.731 1.731 0 0 1-.208.863 5.74 5.74 0 0 0 1.987-2.1A5.707 5.707 0 0 0 18 12.337c0-.833-.154-1.62-.463-2.362A6.102 6.102 0 0 0 16.2 7.986a3.95 3.95 0 0 1-2.175.65c-1.04 0-1.939-.342-2.697-1.025a3.882 3.882 0 0 1-1.303-2.544c-1.3 1.093-2.296 2.266-2.988 3.517C6.346 9.835 6 11.086 6 12.336zm6 1.3-1.425 1.4a1.98 1.98 0 0 0-.425.625c-.1.233-.15.475-.15.725 0 .533.196.992.587 1.375.392.383.863.575 1.413.575s1.02-.192 1.412-.575c.392-.383.588-.842.588-1.375 0-.267-.05-.512-.15-.737-.1-.225-.242-.43-.425-.613L12 13.636z"
				fill="#BA5D11"
				fillRule="nonzero"
			/>
		</g>
	</svg>
);
