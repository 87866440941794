import styled from 'styled-components';

export const StyledImageWrapper = styled.div`
	width: 100%;
	height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
