import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export const uploadContactFile = async (
	apiClient: AxiosInstance,
	{ body, organizationId }: { body: FormData; organizationId: string },
	contactToken?: string,
): Promise<AxiosResponse<string>> => {
	const url = `organizations/${organizationId}/files`;
	const config: AxiosRequestConfig = {};

	if (contactToken) {
		config.headers = { 'Custom-Authorization': contactToken };
	}
	return apiClient.post(url, body);
};
