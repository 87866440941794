import { useTranslation } from 'react-i18next';

import { EventPayloadEnum, OrderConnectionStatusEnum } from '@heylog-app/shared/types';

import { StyledOrderEvent, StyledOrderEventWrapper } from './order-event.styles';
import { useContact, useContactName, useCurrentLanguage, useOrder } from '../../../hooks';
import { extractContactIdFromEntityEvent } from './util';
import { extractOrderIdFromEntityEvent } from './util/extract-order-id-from-entity-event';

import type { EntityEventResType } from '@heylog-app/shared/types';
import type { FC } from 'react';

type ConversationEventProps = {
	event: EntityEventResType;
};

export const ConversationEvent: FC<ConversationEventProps> = ({ event }) => {
	const { t } = useTranslation();
	const { currentLanguage } = useCurrentLanguage();
	const { contact } = useContact(extractContactIdFromEntityEvent(event));
	const { order } = useOrder(extractOrderIdFromEntityEvent(event), true);
	const contactName = useContactName(contact);

	const getConversationEventLabel = (event: EntityEventResType) => {
		if (event.eventType === EventPayloadEnum.ORDER_STATUS_CHANGED) {
			return t('events.order_status_changed', {
				orderId: order?.refNumber || event.externalID,
				newStatus: t(
					`orders.details.status.${event.eventPayload.newStatus.toLowerCase()}`,
				),
			});
		} else if (event.eventType === EventPayloadEnum.CONTACT_UNSUBSCRIBES_FROM_VIBER) {
			return t('events.viber_contact_unsubscribed');
		} else if (event.eventType === EventPayloadEnum.CONTACT_ORDER_ASSIGNMENT_CHANGED) {
			if (event.eventPayload.newStatus === OrderConnectionStatusEnum.REQUESTED) {
				return t('events.order_contact_requested', {
					contactName,
					orderId: order?.refNumber || event.eventPayload.orderId,
				});
			}
			if (event.eventPayload.newStatus === OrderConnectionStatusEnum.ACCEPTED) {
				return t('events.order_contact_accepted', {
					contactName,
					orderId: order?.refNumber || event.eventPayload.orderId,
				});
			}
			if (event.eventPayload.newStatus === OrderConnectionStatusEnum.DECLINED) {
				return t('events.order_contact_declined', {
					contactName,
					orderId: order?.refNumber || event.eventPayload.orderId,
				});
			}
			if (event.eventPayload.newStatus === OrderConnectionStatusEnum.ARCHIVED) {
				return t('events.order_contact_removed', {
					contactName,
					orderId: order?.refNumber || event.eventPayload.orderId,
				});
			}
			if (event.eventPayload.newStatus === OrderConnectionStatusEnum.CREATED) {
				return t('events.order_contact_added', {
					contactName,
					orderId: order?.refNumber || event.eventPayload.orderId,
				});
			}

			return null;
		}
		return null;
	};

	const conversationEventLabel = getConversationEventLabel(event);

	return (
		<StyledOrderEventWrapper>
			<StyledOrderEvent>
				<span>{conversationEventLabel}</span>
				<span>
					{new Date(event.createdAt).toLocaleDateString(currentLanguage, {
						day: 'numeric',
						month: 'numeric',
						year: 'numeric',
						hour: '2-digit',
						minute: '2-digit',
						hour12: false,
					})}
				</span>
			</StyledOrderEvent>
		</StyledOrderEventWrapper>
	);
};
