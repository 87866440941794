import useSWR, { useSWRConfig } from 'swr';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { getAttachmentsKey, getFetcher } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';

import type { FullAttachmentResInterface } from '@heylog-app/shared/types';

export const useAttachments = () => {
	// const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const { workspaceId, shipmentId } = useParams();

	const { data, error, isValidating, isLoading } = useSWR<FullAttachmentResInterface[]>(
		// ability.can(Actions.READ, 'Conversation') ? contactsKey : null,
		workspaceId ? getAttachmentsKey(workspaceId, shipmentId) : null,
		getFetcher(apiClient),
	);

	return {
		attachments: data,
		isLoading,
		isError: error,
		isValidating,
	};
};

export const useAttachmentActions = () => {
	const { apiClient } = useApiClientContext();
	const { mutate: globalMutate } = useSWRConfig();

	const { workspaceId } = useParams();

	const linkAttachmentToOrder = useCallback(
		async ({ attachmentId, orderId }: { attachmentId: string; orderId: string }) => {
			const data = { orderId };
			await apiClient.post(
				`/workspaces/${workspaceId}/attachments/${attachmentId}/linkOrder`,
				data,
			);
			await globalMutate(getAttachmentsKey(workspaceId || ''));
			return;
		},
		[apiClient, globalMutate, workspaceId],
	);

	const unlinkAttachmentToOrder = useCallback(
		async ({ attachmentId, orderId }: { attachmentId: string; orderId: string }) => {
			const data = { orderId };
			await apiClient.post(
				`/workspaces/${workspaceId}/attachments/${attachmentId}/unlinkOrder`,
				data,
			);
			await globalMutate(getAttachmentsKey(workspaceId || ''));
			return;
		},
		[apiClient, globalMutate, workspaceId],
	);

	return {
		linkAttachmentToOrder,
		unlinkAttachmentToOrder,
	};
};
