import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import {
	ContactPageLayout,
	FlashSnackbar,
	getOrderTaskStatus,
	setOrderOnTime,
	useApiClientContext,
	useAuthContext,
	useSnackbar,
} from '@heylog-app/frontend-lib/app';

import {
	StyledButtonContainer,
	StyledPageContent,
	StyledTitleWrapper,
} from '../../styles/order-status.styles';

import type { FC } from 'react';

export const TaskDelayedPage: FC = () => {
	const { apiClient } = useApiClientContext();
	const {
		workspaceId = '',
		orderId = '',
		whatsappTransportNumber = '',
		taskId = '',
	} = useParams();
	const [searchParams] = useSearchParams();
	const { setAppToken } = useAuthContext();

	const bearerParam = searchParams.get('bearer');

	useEffect(() => {
		if (bearerParam) {
			setAppToken(bearerParam);
		}
	}, [bearerParam, setAppToken]);

	const [orderTaskRef, setOrderTaskRef] = useState<string>();
	const { t } = useTranslation();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		getOrderTaskStatus(apiClient, { workspaceId, orderId, taskId })
			.then((res) => {
				setOrderTaskRef(res.data.customerReference);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [apiClient, orderId, taskId, workspaceId]);

	const sendYes = () => {
		setIsSubmitting(true);
		console.log('isSubmitting after setIsSubmitting(true):', isSubmitting);
		setOrderOnTime(apiClient, {
			onTime: true,
			orderId,
			taskId,
			workspaceId,
		})
			.then(() => {
				openSnackbar('success', t('orders.tasks.setOnTimeSuccess', { orderTaskRef }));
				setTimeout(() => {
					window.location.href = `https://wa.me/${whatsappTransportNumber}`;
				}, 2000);
			})
			.catch((err) => {
				openSnackbar('error', t('orders.tasks.setOnTimeSuccess', { orderTaskRef }));
				console.error(err);
			})
			.finally(() => {
				setTimeout(() => {
					setIsSubmitting(false);
				}, 3000);
			});
	};

	const sendNo = () => {
		setIsSubmitting(true);

		setOrderOnTime(apiClient, {
			onTime: false,
			orderId,
			taskId,
			workspaceId,
		})
			.then(() => {
				openSnackbar('success', t('orders.tasks.setOnTimeSuccess', { orderTaskRef }));
				setTimeout(() => {
					window.location.href = `https://wa.me/${whatsappTransportNumber}`;
				}, 2000);
			})
			.catch((err) => {
				openSnackbar('error', t('orders.tasks.setOnTimeSuccess', { orderTaskRef }));
				console.error(err);
			})
			.finally(() => {
				setTimeout(() => {
					setIsSubmitting(false);
				}, 3000);
			});
	};

	const handleBackClick = () => {
		window.location.href = `https://wa.me/${whatsappTransportNumber}`;
	};

	return (
		<ContactPageLayout>
			<StyledPageContent>
				<StyledTitleWrapper>
					<span role="img" aria-label="order-emoji">
						📍
					</span>
					<span role="img" aria-label="order-emoji">
						{t('taskStatusUpdate.task')}: {orderTaskRef}
					</span>
					<br></br>
					<span role="img" aria-label="order-emoji">
						Are you going to be on time?
					</span>
				</StyledTitleWrapper>
			</StyledPageContent>

			<StyledButtonContainer>
				<Button
					variant="outlined"
					onClick={handleBackClick}
					fullWidth
					size="large"
					disabled={isSubmitting}
				>
					{t('actionLabels.back')}
				</Button>
				<Button
					variant="contained"
					onClick={sendYes}
					fullWidth
					size="large"
					disabled={isSubmitting}
				>
					Yes
				</Button>
				<Button
					variant="contained"
					onClick={sendNo}
					fullWidth
					size="large"
					disabled={isSubmitting}
				>
					No
				</Button>
			</StyledButtonContainer>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</ContactPageLayout>
	);
};
