import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useLocalStorage } from './use-local-storage';
import { Switch } from '../components';

const trueString = 'true';
const falseString = 'false';

const getBoolean = (value: string) => value === trueString;
const getBooleanString = (value: boolean) => (value ? trueString : falseString);

export const useDashboardMainFilters = () => {
	const { t } = useTranslation();
	const [showOrdersChart, setOrdersFilter] = useLocalStorage(
		'DashboardMainFilters.showOrdersChart',
		trueString,
	);
	const [showShipmentsChart, setShipmentsFilter] = useLocalStorage(
		'DashboardMainFilters.showShipmentsChart',
		trueString,
	);
	const [showTasksChart, setTasksFilter] = useLocalStorage(
		'DashboardMainFilters.showTasksChart',
		trueString,
	);

	const handleChange = (type: 'shipment' | 'orders' | 'tasks') => (value: boolean) => {
		switch (type) {
			case 'shipment':
				setShipmentsFilter(getBooleanString(value));
				break;
			case 'orders':
				setOrdersFilter(getBooleanString(value));
				break;
			case 'tasks':
				setTasksFilter(getBooleanString(value));
				break;
			default:
				break;
		}
	};

	const FiltersComponent = (
		<Stack direction="column" gap="24px" padding="16px">
			<Switch
				label={t('dashboardMain.ordersImported')}
				value={getBoolean(showOrdersChart)}
				onChange={handleChange('orders')}
			/>
			<Switch
				label={t('dashboardMain.shipments')}
				value={getBoolean(showShipmentsChart)}
				onChange={handleChange('shipment')}
			/>
			<Switch
				label={t('dashboardMain.automatedTasks')}
				value={getBoolean(showTasksChart)}
				onChange={handleChange('tasks')}
			/>
		</Stack>
	);

	return {
		FiltersComponent,
		showShipmentsChart: getBoolean(showShipmentsChart),
		showOrdersChart: getBoolean(showOrdersChart),
		showTasksChart: getBoolean(showTasksChart),
	};
};
