import type { FC } from 'react';

export const IconSk: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M48 8C30.842 8 16.25 18.824 10.568 34H85.43C79.75 18.824 65.158 8 48 8Z"
			fill="#ECEFF1"
		/>
		<path
			d="M88 48C88 43.066 87.064 38.364 85.432 34H10.568C8.936 38.364 8 43.066 8 48C8 52.934 8.936 57.636 10.568 62H85.43C87.064 57.636 88 52.934 88 48Z"
			fill="#1976D2"
		/>
		<path
			d="M85.432 62H10.568C16.25 77.176 30.842 88 48 88C65.158 88 79.75 77.176 85.432 62Z"
			fill="#FF3D00"
		/>
		<path
			d="M30.17 62.216C25.322 59.954 18.112 57.486 18.002 48.362C17.892 39.238 17.834 34.364 17.834 34.364L41.832 34.074C41.832 34.074 41.892 39.042 42 48.072C42.11 57.196 34.962 59.838 30.17 62.216Z"
			fill="#FF3D00"
		/>
		<path
			d="M15.8101 32.3881C15.8101 32.3881 15.8841 38.4421 16.0041 48.3861C16.1241 58.3381 24.5021 61.7541 30.1961 64.2161C35.8281 61.6161 44.1201 58 44.0001 48.048C43.8801 38.104 43.8061 32.05 43.8061 32.05L15.8101 32.3881ZM42.0001 48.072C42.1101 57.196 34.9621 59.838 30.1701 62.216C25.3221 59.954 18.1121 57.486 18.0021 48.362C17.8921 39.238 17.8341 34.364 17.8341 34.364L41.8321 34.0741C41.8321 34.0741 41.8921 39.042 42.0001 48.072Z"
			fill="#ECEFF1"
		/>
		<path
			d="M31.1461 47.18C32.6081 47.186 35.4521 47.206 37.9801 46.332C37.9801 46.332 37.9281 47.238 37.9421 48.294C37.9561 49.35 38.0281 50.254 38.0281 50.254C35.6901 49.508 32.8161 49.528 31.1761 49.564L31.2441 55.204L28.9401 55.232L28.8721 49.6C27.2241 49.596 24.3481 49.646 22.0301 50.456C22.0301 50.456 22.0821 49.55 22.0681 48.486C22.0561 47.432 21.9821 46.526 21.9821 46.526C24.5321 47.346 27.3821 47.258 28.8421 47.208L28.8001 43.67C27.4641 43.678 26.1181 43.508 23.9561 44.26C23.9561 44.26 23.9461 43.354 23.9321 42.3C23.9201 41.238 23.9081 40.332 23.9081 40.332C26.0801 41.032 27.4361 41.328 28.7641 41.304C28.6661 39.056 27.9761 36.242 27.9761 36.242C27.9761 36.242 29.3281 36.328 29.8601 36.32C30.3921 36.314 31.7421 36.196 31.7421 36.196C31.7421 36.196 31.1201 39.024 31.0761 41.266C32.4121 41.258 33.7521 40.96 35.9081 40.216C35.9081 40.216 35.8561 41.122 35.8701 42.178C35.8821 43.24 35.9561 44.146 35.9561 44.146C33.7841 43.446 32.4341 43.618 31.1061 43.642L31.1461 47.18Z"
			fill="white"
		/>
		<path
			d="M33.746 55.47C33.746 55.47 32.432 52.188 30.05 52.218C27.676 52.246 26.434 55.558 26.434 55.558C26.434 55.558 25.712 54.004 23.766 54.028C22.57 54.042 21.328 55.292 20.722 56.284C23.29 59.378 27.31 60.884 30.17 62.218C32.998 60.816 36.862 59.356 39.356 56.2C38.726 55.224 37.57 53.862 36.374 53.876C34.438 53.898 33.746 55.47 33.746 55.47Z"
			fill="#1976D2"
		/>
	</svg>
);
