import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useConversation } from '../hooks/use-conversation.hooks';

import type { SelectChangeEvent } from '@mui/material';

export const useConversationLanguage = ({
	conversationId,
}: {
	isTranslationActive: boolean;
	setIsTranslationActive: (isTranslationActive: boolean) => void;
	conversationId: string;
}) => {
	const { workspaceId = '' } = useParams();
	const { conversation, updateConversation } = useConversation(
		workspaceId,
		conversationId,
	);
	const [conversationLanguage, setConversationLanguage] = useState(
		conversation?.conversationLanguage ?? '',
	);
	const [contactLanguage, setContactLanguage] = useState(
		conversation?.contactLanguage ?? '',
	);

	//update state with values from DB
	useEffect(() => {
		if (!conversation) return;

		setConversationLanguage(conversation?.conversationLanguage ?? '');
		setContactLanguage(conversation?.contactLanguage ?? '');
	}, [conversation]);

	const handleConversationLanguageChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			setConversationLanguage(event.target.value);
			updateConversation({ conversationLanguage: event.target.value });
		},
		[setConversationLanguage, updateConversation],
	);

	const handleContactLanguageChange = useCallback(
		(event: SelectChangeEvent<string>) => {
			setContactLanguage(event.target.value);
			updateConversation({ contactLanguage: event.target.value });
		},
		[setContactLanguage, updateConversation],
	);

	return {
		contactLanguage,
		setContactLanguage,
		conversationLanguage,
		setConversationLanguage,
		handleContactLanguageChange,
		handleConversationLanguageChange,
	};
};
