import { useTranslation } from 'react-i18next';

import type { MaybeHasFirstAndLastName } from '../types';

export const useContactName = (data?: MaybeHasFirstAndLastName) => {
	const { firstName, lastName } = data || {};

	const { t } = useTranslation();

	return !firstName && !lastName ? t('contacts.noName') : `${firstName} ${lastName}`;
};
