import styled from 'styled-components';

import { SIZE_XS, STYLE_3 } from '../../../styles';

export const StyledNotificationBadge = styled.span`
	display: inline-block;
	background: ${({ theme }) => theme.palette.greyblue[225]};
	padding: 0.2rem 0.4rem;
	${SIZE_XS};
	${STYLE_3};
	border-radius: 4px;
`;
