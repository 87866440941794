export const IconAttention = () => (
	<svg width="20" height="20" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
		<polygon
			points="50,20 15,85 85,85"
			fill="none"
			stroke="rgb(178, 15, 15)"
			stroke-width="5"
		/>
		<text
			x="50"
			y="70"
			font-size="45"
			text-anchor="middle"
			fill="rgb(178, 15, 15)"
			font-family="Arial"
			font-weight="bold"
		>
			!
		</text>
	</svg>
);
