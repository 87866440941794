import styled from 'styled-components';
import { InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { SIZE_LG, SIZE_MD, STYLE_1, STYLE_3 } from '@heylog-app/frontend-lib/app';

export const StyledPageContainer = styled.div`
	max-width: 35rem;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	padding: 2rem 1rem;
`;

export const StyledForm = styled.form`
	display: flex;
`;

export const StyledButton = styled(LoadingButton)`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 1rem;
	max-height: 58px;

	span {
		margin-left: 0;
	}
`;

export const StyledTitleWrapper = styled.h1`
	${STYLE_3};
	${SIZE_LG};
	line-height: 1.8rem;
	margin-bottom: 1rem;
`;

export const StyledTextWrapper = styled.div`
	${STYLE_1};
	${SIZE_MD};
	line-height: 1.4rem;
	margin-bottom: 2rem;
`;

export const StyledInputAdornment = styled(InputAdornment)`
	filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
`;
