import styled, { css } from 'styled-components';
import { Box, TextField } from '@mui/material';

import { T3, colors } from '../../styles';
import { ChooseOrder } from '../../components/order';

const inputClasses = css`
	.MuiInputBase-root {
		padding-left: 0;
	}

	input {
		padding: 8.5px 4px 8.5px 0px;
	}

	.Mui-error {
		margin-left: 0;
	}

	fieldset {
		border: 0;
		border-bottom: 1px ${colors.grey[300]} solid;
		border-radius: 0;
	}
`;

export const StyledTextField = styled(TextField)`
	width: 100%;

	${inputClasses}
`;

export const StyledChooseOrder = styled(ChooseOrder)`
	${inputClasses}

	.MuiOutlinedInput-root.MuiInputBase-sizeSmall {
		padding-left: 0;
	}
`;

export const StyledErrorItem = styled(Box)`
	${T3};
`;
