import type { AxiosInstance } from 'axios';

export const getFetcher =
	(apiClient: AxiosInstance) =>
	<T>(key: string) =>
		apiClient
			.get<T>(key)
			.then((res) => res.data)
			.catch(async (error) => {
				throw error;
			});
