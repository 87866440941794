import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Popper, ClickAwayListener } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import EmojiPicker from 'emoji-picker-react';

import {
	MessageTypeEnum,
	MessagingTransportTypeEnum,
	WATplComponentTypeEnum,
	WhatsAppTemplateNames,
} from '@heylog-app/shared/types';
import { getMediaType } from '@heylog-app/shared/util';

import {
	StyledMessageFlexWrapper,
	StyledMessageInput,
	StyledMessageInputWrapper,
	StyledInputBottom,
	StyledSubmitButton,
	StyledFileWrapper,
	StyledTextareas,
	StyledTextareaWrapper,
	StyledSubmitBlock,
} from './message-input.styles';
import { MessageTranslationDialog } from '../message-translation-modal/message-translation-dialog';
import { MessageInputToolbar } from '../message-input-toolbar/message-input-toolbar';
import {
	useConversation,
	useConversationLanguage,
	useDebounce,
	useDialog,
	useMessageTranslation,
	useMutateMessages,
	useSnackbar,
	useTemplateMessage,
	useTemplates,
	useUploadFiles,
	useUser,
	useWorkspace,
} from '../../../hooks';
import { WhatsAppTemplatesDialog } from '../whatsapp-templates-dialog';
import { getLanguageCode } from '../../../util/get-language-code';
import { addEmojiToInput } from '../../../util/add-emoji';
import { UploadedFiles } from '../uploaded-files/uploaded-files';
import { DragOverlay } from '../drag-overlay';
import { MessageTextarea } from './message-textarea';
import { TranslatedMessageTextarea } from './translated-message-textarea';
import { FlashSnackbar } from '../../snackbars';
import { parseAxiosErrorMessage } from '../../../util';
import { SignatureShield } from './signature-shield';

import type { SelectChangeEvent } from '@mui/material';
import type {
	WhatsAppMessageTemplateResInterface,
	TranslationServiceType,
	SupportedLanguagesType,
	WATplParamTextInterface,
} from '@heylog-app/shared/types';
import type { FC, SyntheticEvent, KeyboardEventHandler } from 'react';
import type { EmojiClickData } from 'emoji-picker-react';

interface MessageInputPropsInteface {
	contactId?: string;
	conversationId?: string;
	shouldFocusOnRender?: boolean;
}

export const MessageInput: FC<MessageInputPropsInteface> = ({
	contactId: contactIdProp,
	conversationId: conversationIdProp,
	shouldFocusOnRender,
}) => {
	const { workspaceId = '', ...params } = useParams();
	const conversationId = conversationIdProp || params['conversationId'] || '';
	const contactId = contactIdProp || params['contactId'] || '';
	const { user } = useUser();
	const { workspace } = useWorkspace(workspaceId, user);

	const { conversation } = useConversation(workspaceId, conversationId);
	const { sendMessage } = useMutateMessages(workspaceId, conversationId);
	const [message, setMessage] = useState('');

	const [translatedMessage, setTranslatedMessage] = useState('');
	const [isTranslationActive, setIsTranslationActive] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [emojiPickerField, setEmojiPickerField] = useState<
		(EventTarget & (HTMLTextAreaElement | HTMLInputElement)) | null
	>(null);
	const messageInputToolbarRef = useRef<HTMLDivElement>(null);

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const [selectedTemplate, setSelectedTemplate] =
		useState<WhatsAppMessageTemplateResInterface>();
	const [templateParameters, setTemplateParameters] =
		useState<WATplParamTextInterface[]>();
	const [translatedTemplateParameters, setTranslatedTemplateParameters] =
		useState<WATplParamTextInterface[]>();
	const [translatedTemplate, setTranslatedTemplate] =
		useState<WhatsAppMessageTemplateResInterface>();

	const [focusedFieldIndex, setFocusedFieldIndex] = useState(0);
	const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
	const [templateCursorPosition, setTemplateCursorPosition] = useState<
		number | undefined
	>(undefined);

	const debouncedTemplateParameters = useDebounce<WATplParamTextInterface[] | undefined>(
		templateParameters ?? undefined,
		500,
	);

	const translationDialogControl = useDialog();

	const { templates } = useTemplates();
	const templatesDialogControl = useDialog();

	const debouncedMessage = useDebounce<string>(message, 500);

	useEffect(() => {
		setIsTranslationActive(conversation?.isTranslationActive || false);
		setMessage('');
	}, [conversation]);

	const {
		getMessageTranslation,
		loading: isTranslationLoading,
		supportedLang,
		latestTranslatedText,
	} = useMessageTranslation({
		translationService: workspace?.translationService as TranslationServiceType,
	});

	const {
		contactLanguage,
		conversationLanguage,
		handleContactLanguageChange,
		handleConversationLanguageChange,
	} = useConversationLanguage({
		isTranslationActive,
		setIsTranslationActive,
		conversationId,
	});

	useEffect(() => {
		if (
			!selectedTemplate &&
			supportedLang &&
			contactLanguage &&
			!supportedLang.targetLanguages.includes(contactLanguage as SupportedLanguagesType)
		) {
			handleContactLanguageChange({
				target: {
					value: 'en',
					name: 'languageSelector',
				},
			} as SelectChangeEvent<string>);
		}
	}, [contactLanguage, handleContactLanguageChange, selectedTemplate, supportedLang]);

	const {
		removeFile,
		files,
		getInputProps,
		getRootProps,
		openFileDialog,
		isDragAccept,
		removeAllFiles,
		fileError,
		setFileError,
	} = useUploadFiles();

	const { finalTemplateText } = useTemplateMessage({
		template: selectedTemplate,
		contactId,
		parametersFromInput: templateParameters
			? templateParameters.map((param) => param.text)
			: undefined,
	});

	const { finalTemplateText: translatedFinalTemplateText } = useTemplateMessage({
		template: translatedTemplate,
		contactId,
		parametersFromInput: translatedTemplateParameters
			? translatedTemplateParameters.map((param) => param.text)
			: undefined,
	});

	const isSubmitEnabled =
		!isSending &&
		(message.length > 0 || files.length > 0 || !!selectedTemplate) &&
		(!isTranslationActive ||
			(!isTranslationLoading &&
				((!!message && message === latestTranslatedText) ||
					(message.length === 0 && translatedMessage.length === 0))));

	const isSignatureOn = conversation?.showUserSignature || false;

	const { t } = useTranslation();

	const templateErrorMessage = t('chats.errors.noTranslatedTemplateAvailable');

	const translationTextBoxPlaceholderLabel = useMemo(
		() =>
			isTranslationLoading
				? t('chats.loadingTranslation')
				: t('chats.translationPlaceholder'),
		[isTranslationLoading, t],
	);

	const toggleEmojiPicker = useCallback(() => {
		setIsEmojiPickerVisible(!isEmojiPickerVisible);
	}, [isEmojiPickerVisible, setIsEmojiPickerVisible]);

	useEffect(() => {
		if (isTranslationLoading) setTranslatedMessage('');
		if (selectedTemplate) {
			setTranslatedMessage('');
			setMessage('');
		}
		if (message.length === 0) setTranslatedMessage('');
	}, [isTranslationLoading, message, selectedTemplate]);

	useEffect(() => {
		if (isTranslationActive) {
			if (debouncedMessage) {
				getMessageTranslation(
					debouncedMessage,
					conversationLanguage,
					contactLanguage,
					workspace?.translationService,
				).then((result) => {
					setTranslatedMessage(result);
				});
			} else {
				const translatedTemplate = templates?.find(
					(template) =>
						template.name === selectedTemplate?.name &&
						getLanguageCode(template.language) === getLanguageCode(contactLanguage),
				);

				if (selectedTemplate && !translatedTemplate) {
					openSnackbar('error', templateErrorMessage);
				}

				setTranslatedTemplate(translatedTemplate);

				// update template when language dropdown inside message input is used
				if (conversationLanguage !== selectedTemplate?.language) {
					const updatedLanuageTemplate = templates?.find(
						(template) =>
							template.name === selectedTemplate?.name &&
							getLanguageCode(template.language) ===
								getLanguageCode(conversationLanguage),
					);
					setSelectedTemplate(updatedLanuageTemplate);
				}
			}
		} else {
			setTranslatedTemplate(undefined);
			setTranslatedMessage('');
		}
	}, [
		debouncedMessage,
		isTranslationActive,
		conversationLanguage,
		contactLanguage,
		getMessageTranslation,
		selectedTemplate,
		templates,
		openSnackbar,
		templateErrorMessage,
		workspace?.translationService,
	]);

	// useEffect for handling I_HAVE_A_QUESTION template string translation
	useEffect(() => {
		if (selectedTemplate === undefined) {
			setTemplateParameters(undefined);
			setTranslatedTemplateParameters(undefined);
		}

		if (selectedTemplate?.name !== WhatsAppTemplateNames.I_HAVE_A_QUESTION) {
			setTranslatedTemplateParameters(debouncedTemplateParameters);
		} else if (
			isTranslationActive &&
			debouncedTemplateParameters &&
			debouncedTemplateParameters[0]?.text
		) {
			getMessageTranslation(
				`${debouncedTemplateParameters[0].text}?`,
				conversationLanguage,
				contactLanguage,
				workspace?.translationService,
			).then((result) => {
				const newResult = result.replace(/^¿/, '').replace(/\?$/, '');
				setTranslatedTemplateParameters([{ text: newResult, type: 'text' }]);
			});
		}
	}, [
		templateParameters,
		isTranslationActive,
		getMessageTranslation,
		conversationLanguage,
		contactLanguage,
		debouncedTemplateParameters,
		selectedTemplate,
		workspace?.translationService,
	]);

	const sendWhatsAppTemplateMessage = useCallback(
		async (selectedTemplate: WhatsAppMessageTemplateResInterface) => {
			return sendMessage({
				messagePayload: {
					messageType: MessageTypeEnum.WHATSAPP_TEMPLATE,
					name: selectedTemplate.name,
					sourceLanguage: selectedTemplate.language,
					targetLanguage: translatedTemplate?.language || undefined,
					...(templateParameters && {
						components: [
							{
								type: WATplComponentTypeEnum.BODY,
								parameters: templateParameters,
								...(translatedTemplate &&
									selectedTemplate.name === WhatsAppTemplateNames.I_HAVE_A_QUESTION && {
										translatedParameters: [
											...(translatedTemplateParameters || [{ type: 'text', text: '' }]),
										],
									}),
							},
						],
					}),
				},
				template: selectedTemplate,
				translatedTemplate: translatedTemplate,
				transportType: MessagingTransportTypeEnum.FACEBOOK_WHATSAPP,
			}).catch((error) => {
				console.log('error sending a template message: ', error);
				setIsSending(false);
			});
		},
		[sendMessage, templateParameters, translatedTemplate, translatedTemplateParameters],
	);

	const sendViberTemplateMessage = useCallback(async () => {
		return sendMessage({
			messagePayload: {
				messageType: MessageTypeEnum.TEXT,
				text: finalTemplateText.join('') || '',
				translatedText: translatedFinalTemplateText.join('') || '',
				...(templateParameters && {
					components: [
						{
							type: WATplComponentTypeEnum.BODY,
							parameters: templateParameters,
							...(translatedTemplate &&
								translatedTemplate.name === WhatsAppTemplateNames.I_HAVE_A_QUESTION && {
									translatedParameters: [
										...(translatedTemplateParameters || [{ type: 'text', text: '' }]),
									],
								}),
						},
					],
				}),
			},
			transportType: MessagingTransportTypeEnum.VIBER,
		}).catch((error) => {
			console.log('error sending a template message: ', error);
			setIsSending(false);
		});
	}, [
		sendMessage,
		templateParameters,
		translatedTemplate,
		translatedTemplateParameters,
		finalTemplateText,
		translatedFinalTemplateText,
	]);

	const sendMediaMessage = useCallback(async () => {
		if (!conversation?.transportType) return;

		//handle sending media for now
		for (let i = 0; i <= files.length; i++) {
			const file = files[i];

			if (file) {
				try {
					const mediaType = getMediaType(file.type);

					//TODO handle sent reaction somewhere
					if (mediaType === MessageTypeEnum.REACTION) {
						return;
					}

					if (
						mediaType === MessageTypeEnum.IMAGE ||
						mediaType === MessageTypeEnum.VIDEO
					) {
						await sendMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
							transportType: conversation.transportType,
						});
					} else if (mediaType === MessageTypeEnum.AUDIO) {
						await sendMessage({
							messagePayload: { messageType: mediaType, file: file },
							transportType: conversation.transportType,
						});
					} else if (
						// if message is text or template dont handle this here
						mediaType !== MessageTypeEnum.TEXT &&
						mediaType !== MessageTypeEnum.WHATSAPP_TEMPLATE &&
						mediaType !== MessageTypeEnum.LOCATION &&
						conversation.transportType
					) {
						await sendMessage({
							messagePayload: {
								caption: message,
								messageType: mediaType,
								file: file,
							},
							transportType: conversation.transportType,
						});
					}
				} catch (error) {
					openSnackbar('error', parseAxiosErrorMessage(error));
					setFileError(true);
					setIsSending(false);
					return;
				}
			}
		}
	}, [files, sendMessage, conversation, message, openSnackbar, setFileError]);

	const sendTextMessage = useCallback(async () => {
		if (message && message.trim().length && conversation?.transportType) {
			await sendMessage({
				messagePayload: {
					messageType: MessageTypeEnum.TEXT,
					text: message,
					translatedText: translatedMessage,
				},
				transportType: conversation?.transportType,
			}).catch((error) => {
				console.log('error sending a text message: ', error);
				setIsSending(false);
			});
		}
	}, [conversation, sendMessage, message, translatedMessage]);

	const handleSubmit = useCallback(async () => {
		if (isSending) return;

		if (conversation?.transportType) {
			setIsSending(true);

			// text message won't be sent if it can be sent as caption of media message
			const messageContainsImageOrVideo = files.some((file) => {
				const mimeType = getMediaType(file.type);
				return mimeType === MessageTypeEnum.IMAGE || mimeType === MessageTypeEnum.VIDEO;
			});

			// send selected template
			if (selectedTemplate) {
				switch (conversation.transportType) {
					case MessagingTransportTypeEnum.FACEBOOK_WHATSAPP:
						await sendWhatsAppTemplateMessage(selectedTemplate);
						break;
					case MessagingTransportTypeEnum.VIBER:
						// TODO why is this not using selectedTemplate?
						await sendViberTemplateMessage();
						break;
				}
			}

			// send media message
			if (files.length) {
				await sendMediaMessage();
			}

			// send text message
			if (message && !messageContainsImageOrVideo) {
				await sendTextMessage();
			}

			setMessage('');
			setTranslatedMessage('');
			removeAllFiles();
			setSelectedTemplate(undefined);
			setIsSending(false);
		}
	}, [
		conversation?.transportType,
		files,
		message,
		isSending,
		removeAllFiles,
		selectedTemplate,
		sendWhatsAppTemplateMessage,
		sendViberTemplateMessage,
		sendMediaMessage,
		sendTextMessage,
	]);

	const onChange = (event: SyntheticEvent<HTMLTextAreaElement>) => {
		setMessage(event?.currentTarget?.value);
	};

	const onPressEnter: KeyboardEventHandler = (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			event.preventDefault();

			if (isSubmitEnabled) {
				handleSubmitClick();
			}
		}
	};

	const handleSubmitClick = () => {
		if (templateParameters?.find((param) => param.text === '') !== undefined) {
			openSnackbar('error', t('chats.errors.emptyTemplateParameter'));
		} else if (isSubmitEnabled) {
			handleSubmit();
		}
	};

	const sendEmojiToInput = async (emojiObject: EmojiClickData) => {
		if (emojiPickerField) {
			const message = emojiPickerField.value;
			const cursor = emojiPickerField?.selectionStart || 0;
			const { messageWithEmoji, processedEmojiLength } = addEmojiToInput(
				emojiObject,
				message,
				cursor,
			);

			const newCursor = cursor + processedEmojiLength + 1;

			if (
				selectedTemplate !== undefined &&
				templateParameters?.length &&
				templateParameters[focusedFieldIndex] !== undefined
			) {
				const newTemplateParameters: WATplParamTextInterface[] = [...templateParameters];
				const newField = templateParameters[focusedFieldIndex];

				if (newField) {
					newField.text = messageWithEmoji;
					newTemplateParameters.splice(focusedFieldIndex, 1, newField);
				}

				setTemplateCursorPosition(newCursor);
				setTemplateParameters(newTemplateParameters);
			} else {
				setMessage(messageWithEmoji);
				setTemplateCursorPosition(cursor + processedEmojiLength + 1);
			}
		}
		setIsEmojiPickerVisible(false);
	};

	useEffect(() => {
		if (templateCursorPosition && emojiPickerField) {
			emojiPickerField.setSelectionRange(templateCursorPosition, templateCursorPosition);
		}
	}, [templateCursorPosition, emojiPickerField]);

	const clickAwayEmojiPickerHandler = () => {
		setIsEmojiPickerVisible(false);
	};

	return (
		<>
			<StyledMessageInputWrapper
				data-test="message-input-component"
				ref={messageInputToolbarRef}
			>
				<StyledMessageInput {...getRootProps()}>
					{isEmojiPickerVisible && (
						<ClickAwayListener onClickAway={clickAwayEmojiPickerHandler}>
							<Popper
								data-test="emoji-picker-popper"
								open={isEmojiPickerVisible}
								anchorEl={messageInputToolbarRef?.current}
								style={{ zIndex: 100 }}
								placement={window.innerWidth <= 1600 ? 'top-start' : 'bottom-start'}
								modifiers={[
									{
										name: 'offset',
										options: {
											offset: [15, -10],
										},
									},
									{
										name: 'preventOverflow',
										options: {
											boundary: 'viewport',
										},
									},
								]}
							>
								<EmojiPicker
									onEmojiClick={sendEmojiToInput}
									autoFocusSearch={false}
									previewConfig={{ showPreview: false }}
								/>
							</Popper>
						</ClickAwayListener>
					)}

					{isDragAccept && <DragOverlay />}
					<MessageInputToolbar
						getInputProps={getInputProps}
						openFileDialog={openFileDialog}
						setIsTranslationActive={setIsTranslationActive}
						openTranslationDialog={translationDialogControl.openDialog}
						isTranslationActive={isTranslationActive}
						conversationId={conversationId}
						openWhatsAppTemplatesDialog={templatesDialogControl.openDialog}
						selectedTemplate={selectedTemplate}
						setSelectedTemplate={setSelectedTemplate}
						toggleEmojiPicker={toggleEmojiPicker}
					/>
					<StyledMessageFlexWrapper
						$isSignatureOn={isSignatureOn}
						$hasMedia={files?.length > 0}
					>
						<StyledFileWrapper>
							<StyledTextareas>
								{isTranslationActive ? (
									<TranslatedMessageTextarea
										message={message}
										translatedMessage={translatedMessage}
										conversationLanguage={conversationLanguage}
										contactLanguage={contactLanguage}
										isSending={isSending}
										selectedTemplate={selectedTemplate}
										translatedTemplate={translatedTemplate}
										templateParameters={templateParameters}
										translatedTemplateParameters={translatedTemplateParameters}
										translationTextBoxPlaceholderLabel={
											translationTextBoxPlaceholderLabel
										}
										setTemplateParameters={setTemplateParameters}
										onChange={onChange}
										onPressEnter={onPressEnter}
										handleConversationLanguageChange={handleConversationLanguageChange}
										handleContactLanguageChange={handleContactLanguageChange}
										supportedLang={supportedLang}
										setEmojiPickerField={setEmojiPickerField}
										focusedFieldIndex={focusedFieldIndex}
										setFocusedFieldIndex={setFocusedFieldIndex}
										templateCursorPosition={templateCursorPosition}
									/>
								) : (
									<StyledTextareaWrapper>
										<MessageTextarea
											message={message}
											isSending={isSending}
											selectedTemplate={selectedTemplate}
											templateParameters={templateParameters}
											setTemplateParameters={setTemplateParameters}
											onChange={onChange}
											onPressEnter={onPressEnter}
											setEmojiPickerField={setEmojiPickerField}
											focusedFieldIndex={focusedFieldIndex}
											setFocusedFieldIndex={setFocusedFieldIndex}
											templateCursorPosition={templateCursorPosition}
											shouldFocusOnRender={shouldFocusOnRender}
										/>
									</StyledTextareaWrapper>
								)}
							</StyledTextareas>
						</StyledFileWrapper>
						<StyledInputBottom
							$isSignatureOn={isSignatureOn}
							$hasMedia={files?.length > 0}
						>
							<StyledSubmitBlock $isSignatureOn={isSignatureOn}>
								{isSignatureOn && <SignatureShield />}
								<StyledSubmitButton
									data-test="send-message-button"
									disabled={!isSubmitEnabled}
									$active={isSubmitEnabled}
									$isSignatureOn={isSignatureOn}
									onClick={handleSubmitClick}
								>
									<SendIcon />
									{isSending ? <LinearProgress /> : null}
								</StyledSubmitButton>
							</StyledSubmitBlock>
							{files && (
								<UploadedFiles
									fileError={fileError}
									removeFile={removeFile}
									files={files}
									showMargin={isSignatureOn || files?.length > 0}
								/>
							)}
						</StyledInputBottom>
					</StyledMessageFlexWrapper>
				</StyledMessageInput>
			</StyledMessageInputWrapper>
			<MessageTranslationDialog
				contactLanguage={contactLanguage}
				setIsTranslationActive={setIsTranslationActive}
				conversationLanguage={conversationLanguage}
				handleContactLanguage={handleContactLanguageChange}
				handleConversationLanguage={handleConversationLanguageChange}
				conversationId={conversationId}
				control={translationDialogControl}
				translationService={workspace?.translationService || 'DEEPL'}
			/>

			<WhatsAppTemplatesDialog
				conversation={conversation}
				control={templatesDialogControl}
				setSelectedTemplate={setSelectedTemplate}
				conversationLanguage={conversationLanguage}
			/>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
