import styled from 'styled-components';

import { SIZE_SM, STYLE_2 } from '../../../styles';

export const StyledPreviewWrapper = styled.div`
	margin-top: 1rem;
`;

export const StyledPreviewBox = styled.div`
	border: 1px solid rgba(0, 0, 0, 0.23);
	border-radius: 4px;
	padding: 16px 14px;

	> * {
		opacity: 0.8;
	}
`;

export const StyledPreviewTitle = styled.div`
	${STYLE_2};
	${SIZE_SM};

	margin-bottom: 0.25rem;
`;
