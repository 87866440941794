import { Box, Stack } from '@mui/material';
import { useState, type FC } from 'react';
import dayjs from 'dayjs';
import Icon from '@mdi/react';
import { mdiChevronLeft, mdiChevronRight, mdiTune } from '@mdi/js';
import { useTranslation } from 'react-i18next';

import {
	ButtonV2,
	DashboardOrdersChart,
	DashboardShipmentsChart,
	DashboardTasksChart,
	Datepicker,
	SidebarLayout,
	TableFilterSidebar,
	TabsGroupV2,
	useDashboardMainFilters,
} from '@heylog-app/frontend-lib/app';

import { StyledTitle } from './dashboard-main.styles';

import type { Dayjs } from 'dayjs';

enum DateTypesEnum {
	MONTH,
	CUSTOM,
}

const tabs = [
	{ title: 'dashboard.month', value: DateTypesEnum.MONTH },
	{ title: 'dashboard.custom', value: DateTypesEnum.CUSTOM },
];

export const DashboardMainPage: FC = () => {
	const { FiltersComponent, showOrdersChart, showShipmentsChart, showTasksChart } =
		useDashboardMainFilters();
	const [selectedTab, setSelectedTab] = useState(DateTypesEnum.MONTH);
	const [isOpenFilters, setIsOpenFilters] = useState(false);
	const [rangeDate, setRangeDate] = useState<[Dayjs | null, Dayjs | null]>([
		dayjs().set('date', 1),
		dayjs().set('date', 1).add(1, 'month'),
	]);
	const { t } = useTranslation();

	const onMonthChage = (value: Dayjs | null) => {
		if (!value) return;
		setRangeDate([
			dayjs(value).set('date', 1),
			dayjs(value).set('date', 1).add(1, 'month'),
		]);
	};

	const onCustomDateChage = (value: [Dayjs | null, Dayjs | null]) => {
		setRangeDate(value);
	};

	const handleNextPrev = (type: 'prev' | 'next') => () => {
		const [start] = rangeDate;
		const increment = type === 'next' ? 1 : -1;

		const newStart = dayjs(start).add(increment, 'month');
		const newEnd = dayjs(newStart).add(1, 'month');

		if (start) {
			setRangeDate([newStart, newEnd]);
		}
	};

	const handleCloseFilters = () => setIsOpenFilters(false);
	const handleOpenFilters = () => setIsOpenFilters(true);

	// This small kludge used to re-render charts properly (for some reason containers height bugs out when hiding/showing charts)
	const containerKey = `${showOrdersChart}-${showShipmentsChart}-${showTasksChart}`;

	return (
		<SidebarLayout overflowAuto>
			<TableFilterSidebar
				title={t('dashboardMain.setUpDashboard')}
				isOpen={isOpenFilters}
				onClose={handleCloseFilters}
			>
				{FiltersComponent}
			</TableFilterSidebar>

			<Stack width="100%" padding={4} gap={4} key={containerKey}>
				<Stack direction="row" flexWrap="wrap" justifyContent="space-between" gap={3}>
					<StyledTitle>{t('navigation.dashboard')}</StyledTitle>

					<Stack
						direction="row"
						justifyContent="flex-end"
						alignItems="center"
						columnGap={4}
						rowGap={2}
						flexWrap="wrap-reverse"
					>
						<Box>
							<TabsGroupV2 tabs={tabs} value={selectedTab} onChange={setSelectedTab} />
						</Box>
						<Stack direction="row" gap={2}>
							<ButtonV2 $padding="8px" $variant="light" onClick={handleNextPrev('prev')}>
								<Icon path={mdiChevronLeft} size={1} />
							</ButtonV2>
							<Box width="210px">
								{selectedTab === DateTypesEnum.CUSTOM && (
									<Datepicker
										$inputPadding="10px 14px"
										type="range"
										onChange={onCustomDateChage}
										value={rangeDate}
									/>
								)}
								{selectedTab === DateTypesEnum.MONTH && (
									<Datepicker
										$inputPadding="10px 14px"
										type="month"
										onChange={onMonthChage}
										value={rangeDate[0]}
									/>
								)}
							</Box>
							<ButtonV2 $padding="8px" $variant="light" onClick={handleNextPrev('next')}>
								<Icon path={mdiChevronRight} size={1} />
							</ButtonV2>
						</Stack>
						{!isOpenFilters && (
							<ButtonV2 onClick={handleOpenFilters} $variant="light" $padding="12px">
								<Icon path={mdiTune} size={1} />
							</ButtonV2>
						)}
					</Stack>
				</Stack>

				<Stack
					flex={showOrdersChart || showShipmentsChart ? 1 : 0}
					direction="row"
					flexWrap="wrap"
					gap={4}
				>
					{showOrdersChart && (
						<Box flex={7} flexBasis="500px" minWidth={0}>
							<DashboardOrdersChart />
						</Box>
					)}
					{showShipmentsChart && (
						<Box flex={3} flexBasis="330px" minWidth={0}>
							<DashboardShipmentsChart />
						</Box>
					)}
				</Stack>
				{showTasksChart && (
					<Box flex={1}>
						<DashboardTasksChart />
					</Box>
				)}
			</Stack>
		</SidebarLayout>
	);
};
