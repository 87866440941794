import useSWR from 'swr';

import { useApiClientContext } from './use-api-client-context.hook';
import { getFetcher } from '../util';

import type { OrganizationSetting } from '../util';

export const useSettingsByOrganizationId = (organizationId: number) => {
	const { apiClient } = useApiClientContext();

	const { data, mutate } = useSWR<OrganizationSetting>(
		`/settings/organization/${organizationId}`,
		getFetcher(apiClient),
	);

	return { data, mutate };
};
