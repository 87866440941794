import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { mdiLogout, mdiSwapHorizontal } from '@mdi/js';

import {
	HeylogLogoBlack,
	ROUTES,
	CodingSvg,
	useUser,
	useWorkspaceById,
} from '@heylog-app/frontend-lib/app';

import {
	StyledIconWrapper,
	StyledButton,
	StyledPageContainer,
	StyledButtonsContainer,
	StyledPageContent,
	StyledText,
	StyledTitle,
	StyledMenu,
	StyledMenuItem,
	StyledMenuIcon,
	StyledChip,
} from './no-workspace.styles';

import type { FC } from 'react';

export const DisabledWorkspace: FC = () => {
	const { t } = useTranslation();
	const { workspaceId } = useParams();

	const { workspace } = useWorkspaceById(workspaceId || '-1');
	const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = useState(false);
	const { user } = useUser();

	const navigate = useNavigate();
	const switchWsButtonRef = useRef<HTMLButtonElement>(null);

	const handleSwitchWorkspaceClick = () => {
		setSwitchWorkspaceOpen(!switchWorkspaceOpen);
	};

	const handleLogout = useCallback(() => {
		navigate(ROUTES.LOGOUT);
	}, [navigate]);

	const closeSwitchMenu = useCallback(() => {
		setSwitchWorkspaceOpen(false);
	}, []);

	const handleWorkspaceClick = (id: number) => {
		navigate(
			generatePath(ROUTES.CONTACTS.OVERVIEW, {
				workspaceId: id.toString(),
			}),
		);
	};

	return (
		<StyledPageContainer data-test="disabled-workspace-component">
			<HeylogLogoBlack />

			<StyledPageContent>
				<StyledIconWrapper>
					<CodingSvg />
				</StyledIconWrapper>

				<StyledTitle>
					{t('workspaces.disabledWorkspace.title', { workspaceName: workspace?.name })}
				</StyledTitle>

				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('workspaces.disabledWorkspace.description', {
							workspaceName: workspace?.name,
						}),
					}}
				/>
			</StyledPageContent>

			<StyledButtonsContainer>
				<StyledButton
					variant="outlined"
					color="secondary"
					onClick={handleLogout}
					data-test="logout-button"
				>
					<StyledMenuIcon path={mdiLogout} size={'1rem'} />
					{t('navigation.menu.logout')}
				</StyledButton>

				{user?.workspaces?.length && user.workspaces.length > 1 && (
					<div>
						<StyledButton
							aria-controls="switch-workspace-page-disabled"
							data-test="switch-workspace-page-disabled"
							aria-haspopup="true"
							aria-expanded={switchWorkspaceOpen ? 'true' : undefined}
							ref={switchWsButtonRef}
							onClick={handleSwitchWorkspaceClick}
						>
							<StyledMenuIcon path={mdiSwapHorizontal} size={'1rem'} />
							{t('navigation.menu.changeTeam')}
						</StyledButton>

						<StyledMenu
							id="switch-workspace-page-disabled"
							open={switchWorkspaceOpen}
							onClose={closeSwitchMenu}
							anchorEl={switchWsButtonRef?.current}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{user?.workspaces?.map((workspace, index) => {
								return (
									<StyledMenuItem
										key={index}
										onClick={() => handleWorkspaceClick(workspace.id)}
										disabled={workspace.id === parseInt(workspaceId || '')}
									>
										{workspace.name}
										{workspace.id === parseInt(workspaceId || '') && (
											<StyledChip
												label={`${t('navigation.current')}`}
												variant="outlined"
											/>
										)}
									</StyledMenuItem>
								);
							})}
						</StyledMenu>
					</div>
				)}
			</StyledButtonsContainer>
		</StyledPageContainer>
	);
};
