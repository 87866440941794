import { useEffect, useState } from 'react';

import type { RefObject } from 'react';

export const useElementHeight = <T extends Element>(elementRef: RefObject<T>) => {
	const [elementHeight, setElementHeight] = useState(0);

	useEffect(() => {
		const observer = new ResizeObserver((entries) => {
			for (const entry of entries) {
				setElementHeight(entry.contentRect.height);
			}
		});

		if (elementRef.current) {
			observer.observe(elementRef.current);
		}

		// Clean up the observer when the component unmounts
		return () => observer.disconnect();
	}, [elementRef]);

	return elementHeight;
};
