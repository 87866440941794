import type { SupportedLanguagesType } from '@heylog-app/shared/types';

export const getSurveyLanguageCode = (language: SupportedLanguagesType): string => {
	const surveyLanguageCodes = [
		'default',
		'en',
		'de',
		'et',
		'es',
		'fr',
		'hr',
		'it',
		'lv',
		'lt',
		'hu',
		'pl',
		'pt',
		'ro',
		'sk',
		'rs',
		'fi',
		'tr',
		'cs',
		'bg',
		'mk',
		'ru',
		'ua',
		'sl',
		'bs',
	];

	const languageMapping: { [key: string]: string } = {
		'pt-pt': 'pt',
		uk: 'ua',
		rs: 'sr',
	};

	const mappedLanguage = languageMapping[language] || language;

	return surveyLanguageCodes.includes(mappedLanguage) ? mappedLanguage : 'default';
};
