import Icon from '@mdi/react';
import styled from 'styled-components';

import { SIZE_SM, colors } from '../../styles';

export const StyledDeleteIcon = styled(Icon)`
	padding-right: 2px;
	opacity: 0;
	transition: all;
	transition-duration: 0.3s;
	color: ${colors.primary.main};
	width: 16px;
	margin-left: auto;
`;

export const StyledGuestUser = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px;
	transition: all;
	transition-duration: 0.3s;

	${SIZE_SM}

	:hover {
		background-color: ${colors.common.white};
		cursor: pointer;

		${StyledDeleteIcon} {
			opacity: 1;
		}
	}
`;

export const StyledContentWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	width: 100%;
`;

export const StyledEmail = styled.div<{ $inactive?: boolean }>`
	color: ${({ $inactive }) => ($inactive ? `${colors.common.black}` : '')};
	opacity: ${({ $inactive }) => ($inactive ? '0.5' : '1')};
	max-width: 100px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const StyledIcon = styled(Icon)`
	color: ${colors.greyblue[125]};
`;
