export const getTaskKey = ({
	orderId,
	taskId,
	workspaceId,
}: {
	orderId: string;
	taskId: string;
	workspaceId: string;
}): string => {
	return `workspaces/${workspaceId}/orders/${orderId}/tasks/${taskId}`;
};
