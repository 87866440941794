import { useAbility } from '@casl/react';
import useSWR from 'swr';

import { Actions } from '@heylog-app/shared/types';

import { getFetcher, getMessagesKey } from '../util';
import { AbilityContext } from '../providers';
import { useApiClientContext } from './use-api-client-context.hook';

import type { MessageResInterface } from '@heylog-app/shared/types';

export const useMessages = ({
	conversationId,
	messagesDateFrom,
	messagesToFetch,
	workspaceId,
}: {
	conversationId: string;
	messagesDateFrom?: Date;
	messagesToFetch?: number;
	workspaceId: string;
}) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const messagesKey = getMessagesKey(
		workspaceId,
		conversationId,
		messagesToFetch,
		messagesDateFrom,
	);

	const {
		data: messages,
		error: messagesError,
		mutate: updateMessages,
		isLoading: isLoadingMessages,
		isValidating: isValidatingMessages,
	} = useSWR<MessageResInterface[]>(
		ability.can(Actions.READ, 'Conversation') ? messagesKey : null,
		getFetcher(apiClient),
	);
	return {
		messages,
		updateMessages,
		messagesError,
		isLoadingMessages,
		isValidatingMessages,
	};
};
