import React, { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';

import type { FC } from 'react';

interface Option {
	label: string;
	action: string;
}

interface OverflowMenuProps {
	options: Option[];
	handleDelete: (userId: number) => void;
	userId?: number;
}

export const OverflowMenu: FC<OverflowMenuProps> = ({
	options,
	handleDelete,
	userId,
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleActionCheck = (action: string) => {
		switch (action) {
			case 'delete':
				userId && handleDelete(userId);
				break;
			default:
				break;
		}
	};

	return (
		<div>
			<IconButton
				aria-controls="overflow-menu"
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreHoriz />
			</IconButton>
			<Menu
				id="overflow-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{options.map((option, index) => (
					<MenuItem key={index} onClick={() => handleActionCheck(option.action)}>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};
