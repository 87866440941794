import styled from 'styled-components';
import { Doughnut } from 'react-chartjs-2';

import { H2, T2_medium, T3_medium, T4, T4_medium, colors } from '../../styles';

export const StyledDoughnut = styled(Doughnut)`
	position: relative;
	z-index: 1;
`;

export const StyledTotalTitle = styled.div`
	${T4};
	color: ${colors.grey[600]};
`;

export const StyledTotalValue = styled.div`
	${H2};
	color: ${colors.grey[900]};
`;

export const StyledUrgentValue = styled.div`
	display: flex;
	align-items: center;
	margin-left: -6px;
	${T3_medium};
	color: ${colors.warning.dark};

	svg {
		transform: scale(0.75);
	}
`;

export const StyledTitle = styled.div`
	${T2_medium};
	color: ${colors.grey[900]};
`;

export const StyledLegendLabel = styled.div`
	${T4};
	color: ${colors.grey[900]};
	max-width: 50%;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

export const StyledLegendValue = styled.div`
	${T3_medium};
	color: ${colors.grey[900]};
`;

export const StyledLegendUrgent = styled.div`
	${T4_medium};
	color: ${colors.warning.dark};
`;
