import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const PusherConnectionStatesEnum = mkenum({
	INITIALIZED: 'initialized',
	CONNECTING: 'connecting',
	CONNECTED: 'connected',
	UNAVAILABLE: 'unavailable',
	FAILED: 'failed',
	DISCONNECTED: 'disconnected',
});

export type PusherConnectionStatesType = TypeFromPEnum<typeof PusherConnectionStatesEnum>;
