import type { EmojiClickData } from 'emoji-picker-react';

export const addEmojiToInput = (
	emojiObject: EmojiClickData,
	text: string,
	cursor: number,
) => {
	const spaceRegEx = /^ /g;
	let processedEmojiLength = 1;
	let processedEmoji = emojiObject.emoji;

	if (
		text.slice(cursor, cursor + 1) &&
		!spaceRegEx.test(text.slice(cursor, cursor + 1))
	) {
		processedEmoji = processedEmoji + ' ';
		processedEmojiLength++;
	}

	const previousSignCursor = cursor !== 0 ? cursor - 1 : cursor;

	if (
		text.length > 0 &&
		text.slice(previousSignCursor, cursor) &&
		!spaceRegEx.test(text.slice(previousSignCursor, cursor))
	) {
		processedEmoji = ' ' + processedEmoji;
		processedEmojiLength++;
	}

	const messageWithEmoji = text.slice(0, cursor) + processedEmoji + text.slice(cursor);

	return { messageWithEmoji, processedEmojiLength };
};
