export const InviteNewContactsSvg = () => (
	<svg
		width="200"
		height="200"
		viewBox="40 200 800 600"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M513.985 186.479H476.137C470.103 186.464 464.416 189.302 460.8 194.132C456.086 200.407 448.695 204.098 440.847 204.099H348.325C340.477 204.098 333.087 200.407 328.373 194.132C324.758 189.321 319.092 186.49 313.075 186.488H275.227C263.657 186.486 252.559 191.08 244.377 199.261C236.195 207.441 231.598 218.538 231.598 230.108V769.902C231.598 781.472 236.195 792.569 244.377 800.749C252.559 808.93 263.657 813.524 275.227 813.522H513.985C525.555 813.524 536.652 808.93 544.835 800.749C553.017 792.569 557.614 781.472 557.614 769.902V230.098C557.614 218.528 553.017 207.432 544.835 199.251C536.652 191.07 525.555 186.476 513.985 186.479Z"
			fill="#D1D4F2"
		/>
		<path
			d="M557.614 769.902V755.016C498.167 767.168 383.684 730.153 383.684 730.153L370.023 724.557C370.023 724.557 360.36 733.171 379.068 783.357C383.262 794.608 390.642 804.682 399.55 813.531H513.985C525.557 813.534 536.656 808.938 544.838 800.755C553.021 792.573 557.617 781.474 557.614 769.902Z"
			fill="#7C839E"
		/>
		<path
			d="M518.62 840.06H280.029C266.417 840.06 253.344 839.335 240.829 832.808C219.412 821.473 206.022 799.22 206.039 774.988C206.039 770.294 206.039 765.61 206.039 760.916V242.496C206.039 236.949 206.039 231.392 206.039 225.836C206.039 205.951 214.232 187.292 229.677 174.533C243.24 163.341 259.488 159.94 276.56 159.94H438.75C465.21 159.94 491.67 159.784 518.13 159.94C544.59 160.097 568.757 175.826 578.586 200.493C582.369 209.989 583.173 219.789 583.173 229.834V768.324C583.173 775.449 583.231 782.446 581.663 789.482C575.078 818.883 548.628 839.766 518.63 840.06C514.857 840.06 514.847 845.94 518.63 845.94C548.3 845.693 574.713 827.088 584.937 799.234C588.376 789.718 589.043 779.927 589.043 769.951V251.012C589.043 231.716 590.679 212.047 582.183 194.064C570.58 169.613 545.374 154.256 518.483 154.06C510.359 154.002 502.234 154.06 494.11 154.06H275.913C258.861 154.06 242.642 157.51 228.677 167.937C210.734 181.456 200.205 202.635 200.257 225.101C200.198 240.781 200.257 256.519 200.257 272.239V732.319C200.257 746.666 200.14 761.013 200.257 775.361C200.453 801.154 214.506 825.341 237.38 837.581C250.443 844.578 264.281 845.94 278.706 845.94H518.62C522.364 845.94 522.373 840.06 518.62 840.06Z"
			fill="#7C839E"
		/>
		<path
			d="M513.985 183.539H492.621C485.369 183.539 476.568 182.333 469.522 184.519C462.476 186.704 459.555 192.731 453.94 196.886C449.305 200.277 444.14 201.169 438.544 201.169H352.343C347.963 201.169 343.749 201.169 339.603 199.346C332.489 196.279 329.372 188.899 322.424 185.626C315.476 182.353 306.519 183.509 298.973 183.509C291.133 183.509 283.293 183.421 275.502 183.509C249.807 183.673 228.973 204.376 228.648 230.069C228.501 241.408 228.648 252.756 228.648 264.095V758.181V769.686C228.893 792.226 245.151 812.267 267.74 815.844C275.247 817.04 283.204 816.462 290.77 816.462H497.903C505.214 816.462 512.858 817.001 520.139 816.031C543.316 812.944 560.319 792.863 560.544 769.706C560.593 765.972 560.544 762.229 560.544 758.495V264.634C560.544 253.128 560.691 241.613 560.544 230.108C560.227 204.525 539.568 183.861 513.985 183.539C510.202 183.48 510.202 189.36 513.985 189.419C536.522 189.703 554.649 208.04 554.674 230.579V243.054V731.711C554.674 744.344 554.811 756.986 554.674 769.618C554.568 792.117 536.415 810.362 513.916 810.582C507.301 810.67 500.686 810.582 494.071 810.582H289.3C281.293 810.582 272.64 811.297 264.8 809.239C246.337 804.339 234.577 787.503 234.508 768.775C234.459 754.496 234.508 740.218 234.508 725.939V239.722C234.508 236.527 234.508 233.332 234.508 230.138C234.576 217.809 240.151 206.155 249.708 198.366C258.185 191.506 268.152 189.419 278.765 189.419H305.588C308.057 189.419 310.546 189.311 313.006 189.419C316.838 189.465 320.538 190.821 323.492 193.26C326.217 195.553 328.059 198.621 330.862 200.865C338.81 207.225 347.835 207.049 357.381 207.049H416.7C424.54 207.049 432.429 207.137 440.289 207.049C445.835 207.098 451.278 205.55 455.969 202.59C462.339 198.415 465.367 190.967 473.53 189.664C480.135 188.595 487.387 189.428 494.042 189.428H513.945C517.767 189.419 517.777 183.539 513.985 183.539Z"
			fill="#7C839E"
		/>
		<path
			d="M421.512 452.872C421.512 452.872 419.836 439.152 428.372 438.015C436.908 436.878 438.721 442.18 442.18 439.769C445.639 437.358 469.62 416.994 472.56 413.681C475.5 410.369 483.546 397.707 489.818 409.418C496.09 421.129 518.375 453.803 518.375 453.803C518.375 453.803 518.434 456.566 513.769 459.134C509.104 461.701 508.291 459.751 508.624 464.318C508.957 468.885 506.341 492.738 506.341 492.738C506.341 492.738 506.497 531.046 500.02 530.301C493.542 529.557 429.959 521.815 430.283 514.955C430.606 508.095 431.263 483.595 430.283 483.291C429.303 482.987 417.543 472.197 417.543 472.197"
			fill="white"
		/>
		<path
			d="M639.983 525.353L642.159 498.893C663.278 498.756 713.356 502.705 741.913 501.833C754.359 495.58 760.611 481.096 762.787 467.278C765.416 450.439 763.578 433.204 757.456 417.298C752.801 405.234 743.393 392.798 730.457 392.886C724.959 392.935 719.775 395.317 714.777 397.649L577.146 462.26C575.186 463.172 573.128 464.22 572.089 466.082C571.05 467.944 571.266 470.13 571.511 472.198C572.257 478.317 573.333 484.392 574.735 490.396C574.924 491.858 575.557 493.226 576.548 494.316C577.724 495.375 579.429 495.571 581.007 495.718L616.738 499.02C618.769 507.808 620.795 516.598 622.814 525.392"
			fill="#D1D4F2"
		/>
		<path
			d="M731.221 412.075C727.469 434.017 729.662 456.564 737.572 477.372L731.221 412.075Z"
			fill="#D1D4F2"
		/>
		<path
			d="M731.084 434.34C733.231 441.984 734.039 449.941 733.475 457.86L731.084 434.34Z"
			fill="#D1D4F2"
		/>
		<path
			d="M642.923 525.352L645.099 498.892L642.159 501.832C669.599 501.724 697.039 504.361 724.479 504.831C730.094 504.929 737.601 506.134 743.02 504.527C748.9 502.802 754.163 496.158 757.279 491.278C765.6 478.273 767.609 461.417 766.678 446.315C765.698 430.635 761.611 413.446 751.439 401.069C747.303 396.031 741.746 391.788 735.239 390.445C727.399 388.828 720.216 391.896 713.287 395.12C676.469 412.26 639.787 429.616 602.988 446.864L588.014 453.94C583.182 456.203 577.871 458.114 573.314 460.927C565.66 465.68 568.845 475.735 570.178 483.036C570.883 486.897 571.158 492.013 573.559 495.266C575.823 498.373 579.311 498.5 582.859 498.833C594.139 499.911 605.399 500.891 616.708 501.92L613.876 499.764C615.901 508.565 617.927 517.359 619.952 526.146C620.795 529.831 626.459 528.273 625.616 524.578C623.585 515.784 621.559 506.994 619.54 498.206C619.168 496.946 618.022 496.073 616.708 496.05L591.316 493.728L585.681 493.218C583.868 493.051 580.948 493.404 579.38 492.532C576.44 490.895 576.195 483.467 575.676 480.566C575.019 476.803 572.892 470.266 575.313 467.16C577.538 464.288 582.438 463.005 585.662 461.495L609.486 450.323L703.899 406.027C711.396 402.509 719.314 397.531 727.566 396.159C734.808 394.963 741.129 398.423 745.911 403.666C756.035 414.759 759.916 431.782 760.807 446.354C761.65 460.3 760.053 475.637 752.575 487.759C749.557 492.659 744.628 498.922 738.454 499.029C732.652 499.127 726.85 499.029 721.059 498.922C694.755 498.343 668.442 495.893 642.129 495.982C640.521 496.018 639.226 497.313 639.189 498.922L637.014 525.382C636.71 529.155 642.58 529.135 642.894 525.382L642.923 525.352Z"
			fill="#7C839E"
		/>
		<path
			d="M728.389 411.29C724.614 433.75 726.804 456.808 734.739 478.156C736.053 481.664 741.737 480.116 740.404 476.598C732.748 456.269 730.558 434.288 734.053 412.849C734.661 409.154 729.006 407.566 728.389 411.29Z"
			fill="#7C839E"
		/>
		<path
			d="M728.252 435.075C730.246 442.495 731.018 450.191 730.535 457.86C730.32 461.633 736.19 461.623 736.415 457.86C736.901 449.67 736.057 441.456 733.916 433.536C732.936 429.891 727.272 431.439 728.252 435.104V435.075Z"
			fill="#7C839E"
		/>
		<path
			d="M427.627 627.675C427.627 627.675 471.217 586.093 471.776 586.093C472.335 586.093 500.559 614.592 501.862 612.308C503.165 610.025 533.81 582.536 552.763 570.413C571.716 558.291 585.348 554.87 593.923 543.708C602.498 532.546 617.326 511.368 618.639 519.502C619.952 527.636 616.091 533.095 623.147 531.497C630.203 529.9 663.699 517.189 660.926 525.353C658.152 533.516 651.831 567.493 642.825 573.863C633.819 580.233 627.077 583.663 625.185 598.157C623.294 612.651 617.345 653.037 608.633 661.151C599.921 669.266 583.359 675.851 574.225 670.314C565.092 664.777 553.528 657.418 553.528 657.418C553.528 657.418 521.687 691.502 497.53 688.562C473.373 685.622 434.36 659.515 427.598 649.068C420.836 638.621 413.495 637.935 427.627 627.675Z"
			fill="white"
		/>
		<path
			d="M370.914 423.56C370.914 423.56 375.814 392.808 394.082 395.944C412.349 399.08 417.915 413.446 417.915 413.446C417.915 413.446 433.458 381.988 451.314 381.391C469.169 380.793 502.195 377.216 512.662 386.349C523.128 395.483 529.233 396.473 519.11 406.93C508.987 417.386 501.548 432.831 496.129 421.188C490.71 409.546 484.32 397.1 475.735 407.88C467.15 418.66 453.048 434.517 445.679 437.359C438.309 440.201 431.625 433.86 426.079 437.359C420.532 440.857 414.152 445.806 420.081 454.361C426.01 462.917 427.343 466.18 431.625 466.602C435.908 467.023 441.906 464.054 438.975 475.216C436.045 486.378 432.968 505.596 424.138 497.462C415.308 489.328 406.165 489.485 406.498 470.884C406.831 452.284 405.381 441.2 405.381 441.2C405.381 441.2 403.617 453.45 392.376 452.833C381.136 452.215 367.092 436.486 370.914 423.56Z"
			fill="#7C839E"
		/>
		<path
			d="M363.682 727.193C363.835 750.404 369.763 773.211 380.93 793.559C384.85 800.693 389.652 807.279 393.425 814.521C395.179 817.882 400.285 814.913 398.501 811.581C391.426 798.047 382.606 786.101 377.412 771.538C372.347 757.303 369.698 742.322 369.572 727.213C369.572 723.44 363.692 723.43 363.692 727.213L363.682 727.193Z"
			fill="#7C839E"
		/>
		<path
			d="M482.076 406.292C490.759 418.49 499.321 430.779 507.762 443.16C509.869 446.257 511.985 449.344 514.053 452.47C515.17 454.165 516.689 455.822 514.386 457.468C513.406 458.203 511.446 458.027 510.251 458.34C504.655 459.791 504.194 462.045 504.057 467.288C503.518 487.632 503.146 507.987 502.577 528.322C502.47 532.105 508.34 532.105 508.457 528.322C509.075 506.527 509.437 484.732 510.065 462.927L508.604 465.465C512.691 462.525 519.727 464.054 521.707 458.134C523.51 452.735 517.121 446.502 514.416 442.513C505.485 429.342 496.397 416.275 487.152 403.313C484.977 400.256 479.881 403.186 482.086 406.253L482.076 406.292Z"
			fill="black"
		/>
		<path
			d="M464.847 442.259C463.691 443.454 463.056 445.06 463.084 446.723C463.112 448.387 463.799 449.971 464.994 451.128C465.955 452.044 467.4 452.231 468.562 451.588L469.894 450.785C471.176 449.92 471.57 448.211 470.795 446.873C470.02 445.536 468.342 445.026 466.954 445.708L465.622 446.512L469.179 446.972C469.057 446.865 468.943 446.751 468.836 446.629C469.061 446.914 469.081 446.914 468.905 446.629C469.042 446.963 469.061 446.972 468.954 446.629C468.846 446.286 468.895 446.316 468.954 446.688C468.954 446.345 468.954 446.365 468.905 446.747C468.935 446.591 468.974 446.437 469.022 446.286C468.875 446.62 468.875 446.639 469.022 446.345C468.787 446.61 468.787 446.62 469.022 446.345C470.102 445.187 470.07 443.382 468.95 442.262C467.831 441.142 466.025 441.111 464.867 442.19L464.847 442.259Z"
			fill="black"
		/>
		<path
			d="M486.28 439.161C485.12 440.357 484.484 441.966 484.512 443.631C484.539 445.297 485.228 446.883 486.427 448.04C487.384 448.958 488.829 449.141 489.984 448.491L491.317 447.697C492.599 446.833 492.992 445.124 492.218 443.786C491.443 442.448 489.765 441.939 488.377 442.621L487.035 443.424L490.592 443.885C490.471 443.781 490.36 443.666 490.259 443.542C490.474 443.826 490.494 443.816 490.318 443.542C490.455 443.875 490.474 443.875 490.376 443.542C490.308 443.199 490.308 443.219 490.376 443.601C490.376 443.255 490.357 443.274 490.318 443.66C490.346 443.504 490.386 443.35 490.435 443.199C490.288 443.522 490.288 443.542 490.435 443.248C490.21 443.522 490.21 443.532 490.435 443.248C491.515 442.09 491.483 440.284 490.363 439.165C489.244 438.045 487.438 438.013 486.28 439.093V439.161Z"
			fill="black"
		/>
		<path
			d="M483.409 396.247C470.825 412.965 455.496 427.426 438.074 439.015L441.631 439.475C433.938 430.234 419.209 435.496 416.925 446.58C414.083 460.408 426.833 469.62 439.24 470.247C443.023 470.443 443.013 464.573 439.24 464.367C431.243 463.936 422.404 459.163 422.462 450.098C422.521 442.258 431.89 436.888 437.496 443.63C438.441 444.578 439.908 444.768 441.063 444.091C459.302 431.925 475.344 416.752 488.505 399.217C490.778 396.189 485.682 393.258 483.428 396.277L483.409 396.247Z"
			fill="black"
		/>
		<path
			d="M433.36 454.851C433.444 454.837 433.53 454.837 433.615 454.851L432.831 454.753H432.958L432.253 454.459C432.507 454.596 432.419 454.508 431.978 454.214C431.684 453.773 431.596 453.675 431.733 453.94L431.439 453.234C431.454 453.319 431.454 453.405 431.439 453.489L431.331 452.705V452.832C431.331 454.456 432.648 455.772 434.271 455.772C435.882 455.741 437.18 454.443 437.211 452.832C437.233 451.786 436.827 450.777 436.087 450.037C435.347 449.297 434.338 448.891 433.291 448.912C431.668 448.912 430.351 450.229 430.351 451.852C430.351 453.476 431.668 454.792 433.291 454.792L433.36 454.851Z"
			fill="black"
		/>
		<path
			d="M484.487 465.573L472.139 466.866C469.306 467.17 465.68 466.964 463.319 468.709C458.507 472.207 461.525 480.567 464.593 484.212C468.179 488.456 474.618 490.671 479.685 487.672C485.565 484.203 485.81 476.892 485.32 470.894C485.016 467.151 479.136 467.121 479.44 470.894C479.724 474.383 480.42 479.381 477.48 482.027C474.353 484.82 469.894 482.135 468.111 479.087C467.484 478.029 465.798 474.334 467.199 473.207C468.052 472.55 471.119 472.776 472.148 472.668L484.496 471.365C488.22 470.973 488.26 465.093 484.496 465.485L484.487 465.573Z"
			fill="black"
		/>
		<path
			d="M464.789 481.713C469.627 482.99 474.624 483.561 479.626 483.409C483.399 483.291 483.418 477.411 479.626 477.529C475.151 477.68 470.678 477.181 466.347 476.049C462.691 475.069 461.123 480.763 464.789 481.713Z"
			fill="black"
		/>
		<path
			d="M425.52 388.897L427.48 397.766C428.078 400.491 431.459 400.324 432.85 398.472C447.296 379.146 473.677 378.039 495.188 383.478C500.712 384.828 506.112 386.644 511.329 388.907C513.504 389.887 516.229 390.779 518.052 392.288C521.197 394.885 519.923 398.168 518.845 401.324C515.485 411.006 508.674 419.111 499.716 424.089C496.433 425.951 499.392 431.028 502.656 429.156C513.137 423.263 521.014 413.637 524.716 402.196C526.244 397.355 527.077 392.229 522.883 388.554C517.856 384.144 509.555 381.694 503.283 379.676C477.372 371.502 445.463 371.836 427.735 395.503L433.105 396.208L431.145 387.329C430.332 383.645 424.667 385.203 425.481 388.897H425.52Z"
			fill="black"
		/>
		<path
			d="M417.102 405.832C411.467 395.434 398.482 390.475 387.28 394.17C375.364 398.09 367.543 409.409 366.269 421.61C365.064 433.184 369.209 450.167 380.969 455.41C394.689 461.555 405.087 447.57 410.183 436.898L405.107 433.958C397.272 451.537 399.256 471.941 410.33 487.682C413.985 492.827 422.894 504.459 430.41 501.274C434.526 499.53 436.143 494.414 437.623 490.582C440.063 484.179 441.747 477.512 442.641 470.718C443.15 467.013 437.486 465.416 436.976 469.15C436.371 473.873 435.388 478.539 434.036 483.105C433.125 486.094 430.988 496.413 426.765 495.629C423.913 495.09 420.169 490.386 418.297 488.289C415.969 485.637 413.947 482.731 412.27 479.626C405.185 466.641 404.048 450.481 410.222 436.927C411.761 433.527 406.792 430.528 405.146 433.987C401.363 441.896 392.974 455.979 382.175 449.452C373.639 444.297 371.022 430.303 372.257 421.247C373.541 411.888 379.646 402.912 388.917 399.874C397.794 396.912 407.522 400.676 412.094 408.841C413.897 412.163 418.954 409.203 417.17 405.901L417.102 405.832Z"
			fill="black"
		/>
		<path
			d="M417.837 526.597C397.884 538.543 388.584 562.651 390.348 585.24C392.425 611.926 408.468 635.348 426.471 654.154C444.473 672.96 468.219 690.12 495.218 690.12C521.962 690.12 544.1 672.431 561.23 653.586C583.841 628.492 602.756 600.301 617.404 569.864C619.05 566.483 613.984 563.494 612.328 566.924C600.175 592.063 585.125 615.695 567.483 637.337C552.312 655.82 534.3 675.587 510.368 682.162C485.075 689.13 460.388 676.812 441.612 660.504C422.58 644.06 404.94 622.422 398.433 597.716C392.151 573.882 398.521 545.011 420.806 531.674C424.05 529.714 421.1 524.657 417.866 526.597H417.837Z"
			fill="black"
		/>
		<path
			d="M497.354 618.835C516.17 599.921 538.935 585.074 561.338 570.815C571.746 564.18 582.124 557.987 591.493 549.892C596.012 545.985 600.002 541.504 603.361 536.564C607.281 530.831 610.456 523.089 616.777 519.649C620.099 517.846 617.139 512.789 613.837 514.583C608.251 517.621 605.134 523.05 601.812 528.244C598.078 534.124 594.256 539.69 589.023 544.306C579.145 552.999 568.13 559.525 557.085 566.581C534.731 580.88 512.005 595.805 493.228 614.699C490.563 617.385 494.708 621.559 497.383 618.855L497.354 618.835Z"
			fill="black"
		/>
		<path
			d="M643.59 522.246C638.048 524.996 632.008 526.599 625.832 526.96C622.069 527.185 622.049 533.065 625.832 532.84C633.041 532.419 640.089 530.538 646.549 527.312C649.94 525.637 646.971 520.57 643.609 522.246H643.59Z"
			fill="black"
		/>
		<path
			d="M642.825 536.936H642.384C640.76 536.936 639.444 538.252 639.444 539.876C639.444 541.5 640.76 542.816 642.384 542.816H642.825C644.449 542.816 645.765 541.5 645.765 539.876C645.765 538.252 644.449 536.936 642.825 536.936Z"
			fill="black"
		/>
		<path
			d="M637.2 553.253C640.983 553.253 640.993 547.373 637.2 547.373C633.407 547.373 633.417 553.253 637.2 553.253Z"
			fill="black"
		/>
		<path
			d="M629.429 561.475L616.787 565.209C614.043 566.023 611.063 567.052 611.142 570.413C611.23 574.186 617.1 574.206 617.022 570.413C616.172 571.556 616.414 571.769 617.747 571.05L618.952 570.697L623.166 569.453L631.006 567.14C634.623 566.072 633.084 560.397 629.448 561.475H629.429Z"
			fill="black"
		/>
		<path
			d="M440.632 522.334C452.568 526.254 464.877 528.626 477.303 530.36C489.318 532.046 501.656 534.192 513.808 533.712L510.868 530.772C510.79 537.188 509.624 543.543 507.419 549.568C506.135 553.136 511.809 554.664 513.083 551.127C515.438 544.595 516.677 537.715 516.748 530.772C516.748 529.148 515.432 527.832 513.808 527.832C501.588 528.322 489.122 526.186 477.048 524.441C465.219 522.879 453.551 520.276 442.18 516.66C438.583 515.464 437.035 521.139 440.612 522.324L440.632 522.334Z"
			fill="black"
		/>
		<path
			d="M467.836 584.015L415.504 635.465C412.8 638.121 416.955 642.276 419.66 639.621L471.992 588.171C474.687 585.515 470.531 581.311 467.836 584.015Z"
			fill="black"
		/>
		<path
			d="M564.768 668.648C574.323 674.998 587.21 678.105 598.147 673.47C611.063 667.982 615.787 652.164 619.707 639.944C622.274 632.108 624.285 624.101 625.724 615.983C627.028 608.633 627.204 600.979 628.948 593.727C630.771 586.21 635.426 582.33 641.571 578.223C646.334 575.029 650.92 571.755 653.566 566.463C655.261 563.092 650.195 560.113 648.49 563.523C643.59 573.186 631.046 575.411 625.871 585.083C623.215 590.062 622.49 595.707 621.853 601.234C621.03 608.97 619.747 616.651 618.012 624.234C616.327 631.367 614.19 638.385 611.612 645.246C609.221 651.625 606.712 658.966 601.861 663.866C592.669 673.293 577.489 670 567.698 663.483C564.543 661.386 561.593 666.472 564.758 668.56L564.768 668.648Z"
			fill="black"
		/>
		<path
			d="M655.124 555.86L655.781 555.213C656.86 554.055 656.828 552.25 655.709 551.13C654.589 550.011 652.784 549.979 651.626 551.058L650.969 551.705C649.89 552.863 649.921 554.668 651.041 555.788C652.161 556.908 653.966 556.94 655.124 555.86Z"
			fill="black"
		/>
		<path
			d="M656.31 545.639C660.093 545.639 660.103 539.759 656.31 539.759C652.517 539.759 652.527 545.639 656.31 545.639Z"
			fill="black"
		/>
		<path
			d="M661.357 524.5L657.535 526.636C656.529 527.109 655.875 528.108 655.845 529.219C655.816 530.33 656.415 531.363 657.394 531.888C658.374 532.414 659.566 532.342 660.475 531.703L664.297 529.576C665.698 528.758 666.172 526.96 665.355 525.558C664.514 524.189 662.744 523.723 661.337 524.5H661.357Z"
			fill="black"
		/>
		<path
			d="M380.068 595.707L357.008 657.447L345.826 687.405C343.318 694.099 337.006 704.497 340.838 711.739C342.406 714.679 345.278 716.247 348.178 717.697C352.686 719.941 357.253 722.078 361.849 724.146C370.728 728.144 379.731 731.852 388.858 735.269C407.743 742.316 427.096 748.04 446.776 752.399C468.457 757.187 490.468 760.332 512.622 761.807C516.395 762.062 516.376 756.182 512.622 755.927C475.588 753.433 439.028 746.187 403.842 734.367C386.092 728.416 368.76 721.286 351.961 713.023C350.292 712.379 348.724 711.497 347.306 710.406C344.984 708.211 345.552 705.506 346.395 702.831C347.845 698.146 349.795 693.57 351.51 688.973L362.016 660.838L385.752 597.275C387.075 593.737 381.4 592.198 380.087 595.717L380.068 595.707Z"
			fill="black"
		/>
		<path
			d="M543.924 560.241C537.122 549.882 526.019 537.338 512.436 544.737C509.114 546.54 512.074 551.597 515.376 549.814C525.617 544.237 533.996 555.831 538.818 563.2C540.886 566.346 545.982 563.406 543.894 560.26L543.924 560.241Z"
			fill="black"
		/>
		<path
			d="M787.748 364.133C774.514 375.889 760.692 386.964 746.333 397.315C744.461 398.668 744.373 402.118 747.029 402.686C749.684 403.254 752.164 403.823 754.712 404.509C755.476 404.715 761.866 406.106 761.944 406.625L762.699 403.764C760.112 406.704 756.054 408.958 752.899 411.271L743.305 418.346C740.296 420.571 743.226 425.667 746.245 423.423L758.779 414.162C761.239 412.349 766.981 409.644 767.599 406.234C768.079 403.588 765.894 402.029 763.767 401.118C759.014 399.089 753.565 398.09 748.538 397.021L749.234 402.392C764.007 391.755 778.229 380.373 791.844 368.288C794.676 365.779 790.501 361.634 787.689 364.133H787.748Z"
			fill="black"
		/>
		<path
			d="M752.526 448.295L778.006 439.328C781.554 438.084 780.025 432.409 776.448 433.664L750.968 442.631C747.421 443.875 748.949 449.559 752.526 448.295Z"
			fill="black"
		/>
		<path
			d="M793.735 436.702L794.382 436.045C795.462 434.887 795.43 433.082 794.31 431.962C793.191 430.843 791.385 430.811 790.227 431.89L789.58 432.547C788.434 433.695 788.434 435.554 789.58 436.702C790.743 437.812 792.573 437.812 793.735 436.702Z"
			fill="black"
		/>
		<path
			d="M797.626 472.442L759.827 466.17C756.133 465.553 754.545 471.217 758.269 471.835L796.068 478.107C799.762 478.724 801.36 473.06 797.626 472.442Z"
			fill="black"
		/>
		<path
			d="M743.226 469.992L743.883 470.639C744.607 471.457 745.725 471.807 746.786 471.547C747.847 471.286 748.676 470.459 748.939 469.399C749.201 468.338 748.855 467.22 748.038 466.494L747.381 465.837C746.648 465.051 745.545 464.727 744.503 464.993C743.461 465.259 742.648 466.072 742.382 467.114C742.116 468.156 742.44 469.259 743.226 469.992Z"
			fill="black"
		/>
		<path
			d="M501.656 576.548C503.472 577.167 505.463 577.015 507.164 576.126C508.565 575.308 509.038 573.51 508.222 572.108C507.374 570.747 505.609 570.286 504.204 571.06C504.88 570.736 504.292 570.981 504.116 571.06C503.94 571.138 503.851 571.128 504.283 571.06H503.969H503.655C504.054 571.132 504.142 571.132 503.92 571.06C503.685 570.991 503.45 570.942 503.214 570.854C501.651 570.429 500.037 571.349 499.608 572.912C499.221 574.468 500.122 576.054 501.656 576.518V576.548Z"
			fill="#7C839E"
		/>
		<path
			d="M503.107 594.237H507.027C508.65 594.237 509.967 592.921 509.967 591.297C509.967 589.673 508.65 588.357 507.027 588.357H503.107C501.483 588.357 500.167 589.673 500.167 591.297C500.167 592.921 501.483 594.237 503.107 594.237Z"
			fill="#7C839E"
		/>
		<path
			d="M506.37 707.819H511.593C513.217 707.819 514.533 706.503 514.533 704.879C514.533 703.255 513.217 701.939 511.593 701.939H506.37C504.746 701.939 503.43 703.255 503.43 704.879C503.43 706.503 504.746 707.819 506.37 707.819Z"
			fill="#7C839E"
		/>
		<path
			d="M504.292 729.516C507.287 731.164 510.959 730.967 513.759 729.006C515.12 728.158 515.584 726.397 514.818 724.988C514.083 723.734 512.074 722.979 510.8 723.93C509.838 724.811 508.436 725.017 507.262 724.449C503.91 722.695 500.941 727.762 504.322 729.516H504.292Z"
			fill="#7C839E"
		/>
		<path
			d="M283.106 269.553H390.818C394.601 269.553 394.601 263.673 390.818 263.673H283.106C279.333 263.673 279.324 269.553 283.106 269.553Z"
			fill="white"
		/>
		<path
			d="M283.106 302.843H390.818C394.601 302.843 394.601 296.963 390.818 296.963H283.106C279.333 296.963 279.324 302.843 283.106 302.843Z"
			fill="white"
		/>
		<path
			d="M283.106 336.134H337.006C340.779 336.134 340.789 330.254 337.006 330.254H283.106C279.333 330.254 279.324 336.134 283.106 336.134Z"
			fill="white"
		/>
		<path
			d="M454.92 338.81C455.677 343.095 455.829 347.466 455.371 351.795C454.998 355.558 460.869 355.528 461.251 351.795C461.714 346.94 461.493 342.044 460.594 337.251C459.928 333.537 454.263 335.105 454.93 338.81H454.92Z"
			fill="#7C839E"
		/>
		<path
			d="M480.998 336.134C480.906 340.337 479.983 344.481 478.283 348.325C476.754 351.746 481.811 354.735 483.36 351.265C485.531 346.5 486.728 341.349 486.878 336.115C486.976 332.332 481.106 332.342 480.998 336.115V336.134Z"
			fill="#7C839E"
		/>
		<path
			d="M507.301 342.769C506.78 347.009 504.859 350.954 501.842 353.98C499.177 356.665 503.322 360.84 505.998 358.135C509.761 354.417 512.207 349.572 512.965 344.337C513.475 340.642 507.82 339.035 507.301 342.769Z"
			fill="#7C839E"
		/>
		<path d="M453.989 455.998H460.349V462.358H453.989V455.998Z" fill="#D1D4F2" />
	</svg>
);
