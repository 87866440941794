import { Alert, AlertTitle, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { FC, ReactNode } from 'react';

type AlertMessageProps = {
	title?: string;
	message: string;
	action?: ReactNode;
};

export const AlertMessage: FC<AlertMessageProps> = ({ title, message, action }) => {
	const { t } = useTranslation();

	return (
		<Box mt={1} mb={1}>
			<Alert severity="warning" action={action}>
				{title ? <AlertTitle>{t(title)}</AlertTitle> : null}
				{t(message)}
			</Alert>
		</Box>
	);
};
