import { createContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { getWorkspaceChannel } from '@heylog-app/shared/util';

import { useAuthContext, useEnvContext } from '../hooks';
import { bindToPusherStateChange, buildPusherInstance } from '../util';

import type { Channel } from 'pusher-js';
import type { Nullable } from '@heylog-app/shared/types';
import type { PusherChannelsContextType } from '../types/pusher-channels.types';
import type { FC, PropsWithChildren } from 'react';

export const PusherChannelsContext =
	createContext<Nullable<PusherChannelsContextType>>(null);

const logger = (...args: unknown[]) => console.log('[PusherChannels]:', ...args);

export const PusherChannelsProvider: FC<PropsWithChildren> = ({ children }) => {
	const { workspaceId = 'NONE' } = useParams();
	const envContext = useEnvContext();
	const authContext = useAuthContext();

	const [client] = useState(() => buildPusherInstance(envContext, authContext));
	const [wsChannel, setWsChannel] = useState<Channel>();

	useEffect(() => {
		const channelId = getWorkspaceChannel(workspaceId);
		const channel = client.subscribe(channelId);

		channel.bind_global((eventName: string, data: unknown) => {
			logger('[Channel]:', eventName, data);
		});

		bindToPusherStateChange(client, ({ current, previous }) => {
			logger(`[Connection]: ${previous} -> ${current}`);
		});

		setWsChannel(channel);

		logger(`subscribe to channel ${channelId}`);

		return () => {
			logger(`unsubscribe from channel ${channelId}`);
			client.unsubscribe(channelId);
		};
	}, [client, workspaceId]);

	const value = useMemo(
		() => ({
			channelsClient: client,
			wsChannel,
		}),
		[client, wsChannel],
	);

	return (
		<PusherChannelsContext.Provider value={value}>
			{children}
		</PusherChannelsContext.Provider>
	);
};
