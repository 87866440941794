export const trailerSurveyJson = {
	checkErrorsMode: 'onValueChanged',
	showQuestionNumbers: 'off',
	showNavigationButtons: 'none',
	pages: [
		//page1 - trailer-license-plate
		{
			name: 'page1',
			elements: [
				{
					type: 'text',
					name: 'trailer-license-plate',
					title: {
						default: 'License plate of trailer',
						en: 'License plate of trailer',
						de: 'Auflieger Kennzeichen',
						et: 'Haagise numbrimärk',
						es: 'Matrícula del remolque',
						fr: "Plaque d'immatriculation de la remorque",
						hr: 'Registarska oznaka prikolice',
						it: 'Targa del rimorchio',
						lv: 'Piekabes numura zīme',
						lt: 'Priekabos valstybinis numeris',
						hu: 'Az utánfutó rendszámtáblája',
						pl: 'Tablica rejestracyjna przyczepy',
						pt: 'Matrícula do reboque',
						ro: 'Numărul de înmatriculare al remorcii',
						sk: 'ŠPZ prívesu',
						rs: 'Регистарска ознака приколице',
						fi: 'Perävaunun rekisterikilpi',
						tr: 'Römorkun plakası',
						cs: 'Poznávací značka přívěsu',
						bg: 'Регистрационен номер на ремаркето',
						mk: 'Регистерска табличка на приколката',
						ru: 'Номерной знак прицепа',
						ua: 'Номерний знак причепа',
						sl: 'Registrska tablica prikolice',
						bs: 'Registarska oznaka prikolice',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page2 - tire-damage-side
		{
			name: 'page2',
			elements: [
				{
					type: 'dropdown',
					name: 'tire-damage-side',
					isRequired: true,
					choices: [
						{
							value: 'right',
							text: {
								default: 'Right',
								en: 'Right',
								de: 'Rechts',
								et: 'Õigus',
								es: 'A la derecha',
								fr: 'Droit',
								hr: 'Pravo',
								it: 'Giusto',
								lv: 'Tiesības',
								lt: 'Dešinė',
								hu: 'Jobb',
								pl: 'Prawo',
								pt: 'Certo',
								ro: 'Dreapta',
								sk: 'Vpravo',
								rs: 'Јел тако',
								fi: 'Oikea',
								tr: 'Doğru',
								cs: 'Vpravo',
								bg: 'Вдясно',
								mk: 'Во право',
								ru: 'Правильно',
								ua: 'Гаразд',
								sl: 'Desno',
								bs: 'U redu',
							},
						},
						{
							value: 'left',
							text: {
								default: 'Left',
								en: 'Left',
								de: 'Links',
								et: 'Izquierda',
								es: 'Vasakpoolne',
								fr: 'Gauche',
								hr: 'Lijevo',
								it: 'Sinistra',
								lv: 'Kreisā',
								lt: 'Kairėje pusėje',
								hu: 'Bal',
								pl: 'Lewa',
								pt: 'Esquerda',
								ro: 'Stânga',
								sk: 'Vľavo',
								rs: 'Лево',
								fi: 'Vasen',
								tr: 'Sol',
								cs: 'Vlevo',
								bg: 'Вляво',
								mk: 'Лево',
								ru: 'Левый',
								ua: 'Ліворуч',
								sl: 'Leva stran',
								bs: 'Lijevo',
							},
						},
					],
					title: {
						default:
							'On which side of the vehicle (driving direction) is the tire damaged?',
						en: 'On which side of the vehicle (driving direction) is the tire damaged?',
						de: 'Auf welcher Fahrzeugseite (Fahrtrichtung) ist der Reifen beschädigt?',
						et: 'Kummal pool sõidukit (sõidusuunas) on rehv kahjustatud?',
						es: '¿En qué lado del vehículo (sentido de la marcha) está dañado el neumático?',
						fr: 'De quel côté du véhicule (sens de la marche) le pneu est-il endommagé?',
						hr: 'Na kojoj je strani vozila (smjer vožnje) guma oštećena?',
						it: 'Su quale lato del veicolo (direzione di marcia) è danneggiato lo pneumatico?',
						lv: 'Kurā transportlīdzekļa pusē (braukšanas virzienā) ir bojāta riepa?',
						lt: 'Kurioje transporto priemonės pusėje (važiavimo kryptimi) pažeista padanga?',
						hu: 'A jármű melyik oldalán (menetirányban) sérült meg a gumiabroncs?',
						pl: 'Po której stronie pojazdu (kierunek jazdy) opona jest uszkodzona?',
						pt: 'De que lado do veículo (direção de condução) está o pneu danificado?',
						ro: 'Pe ce parte a vehiculului (direcția de mers) este deteriorat anvelopa?',
						sk: 'Na ktorej strane vozidla (v smere jazdy) je pneumatika poškodená?',
						rs: 'На којој страни возила (смер вожње) је гума оштећена?',
						fi: 'Millä puolella ajoneuvoa (ajosuunta) rengas on vaurioitunut?',
						tr: 'Lastik aracın hangi tarafında (sürüş yönünde) hasar görmüş?',
						cs: 'Na které straně vozidla (ve směru jízdy) je pneumatika poškozena?',
						bg: 'От коя страна на автомобила (в посока на движението) е повредена гумата?',
						mk: 'На која страна од возилото (правец на возење) е оштетена гумата?',
						ru: 'С какой стороны автомобиля(направление движения) повреждена шина?',
						ua: 'З якого боку транспортного засобу (напрямку руху) пошкоджена шина?',
						sl: 'Na kateri strani vozila (smer vožnje) je poškodovana pnevmatika?',
						bs: 'Na kojoj strani vozila (smjer vožnje) je guma oštećena?',
					},
					maxLength: 30,
				},
			],
		},
		//page3 - tire-damage-axle-trailer
		{
			name: 'page3',
			elements: [
				{
					type: 'dropdown',
					name: 'tire-damage-axle-trailer',
					isRequired: true,
					choices: [
						{
							value: '1. axle',
							text: {
								default: '1. axle',
								en: '1. axle',
								de: '1. Achse',
								et: '1. telg',
								es: '1. eje',
								fr: '1. axe',
								hr: '1. osovina',
								it: '1. asse',
								lv: '1. ass',
								lt: '1. ašis',
								hu: '1. tengely',
								pl: '1. oś',
								pt: '1. eixo',
								ro: '1. axa',
								sk: '1. náprava',
								rs: '1. осовина',
								fi: '1. akseli',
								tr: '1. aks',
								cs: '1. náprava',
								bg: '1. ос',
								mk: '1. оска',
								ru: '1. Ось',
								ua: '1. вісь',
								sl: '1. os',
								bs: '1. osovina',
							},
						},
						{
							value: '2. axle',
							text: {
								default: '2. axle',
								en: '2. axle',
								de: '2. Achse',
								et: '2. telg',
								es: '2. eje',
								fr: '2. axe',
								hr: '2. osovina',
								it: '2. asse',
								lv: '2. ass',
								lt: '2. ašis',
								hu: '2. tengely',
								pl: '2. oś',
								pt: '2. eixo',
								ro: '2. axa',
								sk: '2. náprava',
								rs: '2. осовина',
								fi: '2. akseli',
								tr: '2. aks',
								cs: '2. náprava',
								bg: '2. ос',
								mk: '2. оска',
								ru: '2. Ось',
								ua: '2. вісь',
								sl: '2. os',
								bs: '2. osovina',
							},
						},
						{
							value: '3. axle',
							text: {
								default: '3. axle',
								en: '3. axle',
								de: '3. Achse',
								et: '3. telg',
								es: '3. eje',
								fr: '3. axe',
								hr: '3. osovina',
								it: '3. asse',
								lv: '3. ass',
								lt: '3. ašis',
								hu: '3. tengely',
								pl: '3. oś',
								pt: '3. eixo',
								ro: '3. axa',
								sk: '3. náprava',
								rs: '3. осовина',
								fi: '3. akseli',
								tr: '3. aks',
								cs: '3. náprava',
								bg: '3. ос',
								mk: '3. оска',
								ru: '3. Ось',
								ua: '3. вісь',
								sl: '3. os',
								bs: '3. osovina',
							},
						},
					],
					title: {
						default: 'On which trailer axle is the tire damage?',
						en: 'On which trailer axle is the tire damage?',
						de: 'Auf welcher Aufliegerachse ist der Reifenschaden?',
						et: 'Millisel haagise teljel on rehvikahjustus?',
						es: '¿En qué eje del remolque está dañado el neumático?',
						fr: 'Sur quel essieu de la remorque le pneu est-il endommagé?',
						hr: 'Na kojoj je osovini prikolice oštećena guma?',
						it: 'Su quale asse del rimorchio si trova il pneumatico danneggiato?',
						lv: 'Uz kuras piekabes ass ir riepas bojājums?',
						lt: 'Ant kurios priekabos ašies pažeista padanga?',
						hu: 'Melyik pótkocsi tengelyén sérült meg a gumiabroncs?',
						pl: 'Na której osi przyczepy opona jest uszkodzona?',
						pt: 'Em que eixo do reboque é que o pneu está danificado?',
						ro: 'Pe ce axă a remorcii este avariată anvelopa?',
						sk: 'Na ktorej náprave prívesu je poškodená pneumatika?',
						rs: 'На којој осовини приколице је оштећена гума?',
						fi: 'Millä perävaunun akselilla rengas on vaurioitunut?',
						tr: 'Lastik hasarı hangi römork aksında?',
						cs: 'Na které nápravě přívěsu je poškozená pneumatika?',
						bg: 'На коя ос на ремаркето е повредата на гумата?',
						mk: 'На која оска на приколката е оштетена гумата?',
						ru: 'На какой оси прицепа повреждена шина?',
						ua: 'На якій осі причепа пошкоджена шина?',
						sl: 'Na kateri osi prikolice je poškodovana pnevmatika?',
						bs: 'Na kojoj osovini prikolice je oštećena guma?',
					},
					maxLength: 30,
				},
			],
		},
		//page4 - tire-size
		{
			name: 'page4',
			elements: [
				{
					type: 'dropdown',
					name: 'tire-size',
					isRequired: true,
					choices: ['315/70 R22,5', '385/55 R22,5', '385/65 R22,5'],
					title: {
						default: 'Which tire size?',
						en: 'Which tire size?',
						de: 'Welche Reifengröße?',
						et: 'Millise rehvimõõduga?',
						es: '¿Qué tamaño de neumático?',
						fr: 'Quelle taille de pneu?',
						hr: 'Koja veličina gume?',
						it: 'Quale misura di pneumatici?',
						lv: 'Kāda izmēra riepas?',
						lt: 'Kokio dydžio padangos?',
						hu: 'Melyik gumiabroncs méret?',
						pl: 'Jaki rozmiar opon?',
						pt: 'Qual é o tamanho do pneu?',
						ro: 'Ce dimensiuni de anvelope?',
						sk: 'Akú veľkosť pneumatík?',
						rs: 'Која величина гума?',
						fi: 'Mikä rengaskoko?',
						tr: 'Hangi lastik ölçüsü?',
						cs: 'Jaká velikost pneumatik?',
						bg: 'Какъв размер гуми?',
						mk: 'Која големина на гума?',
						ru: 'Какой размер шин?',
						ua: 'Який розмір шин?',
						sl: 'Katera velikost pnevmatik?',
						bs: 'Koja veličina guma?',
					},
					maxLength: 30,
				},
			],
		},
		//page5 - photo-whole-tire
		{
			name: 'page5',
			elements: [
				{
					type: 'file',
					name: 'photo-whole-tire',
					isRequired: true,
					title: {
						default: 'Photo of the whole tire',
						en: 'Photo of the whole tire',
						de: 'Foto vom ganzen Reifen',
						et: 'Foto kogu rehvist',
						es: 'Foto del neumático completo',
						fr: 'Photo du pneu entier',
						hr: 'Slika cijele gume',
						it: "Foto dell'intero pneumatico",
						lv: 'Visas riepas fotoattēls',
						lt: 'Visos padangos nuotrauka',
						hu: 'Fotó az egész gumiabroncsról',
						pl: 'Zdjęcie całej opony',
						pt: 'Foto do pneu inteiro',
						ro: 'Fotografie a întregii anvelope',
						sk: 'Fotografia celej pneumatiky',
						rs: 'Фотографија целе гуме', // serbian
						fi: 'Kuva koko renkaasta',
						tr: 'Tüm lastiğin fotoğrafı',
						cs: 'Fotografie celé pneumatiky',
						bg: 'Снимка на цялата гума',
						mk: 'Фотографија од целата гума',
						ru: 'Фотография всей покрышки',
						ua: 'Фотографія всієї шини',
						sl: 'Fotografija celotne pnevmatike',
						bs: 'Fotografija cijele gume',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page6 - photo-tread-depth
		{
			name: 'page6',
			elements: [
				{
					type: 'file',
					name: 'photo-tread-depth',
					isRequired: true,
					title: {
						default: 'Photo of the tread depth',
						en: 'Photo of the tread depth',
						de: 'Foto von der Profiltiefe',
						et: 'Foto turvise sügavuse kohta',
						es: 'Foto de la profundidad de la banda de rodadura',
						fr: 'Photo de la profondeur de la bande de roulement',
						hr: 'Fotografija dubine gaznoga sloja',
						it: 'Foto della profondità del battistrada',
						lv: 'Protektora dziļuma fotoattēls',
						lt: 'Protektoriaus gylio nuotrauka',
						hu: 'Fotó a futófelület mélységéről',
						pl: 'Zdjęcie głębokości bieżnika',
						pt: 'Fotografia da profundidade do piso',
						ro: 'Fotografie a adâncimii benzii de rulare',
						sk: 'Fotografia hĺbky dezénu',
						rs: 'Слика дубине газећег слоја', // serbian
						fi: 'Kuva kulutuspinnan syvyydestä',
						tr: 'Diş derinliğinin fotoğrafı',
						cs: 'Fotografie hloubky běhounus',
						bg: 'Снимка на дълбочината на протектора',
						mk: 'Фотографија од длабочината на шарата',
						ru: 'Фотография глубины протектора',
						ua: 'Фотографія глибини протектора',
						sl: 'Fotografija globine tekalne plasti',
						bs: 'Fotografija dubine gazećeg sloja',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page7 - photo-tire-damage
		{
			name: 'page7',
			elements: [
				{
					type: 'file',
					name: 'photo-tire-damage',
					isRequired: true,
					title: {
						default: 'Photo of the damage to the tire',
						en: 'Photo of the damage to the tire',
						de: 'Foto von dem Schaden an dem Reifen',
						et: 'Foto rehvi kahjustusest',
						es: 'Foto de los daños en el neumático',
						fr: 'Photo des dommages subis par le pneu',
						hr: 'Slika oštećenja gume',
						it: 'Foto del danno allo pneumatico',
						lv: 'Fotogrāfija ar riepas bojājumiem',
						lt: 'Padangos pažeidimo nuotrauka',
						hu: 'Fotó a gumiabroncs sérüléséről',
						pl: 'Zdjęcie uszkodzenia opony',
						pt: 'Foto dos danos no pneu',
						ro: 'Fotografie a deteriorării anvelopei',
						sk: 'Fotografia poškodenia pneumatiky',
						rs: 'Фотографија оштећења гуме', // serbian
						fi: 'Kuva renkaan vaurioista',
						tr: 'Lastikteki hasarın fotoğrafı',
						cs: 'Fotografie poškození pneumatiky',
						bg: 'Снимка на повредата на гумата',
						mk: 'Фотографија од оштетување на гумата',
						ru: 'Фотография повреждения покрышки',
						ua: 'Фото пошкодження шини',
						sl: 'Fotografija poškodbe pnevmatike',
						bs: 'Fotografija oštećenja gume',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page8 - can-you-still-drive
		{
			name: 'page8',
			elements: [
				{
					type: 'boolean',
					name: 'can-you-still-drive',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Can you still drive?',
						en: 'Can you still drive?',
						de: 'Kannst du noch fahren?',
						et: 'Kas te saate veel sõita?',
						es: '¿Puedes seguir conduciendo?',
						fr: 'Peux-tu encore conduire?',
						hr: 'Znaš li i dalje voziti?',
						it: 'Puoi ancora guidare?',
						lv: 'Vai jūs joprojām varat vadīt automašīnu?',
						lt: 'Ar vis dar galite vairuoti?',
						hu: 'Tudsz még vezetni?',
						pl: 'Czy nadal możesz prowadzić?',
						pt: 'Ainda podes conduzir?',
						ro: 'Mai poți să conduci?',
						sk: 'Môžete ešte šoférovať?',
						rs: 'Можеш ли још да возиш?',
						fi: 'Voitko vielä ajaa?',
						tr: 'Hala araba kullanabiliyor musun?',
						cs: 'Můžete stále řídit?',
						bg: 'Можете ли все още да шофирате?',
						mk: 'Можеш ли уште да возиш?',
						ru: 'Можешь ли ты по-прежнему водить машину?',
						ua: 'Ти все ще можеш водити?',
						sl: 'Ali lahko še vedno vozite?',
						bs: 'Možeš li još uvijek voziti?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					type: 'text',
					name: 'how-far',
					visibleIf: '{can-you-still-drive} == "Yes"',
					title: {
						default: '(If yes) How far do you estimate?',
						en: '(If yes) How far do you estimate?',
						de: '(Wenn ja) Wie weit schätzt du?',
						et: '(Kui jah) Kui kaugele hindate?',
						es: '(Si la respuesta es afirmativa) ¿Qué distancia calculas?',
						fr: '(Si oui) Quelle est la distance estimée ?',
						hr: '(Ako da) Koliko daleko procjenjujete?',
						it: '(Se sì) Quanto stima di essere lontano?',
						lv: '(Ja jā) Cik tālu jūs lēšat?',
						lt: '(Jei taip) Kaip manote, kokiu atstumu?',
						hu: '(Ha igen) Milyen messzire?',
						pl: '(Jeśli tak) Jak daleko szacujesz?',
						pt: '(Se sim) Qual a distância que estimas?',
						ro: '(Dacă da) La ce distanță estimați?',
						sk: '(Ak áno) Akú vzdialenosť odhadujete?',
						rs: '(Ако да) Колико далеко процењујете?',
						fi: '(Jos kyllä) Kuinka pitkälle arvioit?',
						tr: '(Evet ise) Ne kadar uzakta olduğunu tahmin ediyorsunuz?',
						cs: '(Pokud ano) Jakou vzdálenost odhadujete?',
						bg: '(Ако отговорът е "да") Колко далече смятате да стигнете?',
						mk: '(Ако да) Колку далеку проценувате?',
						ru: '(Если да) На какое расстояние ты рассчитываешь?',
						ua: '(Якщо так) Наскільки далеко ви оцінюєте?',
						sl: '(Če da) Kako daleč ocenjujete?',
						bs: '(ako da) Koliko daleko procjenjujete?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page9 - notes-trailer
		{
			name: 'page9',
			elements: [
				{
					type: 'text',
					name: 'notes-trailer',
					title: {
						default: 'Any other comments',
						en: 'Any other comments',
						de: 'Andere Anmerkungen',
						et: 'Muud kommentaarid',
						es: 'Otros comentarios',
						fr: 'Autres commentaires',
						hr: 'Bilo koji drugi komentari',
						it: 'Altri commenti',
						lv: 'Citi komentāri',
						lt: 'Kitos pastabos',
						hu: 'Egyéb észrevételek',
						pl: 'Inne uwagi',
						pt: 'Outras observaçõesse',
						ro: 'Orice alte observațiise',
						sk: 'Akékoľvek ďalšie pripomienky',
						rs: 'Било који други коментар',
						fi: 'Mahdolliset muut huomautuksetse',
						tr: 'Başka yorumunuz varsa',
						cs: 'Jakékoli další připomínky',
						bg: 'Други коментари',
						mk: 'Сите други коментари',
						ru: 'Любые другие комментарииse',
						ua: 'Будь-які інші коментарі',
						sl: 'Morebitne druge pripombe',
						bs: 'Bilo koji drugi komentar',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
	],
};
