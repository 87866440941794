import { Button, DialogContent, TextField } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import { APIProvider } from '@vis.gl/react-google-maps';
import Icon from '@mdi/react';
import { mdiTruckDeliveryOutline } from '@mdi/js';

import { ROUTES } from '../../util/routes';
import { useDialog, useEnvContext, useOrderActions, usePlugins } from '../../hooks';
import { preventSubmitOnEnter } from '../../util';
import { StyledDialogActions } from '../dialog';
import { getMiscOrderData } from './get-misc-order-data';
import { AddTaskDialog } from '../add-task-dialog/add-task-dialog';
import { VerticalStepper } from '../vertical-stepper/vertical-stepper';
import { ButtonV2 } from '../ui';

import type {
	CreateOrderTaskReqInterface,
	CreateOrderReqInterface as CreateOrderV2ReqInterface,
	OrderResInterface,
	OrderTaskResInterface,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

type FormData = CreateOrderV2ReqInterface & {
	etaFrom: string;
	etaTo: string;
	totalWeight: string;
	volumeOfOrder: string;
};

type CreateOrderFormV2Props = {
	order?: OrderResInterface; // provide ID if editing an order: ;
	onSuccess: () => void;
	onCancel: () => void;
};

export const CreateOrderFormV2: FC<CreateOrderFormV2Props> = ({
	order,
	onSuccess,
	onCancel,
}) => {
	const { createOrder, updateOrder, isLoading } = useOrderActions(order?.id.toString());
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { workspaceId = '', shipmentId = '' } = useParams();
	const { FrontendV2Plugin } = usePlugins(workspaceId);
	const env = useEnvContext();

	const withTasks = shipmentId === '';

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		control,
	} = useForm<FormData>({
		defaultValues: {
			refNumber: '',
			customer: '',
			licensePlateTrailer: '',
			licensePlateTruck: '',
		},
	});

	const { totalWeight, volumeOfOrder } = useMemo(() => getMiscOrderData(order), [order]);

	useEffect(() => {
		if (order && !isLoading) {
			reset({
				customer: order.customer ?? '',
				licensePlateTrailer: order.licensePlateTrailer ?? '',
				licensePlateTruck: order.licensePlateTruck ?? '',
				refNumber: order.refNumber ?? '',
				// tasks: order.tasks ?? [],
			});
		}
	}, [order, reset, totalWeight, volumeOfOrder, isLoading]);

	const onSubmit = async (formData: FormData) => {
		const { totalWeight, volumeOfOrder, etaFrom, etaTo, ...data } = formData;

		if (order) {
			const res = await updateOrder({
				id: order?.id.toString(),
				data: {
					...data,
				},
				workspaceId,
			});

			if (res) {
				onSuccess();
			}
		} else {
			const res = await createOrder({
				data: {
					...data,
					tasks: tasks.map((task) => {
						return {
							...task,
							etaFrom: task.etaFrom?.toISOString(),
							etaTo: task.etaTo?.toISOString(),
						} as CreateOrderTaskReqInterface;
					}),
				},
				workspaceId,
			});

			if (res) {
				onSuccess();
				if (!FrontendV2Plugin) {
					navigate(generatePath(ROUTES.ORDERS.DETAIL, { orderId: res.id, workspaceId }));
				}
			}
		}
	};

	const {
		showDialog: showNewTaskDialog,
		openDialog: openNewTaskDialog,
		closeDialog: closeNewTaskDialog,
	} = useDialog();

	const [tasks, setTasks] = useState<OrderTaskResInterface[]>([]);
	const [currentTasks, _setCurrentTasks] = useState<number[]>([]);
	const [completedTasks, _setCompletedtasks] = useState<number[]>([]);

	const [warningTasks, _setWarningTasks] = useState<number[]>([]);
	const [_statusChanged, setStatusChanged] = useState<{ status: string; taskId: string }>(
		{
			status: '',
			taskId: '',
		},
	);

	const onSubmitTask = (task: CreateOrderTaskReqInterface) => {
		const taskForDisplay = {
			...task,
			createdAt: new Date(),
			id: tasks.length + 1,
			orderId: 0,
			status: 'PENDING',
			updatedAt: new Date(),
			company: task.company ?? '',
			customerReference: task.customerReference ?? '',
			etaFrom: task.etaFrom ? new Date(task.etaFrom) : null,
			etaTo: task.etaTo ? new Date(task.etaTo) : null,
		} as OrderTaskResInterface;
		setTasks((prevTasks) => [...prevTasks, taskForDisplay]);

		closeNewTaskDialog();
	};

	const deleteTask = (taskId?: number) => {
		setTasks((prevTasks) => prevTasks.filter((task) => task.id !== taskId));
	};

	return (
		<>
			<form
				data-test="edit-order-dialog"
				onSubmit={handleSubmit(onSubmit)}
				onKeyDown={preventSubmitOnEnter}
				noValidate
			>
				<DialogContent>
					<Controller
						control={control}
						name="refNumber"
						render={({ field }) => (
							<TextField
								data-test="order-refnumber-input"
								label={t('orders.details.orderRef')}
								fullWidth
								margin="dense"
								error={errors.refNumber ? true : false}
								helperText={errors.refNumber && t('forms.errors.required')}
								{...register('refNumber', { required: true })}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						name="customer"
						render={({ field }) => (
							<TextField
								data-test="order-customer-input"
								label={t('orders.details.customer')}
								fullWidth
								margin="dense"
								{...register('customer')}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						name="licensePlateTruck"
						render={({ field }) => (
							<TextField
								data-test="order-licensePlateTruck-input"
								label={t('orders.details.licensePlateTruck')}
								fullWidth
								margin="dense"
								{...register('licensePlateTruck')}
								{...field}
							/>
						)}
					/>

					<Controller
						control={control}
						name="licensePlateTrailer"
						render={({ field }) => (
							<TextField
								data-test="order-licensePlateTrailer-input"
								label={t('orders.details.licensePlateTrailer')}
								fullWidth
								margin="dense"
								{...register('licensePlateTrailer')}
								{...field}
							/>
						)}
					/>
					{!FrontendV2Plugin ? (
						<>
							<Controller
								control={control}
								name="totalWeight"
								render={({ field }) => (
									<TextField
										data-test="order-weight-input"
										label={t('orders.details.totalWeight')}
										fullWidth
										margin="dense"
										{...register('totalWeight')}
										{...field}
									/>
								)}
							/>

							<Controller
								control={control}
								name="volumeOfOrder"
								render={({ field }) => (
									<TextField
										data-test="order-volume-input"
										label={t('orders.details.volumeOfOrder')}
										fullWidth
										margin="dense"
										{...register('volumeOfOrder')}
										{...field}
									/>
								)}
							/>

							<Controller
								control={control}
								name="notes"
								render={({ field }) => (
									<TextField
										data-test="order-notes-input"
										label={t('orders.details.labels')}
										fullWidth
										margin="dense"
										multiline
										{...register('notes')}
										{...field}
									/>
								)}
							/>
						</>
					) : null}
					{withTasks && (
						<>
							<ButtonV2
								onClick={openNewTaskDialog}
								$variant="dark"
								startIcon={<Icon path={mdiTruckDeliveryOutline} size={1} />}
							>
								{t('orders.tasks.create')}
							</ButtonV2>
							<VerticalStepper
								completedSteps={completedTasks}
								contacts={order && order.contacts}
								currentSteps={currentTasks}
								customer={order?.customer ? order.customer : ''}
								deleteTask={deleteTask}
								haveSwitcher={false}
								isLinear={true}
								setStatusChanged={setStatusChanged}
								steps={tasks}
								warningSteps={warningTasks}
							/>
						</>
					)}
				</DialogContent>

				<StyledDialogActions>
					<Button variant="outlined" type="reset" onClick={onCancel}>
						{t('actionLabels.cancel')}
					</Button>
					<Button data-test="submit-order-button" variant="contained" type="submit">
						{order ? t('actionLabels.save') : t('orders.form.labels.submit')}
					</Button>
				</StyledDialogActions>
			</form>
			{withTasks && (
				<APIProvider apiKey={env.GPLACES_KEY}>
					<AddTaskDialog
						dataTest="task-details-dialog"
						isOpen={showNewTaskDialog}
						onClose={closeNewTaskDialog}
						onSubmit={onSubmitTask}
						// @TODO unsure this is suppposed to stay here, but let's see
						// specifically, i should not have this
						// when creating sa task inside of an order creation
						setStatusChanged={setStatusChanged}
					/>
				</APIProvider>
			)}
		</>
	);
};
