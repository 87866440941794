import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const UserRoleEnum = mkenum({
	CONVERSATION_GUEST: 'CONVERSATION_GUEST',
	CUSTOMER: 'CUSTOMER',
	HEYLOG_SUPER_USER: 'HEYLOG_SUPER_USER',
	WORKSPACE_ADMIN: 'WORKSPACE_ADMIN',
});

export type UserRoleType = TypeFromPEnum<typeof UserRoleEnum>;

export const SignupUserRoleEnum = mkenum({
	CONVERSATION_GUEST: 'CONVERSATION_GUEST',
	CUSTOMER: 'CUSTOMER',
	WORKSPACE_ADMIN: 'WORKSPACE_ADMIN',
});

export type SignupUserRoleType = TypeFromPEnum<typeof SignupUserRoleEnum>;

export const WorkspaceinvitationUserRoleEnum = mkenum({
	CUSTOMER: 'CUSTOMER',
	WORKSPACE_ADMIN: 'WORKSPACE_ADMIN',
});

export type WorkspaceInvitationUserRoleType = TypeFromPEnum<
	typeof WorkspaceinvitationUserRoleEnum
>;

export const ConversationInvitationUserRoleEnum = mkenum({
	CONVERSATION_GUEST: 'CONVERSATION_GUEST',
});

export type ConversationInvitationUserRoleType = TypeFromPEnum<
	typeof ConversationInvitationUserRoleEnum
>;
