import { StyledPageHeader } from './page-header.styles';
import { LanguageSwitch } from '../language-switch';
import { HeylogIconBlue } from '../../icons';

import type { FC } from 'react';

export const PageHeader: FC = () => {
	return (
		<StyledPageHeader>
			<HeylogIconBlue />
			<LanguageSwitch />
		</StyledPageHeader>
	);
};
