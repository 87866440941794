import { Component } from 'react';
import axios from 'axios';

import { ErrorSnackbar } from './error-snackbar';

import type { I18nExceptionResponseInterface } from '@heylog-app/shared/types';
import type { AxiosError } from 'axios';
import type { ErrorInfo, ReactNode } from 'react';

interface Props {
	children?: ReactNode;
}

interface State {
	hasError: boolean;
	message?: string;
	showSnackbar: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
	override state: State = {
		hasError: false,
		message: undefined,
		showSnackbar: false,
	};

	public static getDerivedStateFromError(
		error: Error | AxiosError<I18nExceptionResponseInterface>,
	): State {
		let message: string | undefined;

		if (axios.isAxiosError(error)) {
			message = (error.response?.data as I18nExceptionResponseInterface).i18nMessage;
		}

		return { hasError: true, message, showSnackbar: true };
	}

	public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('Uncaught error:', error, errorInfo);
	}

	public override render() {
		if (this.state.hasError) {
			return (
				<ErrorSnackbar
					open={this.state.showSnackbar}
					message={this.state.message}
					onClose={() => this.setState({ showSnackbar: false })}
				/>
			);
		}

		return this.props.children;
	}
}
