import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { FormWrapper } from '../components/form-wrapper/form-wrapper';
import { SignupImage } from '../components/ui/signup-image/signup-image';

import type { FC } from 'react';

export const SignupPage: FC = () => {
	return (
		<Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
			<Grid item xs={6}>
				<FormWrapper>
					<Outlet />
				</FormWrapper>
			</Grid>
			<Grid item xs={6}>
				<SignupImage />
			</Grid>
		</Grid>
	);
};
