import { StyledTextarea, StyledTextareaWrapper } from './message-input.styles';
import { TemplateInput } from '../template-input';
import { MessageLanguageSelect } from '../message-language-select';
import { MessageTextarea } from './message-textarea';

import type { SelectChangeEvent } from '@mui/material';
import type { FC, KeyboardEventHandler, SyntheticEvent } from 'react';
import type {
	SupportedLanguageResInterface,
	WATplParamTextInterface,
	WhatsAppMessageTemplateResInterface,
} from '@heylog-app/shared/types';

type TranslatedMessageTextareaType = {
	templateParameters?: WATplParamTextInterface[];
	setTemplateParameters: React.Dispatch<
		React.SetStateAction<WATplParamTextInterface[] | undefined>
	>;
	selectedTemplate?: WhatsAppMessageTemplateResInterface;
	message: string;
	onChange: (event: SyntheticEvent<HTMLTextAreaElement>) => void;
	onPressEnter: KeyboardEventHandler<Element>;
	isSending: boolean;
	conversationLanguage: string;
	handleConversationLanguageChange: (event: SelectChangeEvent<string>) => void;
	supportedLang?: SupportedLanguageResInterface;
	translatedTemplate?: WhatsAppMessageTemplateResInterface;
	translationTextBoxPlaceholderLabel: string;
	translatedMessage: string;
	contactLanguage: string;
	handleContactLanguageChange: (event: SelectChangeEvent<string>) => void;
	translatedTemplateParameters?: WATplParamTextInterface[];
	setEmojiPickerField: (
		target: EventTarget & (HTMLTextAreaElement | HTMLInputElement),
	) => void;
	focusedFieldIndex: number | null;
	setFocusedFieldIndex: (num: number) => void;
	templateCursorPosition: number | undefined;
};

export const TranslatedMessageTextarea: FC<TranslatedMessageTextareaType> = ({
	selectedTemplate,
	setTemplateParameters,
	message,
	onChange,
	onPressEnter,
	isSending,
	conversationLanguage,
	handleConversationLanguageChange,
	supportedLang,
	translatedTemplate,
	templateParameters,
	translatedTemplateParameters,
	translationTextBoxPlaceholderLabel,
	translatedMessage,
	contactLanguage,
	handleContactLanguageChange,
	setEmojiPickerField,
	focusedFieldIndex,
	setFocusedFieldIndex,
	templateCursorPosition,
}) => {
	return (
		<>
			<StyledTextareaWrapper>
				<MessageTextarea
					message={message}
					isSending={isSending}
					selectedTemplate={selectedTemplate}
					templateParameters={templateParameters}
					setTemplateParameters={setTemplateParameters}
					onChange={onChange}
					onPressEnter={onPressEnter}
					setEmojiPickerField={setEmojiPickerField}
					focusedFieldIndex={focusedFieldIndex}
					setFocusedFieldIndex={setFocusedFieldIndex}
					templateCursorPosition={templateCursorPosition}
				/>
				<MessageLanguageSelect
					dataTest="textarea-user-language-select"
					value={conversationLanguage}
					onChange={handleConversationLanguageChange}
					supportedLang={supportedLang?.sourceLanguages}
					selectedTemplate={selectedTemplate}
					isMessageTextarea
				/>
			</StyledTextareaWrapper>
			<StyledTextareaWrapper>
				{selectedTemplate && translatedTemplate ? (
					<TemplateInput
						template={translatedTemplate}
						templateParameters={templateParameters}
						dataTest="translated-template-input"
						translatedTemplateParameters={translatedTemplateParameters}
						onPressEnter={onPressEnter}
						setEmojiPickerField={setEmojiPickerField}
						focusedFieldIndex={focusedFieldIndex}
						setFocusedFieldIndex={setFocusedFieldIndex}
						templateCursorPosition={templateCursorPosition}
						readonly
					/>
				) : (
					<StyledTextarea
						placeholder={translationTextBoxPlaceholderLabel}
						value={translatedMessage}
						readOnly
						onKeyUp={onPressEnter}
						disabled={isSending}
						data-test="translated-text-field"
					/>
				)}
				<MessageLanguageSelect
					dataTest="textarea-contact-language-select"
					value={contactLanguage}
					onChange={handleContactLanguageChange}
					supportedLang={supportedLang?.targetLanguages}
					selectedTemplate={selectedTemplate}
					isMessageTextarea
				/>
			</StyledTextareaWrapper>
		</>
	);
};
