import {
	Button,
	CircularProgress,
	MenuItem,
	MobileStepper,
	Select,
	Stack,
} from '@mui/material';
import { Survey } from 'survey-react-ui';
import 'survey-core/survey.i18n';
import 'survey-core/defaultV2.min.css';
import { mdiClipboardCheckOutline } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { type FC, useEffect } from 'react';
import Icon from '@mdi/react';
import { useSearchParams } from 'react-router-dom';

import { ContactPageLayout, useAuthContext } from '@heylog-app/frontend-lib/app';

import {
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../vehicle-check/vehicle-check-page.styles';
import { tractorSurveyJson } from './tractor-survey';
import { trailerSurveyJson } from './trailer-survey';
import { themeJson } from './survey-theme';
import { useVchk } from '../utils/vchk-check.hook';

export const FlatTyreCheckPage: FC = () => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const { setAppToken } = useAuthContext();

	const bearerParam = searchParams.get('bearer');

	useEffect(() => {
		if (bearerParam) {
			setAppToken(bearerParam);
		}
	}, [bearerParam, setAppToken]);

	const {
		isRunning,
		isCompleted,
		isError,
		showIntro,
		activeStep,
		isSubmitting,
		choosenSurvey,
		handleBack,
		handleNext,
		handleComplete,
		handleStart,
		handleClose,
		handleRestart,
	} = useVchk({ tractorSurveyJson, themeJson, trailerSurveyJson, type: 'FLAT_TIRE' });

	return (
		<ContactPageLayout>
			<div>
				{showIntro && (
					<Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
						<StyledTitleWrapper>{t('vchk.chooseVehicleFTR')}</StyledTitleWrapper>
						<Select onChange={(e) => handleStart(e)} displayEmpty fullWidth>
							<MenuItem value={'Tractor'}>{t('vchk.tractor')}</MenuItem>
							<MenuItem value={'Trailer'}>{t('vchk.trailer')}</MenuItem>
						</Select>
					</Stack>
				)}

				{isSubmitting && (
					<Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
						<CircularProgress />
						<StyledTitleWrapper>{t('vchk.submitting')}</StyledTitleWrapper>
					</Stack>
				)}

				{isError && (
					<Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
						<StyledTitleWrapper>{t('vchk.error')}</StyledTitleWrapper>
						<Stack direction="row" gap={4}>
							<Button
								fullWidth={false}
								variant="contained"
								size="large"
								onClick={handleRestart}
							>
								{t('actionLabels.restart')}
							</Button>
							<Button
								fullWidth={false}
								variant="contained"
								size="large"
								onClick={handleClose}
							>
								{t('actionLabels.close')}
							</Button>
						</Stack>
					</Stack>
				)}

				{!showIntro && !isCompleted && !isSubmitting && !isError && (
					<Survey model={choosenSurvey} currentPageNo={activeStep} />
				)}

				{isRunning && !showIntro && !isError && (
					<MobileStepper
						variant="progress"
						steps={choosenSurvey.PageCount}
						position="static"
						activeStep={activeStep}
						sx={{ maxWidth: '100%', flexGrow: 1 }}
						backButton={
							<Button size="small" onClick={handleBack} disabled={activeStep === 0}>
								{t('actionLabels.back')}
							</Button>
						}
						nextButton={
							activeStep === choosenSurvey.PageCount - 1 ? (
								<Button size="small" onClick={handleComplete}>
									{t('actionLabels.complete')}
								</Button>
							) : (
								<Button size="small" onClick={handleNext}>
									{t('actionLabels.next')}
								</Button>
							)
						}
					/>
				)}

				{isCompleted && !isError && (
					<Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
						<Icon path={mdiClipboardCheckOutline} size={'4rem'} />
						<StyledTitleWrapper>{t('vchk.vchkCompletedTitle')}</StyledTitleWrapper>
						<StyledTextWrapper>{t('vchk.restartForm')}</StyledTextWrapper>
						<Stack direction="row" gap={4}>
							<Button
								fullWidth={false}
								variant="contained"
								size="large"
								onClick={handleRestart}
							>
								{t('actionLabels.restart')}
							</Button>
							<Button
								fullWidth={false}
								variant="contained"
								size="large"
								onClick={handleClose}
							>
								{t('actionLabels.close')}
							</Button>
						</Stack>
					</Stack>
				)}
			</div>
		</ContactPageLayout>
	);
};
