import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

import type { PickStyleProps } from '../../types';
import type { SidebarProps } from './sidebar';

type StyledSidebarProps = PickStyleProps<SidebarProps, 'variant' | 'width'>;

export const GRID_SIDEBAR_WIDTH = '200px';

export const StyledSidebar = styled(Grid)<StyledSidebarProps>`
	overflow: hidden;
	height: 100%;

	display: flex;
	flex-direction: column;

	${({ $variant, $width }) =>
		$variant === 'primary'
			? css`
					background-color: ${({ theme }) => theme.palette.grey[200]};
					border-right: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
					width: ${$width || '18.75rem'};
			  `
			: $variant === 'secondary'
			? css`
					background-color: ${({ theme }) => theme.palette.grey[200]};
					border-left: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
					width: ${$width || '17.5rem'};
			  `
			: css`
					border-right: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
					width: ${$width || '15rem'};
			  `}
`;

export const StyledGridSidebar = styled(Grid)`
	width: ${GRID_SIDEBAR_WIDTH};
`;
