import styled from 'styled-components';

import { colors, SIZE_LG, STYLE_3 } from '../../../styles';

export const StyledSidebarContent = styled.div`
	padding: 0 1rem;
	margin: 1rem 0;

	display: flex;
	flex-direction: column;
	gap: 1rem;

	color: ${colors.grey[700]};

	& + & {
		margin-top: 0;
	}
`;

export const StyledSidebarContentTitle = styled.h2`
	${STYLE_3};
	${SIZE_LG};
	margin: 0;
	color: ${colors.grey[900]};
`;
