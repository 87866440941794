import styled from 'styled-components';
import {
	Button,
	Chip,
	DialogContent,
	DialogTitle,
	ToggleButton,
	ToggleButtonGroup,
} from '@mui/material';

import { colors } from '../../styles';

export const DIALOG_HEIGHT = 700;
export const DIALOG_WIDTH = 630;

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
	position: relative;
	padding: 0 18px 18px 18px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

export const StyledToggleButton = styled(ToggleButton)`
	background-color: none;
	z-index: 1;

	&.Mui-selected {
		background-color: ${({ theme }) => theme.palette.primary.lighter};
	}
`;

export const StyledChip = styled(Chip)`
	position: absolute;
	top: -15px;
	right: 33%;
	z-index: 2;
`;

export const StyledInviteButton = styled(Button)`
	width: 6rem;
	height: 2.1rem;
`;

export const StyledWrapperDiv = styled.div`
	background-color: ${colors.common.white};
	-webkit-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	box-shadow: 9px 8px 49px -8px rgba(0, 0, 0, 0.75);
	position: absolute;
	height: ${DIALOG_HEIGHT}px;
	width: ${DIALOG_WIDTH}px;
	display: flex;
	flex-direction: column;
	border-radius: 4px;

	.react-resizable-handle-se {
		height: 15px !important; /* Example height, use the desired value */
		cursor: grab;
	}
`;

export const StyledEmailDialogTitle = styled(DialogTitle)<{ $isFocused: boolean }>`
	background-color: ${colors.common.white};
	padding-block: 1rem;
	margin-bottom: 1rem;
	position: sticky;
	top: 0;
	z-index: 20;
	padding: 0;
`;

export const StyledTabNavigation = styled.div`
	background-color: ${colors.common.white};
`;

export const StyledDivTitle = styled.div`
	padding: 1rem 2rem;
	border-bottom: 1px solid ${colors.border.main};
`;

export const StyledDialogContent = styled(DialogContent)`
	height: 100%;
`;
