import { useEffect, useRef } from 'react';

import type { DependencyList } from 'react';

/**
 * A wrapper around original useEffect to prevent effects from running on initial mount
 * @param callback
 * @param deps
 */
export const useUpdateEffectHook = (callback: () => void, deps?: DependencyList) => {
	const isInitialMount = useRef(true);

	// It helps with React StrictMode.
	// It reset the value when component mounts for the consecutive times which only happens in strict mode
	useEffect(() => {
		isInitialMount.current = true;
	}, []);

	useEffect(() => {
		if (isInitialMount.current) {
			isInitialMount.current = false;
		} else {
			callback();
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
};
