import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import { IsNextSevenDays, isThisWeek, isToday } from '@heylog-app/shared/util';
import { UserOrderStatusEnum } from '@heylog-app/shared/types';

import { useLocalStorage } from './use-local-storage';
import { useLabels } from './use-labels.hook';

import type { SelectChangeEvent } from '@mui/material';
import type { DashboardRow } from './use-dashboard.hook';

interface UseDashboardDataTableFiltersReturnType {
	FiltersComponent: JSX.Element;
	filteredRows: DashboardRow[];
	activeFiltersCount: number;
}

const offValue = 'Off';

export const useDashboardDataTableFilters = (
	rows: DashboardRow[],
	workspaceId: string,
): UseDashboardDataTableFiltersReturnType => {
	const { t } = useTranslation();

	// rows and filtering
	const [etaFilter, setEtaFilter] = useLocalStorage(
		'DashboardDataTable.etaFilter',
		offValue,
	);
	const [statusFilter, setStatusFilter] = useLocalStorage(
		'DashboardDataTable.statusFilter',
		offValue,
	);
	const [genericLabelFilter, setGenericLabelFilter] = useLocalStorage(
		'DashboardDataTable.genericLabelFilter',
		offValue,
	);
	const [priorityFilter, setPriorityFilter] = useLocalStorage(
		'DashboardDataTable.priorityFilter',
		offValue,
	);
	const [ownerFilter, setOwnerFilter] = useLocalStorage(
		'DashboardDataTable.ownerFilter',
		offValue,
	);

	const activeFiltersCount = [
		etaFilter,
		statusFilter,
		genericLabelFilter,
		priorityFilter,
		ownerFilter,
	].filter((value) => value !== offValue).length;

	const filterRows = useCallback(
		(rowsToFilter: DashboardRow[]) => {
			rowsToFilter = rowsToFilter.filter((row: DashboardRow) => {
				if (
					etaFilter === offValue ||
					(etaFilter === 'TODAY' && isToday(row.deliveryDate)) ||
					(etaFilter === 'THIS WEEK' && isThisWeek(row.deliveryDate)) ||
					(etaFilter === 'NEXT 7 DAYS' && IsNextSevenDays(row.deliveryDate))
				) {
					return true;
				}
				return false;
			});

			rowsToFilter = rowsToFilter.filter((row: DashboardRow) => {
				if (statusFilter === offValue || statusFilter === row.statusKey) {
					return true;
				}
				return false;
			});

			rowsToFilter = rowsToFilter.filter((row: DashboardRow) => {
				if (genericLabelFilter === offValue || row.labels.includes(genericLabelFilter)) {
					return true;
				}
				return false;
			});

			rowsToFilter = rowsToFilter.filter((row: DashboardRow) => {
				if (priorityFilter === offValue || priorityFilter === row.priority) {
					return true;
				}
				return false;
			});

			rowsToFilter = rowsToFilter.filter((row: DashboardRow) => {
				if (ownerFilter === offValue || row.owner.includes(ownerFilter)) {
					return true;
				}
				return false;
			});

			return rowsToFilter;
		},
		[etaFilter, priorityFilter, ownerFilter, genericLabelFilter, statusFilter],
	);

	// get workspace labels, for filtering

	const { labels } = useLabels(workspaceId);
	const genericLabels = labels?.filter((label) => label.type === 'GENERIC');
	const ownerLabels = labels?.filter((label) => label.type === 'OWNER');

	// select (filters) handlers
	const handleEtaFilter = useCallback(
		(event: SelectChangeEvent<string>) => {
			setEtaFilter(event.target.value);
		},
		[setEtaFilter],
	);

	const handleStatusFilter = useCallback(
		(event: SelectChangeEvent<string>) => {
			setStatusFilter(event.target.value);
		},
		[setStatusFilter],
	);

	const handleGenericLabelFilter = useCallback(
		(event: SelectChangeEvent<string>) => {
			setGenericLabelFilter(event.target.value);
		},
		[setGenericLabelFilter],
	);

	const handlePriorityFilter = useCallback(
		(event: SelectChangeEvent<string>) => {
			setPriorityFilter(event.target.value);
		},
		[setPriorityFilter],
	);

	const handleOwnerFilter = useCallback(
		(event: SelectChangeEvent<string>) => {
			setOwnerFilter(event.target.value);
		},
		[setOwnerFilter],
	);

	const filteredRows = filterRows(rows);
	const FiltersComponent = useMemo(
		() => (
			<Stack direction="column" gap="24px" padding="16px">
				<FormControl>
					<InputLabel id="demo-simple-select-label-eta">
						{t('dashboard.filters.eta')}
					</InputLabel>
					<Select
						labelId="demo-simple-select-label-eta"
						id="demo-simple-select-eta"
						value={etaFilter}
						label="Delivery ETA"
						onChange={handleEtaFilter}
						size="small"
					>
						<MenuItem value={offValue}>Off</MenuItem>
						<MenuItem value="TODAY">Today</MenuItem>
						<MenuItem value="THIS WEEK">This week</MenuItem>
						<MenuItem value="NEXT 7 DAYS">Next 7 days</MenuItem>
					</Select>
				</FormControl>
				{/* <FormControl sx={{ minWidth: '160px' }}>
                        <InputLabel id="demo-simple-select-label-chat">Chat</InputLabel>
                        <Select
                            labelId="demo-simple-select-label-chat"
                            id="demo-simple-select-chat"
                            value={'Yes'}
                            label="Chat"
                            onChange={handleChange}
                        >
                            <MenuItem value={true as never}>Yes</MenuItem>
                            <MenuItem value={false as never}>No</MenuItem>
                        </Select>
                    </FormControl> */}
				<FormControl>
					<InputLabel id="demo-simple-select-label-status">
						{t('dashboard.filters.status')}
					</InputLabel>
					<Select
						labelId="demo-simple-select-label-status"
						id="demo-simple-select-status"
						value={statusFilter}
						label="Status"
						size="small"
						onChange={handleStatusFilter}
					>
						<MenuItem value={offValue}>Off</MenuItem>
						{Object.values(UserOrderStatusEnum).map((status) => {
							return (
								<MenuItem key={status} value={status}>
									{t(`orders.details.status.${status.toLowerCase()}`)}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="demo-simple-select-label-generic">
						{t('dashboard.filters.labels')}
					</InputLabel>
					<Select
						labelId="demo-simple-select-label-generic"
						id="demo-simple-select-generic"
						value={genericLabelFilter}
						label="Label"
						size="small"
						onChange={handleGenericLabelFilter}
					>
						<MenuItem value={offValue}>Off</MenuItem>
						{genericLabels?.map((label) => {
							return (
								<MenuItem key={label.value} value={label.value}>
									{label.value}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="demo-simple-select-priority">
						{t('dashboard.filters.priority')}
					</InputLabel>
					<Select
						labelId="demo-simple-select-priority"
						id="demo-simple-select-priority"
						value={priorityFilter}
						label="Priority"
						size="small"
						onChange={handlePriorityFilter}
					>
						<MenuItem value={offValue}>Off</MenuItem>
						<MenuItem value="Urgent">Urgent</MenuItem>
					</Select>
				</FormControl>
				<FormControl>
					<InputLabel id="demo-simple-select-owner">
						{t('dashboard.filters.owner')}
					</InputLabel>
					<Select
						labelId="demo-simple-select-owner"
						id="demo-simple-select-owner"
						value={ownerFilter}
						label="Owner"
						size="small"
						onChange={handleOwnerFilter}
					>
						<MenuItem value={offValue}>Off</MenuItem>
						{ownerLabels?.map((label) => {
							return (
								<MenuItem key={label.value} value={label.value}>
									{label.value}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
				{/* <FormControl sx={{ minWidth: '160px' }}>
                        <InputLabel id="demo-simple-select-label-priority">Priority</InputLabel>
                        <Select
                            labelId="demo-simple-select-label-priority"
                            id="demo-simple-select-priority"
                            value={'Urgent'}
                            label="Chat"
                            onChange={handlePriorityFilter}
                        >
                            <MenuItem value={'urgent'}>Urgent</MenuItem>
                        </Select>
                    </FormControl> */}
			</Stack>
		),
		[
			etaFilter,
			genericLabelFilter,
			genericLabels,
			handleEtaFilter,
			handleGenericLabelFilter,
			handleOwnerFilter,
			handlePriorityFilter,
			handleStatusFilter,
			ownerFilter,
			ownerLabels,
			priorityFilter,
			statusFilter,
			t,
		],
	);

	return {
		FiltersComponent,
		filteredRows,
		activeFiltersCount,
	};
};
