import styled from 'styled-components';
import { Avatar } from '@mui/material';

import Image from '../../../../images/avatar-bg@2x.jpg';

export const StyledProfileWrapper = styled.div`
	width: 400px;
`;

export const StyledProfileInfo = styled.div`
	height: 40px;
	margin-left: 16px;
	font-family: Inter;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: -0.3px;
	color: var(--gray-900);
`;

export const StyledTeamInfo = styled.div`
	height: 20px;
	margin-left: 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: -0.2px;
	color: var(--gray-900);
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;
