import { Button, Chip, ToggleButton, ToggleButtonGroup } from '@mui/material';
import styled from 'styled-components';

import { colors, H2 } from '@heylog-app/frontend-lib/app';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
	position: relative;
	padding: 0 18px 10px 18px;
	border-bottom: 1px solid ${({ theme }) => theme.palette.border.main};
`;

export const StyledToggleButton = styled(ToggleButton)`
	background-color: none;
	z-index: 1;

	&.Mui-selected {
		background-color: ${({ theme }) => theme.palette.primary.lighter};
	}
`;

export const StyledChip = styled(Chip)`
	position: absolute;
	top: -15px;
	right: 33%;
	z-index: 2;
`;

export const StyledInviteButton = styled(Button)`
	width: 7rem;
	height: 2.1rem;
`;

export const StyledSidebarDescription = styled.div``;

export const StyledSidebarButton = styled(Button)`
	width: 100%;
`;

export const StyledGuestList = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledWelcomeContactsBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const StyledDetailsTitle = styled.h1`
	${H2};
	color: ${colors.grey[900]};
	white-space: nowrap;
	margin: 0;
`;
