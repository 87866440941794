import { Box } from '@mui/material';
import styled from 'styled-components';

import { T3_medium, colors } from '../../../styles';

export const StyledBoxWrapper = styled(Box)`
	display: flex;
	width: 100%;
`;

export const StyledBoxTab = styled(Box)<{ $isActive: boolean; $padding?: string }>`
	transition: 0.3s all ease;
	background-color: ${({ $isActive }) =>
		$isActive ? colors.primary.darkV2 : colors.grey[250]};
	color: ${({ $isActive }) => ($isActive ? colors.common.white : colors.primary.main)};
	padding: ${({ $padding }) => ($padding ? $padding : '9px 24px')};
	${T3_medium};
	cursor: pointer;
	border-radius: 4px;
	align-items: center;
	display: flex;
	flex: 1;
	justify-content: center;

	&:not(:first-of-type) {
		margin-left: -4px;
	}
`;
