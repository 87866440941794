import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const ConfigurableFeatureNameEnum = mkenum({
	OSU_REMINDERS_ENABLED: 'OSU_REMINDERS_ENABLED',
	PLUGINS: 'PLUGINS',
});

export type ConfigurableFeatureName = TypeFromPEnum<typeof ConfigurableFeatureNameEnum>;
