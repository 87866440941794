import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { type FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContactPageLayout, ROUTES } from '@heylog-app/frontend-lib/app';

import {
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../vehicle-check/vehicle-check-page.styles';

export const FlatTyreCheckStartPage: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleStart = () => {
		navigate(ROUTES.FLEET.FTR);
	};

	return (
		<ContactPageLayout>
			<div>
				<Stack justifyContent="center" alignItems="center" sx={{ textAlign: 'center' }}>
					<StyledTitleWrapper>{t('vchk.ftrStartPageTitle')}</StyledTitleWrapper>
					<StyledTextWrapper>{t('vchk.vchkStartPageDescription')}</StyledTextWrapper>
					<Button
						fullWidth={false}
						variant="contained"
						size="large"
						onClick={handleStart}
					>
						{t('actionLabels.startNow')}
					</Button>
				</Stack>
			</div>
		</ContactPageLayout>
	);
};
