import { useCallback, useMemo, useState } from 'react';
import { mdiInformationOutline } from '@mdi/js';
import { Dialog, DialogContent, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { MessageDirectionEnum, MessageTypeEnum } from '@heylog-app/shared/types';
import { getFullDateTime } from '@heylog-app/shared/util';

import { getMessageIconData } from './get-message-icon-data';
import { MessageAuthor, MessageBubble, MessageGroup, ModalClose } from '../ui';
import {
	StyledIcon,
	StyledIconButton,
	StyledIconWrapper,
	StyledTranslationButton,
} from './conversation.styles';
import { isBetweenDates } from '../../util/date-helpers';
import { sortByCreatedAtAsc } from '../../util';
import { useCurrentLanguage } from '../../hooks';

import type { FC } from 'react';
import type { MessageResInterface, Reaction } from '@heylog-app/shared/types';

export const Message: FC<{
	reactions?: MessageResInterface[];
	message: MessageResInterface;
	activeInterval?: (Date | null)[];
}> = ({ reactions, message, activeInterval }) => {
	const { path, color } = getMessageIconData(message);
	const [start, end] = activeInterval || [null, null];
	const [showInfoDialog, setShowInfoDialog] = useState(false);

	const { t } = useTranslation();
	const { currentLanguage } = useCurrentLanguage();
	const [showTranslated, setShowTranslated] = useState(
		message?.direction === MessageDirectionEnum.TO_CONTACT ? false : true,
	);

	const createdAtDate = getFullDateTime(message.createdAt, currentLanguage);

	const toggleMessageText = useCallback(
		() => setShowTranslated(!showTranslated),
		[showTranslated],
	);

	const isMessageInactive = useMemo(() => {
		if (activeInterval && start) {
			return !isBetweenDates(message.createdAt, start, end || new Date());
		}
		return false;
	}, [activeInterval, start, end, message.createdAt]);

	const lastReaction = reactions
		? reactions.sort(sortByCreatedAtAsc).slice(-1)
		: undefined;

	const handleInfoDialogClose = () => {
		setShowInfoDialog(false);
	};

	if (message.messageType === MessageTypeEnum.REACTION) {
		return null;
	}

	return (
		<>
			<MessageGroup message={message} inactive={isMessageInactive}>
				<MessageAuthor message={message} createdAtDate={createdAtDate} />

				<MessageBubble
					message={message}
					reaction={lastReaction ? (lastReaction[0]?.reaction as Reaction) : undefined}
					showTranslated={showTranslated}
				/>

				<StyledIconWrapper>
					{!!message?.translatedText && (
						<StyledTranslationButton
							data-test="message-translation-button"
							onClick={toggleMessageText}
						>
							{t(
								showTranslated
									? 'chats.message.translatedText'
									: 'chats.message.originalText',
							)}
						</StyledTranslationButton>
					)}

					<Tooltip
						disableFocusListener
						disableTouchListener
						disableHoverListener={message.status !== 'OUT_OF_WHATSAPP_24H'}
						title={t('chats.message.buffered')}
						placement="top"
						arrow
					>
						<StyledIcon path={path} size={'0.85em'} color={color} />
					</Tooltip>

					{message.direction === 'TO_CONTACT' && (
						<StyledIconButton
							data-test="message-info-button"
							onClick={() => setShowInfoDialog(true)}
						>
							<StyledIcon path={mdiInformationOutline} size={'0.85em'} />
						</StyledIconButton>
					)}
				</StyledIconWrapper>
			</MessageGroup>

			<Dialog
				data-test="message-info-dialog"
				open={showInfoDialog}
				onClose={handleInfoDialogClose}
				key={message.id}
			>
				<ModalClose closeModalFn={handleInfoDialogClose} />
				<DialogContent
					key={`${message.id}-${message.createdAt}-${message.receivedAt}--${message.seenAt}`}
				>
					<p data-test="message-transport-type-label">
						{t('chats.message.messageType') + ' '}
						{t(`join.transportTypes.${message.transportType}`)}
					</p>
					{message.createdAt && (
						<p data-test="message-sent-time-label">
							{t('chats.message.messageSent')}
							{` ${createdAtDate}`}
						</p>
					)}
					{message.receivedAt && (
						<p data-test="message-received-time-label">
							{t('chats.message.messageReceived')}
							{` ${getFullDateTime(message.receivedAt, currentLanguage)}`}
						</p>
					)}
					{message.seenAt && (
						<p data-test="message-seen-time-label">
							{t('chats.message.messageSeen')}
							{` ${getFullDateTime(message.seenAt, currentLanguage)}`}
						</p>
					)}
				</DialogContent>
			</Dialog>
		</>
	);
};
