import { Box, Button, Portal } from '@mui/material';
import Draggable from 'react-draggable';
import { useRef, type FC, memo, useState, default as React } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Resizable } from 'react-resizable';
import { useTranslation } from 'react-i18next';

import {
	DIALOG_HEIGHT,
	DIALOG_WIDTH,
	StyledDivWrapper,
	StyledEmailDialogTitle,
	StyledEmailTitleText,
} from './email-dialog.styles';
import { ModalClose } from '../ui';
import { NewEmail } from '../new-email/new-email';
import { EmailDisplay } from '../email-display/email-display';
import { TodoCheckbox } from '../todo-checkbox/todo-checkbox';
import { useConversationV2, useOrders } from '../../hooks';
import { ROUTES } from '../../util';
import { EmailDialogActionEnum } from '../../providers';

import type { ResizeCallbackData } from 'react-resizable';
import type { EmailDialogPayloadInterface } from '../../providers';

interface EmailDialogPropsInterface {
	onClose: (id: string) => void;
	onClick: (id: string) => void;
	zIndex: number;
	isFocused: boolean;
	id: string;
	emailData: EmailDialogPayloadInterface;
	emailAction: number;
	isShipmentPage: boolean;
	idx: number;
}

export const EmailDialog: FC<EmailDialogPropsInterface> = memo(
	({
		onClose,
		onClick,
		zIndex,
		id,
		emailData,
		emailAction,
		isShipmentPage,
		isFocused,
		idx,
	}) => {
		const { t } = useTranslation();
		const [subject, setSubject] = useState<null | string>(null);
		const [height, setHeight] = useState(DIALOG_HEIGHT);
		const [width, setWidth] = useState(DIALOG_WIDTH);
		const nodeRef = useRef<HTMLDivElement>(null);
		const navigate = useNavigate();
		const { orders } = useOrders();
		const { conversation } = useConversationV2(
			emailData?.conversationId || null,
			emailData?.workspaceId?.toString() || '',
		);

		const pageWidth = window.innerWidth;
		const elementId = `popup-heading-${id}`;

		const currentOrder = orders?.find(
			(order) => order.refNumber.toString() === conversation?.orderReference,
		);

		const orderInternalReference =
			currentOrder?.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
				?.value || conversation?.orderReference;

		const closeDialog = () => onClose(id);
		const onMouseDown = () => onClick(id);

		const handleNavigateToOrder = () =>
			navigate(
				generatePath(ROUTES.SHIPMENTS.DETAIL, {
					shipmentId: currentOrder?.id.toString(),
					workspaceId: currentOrder?.workspaceId?.toString(),
				}),
			);

		const loadableSubject = subject === null ? 'Loading...' : subject;
		const newEmailSubject = subject ? subject : t('email.newEmail');
		const isNewEmail = emailAction === EmailDialogActionEnum.NEW;
		const dialogTitle = isNewEmail ? newEmailSubject : loadableSubject;

		const xyAxisIndentPx = (idx + 1) * 16;

		const initialX = pageWidth - DIALOG_WIDTH;
		const preparedX = initialX - xyAxisIndentPx;

		const initialY = -DIALOG_HEIGHT;
		const preparedY = initialY - xyAxisIndentPx;

		const onResize = (event: React.SyntheticEvent, { size }: ResizeCallbackData) => {
			if (nodeRef.current) {
				const windowRef = nodeRef.current.getBoundingClientRect();
				const maxWidth = window.innerWidth - windowRef.left;
				const maxHeight = window.innerHeight - windowRef.top;

				size.height <= maxHeight && setHeight(size.height);
				size.width <= maxWidth && setWidth(size.width);
			}
		};

		return (
			<Portal>
				<Draggable
					nodeRef={nodeRef}
					defaultPosition={{ x: preparedX, y: preparedY }}
					onMouseDown={onMouseDown}
					bounds="parent"
					handle={`#${elementId}`}
				>
					<Resizable
						height={height}
						width={width}
						onResize={onResize}
						minConstraints={[400, 400]}
						maxConstraints={[1300, 1300]}
					>
						<StyledDivWrapper ref={nodeRef} style={{ zIndex, height, width }}>
							<StyledEmailDialogTitle
								id={elementId}
								$isFocused={isFocused}
								alignItems="center"
							>
								<StyledEmailTitleText>{dialogTitle}</StyledEmailTitleText>{' '}
								{currentOrder && (
									<Button
										variant="text"
										sx={{ padding: 0 }}
										onClick={handleNavigateToOrder}
									>
										{orderInternalReference}
									</Button>
								)}
								<ModalClose
									BeforeContent={
										!isNewEmail &&
										conversation && (
											<TodoCheckbox
												isShipmentPage={isShipmentPage}
												conversation={conversation}
												workspaceId={emailData.workspaceId || 0}
											/>
										)
									}
									closeModalFn={closeDialog}
								/>
							</StyledEmailDialogTitle>
							<Box padding={2} paddingBottom={0} overflow="auto" height="100%">
								{emailAction === EmailDialogActionEnum.OPEN ? (
									<EmailDisplay
										isShipmentPage={isShipmentPage}
										data={emailData}
										setSubject={setSubject}
										orderChipLabel={orderInternalReference}
										handleCloseDialog={closeDialog}
									/>
								) : (
									<NewEmail
										orderId={emailData.orderId}
										setSubject={setSubject}
										handleCloseDialog={closeDialog}
									/>
								)}
							</Box>
						</StyledDivWrapper>
					</Resizable>
				</Draggable>
			</Portal>
		);
	},
);
