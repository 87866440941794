import styled, { css } from 'styled-components';
import { Button, MenuItem } from '@mui/material';

import { SIZE_LG, SIZE_MD, STYLE_1, STYLE_3 } from '@heylog-app/frontend-lib/app';

export const StyledPageContainer = styled.div`
	max-width: 35rem;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	padding: 2rem 1rem;
`;

export const StyledTitleWrapper = styled.h1`
	${STYLE_3};
	${SIZE_LG};
	${SIZE_LG};
	line-height: 1.8rem;
	margin-bottom: 1rem;
`;

export const StyledTextWrapper = styled.div`
	${STYLE_1};
	${SIZE_MD};
	max-width: 420px;
	min-width: 300px;
	line-height: 1.4rem;
	margin-top: 2rem;
	margin-bottom: 2rem;
`;

export const StyledPageContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const StyledButtonContainer = styled.div`
	margin-top: 1rem;
	max-width: 420px;
	min-width: 300px;
	gap: 26px;
	align-self: center;
	display: flex;
	justify-content: space-between;
`;

export const StyledFieldContainer = styled.div`
	max-width: 420px;
	min-width: 300px;
`;

export const StyledButton = styled(Button)<{ $hasError?: boolean }>`
	background-color: ${({ theme }) => theme.palette.common.white};
	border: solid 1px ${({ theme }) => theme.palette.primary.main};
	color: ${({ theme }) => theme.palette.primary.main};
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${({ $hasError }) =>
		$hasError &&
		css`
			border: solid 1px ${({ theme }) => theme.palette.error.main};
		`}
`;

export const StyledMenuItem = styled(MenuItem)<{ $hasError?: boolean }>`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	& .MuiListItemIcon-root {
		width: 16px;
		min-width: 24px;
	}

	${({ $hasError }) =>
		$hasError &&
		css`
			border: solid 1px ${({ theme }) => theme.palette.error.main};
		`}
`;
