import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentLanguage } from '../hooks';

import type { FC, ReactNode } from 'react';

export const LanguageProvider: FC<{
	children?: ReactNode;
}> = ({ children }) => {
	const { i18n } = useTranslation();

	const { currentLanguage } = useCurrentLanguage();

	useEffect(() => {
		i18n.changeLanguage(currentLanguage);
	}, [i18n, currentLanguage]);

	return <div>{children}</div>;
};
