import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { mdiLogout, mdiSwapHorizontal } from '@mdi/js';

import {
	HeylogLogoBlack,
	ROUTES,
	NoWorkspaceSvg,
	useUser,
} from '@heylog-app/frontend-lib/app';

import {
	StyledButton,
	StyledPageContainer,
	StyledPageContent,
	StyledText,
	StyledTitle,
	StyledButtonIcon,
	StyledMenuItem,
	StyledMenu,
	StyledMenuIcon,
} from './no-workspace.styles';

import type { FC } from 'react';

export const NoWorkspace: FC = () => {
	const { t } = useTranslation();

	const { user } = useUser();

	const guestUserWorkspaceIds = user?.guestConversations?.map(
		(conversation) => conversation.workspaceId,
	);

	const navigate = useNavigate();

	const [switchWorkspaceOpen, setSwitchWorkspaceOpen] = useState(false);
	const switchWsButtonRef = useRef<HTMLButtonElement>(null);

	const handleSwitchWorkspaceClick = () => {
		setSwitchWorkspaceOpen(!switchWorkspaceOpen);
	};

	const closeSwitchMenu = useCallback(() => {
		setSwitchWorkspaceOpen(false);
	}, []);

	const handleWorkspaceClick = (id: number) => {
		navigate(
			generatePath(ROUTES.CONTACTS.OVERVIEW, {
				workspaceId: id.toString(),
			}),
		);
	};

	const handleLogout = useCallback(() => {
		navigate(ROUTES.LOGOUT);
	}, [navigate]);

	return (
		<StyledPageContainer data-test="no-workspace-component">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<NoWorkspaceSvg />
				</div>
				<StyledTitle>{t('workspaces.noWorkspace.title')}</StyledTitle>
				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('workspaces.noWorkspace.description'),
					}}
				/>
			</StyledPageContent>

			{!!guestUserWorkspaceIds?.length && guestUserWorkspaceIds.length > 1 && (
				<div>
					<StyledButton
						aria-controls="switch-workspace-page-disabled"
						data-test="switch-workspace-page-disabled"
						aria-haspopup="true"
						aria-expanded={switchWorkspaceOpen ? 'true' : undefined}
						ref={switchWsButtonRef}
						onClick={handleSwitchWorkspaceClick}
					>
						<StyledMenuIcon path={mdiSwapHorizontal} size={'1rem'} />
						{t('navigation.menu.changeTeam')}
					</StyledButton>

					<StyledMenu
						id="switch-workspace-page-disabled"
						open={switchWorkspaceOpen}
						onClose={closeSwitchMenu}
						anchorEl={switchWsButtonRef?.current}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						{guestUserWorkspaceIds?.map((workspaceId, index) => {
							return (
								<StyledMenuItem
									key={index}
									onClick={() => handleWorkspaceClick(workspaceId)}
								>
									{`Team ${workspaceId}`}
								</StyledMenuItem>
							);
						})}
					</StyledMenu>
				</div>
			)}

			<StyledButton
				variant="outlined"
				color="secondary"
				onClick={handleLogout}
				data-test="logout-button"
			>
				<StyledButtonIcon path={mdiLogout} size={'1rem'} />
				{t('navigation.menu.logout')}
			</StyledButton>
		</StyledPageContainer>
	);
};
