import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogTitle, Stack } from '@mui/material';
import Icon from '@mdi/react';
import { mdiAccount, mdiTruckDeliveryOutline } from '@mdi/js';
import { useParams } from 'react-router-dom';

import {
	type ContactOrderTaskStatus,
	OrderTaskStatusEnum,
	type OrderTaskStatusUpdateReqInterface,
	OrderTaskTypeEnum,
	ConversationTypeEnum,
} from '@heylog-app/shared/types';

import { getFormattedEta, getNameInitials, updateOrderTaskStatus } from '../../util';
import {
	StyledLabel,
	StyledHeader,
	StyledAvatar,
	StyledSectionSomeElements,
	StyledSection,
	StatusLabel,
	StyledContentWrapper,
	StyledSectionBody,
	StyledContactInfo,
	StyledContactInfoData,
	Dropdown,
	DropdownContainer,
	DropdownOption,
	StyledCompanyName,
} from './task-details-dialog.styles';
import { IconUntrack } from '../icons/icon-untrack';
import { ButtonV2, ModalClose } from '../ui';
import { StyledDialogActions } from '../dialog';
import {
	useApiClientContext,
	useDialog,
	useMessagesData,
	useSnackbar,
} from '../../hooks';
import { FlashSnackbar } from '../snackbars';
import { MessageContext } from '../../providers/message-provider';
import { MessageDialogTypesEnum } from '../../providers';
import { ConfirmArchiveDialog } from '../confirm-archive-dialog';

import type { FC, Dispatch, SetStateAction } from 'react';
import type {
	OrderTaskResInterface,
	ContactResInterface,
} from '@heylog-app/shared/types';
import type { UpdateOrderTaskParams } from '../../types';

type TaskDetailsDialogProps = {
	onClose: () => void;
	isOpen: boolean;
	dialogTitle: string;
	dataTest?: string;
	deleteTask: (id: number) => void;
	orderTask: OrderTaskResInterface;
	customer: string;
	contacts?: ContactResInterface[];
	setStatusChanged?: Dispatch<SetStateAction<{ status: string; taskId: string }>>;
};

export const TaskDetailsDialog: FC<TaskDetailsDialogProps> = ({
	contacts,
	customer,
	dataTest,
	deleteTask,
	dialogTitle,
	isOpen,
	onClose,
	orderTask,
	setStatusChanged,
}) => {
	const { t } = useTranslation();
	const { apiClient } = useApiClientContext();
	const { workspaceId = '', shipmentId = '' } = useParams();
	const [status, setStatus] = useState(orderTask.status.toUpperCase());
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const { rows } = useMessagesData([ConversationTypeEnum.WHATSAPP]);

	const { onOpenMessageDialog } = useContext(MessageContext);

	const data = rows.length > 0 ? rows[0] : null;
	const handleNavigateToNewMessage = () => {
		if (
			onOpenMessageDialog &&
			data &&
			data.conversationV1Id &&
			data.contactId &&
			shipmentId
		)
			onOpenMessageDialog({
				type: MessageDialogTypesEnum.CHAT_APP,
				payload: {
					workspaceId: Number(workspaceId),
					conversationV2Id: data.id,
					conversationId: Number(data.conversationV1Id),
					contactId: data.contactId,
					orderId: Number(shipmentId),
				},
			});

		// when we open the conversation dialog we close the task one since it doesn't belong to the same stack
		// it could be possible to keep both open, by sharing stacks, but it's a lot of work, overkill for now
		isOpen && onClose();
	};

	const handleLabelClick = () => {
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleOptionClick = (newStatus: ContactOrderTaskStatus) => {
		setStatus(newStatus);
		setIsDropdownOpen(false);

		if (newStatus !== status) {
			const params: UpdateOrderTaskParams<OrderTaskStatusUpdateReqInterface> = {
				data: { status: newStatus },
				orderId: shipmentId,
				taskId: orderTask.id.toString(),
				workspaceId,
			};

			updateOrderTaskStatus(apiClient, params)
				.then(() => {
					openSnackbar(
						'success',
						t('orders.tasks.tsuSuccess', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					if (setStatusChanged) {
						setStatusChanged({ status: newStatus, taskId: params.taskId });
					}
				})
				.catch((err) => {
					openSnackbar(
						'error',
						t('orders.tasks.tsuError', {
							orderTaskRef: orderTask.customerReference,
						}),
					);
					console.error(err);
				});
		}
	};

	const handleOnClose = () => {
		setIsDropdownOpen(false);
		onClose();
	};

	useEffect(() => {
		setStatus(orderTask.status);
	}, [orderTask]);

	const onArchive = () => {
		deleteTask(orderTask.id);
		closeConfirmArchiveDialog();
		onClose();
	};

	const {
		showDialog: showConfirmArchiveDialog,
		openDialog: openConfirmArchiveDialog,
		closeDialog: closeConfirmArchiveDialog,
	} = useDialog();

	return (
		<Dialog data-test={dataTest} open={isOpen} onClose={handleOnClose}>
			<ModalClose closeModalFn={handleOnClose} />
			<DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>

			<StyledDialogActions>
				<StyledContentWrapper>
					<Stack direction="row" justifyContent="space-between">
						<Stack>
							<StyledLabel>{t('orders.taskDetailsDialog.type')}</StyledLabel>
							<StyledHeader>
								{orderTask.type === OrderTaskTypeEnum.LOADING ? (
									<Icon path={mdiTruckDeliveryOutline} size={1} />
								) : (
									<IconUntrack />
								)}
								<Stack sx={{ marginLeft: '5px', marginTop: '3px' }}>
									{orderTask.type === OrderTaskTypeEnum.LOADING
										? t('orders.tasks.type.loading')
										: t('orders.tasks.type.unloading')}
								</Stack>
							</StyledHeader>
						</Stack>
						<Stack>
							<StyledLabel>{t('orders.taskDetailsDialog.status')}</StyledLabel>
							<DropdownContainer>
								{isDropdownOpen && (
									<Dropdown>
										<DropdownOption
											onClick={() => handleOptionClick(OrderTaskStatusEnum.COMPLETED)}
										>
											<StatusLabel completed={true} active={false}>
												{t('orders.tasks.status.done')}
											</StatusLabel>
										</DropdownOption>
										<DropdownOption
											onClick={() => handleOptionClick(OrderTaskStatusEnum.IN_PROGRESS)}
										>
											<StatusLabel completed={false} active={true}>
												{t('orders.tasks.status.in_progress')}
											</StatusLabel>
										</DropdownOption>
										<DropdownOption
											onClick={() => handleOptionClick(OrderTaskStatusEnum.CANCELLED)}
										>
											<StatusLabel completed={false} active={false} cancelled={true}>
												{t('orders.tasks.status.cancelled')}
											</StatusLabel>
										</DropdownOption>
										<DropdownOption
											onClick={() => handleOptionClick(OrderTaskStatusEnum.PENDING)}
										>
											<StatusLabel completed={false} active={false}>
												{t('orders.tasks.status.pending')}
											</StatusLabel>
										</DropdownOption>
									</Dropdown>
								)}
							</DropdownContainer>
							<StatusLabel
								onClick={handleLabelClick}
								completed={status === OrderTaskStatusEnum.COMPLETED}
								active={status === OrderTaskStatusEnum.IN_PROGRESS}
								cancelled={status === OrderTaskStatusEnum.CANCELLED}
							>
								{status === OrderTaskStatusEnum.COMPLETED
									? t('orders.tasks.status.done')
									: status === OrderTaskStatusEnum.IN_PROGRESS
									? t('orders.tasks.status.in_progress')
									: status === OrderTaskStatusEnum.CANCELLED
									? t('orders.tasks.status.cancelled')
									: t('orders.tasks.status.pending')}
							</StatusLabel>
						</Stack>
					</Stack>
					{orderTask.company && (
						<StyledSection>
							<Stack direction="column">
								<StyledLabel>{t('orders.taskDetailsDialog.company')}</StyledLabel>
								<StyledCompanyName>{orderTask.company}</StyledCompanyName>
							</Stack>
						</StyledSection>
					)}
					{orderTask.location && (
						<StyledSection>
							<StyledLabel>{t('orders.taskDetailsDialog.address')}</StyledLabel>
							{orderTask.location}
						</StyledSection>
					)}
					{orderTask.customerReference && (
						<StyledSection>
							<Stack direction="column">
								<StyledLabel>{t('orders.taskDetailsDialog.reference')}</StyledLabel>
								<Stack>{orderTask.customerReference}</Stack>
							</Stack>
						</StyledSection>
					)}
					<StyledSectionSomeElements>
						{orderTask.etaFrom && (
							<Stack>
								<StyledLabel>{t('orders.taskDetailsDialog.dateFrom')}</StyledLabel>
								{getFormattedEta(new Date(orderTask.etaFrom as Date))}
							</Stack>
						)}
						{orderTask.etaTo && (
							<Stack>
								<StyledLabel>{t('orders.taskDetailsDialog.dateTo')}</StyledLabel>
								{getFormattedEta(new Date(orderTask.etaTo as Date))}
							</Stack>
						)}
					</StyledSectionSomeElements>
					{orderTask.notes && (
						<StyledSection>
							<Stack direction="column">
								<StyledLabel>{t('orders.taskDetailsDialog.notes')}</StyledLabel>
								<Stack>
									{orderTask.notes.split('\n').map((line, index) => (
										<div key={index}>{line || <>&nbsp;</>}</div>
									))}
								</Stack>
							</Stack>
						</StyledSection>
					)}
					{customer && (
						<StyledSectionSomeElements>
							<Stack>
								<StyledLabel>{t('orders.taskDetailsDialog.customer')}</StyledLabel>
								{customer}
							</Stack>
						</StyledSectionSomeElements>
					)}
					{contacts && contacts[0] && (
						<StyledSection>
							<StyledLabel>{t('orders.taskDetailsDialog.contactPerson')}</StyledLabel>
							{contacts.map((contact, key) => (
								<StyledSectionBody key={key}>
									<StyledContactInfo>
										<StyledAvatar src="" sx={{ fontSize: '1rem' }}>
											{getNameInitials(contact.firstName, contact.lastName) || (
												<Icon path={mdiAccount} size={'1rem'} />
											)}
										</StyledAvatar>
										<StyledContactInfoData>
											{contact.firstName + '' + contact.lastName}
											<StyledLabel>{contact.phone}, whatsApp</StyledLabel>
										</StyledContactInfoData>
									</StyledContactInfo>
									<ButtonV2
										data-test="new-message-button"
										$variant="light"
										onClick={handleNavigateToNewMessage}
									>
										{t('orders.taskDetailsDialog.newMessage')}
									</ButtonV2>
								</StyledSectionBody>
							))}
						</StyledSection>
					)}

					<StyledSection>
						<ButtonV2
							data-test="delete-task-button"
							$variant="dark"
							onClick={openConfirmArchiveDialog}
							// onClick={deleteTask}
						>
							{t('orders.taskDetailsDialog.delete')}
						</ButtonV2>
					</StyledSection>
				</StyledContentWrapper>
			</StyledDialogActions>

			<ConfirmArchiveDialog
				dialogContent={t('orders.taskDetailsDialog.confirm_content')}
				dialogConfirmButtonText={t('orders.taskDetailsDialog.confirm_button')}
				dialogTitle={t('orders.taskDetailsDialog.confirm_title')}
				isOpen={showConfirmArchiveDialog}
				onArchive={onArchive}
				onClose={closeConfirmArchiveDialog}
			/>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</Dialog>
	);
};
