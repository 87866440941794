import styled from 'styled-components';

export const StyledSubtitle = styled.div`
	font-size: 20px;
	margin-top: 32px;
	margin-bottom: 16px;
`;

export const StyledMaintitle = styled.div`
	font-size: 38px;
	margin-top: 32px;
	margin-bottom: 16px;
	font-weight: 600;
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
`;

export const StyledFormWrapper = styled.div`
	max-width: 444px;
`;
