import { Grid } from '@mui/material';
import styled from 'styled-components';

import { colors, SIZE_LG, STYLE_2 } from '@heylog-app/frontend-lib/app';

export const OrderDocumentsPage = () => {
	return (
		<Grid
			container
			sx={{ height: '100%', overflow: 'hidden', backgroundColor: colors.greyblue[245] }}
			justifyContent="center"
			alignItems="center"
		>
			<Grid item>
				<StyledNotice>Feature in Arbeit</StyledNotice>
			</Grid>
		</Grid>
	);
};

// TODO: remove when this component is implemented
const StyledNotice = styled.div`
	${SIZE_LG};
	${STYLE_2};
`;
