import { Grid } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { SignupInImage } from '../../components/ui/sign-in-image';
import { FormWrapper } from '../../components/form-wrapper';

import type { FC } from 'react';

export const LoginPage: FC = () => {
	return (
		<Grid container sx={{ height: '100vh', overflow: 'hidden' }}>
			<Grid item xs={6}>
				<FormWrapper>
					<Outlet />
				</FormWrapper>
			</Grid>
			<Grid item xs={6}>
				<SignupInImage />
			</Grid>
		</Grid>
	);
};
