import { format, isSameDay } from 'date-fns';

export const ETA_DATE_FORMAT = 'dd.MM.yyyy';
export const ETA_TIME_FORMAT = 'kk:mm';
export const ETA_DATE_TIME_FORMAT = `${ETA_DATE_FORMAT} ${ETA_TIME_FORMAT}`;

export const getFormattedEta = (from?: string | Date, to?: string | Date) => {
	const etaFrom = from && new Date(from);
	const etaTo = to && new Date(to);

	if (etaFrom && etaTo) {
		if (isSameDay(etaFrom, etaTo)) {
			return `${format(etaFrom, ETA_DATE_FORMAT)} ${format(
				etaFrom,
				ETA_TIME_FORMAT,
			)} – ${format(etaTo, ETA_TIME_FORMAT)}`;
		} else {
			return `${format(etaFrom, ETA_DATE_TIME_FORMAT)} – ${format(
				etaTo,
				ETA_DATE_TIME_FORMAT,
			)}`;
		}
	} else {
		if (etaFrom) {
			return `${format(etaFrom, ETA_DATE_TIME_FORMAT)}`;
		} else if (etaTo) {
			return `${format(etaTo, ETA_DATE_TIME_FORMAT)}`;
		}
	}

	return undefined;
};
