import styled from 'styled-components';
import { Avatar, Grid } from '@mui/material';

import { SIZE_MD, SIZE_SM, STYLE_1, STYLE_2 } from '../../styles';
import Image from './images/avatar-bg@2x.jpg';

export const StyledContactTitleWrapper = styled(Grid)`
	display: flex;
	align-items: center;

	gap: 1rem;
`;

export const StyledContactTitle = styled.h2`
	${STYLE_2};
	${SIZE_MD}
`;

export const StyledTransportTypeWrapper = styled.div`
	${STYLE_2};
	${SIZE_MD};

	display: flex;
	align-items: center;
	gap: 0.5rem;

	svg {
		width: 1.5rem;
		height: auto;
	}
`;

export const StyledTransportTypeInformation = styled.div`
	${STYLE_1};
	${SIZE_SM};
`;

export const StyledAvatar = styled(Avatar)`
	background-image: url(${Image});
	background-size: cover;
`;
