import Image from '../../../assets/images/79b17e6f3dc07c017bc2d6ae2b01d317.jpeg';
import { StyledImageWrapper } from './signup-image.styles';

import type { FC } from 'react';

export const SignupImage: FC = () => {
	return (
		<StyledImageWrapper>
			<img src={Image} alt="" />
		</StyledImageWrapper>
	);
};
