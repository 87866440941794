import { getContactsKey } from './get-contacts-key';

import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	FullContactResInterface,
	I18nExceptionResponseInterface,
	SendSmsInvitationReqInterface,
	SmsInvitationResInterface,
	UpdateContactReqInterface,
} from '@heylog-app/shared/types';
import type { CreateEntryParams, ArchiveEntryParams, UpdateEntryParams } from '../types';

export const inviteContactAPI = async (
	apiClient: AxiosInstance,
	{ data, workspaceId }: CreateEntryParams<SendSmsInvitationReqInterface>,
): Promise<AxiosResponse<SmsInvitationResInterface, I18nExceptionResponseInterface>> => {
	return apiClient.post('workspaces/' + workspaceId + '/sms-invitations', data);
};

export const updateContactAPI = async (
	apiClient: AxiosInstance,
	{ id, data, workspaceId }: UpdateEntryParams<UpdateContactReqInterface>,
) => {
	const key = getContactsKey(workspaceId);
	return apiClient.patch<FullContactResInterface, UpdateContactReqInterface>(
		`${key}/${id}`,
		data,
	);
};

export const deleteContactAPI = async (
	apiClient: AxiosInstance,
	{ id, workspaceId }: ArchiveEntryParams,
) => {
	const key = getContactsKey(workspaceId);
	return apiClient.delete<FullContactResInterface, null>(`${key}/${id}`);
};
