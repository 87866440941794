import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

import { colors } from '../../../styles/theme/colors';
import { T2_medium, T3_medium } from '../../../styles/theme/typography-v2';

import type { ButtonV2Props } from '../buttonV2/buttonV2';

export const StyledButton = styled(Button)<ButtonV2Props>`
	padding: ${({ $padding }) => ($padding ? $padding : '12px 28px')};
	box-shadow: unset;
	${({ $typography }) => ($typography === 'T2' ? T2_medium : T3_medium)}
	min-width: fit-content;
	height: fit-content;
	position: relative;

	svg path {
		fill: currentcolor;
	}

	&:hover {
		box-shadow: unset;
	}

	${({ $variant }) => {
		if ($variant === 'dark')
			return css`
				background-color: ${colors.primary.main};
				color: ${colors.common.white};

				&:hover {
					background-color: ${colors.primary.dark};
				}
			`;
		if ($variant === 'light')
			return css`
				background-color: ${colors.grey[200]};
				color: ${colors.primary.main};

				&:hover {
					background-color: ${colors.primary.lighter};
				}
			`;
		if ($variant === 'warning')
			return css`
				background-color: ${colors.warning.light};
				color: ${colors.warning.dark};

				&:hover {
					background-color: ${colors.warning.light};
				}
			`;

		return null;
	}}
`;

export const StyledButtonsScope = styled.div`
	display: flex;
	flex-direction: row;
`;
