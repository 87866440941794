import type { InviteEntryParams } from '../types';
import type { AxiosInstance, AxiosResponse } from 'axios';
import type {
	ContactJoinWorkspaceReqInterface,
	ContactJoinWorkspaceResInterface,
} from '@heylog-app/shared/types';

export const joinWorkspaceAPI = async (
	apiClient: AxiosInstance,
	{ data }: InviteEntryParams<ContactJoinWorkspaceReqInterface>,
): Promise<AxiosResponse<ContactJoinWorkspaceResInterface>> => {
	return apiClient.post(`/workspaces/join`, data);
};
