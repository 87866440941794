import { useTranslation } from 'react-i18next';
import { Chip, Stack } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

// import { useOrders } from '@heylog-app/frontend-lib/app';
import { getOrderStatusColor } from '@heylog-app/shared/util';

import { useOrders } from './use-orders.hook';
import { colors } from '../styles';

import type { TFunction } from 'react-i18next';
import type { OrderResInterface } from '@heylog-app/shared/types';
import type { GridColDef } from '@mui/x-data-grid-pro';

export interface DashboardRow {
	attention?: string;
	client: string | null;
	collectionCity?: string;
	collectionDate: Date | null;
	collectionLocaleDate: string;
	collectionLocaleTime: string;
	collectionLocaleString: string;
	deliveryCity?: string;
	deliveryDate: Date | null;
	deliveryLocaleDate: string;
	deliveryLocaleTime: string;
	deliveryLocaleString: string;
	id: number;
	internalReference?: string;
	labels: string[];
	owner: string[];
	priority?: string;
	shipmentReference: string;
	statusKey: string;
	status: string;
	trailerPlateNumber: string | null;
	truckPlateNumber: string | null;
}

export const convertOrderToDashboardRow = (
	order: OrderResInterface,
	t: TFunction,
): DashboardRow => {
	const start = order.tasks && order.tasks?.length > 0 ? order.tasks[0] : undefined;
	const destination =
		order.tasks && order.tasks?.length > 1
			? order.tasks[order.tasks?.length - 1]
			: undefined;

	return {
		attention: order.labels.find((label) => label.type === 'ORDER_ATTENTION')?.value,
		// chat: hasChat ? t('yes') : t('no'),
		client: order.customer,
		collectionCity: start?.location || '',
		// the collection date is the first etaFrom or etaTo of the first task
		collectionDate: start?.etaFrom
			? new Date(start?.etaFrom)
			: start?.etaFrom
			? new Date(start?.etaFrom)
			: null,
		collectionLocaleDate: start?.etaFrom
			? new Date(start?.etaFrom).toLocaleDateString('eu')
			: '',
		collectionLocaleTime: start?.etaFrom
			? new Date(start?.etaFrom).toLocaleTimeString('eu')
			: '',
		collectionLocaleString: start?.etaFrom
			? new Date(start?.etaFrom).toLocaleString('eu')
			: '',
		deliveryCity: destination?.location || '',
		// the delivery date is the first etaFrom or etaTo of the last task
		deliveryDate: destination?.etaFrom
			? new Date(destination?.etaFrom)
			: destination?.etaTo
			? new Date(destination?.etaTo)
			: null,
		deliveryLocaleDate: destination?.etaFrom
			? new Date(destination?.etaFrom).toLocaleDateString('eu')
			: '',
		deliveryLocaleTime: destination?.etaFrom
			? new Date(destination?.etaFrom).toLocaleTimeString('eu')
			: '',
		deliveryLocaleString: destination?.etaFrom
			? new Date(destination?.etaFrom).toLocaleString('eu')
			: '',
		id: order.id,
		// the internal reference is the the first label of type ORDER_EXTERNAL_REFERENCE. There should be only one.
		internalReference: order.labels.find(
			(label) => label.type === 'ORDER_EXTERNAL_REFERENCE',
		)?.value,
		labels: order.labels
			.filter((label) => label.type === 'GENERIC')
			.map((label) => label.value),
		owner: order.labels
			.filter((label) => label.type === 'OWNER')
			.map((label) => label.value),
		// the priority is the the first label of type ORDER_PRIORITY. There should be only one.
		priority: order.labels.find((label) => label.type === 'ORDER_PRIORITY')?.value,
		shipmentReference: order.refNumber,
		statusKey: order.currentStatus,
		status: t(`orders.details.status.${order.currentStatus.toLowerCase()}`),
		trailerPlateNumber: order.licensePlateTrailer,
		truckPlateNumber: order.licensePlateTruck,
	};
};
export const useDashboardData = () => {
	const { t } = useTranslation();

	const columns: GridColDef[] = [
		{
			field: 'priority',
			headerName: t('dashboard.priority'),
			renderCell: (params) => {
				const value = params.value;
				if (!value) return null;
				return (
					<Chip
						sx={{
							bgcolor: colors.warning.light,
							fontSize: '10px',
						}}
						label={value}
						size="small"
					/>
				);
			},
		},
		{
			field: 'attention',
			headerName: t('dashboard.attention'),
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<div
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<WarningIcon sx={{ color: colors.error.dark }} fontSize="small" />
					</div>
				);
			},
		},
		{
			field: 'client',
			headerName: t(`dashboard.client`),
		},
		{
			field: 'internalReference',
			headerName: t(`dashboard.internalReference`),
		},
		{
			field: 'shipmentReference',
			headerName: t(`dashboard.shipmentReference`),
		},
		{
			field: 'collectionCity',
			headerName: t(`dashboard.collectionCity`),
		},
		{
			field: 'collectionLocaleString',
			headerName: t(`dashboard.collectionLocaleString`),
		},
		{
			field: 'deliveryCity',
			headerName: t(`dashboard.deliveryCity`),
		},
		{
			field: 'deliveryLocaleString',
			headerName: t(`dashboard.deliveryLocaleString`),
		},
		{
			field: 'status',
			headerName: t(`dashboard.status`),
			renderCell: (params) => {
				const value = params.value;
				return (
					<Chip
						sx={{
							bgcolor: `${getOrderStatusColor(params.row.statusKey)}`,
							fontSize: '10px',
						}}
						label={value}
						size="small"
					/>
				);
			},
		},
		{
			field: 'trailerPlateNumber',
			headerName: t(`dashboard.trailerPlateNumber`),
		},
		{
			field: 'owner',
			headerName: t(`dashboard.owner`),
			renderCell: (params) => {
				return params.value?.join(', ');
			},
		},
		{
			field: 'labels',
			headerName: t(`dashboard.labels`),
			renderCell: (params) => {
				if (!params.value) return null;
				return (
					<Stack direction="row" gap={1}>
						{params.value.map((team: string, index: number) => {
							return (
								<Chip
									sx={{
										bgcolor: '#198A1F',
										fontSize: '10px',
										padding: '2px',
										color: 'white',
									}}
									key={index}
									size="small"
									label={team}
								/>
							);
						})}
					</Stack>
				);
			},
		},
	];

	const { orders } = useOrders();
	// const { contacts } = useContacts({});

	const rows: DashboardRow[] =
		orders?.map((order) => {
			// const hasChat = contacts?.some((contact) =>
			// 	contact.orderConnections.some(
			// 		(connection) =>
			// 			connection.status !== 'ARCHIVED' && connection.orderId === order.id,
			// 	),
			// );

			return convertOrderToDashboardRow(order, t);
		}) || [];

	return { columns, rows };
};
