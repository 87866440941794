import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, TextField } from '@mui/material';
import { useSWRConfig } from 'swr';
import { Box } from '@mui/system';

import {
	ErrorMessage,
	ROUTES,
	useApiClientContext,
	useAuthContext,
	useErrorMessage,
	AlertMessage,
} from '@heylog-app/frontend-lib/app';

import { loginUserAPI } from '../../util';
import { LoginFormWrapper, StyledNavLink, StyledTitle } from './login-form.styles';

import type { FC } from 'react';
type FormData = {
	email: string;
	password: string;
};

export const LoginForm: FC = () => {
	const { t } = useTranslation();
	const { setAppToken, decoded } = useAuthContext();
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting },
	} = useForm<FormData>();
	const { apiClient } = useApiClientContext();

	const { cache } = useSWRConfig();
	const { setError, errorMessage } = useErrorMessage();
	const [passwordPolicyHint, setPasswordPolicyHint] = useState<boolean>(false);

	useEffect(() => {
		if (decoded) {
			navigate(ROUTES.DASHBOARD.REDIRECT);
		}
	}, [decoded, navigate]);

	const onSubmit = async (data: FormData) => {
		// @ts-expect-error The types are wrong, as the function exists at runtime
		cache?.clear?.();

		loginUserAPI(apiClient, { data: { ...data, email: data.email?.toLowerCase() } })
			.then(({ data }) => setAppToken(data))
			.catch((e) => {
				// TODO remove when transition to new password policy is complete
				const passwordPolicy =
					/((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).{8,128}$/;
				setPasswordPolicyHint(!passwordPolicy.test(data.password));

				setError(e);
			});
	};

	return (
		<LoginFormWrapper onSubmit={handleSubmit(onSubmit)}>
			<StyledTitle>{t('login.title')}</StyledTitle>
			<TextField
				label={t('login.labels.email')}
				{...register('email', {
					required: true,
				})}
				data-test="login-email-input"
				fullWidth
				margin="dense"
				type={'email'}
				error={!!errors.email}
				helperText={errors.email && t('forms.errors.required')}
			/>
			<TextField
				label={t('login.labels.password')}
				type={'password'}
				{...register('password', { required: true })}
				data-test="login-password-input"
				fullWidth
				margin="dense"
				error={!!errors.password}
				helperText={errors.password && t('forms.errors.required')}
			/>
			{errorMessage && <ErrorMessage errorMessage={errorMessage} />}

			{/*TODO remove when transition to new password policy is complete*/}
			{passwordPolicyHint && (
				<AlertMessage
					title={t('login.errors.passwordPolicyHintTitle')}
					message={t('login.errors.passwordPolicyHint')}
					action={
						<Button color="inherit" href={ROUTES.FORGOT_PASSWORD}>
							{t('login.errors.passwordPolicyAction')}
						</Button>
					}
				/>
			)}

			<Box mt={4}>
				<Button type="submit" variant="contained" fullWidth data-test="login-button">
					{isSubmitting ? t('actionLabels.loading') : t('login.labels.submit')}
				</Button>
			</Box>
			<StyledNavLink data-test="forgot-password-button" to={ROUTES.FORGOT_PASSWORD}>
				{t('login.labels.forgotPassword')}
			</StyledNavLink>
		</LoginFormWrapper>
	);
};
