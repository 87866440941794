import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { useApiClientContext } from './use-api-client-context.hook';
import { getFetcher, getUserInvitationsKey } from '../util';
import { AbilityContext } from '../providers';

import type { WorkspaceInvitationResInterface } from '@heylog-app/shared/types';

export const useUserInvitations = (workspaceId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, error, mutate, isLoading } = useSWR<
		Array<WorkspaceInvitationResInterface>
	>(
		ability.can(Actions.MANAGE, 'Workspace') ? getUserInvitationsKey(workspaceId) : null,
		getFetcher(apiClient),
	);

	return {
		userInvitations: data,
		fetchUserInvitations: mutate,
		isLoading,
		isError: error,
	};
};
