import { useCallback, useEffect } from 'react';
import useSWR, { mutate } from 'swr';
import { useNavigate, useParams } from 'react-router-dom';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { getFetcher, getConversationKey, getReadMessagesKey, ROUTES } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { useEnvContext } from './use-env-context';
import { AbilityContext } from '../providers';

import type { AxiosInstance } from 'axios';
import type {
	ConversationResInterface,
	ConversationStatusType,
} from '@heylog-app/shared/types';

export const useConversation = (workspaceId: string, conversationId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const navigate = useNavigate();

	const conversationKey = getConversationKey(workspaceId, conversationId);

	const { data: conversation, error: conversationError } =
		useSWR<ConversationResInterface>(
			ability.can(Actions.READ, 'Conversation') ? conversationKey : null,
			getFetcher(apiClient),
		);

	if (
		conversationError &&
		(conversationError.response?.status === 404 ||
			conversationError.response?.status === 403)
	) {
		navigate(ROUTES.NOT_FOUND);
	}

	const updateConversation = useCallback(
		({
			status,
			conversationLanguage,
			contactLanguage,
			isTranslationActive,
		}: {
			status?: ConversationStatusType;
			conversationLanguage?: string;
			contactLanguage?: string;
			isTranslationActive?: boolean;
		}) => {
			return mutate(conversationKey, async () => {
				const res = await apiClient.put(conversationKey, {
					status,
					conversationLanguage,
					contactLanguage,
					isTranslationActive,
				});

				return res.data;
			});
		},
		[apiClient, conversationKey],
	);

	return {
		conversation,
		conversationError,
		updateConversation,
	};
};

export const markConversationsAsReadApi = (
	apiClient: AxiosInstance,
	workspaceId: string,
	conversationId: string,
) => {
	return apiClient
		.put(getReadMessagesKey(workspaceId, conversationId), {})
		.catch(() => console.error('could not update read status'));
};

export const useSetConversationToRead = (conversationId: string) => {
	const { production } = useEnvContext();

	const { apiClient } = useApiClientContext();
	const { workspaceId } = useParams();

	useEffect(() => {
		if (workspaceId && conversationId && production)
			markConversationsAsReadApi(apiClient, workspaceId, conversationId);
	}, [workspaceId, conversationId, apiClient, production]);
};
