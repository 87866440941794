import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { mdiLogout } from '@mdi/js';

import { HeylogLogoBlack, ROUTES, NoConversationSvg } from '@heylog-app/frontend-lib/app';

import {
	StyledButton,
	StyledPageContainer,
	StyledPageContent,
	StyledText,
	StyledTitle,
	StyledButtonIcon,
} from './no-workspace.styles';

import type { FC } from 'react';

export const NoConversationGuest: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleLogout = useCallback(() => {
		navigate(ROUTES.LOGOUT);
	}, [navigate]);

	return (
		<StyledPageContainer data-test="no-conversation-error-page">
			<HeylogLogoBlack />

			<StyledPageContent>
				<div>
					<NoConversationSvg />
				</div>
				<StyledTitle>{t('workspaces.noConversation.title')}</StyledTitle>
				<StyledText
					dangerouslySetInnerHTML={{
						__html: t('workspaces.noConversation.description'),
					}}
				/>
			</StyledPageContent>

			<StyledButton
				variant="outlined"
				color="secondary"
				onClick={handleLogout}
				data-test="logout-button"
			>
				<StyledButtonIcon path={mdiLogout} size={'1rem'} />
				{t('navigation.menu.logout')}
			</StyledButton>
		</StyledPageContainer>
	);
};
