import Icon from '@mdi/react';
import { mdiAccountBoxMultipleOutline, mdiLogout } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { useState, useCallback, useEffect } from 'react';
import { Tabs } from '@mui/material';

import { WorkspaceinvitationUserRoleEnum } from '@heylog-app/shared/types';
import {
	ButtonV2,
	ButtonV2Dropdown,
	ROUTES,
	SidebarLayout,
	SidebarLayoutContent,
	usePusherBeamsContext,
	useUser,
	useWorkspace,
} from '@heylog-app/frontend-lib/app';

import {
	StyledButtonsBlock,
	StyledHeaderTitle,
	StyledHeaderWrapper,
	StyledSettingsHeader,
	StyledSettingsTitle,
	StyledTab,
} from './settings.styles';
import { ProfileInfo } from './components/profile-info';
import { ProfileSettingsPage } from './profile-settings';
import { AppsSettingsPage } from './apps-settings';
import { OrderStatusSettingsPage } from './order-status-settings';
import { WorkspaceSettingsPage } from './workspace-settings';
import { MembersSettingsPage } from './members-settings';

import type { FC } from 'react';

export const SettingsPage: FC = () => {
	const { beamsClient } = usePusherBeamsContext();
	const [menuValue, setMenuValue] = useState(0);
	const { t } = useTranslation();

	const { user } = useUser();
	const { workspaceId = '' } = useParams();
	const { workspace } = useWorkspace(workspaceId ? workspaceId : '-1', user);

	// const { FleetCheckPlugin, FleetCheckReportPlugin } = usePlugins(workspaceId);
	const [menuOptions, setMenuOptions] = useState<{ label: string; value: number }[]>([]);
	const navigate = useNavigate();

	const handleLogout = useCallback(() => {
		beamsClient?.stop().catch(console.error);
		localStorage.removeItem('pusherTransportTLS');
		localStorage.removeItem('pusherTransportNonTLS');
		navigate(ROUTES.LOGOUT);
	}, [navigate, beamsClient]);

	const handleWorkspaceClick = (id: number) => {
		navigate(
			generatePath(ROUTES.HOME, {
				workspaceId: id.toString(),
			}),
		);
	};

	const handleChange = (event: unknown, newValue: number) => {
		setMenuValue(newValue);
	};

	useEffect(() => {
		const options: { label: string; value: number }[] = [];
		user?.workspaces &&
			user.workspaces.map((workspace) => {
				return options.push({ label: workspace.name, value: workspace.id });
			});
		setMenuOptions(options);
	}, [user]);

	return (
		<SidebarLayout>
			<SidebarLayoutContent>
				<StyledHeaderWrapper>
					<StyledHeaderTitle>
						<StyledSettingsHeader>
							<StyledSettingsTitle>
								<ProfileInfo workspaceName={workspace?.name} height={80} width={80} />
							</StyledSettingsTitle>

							<StyledButtonsBlock>
								<ButtonV2Dropdown
									size="medium"
									$variant="light"
									type="button"
									handleFuncClick={handleWorkspaceClick}
									startIcon={<Icon path={mdiAccountBoxMultipleOutline} size={1} />}
									options={menuOptions}
								>
									{t('settings.labels.changeTeam')}
								</ButtonV2Dropdown>

								<ButtonV2
									size="medium"
									$variant="light"
									type="button"
									onClick={handleLogout}
									data-test="settings-user-save-button"
									startIcon={<Icon path={mdiLogout} size={1} />}
								>
									{t('settings.labels.logOut')}
								</ButtonV2>
							</StyledButtonsBlock>
						</StyledSettingsHeader>
						<Tabs value={menuValue} onChange={handleChange}>
							<StyledTab label={t('settings.labels.myProfile')} />
							<StyledTab label={t('settings.labels.myTeam')} />
							<StyledTab label={t('settings.labels.customers')} />
							<StyledTab label={t('settings.labels.orderStatus')} />
							<StyledTab label={t('settings.labels.apps')} />
						</Tabs>
					</StyledHeaderTitle>
				</StyledHeaderWrapper>

				{menuValue === 0 && <ProfileSettingsPage />}
				{menuValue === 1 && <WorkspaceSettingsPage />}
				{menuValue === 2 && (
					<MembersSettingsPage role={WorkspaceinvitationUserRoleEnum.CUSTOMER} />
				)}
				{menuValue === 3 && <OrderStatusSettingsPage />}
				{menuValue === 4 && <AppsSettingsPage />}
			</SidebarLayoutContent>
			{/*<SidebarLayoutContent>*/}
			{/*	<Outlet />*/}
			{/*</SidebarLayoutContent>*/}
		</SidebarLayout>
	);
};
