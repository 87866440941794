import styled from 'styled-components';
import Icon from '@mdi/react';

import { colors } from '../../styles';
import { T2_medium } from '../../styles/theme/typography-v2';

const borderColor = colors.grey[300];

export const StyledFilterSidebar = styled.div<{ isOpen: boolean }>`
	transition: 0.4s all ease;
	width: ${({ isOpen }) => (isOpen ? '340px' : '0px')};
	height: 100%;
	border-left: 1px ${borderColor} solid;
	overflow: hidden;
`;

export const StyledFilterHeader = styled.div`
	border-bottom: 1px ${borderColor} solid;
	${T2_medium};
	color: ${colors.grey[900]};
	padding: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledCloseIcon = styled(Icon)`
	cursor: pointer;
`;
