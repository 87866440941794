import type { FC } from 'react';

export const IconTr: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M48 88C70.0914 88 88 70.0914 88 48C88 25.9086 70.0914 8 48 8C25.9086 8 8 25.9086 8 48C8 70.0914 25.9086 88 48 88Z"
			fill="#FF3D00"
		/>
		<path
			d="M43.578 46.376L43.562 40.916L46.78 45.314L51.968 43.618L48.772 48.04L52 52.446L46.804 50.774L43.616 55.204L43.592 49.744L38.396 48.08L43.578 46.376Z"
			fill="white"
		/>
		<path
			d="M36.946 62.172C29.15 62.172 22.82 55.852 22.82 48.054C22.82 40.256 29.14 33.936 36.946 33.936C38.212 33.936 39.43 34.116 40.602 34.428C38.164 32.928 35.306 32.046 32.234 32.046C23.398 32.046 16.234 39.21 16.234 48.046C16.234 56.882 23.398 64.046 32.234 64.046C35.304 64.046 38.164 63.17 40.602 61.67C39.43 61.992 38.21 62.172 36.946 62.172Z"
			fill="white"
		/>
	</svg>
);
