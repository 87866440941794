import { Autocomplete, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrders } from '../../../hooks';
import { ButtonV2 } from '../../ui';

import type { TextFieldVariants } from '@mui/material';
import type { FC } from 'react';

interface OrderOption {
	id: number;
	label: string;
}

interface ChooseOrderProps {
	handleAssign: ({ orderId }: { orderId: string | null }) => void;
	width?: number | string;
	hasSubmit?: boolean;
	initialOrderId?: number;
	flexWrap?: 'wrap' | 'nowrap';
	className?: string;
	inputProps?: {
		variant?: TextFieldVariants;
		hasLabel?: boolean;
		startAdornment?: JSX.Element;
	};
}

export const ChooseOrder: FC<ChooseOrderProps> = ({
	handleAssign,
	className,
	width = 300,
	flexWrap = 'wrap',
	initialOrderId,
	hasSubmit = true,
	inputProps: { hasLabel = true, variant, startAdornment } = {},
}) => {
	const { t } = useTranslation();
	const [selectedOrder, setSelectedOrder] = useState<OrderOption | null>(null);
	const [inputValue, setInputValue] = useState('');

	const { orders } = useOrders();

	const localHandleAssign = () => {
		if (selectedOrder) handleAssign({ orderId: selectedOrder.id.toString() });
	};
	const options: OrderOption[] =
		orders?.map((order) => {
			return {
				id: order.id,
				label:
					order.labels.find((label) => label.type === 'ORDER_EXTERNAL_REFERENCE')
						?.value || order.refNumber,
			};
		}) || [];

	const matchedOrder = options.find((opt) => opt.id === initialOrderId);

	useEffect(() => {
		if (matchedOrder) setSelectedOrder(matchedOrder);
		// I don't need to depend on whole object, i need to check id only, in order to avoid unnecessary re-renders
		// eslint-disable-next-line
	}, [matchedOrder?.id]);

	return (
		<Stack direction="row" flexWrap={flexWrap} gap={2} className={className}>
			<Autocomplete
				// config
				disablePortal
				id="combo-box-demo"
				size="small"
				sx={{ width }}
				// state
				value={selectedOrder}
				onChange={(_, newValue: OrderOption | null) => {
					setSelectedOrder(newValue);
					if (!hasSubmit) handleAssign({ orderId: newValue?.id.toString() || null });
				}}
				inputValue={inputValue}
				onInputChange={(_, newInputValue) => {
					setInputValue(newInputValue);
				}}
				options={options}
				renderInput={(params) => (
					<TextField
						{...params}
						InputProps={{
							...params.InputProps,
							startAdornment,
						}}
						variant={variant}
						label={hasLabel && t('orders.chooseOrder')}
					/>
				)}
			/>
			{hasSubmit && (
				<ButtonV2
					$variant="dark"
					size="small"
					$padding="8px 16px"
					disabled={!selectedOrder}
					onClick={localHandleAssign}
				>
					{t('orders.linkOrder')}
				</ButtonV2>
			)}
		</Stack>
	);
};
