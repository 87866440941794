import { Grid } from '@mui/material';
import styled, { css } from 'styled-components';

type StyledContentContainerProps = {
	$variant: 'primary' | 'secondary' | 'conversations';
	$oarHeaderHeight?: number;
};

export const StyledContentContainer = styled(Grid)<StyledContentContainerProps>`
	display: flex;
	flex-direction: column;

	height: ${(props) =>
		props.$oarHeaderHeight ? `calc(100% - ${props.$oarHeaderHeight}px)` : '100%'};

	${({ $variant }) =>
		$variant === 'primary'
			? css`
					background-color: ${({ theme }) => theme.palette.grey[200]};
					border-right: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
			  `
			: $variant === 'secondary'
			? css`
					background-color: ${({ theme }) => theme.palette.grey[200]};
					border-left: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
			  `
			: css`
					border-right: 1px solid ${({ theme }) => theme.palette.greyblue[205]};
			  `}
`;
