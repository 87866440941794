// ability.ts
import { AbilityBuilder, PureAbility } from '@casl/ability';

import { Actions, UserRoleEnum } from '@heylog-app/shared/types';

import type { UserResInterface, AppAbility } from '@heylog-app/shared/types';

type DefineAbility = (workspaceId?: number, user?: UserResInterface) => AppAbility;

export const defineAbilitiesFor: DefineAbility = (workspaceId, user) => {
	const { can, build } = new AbilityBuilder<AppAbility>(PureAbility);

	if (user && workspaceId) {
		const userRole = user.role;
		const guestUserWorkspaceIds = user?.guestConversations?.map(
			(conversation) => conversation.workspaceId,
		);
		const isVisitor = guestUserWorkspaceIds?.includes(workspaceId);

		if (userRole === UserRoleEnum.CONVERSATION_GUEST || isVisitor) {
			can(Actions.READ, 'Conversation');
			can(Actions.READ, 'Workspace');
		} else if (userRole === UserRoleEnum.WORKSPACE_ADMIN) {
			can(Actions.MANAGE, 'all');
		}
	}

	return build();
};
