import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Button,
	Dialog,
	DialogTitle,
	FormControl,
	MenuItem,
	Stack,
	TextField,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
	OrderTaskTypeEnum,
	// OrderTaskStatusEnum,
	ContactOrderTaskStatusEnum,
} from '@heylog-app/shared/types';

import {
	StyledSectionSomeElements,
	StyledContentWrapper,
	StyledSection,
} from '../task-details-dialog/task-details-dialog.styles';
import { StyledDialogActions } from '../dialog';
import { useSnackbar } from '../../hooks';
import { FlashSnackbar } from '../snackbars';
import { ControlledSelect } from '../ui';
import { ETA_DATE_TIME_FORMAT } from '../../util';
import { AddressInput } from '../address-input';

import type { SelectChangeEvent } from '@mui/material';
import type { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import type {
	ContactOrderTaskStatus,
	OrderTaskType,
	CreateOrderTaskReqInterface,
} from '@heylog-app/shared/types';
import type { FC, Dispatch, SetStateAction } from 'react';

export type TaskFormData = {
	company: string;
	customerReference: string;
	etaFrom: string;
	etaTo: string;
	location: {
		name: string;
	};
	notes: string;
	status: ContactOrderTaskStatus;
	type: OrderTaskType;
};

type AddTaskDialogProps = {
	dataTest?: string;
	isOpen: boolean;
	onClose: () => void;
	onSubmit: (task: CreateOrderTaskReqInterface) => void;
	setStatusChanged?: Dispatch<SetStateAction<{ status: string; taskId: string }>>;
};

export const AddTaskDialog: FC<AddTaskDialogProps> = ({
	dataTest,
	isOpen,
	onClose,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();

	const {
		control,
		formState: { errors },
		handleSubmit,
		register,
		reset,
		setValue,
		watch,
	} = useForm<TaskFormData>({
		defaultValues: {
			customerReference: '',
			location: { name: '' },
			type: OrderTaskTypeEnum.LOADING,
			status: ContactOrderTaskStatusEnum.PENDING,
			etaFrom: '',
			etaTo: '',
			notes: '',
		},
	});

	const defaultTypeSelected = OrderTaskTypeEnum.LOADING;

	const [type, setType] = useState<OrderTaskType>(defaultTypeSelected);
	const [lng, setLng] = useState<number | undefined>(undefined);
	const [lat, setLat] = useState<number | undefined>(undefined);

	const handleTypeChange = useCallback(
		(event: SelectChangeEvent<unknown>) => {
			setType(event.target.value as OrderTaskType);
		},
		[setType],
	);
	const submitAndClose = (data: TaskFormData) => {
		onSubmit({
			longitude: lng,
			latitude: lat,
			...data,
			location: data.location.name,
			type,
		});
		reset();
		setType(defaultTypeSelected);
		onClose();
	};

	const etaFromDate = watch().etaFrom ? new Date(watch().etaFrom) : undefined;

	const onCancel = () => {
		reset();
		setType(defaultTypeSelected);
		onClose();
	};

	return (
		<Dialog data-test={dataTest} open={isOpen}>
			<DialogTitle id="alert-dialog-title">{t('orders.tasks.create')}</DialogTitle>

			<form onSubmit={handleSubmit(submitAndClose)} noValidate>
				<StyledDialogActions>
					<StyledContentWrapper>
						<StyledSection>
							<Stack direction="row" justifyContent="space-between">
								<Stack>
									<ControlledSelect
										margin="none"
										control={control as unknown as Control<FieldValues>}
										label={t('orders.taskDetailsDialog.type')}
										id="type"
										rules={{ required: true }}
										selectOptions={{
											error: !!errors.type,
											onChange: handleTypeChange,
											value: type,
										}}
										errorMessage={errors.type && t('forms.errors.required')}
										defaultValue={defaultTypeSelected}
									>
										{Object.values(OrderTaskTypeEnum).map((type) => (
											<MenuItem key={type} value={type}>
												{t(`orders.tasks.type.${type.toLowerCase()}`)}
											</MenuItem>
										))}
									</ControlledSelect>
								</Stack>
							</Stack>
						</StyledSection>
						<StyledSection>
							<Stack direction="column">
								<Stack>
									<Controller
										control={control}
										name="company"
										render={({ field }) => (
											<TextField
												data-test="task-company-input"
												label={t('orders.taskDetailsDialog.company')}
												fullWidth
												margin="dense"
												error={errors.company ? true : false}
												helperText={errors.company && t('forms.errors.required')}
												{...register('company', { required: false })}
												{...field}
											/>
										)}
									/>
								</Stack>
							</Stack>
						</StyledSection>
						<StyledSection>
							<Controller
								name="location.name"
								control={control}
								render={({ field }) => (
									<AddressInput
										addressName={field.value}
										dataTest="order-address-input"
										error={!!errors.location?.name}
										helperText={errors.location && t('forms.errors.required')}
										id="location"
										label="orders.taskDetailsDialog.address"
										setLat={setLat}
										setLng={setLng}
										setValue={setValue as unknown as UseFormSetValue<FieldValues>}
										{...register('location.name', { required: true })}
										{...field}
									/>
								)}
							/>
						</StyledSection>
						<StyledSection>
							<Stack direction="column">
								<Stack>
									<Controller
										control={control}
										name="customerReference"
										render={({ field }) => (
											<TextField
												data-test="task-customerReference-input"
												label={t('orders.taskDetailsDialog.reference')}
												fullWidth
												margin="dense"
												error={errors.customerReference ? true : false}
												helperText={
													errors.customerReference && t('forms.errors.required')
												}
												{...register('customerReference', { required: false })}
												{...field}
											/>
										)}
									/>
								</Stack>
							</Stack>
						</StyledSection>
						<StyledSectionSomeElements>
							<Stack>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Controller
										control={control}
										name="etaFrom"
										rules={{
											required: 'This field is required',
											validate: (value) =>
												!isNaN(new Date(value).getTime()) || 'Invalid date', // Custom validation rule
										}}
										render={({ field: { ...props }, fieldState: { error } }) => (
											<FormControl margin="dense">
												<DateTimePicker
													{...props}
													value={props.value || null}
													renderInput={(params) => (
														<TextField
															{...params}
															error={!!error}
															helperText={error ? error.message : null}
														/>
													)}
													inputFormat={ETA_DATE_TIME_FORMAT}
													label={t('orders.form.labels.etaFrom')}
													ampm={false}
													disablePast
												/>
											</FormControl>
										)}
									/>
									<Controller
										control={control}
										name="etaTo"
										rules={{
											validate: (value) =>
												!value || !isNaN(new Date(value).getTime()) || 'Invalid date', // Custom validation rule
										}}
										render={({ field: { ...props }, fieldState: { error } }) => (
											<FormControl margin="dense">
												<DateTimePicker
													{...props}
													value={props.value || null}
													renderInput={(params) => (
														<TextField
															{...params}
															error={!!error}
															helperText={error ? error.message : null}
														/>
													)}
													inputFormat={ETA_DATE_TIME_FORMAT}
													label={t('orders.form.labels.etaTo')}
													ampm={false}
													disabled={!etaFromDate}
													minDate={etaFromDate}
													minTime={etaFromDate}
												/>
											</FormControl>
										)}
									/>
								</LocalizationProvider>
							</Stack>
						</StyledSectionSomeElements>
						<StyledSection>
							<Stack direction="column">
								<Stack>
									<Controller
										control={control}
										name="notes"
										render={({ field }) => (
											<TextField
												data-test="task-notes-input"
												error={errors.notes ? true : false}
												fullWidth
												helperText={errors.notes && t('forms.errors.required')}
												label={t('orders.taskDetailsDialog.notes')}
												margin="dense"
												multiline
												rows={4}
												{...field}
												{...register('notes')}
											/>
										)}
									/>
								</Stack>
							</Stack>
						</StyledSection>
						<StyledSection>
							<StyledDialogActions>
								<Button variant="outlined" type="reset" onClick={onCancel}>
									{t('actionLabels.cancel')}
								</Button>
								<Button data-test="submit-task-button" variant="contained" type="submit">
									{t('orders.tasks.create')}
								</Button>
							</StyledDialogActions>
						</StyledSection>
					</StyledContentWrapper>
				</StyledDialogActions>
			</form>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</Dialog>
	);
};
