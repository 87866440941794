/** this file contains all types for requests to the whatsapp API for sending template messages
 * types for our own endpoint to send template messages are in ./send-message-req.interface.ts
 */

import type { WhatsAppTemplateNames } from '../../../enums';
import type { MessagingTransportType } from '../../../pseudo-enums';
import type { MessagePayloadType } from './message-payload.interface';

export interface WATplParamTextInterface {
	type: 'text';
	text: string;
}

export interface WATplParamPayloadInterface {
	type: 'payload';
	payload: string;
}

export type WATplParamInterface = WATplParamTextInterface | WATplParamPayloadInterface;

export enum WATplComponentTypeEnum {
	HEADER = 'HEADER',
	BODY = 'BODY',
	FOOTER = 'FOOTER',
	BUTTON = 'BUTTON',
}
export interface WATplGenericComponentInterface {
	type: WATplComponentTypeEnum;
	parameters: WATplParamInterface[];
	translatedParameters?: WATplParamInterface[];
}
export interface WATplButtonComponentInterface {
	type: WATplComponentTypeEnum.BUTTON;
	sub_type: string;
	index: string;
	parameters: WATplParamPayloadInterface[];
	translatedParameters?: WATplParamPayloadInterface[];
}

export type WATplComponentInterface =
	| WATplGenericComponentInterface
	| WATplButtonComponentInterface;

export interface WATplInterface {
	to: string;
	type: 'template';
	template: {
		name: string;
		language: {
			code: string;
			policy: string;
		};
		components: WATplComponentInterface[];
	};
	messaging_product: 'whatsapp';
}

export interface MessageReqInterface {
	messagePayload: MessagePayloadType;

	transportType: MessagingTransportType;

	template?: WhatsAppMessageTemplateResInterface;
	translatedTemplate?: WhatsAppMessageTemplateResInterface;
}

export interface WhatsAppMessageTemplateComponentResInterface {
	type: string;
	format?: string;
	text: string;
	buttons?: Array<{ text: string; type: string }>;
}

export interface WhatsAppMessageTemplateResInterface {
	name: WhatsAppTemplateNames;
	components: WhatsAppMessageTemplateComponentResInterface[];
	language: string;
	status: string;
	category: string;
	id: string;
}
