import { useTranslation } from 'react-i18next';

import { IconSelectAContact, OnboardingNotice } from '@heylog-app/frontend-lib/app';

import { StyledWelcomeOrdersBlock } from '../orders.styles';

import type { FC } from 'react';
import type { UserResInterface } from '@heylog-app/shared/types';

type SelectOrderWelcomeProps = {
	user: UserResInterface | undefined;
};

export const SelectOrderWelcome: FC<SelectOrderWelcomeProps> = ({ user }) => {
	const { t } = useTranslation();

	return (
		<StyledWelcomeOrdersBlock>
			<IconSelectAContact />
			<OnboardingNotice
				title={t('onboarding.title', {
					name: `${user?.firstName} ${user?.lastName}`,
				})}
				text={t('onboarding.orders.pleaseSelect')}
			/>
		</StyledWelcomeOrdersBlock>
	);
};
