import { DayUnitsInMiliseconds } from '@heylog-app/shared/types';

export const getIsMessageSentRecently = (
	messageDelta: number,
	messageDate: Date,
	now: Date,
): boolean => {
	return (
		(DayUnitsInMiliseconds.TwoDays < messageDelta &&
			messageDelta < DayUnitsInMiliseconds.SixDays) ||
		(messageDelta < DayUnitsInMiliseconds.TwoDays &&
			messageDate.getDay() !== now.getDay() - DayUnitsInMiliseconds.OneDay)
	);
};
