import { SidebarLayout } from '@heylog-app/frontend-lib/app';

import { StyledBackground } from './shipment.styles';
import { ShipmentDetails } from './components/shipment-details';

import type { FC } from 'react';

export const ShipmentPage: FC = () => {
	return (
		<SidebarLayout>
			<StyledBackground>
				<ShipmentDetails />
			</StyledBackground>
		</SidebarLayout>
	);
};
