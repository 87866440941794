import { useCallback, useState } from 'react';
import useSWR from 'swr';
import { useAbility } from '@casl/react';

import { Actions } from '@heylog-app/shared/types';

import { getFetcher, getTranslationKey } from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers';

import type {
	I18nExceptionResponseInterface,
	SupportedLanguageResInterface,
	TranslateMessageReqInterface,
	TranslateMessageResInterface,
	TranslationServiceType,
} from '@heylog-app/shared/types';
import type { AxiosResponse } from 'axios';

export const useMessageTranslation = ({
	translationService,
}: {
	translationService: TranslationServiceType;
}) => {
	const ability = useAbility(AbilityContext);

	const { apiClient } = useApiClientContext();
	const [loading, setLoading] = useState(false);
	const [latestTranslatedText, setLatestTranslatedText] = useState('');

	const translationKey = getTranslationKey();
	const supportedLangKey =
		translationKey + `/supported-languages?translationService=${translationService}`;

	const { data: supportedLang, error: supportedLangError } = useSWR(
		ability.can(Actions.MANAGE, 'Workspace') ? [supportedLangKey] : null,
		() => getFetcher(apiClient)<SupportedLanguageResInterface>(supportedLangKey),
		{
			revalidateOnFocus: false,
		},
	);

	const getMessageTranslation = useCallback(
		async (
			message: string,
			conversationLanguage: string,
			contactLanguage: string,
			translationService?: TranslationServiceType,
		) => {
			setLoading(true);

			return apiClient
				.post<
					TranslateMessageResInterface,
					AxiosResponse<TranslateMessageResInterface, I18nExceptionResponseInterface>,
					TranslateMessageReqInterface
				>(translationKey, {
					text: message,
					sourceLang: conversationLanguage,
					targetLang: contactLanguage,
					translationService: translationService || 'DEEPL',
				})
				.then((res) => res.data.text)
				.catch((err) => {
					throw new Error(err?.message);
				})
				.finally(() => {
					setLoading(false);
					setLatestTranslatedText(message);
				});
		},
		[apiClient, translationKey],
	);

	return {
		getMessageTranslation,
		supportedLang,
		supportedLangError,
		loading,
		latestTranslatedText,
	};
};
