export const getMessagesKey = (
	workspaceId: string,
	conversationId: string,
	messagesToFetch?: number,
	messagesDateFrom?: Date,
) => {
	const params = new URLSearchParams();
	if (messagesToFetch) {
		params.append('count', messagesToFetch.toString());
	}
	if (messagesDateFrom) {
		params.append('beforeDate', messagesDateFrom.toISOString());
	}
	return `/workspaces/${workspaceId}/conversations/${conversationId}/messages?${params.toString()}`;
};

export const getReadMessagesKey = (workspaceId: string, conversationId: string) => {
	return `/workspaces/${workspaceId}/conversations/${conversationId}/read`;
};
