import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Box, Dialog, DialogTitle, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	generatePath,
	Outlet,
	useNavigate,
	useOutletContext,
	useParams,
	useSearchParams,
} from 'react-router-dom';
import { Icon } from '@mdi/react';
import { mdiAccountPlusOutline } from '@mdi/js';

import {
	AddContactForm,
	ContactSkeleton,
	ModalClose,
	ROUTES,
	Sidebar,
	SidebarHeader,
	SidebarLayout,
	SidebarLayoutContent,
	SidebarList,
	SidebarSearch,
	useContacts,
	useDebounce,
	useDialog,
	useUnread,
	useUser,
	ContactListItem,
	useOrderActions,
	FlashSnackbar,
	useSnackbar,
	Can,
	usePlugins,
	ButtonV2,
	colors,
} from '@heylog-app/frontend-lib/app';
import {
	OrderConnectionStatusEnum,
	ContactStatusEnum,
	Actions,
} from '@heylog-app/shared/types';

import {
	StyledToggleButton,
	StyledToggleButtonGroup,
	StyledChip,
	StyledDetailsTitle,
} from './contacts.styles';
import { CreateContactWelcome } from './components/welcome-blocks/create-contact-welcome';
import { SelectContactWelcome } from './components/welcome-blocks/select-contact-welcome';
import { NoContactsGuest } from './components/welcome-blocks/no-contacts-guest';

import type { FC, ChangeEvent } from 'react';
import type {
	FullContactResInterface,
	OrderResInterface,
	ContactStatusType,
} from '@heylog-app/shared/types';

type ContactOrderContextType = {
	order: OrderResInterface | null;
	contact: FullContactResInterface | null;
	contactFilter: ContactStatusType | 'ACTIVE';
};

export const useContactContext = (): ContactOrderContextType => {
	return useOutletContext<ContactOrderContextType>();
};

export const ContactsPage: FC = () => {
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '', contactId, conversationId } = params;
	const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);
	const [contactFilter, setContactFilter] = useState<ContactStatusType>(
		ContactStatusEnum.ACTIVE,
	);

	const { TimeMattersOSUPlugin } = usePlugins(workspaceId);

	const navigate = useNavigate();
	const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 250);
	const [_, setSearchParams] = useSearchParams();
	const { unreadMap, isLoading: unreadLoading } = useUnread();
	const { user } = useUser();
	const {
		contacts,
		isLoading: contactsLoading,
		updateContacts,
	} = useContacts({ q: debouncedSearchTerm });

	const pendingContacts = contacts?.filter(
		(contact) =>
			contact.status === ContactStatusEnum.PENDING_ONBOARDING ||
			contact.status === ContactStatusEnum.PENDING_ONBOARDING_TO_ORDER,
	);

	const contactsToDisplay = useMemo(() => {
		if (!contacts) return [];

		switch (contactFilter) {
			case ContactStatusEnum.ACTIVE:
				return contacts.filter((contact) => contact.status === ContactStatusEnum.ACTIVE);

			case ContactStatusEnum.PENDING_ONBOARDING:
				return contacts.filter(
					(contact) =>
						contact.status === ContactStatusEnum.PENDING_ONBOARDING ||
						contact.status === ContactStatusEnum.PENDING_ONBOARDING_TO_ORDER,
				);

			case ContactStatusEnum.ARCHIVED:
				return contacts.filter(
					(contact) => contact.status === ContactStatusEnum.ARCHIVED,
				);

			default:
				return contacts;
		}
	}, [contactFilter, contacts]);

	const hasContacts = contacts?.length && contacts?.length > 0 ? true : false;

	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [isSearching, setIsSearching] = useState<boolean>(false);

	useEffect(() => {
		if (debouncedSearchTerm) {
			setSearchParams({ q: debouncedSearchTerm }, { replace: true });
		} else {
			setSearchParams({}, { replace: true });
		}
	}, [debouncedSearchTerm, setSearchParams]);

	const currentContact = useMemo(() => {
		if (!contactId) return null;
		return contacts?.find((contact) => contact.id === parseInt(contactId));
	}, [contacts, contactId]);

	const currentAssignment = useMemo(
		() =>
			currentContact?.orderConnections?.find((connection) =>
				connection.status !== OrderConnectionStatusEnum.ARCHIVED ? true : false,
			),
		[currentContact],
	);

	const handleFilterChange = (
		event: React.MouseEvent<HTMLElement>,
		value: ContactStatusType,
	) => {
		if (value !== null) setContactFilter(value);
	};

	const { order: currentOrder } = useOrderActions(
		currentAssignment?.orderId ? currentAssignment.orderId + '' : undefined,
	);

	const {
		showDialog: showCreateDialog,
		openDialog: openCreateDialog,
		closeDialog: closeCreateDialog,
	} = useDialog();

	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value);
			setIsSearching(true);
		},
		[setSearchTerm],
	);

	const handleSuccess = useCallback(() => {
		openSnackbar('success', t('contacts.form.smsSent'));
		updateContacts();
		closeCreateDialog();
	}, [closeCreateDialog, updateContacts, t, openSnackbar]);

	const onSelectContact = useCallback(
		(contact: FullContactResInterface) => {
			if (
				workspaceId &&
				conversationId &&
				contact.conversations[0]?.id.toString() === conversationId
			) {
				// in case we are already on the same conversation no need to navigate again
				return;
			}
			navigate(
				generatePath(ROUTES.CONTACTS.CHAT, {
					...params,
					contactId: contact?.id.toString(),
					conversationId: contact?.conversations[0]?.id.toString(),
				}),
			);
		},
		[workspaceId, conversationId, navigate, params],
	);

	return (
		<>
			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />

			<Box display="flex" flexDirection="column" height="100%">
				<SidebarLayout>
					<Box
						width="95%"
						sx={{
							marginTop: '30px',
							marginLeft: '30px',
							paddingBottom: '20px',
						}}
					>
						<Stack flexDirection="row" justifyContent="space-between">
							<StyledDetailsTitle>{t('contacts.title')}</StyledDetailsTitle>
							{!TimeMattersOSUPlugin && (
								<Can I={Actions.MANAGE} a="Workspace">
									{() => (
										<ButtonV2
											$variant="light"
											onClick={openCreateDialog}
											startIcon={<Icon path={mdiAccountPlusOutline} size={'1.5em'} />}
										>
											{t('contacts.inviteNewContact')}
										</ButtonV2>
									)}
								</Can>
							)}
						</Stack>
					</Box>
					<Sidebar
						variant="conversations"
						width="30%"
						marginLeft="25px"
						border="1px solid rgba(0, 0, 0, 0.12)"
					>
						<SidebarHeader title={'contacts.sidebar.title'}>
							<SidebarSearch
								onSearch={handleSearch}
								setIsSearching={setIsSearching}
								isStyled={true}
							/>
						</SidebarHeader>
						<StyledToggleButtonGroup
							color="primary"
							value={contactFilter}
							exclusive
							onChange={handleFilterChange}
							aria-label="Contact filter change"
							size="medium"
							fullWidth
						>
							<StyledToggleButton
								data-test="active-contacts-button"
								value={ContactStatusEnum.ACTIVE}
							>
								{t('contacts.sidebar.activeLabel')}
							</StyledToggleButton>
							{pendingContacts?.length && pendingContacts?.length > 0 && (
								<StyledChip
									color="error"
									label={pendingContacts?.length.toString()}
									size={'small'}
								/>
							)}
							<StyledToggleButton
								data-test="pending-onboarding-contacts-button"
								value={ContactStatusEnum.PENDING_ONBOARDING}
							>
								{t('contacts.sidebar.pendingLabel')}
							</StyledToggleButton>
							<StyledToggleButton
								data-test="archived-contacts-button"
								value={ContactStatusEnum.ARCHIVED}
							>
								{t('contacts.sidebar.archivedLabel')}
							</StyledToggleButton>
						</StyledToggleButtonGroup>
						<SidebarList>
							{contactsToDisplay?.length && !unreadLoading ? (
								contactsToDisplay?.map((contact) => (
									<ContactListItem
										data-test="contact-list-item"
										onSelectContact={onSelectContact}
										key={contact.id}
										contact={contact}
										unreadCount={unreadMap?.[contact.id] ?? 0}
									/>
								))
							) : unreadLoading ? (
								<ContactSkeleton animated={contactsLoading} />
							) : null}
						</SidebarList>
					</Sidebar>

					<Box
						sx={{
							borderTop: `1px solid ${colors.border.main}`,
							width: '66%',
							height: '72vh',
							overflowY: 'hidden',
						}}
					>
						<SidebarLayoutContent variant="primary">
							{!hasContacts || isSearching ? (
								<>
									<Can not I={Actions.MANAGE} a="Workspace">
										{() => <NoContactsGuest user={user} />}
									</Can>
									<Can I={Actions.MANAGE} a="Workspace">
										{() => (
											<CreateContactWelcome user={user} onClick={openCreateDialog} />
										)}
									</Can>
								</>
							) : !contactId ? (
								<SelectContactWelcome user={user} />
							) : (
								<Outlet
									context={{
										order: currentOrder ? currentOrder : null,
										contact: currentContact && !isSearching ? currentContact : null,
										contactFilter: contactFilter,
									}}
								/>
							)}
						</SidebarLayoutContent>
					</Box>
				</SidebarLayout>
			</Box>
			<Dialog open={showCreateDialog} onClose={closeCreateDialog} fullWidth maxWidth="md">
				<ModalClose closeModalFn={closeCreateDialog} />
				<DialogTitle>{t('contacts.form.createContactTitle')}</DialogTitle>
				<AddContactForm onSuccess={handleSuccess} onCancel={closeCreateDialog} />
			</Dialog>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</>
	);
};
