import { useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { MobileInfo } from '../mobile-info';
import { mediaQueries } from '../../styles';

export const MobileRestrictedRoute = () => {
	const isMobile = useMediaQuery(mediaQueries.md);

	if (isMobile) {
		return <MobileInfo />;
	}
	return <Outlet />;
};
