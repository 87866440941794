import { mkenum } from './mkenum';

export const FileTypeEnum = mkenum({
	DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
	XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
	PDF: 'application/pdf',
	TXT: 'text/plain',
	RTF: 'application/rtf',
	CSV: 'text/csv',
	XML: 'application/xml',
	HTML: 'text/html',
	JPEG: 'image/jpeg',
	JPG: 'image/jpeg',
	PNG: 'image/png',
	GIF: 'image/gif',
	BMP: 'image/bmp',
	TIFF: 'image/tiff',
	SVG: 'image/svg+xml',
	WEBP: 'image/webp',
});

export type FileType = typeof FileTypeEnum[keyof typeof FileTypeEnum];
