import styled, { css } from 'styled-components';

import { MessageDirectionEnum } from '@heylog-app/shared/types';

import type { MessageResInterface } from '@heylog-app/shared/types';

type StyledMessageGroupProps = {
	$direction: MessageResInterface['direction'];
	$inactive?: boolean;
};

export const StyledMessageGroupWrapper = styled.div<StyledMessageGroupProps>`
	display: flex;

	justify-content: ${({ $direction }) =>
		$direction === MessageDirectionEnum.TO_CONTACT ? 'flex-end' : 'flex-start'};

	${({ $inactive }) =>
		$inactive &&
		css`
			opacity: 0.5;
		`}
`;

export const StyledMessageGroup = styled.div<StyledMessageGroupProps>`
	display: flex;
	max-width: 25rem;
	flex-direction: column;
	align-items: ${({ $direction }) =>
		$direction === MessageDirectionEnum.TO_CONTACT ? 'flex-end' : 'flex-start'};
`;
