import { Button } from '@mui/material';
import styled from 'styled-components';

export const StyledWelcomeOrdersBlock = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

export const StyledCreateButton = styled(Button)`
	width: 6rem;
	height: 2.1rem;
`;

export const OrderDetailsTabpanel = styled.div`
	overflow: scroll;
`;
