export const IconSMS = () => (
	<svg
		width="32"
		height="32"
		viewBox="0 0 32 32"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.8333 14.8333H19.5V12.5H21.8333M17.1667 14.8333H14.8333V12.5H17.1667M12.5 14.8333H10.1667V12.5H12.5M25.3333 4.33333H6.66666C6.04782 4.33333 5.45433 4.57916 5.01675 5.01675C4.57916 5.45433 4.33333 6.04782 4.33333 6.66666V27.6667L9 23H25.3333C25.9522 23 26.5457 22.7542 26.9832 22.3166C27.4208 21.879 27.6667 21.2855 27.6667 20.6667V6.66666C27.6667 5.37166 26.6167 4.33333 25.3333 4.33333Z"
			fill="#25D366"
		/>
	</svg>
);
