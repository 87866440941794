import { Button, Chip, Menu, MenuItem } from '@mui/material';
import styled from 'styled-components';
import { Icon } from '@mdi/react';

import { colors, SIZE_MD, STYLE_2 } from '@heylog-app/frontend-lib/app';

export const StyledPageContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	margin: 32px auto 0 auto;
	max-width: 720px;
`;

export const StyledPageContent = styled.div`
	margin: 72px auto 24px auto;
	padding: 56px;
	background-color: ${colors.greyblue[245]};
	border-radius: 2px;
	text-align: center;
`;

export const StyledIconWrapper = styled.div`
	svg {
		width: 100%;
		height: auto;
	}
`;

export const StyledTitle = styled.h1`
	margin: 16px auto 0 auto;
	max-width: 320px;
	font-size: 32px;
	line-height: 40px;
	font-weight: 700;
	color: ${colors.common.black};
`;

export const StyledText = styled.p`
	margin: 24px auto 0 auto;
	max-width: 505px;
	line-height: 24px;
	font-size: 15px;
`;

export const StyledButtonsContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledButton = styled(Button)`
	margin-bottom: 20px;
	color: ${colors.primary.main};
	border: 1px solid ${colors.primary.main};

	&:not(:last-child) {
		margin-right: 20px;
	}
`;

export const StyledMenu = styled(Menu)`
	.MuiList-root {
		border: 1px solid ${colors.primary.main};
		padding: 0;
	}
`;

export const StyledMenuItem = styled(MenuItem)`
	${STYLE_2};
	${SIZE_MD};
	margin: 0;
	color: black;
	border-bottom: 1px solid ${colors.primary.main};

	&:last-of-type {
		border-bottom: none;
	}
`;

export const StyledMenuIcon = styled(Icon)`
	margin-right: 10px;
`;

export const StyledChip = styled(Chip)`
	margin-left: 10px;
`;

export const StyledButtonIcon = styled(Icon)`
	margin-right: 10px;
`;
