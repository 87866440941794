import { Button, ListItemIcon, Menu } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
	ContactPageLayout,
	FlashSnackbar,
	getFormattedEta,
	getOrderTask,
	getOrderTaskStatus,
	updateOrderTaskStatus,
	useApiClientContext,
	useAuthContext,
	useMenu,
	useSnackbar,
} from '@heylog-app/frontend-lib/app';
import { ContactOrderTaskStatusEnum } from '@heylog-app/shared/types';

import {
	StyledButtonContainer,
	StyledMenuItem,
	StyledPageContent,
	StyledTextWrapper,
	StyledTitleWrapper,
} from '../../styles/order-status.styles';
import { StyledButton } from '../../components/ui/order-status-menu/order-status-menu.styles';
import { OrderStatusIcon } from '../../components/ui/order-status-icon';

import type { UpdateOrderTaskParams } from '@heylog-app/frontend-lib/app';
import type {
	OrderTaskStatus,
	OrderTaskStatusUpdateReqInterface,
	ContactOrderTaskStatus,
	OrderTaskResInterface,
} from '@heylog-app/shared/types';
import type { FC } from 'react';

export const TaskStatusUpdatePage: FC = () => {
	const { apiClient } = useApiClientContext();
	const {
		workspaceId = '',
		orderId = '',
		whatsappTransportNumber = '',
		taskId = '',
	} = useParams();
	const [searchParams] = useSearchParams();
	const { setAppToken } = useAuthContext();

	const bearerParam = searchParams.get('bearer');

	useEffect(() => {
		if (bearerParam) {
			setAppToken(bearerParam);
		}
	}, [bearerParam, setAppToken]);

	const [orderTaskStatus, setOrderTaskStatus] = useState<OrderTaskStatus>();
	const [orderTask, setOrderTask] = useState<OrderTaskResInterface>();
	const [orderTaskRef, setOrderTaskRef] = useState<string>();
	const { isMenuOpen, toggleButtonRef, toggleMenu, closeMenu } = useMenu();
	const { t } = useTranslation();
	const [stateSnackbar, openSnackbar, closeSnackbar] = useSnackbar();
	const [isSubmitting, setIsSubmitting] = useState(false);

	useEffect(() => {
		getOrderTaskStatus(apiClient, { workspaceId, orderId, taskId })
			.then((res) => {
				setOrderTaskStatus(res.data.currentStatus);
				setOrderTaskRef(res.data.customerReference);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [apiClient, orderId, taskId, workspaceId]);

	useEffect(() => {
		getOrderTask(apiClient, { workspaceId, orderId, taskId })
			.then((res) => {
				setOrderTask(res.data);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [apiClient, orderId, taskId, workspaceId]);

	const handleSubmitClick = () => {
		handleSubmitComplete();
	};

	const handleBackClick = () => {
		window.location.href = `https://wa.me/${whatsappTransportNumber}`;
	};

	const handleSubmitComplete = () => {
		setIsSubmitting(true);

		const params: UpdateOrderTaskParams<OrderTaskStatusUpdateReqInterface> = {
			data: { status: orderTaskStatus as ContactOrderTaskStatus },
			orderId,
			taskId,
			workspaceId,
		};

		updateOrderTaskStatus(apiClient, params)
			.then(() => {
				openSnackbar('success', t('orders.tasks.tsuSuccess', { orderTaskRef }));
				setTimeout(() => {
					window.location.href = `https://wa.me/${whatsappTransportNumber}`;
				}, 2000);
			})
			.catch((err) => {
				openSnackbar('error', t('orders.tasks.tsuError', { orderTaskRef }));
				console.error(err);
			})
			.finally(() => {
				setIsSubmitting(false);
			});
	};

	const handleStatusChange = (status: OrderTaskStatus) => {
		setOrderTaskStatus(status);
		closeMenu();
	};

	const statusLabel = useMemo(() => {
		return t(`orders.tasks.status.${orderTaskStatus?.toLowerCase()}`);
	}, [orderTaskStatus, t]);

	return (
		<ContactPageLayout>
			<StyledPageContent>
				{/* <StyledTitleWrapper>
				</StyledTitleWrapper> */}
				<StyledTextWrapper>
					<StyledTitleWrapper>
						<span role="img" aria-label="order-emoji">
							📍
						</span>
						{t('taskStatusUpdate.task')}: {orderTaskRef}
						{/* {t('orderStatusUpdate.order')}: {orderRef} */}
					</StyledTitleWrapper>
					<StyledButton
						data-test="order-status-menu-button"
						aria-controls={'order-status-menu'}
						aria-haspopup="true"
						aria-expanded={isMenuOpen ? 'true' : undefined}
						ref={toggleButtonRef}
						onClick={toggleMenu}
						variant="outlined"
						startIcon={orderTaskStatus && <OrderStatusIcon status={orderTaskStatus} />}
						endIcon={<KeyboardArrowDownIcon />}
						fullWidth
					>
						{statusLabel || t('orders.tasks.status.none')}
					</StyledButton>
					<Menu
						id="order-status-menu"
						open={isMenuOpen}
						anchorEl={toggleButtonRef?.current}
						onClose={closeMenu}
						sx={{
							'& .MuiPaper-root': {
								width: (toggleButtonRef.current?.clientWidth || 0) + 2,
							},
						}}
					>
						{Object.values(ContactOrderTaskStatusEnum).map((status) => {
							return (
								<StyledMenuItem
									key={status}
									onClick={() => handleStatusChange(status)}
									data-test={`order-status-${status}`}
									sx={{ fontSize: '0.875rem' }}
								>
									<ListItemIcon>
										<OrderStatusIcon status={status} />
									</ListItemIcon>
									{t(`orders.tasks.status.${status.toLowerCase()}`)}
								</StyledMenuItem>
							);
						})}
					</Menu>
					<StyledTextWrapper>
						<p>
							<br />
							{orderTask?.type && (
								<>
									<b>{t('orders.taskDetailsDialog.type')}:</b> {orderTask.type}
									<br />
								</>
							)}
							{orderTask?.company && (
								<>
									<b>{t('orders.taskDetailsDialog.company')}:</b> {orderTask.company}
									<br />
								</>
							)}
							{orderTask?.location && (
								<>
									<b>{t('orders.taskDetailsDialog.location')}:</b> {orderTask.location}
									<br />
								</>
							)}
							<br />
							{orderTask?.etaFrom && (
								<>
									<b>{t('orders.taskDetailsDialog.dateFrom')}:</b>{' '}
									{getFormattedEta(new Date(orderTask.etaFrom as Date))}
									<br />
								</>
							)}
							{orderTask?.etaTo && (
								<>
									<b>{t('orders.taskDetailsDialog.dateTo')}:</b>{' '}
									{getFormattedEta(new Date(orderTask.etaTo as Date))}
									<br />
								</>
							)}
							<br />
							{orderTask?.notes && (
								<>
									<b>{t('orders.taskDetailsDialog.notes')}:</b> {orderTask.notes}
									<br />
								</>
							)}
						</p>
					</StyledTextWrapper>
				</StyledTextWrapper>
			</StyledPageContent>

			<StyledButtonContainer>
				<Button variant="outlined" onClick={handleBackClick} fullWidth size="large">
					{t('actionLabels.back')}
				</Button>
				<Button
					variant="contained"
					onClick={handleSubmitClick}
					fullWidth
					size="large"
					disabled={isSubmitting}
				>
					{t('actionLabels.confirm')}
				</Button>
			</StyledButtonContainer>

			<FlashSnackbar controls={[stateSnackbar, openSnackbar, closeSnackbar]} />
		</ContactPageLayout>
	);
};
