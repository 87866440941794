import {
	Drawer,
	// TextField,
	ThemeProvider,
	createTheme,
	debounce,
} from '@mui/material';
import {
	DataGridPro,
	// GridToolbarContainer,
	// GridToolbarFilterButton,
	useGridApiRef,
	deDE,
	enUS,
} from '@mui/x-data-grid-pro';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import * as locales from '@mui/material/locale';
import i18next from 'i18next';

import {
	ROUTES,
	TableV2,
	theme,
	useDialog,
	useFleetCheckResultDownload,
	useOrganizationIdByWorkspaceId,
	useRequestsWithFleetChecks,
} from '@heylog-app/frontend-lib/app';

import { SurveyDetailsDrawerContent } from './survey-details-drawer-content';

import type { FC } from 'react';
import type { GridColDef, GridRowParams } from '@mui/x-data-grid-pro';

export type DataTableProps = {
	id: string;
	form: string;
	conversationId: number;
	contactId: number;
	driver: string;
	licensePlate: string;
	status: string;
	createdAt: Date;
};

// function CustomToolbar({
// 	searchValue,
// 	handleSearchValueChange,
// }: {
// 	searchValue: string;
// 	handleSearchValueChange: () => void;
// }) {
// 	const { t } = useTranslation();
// 	return (
// 		<GridToolbarContainer sx={{ width: '100%', padding: '8px' }}>
// 			<TextField
// 				value={searchValue}
// 				onChange={handleSearchValueChange}
// 				label={t('actionLabels.search')}
// 				sx={{ mb: 1 }}
// 				size="small"
// 			/>
// 			<GridToolbarFilterButton />
// 		</GridToolbarContainer>
// 	);
// }

interface FleetDataTablePropsInterface {
	isV2: boolean;
	TableV2Toolbar?: JSX.Element;
	tableV2Title?: string;
}

export const DataTable: FC<FleetDataTablePropsInterface> = ({
	isV2,
	TableV2Toolbar,
	tableV2Title,
}) => {
	const {
		showDialog: showDrawer,
		openDialog: openDrawer,
		closeDialog: closeDrawer,
	} = useDialog();
	const [selectedRow, setSelectedRow] = useState<DataTableProps | undefined>(undefined);
	const [searchValue, setSearchValue] = useState('');
	const { workspaceId = '' } = useParams();
	const currentLanguage = i18next.language;
	const tableLanguage = currentLanguage === 'de' ? 'deDE' : 'enUS';
	const { organizationId = -1 } = useOrganizationIdByWorkspaceId(workspaceId);
	const { rowsToShow } = useRequestsWithFleetChecks(organizationId, workspaceId);
	const { t } = useTranslation();
	const { handleDownloadClick } = useFleetCheckResultDownload(
		selectedRow?.id || '',
		organizationId,
		workspaceId,
	);

	const navigate = useNavigate();

	const apiRef = useGridApiRef();

	const columns: GridColDef[] = [
		{ field: 'form', headerName: t('vchk.form'), flex: 1 },
		{
			field: 'driver',
			headerName: t('vchk.driver'),
			flex: 1,
		},
		{ field: 'licensePlate', headerName: t('vchk.licensePlate'), flex: 1 },
		{
			field: 'status',
			headerName: 'Status',
			flex: 1,
			cellClassName: (params) => {
				if (params.row.statusEnum == null) {
					return '';
				}
				if (params.row.statusEnum === 'PENDING') {
					return 'status-pending';
				}
				if (params.row.statusEnum === 'COMPLETED') {
					return 'status-completed';
				}
				return '';
			},
		},
		{
			field: 'createdAt',
			headerName: t('vchk.createdAt'),
			flex: 2,
			valueFormatter: (params) =>
				new Date(params.value).toLocaleString(undefined, {
					day: '2-digit',
					month: 'long',
					year: 'numeric',
					hour: 'numeric',
					minute: '2-digit',
				}),
		},
		// {
		// 	field: 'fullName',
		// 	headerName: 'Full name',
		// 	description: 'This column has a value getter and is not sortable.',
		// 	sortable: false,
		// 	width: 160,
		// 	// valueGetter: (params: ) =>
		// 	// 	`${params.row.firstName || ''} ${params.row.lastName || ''}`,
		// },
	];

	const handleRowClick = (params: GridRowParams) => {
		const survey = params.row;
		setSelectedRow(survey);
		openDrawer();
	};

	const handleExportData = () => {
		handleDownloadClick();
	};

	const handleOpenConversation = () => {
		navigate(
			generatePath(ROUTES.CONTACTS.CHAT, {
				conversationId: selectedRow?.conversationId,
				contactId: selectedRow?.contactId,
				workspaceId: workspaceId,
			}),
		);
	};

	const updateSearchValue = useMemo(() => {
		return debounce((newValue) => {
			apiRef.current.setQuickFilterValues(
				newValue.split(' ').filter((word: string) => word !== ''),
			);
		}, 500);
	}, [apiRef]);

	const handleSearchValueChange = useCallback(
		(event: { target: { value: string } }) => {
			const newValue = event.target.value;
			setSearchValue(newValue);
			updateSearchValue(newValue);
		},
		[updateSearchValue],
	);

	const themeWithLocale = useMemo(
		() => createTheme(theme, locales[tableLanguage]),
		[tableLanguage],
	);

	return (
		<ThemeProvider theme={themeWithLocale}>
			{isV2 ? (
				<TableV2
					title={tableV2Title}
					ToolbarContent={TableV2Toolbar}
					data-test="fleet-checks-table"
					localeText={
						tableLanguage === 'deDE'
							? deDE.components.MuiDataGrid.defaultProps.localeText
							: enUS.components.MuiDataGrid.defaultProps.localeText
					}
					apiRef={apiRef}
					rows={rowsToShow}
					columns={columns}
					density="compact"
					disableDensitySelector
					disableColumnSelector
					slots={{
						footer: undefined,
					}}
					initialState={{
						filter: {
							filterModel: {
								items: [],
								quickFilterValues: [''],
							},
						},
						pagination: { paginationModel: { pageSize: 15 } },
						sorting: {
							sortModel: [{ field: 'createdAt', sort: 'desc' }],
						},
					}}
					// slots={{ toolbar: CustomToolbar }}
					slotProps={{
						toolbar: {
							searchValue: searchValue,
							handleSearchValueChange: handleSearchValueChange,

							showQuickFilter: true,
							printOptions: { disableToolbarButton: true },
							csvOptions: { disableToolbarButton: true },
						},
					}}
					pageSizeOptions={[5, 10, 15]}
					sx={{ backgroundColor: 'white', borderRadius: '5px', fontSize: '10px' }}
					onRowClick={handleRowClick}
				/>
			) : (
				<DataGridPro
					data-test="fleet-checks-table"
					localeText={
						tableLanguage === 'deDE'
							? deDE.components.MuiDataGrid.defaultProps.localeText
							: enUS.components.MuiDataGrid.defaultProps.localeText
					}
					apiRef={apiRef}
					rows={rowsToShow}
					rowHeight={40}
					columns={columns}
					density="compact"
					disableDensitySelector
					disableColumnSelector
					autoHeight
					initialState={{
						filter: {
							filterModel: {
								items: [],
								quickFilterValues: [''],
							},
						},
						pagination: { paginationModel: { pageSize: 15 } },
						sorting: {
							sortModel: [{ field: 'createdAt', sort: 'desc' }],
						},
					}}
					// slots={{ toolbar: CustomToolbar }}
					slotProps={{
						toolbar: {
							searchValue: searchValue,
							handleSearchValueChange: handleSearchValueChange,

							showQuickFilter: true,
							printOptions: { disableToolbarButton: true },
							csvOptions: { disableToolbarButton: true },
						},
					}}
					pageSizeOptions={[5, 10, 15]}
					sx={{ backgroundColor: 'white', borderRadius: '5px', fontSize: '10px' }}
					onRowClick={handleRowClick}
				/>
			)}

			<Drawer anchor="right" open={showDrawer} onClose={closeDrawer}>
				<SurveyDetailsDrawerContent
					selectedRow={selectedRow}
					closeDrawer={closeDrawer}
					handleExportData={handleExportData}
					handleOpenConversation={handleOpenConversation}
					organizationId={organizationId}
					workspaceId={workspaceId}
				/>
			</Drawer>
		</ThemeProvider>
	);
};
