import styled from 'styled-components';

export const StyledSidebarSection = styled.div`
	overflow: hidden;
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	align-self: stretch;
	flex-grow: 1;
	align-items: flex-end;
	width: 100%;
	gap: 1rem;
	padding: 1rem;
`;

export const StyledOrderDetailSidebar = styled.div`
	overflow: scroll;
	display: flex;
	flex-direction: column;
	padding: 1rem;
	background-color: ${({ theme }) => theme.palette.greyblue[245]};
	border-bottom: 1px solid ${({ theme }) => theme.palette.greyblue[225]};
	justify-content: space-between;
`;
