import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams, generatePath } from 'react-router-dom';
import { mdiCircleSmall } from '@mdi/js';
import { Grid, Stack } from '@mui/material';

import { Actions } from '@heylog-app/shared/types';

import { ROUTES, getUnreadSum } from '../../util';
import { useTitle, useActiveUnread, useOrdersUnread, usePlugins } from '../../hooks';
import {
	StyledLogoWrapper,
	StyledNavigation,
	StyledNavItem,
	StyledNavList,
	StyledToolbar,
	StyledDotIcon,
	StyledOrderDotIcon,
} from './navigation.styles';
import { UserMenu } from './user-menu/user-menu';
import { matchNavigationPath } from '../../util/match-navigation-path';
import { HeylogLogoWhite } from '../icons/heylog-logo-white';
import { Can } from '../can';
import { StyledGridSidebar } from '../sidebar';

import type { FC } from 'react';

export const Navigation: FC = () => {
	const location = useLocation();
	const { pathname } = location;
	const { t } = useTranslation();
	const params = useParams();
	const { workspaceId = '' } = useParams();

	const { activeUnreadMessagesSum, setTitle, cleanTitle } = useTitle();
	const { activeUnread } = useActiveUnread();
	const { ordersUnread } = useOrdersUnread();
	const { FleetCheckPlugin, FleetCheckReportPlugin, FrontendV2Plugin } =
		usePlugins(workspaceId);

	const hasContactsUnread = getUnreadSum(activeUnread) > 0;
	const hasOrdersUnread = getUnreadSum(ordersUnread) > 0;

	useEffect(() => {
		setTitle();
		return () => cleanTitle();
	}, [setTitle, activeUnreadMessagesSum, cleanTitle]);

	return (
		<StyledNavigation container gap={2} columns={{ xs: 12 }}>
			<StyledGridSidebar>
				<StyledLogoWrapper>
					<Link
						to={generatePath(FrontendV2Plugin ? ROUTES.HOME : ROUTES.HOME_V1, params)}
					>
						<HeylogLogoWhite />
					</Link>
				</StyledLogoWrapper>
			</StyledGridSidebar>

			<Grid item xs={11}>
				<Stack direction="row" sx={{ height: '100%' }}>
					<StyledNavList>
						{!FrontendV2Plugin && (
							<StyledNavItem
								variant="contained"
								data-test="contacts-tab-button"
								$active={matchNavigationPath(pathname, ROUTES.CONTACTS)}
							>
								<Link to={generatePath(ROUTES.CONTACTS.OVERVIEW, params)}>
									{t('navigation.messages')}
									{hasContactsUnread && (
										<StyledDotIcon
											data-test="unread-contacts-dot"
											path={mdiCircleSmall}
											size={1}
										/>
									)}
								</Link>
							</StyledNavItem>
						)}
						{!FrontendV2Plugin && (
							<Can I={Actions.MANAGE} a="Workspace">
								{() => (
									<StyledNavItem
										role="navigation"
										variant="contained"
										data-test="orders-nav-menu"
										$active={matchNavigationPath(pathname, ROUTES.ORDERS)}
									>
										<Link to={generatePath(ROUTES.ORDERS.OVERVIEW, params)}>
											{t('navigation.orders')}
											{hasOrdersUnread && (
												<StyledOrderDotIcon
													data-test="unread-orders-dot"
													path={mdiCircleSmall}
													size={1}
												/>
											)}
										</Link>
									</StyledNavItem>
								)}
							</Can>
						)}

						{FrontendV2Plugin && (
							<StyledNavItem
								role="navigation"
								variant="contained"
								data-test="contacts-tab-button"
								$active={matchNavigationPath(pathname, ROUTES.MESSAGES)}
							>
								<Link to={generatePath(ROUTES.MESSAGES.OVERVIEW, params)}>
									{t('navigation.messages')}
									{hasContactsUnread && (
										<StyledDotIcon
											data-test="unread-contacts-dot"
											path={mdiCircleSmall}
											size={1}
										/>
									)}
								</Link>
							</StyledNavItem>
						)}
						{FleetCheckPlugin && FleetCheckReportPlugin && (
							<Can I={Actions.MANAGE} a="Workspace">
								{() => (
									<StyledNavItem
										role="navigation"
										variant="contained"
										data-test="fleet-nav-menu"
										$active={matchNavigationPath(pathname, ROUTES.FLEET)}
									>
										<Link to={generatePath(ROUTES.FLEET.OVERVIEW, params)}>
											{t('navigation.fleet')}
										</Link>
									</StyledNavItem>
								)}
							</Can>
						)}
						{FrontendV2Plugin && (
							<StyledNavItem
								role="navigation"
								variant="contained"
								data-test="documents-nav-menu"
								$active={matchNavigationPath(pathname, ROUTES.DOCUMENTS)}
							>
								<Link to={generatePath(ROUTES.DOCUMENTS.OVERVIEW, params)}>
									{t('navigation.documents')}
								</Link>
							</StyledNavItem>
						)}
						{/* {FrontendV2Plugin && (
							<StyledNavItem
								role="navigation"
								variant="contained"
								// $active={matchNavigationPath(pathname, ROUTES.DOCUMENTS)}
							>
								<Link to={generatePath(ROUTES.DOCUMENTS.OVERVIEW, params)}>Reports</Link>
								Reports
							</StyledNavItem>
						)} */}
					</StyledNavList>

					<StyledToolbar>
						<UserMenu />
					</StyledToolbar>
				</Stack>
			</Grid>
		</StyledNavigation>
	);
};
