import { css } from 'styled-components';

export const typographyV2 = {
	fontFamily: 'Inter, sans-serif',
};

export const STYLE_1 = css`
	font-family: 'Eudoxus Sans', sans-serif;
	font-weight: 400;
	letter-spacing: 0.01em;
`;

export const STYLE_H2 = css`
	font-size: 20px;
	line-height: 25px;
`;

export const STYLE_2 = css`
	font-family: 'Eudoxus Sans', sans-serif;
	font-weight: 500;
	letter-spacing: 0.01em;
`;

export const STYLE_3 = css`
	font-family: 'Eudoxus Sans', sans-serif;
	font-weight: 700;
	letter-spacing: 0.05em;
`;

export const STYLE_4 = css`
	font-family: 'Eudoxus Sans', sans-serif;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.05em;
`;

export const SIZE_XS = css`
	font-size: 0.66rem;
`;

export const SIZE_SM = css`
	font-size: 0.7778rem;
`;

export const SIZE_SM_PLUS = css`
	font-size: 0.85rem;
`;

export const SIZE_MD = css`
	font-size: 1rem;
`;

export const SIZE_LG = css`
	font-size: 1.33rem;
`;
