import { useTranslation } from 'react-i18next';

import {
	StyledDetailItem,
	StyledDetailItemLabel,
	StyledDetailItemValue,
} from './contact-detail-item.styles';

import type { FC, ReactNode } from 'react';

type ContactDetailItemProps = {
	label: string;
	children: ReactNode;
};

/** to display contact data like name, email on details page */
export const ContactDetailItem: FC<ContactDetailItemProps> = ({ label, children }) => {
	const { t } = useTranslation();
	return (
		<StyledDetailItem>
			<StyledDetailItemLabel>{t(label)}</StyledDetailItemLabel>
			<StyledDetailItemValue>{children}</StyledDetailItemValue>
		</StyledDetailItem>
	);
};
