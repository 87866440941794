import i18next from 'i18next';
import { useEffect } from 'react';

import { LOCAL_STORAGE_LANGUAGE } from '../util';
import { useLocalStorage } from './use-local-storage';

export const useCurrentLanguage = () => {
	const detectedLanguage =
		new Intl.Locale(navigator.language).language === 'de' ? 'de' : 'en';

	const [currentLanguage, setCurrentLanguage] = useLocalStorage<string>(
		LOCAL_STORAGE_LANGUAGE,
		detectedLanguage,
	);

	useEffect(() => {
		if (currentLanguage !== i18next.language) {
			i18next.changeLanguage(currentLanguage);
		}
	}, [currentLanguage]);

	return { currentLanguage, setCurrentLanguage, detectedLanguage };
};
