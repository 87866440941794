import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

import { CreateNewOrderSvg, OnboardingNotice } from '@heylog-app/frontend-lib/app';

import { StyledWelcomeOrdersBlock } from '../orders.styles';

import type { FC } from 'react';
import type { UserResInterface } from '@heylog-app/shared/types';

type CreateOrderWelcomeProps = {
	user: UserResInterface | undefined;
	onClick: () => void;
};

export const CreateOrderWelcome: FC<CreateOrderWelcomeProps> = ({ user, onClick }) => {
	const { t } = useTranslation();

	return (
		<StyledWelcomeOrdersBlock>
			<CreateNewOrderSvg />
			<OnboardingNotice
				title={t('onboarding.title', {
					name: `${user?.firstName} ${user?.lastName}`,
				})}
				text={t('onboarding.orders.pleaseCreate')}
			>
				<Button variant="outlined" color="primary" onClick={onClick}>
					{t('onboarding.orders.ctaLabel')}
				</Button>
			</OnboardingNotice>
		</StyledWelcomeOrdersBlock>
	);
};
