import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import { useCallback } from 'react';
import { useAbility } from '@casl/react';

import { Actions, UserOrderStatusEnum } from '@heylog-app/shared/types';

import {
	createOrderAPI,
	getFetcher,
	getLiveLocationDataAPI,
	getOrderKey,
	getQueryString,
	toggleAttentionOrderAPI,
	toggleUrgentOrderAPI,
	updateOrderAPI,
} from '../util';
import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers';

import type { Key } from 'swr';
import type {
	OrderResInterface,
	UpdateOrderReqInterface,
	Nullable,
	CreateOrderReqInterface,
} from '@heylog-app/shared/types';
import type { ArchiveEntryParams, CreateEntryParams, UpdateEntryParams } from '../types';

const useKey = (): Key => {
	const { workspaceId } = useParams();
	return workspaceId ? getOrderKey(workspaceId) : undefined;
};

export const useOrders = ({ q }: { q?: string } = {}) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();
	const baseOrdersKey = useKey();
	const ordersKey = q ? baseOrdersKey + getQueryString({ q }) : baseOrdersKey;

	const { data, error, mutate, isLoading } = useSWR<OrderResInterface[]>(
		ability.can(Actions.MANAGE, 'Workspace') ? ordersKey : null,
		getFetcher(apiClient),
	);

	return {
		orders: data,
		isLoading,
		isError: error,
		updateOrders: mutate,
	};
};

export const useOrder = (id?: Nullable<string>, includeArchived = false) => {
	// TODO refine abilities later, or use new endpoint without them
	const { apiClient } = useApiClientContext();
	const ordersKey = useKey();
	const keyWithId = [ordersKey, id];

	const { data, error, mutate, isLoading } = useSWR<OrderResInterface>(
		id && keyWithId,
		() =>
			getFetcher(apiClient)(
				keyWithId.join('/') + `${includeArchived ? '?includeArchived=true' : ''}`,
			) as Promise<OrderResInterface>,
	);

	return {
		order: data,
		orderError: error,
		mutateOrder: mutate,
		ordersKey,
		isLoading,
	};
};

export const useOrderActions = (id?: Nullable<string>) => {
	const { apiClient } = useApiClientContext();
	const { mutate: globalMutate } = useSWRConfig();
	const { order, orderError, mutateOrder, ordersKey, isLoading } = useOrder(id);

	const createOrder = useCallback(
		async (params: CreateEntryParams<CreateOrderReqInterface>) => {
			const { data: newOrder } = await createOrderAPI(apiClient, {
				data: params.data,
				workspaceId: params.workspaceId,
			});

			const ordersRes = globalMutate(ordersKey, (orders?: OrderResInterface[]) =>
				orders
					? [newOrder, ...orders].filter(
							({ currentStatus }) => currentStatus !== UserOrderStatusEnum.ARCHIVED,
					  )
					: [newOrder],
			);

			await ordersRes;

			return newOrder;
		},
		[apiClient, globalMutate, ordersKey],
	);

	const updateOrder = useCallback(
		async (params: UpdateEntryParams<UpdateOrderReqInterface>) => {
			const { data } = await updateOrderAPI(apiClient, params);

			const orderRes = mutateOrder(data, { revalidate: false });
			const ordersRes = globalMutate(ordersKey, (orders?: OrderResInterface[]) =>
				orders
					?.map((existing) => (existing.id === data.id ? data : existing))
					.filter(({ currentStatus }) => currentStatus !== UserOrderStatusEnum.ARCHIVED),
			);

			await Promise.all([orderRes, ordersRes]);

			return data;
		},
		[apiClient, globalMutate, mutateOrder, ordersKey],
	);

	const archiveOrder = useCallback(
		(params: ArchiveEntryParams) =>
			updateOrder({
				...params,
				data: { currentStatus: UserOrderStatusEnum.ARCHIVED },
			}),
		[updateOrder],
	);

	const toggleUrgentOrder = useCallback(async () => {
		// sanity checks
		if (!order) return;
		if (!order.workspaceId) return;

		const { data } = await toggleUrgentOrderAPI(apiClient, {
			id: order.id.toString(),
			workspaceId: order.workspaceId.toString(),
		});

		const orderRes = mutateOrder(data, { revalidate: false });
		const ordersRes = globalMutate(ordersKey, (orders?: OrderResInterface[]) =>
			orders
				?.map((existing) => (existing.id === data.id ? data : existing))
				.filter(({ currentStatus }) => currentStatus !== UserOrderStatusEnum.ARCHIVED),
		);

		await Promise.all([orderRes, ordersRes]);

		return data;
	}, [order, apiClient, globalMutate, mutateOrder, ordersKey]);

	const toggleAttentionOrder = useCallback(async () => {
		// sanity checks
		if (!order) return;
		if (!order.workspaceId) return;

		const { data } = await toggleAttentionOrderAPI(apiClient, {
			id: order.id.toString(),
			workspaceId: order.workspaceId.toString(),
		});

		const orderRes = mutateOrder(data, { revalidate: false });
		const ordersRes = globalMutate(ordersKey, (orders?: OrderResInterface[]) =>
			orders
				?.map((existing) => (existing.id === data.id ? data : existing))
				.filter(({ currentStatus }) => currentStatus !== UserOrderStatusEnum.ARCHIVED),
		);

		await Promise.all([orderRes, ordersRes]);

		return data;
	}, [order, apiClient, globalMutate, mutateOrder, ordersKey]);

	const getLiveLocationData = useCallback(async () => {
		// sanity checks
		if (!order) return;
		if (!order.workspaceId) return;

		const { data } = await getLiveLocationDataAPI(apiClient, {
			orderId: order.id.toString(),
			workspaceId: order.workspaceId.toString(),
		});
		return data;
	}, [apiClient, order]);

	return {
		archiveOrder,
		createOrder,
		getLiveLocationData,
		isError: orderError,
		isLoading,
		mutateOrder,
		order,
		toggleAttentionOrder,
		toggleUrgentOrder,
		updateOrder,
	};
};
