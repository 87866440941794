import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const WorkspaceStatusEnum = mkenum({
	ACTIVE: 'ACTIVE',
	ARCHIVED: 'ARCHIVED',
	DISABLED: 'DISABLED',
});

export type WorkspaceStatusType = TypeFromPEnum<typeof WorkspaceStatusEnum>;
