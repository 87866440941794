import { useAbility } from '@casl/react';
import useSWR from 'swr';

import { Actions } from '@heylog-app/shared/types';

import { useApiClientContext } from './use-api-client-context.hook';
import { AbilityContext } from '../providers';
import { getFetcher } from '../util';

import type { LabelResInterface } from '@heylog-app/shared/types';

export const useLabels = (workspaceId: string) => {
	const ability = useAbility(AbilityContext);
	const { apiClient } = useApiClientContext();

	const { data, mutate, error, isLoading } = useSWR<Array<LabelResInterface>>(
		ability.can(Actions.MANAGE, 'Workspace') && workspaceId
			? `/workspaces/${workspaceId}/labels`
			: null,
		getFetcher(apiClient),
	);

	return {
		labels: data,
		mutateLabels: mutate,
		isLoading,
		isError: error,
	};
};
