import { TextField } from '@mui/material';
import { useCallback, useEffect, useRef, useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

import { StyledAddressInput } from './address-input.styles';

import type { GoogleAutoCompleteResponse } from '../../types/google-places.types';
import type { FieldValues, UseFormSetValue } from 'react-hook-form';
import type { ForwardRefRenderFunction } from 'react';

type AddressInputProps = {
	addressName?: string;
	dataTest?: string;
	error?: boolean;
	helperText?: string;
	id: string;
	label: string;
	setValue: UseFormSetValue<FieldValues>;
	setLng?: (lng: number) => void;
	setLat?: (lat: number) => void;
};

export type GeoCodedAddress = {
	name: string;
	street?: string;
	houseNumber?: string;
	city?: string;
	zip?: string;
	latitude?: number | null;
	longitude?: number | null;
};

const getAddress = (response: GoogleAutoCompleteResponse) => {
	if (response) {
		const street = response.address_components.find((item) =>
			item.types.includes('route'),
		);
		const city = response.address_components.find((item) =>
			item.types.includes('locality'),
		);
		const zipCode = response.address_components.find((item) =>
			item.types.includes('postal_code'),
		);
		const streetNumber = response.address_components.find((item) =>
			item.types.includes('street_number'),
		);

		return {
			name: response.formatted_address,
			latitude: response.geometry.location.lat(),
			longitude: response.geometry.location.lng(),
			street: streetNumber
				? `${street?.long_name} ${streetNumber?.long_name}`
				: street?.long_name,
			city: city?.long_name,
			zip: zipCode?.long_name,
		};
	}
	return undefined;
};

const AddressInput: ForwardRefRenderFunction<HTMLInputElement, AddressInputProps> = ({
	id,
	label,
	setValue,
	addressName,
	dataTest,
	error,
	helperText,
	setLng,
	setLat,
}: AddressInputProps) => {
	const [placeAutocomplete, setPlaceAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const places = useMapsLibrary('places');

	const onPlaceSelect = useCallback(
		(response: unknown) => {
			const address = getAddress(response as GoogleAutoCompleteResponse);
			if (address?.latitude && address?.longitude) {
				setLat?.(address.latitude);
				setLng?.(address.longitude);
			}
			setValue(id, address, { shouldValidate: true });
		},
		[setLat, setLng, setValue, id],
	);

	useEffect(() => {
		if (!places || !inputRef.current) return;

		const options = {
			types: ['address'],
		};

		setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
	}, [places]);

	useEffect(() => {
		if (!placeAutocomplete) return;

		placeAutocomplete.addListener('place_changed', () => {
			onPlaceSelect(placeAutocomplete.getPlace());
		});
	}, [onPlaceSelect, placeAutocomplete]);
	const { t } = useTranslation();

	return (
		<StyledAddressInput>
			<TextField
				data-test={dataTest}
				id={id}
				label={t(label)}
				inputRef={inputRef}
				variant="outlined"
				fullWidth
				margin="dense"
				placeholder={t('orders.form.destinationPlaceholder')}
				defaultValue={addressName || ''}
				error={!!error}
				helperText={helperText}
			/>
		</StyledAddressInput>
	);
};

export const ForwardedAddressInput = forwardRef(AddressInput);
export { ForwardedAddressInput as AddressInput };
