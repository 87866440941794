import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConversationTypeEnum } from '@heylog-app/shared/types';

import { useLocalStorage } from './use-local-storage';

import type { ConversationDetailsRow } from './use-messages-data.hook';

export const useMessagesDataTableFilters = (rows: ConversationDetailsRow[]) => {
	const { t } = useTranslation();
	const [emailCheckbox, setEmailCheckbox] = useLocalStorage(
		'MessagesDataTable.emailCheckbox',
		true,
	);
	const [whatsappCheckbox, setWhatsappCheckbox] = useLocalStorage(
		'MessagesDataTable.whatsappCheckbox',
		true,
	);
	const [viberCheckbox, setViberCheckbox] = useLocalStorage(
		'MessagesDataTable.viberCheckbox',
		true,
	);
	// "urgent" here means that the conversation is attached to an order with priority urgent
	const [urgentCheckbox, setUrgentCheckbox] = useLocalStorage(
		'MessagesDataTable.urgentCheckbox',
		false,
	);

	const [withoutOrderCheckbox, setWithoutOrderCheckbox] = useLocalStorage(
		'MessagesDataTable.withoutOrderCheckbox',
		false,
	);

	const [internalConversationCheckbox, setInternalConversationCheckbox] = useLocalStorage(
		'MessagesDataTable.internalConversationCheckbox',
		true,
	);
	const [externalConversationCheckbox, setExternalConversationCheckbox] = useLocalStorage(
		'MessagesDataTable.externalConversationCheckbox',
		true,
	);

	let filteredRows = rows.filter((row: ConversationDetailsRow) => {
		if (
			(emailCheckbox === true && row.type === ConversationTypeEnum.EMAIL) ||
			(whatsappCheckbox === true && row.type === ConversationTypeEnum.WHATSAPP) ||
			(viberCheckbox === true && row.type === ConversationTypeEnum.VIBER) ||
			(internalConversationCheckbox === true &&
				row.type === ConversationTypeEnum.HEYLOG_INTERNAL) ||
			(externalConversationCheckbox === true &&
				row.type === ConversationTypeEnum.HEYLOG_EXTERNAL)
		) {
			return true;
		}
		return false;
	});

	if (urgentCheckbox) {
		filteredRows = filteredRows.filter((row: ConversationDetailsRow) => {
			if (row.priority === 'Urgent') {
				return true;
			}
			return false;
		});
	}

	if (withoutOrderCheckbox) {
		filteredRows = filteredRows.filter((row: ConversationDetailsRow) => {
			if (!row.internalReference) {
				return true;
			}
			return false;
		});
	}

	const activeFiltersCount = [
		emailCheckbox,
		whatsappCheckbox,
		viberCheckbox,
		urgentCheckbox,
		withoutOrderCheckbox,
		internalConversationCheckbox,
		externalConversationCheckbox,
	].filter(Boolean).length;

	const FiltersComponent = (
		<Stack padding={2}>
			<Stack sx={{ mt: '4px' }}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={emailCheckbox}
								onChange={() => setEmailCheckbox(!emailCheckbox)}
							/>
						}
						label="E-mail"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={whatsappCheckbox}
								onChange={() => setWhatsappCheckbox(!whatsappCheckbox)}
							/>
						}
						label="Whatsapp"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={viberCheckbox}
								onChange={() => setViberCheckbox(!viberCheckbox)}
							/>
						}
						label="Viber"
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={internalConversationCheckbox}
								onChange={() =>
									setInternalConversationCheckbox(!internalConversationCheckbox)
								}
							/>
						}
						label={t('messages.filters.internalComments')}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={externalConversationCheckbox}
								onChange={() =>
									setExternalConversationCheckbox(!externalConversationCheckbox)
								}
							/>
						}
						label={t('messages.filters.externalComments')}
					/>
					{/* TODO */}
					{/* <FormControlLabel control={<Checkbox />} label="Sent" />
					<FormControlLabel control={<Checkbox />} label="Archive" /> */}
				</FormGroup>
			</Stack>
			<Typography sx={{ mt: 4, fontWeight: 700 }}>{t('messages.labels')}</Typography>
			<Stack sx={{ mt: '4px' }}>
				<FormGroup>
					<FormControlLabel
						control={
							<Checkbox
								checked={withoutOrderCheckbox}
								onChange={() => setWithoutOrderCheckbox(!withoutOrderCheckbox)}
							/>
						}
						label={t('messages.filters.withoutOrder')}
					/>
					<FormControlLabel
						control={
							<Checkbox
								checked={urgentCheckbox}
								onChange={() => setUrgentCheckbox(!urgentCheckbox)}
							/>
						}
						label={t('messages.filters.urgentOnly')}
					/>
				</FormGroup>
			</Stack>
		</Stack>
	);

	return {
		FiltersComponent,
		filteredRows,
		activeFiltersCount,
	};
};
