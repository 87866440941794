import type { FC } from 'react';

export const IconBg: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 96 96"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M48 8C30.842 8 16.25 18.824 10.568 34H85.43C79.75 18.824 65.158 8 48 8Z"
			fill="#ECEFF1"
		/>
		<path
			d="M88 48C88 43.066 87.064 38.364 85.432 34H10.568C8.936 38.364 8 43.066 8 48C8 52.934 8.936 57.636 10.568 62H85.43C87.064 57.636 88 52.934 88 48Z"
			fill="#43A047"
		/>
		<path
			d="M85.432 62H10.568C16.25 77.176 30.842 88 48 88C65.158 88 79.75 77.176 85.432 62Z"
			fill="#FF3D00"
		/>
	</svg>
);
