export type CreateEntryParams<T> = {
	data: T;
	workspaceId: string;
	conversationId?: string;
};

export type InviteEntryParams<T> = {
	data: T;
};

export type UpdateEntryParams<T> = {
	id?: string;
	data: T;
	workspaceId: string;
};

export type DeleteGuestParams = {
	workspaceId: string;
	conversationId: string;
	guestToRemoveId: string;
};

export type ArchiveEntryParams = {
	id?: string;
	workspaceId: string;
};

export type UpdateOrderParams<T> = {
	id?: string;
	data: T;
	workspaceId: string;
	contactToken?: string;
};

export type UpdateOrderTaskParams<T> = {
	contactToken?: string;
	data: T;
	orderId: string;
	taskId: string;
	workspaceId: string;
};

export type TogglePriorityOrderParams = {
	id?: string;
	workspaceId: string;
};

export type AcceptUserInviteEntryParams = {
	workspaceId: string;
	invitationId: string;
};

export type AcceptGuestInviteEntryParams = {
	workspaceId: string;
	conversationId: string;
	invitationId: string;
};

export type ToggleTodoConversationV2Params = {
	id: string;
	workspaceId: string;
};

export type SetOrderOnTimeParams = {
	orderId: string;
	onTime: boolean;
	taskId: string;
	workspaceId: string;
};

export type CreateTaskParams<T> = {
	data: T;
	orderId: string;
	workspaceId: string;
};

export type DeleteTaskParams = {
	orderId: string;
	taskId: string;
	workspaceId: string;
};
