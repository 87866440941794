import { mkenum } from './mkenum';

import type { TypeFromPEnum } from './type-from-p-enum';

export const UserOrderStatusEnum = mkenum({
	CREATED: 'CREATED',
	ACCEPTED: 'ACCEPTED',
	APPROACHING: 'APPROACHING',
	ARRIVED_AT_LOADING: 'ARRIVED_AT_LOADING',
	LOADED: 'LOADED',
	DEPARTED_FROM_LOADING: 'DEPARTED_FROM_LOADING',
	IN_TRANSIT: 'IN_TRANSIT',
	ARRIVED_AT_UNLOADING: 'ARRIVED_AT_UNLOADING',
	DELIVERED: 'DELIVERED',
	PROBLEM: 'PROBLEM',
	ARCHIVED: 'ARCHIVED',
});

export type UserOrderStatus = TypeFromPEnum<typeof UserOrderStatusEnum>;

// when switching to one of these statuses, send a OSU reminder
export const OSUReminderStartUserOrderStatuses = [
	// CREATED will work for TM but break the flow for regular OSUs
	UserOrderStatusEnum.CREATED,
	UserOrderStatusEnum.ACCEPTED,
	UserOrderStatusEnum.APPROACHING,
	UserOrderStatusEnum.ARRIVED_AT_LOADING,
	UserOrderStatusEnum.LOADED,
	UserOrderStatusEnum.DEPARTED_FROM_LOADING,
	UserOrderStatusEnum.IN_TRANSIT,
	UserOrderStatusEnum.ARRIVED_AT_UNLOADING,
];

// when switching to one of these statuses, stop sending a OSU reminder
export const OSUReminderStopUserOrderStatuses = [
	UserOrderStatusEnum.DELIVERED,
	UserOrderStatusEnum.PROBLEM,
	UserOrderStatusEnum.ARCHIVED,
];

// when switching to one of these statuses, stop sending all Task reminders for that order (including warnings)
export const TaskReminderStopUserOrderStatuses = [
	UserOrderStatusEnum.DELIVERED,
	UserOrderStatusEnum.ARCHIVED,
];

export const ContactOrderStatusEnum = mkenum({
	ACCEPTED: 'ACCEPTED',
	APPROACHING: 'APPROACHING',
	ARRIVED_AT_LOADING: 'ARRIVED_AT_LOADING',
	LOADED: 'LOADED',
	DEPARTED_FROM_LOADING: 'DEPARTED_FROM_LOADING',
	IN_TRANSIT: 'IN_TRANSIT',
	ARRIVED_AT_UNLOADING: 'ARRIVED_AT_UNLOADING',
	DELIVERED: 'DELIVERED',
	PROBLEM: 'PROBLEM',
});

export type ContactOrderStatus = TypeFromPEnum<typeof ContactOrderStatusEnum>;

export const TimeMattersOrderStatusEnum = mkenum({
	APPROACHING: 'APPROACHING',
	ARRIVED_AT_LOADING: 'ARRIVED_AT_LOADING',
	LOADED: 'LOADED',
	ARRIVED_AT_UNLOADING: 'ARRIVED_AT_UNLOADING',
	DELIVERED: 'DELIVERED',
});

export type TimeMattersOrderStatus = TypeFromPEnum<typeof TimeMattersOrderStatusEnum>;
