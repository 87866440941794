export const trailerSurveyJson = {
	checkErrorsMode: 'onValueChanged',
	showQuestionNumbers: 'off',
	showNavigationButtons: 'none',
	pages: [
		//page1 - photo-driver-license
		{
			name: 'page1',
			elements: [
				{
					type: 'file',
					name: 'photo-driver-license',
					isRequired: true,
					title: {
						default: 'Please upload a pictures (front and back) of your driving license',
						en: 'Please upload a pictures (front and back) of your driving license',
						de: 'Bitte um ein Foto der Vorder- und Rückseite deines Führerscheins',
						et: 'Palun laadige üles pilte (esi- ja tagakülg) oma juhiloast',
						es: 'Por favor, sube una foto (anverso y reverso) de tu permiso de conducir',
						fr: 'Télécharge une photo (recto et verso) de ton permis de conduire',
						hr: 'Molimo prenesite slike (prednju i stražnju) svoje vozačke dozvole',
						it: 'Carica una foto (fronte e retro) della tua patente di guida',
						lv: 'Lūdzu, augšupielādējiet savas vadītāja apliecības fotoattēlus (priekšpusē un aizmugurē)',
						lt: 'Įkelkite vairuotojo pažymėjimo nuotraukas (priekyje ir gale)',
						hu: 'Kérjük, tölts fel egy képet (elõ és hát oldal) a jogosítványodról',
						pl: 'Prześlij zdjęcia (przód i tył) swojego prawa jazdy.',
						pt: 'Carrega uma fotografia (frente e verso) da tua carta de condução',
						ro: 'Vă rugăm să încărcați o fotografie (față și verso) a permisului de conducere',
						sk: 'Nahrajte fotografie (prednej a zadnej strany) svojho vodičského preukazu',
						rs: 'Отпремите слике (предње и задње) возачке дозволе',
						fi: 'Lataa ajokorttisi kuvat (etu- ja takaosa)',
						tr: 'Lütfen ehliyetinizin bir fotoğrafını (ön ve arka) yükleyin',
						cs: 'Nahrajte prosím fotografie (přední a zadní) svého řidičského průkazu',
						bg: 'Моля, качете снимки (отпред и отзад) на шофьорската си книжка',
						mk: 'Поставете слики (напред и назад) од вашата возачка дозвола',
						ru: 'Пожалуйста, загрузите фотографии (спереди и сзади) своего водительского удостоверения',
						ua: 'Будь ласка, завантажте фотографію вашого водійського посвідчення (спереду і ззаду)',
						sl: 'Prenesite slike (spredaj in zadaj) svojega vozniškega dovoljenja.',
						bs: 'Postavite slike (prednje i zadnje strane) vaše vozačke dozvole',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page2 - trailer-license-plate
		{
			name: 'page2',
			elements: [
				{
					type: 'text',
					name: 'trailer-license-plate',
					title: {
						default: 'License plate of trailer',
						en: 'License plate of trailer',
						de: 'Auflieger Kennzeichen',
						et: 'Haagise numbrimärk',
						es: 'Matrícula del remolque',
						fr: "Plaque d'immatriculation de la remorque",
						hr: 'Registarska oznaka prikolice',
						it: 'Targa del rimorchio',
						lv: 'Piekabes numura zīme',
						lt: 'Priekabos valstybinis numeris',
						hu: 'Az utánfutó rendszámtáblája',
						pl: 'Tablica rejestracyjna przyczepy',
						pt: 'Matrícula do reboque',
						ro: 'Numărul de înmatriculare al remorcii',
						sk: 'ŠPZ prívesu',
						rs: 'Регистарска ознака приколице',
						fi: 'Perävaunun rekisterikilpi',
						tr: 'Römorkun plakası',
						cs: 'Poznávací značka přívěsu',
						bg: 'Регистрационен номер на ремаркето',
						mk: 'Регистерска табличка на приколката',
						ru: 'Номерной знак прицепа',
						ua: 'Номерний знак причепа',
						sl: 'Registrska tablica prikolice',
						bs: 'Registarska oznaka prikolice',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page3 - cleaned-outside
		{
			name: 'page3',
			elements: [
				{
					type: 'boolean',
					name: 'cleaned-outside',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Cleaned outside?',
						en: 'Cleaned outside?',
						de: 'Außen gereinigt?',
						et: 'Väljas puhastatud?',
						es: '¿Limpiaste el exterior?',
						fr: 'Vani očišćeno?',
						hr: 'Vani očišćeno?',
						it: "Pulito all'esterno?",
						lv: 'Tīrīts ārpusē?',
						lt: 'Išvalyta lauke?',
						hu: 'Kívülről megtisztítva?',
						pl: 'Sprzątałeś na zewnątrz?',
						pt: 'Limpaste o exterior?',
						ro: 'Ați curățat afară?',
						sk: 'Vyčistené vonku?',
						rs: 'Очишћени напољу?',
						fi: 'Puhdistettu ulkona?',
						tr: 'Dışarısı temizlendi mi?',
						cs: 'Vyčištěno venku?',
						bg: 'Почистено отвън?',
						mk: 'Исчистена надвор?',
						ru: 'Почистил снаружи?',
						ua: 'Зовні прибрали?',
						sl: 'Očiščeno zunaj?',
						bs: 'Očišćeni napolju?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page4 - photo-trailer
		{
			name: 'page4',
			elements: [
				{
					type: 'file',
					name: 'photo-trailer',
					isRequired: true,
					title: {
						default: 'Please send a photo of the trailer',
						en: 'Please send a photo of the trailer',
						de: 'Bitte schicke ein Foto vom Auflieger',
						et: 'Palun saatke foto haagisest',
						es: 'Por favor, envía una foto del remolque',
						fr: "Merci d'envoyer une photo de la remorque",
						hr: 'Pošaljite fotografiju trailera',
						it: 'Invia una foto del rimorchio',
						lv: 'Lūdzu, atsūtiet piekabes fotoattēlu',
						lt: 'Atsiųskite priekabos nuotrauką',
						hu: 'Kérjlek, küldj fényképet a pótkocsiról',
						pl: 'Prześlij zdjęcie przyczepy',
						pt: 'Envia uma fotografia do atrelado',
						ro: 'Vă rugăm să trimiteți o fotografie a remorcii',
						sk: 'Pošlite prosím fotografiu prívesu',
						rs: 'Пошаљите фотографију трејлера',
						fi: 'Lähetä kuva perävaunusta',
						tr: 'Lütfen römorkun bir fotoğrafını gönderin',
						cs: 'Zašlete prosím fotografii přívěsu',
						bg: 'Моля, изпратете снимка на ремаркето',
						mk: 'Ве молиме испратете фотографија од трејлерот',
						ru: 'Пожалуйста, пришлите фотографию трейлера',
						ua: 'Будь ласка, надішліть фото причепа',
						sl: 'Pošljite fotografijo prikolice',
						bs: 'Molimo pošaljite fotografiju trejlera',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page5 - tires-left-side
		{
			name: 'page5',
			elements: [
				{
					type: 'boolean',
					name: 'tires-left-side',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Tires left side ok?',
						en: 'Tires left side ok?',
						de: 'Reifen linke Seite ok?',
						et: 'Rehvid vasakpoolsed ok?',
						es: '¿Los neumáticos del lado izquierdo están bien?',
						fr: 'Les pneus du côté gauche sont en bon état?',
						hr: 'Gume s lijeve strane ok?',
						it: 'Le gomme del lato sinistro vanno bene?',
						lv: 'Riepas kreisajā pusē ok?',
						lt: 'Padangos kairėje pusėje gerai?',
						hu: 'A bal oldali gumiabroncsok rendben vannak?',
						pl: 'Opony po lewej stronie w porządku?',
						pt: 'Os pneus do lado esquerdo estão bem?',
						ro: 'Anvelope partea stângă ok?',
						sk: 'Pneumatiky na ľavej strane v poriadku?',
						rs: 'Гуме са леве стране у реду?',
						fi: 'Renkaat vasemmalla puolella ok?',
						tr: 'Lastiklerin sol tarafı iyi mi?',
						cs: 'Pneumatiky na levé straně v pořádku?',
						bg: 'Гумите от лявата страна са добре?',
						mk: 'Гумите од левата страна добро?',
						ru: 'Шины с левой стороны в порядке?',
						ua: 'Шини зліва в порядку?',
						sl: 'Pnevmatike na levi strani v redu?',
						bs: 'Gume sa lijeve strane ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page6 - photo-left-side-wheels
		{
			name: 'page6',
			elements: [
				{
					type: 'file',
					name: 'photo-left-side-wheels',
					isRequired: true,
					title: {
						default: 'Please send a photo on which the tires are clearly visible',
						en: 'Please send a photo on which the tires are clearly visible',
						de: 'Bitte schick ein Foto auf dem die Reifen gut erkennbar sind',
						et: 'Palun saatke foto, millel rehvid on selgelt näha',
						es: 'Por favor, envía una foto en la que se vean claramente los neumáticos',
						fr: "Merci d'envoyer une photo sur laquelle les pneus sont clairement visibles",
						hr: 'Pošaljite fotografiju na kojoj se dobro vide gume',
						it: 'Invia una foto in cui i pneumatici siano chiaramente visibili',
						lv: 'Lūdzu, atsūtiet fotoattēlu, kurā riepas ir skaidri redzamas',
						lt: 'Atsiųskite nuotrauką, kurioje aiškiai matomos padangos',
						hu: 'Kérjlek, küldj egy olyan fényképet, amelyen a gumiabroncsok jól láthatók',
						pl: 'Prześlij zdjęcie, na którym opony są wyraźnie widoczne',
						pt: 'Envia uma fotografia em que os pneus sejam claramente visíveis',
						ro: 'Vă rugăm să trimiteți o fotografie pe care să se vadă clar anvelopele',
						sk: 'Pošlite fotografiu, na ktorej sú jasne viditeľné pneumatiky',
						rs: 'Пошаљите фотографију на којој су гуме јасно видљиве', // serbian
						fi: 'Lähetä kuva, jossa renkaat näkyvät selvästi',
						tr: 'Lütfen lastiklerin açıkça görülebildiği bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii, na které jsou pneumatiky jasně viditelné',
						bg: 'Моля, изпратете снимка, на която гумите се виждат ясно',
						mk: 'Ве молиме испратете фотографија на која јасно се гледаат гумите',
						ru: 'Пожалуйста, пришлите фотографию, на которой хорошо видны шины',
						ua: 'Будь ласка, надішліть фото, на якому чітко видно шини',
						sl: 'Pošljite fotografijo, na kateri so pnevmatike jasno vidne.',
						bs: 'Pošaljite fotografiju na kojoj se jasno vide gume',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page7 - tires-right-side
		{
			name: 'page7',
			elements: [
				{
					type: 'boolean',
					name: 'tires-rigth-side',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Tires rigth side ok?',
						en: 'Tires rigth side ok?',
						de: 'Reifen rechte Seite ok?',
						et: 'Rehvid õigel poolel ok?',
						es: '¿Los neumáticos del lado derecho están bien?',
						fr: 'Les pneus sont bien à droite?',
						hr: 'Gume s desne strane ok?',
						it: 'I pneumatici sul lato destro vanno bene?',
						lv: 'Riepas rigth pusē ok?',
						lt: 'Padangos standžiojoje pusėje gerai?',
						hu: 'Gumiabroncsok jobb oldalon rendben?',
						pl: 'Opony po prawej stronie w porządku?',
						pt: 'Os pneus do lado direito estão bem?',
						ro: 'Anvelope pe partea dreaptă ok?',
						sk: 'Pneumatiky na pravej strane v poriadku?',
						rs: 'Гуме са десне стране у реду?',
						fi: 'Renkaat oikealla puolella ok?',
						tr: 'Lastikler sağlam mı?',
						cs: 'Pneumatiky na pravé straně v pořádku?',
						bg: 'Гумите на твърдата страна са добре?',
						mk: 'Гуми од десната страна во ред?',
						ru: 'Шины с жесткой стороны в порядке?',
						ua: 'Шини з правого боку в порядку?',
						sl: 'Pnevmatike na togi strani v redu?',
						bs: 'Gume sa desne strane ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page8 - photo-right-side-wheels
		{
			name: 'page8',
			elements: [
				{
					type: 'file',
					name: 'photo-right-side-wheels',
					isRequired: true,
					title: {
						default: 'Please send a photo on which the tires are clearly visible',
						en: 'Please send a photo on which the tires are clearly visible',
						de: 'Bitte schick ein Foto auf dem die Reifen gut erkennbar sind',
						et: 'Palun saatke foto, millel rehvid on selgelt näha',
						es: 'Por favor, envía una foto en la que se vean claramente los neumáticos',
						fr: "Merci d'envoyer une photo sur laquelle les pneus sont clairement visibles",
						hr: 'Pošaljite fotografiju na kojoj se dobro vide gume',
						it: 'Invia una foto in cui i pneumatici siano chiaramente visibili',
						lv: 'Lūdzu, atsūtiet fotoattēlu, kurā riepas ir skaidri redzamas',
						lt: 'Atsiųskite nuotrauką, kurioje aiškiai matomos padangos',
						hu: 'Kérjlek, küldj egy olyan fényképet, amelyen a gumiabroncsok jól láthatók',
						pl: 'Prześlij zdjęcie, na którym opony są wyraźnie widoczne',
						pt: 'Envia uma fotografia em que os pneus sejam claramente visíveis',
						ro: 'Vă rugăm să trimiteți o fotografie pe care să se vadă clar anvelopele',
						sk: 'Pošlite fotografiu, na ktorej sú jasne viditeľné pneumatiky',
						rs: 'Пошаљите фотографију на којој су гуме јасно видљиве', // serbian
						fi: 'Lähetä kuva, jossa renkaat näkyvät selvästi',
						tr: 'Lütfen lastiklerin açıkça görülebildiği bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii, na které jsou pneumatiky jasně viditelné',
						bg: 'Моля, изпратете снимка, на която гумите се виждат ясно',
						mk: 'Ве молиме испратете фотографија на која јасно се гледаат гумите',
						ru: 'Пожалуйста, пришлите фотографию, на которой хорошо видны шины',
						ua: 'Будь ласка, надішліть фото, на якому чітко видно шини',
						sl: 'Pošljite fotografijo, na kateri so pnevmatike jasno vidne.',
						bs: 'Pošaljite fotografiju na kojoj se jasno vide gume',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page9 - lighting
		{
			name: 'page9',
			elements: [
				{
					type: 'boolean',
					name: 'lighting',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Lighting ok?',
						en: 'Lighting ok?',
						de: 'Beleuchtung ok?',
						et: 'Valgustus ok?',
						es: '¿Está bien la iluminación?',
						fr: 'Eclairage ok?',
						hr: 'Rasvjeta u redu?',
						it: "L'illuminazione va bene?",
						lv: 'Apgaismojums ir kārtībā?',
						lt: 'Apšvietimas tvarkingas?',
						hu: 'Világítás rendben?',
						pl: 'Oświetlenie w porządku?',
						pt: 'A iluminação está boa?',
						ro: 'Iluminarea este în regulă?',
						sk: 'Osvetlenie je v poriadku?',
						rs: 'Осветљење ОК?',
						fi: 'Onko valaistus kunnossa?',
						tr: 'Işıklandırma iyi mi?',
						cs: 'Osvětlení je v pořádku?',
						bg: 'Осветлението е наред?',
						mk: 'Осветлувањето е во ред?',
						ru: 'Освещение в порядке?',
						ua: 'Освітлення в порядку?',
						sl: 'Razsvetljava je v redu?',
						bs: 'Osvetljenje OK?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{lighting} = "No"',
					type: 'file',
					name: 'photo-lighting',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page10 - roof-tarp
		{
			name: 'page10',
			elements: [
				{
					type: 'boolean',
					name: 'roof-tarp',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Roof tarpauline ok?',
						en: 'Roof tarpauline ok?',
						de: 'Dachplane ok?',
						et: 'Katusekate ok?',
						es: '¿Está bien la lona del tejado?',
						fr: "La bâche de toit, c'est bon ?",
						hr: 'Krovna cerada u redu?',
						it: 'Il telo del tetto va bene?',
						lv: 'Jumta brezenta jumts ir kārtībā?',
						lt: 'Stogo brezentas gerai?',
						hu: 'Tetőponyva rendben?',
						pl: 'Plandeka dachowa ok?',
						pt: 'Podes usar uma lona de telhado?',
						ro: 'Pânza de acoperiș ok?',
						sk: 'Strešná plachta v poriadku?',
						rs: 'Кровна церада ок?',
						fi: 'Kattopeite ok?',
						tr: 'Çatı muşambası olur mu?',
						cs: 'Střešní plachta v pořádku?',
						bg: 'Покривният брезент е добре?',
						mk: 'Кровна церада во ред?',
						ru: 'Брезент на крыше - это нормально?',
						ua: 'Брезент на даху підійде?',
						sl: 'Strešna ponjava v redu?',
						bs: 'Krovna cerada ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{roof-tarp} = "No"',
					type: 'file',
					name: 'photo-roof-tarp',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page11 - rear-doors
		{
			name: 'page11',
			elements: [
				{
					type: 'boolean',
					name: 'rear-doors',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Rear doors ok?',
						en: 'Rear doors ok?',
						de: 'Hecktüren ok?',
						et: 'Tagumised uksed ok?',
						es: '¿Están bien las puertas traseras?',
						fr: 'Les portes arrière sont en bon état ?',
						hr: 'Stražnja vrata ok?',
						it: 'Le porte posteriori vanno bene?',
						lv: 'Aizmugurējās durvis ir kārtībā?',
						lt: 'Galinės durys tvarkingos?',
						hu: 'Hátsó ajtók rendben?',
						pl: 'Tylne drzwi w porządku?',
						pt: 'As portas traseiras estão bem?',
						ro: 'Ușile din spate sunt în regulă?',
						sk: 'Zadné dvere v poriadku?',
						rs: 'Задња врата у реду?',
						fi: 'Takaovet kunnossa?',
						tr: 'Arka kapılar iyi mi?',
						cs: 'Zadní dveře v pořádku?',
						bg: 'Задните врати са добре?',
						mk: 'Задните врати во ред?',
						ru: 'Задние двери в порядке?',
						ua: 'Задні двері в порядку?',
						sl: 'Zadnja vrata v redu?',
						bs: 'Zadnja vrata ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{rear-doors} = "No"',
					type: 'file',
					name: 'photo-rear-doors',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page12 - cleaned-inside
		{
			name: 'page12',
			elements: [
				{
					type: 'boolean',
					name: 'cleaned-inside',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Cleaned inside?',
						en: 'Cleaned inside?',
						de: 'Innen gereinigt?',
						et: 'Seestpoolt puhastatud?',
						es: '¿Limpiado por dentro?',
						fr: "L'intérieur a été nettoyé ?",
						hr: 'Očišćeno iznutra?',
						it: "Pulito all'interno?",
						lv: 'Tīrīts iekšpusē?',
						lt: 'Išvalytas vidus?',
						hu: 'Belül kitakarítva?',
						pl: 'Wyczyściłeś wnętrze?',
						pt: 'Limpaste o interior?',
						ro: 'Curățat în interior?',
						sk: 'Vyčistené vnútro?',
						rs: 'Очишћено унутра?',
						fi: 'Puhdistettu sisältä?',
						tr: 'İçi temizlendi mi?',
						cs: 'Vyčištěno uvnitř?',
						bg: 'Почистена отвътре?',
						mk: 'Исчистена внатре?',
						ru: 'Почистил внутри?',
						ua: 'Почистили всередині?',
						sl: 'Očiščena notranjost?',
						bs: 'Očišćeno unutra?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page13 - floor
		{
			name: 'page13',
			elements: [
				{
					type: 'boolean',
					name: 'floor',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Floor ok?',
						en: 'Floor ok?',
						de: 'Boden ok?',
						et: 'Korrus ok?',
						es: '¿El suelo está bien?',
						fr: 'Le sol est ok?',
						hr: 'Pod ok?',
						it: 'Il pavimento va bene?',
						lv: 'Grīda ir kārtībā?',
						lt: 'Grindys tvarkingos?',
						hu: 'Padló rendben?',
						pl: 'Podłoga w porządku?',
						pt: 'O chão está bom?',
						ro: 'Podeaua e în regulă?',
						sk: 'Podlaha v poriadku?',
						rs: 'Флоор ок?',
						fi: 'Onko lattia kunnossa?',
						tr: 'Zemin iyi mi?',
						cs: 'Podlaha je v pořádku?',
						bg: 'Подът е наред?',
						mk: 'Подот е во ред?',
						ru: 'Пол в порядке?',
						ua: 'Підлога в порядку?',
						sl: 'So tla v redu?',
						bs: 'Floor ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{floor} = "No"',
					type: 'file',
					name: 'photo-floor',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page14 - partition-wall
		{
			name: 'page14',
			elements: [
				{
					type: 'boolean',
					name: 'partition-wall',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Dividing wall ok?',
						en: 'Dividing wall ok?',
						de: 'Trennwand ok?',
						et: 'Vahesein on ok?',
						es: '¿Está bien la pared divisoria?',
						fr: 'Le mur de séparation est-il acceptable ?',
						hr: 'Pregradni zid u redu?',
						it: 'Il muro divisorio va bene?',
						lv: 'Sadalošā siena ok?',
						lt: 'Skiriamoji siena gerai?',
						hu: 'Az elválasztó fal rendben van?',
						pl: 'Ścianka działowa ok?',
						pt: 'Podes fazer uma parede divisória?',
						ro: 'Perete despărțitor ok?',
						sk: 'Deliaca stena v poriadku?',
						rs: 'Преградни зид у реду?',
						fi: 'Jakava seinä ok?',
						tr: 'Bölme duvar tamam mı?',
						cs: 'Dělící stěna v pořádku?',
						bg: 'Разделителната стена е подходяща?',
						mk: 'Ѕидот за поделба е во ред?',
						ru: 'Разделительная стена - это нормально?',
						ua: 'Перегородка підійде?',
						sl: 'Razdelilna stena v redu?',
						bs: 'Pregradni zid ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{partition-wall} = "No"',
					type: 'file',
					name: 'photo-partition-wall',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page15 - partition-wall-roller
		{
			name: 'page15',
			elements: [
				{
					type: 'boolean',
					name: 'partition-wall-roller',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Dividing wall wheels ok?',
						en: 'Dividing wall wheels ok?',
						de: 'Trennwand Rollen ok?',
						et: 'Vaheseinarattad ok?',
						es: '¿Las ruedas de pared divisoria están bien?',
						fr: 'Les roues du mur de séparation peuvent-elles être utilisées ?',
						hr: 'Pregradni zidni kotači u redu?',
						it: 'Le ruote divisorie vanno bene?',
						lv: 'Sadalošās sienas riteņi ok?',
						lt: 'Skiriamosios sienelės ratai gerai?',
						hu: 'Osztófal kerekek rendben?',
						pl: 'Koła do ścianek działowych ok?',
						pt: 'Podes dividir as rodas da parede?',
						ro: 'Roți de perete despărțitor ok?',
						sk: 'Deliace stenové kolesá v poriadku?',
						rs: 'Преградни зидни точкови у реду?',
						fi: 'Jakoseinän pyörät ok?',
						tr: 'Bölme duvar tekerlekleri tamam mı?',
						cs: 'Dělicí stěnová kola v pořádku?',
						bg: 'Колелата за разделителна стена са подходящи?',
						mk: 'Разделување на ѕидни тркала во ред?',
						ru: 'Разделительные стеновые диски - это нормально?',
						ua: 'Колеса на перегородці в порядку?',
						sl: 'Razdelilna stenska kolesa v redu?',
						bs: 'Pregradni zidni točkovi u redu?',
					},
					isRequired: true,
					maxLength: 30,
				},
				{
					visibleIf: '{partition-wall-roller} = "No"',
					type: 'file',
					name: 'photo-partition-wall roller',
					isRequired: true,
					title: {
						default: 'Please send a photo',
						en: 'Please send a photo',
						de: 'Bitte um ein Foto',
						et: 'Palun saatke foto',
						es: 'Por favor, envía una foto',
						fr: 'Envoie une photo',
						hr: 'Pošaljite fotografiju',
						it: 'Invia una foto',
						lv: 'Lūdzu, nosūtiet fotoattēlu',
						lt: 'Atsiųskite nuotrauką',
						hu: 'Kérlek, küldj egy fotót',
						pl: 'Prześlij zdjęcies',
						pt: 'Envia uma fotografia',
						ro: 'Vă rugăm să trimiteți o fotografie',
						sk: 'Pošlite prosím fotografiu',
						rs: 'Молимо пошаљите фотографију', // serbian
						fi: 'Lähetä kuva',
						tr: 'Lütfen bir fotoğraf gönderin',
						cs: 'Zašlete prosím fotografii',
						bg: 'Моля, изпратете снимка',
						mk: 'Ве молиме испратете фотографија',
						ru: 'Пожалуйста, пришлите фотографию',
						ua: 'Будь ласка, надішліть фотографію',
						sl: 'Pošljite fotografijo',
						bs: 'Pošaljite fotografiju',
					},
					storeDataAsText: false,
					storePhotoAsText: false,
					waitForUpload: true,
					hideNumber: false,
					allowMultiple: true,
					sourceType: 'file-camera', // or "file-camera"
				},
			],
		},
		//page16 - remote-control-walky
		{
			name: 'page16',
			elements: [
				{
					type: 'boolean',
					name: 'remote-control-walky',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Remote control walky ok?',
						en: 'Remote control walky ok?',
						de: 'Fernbedienung Walky ok?',
						et: 'Kaugjuhtimispuldi walky ok?',
						es: '¿Control remoto walky ok?',
						fr: 'La télécommande walky est ok?',
						hr: 'Daljinski upravljač walky ok?',
						it: 'Il telecomando walky va bene?',
						lv: 'Tālvadības pults walky ok?',
						lt: 'Nuotolinio valdymo pultelis Walky ok?',
						hu: 'Walky rendben?',
						pl: 'Zdalnie sterowany walky ok?',
						pt: 'Podes usar o controlo remoto?',
						ro: 'Telecomanda walky ok?',
						sk: 'Diaľkové ovládanie walky v poriadku?',
						rs: 'Даљински управљач валки ок?',
						fi: 'Kaukosäädin walky ok?',
						tr: 'Uzaktan kumandalı walky tamam mı?',
						cs: 'Dálkové ovládání walky v pořádku?',
						bg: 'Дистанционно управление Walky добре?',
						mk: 'Далечински управувач, во ред?',
						ru: 'Рации с дистанционным управлением в порядке?',
						ua: 'Пульт дистанційного керування працює?',
						sl: 'Daljinski upravljalnik walky ok?',
						bs: 'Daljinski upravljač walky ok?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page17 - shovel
		{
			name: 'page17',
			elements: [
				{
					type: 'boolean',
					name: 'shovel',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Shovel',
						en: 'Shovel',
						de: 'Schaufel',
						et: 'Labidas',
						es: 'Pala',
						fr: 'Pelle',
						hr: 'Lopata',
						it: 'Pala',
						lv: 'Lāpsta',
						lt: 'Kastuvas',
						hu: 'Lapát',
						pl: 'Łopata',
						pt: 'Pá',
						ro: 'Lopata',
						sk: 'Lopata',
						rs: 'Лопата',
						fi: 'Lapio',
						tr: 'Kürek',
						cs: 'Lopata',
						bg: 'Лопата',
						mk: 'Лопата',
						ru: 'Лопата',
						ua: 'Лопата',
						sl: 'Lopata',
						bs: 'Lopata',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page18 - broom
		{
			name: 'page18',
			elements: [
				{
					type: 'boolean',
					name: 'broom',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Broom',
						en: 'Broom',
						de: 'Besen',
						et: 'Luud',
						es: 'Escoba',
						fr: 'Balai',
						hr: 'Metla',
						it: 'Scopa',
						lv: 'Slota',
						lt: 'Šluota',
						hu: 'Seprű',
						pl: 'Miotła',
						pt: 'Vassoura',
						ro: 'Mătură',
						sk: 'Metla',
						rs: 'Метла',
						fi: 'Luuta',
						tr: 'Süpürge',
						cs: 'Metla',
						bg: 'Метла',
						mk: 'Метла',
						ru: 'Метла',
						ua: 'Мітла',
						sl: 'Metla',
						bs: 'Metla',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page19 - tarpaulin-hook
		{
			name: 'page19',
			elements: [
				{
					type: 'boolean',
					name: 'tarpaulin-hook',
					valueTrue: 'Yes',
					valueFalse: 'No',
					renderAs: 'radio',
					title: {
						default: 'Tarpaulin hooks ok?',
						en: 'Tarpaulin hooks ok?',
						de: 'Planenhaken ok?',
						et: 'Tentkonksud ok?',
						es: '¿Los ganchos de lona están bien?',
						fr: 'Les crochets de bâche, sont-ils en bon état?',
						hr: 'Jesu li kuke za ceradu u dobrom stanju?',
						it: 'I ganci del telone vanno bene?',
						lv: 'Vai brezenta āķi ir kārtībā?',
						lt: 'Ar brezento kabliukai gerai?',
						hu: 'A ponyvahorgok rendben vannak?',
						pl: 'Czy haki do plandek są w porządku?',
						pt: 'Os ganchos da lona estão OK?',
						ro: 'Cârligele prelatei sunt în regulă?',
						sk: 'Sú háky na plachty v poriadku?',
						rs: 'Да ли су куке за цераду у реду?',
						fi: 'Ovatko pressun koukut kunnossa?',
						tr: 'Branda kancaları iyi durumda mı?',
						cs: 'Jsou háky plachty v pořádku?',
						bg: 'Добре ли са куките за брезент?',
						mk: 'Дали куките за церада се во ред?',
						ru: 'Крючки для брезента в порядке?',
						ua: 'Гачки для брезенту в порядку?',
						sl: 'Ali so kljuke za ponjavo v redu?',
						bs: 'Jesu li kuke za ceradu u redu?',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
		//page20 - notes
		{
			name: 'page20',
			elements: [
				{
					type: 'text',
					name: 'notes-trailer',
					title: {
						default: 'Any other comments',
						en: 'Any other comments',
						de: 'Andere Anmerkungen',
						et: 'Muud kommentaarid',
						es: 'Otros comentarios',
						fr: 'Autres commentaires',
						hr: 'Bilo koji drugi komentari',
						it: 'Altri commenti',
						lv: 'Citi komentāri',
						lt: 'Kitos pastabos',
						hu: 'Egyéb észrevételek',
						pl: 'Inne uwagi',
						pt: 'Outras observaçõesse',
						ro: 'Orice alte observațiise',
						sk: 'Akékoľvek ďalšie pripomienky',
						rs: 'Било који други коментар',
						fi: 'Mahdolliset muut huomautuksetse',
						tr: 'Başka yorumunuz varsa',
						cs: 'Jakékoli další připomínky',
						bg: 'Други коментари',
						mk: 'Сите други коментари',
						ru: 'Любые другие комментарииse',
						ua: 'Будь-які інші коментарі',
						sl: 'Morebitne druge pripombe',
						bs: 'Bilo koji drugi komentar',
					},
					isRequired: true,
					maxLength: 30,
				},
			],
		},
	],
};
