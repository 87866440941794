import { useState } from 'react';

import { useEvent, usePusherChannelsContext } from '../hooks';

import type {
	NarrowType,
	RealtimeWorkspaceEvent,
	WorkspaceEventsType,
} from '@heylog-app/shared/types';

/**
 * This hook lets you react to a workspace event. By providing the workspace event,
 * Typescript will automatically inject the correct callback for you. Also, you don't need
 * to memoize the callback function, as it is directly memoized in this hook. This implies
 * however, that once this hook has been called, the callback cannot be changed anymore.
 * Make sure you use this hook only inside the Pusher channels context provider.
 */
export const useWsChannelEvent = <
	Event extends WorkspaceEventsType,
	Payload extends RealtimeWorkspaceEvent,
>(
	event: Event,
	fn: (payload: NarrowType<Payload, Event>['payload']) => void,
) => {
	const { wsChannel } = usePusherChannelsContext();

	const [memoizedFunction] = useState(() => fn);

	useEvent(wsChannel, event, memoizedFunction);
};
